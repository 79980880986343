<template>
  <div :class="$style.union_leaning_status">
    <app-prev-button @click="$router.push(`/union/home`)" />
    <div :class="$style.chart_content_wrap">
      <h1 :class="$style.chart_content_title">あなたの学習ステータス</h1>
      <div :class="$style.chart_content_chart">
        <app-status-chart
          :labels="['組合理解','役割理解','労働基礎','スキル']"
          :data="[
            attendanceRate.union,
            attendanceRate.role,
            attendanceRate.scene,
            attendanceRate.skill
          ]"
          :average="[
            attendanceRate.union_average,
            attendanceRate.role_average,
            attendanceRate.scene_average,
            attendanceRate.skill_average
          ]"
        />
      </div>
    </div>
    <div :class="$style.union_leaning_status_content">
      <div :class="$style.select_wrap">
        <base-select :options="selectOptions" v-model="selectedValue" v-on:update="selectChanged($event)"/>
      </div>
      <div :class="$style.list_accordion_wrap">
        <div :class="$style.post_item_list">
          <div v-for="(model, index) in getLectures" :class="$style.team_item_wrap" :key="index">
            <app-carousel-post-item
              :borderColor="convertColor(model.bar_color)"
              :tag="model.status"
              icon="heart"
              :selected="!!model.good_flg"
              :head="model.lecture_title"
              :foot="createPageTitle(model)"
              :image="model.lecture_path"
              :customTagText="model.status"
              :customTagColor="convertColor(model.background_color)"
              :customTagTextColor="convertColor(model.text_color)"
              v-on:select="clickGood(index, $event)"
              v-on:click="$router.push('/union/course/' + model.lecture_id)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppStatusChart from '@/components/app/AppChart/AppStatusChart/AppStatusChart'
import BaseSelect from '@/components/base/BaseSelect/BaseSelect'
import AppCarouselPostItem from '@/components/app/AppCarousel/PostItem/AppCarouselPostItem'
import { editLectureHistoryChatFavorite } from '@/helper/common.js'
import { convertColor, createPageTitle, fromBoolToNumber, commitGoodFlg } from '@/helper/union/lecture.js'
import AppPrevButton from '@/components/app/AppPrevButton/AppPrevButton'

export default {
  name: 'UnionLeaningStatus',
  components: { AppStatusChart, BaseSelect, AppCarouselPostItem, AppPrevButton },
  mounted() {
    this.$router.app.$emit('page-updated', {
      pageTitle: '学習状況',
      back: '/union/home',
      headerColor: 'white',
      showSetting: false,
      bgColor: 'yellow'
    })
  },
  data() {
    return {
      /**
       * ドロップダウンアイテム
       */
      selectOptions: [
        {
          label: "全ての講座",
          value: 1,
        },
        {
          label: "視聴完了していない講座",
          value: 2,
        },
        {
          label: "クイズに回答/正答していない講座",
          value: 3,
        },
      ],
      selectedValue: null,
      /**
       * POST処理実行中フラグ
       */
      processing: false,
      /**
       * ステータス
       */
      attendanceRate: {},
      /** 講座一覧 */
      lectureData: { lecture_list: []},
    }
  },
  created: async function() {
    this.$router.app.$emit('show-spinner', true)
    const defaultIndex = 1
    this.selectedValue = defaultIndex
    this.attendanceRate = await this.fetchAttendanceRate()
    // 全ての講座取得
    this.commitLectureData(await this.fetchLectureList(defaultIndex))
    this.$router.app.$emit('show-spinner', false)
  },
  computed: {
    getLectures: function () {
      return this.lectureData.lecture_list
    },
    // createLectureListMock: function () {
    //   const result = this.$store.getters['union/createLectureListMock']
    //   return result
    // },
  },
  methods: {
    convertColor,
    createPageTitle,
    /**
     * 講座一覧設定処理
     */
    commitLectureData: function (lectureData) {
      this.lectureData.lecture_list.splice(0)
      const lectures = lectureData.lecture_list
      this.lectureData.lecture_list.splice(0, 0, ...lectures)
    },
    /**
     * 講座の受講率取得API実行処理
     */
    fetchAttendanceRate: async function () {
      let attendanceRate
      await this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/attendance_rate/list')
        .then((res) => {
          if (res.data.status == 'success') {
            attendanceRate = res.data.value
          } else {
            attendanceRate = null
          }
        })
        .catch((err) => {
          this.$logerror(err)
          throw new Error(err)
        })
      return attendanceRate
    },
    /**
     * ドロップダウンの選択アイテム変更処理
     */
    selectChanged: async function (value) {
      const lecture_value = await this.fetchLectureList(value)
      this.commitLectureData(lecture_value)
    },
    /**
     * 講座一覧取得API実行処理
     */
    fetchLectureList: async function (item) {
      let lectureData = {
        lecture_list: []
      }

      let parameters = {
        // "corner_id": this.corner_id,
        "sort": "sort",
        "direction": "asc",
        "unread_flg": 0, // 仮
        "limit": 500,
      }
      // this.$loginfo(item)
      switch (item){
        case 1: // 全ての講座
          // sort=created_at,direction=desc,limit=500
          break
        case 2: // 視聴完了していない講座
          parameters["unread_flg"] = 1
          // unread_flg=1,sort=created_at,direction=desc,limit=500
          break
        case 3: // クイズに回答/正答していない講座
          parameters["quiz_confirmed_status"] = "1,2"
          // sort=created_at,direction=desc,limit=500
          break
        default:
          return lectureData
      }
      await this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/lecture/list', {
          params: parameters
        })
        .then((res) => {
          if (res.data.status == 'success') {
            lectureData = res.data.value
            this.$loginfo(lectureData)
          //   if (lectureData.lecture_list.length == 0) {
          //     // 開発用
          //     lectureData = null
          //   }
          // } else {
          //   lectureData = null
          }
        })
        .catch((err) => {
          this.$logerror(err)
          // lectureData = null
          throw new Error(err)
        })

      // if (lectureData == null) {
      //   lectureData = this.createLectureListMock
      // }

      return lectureData
    },
    /**
     * いいね！クリック処理
     */
    clickGood: async function (index, flg) {
      if (this.processing) return

      const good_flg = fromBoolToNumber(flg)
      const lecture = this.getLectures[index]
      this.processing = true
      const result = await editLectureHistoryChatFavorite({lecture_id: lecture.lecture_id, good_flg: good_flg})
      if (result.status == 'success') {
        commitGoodFlg(this.lectureData.lecture_list, index, good_flg)
      }
      this.processing = false
    },
  }
}
</script>

<style lang="scss" module>
.union_leaning_status {
  display: flex;
  width: 960px;
  flex-direction: column;
  align-items: center;
  padding: 10px 0 62px 0;
  margin: 0 auto;
  @include mobile(){
    width: 100%;
    margin: 0;
    padding: 12px 0 0 0;
  }
}

.chart_content_wrap {
  width: 100%;
  margin-bottom: 20px;

  @include mobile(){
    margin-bottom: 12px;
  }
}

.chart_content_title {
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  margin: 0 0 20px 0;

  @include mobile() {
    font-size: 11px;
    margin: 0 0 12px 0;
  }
}

.chart_content_chart {
  display: flex;
  justify-content: center;
  width: 100%;
}

.union_leaning_status_content {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 20px 32px 20px 32px;
  background-color: $keyWhite;
}

// .select_wrap {

// }

.list_accordion_wrap {
  width: 100%;
  margin-top: 20px;

  .post_item_list {
    padding: 0 1px;
    margin-left: 8px;
    display: flex;
    flex-wrap: wrap;
    @include mobile() {
      flex-wrap: nowrap;
      overflow-y: scroll;
    }

    .team_item_wrap {
      margin-right: 20px;
      margin-bottom: 14px;
    }
  }
}

</style>
