<template>
  <div :class="$style.page_nav_wrap">
    <div :class="$style.page_nav_border">
      <div :class="$style.page_nav">
        <a
          :class="[$style.prev, {[$style.disabled]: !enablePrev}]"
          @click.prevent.stop="() => { enablePrev && $emit('page', page - 1) }"
        ><base-icon type="fas" name="chevron-left" size="18px" /></a>
        <a
          v-for="listPage in listPages"
          :key="listPage"
          :class="[$style.page, {
            [$style.current]: listPage == page,
          }]"
          @click.prevent.stop="() => { enablePage(listPage) && $emit('page', listPage) }"
        >
          {{ listPage }}
        </a>
        <a
          :class="[$style.next, {[$style.disabled]: !enableNext}]"
          @click.prevent.stop="() => { enableNext && $emit('page', page + 1) }"
        ><base-icon type="fas" name="chevron-right" size="18px" /></a>
      </div>
    </div>
  </div>
</template>
<script>
import BaseIcon from "@/components/base/BaseIcon/BaseIcon"

export default {
  components: { BaseIcon },
  props: {
    page: {
      type: Number,
    },
    pageMax: {
      type: Number,
    },
    pageMin: {
      type: Number,
      default: 1
    },
    pageListNum: {
      type: Number,
      default: 6
    },
  },
  methods: {
    enablePage(page) {
      return this.page != page
    }
  },
  computed: {
    pageIndex() {
      return this.page - this.pageMin
    },
    pageNum() {
      return this.pageMax - this.pageMin + 1
    },
    enablePrev() {
      return this.pageIndex > 0
    },
    enableNext() {
      return this.pageIndex + 1 < this.pageNum
    },
    listPages() {
      const pages = []
      if (this.pageListNum > 0) {
        let page = this.page - Math.ceil(this.pageListNum / 2) + 1
        if (page < this.pageMin) {
          page = this.pageMin
        }
        let pageLast = page + this.pageListNum - 1
        if (pageLast > this.pageMax) {
          page -= pageLast - this.pageMax
          if (page < this.pageMin) {
            page = this.pageMin
          }
          pageLast = this.pageMax
        }
        for (; page <= pageLast; ++page) {
          pages.push(page)
        }
      }
      return pages
    }
  }
}
</script>
<style lang="scss" module>
$fontGray: #B7B7B7;

.page_nav {
  text-align: center;
  padding: 2px 20px 0px;

  @media (max-width: 767px) {
    padding: 2px 5px 0px;
  }

  display: flex;
  align-items: center;

  .prev,
  .next {
    display: inline-block;
    color: rgb(45, 204, 211);
    font-weight: bold;
    cursor: pointer;

    &.disabled {
      color: $fontGray;
      cursor: default;
    }
  }
  .prev { margin-right: 24px; }
  .next { margin-left: 24px; }

  .page {
    display: inline-block;
    color: $keyBlack;
    text-decoration: underline;
    cursor: pointer;
    margin: 0 13px;
    font-size: 16px;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;

    &.current {
      color: #fff;
      font-weight: bold;
      cursor: default;
      position: relative;
      background-color: rgb(45, 204, 211);
      text-decoration: none;
    }

    @media (max-width: 767px) {
      margin: 0 6px;
      font-size: 13px;
    }
  }
}
.page_nav_border{
  border: solid 1px rgb(45, 204, 211);
  border-radius: 6px;
  display: inline-block;
  padding: 8px 0;
}
</style>
