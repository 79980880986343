// modules/authChange.js
const state = {
  currentCognitoId: '',
  changeCognitoId: '',
  changeCognitoIdConfirmation: '',
  password: '',
  authChangeId: '',
};

const mutations = {
  setAuthChangeData(state, data) {
    state.currentCognitoId = data.currentCognitoId;
    state.changeCognitoId = data.changeCognitoId;
    state.changeCognitoIdConfirmation = data.changeCognitoIdConfirmation;
    state.password = data.password;
    state.authChangeId = data.authChangeId;
  },
};

const actions = {
  saveAuthChangeData({ commit }, data) {
    commit('setAuthChangeData', data);
  },
};

const getters = {
  getAuthChangeData: state => state,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
