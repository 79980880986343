<template>
  <section :class="[$style.wrapper, { [$style.wrapper_home]: home }]">
    <div :class="customHeaderClass">
      <div :class="$style.sso_logo_wrapper" @click="handleClick(sso_login_url)">
        <img
          :class="$style.sso_logo"
          :src="sso_list_logo_imgpath(service_id)"
          :style="cursorStyle"
        />
      </div>
    </div>
    <div v-for="(sso, index) in sso_list" :key="index">
      <div :class="[$style.head_wrapper, { [$style.head_wrapper_home]: home }]">
        <div
          :href="sso.redirect_url"
          :class="customClass"
          @click="handleClick(sso)"
        >
          <div :class="$style.head">{{ sso.team_name }}</div>
          <img src="@/assets/images/icon_traget_blank.png" width="16" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AppCarouselSsoItem',
  props: {
    header_card: {
      type: Boolean,
      default: false,
    },
    head: {
      type: String,
      default: '8月の活動日決めます！投票してください', // あとで消す
    },
    foot: {
      type: String,
      default: 'オタメシ労働組合湘南サーフィン支部', // あとで消す
    },
    service_id: {
      type: Number,
      default: null,
    },
    sso_list: {
      type: [Array, Object],
      default: null,
    },
    sso_login_url: {
      type: Object,
      default: null,
    },
    home: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // sso_login_urlが指定されている場合はポインター表示
    cursorStyle() {
      if (this.sso_login_url) {
        return {
          cursor: 'pointer',
        }
      } else {
        return {}
      }
    },
    customClass() {
      return [
        this.$style.redirect_url,
        {
          [this.$style.header_card]: !!this.header_card,
        },
        {
          [this.$style.redirect_url_home]: !!this.home,
        },
      ]
    },
    customHeaderClass() {
      return [
        this.$style.header_wrapper,
        {
          [this.$style.header_card_wrapper]: !!this.header_card,
        },
        {
          [this.$style.home_wrapper]: !!this.home,
        },
      ]
    },
  },
  methods: {
    handleClick(sso) {
      if (sso != null) {
        // クリックイベントが発火するように設定
        this.$emit('click', sso)
      }
    },
    sso_list_logo_imgpath(service_id) {
      try {
        const img = require(`@/assets/images/sso_list_logo_service_id_${service_id}.png`)
        return img
      } catch (e) {
        return ''
      }
    },
  },
}
</script>

<style lang="scss" module>
.wrapper {
  position: relative;
  width: 240px;
  height: auto;
  background-color: $keyWhite;
  box-sizing: border-box;
  @include mobile_only() {
    width: 140px;
  }
  &.wrapper_home {
    @include mobile_only() {
      max-width: 340px;
      gap: 4px;
      width: auto;
    }
  }
}

.clip {
  position: absolute;
  top: -16px;
  left: 4px;
}

.pin {
  z-index: 2;
  position: absolute;
  top: -14px;
  left: -3px;
  transform: rotate(-21deg);
}
.header_wrapper {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  line-height: 11px;
  // box-shadow: 0px 2px 0px #c4c4c4;
  &.header_card_wrapper {
    box-shadow: none;
  }
}
.sso_logo_wrapper {
  height: 38px;
}
.sso_logo {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.redirect_url {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  &.header_card {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    gap: 4px;
  }
}
.tag_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  max-width: 100px;
}

.time {
  display: inline-block;
  color: $fontGray;
  margin: 0;
}
.time_text {
  display: inline-block;
  font-size: 10px;
  transform: scale(0.9);
}
.icons {
  display: inline-block;
  color: $borderLiteGray;
  margin: 0;

  &_wrapper {
    display: flex;
  }

  .selected {
    color: $keyPink;
  }
}

.overlay_menu_wrapper {
  display: flex;
  .menu_button_icon {
    width: 10px;
    height: 10px;
    cursor: pointer;
    background-image: url('../../../../assets/images/グループ 1656.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}
.picture {
  display: block;
  height: 50px;
  overflow: hidden;
}
.picture > img {
  width: 100%;
}
.head_wrapper {
  border-bottom: 2px dotted rgb(238 238 238);
  margin: 9px 0 0;
  padding: 0 6px;
}
.head {
  color: #262626;
  height: 2em;
  margin: 10px;
  padding: 1px 0px 0px 0px;
  line-height: 1;
  box-sizing: border-box;
  font-size: 16px;
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
  @include mobile() {
    font-size: 10px;
  }
}

.foot {
  display: block;
  color: $fontGray;
  max-height: 36px;
  line-height: 12px;
  margin: 0;
  font-size: 10px;
  transform: scale(0.9);
  word-break: break-all;
  overflow: hidden;
  // position: absolute;
  bottom: 4px;
}

.header_card > * {
  color: $keyGreen;
  font-weight: normal;
  text-decoration: underline;
  height: auto;
  margin: 0;
}
.home_wrapper {
  display: flex;
  justify-content: flex-start;
  padding: 0;
  .sso_logo_wrapper {
    height: auto;
    img {
      height: 36px;
      width: auto;
    }
  }
}
.head_wrapper_home {
  border: 0;
  padding: 0;
}
.redirect_url_home {
  display: flex;
  gap: 4px;
  align-items: center;
  font-weight: normal;
  .head {
    text-decoration: underline;
    color: $keyGreen;
    margin: 0;
    height: auto;
    font-size: 14px;
    line-height: 1.5;
  }
}
</style>
