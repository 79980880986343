<template>
  <div :class="$style.change_password">
    <app-prev-button @click="backButtonAction(prevRoute)" />
    <h1 :class='$style.title'>確認コード入力</h1>
    <p :class='$style.note'>くみあいアプリより通知された確認コードを24時間以内に入力してください。</p>
    <div :class="$style.input_group">
      <base-form-field name="confirm_code" label="確認コード">
        <base-input
          type="text"
          name="confirm_code"
          maxLength="255"
          v-model="inputConfirmCode"
          :error="confirmCodeError"
        />
      </base-form-field>
    </div>

    <div :class='$style.next_button_wrap'>
      <base-button display='inline-block' width='100%' height='50px' @click="clickNext">
        次へ
      </base-button>
    </div>

    <base-modal
      v-if="showModal"
      type="round"
      hidenCloseBtn="true"
    >
      <template v-slot:header-title>設定完了</template>
      <template v-slot:body>
        <div :class="$style.modal_content">
          <p :class="$style.modal_content_description">ログイン情報を変更しました。</p>
          <img src="@/assets/images/modal_logo.png" :class="$style.modal_image"/>
          <base-button display='inline-block' rounded='rounded' width='100%' height='50px' @click="clickModalButton">
            HOME画面へ
          </base-button>
        </div>
      </template>
    </base-modal>
  </div>
</template>

<script>
import BaseInput from '@/components/base/BaseInput/BaseInput'
import BaseButton from '@/components/base/BaseButton/BaseButton'
import BaseFormField from '@/components/base/BaseFormField/BaseFormField'
import BaseModal from '@/components/base/BaseModal/BaseModal'
import AppPrevButton from '@/components/app/AppPrevButton/AppPrevButton'

export default {
  name: 'HomeChangeAuthConfirm',
  components: { BaseInput, BaseButton, BaseFormField, BaseModal, AppPrevButton },

  computed: {
    // Vuexストアからデータを取得
    authChangeData() {
      return this.$store.getters.getAuthChangeData;
    }
  },

  mounted() {
    this.$router.app.$emit('page-updated', {
      pageTitle: 'ログイン情報の変更',
      showSetting: false,
      back: this.prevRoute,
      bgColor: 'white',
      headerColor: 'yellow',
      showTabbar: false,
      showFooter: true, // this.isMobile
    });

    // Vuexストアからデータが取得できなければHomeChangeAuth画面に戻る
    if (!this.authChangeData.authChangeId || !this.authChangeData.changeCognitoId) {
      this.$router.replace({ name: 'HomeChangeAuth' });
    }
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from && from.name) {
        if (from.name == 'HomeChangeAuthRequested') {
          vm.prevRoute = { name: 'HomeChangeAuth' }
        } else {
          vm.prevRoute = from
        }
      } else {
        vm.prevRoute = '__history_back'
      }
    })
  },


  data() {
    return {
      prevRoute: '',
      inputConfirmCode: "",
      confirmCodeError: "",
      showModal: false,
    };
  },

  methods: {
    clickNext() {
      this.confirmCodeError = "";

      this.$axios.post('/api/' + this.$constants.API_VERSION + '/auth/change/regist', {
        "auth_change_id": this.authChangeData.authChangeId,
        "change_cognito_id": this.authChangeData.changeCognitoId,
        "confirm_code": this.inputConfirmCode,
      }).then(() => {
        this.showModal = true;
      }).catch((err) => {
        this.showModal = false;
        this.confirmCodeError = "確認コードを検証できませんでした。";
        err.response.data.value.errors.forEach(function(v) {
          this.confirmCodeError = v.message;
        }.bind(this));
      });
    },
    clickModalButton() {
      this.$router.replace({ name: 'HomeHome' });
    },
    backButtonAction(back) {
      if (back === '__history_back') {
        this.$router.go(-1);
      } else {
        this.$router.push(back, () => {});
      }
    },
  },
}
</script>

<style lang="scss" module>
.change_password {
  display: flex;
  flex-direction: column;
  min-width: 375px;
  margin: 0 auto;
  padding: 42px 32px 30px 32px;
  box-sizing: border-box;
  text-align: left;
}

.title {
  margin-top: 0;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 50px;
}

.note {
  font-size: 13px;
  color: $fontBlack;
  margin-top: 0;
  margin-bottom: 40px;
}

.input_group {
  & > * {
    margin-bottom: 24px;
  }
}

.next_button_wrap {
  margin-top: auto;
}

.modal_content {
  margin: 16px auto;
  width: 375px;
  text-align: center;
  @include mobile() {
    width: auto;
    margin: 16px;
  }
  &_description {
    font-size: 11px;
  }
  &_name {
    font-size: 16px;
    margin-left: 16px;
  }
  h2 {
    font-size: 14px;
  }
  section:first-of-type {
    margin-bottom: 56px;
  }
}

.modal_image {
  width: 260px;
  height: 260px;
}
</style>
