<template>
  <div>
    <h1>テスト</h1>
    <p>{{ result }}</p>
    <p>{{ login_data.access_token }}</p>
    <p>{{ login_data.expired_at }}</p>
    <router-link :to="{ name: 'Index' }">トップに戻る</router-link>
  </div>
</template>

<script>
export default {
  components: {},
  head: {
    title: {
      inner: 'Test',
    },
  },
  data: () => ({
    result: '',
    login_data: {
      access_token: ''
    },
  }),
  created: async function () {
    // *** Sample code ***

    // Axios
    await this.$axios
      .get(
        'https://jsonplaceholder.typicode.com/users/' + this.$constants.USER_ID
      )
      .then((response) => {
        this.$loginfo(response.data)
        this.result = response.data
      })
      .catch((err) => {
        // Exceptions not caught are handled by errorHandler
        this.$logerror(err)
        this.result = err.response
      })

    // Store
    this.$store.commit('sample/addCount', 1)
    this.$loginfo(this.$store.state.sample.count)

    // UUID
    const uuid = this.$uuidv4()
    this.$loginfo(uuid)

    // Cookie
    this.$cookies.set('UUID', uuid)
    this.$loginfo(this.$cookies.get('UUID'))

    if (!this.$cookies.get('auth_info')) {
      await this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/login', {
          "tel_or_email": "",
          "password": "?",
          "device_info": "Web"
        })
        .then((response) => {
          let auth_info = response.data.value
          auth_info['expired_at'] = this.$dayjs().add(auth_info.expires_in, 's').format()

          this.$cookies.set('auth_info', auth_info)

          this.$loginfo(auth_info)

          this.login_data = response.data.value
        })
        .catch(() => {})
    } else {
      this.$loginfo(this.$cookies.get('auth_info'))
      this.login_data = this.$cookies.get('auth_info')
    }
  },
}
</script>
