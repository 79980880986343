<template>
  <div :class='$style.registration'>
    <div v-if="!processing">
      <h1 :class='$style.title'>新規登録</h1>
      <p :class='$style.note'>くみあいアプリは、確認のために携帯電話、または Eメールアドレスにメッセージを送信します。</p>

      <div :class='$style.input_group'>

        <base-tab
          tab-color="green"
          :tab="current_tab"
          @tab="tabChange"
        >
          <template v-slot:[TAB_TEL]>
            <div :class="$style.tab_tel">
              <dl :class="$style.tab_input_group">
                <div :class="$style.tab_input_group_item">
                  <dt>国/地域</dt>
                  <dd :class="$style.dd_country">
                    <base-select
                      name="country"
                      :options="country_options"
                      look="border"
                      v-model="user.country"
                    />
                  </dd>
                </div>
                <div :class="$style.tab_input_group_item">
                  <dt>電話番号</dt>
                  <dd :class="$style.dd_country_code">
                    <base-input
                      name="country_code"
                      look="border"
                      v-model="user.country"
                      maxlength="4"
                      readonly
                    />
                  </dd>
                  <dd :class="$style.dd_tel">
                    <base-input
                      name="contact"
                      look="border"
                      v-model="user.tel"
                      :error="v$.user.tel.$invalid && v$.user.tel.$dirty || current_tab == TAB_TEL && !!isFieldErrorMsg('tel_or_email', errorMsgList)"
                      maxlength="26"
                      autocomplete="off"
                    />
                  </dd>
                  <dd v-if="v$.user.tel.$invalid && v$.user.tel.$dirty">
                    <p :class="$style.dd_error_message">{{ v$.user.tel.$errors[0].$message }}</p>
                  </dd>
                  <dd v-else-if="current_tab == TAB_TEL && !!isFieldErrorMsg('tel_or_email', errorMsgList)">
                    <p :class="$style.dd_error_message">{{ isFieldErrorMsg('tel_or_email', errorMsgList)  }}</p>
                  </dd>
                </div>
                <div :class="$style.tab_input_group_item">
                  <dt>パスワード</dt>
                  <dd>
                    <base-input
                      name="password"
                      type="password"
                      look="border"
                      v-model="user.password"
                      :error="v$.user.password.$invalid && v$.user.password.$dirty"
                      maxlength="256"
                      autocomplete="new-password"
                    />
                  </dd>
                  <dd v-if="v$.user.password.$invalid && v$.user.password.$dirty">
                    <p :class="$style.dd_error_message">{{ v$.user.password.$errors[0].$message }}</p>
                  </dd>
                </div>
              </dl>
            </div>
          </template>

          <template v-slot:[TAB_MAIL]>
            <div :class="$style.tab_mail">
              <dl :class="$style.tab_input_group">
                <div :class="$style.tab_input_group_item">
                  <dt :class="$style.dt_mail">メールアドレス</dt>
                  <dd :class="$style.dd_mail">
                    <base-input
                      name="contact"
                      look="border"
                      v-model="user.email"
                      :error="v$.user.email.$invalid && v$.user.email.$dirty || current_tab == TAB_MAIL && !!isFieldErrorMsg('tel_or_email', errorMsgList)"
                      maxlength="255"
                    />
                  </dd>
                  <dd v-if="v$.user.email.$invalid && v$.user.email.$dirty">
                    <p :class="$style.dd_error_message">{{ v$.user.email.$errors[0].$message }}</p>
                  </dd>
                  <dd v-else-if="current_tab == TAB_MAIL && !!isFieldErrorMsg('tel_or_email', errorMsgList)">
                    <p :class="$style.dd_error_message">{{ isFieldErrorMsg('tel_or_email', errorMsgList)  }}</p>
                  </dd>
                </div>
                <div :class="$style.tab_input_group_item">
                  <dt :class="$style.dt_mail">メールアドレス<br/>※確認用の再入力</dt>
                  <dd :class="$style.dd_mail">
                    <base-input
                      name="contact"
                      look="border"
                      v-model="user.email_confirmation"
                      :error="v$.user.email_confirmation.$invalid && v$.user.email_confirmation.$dirty"
                      maxlength="255"
                      autocomplete="off"
                    />
                  </dd>
                  <dd v-if="v$.user.email_confirmation.$invalid && v$.user.email_confirmation.$dirty">
                    <p :class="$style.dd_error_message">{{ v$.user.email_confirmation.$errors[0].$message }}</p>
                  </dd>
                </div>
                <div :class="$style.tab_input_group_item">
                  <dt>パスワード</dt>
                  <dd>
                    <base-input
                      name="password"
                      type="password"
                      look="border"
                      v-model="user.password"
                      :error="v$.user.password.$invalid && v$.user.password.$dirty ? v$.user.password.$errors[0].$message : ''"
                      maxlength="256"
                      autocomplete="new-password"
                    />
                  </dd>
                </div>
              </dl>
            </div>
          </template>
        </base-tab>

        <p
        v-if="current_tab == TAB_TEL"
        :class='$style.note'>国番号:+81<br>
        電話番号:090-1234-5678<br>
        →入力例:+819012345678</p>

        <div :class="$style.check_box_wrap">
          <base-check-box
            name="terms_of_service_flg"
            v-model="user.terms_of_service_flg"
            :error="
              v$.user.terms_of_service_flg.$invalid && v$.user.terms_of_service_flg.$dirty
                ? v$.user.terms_of_service_flg.$errors[0].$message
                : ''
            "
          >
            <div :class="$style.note_terms_of_service">
              <a
                href="/open/teams_of_service"
                :class="$style.a">
                利用規約に同意する
              </a>
            </div>
          </base-check-box>
          <base-check-box
            name="privacy_policy_flg"
            v-model="user.privacy_policy_flg"
            :error="
              v$.user.privacy_policy_flg.$invalid && v$.user.privacy_policy_flg.$dirty
                ? v$.user.privacy_policy_flg.$errors[0].$message
                : ''
            "
          >
            <div :class="$style.note_terms_of_service">
              <a
                href="https://www.j-union.co.jp/privacy/"
                target="_blank"
                rel="noopener noreferrer"
                :class="$style.a">
                プライバシーポリシーに同意する
              </a>
            </div>
          </base-check-box>
        </div>
      </div>

      <template v-if="hasError(errorMsgList) && !hasFieldErrorMsg(errorMsgList, ['no_attribute'])">
        <base-message
        type="error" v-for="(error, i) in errorMsgList" :key="i"
        :class="$style.msg_area">{{error.message}}</base-message>
      </template>

      <div :class="$style.next_button_wrap">
        <base-button
          display="inline-flex"
          width="100%"
          height="50px"
          @click="registration"
          :disabled="processing"
          :bgColor="user.terms_of_service_flg && user.privacy_policy_flg ? 'green': 'gray'"
        >
          次へ
        </base-button>
      </div>
    </div>

    <div :class="$style.spinner" v-if="processing">
      <base-icon name="spinner" size="32px" spin />
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/base/BaseInput/BaseInput'
import BaseSelect from '@/components/base/BaseSelect/BaseSelect'
import BaseButton from '@/components/base/BaseButton/BaseButton'
import BaseCheckBox from '@/components/base/BaseCheckBox/BaseCheckBox'
import BaseMessage from '@/components/base/BaseMessage/BaseMessage'
import BaseTab from '@/components/base/BaseTab/BaseTab'
import BaseIcon from '@/components/base/BaseIcon/BaseIcon'
import useVuelidate from '@vuelidate/core'
import { validatePassword, getEditPhoneNumber } from "../../utils/helper";
import validate from 'simple-valid'
import { helpers, required } from '@vuelidate/validators'
import {isFieldErrorMsg, hasError, hasFieldErrorMsg} from '@/helper/validator.js'

const requiredTel = function(value) {
  if (this.current_tab == this.TAB_TEL) {
    return !!value && 0 < value.length;
  }
  return true
}

const requiredMail = function(value) {
  if (this.current_tab == this.TAB_MAIL) {
    return !!value && 0 < value.length;
  }
  return true
}

const requiredTeam = function() {
  if (!this.user.terms_of_service_flg) {
    return false;
  }
  return true
}

const requiredPrivacyPolicy = function() {
  if (!this.user.privacy_policy_flg) {
    return false;
  }
  return true
}

const tel = function(value) {
  if (this.current_tab == this.TAB_TEL) {
    const regexTel = /^\d{10,}$/;
    return regexTel.test(value);
  }
  return true
}

const email = function(value) {
  if (this.current_tab == this.TAB_MAIL) {
    const errors = validate.execute({email:value}, {email:'email'})
    return !errors.has()
  }
  return true
}

const emailConfirm = function(value) {
  if (this.current_tab == this.TAB_MAIL) {
    return value == this.user.email
  }
  return true
}

export default {
  name: 'Registration',
  components: { BaseInput, BaseSelect, BaseButton, BaseCheckBox, BaseMessage, BaseTab, BaseIcon},
  data() {
    const TAB_TEL = '電話番号で確認'
    const TAB_MAIL = 'メールアドレスで確認'
    return {
      TAB_TEL,
      TAB_MAIL,
      current_tab: TAB_MAIL,
      country_options: [
        { value: '+1', label: 'カナダ' },
        { value: '+1', label: 'アメリカ' },
        { value: '+34', label: 'スペイン' },
        { value: '+44', label: 'イギリス' },
        { value: '+52', label: 'メキシコ' },
        { value: '+55', label: 'ブラジル' },
        { value: '+62', label: 'インドネシア' },
        { value: '+63', label: 'フィリピン' },
        { value: '+65', label: 'シンガポール' },
        { value: '+66', label: 'タイ' },
        { value: '+81', label: '日本' },
        { value: '+82', label: '韓国' },
        { value: '+84', label: 'ベトナム' },
        { value: '+86', label: '中国' },
        { value: '+91', label: 'インド' },
        { value: '+852', label: '香港' },
        { value: '+886', label: '台湾' },
      ],
      user: {
        tel_or_email: this.$store.state.registration.tel_or_email,
        country: this.$store.state.registration.country ? this.$store.state.registration.country : '+81',
        tel: !this.$store.state.registration.is_email ? this.$store.state.registration.tel : '',
        email: this.$store.state.registration.is_email ? this.$store.state.registration.tel_or_email : '',
        email_confirmation: '',
        password: '',
        terms_of_service_flg: false,
        privacy_policy_flg: false,
      },
      errorMsgList: [],
      processing: false
    }
  },
  setup: function() {
    return { v$: useVuelidate() }
  },
  validations: function() {
    return {
      user: {
        tel: {
          required: helpers.withMessage('電話番号は、必ず指定してください。', requiredTel),
          tel: helpers.withMessage('ハイフンなしの電話番号の形式で指定してください。', tel),
          $autoDirty: true
        },
        email: {
          required: helpers.withMessage('メールアドレスは、必ず指定してください。', requiredMail),
          email: helpers.withMessage('Eメールの形式で指定してください。', email),
          $autoDirty: true
        },
        email_confirmation: {
          sameAsEmail: helpers.withMessage('入力したメールアドレスと同じものを入力してください', emailConfirm),
          $autoDirty: true
        },
        password: {
          required: helpers.withMessage('パスワードは、必ず指定してください。', required),
          // eslint-disable-next-line
          password: helpers.withMessage('パスワードには、10文字以上の英大文字・英小文字・数字・記号(^$*.[]{}()?"!@#%&/\,><\':;|_~`)それぞれを最低1文字ずつ含む文字列を指定してください。', validatePassword),
          $autoDirty: true
        },
        terms_of_service_flg: {
          required: helpers.withMessage('利用規約のチェックボックスにチェックを入れてください。', requiredTeam),
        },
        privacy_policy_flg: {
          required: helpers.withMessage('プライバシーポリシーのチェックボックスにチェックを入れてください。', requiredPrivacyPolicy),
        },
      }
    }
  },
  methods: {
    isFieldErrorMsg,
    hasError,
    hasFieldErrorMsg,

    tabChange(name) {
      this.current_tab = name
    },
    async registration() {
      // 利用規約同意フラグのために認証基盤側のAPIにサービス名を渡す
      const service_name = 'kumiai'
      // 利用規約のversion
      const terms_of_service_version = '1.0'

      // フロント側でエラーチェック
      this.v$.$validate()
      if(this.v$.$invalid) {
        return
      }

      if(this.processing){
        return
      }
      this.processing = true
      this.errorMsgList = []

      if (this.current_tab == this.TAB_TEL) {
        this.user.tel_or_email = getEditPhoneNumber(this.user.country + this.user.tel)
      } else {
        this.user.tel_or_email = this.user.email
      }

      var is_black = true
      var black_message = ''
      await this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/user/blacklist', {
          tel_or_email: this.user.tel_or_email,
        })
        .then((response) => {
          console.log(response.data)
          is_black = !!response.data.value.result
          black_message = response.data.value.message
        })

      // 認証基盤 ユーザー登録APIコール
      if (!is_black) {
        await this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/auth/user/registration', {
          tel_or_email: this.user.tel_or_email,
          password: this.user.password,
          terms_of_service_flg: this.user.terms_of_service_flg,
          invite_token: this.$store.state.invite.token,
          service_name: service_name,
          terms_of_service_version: terms_of_service_version,
        })
        .then((response) => {
          this.$store.commit('registration/setTelOrEmail',this.user.tel_or_email)
          this.$store.commit('registration/setTel',this.user.tel)
          this.$store.commit('registration/setPassword', this.user.password)
          this.$store.commit('registration/setJuid', response.data.value.juid)
          this.$store.commit('registration/setCountry', this.user.country)
          this.$store.commit('registration/setIsEmail', this.current_tab == this.TAB_MAIL)

          this.$router.push(`/open/verification_send/new`) // :modeにnewを指定
        }).catch(err => {
          if(err.response.status === 400 || err.response.status === 401){
            err.response.data.value.errors.forEach(error => {
              if (error.attribute) {
                this.errorMsgList.push(error)
              } else {
                if (this.current_tab == this.TAB_TEL
                  && error.message == '指定パラメータが不正です。') {
                  this.errorMsgList.push({
                    attribute: 'tel_or_email',
                    message: '電話番号の形式に誤りがあります。'
                  })
                }

                this.errorMsgList.push({
                  attribute: 'no_attribute',
                  message: error.message
                })
              }
            })
          }
        }).finally(() => {
          this.processing = false
        });
      } else {
        this.errorMsgList.push({
          attribute: 'tel_or_email',
          message: black_message
        })
        this.processing = false
      }
    },
  },
}
</script>
<style lang='scss' module>
.registration {
  display: flex;
  flex-direction: column;
  width: 375px;
  margin: 0 auto;
  padding: 42px 32px 30px 32px;
  box-sizing: border-box;
  text-align: left;
}

.title {
  margin-top: 0;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 50px;
}

.note {
  font-size: 13px;
  color: $fontBlack;
  margin-top: 0;
  margin-bottom: 30px;
}

.note_terms_of_service {
  font-size: 13px;
  color: $fontBlack;
  padding-top: 20px;
  margin-bottom: 20px;
}

.input_group {
  & > * {
    &:nth-child(n) {
      margin-bottom: 30px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  margin-bottom: 30px;
}

.next_button_wrap {
  //margin-top: auto;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}

.msg_area {
  width: 100% !important;
  margin-bottom: 10px;
}

.tab_tel, .tab_mail {
  background-color: $openBg;
  padding: 24px 16px 32px 12px;
}

.tab_input_group {
  width: 100%;
  padding: 0;
  margin: 0;

  &_item {
    display: flex;
    flex-wrap: wrap;
    font-size: 11px;

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    select {
      width: 100%;
    }

    dt, dd {
      margin: 0;
    }

    dt {
      width: 58px;
      display: flex;
      align-items: center;
      color: $fontGray;
      &.dt_mail {
        width: 116px;
      }
    }

    dd {
      &.dd_country {
        width: calc(100% - 58px);
        select {
          width: 100%;
        }
      }
      &.dd_country_code {
        width: 46px;
        margin-right: 12px;
        input {
          padding: 0 2px;
          color: $fontGray !important;
        }
      }
      &.dd_tel {
        width: 164px;
      }
      .dd_error_message {
        font-size: 13px;
        color: $keyRed;
        margin-top: 4px;
        margin-bottom: 0;
        padding: 0;
      }
      &.dd_mail {
        width: 164px;
      }
    }
  }
}
.a {
    display: block;
    color: $keyGreen;
    font-size: 13px;

    @include mobile() {
      font-size: 11px;
    }

    &:not(:last-child) {
      margin-bottom: 16px;

      @include mobile() {
        margin-bottom: 13px;
      }
    }
  }
</style>
