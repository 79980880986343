<template>
  <div :class='$style.ju_setting'>

      <h1 :class='$style.title'>ログイン</h1>
      <p :class='$style.note'>本人確認済のメールアドレス、または電話番号と設定したパスワードを入力してください</p>

      <div :class='$style.input_group'>

        <base-input
          name="contact"
          look="border"
          v-model="inputTelOrEmail"
          :error="v$.inputTelOrEmail.$invalid && v$.inputTelOrEmail.$dirty ? v$.inputTelOrEmail.$errors[0].$message : ''"
        >
          <template v-slot:prepend>
            <div :class='$style.input_prepend_contact'>
              <base-icon name='envelope' size='20px' color='green'/>
              <span :class='$style.input_prepend_contact_or'>OR</span>
              <base-icon name='mobile-alt' size='22px' color='green'/>
            </div>
          </template>
          <template v-slot:append>
            <base-icon
              v-if="!v$.inputTelOrEmail.$invalid && v$.inputTelOrEmail.$dirty"
              name="check"
              color="green"
            />
          </template>
        </base-input>

        <base-input
          name="password"
          :type="showPassword ? 'text' : 'password'"
          look="border"
          v-model="inputPassword"
          :error="v$.inputPassword.$invalid && v$.inputPassword.$dirty ? v$.inputPassword.$errors[0].$message : ''"
        >
          <template v-slot:prepend>
            <span :class='$style.input_prepend_password'>パスワード</span>
          </template>
          <template v-slot:append>
          <div :class='$style.input_prepend_contact' @click="toggleShowPassword">
            <base-icon
              :name="showPassword ? 'eye' : 'eye-slash'"
              color="black"
              :class="$style.cursor_pointer"
            />
            </div>
          </template>
        </base-input>
      </div>

      <div :class='$style.input_group_act_juid' v-show="is_act_login">
        <base-input
          name="act_juid"
          look="border"
          v-model="act_juid"
          :error="v$.act_juid.$invalid && v$.act_juid.$dirty ? v$.act_juid.$errors[0].$message : ''"
          maxlength="100"
        >
          <template v-slot:prepend>
            <span :class='$style.input_prepend_act_juid'>代行ログインJUID</span>
          </template>
        </base-input>
      </div>

      <div :class='$style.next_button_wrap'>
        <p v-if="login_error" :class="$style.error_txt">ログインに失敗しました。入力項目を見直してください。</p>
        <base-button display='inline-block' width='100%' height='50px' v-on:click="login">
          ログイン
        </base-button>
      </div>
      <div :class='$style.link_wrap'>
        <a href="/open/password_forget" :class="$style.link">ID・パスワードを忘れた方はこちら</a>
        <a href="/open/registration" :class="$style.link">新規作成はこちら</a>
      </div>
    </div>

</template>

<script>
import BaseInput from '@/components/base/BaseInput/BaseInput'
import BaseButton from '@/components/base/BaseButton/BaseButton'
import BaseIcon from '@/components/base/BaseIcon/BaseIcon'
import useVuelidate from "@vuelidate/core";
import {required, email, or, helpers} from "@vuelidate/validators";
import { addJapanInternationalCode,getEditPhoneNumber } from "../../utils/helper";

const requiredActJuid = function(value) {
  if (this.is_act_login) {
    return !!value && 0 < value.length;
  }
  return true
}

const loginTel = function(value) {
    const regexTel = /^(\+\d+)?\d{10,}$/;
    return regexTel.test(value);
}

export default {
  name: 'JuSetting',
  components: {
    BaseInput,
    BaseButton,
    BaseIcon
  },
  props: {
    is_act_login: {
      type: Boolean,
      default: false,
    },
  },
  data: function() {
    return {
      inputTelOrEmail: "",
      inputPassword: "",
      showPassword: false,

      act_juid: "",

      login_sending: false,
      login_error: false,
    }
  },
  setup: function() {
    return { v$: useVuelidate() }
  },
  validations: function() {
    return {
      inputTelOrEmail: {
        required: helpers.withMessage('メールアドレス、または電話番号の入力は必須です。', required),
        shouldBeChecked: helpers.withMessage('メールアドレス、または電話番号の形式で入力してください。', or(email, loginTel)),
        $autoDirty: true
      },
      inputPassword: {
        required: helpers.withMessage('パスワードの入力は必須です。', required),
        $autoDirty: true
      },
      act_juid: {
        required: helpers.withMessage('代行ログインJUIDの入力は必須です。', requiredActJuid),
        $autoDirty: true
      },
    }
  },
  methods: {
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    async login() {
      const result = await this.v$.$validate()

      if(! result){
        return
      }

      if(this.login_sending){
        return
      }
      //連投させないようにログイン中フラグを立てておく
      this.login_sending = true

      let tel_or_email = getEditPhoneNumber(addJapanInternationalCode(this.inputTelOrEmail))

      const post_data = {
          "tel_or_email": tel_or_email,
          "password": this.inputPassword,
          "device_info": navigator.userAgent
      }
      if (this.is_act_login) {
        post_data['act_juid'] = this.act_juid
      }

      await this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/login', post_data)
        .then((response) => {
          let auth_info = response.data.value

          this.$cookies.set('auth_info', {
            'tel_or_email': tel_or_email,
            'access_token': auth_info.access_token,
            'expired_at': this.$dayjs().add(auth_info.expires_in, 's').format(),
            'refresh_token': auth_info.refresh_token
          })

          this.$store.commit('auth/setTelOrEmail', tel_or_email)
          this.$store.commit('auth/setJuid', auth_info.juid)
          this.$store.commit('auth/setUserId', auth_info.user_id)

          this.switchNextRouting()
        })
        .catch(() => {
          this.login_error = true
        })

      //ログイン中フラグを元に戻す
      this.login_sending = false
    },
    switchNextRouting: async function(){
      //user_idがない場合は強制的にプロフィール登録へ飛ばす
      if(! this.$store.state.auth.user_id){
        await this.$router.push('/open/profile_edit')
        return //後ろの関数が走るのでreturnで終了
      }

      //tokenを持っているときはteamに飛ばす
      if(this.$store.state.invite.token){
        const invite_flow = await this.fetchInviteFlow(this.$store.state.invite.token)

        if(['team_admin_url', 'union_salon'].includes(invite_flow.type)){
          await this.$router.push('/team/info/' + invite_flow.team_id)
          return //後ろの関数が走るのでreturnで終了
        }else if (invite_flow.type === 'team_admin'){
          await this.$router.push('/team/create/')
          return //後ろの関数が走るのでreturnで終了
        }else {
          // 招待フローがない場合はホームに飛ばす
          await this.$router.push('/home/home')
          return //後ろの関数が走るのでreturnで終了
        }
      }

      //ページ遷移
      const route = this.$route.query.redirect
      if(route){
        await this.$router.push(route)
      }else{
        await this.$router.push('/home/home')
      }
    },
    fetchInviteFlow: async function(token){
      let invite_flow = {}

      await this.$axios('/api/' + this.$constants.API_VERSION + '/invite/info', {
        params: {
          token: token
        }
      }).then((res) => {
        invite_flow = res.data.value
      }).catch(() => {
      })

      return invite_flow
    },
  }
}
</script>
<style lang='scss' module>
.ju_setting {
  display: flex;
  flex-direction: column;
  width: 375px;
  margin: 0 auto;
  padding: 42px 32px 30px 32px;
  box-sizing: border-box;
  text-align: left;
}

.title {
  margin-top: 0;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 50px;
}

.note {
  font-size: 13px;
  color: $fontBlack;
  margin-top: 0;
  margin-bottom: 60px;
}

.input_group {
  & > * {
    &:nth-child(1) {
      margin-bottom: 54px;
    }
  }
  margin-bottom: 30px;

  &_act_juid {
    margin-top: 30px;
  }
}

.input_prepend {

  &_contact, &_password {
    font-size: 11px;
    color: $fontBlack !important;
  }

  &_contact {
    margin-right: 0px;

    &_or {
      color: $KeyDarkGreen;
      font-weight: bold;
      margin-left: 2px;
    }

  }

  &_password {
    margin-right: 0px;
  }

}

.next_button_wrap {
  margin-top: 50px;
}

.link_wrap {
  text-align: center;
}
.link {
  display: block;
  font-size: 13px;
  line-height: 30px;
  color: $fontBlack;
  margin-top: 0;
  margin-bottom: 0px;
}


$textSize_Error: 13px;
$textSpecing_Error: 4px;
$textColor_Error: #ff0000;

.error_txt {
  font-size: $textSize_Error;
  margin: $textSpecing_Error 0 0 0;
  color: $textColor_Error;
}
.cursor_pointer {
  cursor: pointer;
}
</style>
