<template>
  <section :class="$style.profile">
    <app-prev-button margin="10px 10px 0px" @click="$router.push('/team/' + team_id + '/manage-member')" />
    <div :class="$style.profile_action">
      <p v-if="button_types.approval">「{{ team.name }}」への参加申請日: {{ user_team.application_date }}</p>
      <p v-else>「{{ team.name }}」への参加日: {{ user_team.participation_date }}</p>
      <div :class="$style.profile_action_buttons">

        <base-button
          v-if="button_types.approval"
          :class="$style.profile_action_button"
          width="200px"
          rounded="rounded"
          color="white"
          bordered
          @click="updateTeamUser('not_user', 'not_approval')"
        >承認しない</base-button>
        <base-button
          v-if="button_types.approval"
          :class="$style.profile_action_button"
          width="200px"
          rounded="rounded"
          @click="approve"
        >承認する</base-button>

        <base-button
          v-if="button_types.remove_member"
          :class="$style.profile_action_button"
          width="200px"
          rounded="rounded"
          @click="withdrawal"
        >メンバーから除く</base-button>
        <base-button
          v-if="button_types.force_remove_member"
          :class="$style.profile_action_button"
          width="200px"
          rounded="rounded"
          @click="expulsion"
        >強制退会にする</base-button>

        <base-button
          v-if="button_types.joint_admin"
          :class="$style.profile_action_button"
          width="200px"
          rounded="rounded"
          @click="updateTeamUser('admin_auth')"
        >共同管理者にする</base-button>

        <base-button
          v-if="button_types.remove_joint_admin"
          :class="$style.profile_action_button"
          width="200px"
          rounded="rounded"
          @click="updateTeamUser('user')"
        >共同管理者から除く</base-button>

        <base-button
          v-if="button_types.admin"
          :class="$style.profile_action_button"
          width="200px"
          rounded="rounded"
          @click="updateTeamUser('admin')"
        >管理者にする</base-button>

        <base-button
          v-if="button_types.remove_admin"
          :class="$style.profile_action_button"
          width="200px"
          rounded="rounded"
          @click="updateTeamUser('user')"
        >管理者から除く</base-button>

      </div>
      <p :class="$style.error_txt" v-if="err_message">{{ err_message }}</p>
    </div>

    <div class="$style.member_profile">

      <div :class="$style.member_avatar">
        <base-avatar :class="$style.member_avatar_image" size="120px" :path="user.profile_image_path ? user.profile_image_path : NoImage" />
      </div>

      <h1 :class="$style.member_nickname">{{ user.nickname }}</h1>
      <p :class="$style.member_name" v-if="user.display_name_flg">{{ user.name }}（{{ user.furigana }}）</p>

      <div :class="$style.member_meta_wrap">
        <dl :class="$style.member_meta">
          <dt>所属:</dt>
          <dd>{{ team_list_value.affiliation }}</dd>
          <dt>ju-ID:</dt>
          <dd>{{ user.juid }}</dd>
        </dl>
      </div>

      <div :class="$style.member_comment">
        <p>{{ user.open_profile }}</p>
      </div>

    </div>

      <base-accordion
        :class="$style.member_myteam"
        title="Myチーム"
        type="borderless"
        :expanded="true"
      >
        <div :class="$style.member_myteam_list">
          <div v-for="(belong_team, i) in team_list_value.team_list" :class="$style.member_myteam_item" :key="i">
            <app-carousel-team-item
              category="life"
              :head="belong_team.name ? belong_team.name : 'ヘッダー'"
              :image="belong_team.path ? belong_team.path : 'https://via.placeholder.com/140x50'"
              :border-color="belong_team.team_color ? `rgb(${belong_team.team_color})` : '#7BE293'"
              @click="$router.push('/team/info/' + belong_team.team_id)"
            />
          </div>
        </div>
      </base-accordion>
    <div>

    </div>

  </section>
</template>
<script>
import BaseButton from "@/components/base/BaseButton/BaseButton";
import BaseAvatar from "@/components/base/BaseAvatar/BaseAvatar";
import BaseAccordion from "@/components/base/BaseAccordion/BaseAccordion";
import AppCarouselTeamItem from "@/components/app/AppCarousel/TeamItem/AppCarouselTeamItem";
import AppPrevButton from '@/components/app/AppPrevButton/AppPrevButton';
import NoImage from '@/assets/images/account-circle.svg';
export default {
  name: 'TeamMemberProfile',
  components: {
    AppCarouselTeamItem,
    BaseAccordion,
    BaseAvatar,
    BaseButton,
    AppPrevButton
  },
  props: {
    team_id: {
      type: Number,
      required: true
    },
    user_id: {
      type: Number,
      required: true
    }
  },
  mounted() {
    this.$router.app.$emit('page-updated', {
      pageTitle: 'プロフィール',
      showSetting: false,
      back: '/team/' + this.team_id + '/manage-member',
      headerColor: 'white',
      bgColor: 'white'
    })
  },
  data() {
    return {
      NoImage,
      user: {},
      team: {},
      user_team: {},
      team_list_value: {},
      button_types: {},

      err_message: '',

      posting: false,
    }
  },
  created: async function(){
    this.user = await this.fetchUserInfo(this.user_id)
    this.team = await this.fetchTeamInfo(this.team_id)
    this.user_team = await this.fetchUserTeamInfo(this.user_id, this.team_id)
    this.team_list_value = await this.fetchTeamList(this.user_id)
    this.button_types = await this.fetchButtonType(this.user_id, this.team_id)
  },
  methods: {
    fetchUserInfo: async function(user_id){
      let user = {}

      await this.$axios.get('/api/' + this.$constants.API_VERSION + '/user/info', {
        params: {
          user_id: user_id
        }
      }).then((res) => {
        user = res.data.value.user
      })

      return user
    },
    fetchTeamInfo: async function(team_id){
      let team = {}

      await this.$axios.get('/api/' + this.$constants.API_VERSION + '/team/info', {
        params: {
          team_id: team_id
        }
      }).then((res) => {
        team = res.data.value.team
      })

      return team
    },
    fetchUserTeamInfo: async function(user_id, team_id){
      let user_team = {}

      await this.$axios.get('/api/' + this.$constants.API_VERSION + '/team/user/info', {
        params: {
          user_id: user_id,
          team_id: team_id
        }
      }).then((res) => {
        user_team = res.data.value.user_team
      })

      return user_team
    },
    fetchTeamList: async function(user_id){
      let team_list_value

      let params = {
          "user_id": user_id,
          "search_type": "login",
          "sort": "name",
          "direction": "asc",
          "limit": 500
      }

      if(this.$store.state.auth.user_id !== this.user_id){
          // IRET_JUNION_UNI_VERSE-1882
          // 他のユーザーからプロフィールを表示した場合は検索対象としないチームは表示対象外
          params.open_flg = 1;
        }

      await this.$axios.get('/api/' + this.$constants.API_VERSION + '/team/list', { params }).then((res) => {
        team_list_value = res.data.value
      })

      return team_list_value
    },
    fetchButtonType: async function() {
      let button_types = ""

      await this.$axios.post('/api/' + this.$constants.API_VERSION + '/user/profile/button', {
        user_id: this.user_id,
        team_id: this.team_id
      }).then((res) => {
        button_types = res.data.value
      })

      return button_types
    },
    approve: async function() {
      if (this.posting) return
      this.posting = true
      await this.$axios.post('/api/' + this.$constants.API_VERSION + '/team/user/approve', {
        user_id_list: [this.user_id],
        team_id: this.team_id
      })
      .then(() => {
        this.posting = false
        this.goManageMemberPage()
      })
      .catch((err) => {
        this.posting = false
        this.err_message = err.response.data.value.errors[0].message
      })
    },
    expulsion: async function () {
      if (this.posting) return
      var confirm_result = confirm('本当に強制退会させますか。\n強制退会すると二度とチーム参加ができなくなります。\n再度チーム参加する事がある場合、「メンバーから除く」を選択してください。');
      if(!confirm_result) {
          return
      }
      this.posting = true
      await this.$axios.post('/api/' + this.$constants.API_VERSION + '/team/user/remove', {
        user_id: this.user_id,
        team_id: this.team_id
      })
      .then(() => {
        this.posting = false
        this.goManageMemberPage()
      })
      .catch((err) => {
        this.posting = false
        this.err_message = err.response.data.value.errors[0].message
      })
    },
    withdrawal: async function () {
      if (this.posting) return
      var confirm_result = confirm('本当にメンバーから除きますか。');
      if(!confirm_result) {
        return
      }
      this.updateTeamUser('not_user', 'withdrawal')
    },
    async updateTeamUser(auth_type = '', status = '') {
      if (this.posting) return
      this.posting = true
      this.$router.app.$emit('show-spinner', true)

      this.err_message = ''

      let team_user = {
        user_id: this.user_id,
        team_id: this.team_id
      }

      if(auth_type) team_user['auth_type'] = auth_type
      if(status) team_user['status'] = status

      await this.$axios.post('/api/' + this.$constants.API_VERSION + '/team/user/registration', {
        team_user_list: [team_user]
      }).catch((err) => {
        this.err_message = err.response.data.value.errors[0].message
      })

      this.posting = false
      this.$router.app.$emit('show-spinner', false)

      if(this.err_message){
        return
      }

      this.goManageMemberPage()
    },
    goManageMemberPage(){
      this.$router.push(`/team/${this.team_id}/manage-member`)
    }
  }
}
</script>
<style lang="scss" module>
.profile {
  @include pc() {
    width: 800px;
    margin: auto;
  }
}
.profile_action {
  padding: 32px 0;
  text-align: center;
  font-size: 9px;
  border-bottom: 1px solid #ddd;
  margin: 0 32px;
  p {
    margin-bottom: 16px;
  }
  &_buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 1080px) {
      flex-direction: column;
      gap: 6px
    }
  }
  &_button {
    margin: 0 10px;
  }
  &_profile {

  }
}
.member_avatar {
  margin: 16px;
  &_image {
    margin: auto;
  }
}
.member_nickname {
  font-size: 30px;
  color: $fontBlack;
  margin: 30px 0 4px;
  text-align: center;
  @include mobile(){
    margin: 20px 0 4px;
    font-size: 20px;
  }
}
.member_name {
  font-size: 13px;
  color: $fontBlack;
  text-align: center;
  margin: 0;
  @include mobile(){
    font-size: 10px;
  }
}
.member_meta_wrap {
}
.member_meta {
  font-size: 13px;
  color: $fontGray;
  line-height: 24px;
  margin: 30px 32px 42px;
  @include mobile(){
    font-size: 10px;
    margin: 24px 32px;
  }

  dt {
    width: 80px;
    float: left;
  }
  dd {
    margin: 0;
    overflow: hidden;
  }
}
.member_comment {
  font-size: 13px;
  color: $fontBlack;
  margin: 30px 32px 30px;
  @include mobile(){
    font-size: 11px;
  }
}
.member_myteam {
  margin: 24px 32px;
}
.member_myteam_list {
  margin: 30px 0 0;
  display: flex;
  flex-wrap: wrap;

  @include mobile() {
    margin: 10px 0 0;
  }

  // 右端下端のマージンを打ち消す。ピッタリだと影が消えるので少し小さく取る
  margin-right: -32px;
  margin-bottom: -10px;
  // @include mobile(){
  //   margin-right: -40px;
  //   margin-bottom: -20px;
  // }

  .member_myteam_item {
    margin-right: 20px;
    margin-bottom: 14px;
  }
}
.member_myteam_list::after {
  content: '';
  display: block;
  width: 80px;
}

$textSize_Error: 13px;
$textSpecing_Error: 4px;
$textColor_Error: #ff0000;

.error_txt {
  font-size: $textSize_Error;
  margin: $textSpecing_Error 0 0 0;
  color: $textColor_Error;
  white-space: pre-wrap;
}
</style>
