<template>
  <section :class="$style.article" v-bind="$attrs" @click="handleSelect" :style="customStyle">
    <div style="position: relative;">
      <base-avatar size="100px" :path="image" :border-color="borderColor" :outline-color="outlineColor" />
      <base-tag v-if="showBadge" style="position: absolute; top: 80px; left: 60px;" bg-color="pink" font-size="m" height="20px" width="40px">
        表示中
      </base-tag>
    </div>
    <h3 :class="$style.head">
      <slot name="head">
        {{head.length > 20 ? head.slice(0,20) + '...' : head}}
      </slot>
    </h3>
  </section>
</template>

<script>
import BaseAvatar from '../../../base/BaseAvatar/BaseAvatar'
import BaseTag from '../../../base/BaseTag/BaseTag'

export default {
  name: 'AppCarouselTeamItem',
  components: { BaseAvatar, BaseTag },
  props: {
    image: {
      type: String,
    },
    head: {
      type: String,
    },
    borderColor: {
      type: String,
      default: '#FFFFFF',
    },
    showBadge: {
      type: Boolean,
      default: false
    },
    outlineColor: {
      type: String,
      default: undefined,
    },
    isCursorPointer: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleSelect() {
      // 選択イベントが発火するように設定
      // this.$emit('select', !this.selected)
      this.$emit('click', this.item)
    },
  },
  computed: {
    customStyle() {
      let style = { }

      if(this.isCursorPointer){
        // trueの場合マウスオーバーでカーソルが指マークに変化
        style.cursor = 'pointer'
      }
      return style
    },
  }
}
</script>

<style lang="scss" module>
.article {
  width: 124px;
  height: 140px;
}

.head {
  display: block;
  color: #262626;
  line-height: 12px;
  font-size: 10px;
  font-weight: bold;
  // transform: scale(1.0);
  word-break: break-all;
  overflow: hidden;
}
</style>
