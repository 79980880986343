<template>
  <div>
    <team-header
      :team="dataTeam"
      :user_id="user_id"
      :belongsTo="belongsTo"
      :indexType="indexType"
      :informationDisplayNone="informationDisplayNone"
      :hidePcHeaderDetail="false"
      @update-good_flg="updateGoodFlg"
    />
    <div :class="$style.team_info">
      <app-prev-button margin="0 130px" @click="$router.go(-1)" />
      <section :class="$style.team_info_section">
        <h1 :class="$style.team_info_title">このチームについて</h1>
        <p :class="$style.team_info_description" v-html="autoLink(dataTeam.profile)"></p>
      </section>
      <section :class="$style.team_info_section">
        <h1 :class="$style.team_info_title">チーム運営ルール</h1>
        <ul :class="$style.team_info_rules">
          <li v-for="rule in dataTeam.rules" :key="rule">{{ rule }}</li>
        </ul>
      </section>
      <section :class="$style.team_info_section" v-if="dataTeam.url_type && dataTeam.url_type.indexOf(1) != -1">
        <h1 :class="$style.team_info_title">チームURL</h1>
        <div :class="$style.team_info_url">
          <p :class="$style.team_info_url_path">{{ dataTeam.url }}</p>
          <p :class="$style.team_info_url_link"><a @click="generateQr()" href="javascript: void(0)">コピー / QRコードを表示</a></p>
        </div>
      </section>
      <div
        :class="$style.team_info_application"
      >
        <base-button
          rounded="rounded"
          href="javascript: void(0)"
          @click="join()"
          v-if="!Object.keys(dataUserTeam).length || this.dataUserTeam.status == 'not_belong_team' || this.dataUserTeam.status == 'withdrawal' || this.dataUserTeam.status == 'not_approval'"
        >このチームに参加する</base-button>

        <base-button
          rounded="rounded"
          @click=this.goToTeamHome
          v-if="Object.keys(dataUserTeam).length && (this.dataUserTeam.status == 'approved' || this.dataUserTeam.status == 'no_approval_required') "
        >このチームを見る</base-button>
      </div>

      <template v-if="error_register_team_or_salon.length > 0">
        <base-message
          type="error" v-for="(error, i) in error_register_team_or_salon" :key="i"
          :class="$style.msg_area">{{error}}</base-message>
      </template>
    </div>


    <base-modal
      v-if="showQR"
      type="round"
      @close="showQR = false"
    >
      <template v-slot:header-title>このチームの QR コード</template>
      <template v-slot:body>
        <div :class="$style.modal_content">
          <div :class="$style.modal_content_qr">
            <vue-qrcode :value="qrUrl" tag="img"></vue-qrcode>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <base-button
          width="185px"
          rounded="rounded"
          @click = "copy()"
        >URL をコピーする</base-button>
      </template>
    </base-modal>

    <base-modal
      v-if="showCommonWord"
      type="round"
      @close="showCommonWord = false"
    >
      <template v-slot:header-title>チームへの参加</template>
      <template v-slot:body>
        <div :class="$style.modal_content">
          <p :class="$style.modal_content_description">このチームへ参加するには、<br />
            専用の「あいことば」が必要です。</p>

          <section>
            <h2>名前（自動入力）</h2>
            <p :class="$style.modal_content_name">{{ userName }}</p>
          </section>

          <section>
            <h2>あいことば</h2>
            <base-input
              :type="string"
              v-model="common_word"
              :error="v$.common_word.$invalid && v$.common_word.$dirty ? v$.common_word.$errors[0].$message : error_common_word ?  error_common_word : ''"
              />
          </section>

        </div>
      </template>
      <template v-slot:footer>
        <base-button
          width="185px"
          rounded="rounded"
          @click="sendCommonWord"
        >送信する</base-button>
      </template>
    </base-modal>

  <div :class="$style.spinner" v-if="initLoading">
    <base-icon name="spinner" size="32px" spin />
  </div>
  </div>
</template>
<script>

import BaseButton from "@/components/base/BaseButton/BaseButton";
import BaseInput from "@/components/base/BaseInput/BaseInput";
import BaseModal from "@/components/base/BaseModal/BaseModal";
import TeamHeader from "@/pages/team/components/TeamHeader";
import BaseIcon from '@/components/base/BaseIcon/BaseIcon'
import BaseMessage from '@/components/base/BaseMessage/BaseMessage'
import VueQrcode from "@chenfengyuan/vue-qrcode";
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import { mapGetters } from 'vuex'
import { autoLink } from '@/utils/helper.js'
import AppPrevButton from '@/components/app/AppPrevButton/AppPrevButton'

export default {
  name: 'TeamInfo',
  components: {BaseInput, BaseModal, BaseButton, BaseIcon, BaseMessage, TeamHeader, VueQrcode, AppPrevButton},
  computed: {
    ...mapGetters('auth', ['getUserId']),
    changeType() {
      return this.isCommonWordTypePassword ? "password" : "text"
    },
  },
  props: {
    team_id: {
      type: Number,
      required: true,
    },
  },
  async created() {
    this.initFetch()
    this.initShowCommonWord()
  },
  mounted() {
    this.$router.app.$emit('page-updated', {
      pageTitle: '',
      back: this.prevRoute,
      showSetting: true,
      headerColor: 'yellow'
    })
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  data() {
    return {
      user_id: null,
      userName: null,
      dataTeam: {
        good_flg: null,
        profile: null,
        rules: null,
        url_type: null,
        url: null,
      },
      dataUserTeam: {},
      informationDisplayNone: true,
      approvalFlg: null,
      chatAddFlg: null,
      commentAddFlg: null,
      miniVoteAddFlg: null,
      inviteFlg: null,
      publicStatus: null,
      initLoading: false,
      belongsTo: false,

      showJoin: true,
      showQR: false,
      qrUrl: null,
      common_word: '',
      error_common_word: '',
      error_register_team_or_salon: [],
      showCommonWord: false,
      isCommonWordTypePassword: true,
      token: this.$store.state.invite.token || this.$route.query.token,
      inviteType: null,
      inviteCourseId: null,
      inviteLectureId: null,
      sending: false,
      joinTeam: false,
      userTeam: {},
      prevRoute: ''
    }
  },
  setup: function() {
    return { v$: useVuelidate() }
  },
  validations: function() {
    return {
      common_word: {
          required: helpers.withMessage('あいことばは、必ず指定してください。', required),
          $autoDirty: true
      }
    }
  },
  methods: {
    async initShowCommonWord() {
      if (this.token) {
        // トークンをクリア、参照渡しでdata内のtokenも消えるので一旦変数に寄せてからdataに再代入してあげる
        const token = this.token
        this.$store.commit('invite/setToken','')
        this.token = token

        await this.$axios.get('/api/' + this.$constants.API_VERSION + '/team/user/info', {
          params: {
            team_id: this.team_id,
            user_id: this.user_id
          }
        }).then((res) => {
          this.userTeam = res.data.value?.user_team
        }).catch(() => {}) //ユーザーが存在しないとき400を返されて動作が止まるのでcatchを置いておく

        const status = ['approved', 'no_approval_required']
        const auth_type = ['admin', 'admin_auth', 'user']

        if (status.includes(this.userTeam.status) && auth_type.includes(this.userTeam.auth_type)) {
          this.joinTeam = true
        }

        // 招待フロー詳細取得APIコール
        this.$axios
          .get('/api/' + this.$constants.API_VERSION + '/invite/info', {
            params: {
              token: this.token
            }
          })
          .then((response) => {
            let team_id = response.data.value.team_id
            this.inviteType = response.data.value.type
            this.inviteCourseId = response.data.value.course_id
            this.inviteLectureId = response.data.value.lecture_id

            if (this.team_id == team_id && this.joinTeam == true) {
              // 組織に所属している場合

              if (this.inviteType != 'union_salon') {

                //トークン種別が union_salon ではない場合に20000-HOMEに遷移
                this.$router.push('/home/home')

              } else {

                const union_salon_user = {
                  user_id: this.getUserId,
                  course_id: this.inviteCourseId,
                  team_id: this.team_id,
                  status: 'use'
                }
                this.$loginfo(union_salon_user)

                // UNION-SALON登録ユーザー登録・更新API 実行
                this.$axios
                  .post('/api/' + this.$constants.API_VERSION + '/union_salon/user/registration', {
                    union_salon_user_list: [
                      union_salon_user
                    ]
                  })
                  .then(() => {
                    // 40000 - UNION SALONに遷移
                    this.$router.push(`/union/home`)
                  }).catch(err  => {
                    if (err.response.status === 400) {
                      this.error_register_team_or_salon.splice(0)
                      err.response.data.value.errors.forEach(error => {
                        this.error_register_team_or_salon.push(error.message)
                      })
                    } else {
                      throw new Error(err)
                    }
                  })

              }

            } else {
              // 招待のチームと画面表示のチームが同一かつ、組織に参加していない場合にあいことば画面を表示
              this.showCommonWord = true
            }
          })
      }
    },
    sendCommonWord() {
      this.v$.$validate()
      if(this.v$.$invalid){
        return
      }

      if(this.sending){
        return
      }
      this.sending = true
      this.error_common_word = ''
      let self = this

          let team_user = {
              team_id: this.team_id,
              common_word: self.common_word,
          };
          if (this.token) {
            team_user['token'] = this.token
          }
          // 組織ユーザー登録・更新API(あいことば)コール
          this.$axios
            .post('/api/' + this.$constants.API_VERSION + '/team/user/common_word', {
              team_user_list: [
                team_user
              ]
            })
            .then(() => {

              if (this.token) {
                if (this.inviteType == 'union_salon') {
                  const union_salon_user = {
                    user_id: this.getUserId,
                    course_id: this.inviteCourseId,
                    team_id: this.team_id,
                    status: 'use'
                  }
                  this.$loginfo(union_salon_user)

                  // UNION-SALON登録ユーザー登録・更新API 実行
                  this.$axios
                    .post('/api/' + this.$constants.API_VERSION + '/union_salon/user/registration', {
                      union_salon_user_list: [
                        union_salon_user
                      ]
                    })
                    .then(() => {
                      // UNION-SALONからの招待の場合は43000-講座に遷移
                      this.$router.push(`/union/home`)
                    }).catch(err  => {
                      if (err.response.status === 400) {
                        err.response.data.value.errors.forEach(error => {
                          this.error_register_team_or_salon.push(error.message)
                        })
                      } else {
                        throw new Error(err)
                      }
                    })
                } else {
                  // 30000-チームHOMEに遷移
                  this.$router.push(`/team/home/${this.team_id}`)
                }
              } else {
                // 招待トークンが無い場合は20000-HOMEに遷移
                this.$router.push(`/home/home`)
              }
              this.sending = false
            }).catch(err  => {
              if(err.response.status === 400){
                err.response.data.value.errors.forEach(error => {
                  self.error_common_word = error.message
                })
              }
              this.sending = false
            });
    },
    commonWordPasswordFocus() {
      this.isCommonWordTypePassword = false
    },
    commonWordPasswordBlur() {
      this.isCommonWordTypePassword = true
    },
    fetchUserInfo: function () {
      return this.$axios.get('/api/' + this.$constants.API_VERSION + '/user/info')
        .then((res) => {
          this.user_id = res.data?.value?.user?.user_id
          this.userName = res.data?.value?.user?.nickname
        })
    },
    fetchTeamInfo: function () {
      return this.$axios.get('/api/' + this.$constants.API_VERSION + '/team/info', {
        params: {
          team_id: this.team_id
        }
      })
        .then((res) => {
          this.dataTeam = res.data.value?.team
          this.dataTeam.team_color = "rgb(" + res.data.value?.team.team_color + ")"
          this.dataTeam.team_id = this.team_id
          if (this.dataTeam.url.length > 50) {
            // 長いURLの表示処理
            this.dataTeam.url = this.dataTeam.url.substr(0, 50) + "..."
          }
        })
        .catch((err) => {
          this.$router.push('/error/' +err.response.status)
        })
    },
    fetchUserTeamInfo: async function () {
      return await this.$axios.get('/api/' + this.$constants.API_VERSION + '/team/user/info', {
        params: {
          team_id: this.team_id,
          user_id: this.user_id
        }
      }).then((res) => {
        this.dataUserTeam = res.data.value?.user_team
      }).catch(() => {}) //ユーザーが存在しないとき400を返されて動作が止まるのでcatchを置いておく
    },
    async initFetch() {
      this.initLoading = true

      await Promise.all([
        this.fetchUserInfo(),
        this.fetchTeamInfo(),
      ])

      await this.fetchUserTeamInfo()
      if(Object.keys(this.dataUserTeam).length > 0
      && (this.dataUserTeam.status == 'approval' || this.dataUserTeam.status == 'no_approval_required')){
        this.belongsTo = true
      }

      this.initLoading = false
    },
    copy: function () {
      let url = location.href
      navigator.clipboard.writeText(url)
    },
    generateQr: function () {
      this.showQR = true

      this.qrUrl = location.href
    },
    join: function(){
      if (!confirm('チームに参加しますか？')) {
        return;
      }
      if(this.dataTeam.approval_flg){
        // 承認が必要な場合は/api/' + this.$constants.API_VERSION + '/team/user/apply:組織への所属申請API
        this.$axios.post('/api/' + this.$constants.API_VERSION + '/team/user/apply', {
            team_id: this.team_id,
            event_flg: 1
        }).then(() => {
          alert('チームへの参加を申請しました')
          //ページ遷移
          this.$router.push('/home/home')
        }).catch(err  => {
            if (err.response.status === 400) {
              err.response.data.value.errors.forEach(error => {
                this.error_register_team_or_salon.push(error.message)
                  })
              }
            })
      }else{
        this.showCommonWord = true
      }
    },
    goToTeamHome: function () {
      // 素早くWクリックした際のNavigationDuplicatedエラー防止のため、同じパスに遷移できないようにしている
      if (this.$route.path != `/team/home/${this.team_id}`) {
        this.$router.push(`/team/home/${this.team_id}`)
      }
    },
    autoLink
  },
}
</script>
<style lang="scss" module>
.team_info {
  max-width: 980px;
  margin: 0 auto;
  padding-top: 25px;
  background-color: white;
  @media (max-width: 768px) {
    background: #FFFBE4;
  }
  &_section {
    padding: 16px 130px;
    border-bottom: 1px solid #ddd;
    &:last-of-type {
      border-bottom: none;
    }
    @include mobile() {
      padding: 0 16px;
    }
  }
  &_title {
    font-size: 9px;
    font-weight: bold;
  }
  &_description {
    font-size: 13px;
    margin: 20px 0 0;
    white-space: pre-wrap;
  }
  &_rules {
    font-size: 9px;
  }
  &_url {
    display: flex;
    font-size: 9px;
    &_path {
      flex: 1;
    }
    &_link {
      flex: 1;
      a {
        color: $KeyDarkGreen;
      }
    }
  }
  &_application {
    text-align: center;
  }
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}

.modal_width {
  width: 800px;
  @include mobile() {
    width: 80%;
  }
}
.modal_content {
  margin: 16px auto;
  width: 400px;
  @include mobile() {
    width: auto;
    margin: 16px;
  }
  &_description {
    font-size: 11px;
  }
  &_name {
    font-size: 16px;
    margin-left: 16px;
  }
  h2 {
    font-size: 14px;
  }
  section:first-of-type {
    margin-bottom: 56px;
  }
  &_qr {
    text-align: center;
    margin: 80px 0;
  }
}

.msg_area {
  width: 100% !important;
  margin: 0 0 10px;
}
</style>
