var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.wrapper},[(_vm.showPost)?_c('base-circle-button',{attrs:{"size":"70px","bgColor":"green","color":"white","bordered":true,"shadow":true},on:{"click":function () { return _vm.$emit('post'); }}},[_c('div',{style:({
        'display':'flex',
        'flex-direction':'column',
        'align-items': 'center'
      })},[_c('span',{style:({'padding-bottom': '4px'})},[_c('base-icon',{attrs:{"size":"17px","name":"pencil-alt","color":"yellow"}})],1),_c('span',{style:({'line-height':'16px'})},[_vm._v("投稿")])])]):_vm._e(),(_vm.showVote)?_c('base-circle-button',{attrs:{"size":"70px","bgColor":"green","color":"white","bordered":true,"shadow":true},on:{"click":function () { return _vm.$emit('vote'); }}},[_c('div',{style:({
        'display':'flex',
        'flex-direction':'column',
        'align-items': 'center'
      })},[_c('span',{style:({'padding-bottom': '4px'})},[_c('base-icon',{style:({'padding-right':'4px'}),attrs:{"size":"12px","name":"check-square","color":"yellow"}}),_c('base-icon',{attrs:{"size":"12px","type":"far","name":"square","color":"yellow"}})],1),_c('span',{style:({'line-height':'16px'})},[_vm._v("ミニ投票")])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }