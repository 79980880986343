<template>
  <div :class="$style.score_chart">
    <div :class="$style.inside">
      <span :class="$style.inside_num">{{ scoreNum }}</span>
      <span :class="$style.inside_bar">
        <span :class="$style.inside_bar_point" :style="scoreRatio"></span>
      </span>
      <img src="@/assets/images/triangle.svg" :class="$style.inside_tri" />
      <span :class="$style.inside_ave">AVERAGE</span>
    </div>
    <doughnut-chart :chart-data="pieData" :options="pieOptions" />
  </div>
</template>

<script>
import DoughnutChart from '../DoughnutChart'
import { delimit } from "../../../../utils/helper";

export default {
  components: {
    DoughnutChart,
  },
  props: {
    data: {
      type: Array,
    },
    labels: {
      type: Array,
    },
    score: {
      type: Number,
    },
    average: {
      type: Number,
    },
  },
  computed: {
    pieData() {
      return {
        labels: this.labels,
        datasets: [
          {
            label: "app-score-chart",
            data: this.data,
            backgroundColor: ["#7BE293", "#75B5E2", "#FFA098", "#F7DE64"],
            borderWidth: 0,
          },
        ],
      };
    },
    pieOptions() {
      return {
        legend: {
          display: false
        }
      };
    },
    scoreNum() {
      return delimit(this.score);
    },
    scoreRatio() {
      let ratio = ((this.score / this.average) * 100);
      if (200 < ratio) {
        ratio = 200;
      }
      ratio = ratio / 2 * 0.9
      return { left: ratio + "%" };
    },
  },
};
</script>

<style lang="scss" module>
.score_chart {
  position: relative;
  width: 300px;
  height: 300px;

  @include mobile() {
    width: 200px;
    height: 200px;
  }

  .inside {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 150px;
    height: 150px;
    top: 75px;
    left: 75px;
    border-radius: 50%;
    z-index: 1;

    @include mobile() {
      width: 100px;
      height: 100px;
      top: 50px;
      left: 50px;
    }
  }

  .inside_bar {
    width: 150px;
    margin-top: 6.5px;
    margin-bottom: 6.5px;
    border-top: 1px dotted $borderLiteGray;
    height: 1px;
    box-sizing: border-box;

    @include mobile() {
      width: 100px;
      margin-top: 4.5px;
      margin-bottom: 4.5px;
    }

    .inside_bar_point {
      content: "";
      display: block;
      background: $keyGreen;
      border-radius: 50%;
      position: relative;

      width: 15px;
      height: 15px;
      top: -7.5px;

      @include mobile() {
        width: 10px;
        height: 10px;
        top: -5px;
      }
    }
  }

  .inside_num {
    font-size: 29px;
    @include mobile() {
      font-size: 20px;
    }
  }

  .inside_tri {
    margin-top: 6px;
    transform: scale(1.5);
    @include mobile() {
      margin-top: 4px;
    }
  }

  .inside_ave {
    font-weight: bold;
    font-size: 13px;
    transform: none;

    @include mobile() {
      font-size: 10px;
      transform: scale(0.9);
    }
  }

  canvas {
    position: relative;
    z-index: 2;
  }
}
</style>
