<template>
  <li :class="$style.menuTitle"><slot /></li>
</template>
<script>
export default {
  name: 'BaseOverlayMenuListTitle'
}
</script>
<style lang="scss" module>
.menuTitle {
  font-size: 11px;
  font-weight: bold;
  color: #262626;
  margin: 10px 20px;
}
</style>
