<template>
  <div :class="$style.team_info">
    <!-- 左エリア -->
    <div :class="$style.team_info__header">
      <!-- アイコン -->
      <div :class="$style.team_info__badge">
        <img
          :src="badgeImage"
          :style="imgStyleObject"
          @click="
            $router.push({ name: 'TeamInfo', params: { team_id: team_id } })
          "
        />
      </div>
      <!-- 設定ボタン -->
      <div
        v-if="auth_type == 'admin' || auth_type == 'admin_auth'"
        :class="$style.team_info__settings"
      >
        <div
          :class="$style.team_info__settings_button"
          v-if="!initProcessing && isValidUser"
        >
          <base-overlay-menu-list :menuWidth="200" :showGearIcon="showGearIcon">
            <template v-if="auth_type == 'admin' || auth_type == 'admin_auth'">
              <base-overlay-menu-list-title
                >管理者向けメニュー</base-overlay-menu-list-title
              >
              <base-overlay-menu-list-item
                to=""
                @click.native="ruleClick"
                v-if="canTeamRuleEdit"
                >チーム設定の編集</base-overlay-menu-list-item
              >
            </template>
            <template v-if="dataTeam.member_list_view_flg">
              <base-overlay-menu-list-item
                to=""
                @click.native="memberManageClick"
                >チームメンバー管理</base-overlay-menu-list-item
              >
            </template>
            <template v-if="auth_type == 'admin' || auth_type == 'admin_auth'">
              <base-overlay-menu-list-item
                to=""
                @click.native="unionSalonSettingClick"
                v-if="!isMobile && !isTablet"
                >チームメンバー招待/割当</base-overlay-menu-list-item
              >
              <base-overlay-menu-list-item
                to=""
                @click.native="giftSendClick"
                v-if="!isMobile && !isTablet"
                >メッセージ機能</base-overlay-menu-list-item
              >
              <base-overlay-menu-list-item
                to=""
                @click.native="clickAdminIcon"
                >{{
                  admin_notification_flg == false
                    ? '管理者通知をONにする'
                    : admin_notification_flg == true
                    ? '管理者通知をOFFにする'
                    : ''
                }}</base-overlay-menu-list-item
              >
            </template>
            <template v-if="auth_type == 'admin'">
              <base-overlay-menu-list-item
                to=""
                @click.native="inviteDeviceUserClick"
                >デバイスユーザーの招待</base-overlay-menu-list-item
              >
            </template>
          </base-overlay-menu-list>
        </div>
      </div>
    </div>
    <!-- /左エリア -->

    <!-- 右エリア -->
    <div v-if="dataTeam != null" :class="$style.team_info__right">
      <!-- チーム名 -->
      <div :class="$style.team_info__name">{{ dataTeam.name }}</div>

      <!-- チームプロフィール -->
      <div :class="$style.team_info__profile">{{ dataTeam.profile }}</div>

      <!-- 詳細 -->
      <div :class="$style.team_info__body">
        <!-- 上部 -->
        <div :class="$style.team_info__body__top">
          <!-- アイコン -->
          <div :class="$style.team_info__body__top__badge">
            <img
              :src="badgeImage"
              :style="imgStyleObject"
              @click="
                $router.push({ name: 'TeamInfo', params: { team_id: team_id } })
              "
            />
          </div>

          <div :class="$style.team_info__body__top__right">
            <!-- メンバー数、チームID -->
            <ul :class="$style.team_info__detail">
              <!-- メンバー数 -->
              <li>メンバー数: {{ dataTeam.member_count }}名</li>
              <!-- チームID -->
              <li>Team-ID: {{ team_id }}</li>
            </ul>
            <!-- /メンバー数、チームID -->

            <!-- 利用可能アプリ -->
            <div :class="$style.team_info__app_icon">
              <p>利用可能アプリ</p>
              <!-- ない場合は予約のみ表示 -->
              <ul v-if="sso_list.length === 0">
                <li>
                  <a @click="goToSso(fixed_sso_data[1])">
                    <!-- 利用可能アプリアイコン -->
                    <img
                      :src="imgPath(1)"
                      alt=""
                      :class="$style.cursor_pointer"
                    />
                  </a>
                </li>
              </ul>
              <ul v-else>
                <!-- イベント予約ページは固定で表示 -->
                <li>
                  <a @click="goToSso(fixed_sso_data[1])">
                    <!-- 利用可能アプリアイコン -->
                    <img
                      :src="imgPath(1)"
                      alt=""
                      :class="$style.cursor_pointer"
                    />
                  </a>
                </li>
                <!-- イベント予約ページ以外のサービスを表示 -->
                <li v-for="(sso, i) in sso_list" :key="i">
                  <a @click="goToSso(sso)">
                    <!-- 利用可能アプリアイコン -->
                    <img
                      :src="imgPath(sso.service_id)"
                      alt=""
                      :class="$style.cursor_pointer"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- /上部 -->

        <!-- お気に入り、いいね、通知 -->
        <ul :class="$style.team_info__buttons">
          <team-icon
            :team="dataTeam"
            :user_id="user_id"
            :belongsTo="true"
            :indexType="true"
            :informationDisplayNone="false"
          ></team-icon>
        </ul>
        <!-- /お気に入り、いいね、通知 -->
      </div>
      <!-- /詳細 -->

      <!-- リンク -->
      <div :class="$style.team_info__body">
        <!-- 左 -->
        <ul :class="$style.team_info__link">
          <!-- このチームについて -->
          <li v-if="!informationDisplayNone">
            <router-link :to="{ name: 'TeamInfo' }">
              <div :class="$style.team_info__link__button">
                <img :src="informationImg" width="18px" alt="" />
                このチームについて
              </div>
            </router-link>
          </li>
          <!-- /このチームについて -->

          <!-- [関連URLを見る]ボタン チーム設定の「チームプロフィールに表示」にチェックが入っている場合表示-->
          <li v-if="dataTeam.url_type.includes('1')">
            <a
              :class="$style.link"
              @click.stop="$router.push(`/home/team/${team_id}`)"
            >
              <div :class="$style.team_info__link__button">
                <img :src="relatedUrlImg" width="18px" alt="" />
                関連URLを見る
              </div>
            </a>
          </li>
        </ul>
        <!-- /左 -->

        <!-- 右 -->
        <div :class="$style.team_info__link__right">
          <router-link to="" @click.native="inviteClick" v-if="invite == true">
            このチームに招待する
          </router-link>
        </div>
        <!-- /右 -->
      </div>
      <!-- /リンク -->
    </div>
    <!-- /右エリア -->

    <base-modal
      v-if="showQrGrenerator"
      type="round"
      @close="showQrGrenerator = false"
    >
      <template v-slot:header-title>このチームのQRコード</template>
      <template v-slot:body>
        <div :class="$style.modal_content">
          <vue-qrcode
            v-if="displayQrGeneratorUrl"
            :value="displayQrGeneratorUrl"
            :options="qr_generator_options"
            tag="img"
          ></vue-qrcode>
        </div>
      </template>
      <template v-slot:footer>
        <base-button
          rounded="rounded"
          width="150px"
          height="50px"
          @click="clickQrCopy"
          >URLをコピーする</base-button
        >
      </template>
    </base-modal>

    <base-modal v-if="isDissolve" type="round" @close="isDissolve = false">
      <template v-slot:body>
        <div :class="$style.modal_content">
          <div>
            こちらの組織は公的組織のため、解散ができません。<br />
            解散したい場合は、下記お問い合わせ先からお問い合わせください。<br />
            URL: <a :href="creatContactUrl">{{ creatContactUrl }}</a>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <base-button
          rounded="rounded"
          width="150px"
          height="50px"
          @click="dismiss"
          >閉じる</base-button
        >
      </template>
    </base-modal>
    <base-modal
      v-if="showDeviceUserQrGrenerator"
      type="round"
      @close="showDeviceUserQrGrenerator = false"
    >
      <template v-slot:header-title>デバイスユーザーの招待</template>
      <template v-slot:body>
        <strong :class="$style.qr_notice"
          >※くみあいアプリインストール後にQRコードを読み込んでください。</strong
        >
        <div :class="$style.modal_content">
          <vue-qrcode
            v-if="displayDeviceUserQrGeneratorUrl"
            :value="displayDeviceUserQrGeneratorUrl"
            :options="qr_generator_options"
            tag="img"
          ></vue-qrcode>
        </div>
      </template>
      <template v-slot:footer>
        <base-button
          rounded="rounded"
          width="150px"
          height="50px"
          @click="clickDeviceUserQrCopy"
          >URLをコピーする</base-button
        >
      </template>
    </base-modal>
  </div>
</template>

<script>
import BaseOverlayMenuList from '@/components/base/BaseOverlayMenuList/BaseOverlayMenuListPhase2.9'
import BaseOverlayMenuListTitle from '@/components/base/BaseOverlayMenuList/BaseOverlayMenuListTitlePhase2.9'
import BaseOverlayMenuListItem from '@/components/base/BaseOverlayMenuList/BaseOverlayMenuListItemPhase2.9'
import BaseButton from '@/components/base/BaseButton/BaseButton'
import BaseModal from '@/components/base/BaseModal/BaseModal'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import { mapMutations, mapGetters } from 'vuex'
import isMobile from '@/utils/mixins/isMobile'
import TeamIcon from '@/pages/team/components/TeamIconPhase2.9'
import InformationImg from '@/assets/images/Icon-material-info-outline.svg'
import RelatedUrlImg from '@/assets/images/Icon-material-link.svg'
import {
  fetchTeamInvite,
  fetchTeamUserInfo,
  editTeamUser,
} from '@/helper/common.js'
import VueCookies from 'vue-cookies'
const cookies = VueCookies
export default {
  mixins: [isMobile()],
  components: {
    BaseOverlayMenuListItem,
    BaseOverlayMenuListTitle,
    BaseOverlayMenuList,
    BaseButton,
    BaseModal,
    VueQrcode,
    TeamIcon,
  },
  name: 'TeamInfo',
  props: {
    showDissolve: {
      type: Boolean,
      default: false,
    },
    dataTeam: {
      type: Object,
      default: () => {},
    },
    user_id: {
      type: Number,
    },
    informationDisplayNone: {
      type: Boolean,
    },
  },
  data() {
    return {
      team_id: null,
      invite: false,
      approval: false,
      favorite_flg: 0,
      auth_type: null,
      can_dissolve: false,
      canTeamRuleEdit: false,
      initProcessing: false,
      processing: false,
      user_belongs: false, // 所属しているかフラグ
      showQrGrenerator: false,
      showDeviceUserQrGrenerator: false,
      isDissolve: false,
      publicStatus: '',
      displayQrGeneratorUrl: '',
      displayDeviceUserQrGeneratorUrl: '',
      qr_generator_options: {
        errorCorrectionLevel: 'M',
        maskPattern: 0,
        margin: 2,
        scale: 2,
        width: 300,
        color: {
          dark: '#000000FF',
          light: '#FFFFFFFF',
        },
      },
      teamErrorMsgList: [],
      isShowMenu: false,
      sso_list: [],
      fixed_sso_data: {},
      fixed_sso_data_all: {
        local: {
          1: {
            team_name: '予約履歴へ',
            sso_url:
              'https://j-union.revn5-2.demo.iqnet.co.jp/auth/signon?uuid=',
            redirect_url:
              'https://j-union.revn5-2.demo.iqnet.co.jp/reservations/history',
          },
        },
        develop: {
          1: {
            team_name: '予約履歴へ',
            sso_url:
              'https://j-union.revn5-2.demo.iqnet.co.jp/auth/signon?uuid=',
            redirect_url:
              'https://j-union.revn5-2.demo.iqnet.co.jp/reservations/history',
          },
        },
        staging: {
          1: {
            team_name: '予約履歴へ',
            sso_url:
              'https://j-union.revn5-2.demo.iqnet.co.jp/auth/signon?uuid=',
            redirect_url:
              'https://j-union.revn5-2.demo.iqnet.co.jp/reservations/history',
          },
        },
        production: {
          1: {
            team_name: '予約履歴へ',
            sso_url: 'https://event.revn.jp/auth/signon?uuid=',
            redirect_url: 'https://event.revn.jp/reservations/history',
          },
        },
      },
      auth_info: cookies.get('auth_info'),
      device_invite_query: '',
      admin_notification_flg: false,
    }
  },
  methods: {
    ...mapMutations('team', ['setTeamUserEventFlg']),
    fetchUserAdminSetting() {
      if (this.dataTeam != null) {
        this.$axios
          .get(
            '/api/' +
              this.$constants.API_VERSION +
              '/user/notification/admin_setting',
            { params: { team_id: this.team_id } }
          )
          .then((res) => {
            if (res.data.status == 'success') {
              this.admin_notification_flg =
                res.data.value.admin_notification_flg
            }
          })
      }
    },
    clickAdminIcon() {
      console.log('おk')
      console.log(this.dataTeam)
      this.$axios
        .post(
          '/api/' +
            this.$constants.API_VERSION +
            '/user/notification/admin_setting',
          {
            team_id: this.team_id,
            admin_notification_flg: !this.admin_notification_flg,
            approve_notification_flg: !this.admin_notification_flg,
            invite_notification_flg: !this.admin_notification_flg,
            member_add_notification_flg: !this.admin_notification_flg,
            request_notification_flg: !this.admin_notification_flg,
          }
        )
        .then((res) => {
          if (res.data.status == 'success') {
            this.admin_notification_flg = !this.admin_notification_flg
          }
        })
    },
    imgPath(serviceId) {
      /** SSOサービスのアイコンが格納されているパス */
      if ([1, 2, 3, 6].includes(serviceId)) {
        return require(`@/assets/images/service_icon_${serviceId}.png`)
      }
    },
    async fetchSsoList() {
      if (!this.dataTeam.team_code) {
        return
      }
      await this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/auth/sso/list', {
          params: { team_code: this.dataTeam.team_code },
        })
        .then((res) => {
          this.sso_list = res.data.value.filter((sso) => {
            // イベント予約ページは固定表示するため取得不要
            return [2, 3, 6].includes(sso.service_id)
          })
        })
    },
    async goToSso(sso) {
      // SSOリクエスト取得処理
      await this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/auth/sso/request', {
          redirect_url: encodeURIComponent(sso.redirect_url),
          error_url: encodeURIComponent(
            `${process.env.VUE_APP_BASE_URL}/error/404`
          ),
          access_token: this.auth_info.access_token,
          refresh_token: this.auth_info.refresh_token,
          device: 'PC',
          tel_or_email: this.$store.state.auth.tel_or_email,
        })
        .then((response) => {
          if (response.status != 200) {
            return
          }
          window.open(sso.sso_url + response.data.value.uuid)
        })
        .catch((error) => {
          this.$logerror(error)
          alert(error)
        })
    },
    async getTeamUserInfo() {
      // 組織ユーザー情報取得
      const { value } = await fetchTeamUserInfo({ team_id: this.team_id })
      this.favorite_flg = value?.favorite_flg ?? 0
      this.setTeamUserEventFlg(value?.event_flg)
      this.auth_type = value?.auth_type

      if (
        this.auth_type == 'admin' &&
        value?.black_list_flg == 0 &&
        (value?.status == 'approved' || value?.status == 'no_approval_required')
      ) {
        this.can_dissolve = true
      }

      // valueが返って来たら所属フラグを立てる
      if (value !== undefined) {
        this.user_belongs = true
      }
    },
    async getTeamInvite() {
      // 未所属の場合はAPIを叩かせない
      if (!this.user_belongs) {
        return
      }
      //組織への招待、組織への承認権限確認取得
      const { invite, approval } = await fetchTeamInvite({
        team_id: this.team_id,
      })

      if (invite) this.invite = true // 0:組織への招待権限がない 1:組織への招待権限がある
      if (approval) this.approval = true // 0:組織への承認権限がない 1:組織への承認権限がある
    },
    fetchScreenAuthTeamUpdate: function () {
      this.canTeamRuleEdit = false

      // 未所属の場合はAPIを叩かせない
      if (!this.user_belongs) {
        return
      }

      // 組織の編集権限を確認するAPI
      return this.$axios
        .post(
          '/api/' + this.$constants.API_VERSION + '/screen_auth/team/update',
          { team_id: this.team_id }
        )
        .then((res) => {
          if (res.data.status == 'success') {
            this.canTeamRuleEdit = true
          }
        })
        .catch((err) => {
          if (err.response.status === 461) {
            // 組織の編集権限なし
            this.canTeamRuleEdit = false
          }
        })
    },
    fetchTeamInfo() {
      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/team/info', {
          params: { team_id: this.team_id },
        })
        .then((res) => {
          this.publicStatus = res.data?.value?.team.public_status
        })
    },
    async getDeviceInviteQuery() {
      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/device/issue/query', {
          params: { team_id: this.team_id },
        })
        .then((res) => {
          this.device_invite_query = res.data?.value?.query
        })
        .catch((error) => {
          this.$logerror(error)
          alert(error)
        })
    },
    async initFetch() {
      this.initProcessing = true
      try {
        if (this.team_id) {
          await this.getTeamUserInfo()
          this.fetchTeamInfo()
          this.fetchUserAdminSetting()
          await Promise.all([
            this.fetchSsoList(),
            this.getTeamInvite(),
            this.fetchScreenAuthTeamUpdate(),
          ])
        }
      } finally {
        this.initProcessing = false
      }
    },
    async favoriteClick() {
      if (this.processing) return

      this.processing = true
      // 組織ユーザー登録・更新 [お気に入りに追加する/削除する]
      const { status } = await editTeamUser({
        team_id: this.team_id,
        favorite_flg: this.favorite_flg ^ 1,
      })
      if (status == 'success') {
        this.favorite_flg = this.favorite_flg ^ 1
      }
      this.processing = false
    },
    async inviteClick() {
      // 組織ユーザー更新 このチームに招待する
      this.showQrGrenerator = true
    },
    async inviteDeviceUserClick() {
      // デバイスユーザー招待クエリ発行API実行
      await this.getDeviceInviteQuery()

      // デバイスユーザーに招待する
      this.showDeviceUserQrGrenerator = true
    },

    async withDrawClick() {
      if (this.processing) return
      var confirm_result = confirm('本当に脱退しますか。')
      if (!confirm_result) {
        return
      }

      this.teamErrorMsgList = []
      this.processing = true
      //  組織ユーザー登録・更新 [このチームを脱退する]
      const { status, errors } = await editTeamUser({
        team_id: this.team_id,
        status: 'withdrawal',
        auth_type: 'not_user',
      })
      if (status == 'success') {
        // ホーム
        this.$router.push(`/home/home`)
      } else if (status == 'error') {
        this.teamErrorMsgList = errors
        setTimeout(() => (this.teamErrorMsgList = []), 5000)
      }
      this.processing = false
    },
    dismiss() {
      // ダイアログを閉じる
      this.isDissolve = false
    },
    async dissolveClick() {
      // 公的組織の場合ダイアログ表示
      let isPublic = this.publicStatus === 'public_approved'
      if (isPublic) {
        this.isDissolve = true
        return
      }

      if (this.processing) return
      var confirm_result = confirm('本当にチームを解散しますか。')
      if (!confirm_result) {
        return
      }

      this.teamErrorMsgList = []
      this.processing = true
      //  チーム解散処理
      this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/team/dissolve', {
          team_id: this.team_id,
        })
        .then((res) => {
          if (res.data.status == 'success') {
            this.$router.push(`/home/home`)
          }
        })
        .catch((err) => {
          this.teamErrorMsgList = err.response.data.value.errors
          setTimeout(() => (this.teamErrorMsgList = []), 5000)
        })
      this.processing = false
    },
    ruleClick() {
      // チーム設定の編集
      this.$router.push(`/team/create/${this.team_id}`)
    },
    memberManageClick() {
      // チームメンバー管理
      this.$router.push(`/team/${this.team_id}/manage-member`)
    },
    unionSalonSettingClick() {
      // UNION SALON 設定
      this.$router.push(`/union/setting/${this.team_id}`)
    },
    giftSendClick() {
      // メッセージ機能
      this.$router.push(`/union/gift/${this.team_id}`)
    },
    async eventClick() {
      if (this.processing) return

      this.processing = true
      //  組織ユーザー登録・更新 [通知をONにする/通知をOFFにする/自分宛のみ通知]
      let event_flg
      // 現在のevent_flgが0の場合は1に変更する
      if (this.isEventDone === 0) {
        event_flg = 1
        // 現在のevent_flgが1の場合は2に変更する
      } else if (this.isEventDone === 1) {
        event_flg = 2
        // 現在のevent_flgが2の場合は0に変更する
      } else if (this.isEventDone === 2) {
        event_flg = 0
      }
      const { status } = await editTeamUser({
        team_id: this.team_id,
        event_flg: event_flg,
      })
      if (status == 'success') {
        this.setTeamUserEventFlg(event_flg)
      }
      this.processing = false
    },
    clickQrCopy() {
      if (this.processing) return

      this.processing = true
      const elem = document.createElement('input')
      elem.value = this.creatQrCodeUrl
      document.body.appendChild(elem)
      elem.select()
      document.execCommand('copy')
      document.body.removeChild(elem)
      this.processing = false
    },
    clickDeviceUserQrCopy() {
      if (this.processing) return

      this.processing = true
      const elem = document.createElement('textarea')
      elem.value =
        '-----------------------------------------------------------------------------------\n' +
        '●くみあいアプリをインストールされてない方はこちら\n' +
        'アプリインストール用ＵＲＬ：' +
        `${process.env.VUE_APP_BASE_URL}?hideHeader=true` +
        '\n\n' +
        '●くみあいアプリをインストールしている方はこちら\n' +
        'デバイスログイン招待用ＵＲＬ：' +
        this.createDeviceUserQrCodeUrl +
        '\n' +
        '-----------------------------------------------------------------------------------'
      document.body.appendChild(elem)
      elem.select()
      document.execCommand('copy')
      document.body.removeChild(elem)
      this.processing = false
    },
    toggleShowMenu() {
      this.isShowMenu = !this.isShowMenu
    },
  },
  computed: {
    ...mapGetters('team', ['getTeamUserEventFlg']),
    badgeImage() {
      return this.dataTeam?.badge_image || ''
    },
    isFavorite() {
      return !!this.favorite_flg == true
    },
    isEventDone() {
      return this.getTeamUserEventFlg
    },
    isValidUser() {
      if (
        this.auth_type &&
        (this.auth_type == 'admin' ||
          this.auth_type == 'admin_auth' ||
          this.auth_type == 'user')
      ) {
        return true
      }
      return false
    },
    creatQrCodeUrl() {
      return `${process.env.VUE_APP_BASE_URL}/team/info/${this.team_id}`
    },
    createDeviceUserQrCodeUrl() {
      return `${process.env.VUE_APP_MOBILE_INVITE_URL}/open/ju_setting?${this.device_invite_query}`
    },
    creatContactUrl() {
      return `${process.env.VUE_APP_BASE_URL}/open/contact`
    },
    isTablet() {
      const ua = navigator.userAgent.toLowerCase()
      // 前提条件：macはタッチスクリーン不採用
      return /ipad|macintosh|android/.test(ua) && 'ontouchend' in document
    },
    showGearIcon() {
      return true
    },
    imgStyleObject() {
      return {
        width: '100%',
        height: '100%',
        'border-radius': '50%',
        'object-fit': 'cover',
        border: `solid 1.5pt ${this.dataTeam?.team_color || ''}`,
      }
    },
    informationImg() {
      return InformationImg
    },
    relatedUrlImg() {
      return RelatedUrlImg
    },
  },
  watch: {
    showQrGrenerator(nv, ov) {
      if (!nv && ov) {
        this.displayQrGeneratorUrl = ''
      }
      if (nv && !ov) {
        this.displayQrGeneratorUrl = this.creatQrCodeUrl
      }
    },
    showDeviceUserQrGrenerator(nv, ov) {
      if (!nv && ov) {
        this.displayQrGeneratorUrl = ''
      }
      if (nv && !ov) {
        this.displayDeviceUserQrGeneratorUrl = this.createDeviceUserQrCodeUrl
      }
    },
    dataTeam() {
      this.initFetch()
    },
  },
  created() {
    this.team_id = this.$route.params?.team_id
    this.fixed_sso_data = this.fixed_sso_data_all[process.env.VUE_APP_NODE_ENV]
  },
}
</script>

<style lang="scss" module>
.team_content {
  padding-top: 70px;
  padding-bottom: 70px;
  min-height: calc(100vh - 70px);
  &.yellow {
    background: #fffbe4;
  }
  &.white {
    background: #fff;
  }
  @include mobile() {
    min-height: calc(100vh - 70px - 50px);

    &.hide_tabbar {
      min-height: calc(100vh - 50px);
      padding-bottom: 0;
    }
  }
}

.team_content_body {
  margin: 0 auto;
  max-width: 1280px;
  &_loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.footer {
  @include mobile() {
    display: none;
  }
}

.modal_content {
  margin: 0 20px !important;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  @include mobile() {
    width: auto;
    margin: 20px;
  }
}
.team_info {
  border-radius: 10pt;
  padding: 30px 30px 10px 30px;
  display: flex;
  background-color: #fff;

  @include mobile() {
    padding: 1rem;
  }

  &__header {
    padding-right: 25px;

    @include mobile() {
      display: none;
    }
  }

  &__badge {
    width: 110px;
    height: 110px;
    cursor: pointer;

    @include mobile() {
      display: none;
    }
  }

  &__settings {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
  }

  &__settings_button {
    cursor: pointer;
    margin: 12px auto 0;
  }

  &__icon {
    display: flex;
    justify-content: space-between;
    width: 60px;
  }

  &__notification {
    width: 22px;
    cursor: pointer;

    img {
      width: 100%;
      vertical-align: top;
    }
  }
  &__right {
    width: 100%;

    @include mobile() {
      width: auto;
    }
  }

  &__name {
    font-size: 16pt;

    @include mobile() {
      display: none;
      font-size: 18pt;
    }

    font-weight: bold;
    word-break: break-all;
  }

  &__profile {
    font-size: 10pt;

    @include mobile() {
      display: none;
      font-size: 10pt;
    }

    padding-bottom: 10px;
  }

  &__body {
    display: flex;

    @include mobile() {
      display: block;
      width: auto;
    }

    align-items: center;
    padding-bottom: 15px;

    &__top {
      display: flex;
      align-items: center;

      @include mobile() {
        padding-bottom: 20px;
      }

      &__badge {
        display: none;

        @include mobile() {
          display: block;
          width: 90px;
          height: 90px;
          padding-right: 25px;
        }

        img {
          cursor: pointer;
        }
      }

      &__right {
        display: flex;
        align-items: center;

        @include mobile() {
          display: block;
          width: auto;
        }
      }
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      word-wrap: break-word;

      li {
        padding-right: 20px;
      }
    }
  }

  &__detail {
    font-size: 12pt;
    display: flex;
    justify-content: space-between;

    margin-bottom: 100px;
    @include mobile() {
      justify-content: flex-start;
      font-size: 9.75pt;
    }
  }

  &__link {
    display: flex;
    justify-content: flex-start;
    list-style: none;
    padding: 0;
    margin: 0;
    word-wrap: break-word;

    li {
      padding-right: 20px;
    }

    &__button {
      font-size: 12pt;

      @include mobile() {
        font-size: 9.75pt;
      }
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #2dccd3;
      text-decoration: underline;
      font-weight: 700;
    }

    &__right {
      display: flex;
      justify-content: flex-end;

      @include mobile() {
        display: none;
        justify-content: flex-start;
      }

      flex: 3;
      align-items: center;

      a {
        font-size: 10pt;

        @include mobile() {
          font-size: 9.75pt;
        }

        color: #262626;
      }
    }
  }

  &__app_icon {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mobile() {
      justify-content: flex-start;
      padding-top: 10px;
    }

    p {
      font-size: 10pt;

      @include mobile() {
        font-size: 9.75pt;
      }

      margin: 0;
      padding: 0;
    }

    ul {
      list-style: none;
      padding: 0;
      display: flex;
      align-items: flex-start;
      gap: 0;

      li {
        width: 30px;
        padding: 0 0 0 10px;

        img {
          width: 100%;
          vertical-align: top;
        }
      }
    }
  }

  &__buttons {
    flex: 3;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include mobile() {
      justify-content: flex-start;
    }

    > ul {
      list-style: none;
      padding: 0;
      align-items: flex-start;
      li {
        padding: 0 0 0 10px;
      }
    }
  }
}

.cursor_pointer {
  cursor: pointer;
}

.qr_notice {
  display: block;
  font-size: small;
  text-align: center;
}

.link {
  color: $keyGreen;
  line-height: 1.5;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}
</style>
