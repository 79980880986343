<template>
  <div
    ref="wrapperRef"
    :class="classObject"
    :style="customStyle"
    @click="$emit('click')"
  >
    <span :class="innerClassObject" :style="customStyleText">
      <slot />
    </span>
    <span ref="hiddenSpanRef" :class="$style.hidden">
      <slot />
    </span>
  </div>
</template>

<script>
export default {
  name: 'BaseTag',
  props: {
    height: {
      type: String,
      default: '12px',
    },
    width: {
      type: String,
      default: '',
    },
    display: {
      type: String,
      default: 'inline-block',
      validator: (value) =>
        [undefined, 'inline-block', 'inline-flex', 'flex', 'block'].includes(
          value
        ),
    },
    padding: {
      type: String,
      default: '16px',
    },
    fontSize: {
      type: String,
      default: 's',
      validator: (value) => ['l', 'm', 's', 'xs', 'base'].includes(value),
    },
    /**
     * ボタン色を指定できます。
     */
    bgColor: {
      type: String,
      default: 'green',
    },
    /**
     * 文字色を指定できます。
     */
    color: {
      type: String,
      default: 'white',
    },
    /**
     * タグの形状を指定できます。
     */
    rounded: {
      type: String,
      default: 'conner',
      validator: (value) =>
        [undefined, 'round-4px', 'round-5px', 'round-6px', 'conner'].includes(
          value
        ),
    },
    /**
     * ボーダースタイルを適用するかを指定します
     */
    bordered: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: null,
    },
  },
  mounted() {
    this.updateWidth()
  },
  computed: {
    customStyle() {
      const displays = {
        'inline-block': 'inline-flex',
        'inline-flex': 'inline-flex',
        block: 'flex',
        flex: 'flex',
      }
      return {
        display: displays[this.display],
        height: this.height,
        width: this.width,
        'box-sizing': this.isAutoWidth ? undefined : 'border-box',
        padding: this.padding ? `0 ${this.padding}` : null,
        background: this.bgColor,
        // '&.bordered': {
        //   color: this.bgColor,
        //   background: this.color,
        //   border: '1px solid ' + this.bgColor
        // },
        color: this.color,
      }
    },
    customStyleText() {
      return {
        position: this.position ? this.position : null,
      }
    },
    classObject() {
      return [
        this.$style.base_tag,
        this.$style[this.rounded],
        // this.bordered ? this.$style.bordered : null,
        this.$style['bg-' + this.bgColor],
        this.bordered
          ? {
              [this.$style.bordered]: {
                color: this.bgColor,
                background: this.color,
                border: '1px solid ' + this.bgColor,
              },
            }
          : null,
      ]
    },
    innerClassObject() {
      return [this.$style.base_tag, this.$style['font-' + this.fontSize]]
    },
    isAutoWidth() {
      return !this.width || this.width == 'auto'
    },
  },
  methods: {
    updateWidth() {
      if (this.isAutoWidth) {
        const scaleMap = { m: 0.9, s: 0.7, xs: 0.6 }
        const width =
          this.$refs.hiddenSpanRef.clientWidth *
          (scaleMap[this.fontSize] || 1.0)
        this.$refs.wrapperRef.style.width = width + 'px'
      }
    },
  },
}
</script>
<style lang="scss" module>
.base_tag {
  border: 0;
  background: none;
  padding: 0;
  display: inline-flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  position: relative;

  &.font-xs,
  &.font-s,
  &.font-m,
  &.font-base {
    display: inline-block;
    position: absolute;
    text-align: center;
    font-size: 10px;
    line-height: 10px;
  }
  &.font-xs {
    transform: scale(0.6);
    width: calc(100% / 0.6);
  }
  &.font-s {
    transform: scale(0.7);
    width: calc(100% / 0.7);
  }
  &.font-m {
    transform: scale(0.9);
    width: calc(100% / 0.9);
  }
  &.font-l {
    display: inline-block;
    position: absolute;
    text-align: center;
    font-size: 20px;
    line-height: 10px;
  }

  &.bg-green {
    background: $keyGreen;
    &.bordered {
      color: $keyGreen;
      background: $keyWhite;
      border: 1px solid $keyGreen;
    }
  }

  &.bg-dark-green {
    background: $KeyDarkGreen;
    &.bordered {
      color: $KeyDarkGreen;
      background: $keyWhite;
      border: 1px solid $KeyDarkGreen;
    }
  }

  &.bg-white {
    background: $keyWhite;
    &.bordered {
      color: $fontBlack;
      background: $keyWhite;
      border: 1px solid $borderLiteGray;
    }
  }

  &.bg-yellow {
    background: $keyYellow;
    &.bordered {
      color: $keyYellow;
      background: $keyWhite;
      border: 1px solid $keyYellow;
    }
  }

  &.bg-pink {
    background: $keyPink;
    &.bordered {
      color: $keyPink;
      background: $keyWhite;
      border: 1px solid $keyPink;
    }
  }

  &.bg-black {
    background: $keyBlack;
    &.bordered {
      color: $fontBlack;
      background: $keyWhite;
      border: 1px solid $keyBlack;
    }
  }

  &.white {
    color: $fontWhite;
  }
  &.black {
    color: $fontBlack;
  }
  &.gray {
    color: $fontGray;
  }
  &.green {
    color: $keyGreen;
  }

  &.conner {
    border-radius: 2px;
  }

  &.round-4px {
    border-radius: 4px;
  }
  &.round-5px {
    border-radius: 5px;
  }
  &.round-6px {
    border-radius: 6px;
  }
}
.hidden {
  visibility: hidden;
  font-size: 10px;
  left: 0;
  top: 0;
  position: fixed;
}
</style>
