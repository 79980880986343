<template>
  <div :class="$style.password_forget">
    <h1 :class="$style.title">パスワード再発行</h1>
    <p :class="$style.note">
      まずはご本人確認を行います。<br />必要な情報を入力してください。
    </p>
    <div :class="$style.input_group">
      <base-input
        name="contact"
        look="border2"
        v-model="user.tel_or_email"
        :error="
          v$.user.tel_or_email.$invalid && v$.user.tel_or_email.$dirty
            ? v$.user.tel_or_email.$errors[0].$message
            : isFieldErrorMsg('tel_or_email', errorMsgList)
        "
        maxlength="255"
      >
        <template v-slot:prepend>
          <span :class="$style.input_prepend_contact"
            >電話番号または<br />メールアドレス</span
          >
        </template>
      </base-input>

      <base-date-picker
        name="birthday"
        type="date"
        look="border2"
        v-model="user.birthday"
        :error="
          v$.user.birthday.$invalid && v$.user.birthday.$dirty
            ? v$.user.birthday.$errors[0].$message
            : isFieldErrorMsg('birthday', errorMsgList)
        "
      >
        <template v-slot:prepend>
          <span :class="$style.input_prepend_birthday">生年月日</span>
        </template>
      </base-date-picker>
    </div>

    <p :class="$style.note_2">
      ※電話番号やメールアドレス、生年月日は本人確認のためにのみ使用されます。
    </p>

    <p>
      <a href="/open/contact" :class="$style.link">登録した生年月日をお忘れの方はこちら（リンク）からお問い合わせください。</a>
    </p>

    <template v-if="hasError(errorMsgList) && !hasFieldErrorMsg(errorMsgList, [])">
      <base-message
      type="error" v-for="(error, i) in errorMsgList" :key="i"
      :class="$style.msg_area">{{error.message}}</base-message>
    </template>

    <div :class="$style.next_button_wrap">
      <base-button
        display="inline-flex"
        width="100%"
        height="50px"
        @click="submit"
        :disabled="processing"
      >
        次へ
      </base-button>
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/base/BaseInput/BaseInput'
import BaseDatePicker from '@/components/base/BaseDatePicker/BaseDatePicker'
import BaseButton from '@/components/base/BaseButton/BaseButton'
import BaseMessage from '@/components/base/BaseMessage/BaseMessage'
import useVuelidate from '@vuelidate/core'
import { required, helpers, email, or } from '@vuelidate/validators'
import {isFieldErrorMsg, hasError, hasFieldErrorMsg} from '@/helper/validator.js'
import { addJapanInternationalCode, getEditPhoneNumber } from "../../utils/helper";

function tel(value) {
  const regexTel = /^(\+\d+)?\d{10,}$/
  return regexTel.test(value)
}

export default {
  name: 'Registration',
  components: { BaseInput, BaseDatePicker, BaseButton, BaseMessage },
  data() {
    return {
      user: {
        tel_or_email: '',
        birthday: '',
      },
      errorMsgList: [],
      processing: false
    }
  },
  setup: function () {
    return { v$: useVuelidate() }
  },
  created: function(){
    // 生年月日の初期値を登録
    this.user.birthday = this.$dayjs().format('YYYY-MM-DD')
  },
  validations: function () {
    return {
      user: {
        tel_or_email: {
          required: helpers.withMessage(
            '電話番号またはメールアドレスは、必ず指定してください。',
            required
          ),
          tel_or_email: helpers.withMessage(
            '電話番号またはメールアドレスには、電話番号またはEメールの形式で指定してください。',
            or(email, tel)
          ),
          $autoDirty: true,
        },
        birthday: {
          required: helpers.withMessage(
            '生年月日は、必ず指定してください。',
            required
          ),
          $autoDirty: true,
        },
      },
    }
  },
  methods: {
    isFieldErrorMsg,
    hasError,
    hasFieldErrorMsg,

    async submit() {
      const validate = await this.v$.$validate()
      if (!validate) {
        return
      }
      if(this.processing){
        return
      }
      this.processing = true
      this.errorMsgList = []

      let tel_or_email = getEditPhoneNumber(addJapanInternationalCode(this.user.tel_or_email))

      // 認証基盤 パスワードリセット要求API
      this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/auth/password/reset/request', {
          tel_or_email: tel_or_email,
          birthday: this.user.birthday.replaceAll('-', ''),
        })
        .then(() => {
          this.$store.commit('registration/setTelOrEmail',tel_or_email)
          this.$store.commit('registration/setBirthday', this.user.birthday.replaceAll('-', ''))
          // 画面遷移
          this.$router.push(`/open/verification_send/reset`) // :modeにreset指定
        }).catch(err => {
          if(err.response.status === 400){
            this.errorMsgList = err.response.data.value.errors;
          }
        }).finally(() => {
          this.processing = false
        });
    },
  },
}
</script>
<style lang="scss" module>
.password_forget {
  display: flex;
  flex-direction: column;
  width: 375px;
  margin: 0 auto;
  padding: 42px 32px 30px 32px;
  box-sizing: border-box;
  text-align: left;
}

.title {
  margin-top: 0;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 50px;
}

.note {
  font-size: 13px;
  color: $fontBlack;
  margin-top: 0;
  margin-bottom: 30px;
}

.note_2 {
  font-size: 11px;
  color: $fontBlack;
  margin-top: 0;
  margin-bottom: 30px;
}

.input_group {
  & > * {
    &:nth-child(n) {
      margin-bottom: 30px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  margin-bottom: 30px;
}

.input_prepend {
  &_contact,
  &_birthday {
    font-size: 11px;
  }
  &_contact {
    margin-right: 6px;
    margin-bottom: 8px;
  }
  &_birthday {
    margin-right: 38px;
  }
}

.next_button_wrap {
  margin-top: auto;
}

.msg_area {
  width: 100% !important;
  margin-top: 10px;
}

.link {
  font-size: 11px;
  color: $fontBlack;
  display: block;
}
</style>
