import Encoding from 'encoding-japanese'

/**
 * 数字を3桁カンマ区切りにして返す
 * @param num
 * @returns {string}
 */
export const delimit = (num) => {
  return String(num).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

/**
 * パスワード文字列を検証する
 * 10文字以上、半角英数字大文字小文字記号を含む
 * 使用可能な記号：^$*.[]{}()?"!@#%&/\,><':;|_~`
 * 使用不可な記号：+-=
 * @param value
 * @returns {Boolean}
 */
export const validatePassword = (value) => {
  if(value.length === 0) return true; // requiredは別で判断するのでnullはここで弾く
  // eslint-disable-next-line
  const regexPassword = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[\^\$\*\.\[\]\{\}\(\)\?\"!@#%&\/\\,><\':;\|_~`]).{10,}$/
  // eslint-disable-next-line
  const regexChar = /^[A-Za-z0-9\^\$\*\.\[\]\{\}\(\)\?\"!@#%&\/\\,><\':;\|_~`]+$/
  return regexPassword.test(value) && regexChar.test(value);
};

/**
 * Youtubeか講座のリンクかどうかを検証する
 * 「https://www.youtube.com/watch」を含んでいるか
 * 「https://youtu.be/」を含んでいるか
 * 「VUE_APP_REGEX_URL+/union/course/」を含んでいるか
 * 「VUE_APP_REGEX_URL+?path=/union/course&lectureId=」を含んでいるか
 * どれかに該当する場合trueを返す
 * @param value
 * @returns {Boolean}
 */
 export const validateYoutube = (value) => {
  if(value.length === 0) return true; // requiredは別で判断するのでnullはここで弾く
  const regexYoutube = /https?:\/\/www.youtube.com\/watch\?v=[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+/
  const regexYoutubeM = /https?:\/\/m.youtube.com\/watch\?v=[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+/
  const regexYoutubeLive = /https?:\/\/www.youtube.com\/live\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+/
  const regexYoutubeShortening = /https?:\/\/youtu.be\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+/
  const regexLecture = RegExp(process.env.VUE_APP_BASE_URL+"/union/course/[0-9]+")
  const regexLectureSP = RegExp(process.env.VUE_APP_BASE_URL+'\\?path=/union/course&lectureId=[0-9]+')
  if(regexYoutube.test(value) || regexYoutubeM.test(value) || regexYoutubeLive.test(value) || regexYoutubeShortening.test(value) || regexLecture.test(value) || regexLectureSP.test(value)) {
    return true;
  } else {
    return false;
  }
};

/**
 * 投稿やミニ投票の本文に使用できるタグかどうかを判定する
 * @param value
 * @returns {Boolean}
 */
export const validateTags = (value) => {
  // 許可されたタグを定義する
  const ALLOWED_TAGS_WEB = [
    'p',
    'span',
    'strong',
    'a',
    'u',
    'br',
  ];

    // 許可されていないタグがあるかを確認
    const regex = new RegExp(`<(?!\\/?(?:${ALLOWED_TAGS_WEB.join('|')})\\b)[^>]*>`, 'gi');
    const matches = value.match(regex) || [];

    // 許可されていないタグが検出された場合は無効として処理する
    if (matches.length > 0) {
      // console.log('validateTags false');
      // console.log(matches)
      return false;
    }

    return true;
}

/**
 * MIMEタイプから画像かどうか判定する
 * @param mimeType
 * @returns {Boolean}
 */
export const isImage = (mimeType) => {
  if (mimeType == 'image/apng'        // APNG
      || mimeType == 'image/avif'     // AVIF
      || mimeType == 'image/gif'      // GIF
      || mimeType == 'image/jpeg'     // JPEG
      || mimeType == 'image/png'      // PNG
      || mimeType == 'image/svg+xml'  // SVG
      || mimeType == 'image/webp'     // WebP
      )
      return true;
  return false;
};

/**
 * dataUrl文字列からBASE64文字列のみ抽出
 * @param dataUrl
 * @returns {String}
 */
export const getBase64FromDataUrl = (base64) => {
  return base64.replace(/^data.*?base64,/, '');
};

/**
 * 対象文字列をSJISにエンコード
 * @param {*} params_str (対象文字列) 文字コード自体は自動判別
 * @returns SJISにエンコードされた文字列
 */
export const convertShiftJIS = (param_str) => {
  if (!param_str) return

  const unicodeArray = []
  for (let i = 0; i < param_str.length; i++) {
    unicodeArray.push(param_str.charCodeAt(i))
  }
  const detected = Encoding.detect(unicodeArray)

  // Shift_JISに変換
  const sjisArray = Encoding.convert(unicodeArray, {
    to: 'SJIS',
    from: detected,
  })

  // SJISをURLエンコード
  const encodedKeyword = Encoding.urlEncode(sjisArray)

  return encodedKeyword
};

/**
 * 文字列内にURLがある場合、リンク化する
 * @param {*} text 対象文字列
 * @returns aタグ変換後の文字列
 */
export const autoLink = (text) => {
  return text ? encode(text).replace(/(https?:\/\/[^\s]*)/g, "<a href='$1'>$1</a>") : '';
}

/**
 * 文字列エスケープ処理
 * @param {*} str
 * @returns
 */
export const encode = (str) => {
  return str.replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#39;');
}

/**
 * 国番号指定が無い電話番号に国番号(+81)日本を付与する
 * @param ログインID
 * @returns 国番号編集したログインID
 */
export const addJapanInternationalCode = (tel_or_email) => {
  if (tel_or_email.indexOf('@') == -1) {
    // 電話番号でログインする場合
    if (!tel_or_email.startsWith('+')) {
      // 国番号指定が無い場合、国番号指定ではないと見做して国番号(+81)日本を付与する
      tel_or_email = '+81' + tel_or_email
    }
  }
  return tel_or_email
};

/**
 * 国番号(+81)日本の場合に、国番号の後の電話番号で先頭0の場合は先頭0を削る
 * @param 国番号付き電話番号
 * @returns 編集した国番号付き電話番号
 */
export const getEditPhoneNumber = (tel) => {
  if (tel.indexOf('@') == -1) {
    if (tel.startsWith('+81') && 4 <= tel.length) {
      // 先頭0の場合先頭の0を削る
      if (tel.charAt(3) == '0') {
        return tel.substring(0, 3) + tel.substring(4, tel.length);
      }
    }
  }
  return tel;
};
