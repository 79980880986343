import { render, staticRenderFns } from "./Setting.vue?vue&type=template&id=5e580532&scoped=true"
import script from "./Setting.vue?vue&type=script&lang=js"
export * from "./Setting.vue?vue&type=script&lang=js"
import style0 from "./Setting.vue?vue&type=style&index=0&id=5e580532&prod&lang=scss&module=true"
import style1 from "./Setting.vue?vue&type=style&index=1&id=5e580532&prod&scoped=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "5e580532",
  null
  
)

export default component.exports