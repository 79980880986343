<template>
  <section
    :class="[
      $style.wrapper,
      {
        [$style.read]: this.isRead,
      },
    ]"
    :style="customStyle"
    @click="handleClick"
  >
    <img v-if="clip" :class="$style.clip" src="@/assets/images/clip.svg" />
    <img
      v-if="pin"
      :class="$style.pin"
      src="@/assets/images/favorite_pin.svg"
    />
    <div :class="$style.picture_wrapper">
      <picture :class="$style.picture">
        <img :src="image" />
      </picture>
    </div>
    <div :class="$style.info_wrapper">
      <div :class="$style.header_wrapper">
        <div :class="$style.header_inner_left">
          <div v-if="displayTag" :class="$style.tag_wrapper">
            <base-tag
              width="100%"
              height="10px"
              :bgColor="tagColor"
              :color="tagTextColor"
              fontSize="xs"
            >
              {{ tagText }}
            </base-tag>
          </div>
          <p v-else :class="$style.time">
            <time :datetime="timeAttr" :class="$style.time_text">{{
              timeText
            }}</time>
          </p>
          <div :class="$style.team_name">
            {{ teamName }}
          </div>
        </div>
        <div :class="$style.icons_area">
          <p v-if="ableToSelect" :class="$style.icons">
            <span
              :class="[$style.icons_wrapper, { [$style.selected]: !!selected }]"
            >
              <span>
                <base-icon
                  v-if="isMentioned"
                  :type="iconType"
                  :name="iconAtName"
                  :color="iconAtColor"
                  size="21px"
                />
              </span>
              <span>
                <base-icon
                  v-if="!isRead"
                  :type="iconType"
                  :name="iconEyeName"
                  :color="iconEyeColor"
                  size="21px"
                />
              </span>
              <span @click.stop="handleSelect">
                <base-icon
                  v-if="isDisplayHeart"
                  :type="iconType"
                  :name="iconHeartName"
                  :color="iconHeartColor"
                  size="21px"
                  @click.stop="handleSelect"
                />
              </span>
            </span>
          </p>
        </div>

        <span v-if="type === 'app'" :class="$style.overlay_menu_wrapper">
          <base-overlay-menu-list>
            <template v-slot:menu-button>
              <div :class="$style.menu_button_icon" />
            </template>
            <base-overlay-menu-list-item to="" @click.native="handleFavorite">
              {{ favorite ? 'お気に入りから削除する' : 'お気に入りに追加する' }}
            </base-overlay-menu-list-item>
            <base-overlay-menu-list-item to="" @click.native="handlePin">
              {{ pin ? 'クリップを解除する' : 'クリップする' }}
            </base-overlay-menu-list-item>
            <base-overlay-menu-list-item to="" @click.native="handleShare">
              シェアする
            </base-overlay-menu-list-item>
          </base-overlay-menu-list>
        </span>
        <span v-else-if="type === 'team'" :class="$style.overlay_menu_wrapper">
          <base-overlay-menu-list>
            <template v-slot:menu-button>
              <div :class="$style.menu_button_icon" />
            </template>
            <base-overlay-menu-list-item
              to=""
              @click.native="handleFavorite"
              v-if="showFavorite"
            >
              {{ favorite ? 'お気に入りから削除する' : 'お気に入りに追加する' }}
            </base-overlay-menu-list-item>
            <base-overlay-menu-list-item
              to=""
              @click.native="handlePin"
              v-if="showPin"
            >
              {{ pin ? 'クリップを解除する' : 'クリップする' }}
            </base-overlay-menu-list-item>
            <base-overlay-menu-list-item
              to=""
              @click.native="handleShare"
              v-if="showShare"
            >
              シェアする
            </base-overlay-menu-list-item>
            <base-overlay-menu-list-item
              to=""
              @click.native="handleRead"
              v-if="showRead"
            >
              {{ read ? '未読にする' : '既読にする' }}
            </base-overlay-menu-list-item>
            <base-overlay-menu-list-item
              to=""
              @click.native="handleDelete"
              v-if="showDelete"
            >
              削除する
            </base-overlay-menu-list-item>
            <base-overlay-menu-list-item
              to=""
              @click.native="handleBrowse"
              v-if="showBrowse"
            >
              この投稿を閲覧する
            </base-overlay-menu-list-item>
            <base-overlay-menu-list-item
              to=""
              @click.native="handleReport"
              v-if="showReport"
            >
              {{
                reported ? '※このユーザは通報済みです' : 'このユーザを通報する'
              }}
            </base-overlay-menu-list-item>
            <base-overlay-menu-list-item
              to=""
              @click.native="handleBlock"
              v-if="showBlock"
            >
              {{
                blockUser
                  ? 'このユーザのブロックを解除する'
                  : 'ユーザをブロックする'
              }}
            </base-overlay-menu-list-item>
          </base-overlay-menu-list>
        </span>
      </div>
      <h3 :class="$style.head">{{ head }}</h3>
    </div>
  </section>
</template>

<script>
import BaseIcon from '../../../base/BaseIcon/BaseIcon'
import BaseTag from '../../../base/BaseTag/BaseTag'
import BaseOverlayMenuList from '@/components/base/BaseOverlayMenuList/BaseOverlayMenuList'
import BaseOverlayMenuListItem from '@/components/base/BaseOverlayMenuList/BaseOverlayMenuListItem'

export default {
  name: 'AppCarouselPostNotificationItem',
  components: {
    BaseIcon,
    BaseTag,
    BaseOverlayMenuList,
    BaseOverlayMenuListItem,
  },
  props: {
    head: {
      type: String,
      default: '',
    },
    teamName: {
      type: String,
      default: 'チーム名が入ります',
    },
    iconHeart: {
      type: String,
      default: 'heart',
      validator: (icon) => !icon || ['heart'].includes(icon),
    },
    iconEye: {
      type: String,
      default: 'eye',
      validator: (icon) => !icon || ['eye'].includes(icon),
    },
    image: {
      type: String,
      default: require('@/assets/images/no-image.png'),
    },
    tag: {
      type: String,
      default: null,
    },
    customTagText: {
      type: String,
      default: null,
    },
    customTagColor: {
      type: String,
      default: null,
    },
    customTagTextColor: {
      type: String,
      default: null,
    },
    time: {
      type: [String, Date, Number],
      default: () => new Date(),
    },
    selected: {
      type: Boolean,
      default: null, // true:選択, false:非選択, null:選択機能無し
    },
    clip: {
      type: Boolean,
      default: false,
    },
    pin: {
      type: Boolean,
      default: false,
    },
    favorite: {
      type: Boolean,
      default: false,
    },
    read: {
      type: Boolean,
      default: false,
    },
    borderColor: {
      type: String,
      default: '#FFFFFF',
    },
    steps: {
      type: Array,
      default: () => [],
      validator: (steps) => steps.every((step) => step >= 0 && step <= 100),
    },
    item: {
      type: Object,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: false,
      default: null,
    },
    showShare: {
      type: Boolean,
      default: true,
    },
    showRead: {
      type: Boolean,
      default: true,
    },
    showPin: {
      type: Boolean,
      default: true,
    },
    showFavorite: {
      type: Boolean,
      default: true,
    },
    showDelete: {
      type: Boolean,
      default: true,
    },
    reported: {
      type: Boolean,
      default: false,
    },
    showReport: {
      type: Boolean,
      default: true,
    },
    blockUser: {
      type: Boolean,
      default: false,
    },
    showBlock: {
      type: Boolean,
      default: true,
    },
    showBrowse: {
      type: Boolean,
      default: false,
    },
    isCursorPointer: {
      type: Boolean,
      default: true,
    },
    isRead: {
      type: Boolean,
      default: false,
    },
    userTeamStatus: {
      type: String,
      default: '',
    }
  },
  methods: {
    handleSelect() {
      // 選択イベントが発火するように設定
      // this.$emit('select', !this.selected)
      this.$emit('select', !this.selected, this.item)
    },
    handleClick() {
      // クリックイベントが発火するように設定
      this.$emit('click', this.item)
    },
    handleFavorite() {
      // お気に入りイベント
      this.$emit('favorite', this.item)
    },
    handlePin() {
      // ピンイベント
      this.$emit('pin', this.item) //user側の変更は文言でクリップと言いつつピンなので注意
    },
    handleRead() {
      // 未読/既読イベント
      this.$emit('read', this.item)
    },
    handleDelete() {
      // 削除イベント
      this.$emit('delete', this.item)
    },
    handleShare() {
      // シェアイベント
      this.$emit('share', this.item)
    },
    handleReport() {
      // ユーザ通報イベント
      this.$emit('report', this.item)
    },
    handleBlock() {
      // ブロックイベント
      this.$emit('blockUser', this.item)
    },
    handleBrowse() {
      // 閲覧イベント
      this.$emit('browse', this.item)
    },
  },
  computed: {
    ableToSelect() {
      return this.selected === false || this.selected === true
    },
    isDisplayHeart() {
      return this.userTeamStatus !== 'before_approval'
    },
    customStyle() {
      let style = {}

      if (this.isCursorPointer) {
        // trueの場合マウスオーバーでカーソルが指マークに変化
        style.cursor = 'pointer'
      }
      return style
    },
    iconType() {
      return 'fas' // iconやselectedによって変化する予定
    },
    iconHeartName() {
      return this.iconHeart
    },
    iconEyeName() {
      return this.iconEye
    },
    iconHeartColor() {
      return this.selected ? 'pink' : 'gray'
    },
    iconEyeColor() {
      return 'green'
    },
    timeAttr() {
      const date =
        this.time instanceof Date
          ? this.time
          : new Date(this.time.replace(/-/g, '/'))
      return date.toLocaleString()
    },
    timeText() {
      if (!this.time) {
        return ''
      }
      const date =
        this.time instanceof Date
          ? this.time
          : new Date(this.time.replace(/-/g, '/'))
      return `${date.getFullYear()}/${('0' + (date.getMonth() + 1)).slice(
        -2
      )}/${('0' + date.getDate()).slice(-2)} ${('0' + date.getHours()).slice(
        -2
      )}:${('0' + date.getMinutes()).slice(-2)}`
    },
    displayTag() {
      if (
        this.customTagText ||
        this.customTagTextColor ||
        this.customTagColor
      ) {
        return true
      } else if (this.tag) {
        return true
      }

      return false
    },
    tagColor() {
      if (this.customTagColor) {
        return this.customTagColor
      }

      const tagColorMap = {
        need: 'pink',
        step: 'yellow',
        done: 'yellow',
      }

      return tagColorMap[this.tag] || 'pink'
    },
    tagStepText() {
      const tagStepText = this.steps
        .map((step, i) => `Step${i + 1} - ${step}%`)
        .slice(0, 2)
        .join('  ')
      return tagStepText
    },
    tagText() {
      if (this.customTagText) {
        return this.customTagText
      }

      const tagTextMap = {
        need: '受講してください',
        step: this.tagStepText,
        done: '受講完了',
      }

      return tagTextMap[this.tag] || ''
    },
    tagTextColor() {
      if (this.customTagTextColor) {
        return this.customTagTextColor
      }

      const tagTextColorMap = {
        need: 'white',
        step: 'black',
        done: 'black',
      }

      return tagTextColorMap[this.tag] || 'white'
    },
  },
}
</script>

<style lang="scss" module>
.wrapper {
  position: relative;
  width: 100%;
  background-color: $keyWhite;
  box-sizing: border-box;
  border-bottom: 1px solid $borderGray;
  display: flex;
  padding: 16px 0;
  gap: 20px;
  @include mobile_only() {
    height: auto;
    flex-direction: column;
    gap: 10px;
    // width: 150px;
    // height: 160px;
  }
}
.wrapper.read {
  opacity: 50%;
}

.clip {
  position: absolute;
  top: -16px;
  left: 4px;
}

.pin {
  z-index: 2;
  position: absolute;
  top: -14px;
  left: -3px;
  transform: rotate(-21deg);
}
.header_wrapper {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
}

.tag_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  max-width: 100px;
}

.time {
  display: inline-block;
  color: $fontGray;
  margin: 0;
  // width: 142px;
}
.time_text {
  display: inline-block;
  font-size: 12px;
  white-space: nowrap;
  text-align: left;
}
.icons_area {
  margin-left: auto;
  @include mobile_only() {
    display: none;
  }
}
.icons {
  display: inline-block;
  color: $borderLiteGray;
  margin: 0;
  &_wrapper {
    display: flex;
    @include mobile_only() {
      transform: scale(0.5);
    }
  }

  .selected {
    color: $keyPink;
  }
}
.overlay_menu_wrapper {
  display: flex;
  .menu_button_icon {
    width: 10px;
    height: 10px;
    cursor: pointer;
    background-image: url('../../../../assets/images/グループ 1656.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}
.picture {
  display: block;
  // height: 130px;
  overflow: hidden;
  text-align: center;
  justify-content: center;
  align-items: center;
  @include mobile_only() {
    height: 120px;
    text-align: left;
  }
}
.picture > img {
  margin-top: 10px;
  width: 200px;
  height: 120px;
  object-fit: contain;
  @include mobile_only() {
    // margin: 4px;
  }
}

.head {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: $keyGreen;
  line-height: 1.5;
  font-size: 16px;
  word-break: break-all;
  overflow: hidden;
  margin: 0;
  padding: 0;
  text-decoration: underline;
  @include mobile_only() {
    font-size: 14px;
  }
}

.foot {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: $fontGray;
  max-height: 32px;
  line-height: 1.75;
  margin: 0;
  font-size: 14px;
  word-break: break-all;
  overflow: hidden;
  position: absolute;
  bottom: 8px;
  @include mobile_only() {
    line-height: 1.75;
    font-size: 12px;
  }
}
.team_name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 14px;
  line-height: 1.5;
  color: $keyBlack;
  @include mobile_only() {
    line-height: 1.75;
    font-size: 12px;
  }
}
.info_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.header_inner_left {
  display: flex;
  gap: 20px;
  @include mobile_only() {
    flex-direction: column;
    gap: 10px;
  }
}
.icons_wrapper {
  gap: 10px;
}
</style>
