<template>
  <div></div>
</template>
<script>
export default {
  props: {
    account_id: {
      type: String,
    },
    uuid: {
      type: String,
    },
    sp: {
      type: String,
    },
  },
  data() {
    return {
      user_id: null,
      result: false,
      access_token: null,
      refresh_token: null,
      redirect_url: null,
      error_url: null,
      device: null,
    }
  },
  created: async function () {
    let request_error = false
    // SSOリクエスト取得処理
    await this.$axios
    .get('/api/' + this.$constants.API_VERSION + '/auth/sso/request', {
      params: { uuid: this.uuid },
    })
    .then((response) => {
      this.access_token = response.data.value.access_token
      this.refresh_token = response.data.value.refresh_token
      this.redirect_url = response.data.value.redirect_url
      this.error_url = response.data.value.error_url
      this.device = response.data.value.device
    })
    .catch((error) => {
      request_error = true
      this.$logerror(error)
    })

    if (request_error) {
      if (this.sp == 'SP') {
        window.location = process.env.VUE_APP_MOBILE_SSO_URL + '?path=/error'
      } else {
        alert('リクエストを処理できませんでした')
      }
      window.close()
      return;
    }

    //スマホの場合
    if (this.device == 'SP') {
      var url =
        process.env.VUE_APP_MOBILE_SSO_URL +
        '?path=' +
        this.redirect_url +
        '&access_token=' +
        this.access_token.replace(/\$/g, '\\$') +
        '&refresh_token=' +
        this.refresh_token.replace(/\$/g, '\\$') +
        '&error_url=' +
        this.error_url
      window.location = url
      return
    }
    this.$router.app.$emit('show-spinner', true)
    this.processing = true
    //SSOログイン処理
    this.$axios
      .get('/api/' + this.$constants.API_VERSION + '/auth/token/check', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.access_token,
        },
        params: {
          service_code: this.$constants.KUMIAI_SERVICE_CODE,
          refresh_token: this.refresh_token,
        },
      })
      .then((response) => {
        if (response.data.status == 'success') {
          this.$store.commit(
            'auth/setTelOrEmail',
            response.data.value.tel_or_email
          )
          this.$store.commit('auth/setJuid', response.data.value.juid)
          this.$cookies.set('auth_info', {
            tel_or_email: response.data.value.tel_or_email,
            access_token: response.data.value.access_token,
            expired_at: response.data.value.expires_in,
            refresh_token: response.data.value.refresh_token,
          })
          if (response.data.value.user_id) {
            this.user_id = response.data.value.user_id
          }
          this.result = true
        } else {
          this.result = false
        }
      })
      .catch((error) => {
        this.$logerror(error)
        this.result = false
      })
      .finally(() => {
        this.processing = false
        this.$router.app.$emit('show-spinner', false)
        if (this.result) {
          //くみあいアプリ側のuni_usersにデータがない場合
          if (this.user_id == null) {
            this.$cookies.set('redirect_url', this.redirect_url)
            //プロフィール画面へ遷移
            this.$router.push('/open/profile_edit')
          } else {
            window.location = decodeURIComponent(this.redirect_url)
          }
        } else {
          window.location = decodeURIComponent(this.error_url)
        }
      })
  },
}
</script>
