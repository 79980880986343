<template>
  <section
    :class="$style.article"
    v-bind="$attrs"
    @click.stop="handleSelect"
    :style="customStyle"
  >
    <div style="position: relative">
      <div
        :border-color="borderColor"
        :outline-color="outlineColor"
        :class="$style.img_wrapper"
        :style="styleObject"
      >
        <img :src="image" />
      </div>
    </div>
    <h3 :class="$style.head">
      <slot name="head">
        {{ head.length > 20 ? head.slice(0, 20) + '...' : head }}
      </slot>
    </h3>
    <a
      v-if="link"
      :class="$style.link"
      @click.stop="$router.push(`/home/team/${team_id}`)"
      >関連URLを見る</a
    >
  </section>
</template>

<script>
export default {
  name: 'AppCarouselTeamItemRect',
  props: {
    image: {
      type: String,
    },
    head: {
      type: String,
    },
    borderColor: {
      type: String,
      default: '#FFFFFF',
    },
    size: {
      type: String,
      default: undefined,
    },
    outlineColor: {
      type: String,
      default: undefined,
    },
    isCursorPointer: {
      type: Boolean,
      default: true,
    },
    link: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: null,
    },
    team_id: {
      type:[String, Number], // team_idの型が文字列か数値のいずれかであることを指定
      default: '' // team_idは必須のプロパティであることを指定
    },
  },
  methods: {
    handleSelect() {
      // 選択イベントが発火するように設定
      // this.$emit('select', !this.selected)
      this.$emit('click', this.item)
    },
  },
  computed: {
    customStyle() {
      let style = {}

      if (this.isCursorPointer) {
        // trueの場合マウスオーバーでカーソルが指マークに変化
        style.cursor = 'pointer'
      }
      return style
    },
    styleObject() {
      const size = {
        width: this.size,
        height: this.size,
      }
      const border = this.borderColor
        ? { border: `2px solid ${this.borderColor}` }
        : null
      const outline = this.outlineColor
        ? { outline: `2px solid ${this.outlineColor}`, 'outline-offset': `2px` }
        : null
      return {
        ...size,
        ...border,
        ...outline,
      }
    },
  },
}
</script>

<style lang="scss" module>
.article {
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.head {
  display: block;
  color: $keyBlack;
  line-height: 1.5;
  font-size: 14px;
  font-weight: bold;
  word-break: break-all;
  overflow: hidden;
  margin: 0;
}

.link {
  color: $keyGreen;
  line-height: 1.5;
  font-size: 14px;
  text-decoration: underline;
}
.img_wrapper {
  max-width: 180px;
  max-height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  aspect-ratio: 6/4;
}
</style>
