<template>
  <div :class="$style.home_favorite">
    <base-tab :tab="current_tab" @tab="tabChange">
      <template v-slot:[TAB_APP]>
        <div :class="$style.app_tab_content">
          <base-accordion title="MOVIE" type="borderless" expanded>
            <div :class="$style.app_item_list">
              <div
                v-for="(content, i) of contents_favorite_list"
                :class="$style.app_item_wrap"
                :key="i"
              >
                <app-carousel-post-item
                  :border-color="
                    content.bar_color ? `rgb(${content.bar_color})` : '#7BE293'
                  "
                  :head="content.title"
                  :foot="judgeFoot(content)"
                  :image="
                    content.image_url
                      ? content.image_url
                      : 'https://via.placeholder.com/140x50'
                  "
                  :selected="
                    content.type && content.type == 'corner'
                      ? null
                      : Boolean(content.good_flg)
                  "
                  :pin="Boolean(content.pin_flg)"
                  @pin="pin(content, i, 'app')"
                  :time="content.created_at"
                  :customTagText="content.status"
                  :customTagColor="
                    content.background_color
                      ? `rgb(${content.background_color})`
                      : null
                  "
                  :customTagTextColor="
                    content.text_color ? `rgb(${content.text_color})` : null
                  "
                  :favorite="Boolean(content.favorite_flg)"
                  @favorite="favorite(content, i, 'app')"
                  @share="share(content, 'app')"
                  @click="$router.push(switchContentsPageURL(content))"
                  @select="good(content, i, 'app')"
                  type="app"
                />
              </div>
            </div>
          </base-accordion>
        </div>
      </template>
      <template v-slot:[TAB_TEAM]>
        <div :class="$style.team_tab_content">
          <div :class="$style.team_item_list">
            <div
              v-for="(content, i) of team_favorite_list"
              :class="$style.team_item_wrap"
              :key="i"
            >
              <app-carousel-post-item
                :border-color="
                  content.team_color ? `rgb(${content.team_color})` : '#7BE293'
                "
                :head="content.title"
                :foot="content.team_name"
                :image="
                  content.image_url
                    ? content.image_url
                    : 'https://via.placeholder.com/140x50'
                "
                :selected="Boolean(content.good_flg)"
                :pin="Boolean(content.pin_flg)"
                @pin="pin(content, i, 'team')"
                :time="content.created_at"
                :favorite="Boolean(content.favorite_flg)"
                @favorite="favorite(content, i, 'team')"
                :read="Boolean(content.read_flg)"
                @read="read(content, i, 'team')"
                @share="share(content, 'team')"
                @delete="deleteTeamContent(content, i)"
                @click="$router.push(switchTeamPageURL(content))"
                @select="good(content, i, 'team')"
                type="team"
                :showFavorite="content.block_user_flg ? false : true"
                :showPin="content.block_user_flg ? false : true"
                :showShare="content.block_user_flg ? false : true"
                :showRead="
                  content.block_user_flg ? false : content.type != 'team'
                "
                :showDelete="
                  content.block_user_flg ? false : !!content.delete_auth_flg
                "
                :icon="content.block_user_flg ? '' : 'heart'"
                :blockUser="content.block_user_flg"
                :showBrowse="content.block_user_flg ? true : false"
                :showReport="
                  $store.state.auth.user_id == content.chat_user_id
                    ? false
                    : true
                "
                :reported="content.is_user_reported"
                @report="reportUser(content)"
                :showBlock="
                  $store.state.auth.user_id == content.chat_user_id
                    ? false
                    : true
                "
                @blockUser="blockUser(content, i, 'team')"
                @browse="$router.push(switchTeamPageURL(content))"
              />
            </div>
          </div>
        </div>
      </template>
    </base-tab>
  </div>
</template>

<script>
import BaseTab from '@/components/base/BaseTab/BaseTab'
import BaseAccordion from '@/components/base/BaseAccordion/BaseAccordion'
import AppCarouselPostItem from '@/components/app/AppCarousel/PostItem/AppCarouselPostItem'
export default {
  name: 'HomeFavorite',
  components: { BaseTab, BaseAccordion, AppCarouselPostItem },
  mounted() {
    this.emitPageUpdated()
  },
  data() {
    const TAB_APP = 'アプリ内'
    const TAB_TEAM = 'チーム内'
    return {
      TAB_APP,
      TAB_TEAM,
      current_tab: TAB_APP,
      contents_favorite_list: [],
      team_favorite_list: [],
      request_sending: false,
    }
  },
  created: async function () {
    this.$router.app.$emit('show-spinner', true)
    await this.initFetch()
    this.$router.app.$emit('show-spinner', false)
  },
  methods: {
    emitPageUpdated() {
      this.$router.app.$emit('page-updated', {
        pageTitle: 'お気に入り',
        headerColor: 'white',
        showSetting: this.current_tab == this.TAB_TEAM,
      })
    },
    fetchContentsFavoriteList() {
      return this.$axios.get('/api/' + this.$constants.API_VERSION + '/contents_favorite/list').then((res) => {
        this.contents_favorite_list = res.data.value.contents_favorite_list
      })
    },
    fetchTeamFavoriteList() {
      return this.$axios.get('/api/' + this.$constants.API_VERSION + '/team_favorite/list').then((res) => {
        this.team_favorite_list = res.data.value.team_favorite_list
      })
    },
    async initFetch() {
      await Promise.all([
        this.fetchContentsFavoriteList(),
        this.fetchTeamFavoriteList(),
      ])
    },
    tabChange(name) {
      // this.$loginfo('Click tab:' + name)
      this.current_tab = name
      this.emitPageUpdated()
    },
    switchContentsPageURL(content) {
      if (content.type === 'lecture') {
        return '/union/course/' + content.id
      } else if (content.type === 'corner') {
        return '/union/corner/' + content.id
      }
    },
    switchTeamPageURL(content) {
      if (content.type === 'chat' || content.type === 'gift' || content.type === 'sso') {
        return '/team/post-detail/' + content.team_id + '/' + content.id
      } else if (content.type === 'team') {
        return '/team/home/' + content.team_id
      } else if (content.type === 'mini_vote') {
        return '/team/mini-vote-detail/' + content.team_id + '/' + content.id
      }
    },
    judgeFoot(content) {
      if (content.type === 'lecture') {
        return content.course_name
      } else if (content.type === 'corner') {
        return content.corner_name
      }
    },
    async pin(content, i, type) {
      //多重送信させない
      if (this.request_sending) {
        return
      }
      this.request_sending = true

      //更新したいフラグの切り分け
      let params = {
        pin_flg: 1,
      }
      if (content.pin_flg === 1) {
        params['pin_flg'] = 0
      }

      //更新
      if (type === 'app') {
        await this.updateAppContent(content, params)
        await this.fetchContentsFavoriteList()
      }

      if (type === 'team') {
        await this.updateTeamContent(content, params)
        await this.fetchTeamFavoriteList()
      }

      //正常終了したら送信中フラグの解除
      this.request_sending = false
    },
    async good(content, i, type) {
      //多重送信させない
      if (this.request_sending) {
        return
      }
      this.request_sending = true

      //更新したいフラグの切り分け
      let params = {
        good_flg: 1,
      }
      if (content.good_flg === 1) {
        params['good_flg'] = 0
      }

      //更新
      if (type === 'app') {
        await this.updateAppContent(content, params)
        this.contents_favorite_list[i]['good_flg'] = params['good_flg']
      }

      if (type === 'team') {
        await this.updateTeamContent(content, params)
        this.team_favorite_list[i]['good_flg'] = params['good_flg']
      }

      //正常終了したら送信中フラグの解除
      this.request_sending = false
    },
    async reportUser(content) {
      //多重送信させない
      if (this.request_sending) {
        return
      }
      if (content.is_user_reported) {
        //３点リーダーを閉じる
      } else {
        if (!confirm('このユーザを悪質なユーザとして報告しますか？')) {
          return
        }
        this.request_sending = true
        await this.callReportUser(content.chat_user_id)
      }
      this.request_sending = false
      return
    },
    callReportUser: async function (user_id) {
      await this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/user/report', {
          reported_user_id: user_id,
        })
        .then((res) => {
          if (res.data.status == 'success') {
            //データ再取得
            this.fetchTeamFavoriteList()
            alert('管理者が内容を確認し、24時間以内に対応を致します。')
          }
        })
        .catch((err) => {
          if (err.response.status != '200') {
            alert(err.response.data.value.errors[0].message)
          }
        })
    },
    async blockUser(content, i, type) {
      //多重送信させない
      if (this.request_sending) {
        return
      }
      this.request_sending = true
      //更新
      if (type === 'team') {
        if (content.block_user_flg) {
          if (!confirm('ユーザのブロックを解除しますか？')) {
            this.request_sending = false
            return
          }
          await this.deleteBlockUser(content.chat_user_id)
        } else {
          if (!confirm('ユーザをブロックしますか？')) {
            this.request_sending = false
            return
          }
          await this.updateBlockUser(content.chat_user_id)
        }
      }
      //正常終了したら送信中フラグの解除
      this.request_sending = false
    },
    async updateBlockUser(block_user_id) {
      await this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/user/block/registration', {
          block_user_id: block_user_id,
        })
        .then((res) => {
          if (res.data.status == 'success') {
            //画像再取得
            this.fetchTeamFavoriteList()
            alert('ユーザをブロックしました')
          }
        })
        .catch((err) => {
          if (err.response.status != '200') {
            alert(err.response.data.value.errors[0].message)
          }
        })
    },
    async deleteBlockUser(block_user_id) {
      await this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/user/block/delete', {
          block_user_id: block_user_id,
        })
        .then((res) => {
          if (res.data.status == 'success') {
            //画像再取得
            this.fetchTeamFavoriteList()
            alert('ユーザのブロックを解除しました')
          }
        })
        .catch((err) => {
          if (err.response.status != '200') {
            alert(err.response.data.value.errors[0].message)
          }
        })
    },
    async favorite(content, i, type) {
      //多重送信させない
      if (this.request_sending) {
        return
      }
      this.request_sending = true

      const params = {
        favorite_flg: 0, //お気に入り解除なので0になる
      }

      //更新
      if (type === 'app') {
        await this.updateAppContent(content, params)
        this.contents_favorite_list.splice(i, 1)
      }
      if (type === 'team') {
        await this.updateTeamContent(content, params)
        this.team_favorite_list.splice(i, 1)
      }

      //送信中フラグの解除
      this.request_sending = false
    },
    async read(content, i, type) {
      //多重送信させない
      if (this.request_sending) {
        return
      }
      this.request_sending = true

      let params = {
        read_flg: 1,
      }
      if (content.read_flg) {
        params['read_flg'] = 0
      }

      //更新
      if (type === 'team') {
        await this.updateTeamContent(content, params)
        this.team_favorite_list[i]['read_flg'] = params['read_flg']
      }

      //送信中フラグの解除
      this.request_sending = false
    },
    async updateAppContent(content, params) {
      if (content.type === 'lecture') {
        await this.updateLectureUser(content.id, params)
      } else if (content.type === 'corner') {
        await this.updateCornerUser(content.id, params)
      }
    },
    async updateTeamContent(content, params) {
      if (['chat', 'mini_vote', 'gift'].includes(content.type)) {
        await this.updateChatUser(content.id, params)
      } else if (content.type === 'team') {
        await this.updateTeamUser(content.id, params)
      }
    },
    async updateLectureUser(lecture_id, params) {
      await this.$axios.post('/api/' + this.$constants.API_VERSION + '/lecture_history/chat/favorite', {
        chat_users: [Object.assign({ lecture_id: String(lecture_id) }, params)],
      })
    },
    async updateCornerUser(corner_id, params) {
      await this.$axios.post(
        '/api/' + this.$constants.API_VERSION + '/corner/user',
        Object.assign({ corner_id: corner_id }, params)
      )
    },
    async updateChatUser(chat_id, params) {
      await this.$axios.post('/api/' + this.$constants.API_VERSION + '/team/chat/favorite', {
        chat_users: [Object.assign({ chat_id: chat_id }, params)],
      })
    },
    async updateTeamUser(team_id, params) {
      await this.$axios.post('/api/' + this.$constants.API_VERSION + '/team/user/registration', {
        team_user_list: [Object.assign({ team_id: team_id }, params)],
      })
    },
    async share(content, type) {
      let url = process.env.VUE_APP_BASE_URL
      if (type === 'app') {
        url = url + this.switchContentsPageURL(content)
      } else if (type === 'team') {
        url = url + this.switchTeamPageURL(content)
      }

      navigator.clipboard
        .writeText(url)
        .then(() => {
          this.$loginfo(url)
          alert('URLをクリップボードにコピーしました')
        })
        .catch((error) => this.$logerror(error))
    },
    async deleteTeamContent(content, i) {
      //投稿関連のみ削除可能
      if (['chat', 'mini_vote', 'gift'].includes(content.type)) {
        await this.$axios.delete('/api/' + this.$constants.API_VERSION + '/team/chat/delete', {
          params: {
            chat_id: content.id,
          },
        })

        this.team_favorite_list.splice(i, 1)
      }
    },
  },
}
</script>

<style lang="scss" module>
.home_favorite {
  width: 100%;
}

.app_tab_content {
  display: flex;
  width: 960px;
  min-height: calc(100vh - 170px);
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 0 0 0;
  margin: 0 auto;

  @include mobile() {
    width: auto;
    margin: 0;
    padding: 24px 20px 24px 20px;
  }
}

.team_tab_content {
  display: flex;
  width: 960px;
  min-height: calc(100vh - 170px);
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 0 0 0;
  margin: 0 auto;

  @include mobile() {
    width: auto;
    margin: 0;
    padding: 24px 32px 24px 32px;
  }
}

.app_item_list,
.team_item_list {
  padding: 20px 2px 140px 2px;
  margin: 0 0 0 0;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
</style>
