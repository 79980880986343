import FontAwesomeIcon from "@/utils/fontawesome";
import "@fortawesome/fontawesome-svg-core/styles.css";
import '../src/utils/installCompositionApi'
import Vue from 'vue'
import VueHead from 'vue-head'
import App from './App.vue'
import store from './store'
import VueSelect from 'vue-select'
import settingAxios from './plugins/axios-setting'
import sanitizeHTML from 'sanitize-html'

// Library
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
// cookieの属性を一括設定
// リフレッシュトークンの有効期限よりも長い有効期限
// Secure = true
// SameSite = None
Vue.$cookies.config('31d', '/', null, true, 'None')
import dayjs from "dayjs";
import 'dayjs/locale/ja'
dayjs.locale('ja')
Vue.prototype.$dayjs = dayjs

// Plagins
import ConstantsPlugin from './plugins/constants'
Vue.use(ConstantsPlugin)
import AxiosPlugin from './plugins/axios'
window.$axios = settingAxios();
Vue.use(AxiosPlugin, window.$axios)

import LoggerPlugin from './plugins/logger'
Vue.use(LoggerPlugin)
import UUIDPlugin from './plugins/uuid'
Vue.use(UUIDPlugin)

// head tag
Vue.use(VueHead, {
  separator: '|',
  complement: 'vuejs2-front-init',
})

// Router
import router from './router'

Vue.config.productionTip = false
Vue.config.devtools = process.env.VUE_APP_DEVTOOLS == 'true'

// Error Handling
Vue.config.errorHandler = (err, vm, info) => {
  if (process.env.VUE_APP_CONSOLE_LOGGING == 'true') {
    console.error(`Captured in Vue.config.errorHandler: ${info}`, err)
  }

  if (process.env.VUE_APP_DEVTOOLS != 'true') {
    router.push('/error/500')
  }
}

// load FontAwesome
Vue.component("fa-icon", FontAwesomeIcon)

// Vue Select
Vue.component("v-select", VueSelect );

// Sanitize HTML
sanitizeHTML.defaults.allowedTags = ['a', 'p', 'span', 'strong', 'br', 'mention'];
sanitizeHTML.defaults.allowedAttributes['span'] = ['style'];
sanitizeHTML.defaults.allowedAttributes['a'] = ['href'];
sanitizeHTML.defaults.allowedAttributes['mention'] = ['juid'];
Vue.prototype.$sanitize = sanitizeHTML

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app')

import { ToggleButton } from 'vue-js-toggle-button'
Vue.component('ToggleButton', ToggleButton);

import { BPopover } from 'bootstrap-vue';
Vue.component('b-popover', BPopover);


