<template>
  <nav :class="[$style.nav, { [$style[this.type]]: !!this.type }, (this.bottomFixed? $style.bottom_fixed: '')]">
    <ol :class="$style.item_list">
      <li :class="$style.item" :style="{'background-color': homeBackgroundColor}">
        <a :href="urls.home || undefined" @click="$emit('home')" @mouseover="homeMouseOver" @mouseleave="homeMouseLeave">
          <span
            :class="[$style.news, { [$style.hasNews]: !!this.news.home }]"
          ></span>
          <span :class="$style.icon">
            <base-icon size="21.5px" type="fas" name="home" :color="homeIconColor" />
          </span>
          <span :class="$style.label">HOME</span>
        </a>
      </li>
      <li :class="$style.item" :style="{'background-color': noticeBackgroundColor}">
        <a :href="urls.notice || undefined" @click="$emit('notice')" @mouseover="noticeMouseOver" @mouseleave="noticeMouseLeave">
          <span
            :class="[$style.news, { [$style.hasNews]: !!this.news.notice }]"
          ></span>
          <span :class="$style.icon">
            <base-icon size="21.5px" type="far" name="bell" :color="noticeIconColor" />
          </span>
          <span :class="$style.label">通知</span>
        </a>
      </li>
      <li :class="$style.item" :style="{'background-color': searchBackgroundColor}">
        <a :href="urls.search || undefined" @click="$emit('search')" @mouseover="searchMouseOver" @mouseleave="searchMouseLeave">
          <span
            :class="[$style.news, { [$style.hasNews]: !!this.news.search }]"
          ></span>
          <span :class="$style.icon">
            <base-icon size="21.5px" type="fas" name="search" :color="searchIconColor" />
          </span>
          <span :class="$style.label">検索</span>
        </a>
      </li>
      <li :class="$style.item" :style="{'background-color': favoriteBackgroundColor}">
        <a :href="urls.favorite || undefined" @click="$emit('favorite')" @mouseover="favoriteMouseOver" @mouseleave="favoriteMouseLeave">
          <span
            :class="[$style.news, { [$style.hasNews]: !!this.news.favorite }]"
          ></span>
          <span :class="$style.icon">
            <base-icon size="21.5px" type="far" name="star" :color="favoriteIconColor" />
          </span>
          <span :class="$style.label">お気に入り</span>
        </a>
      </li>
      <li :class="[$style.item, $style.menu]" :style="{'background-color': menuBackgroundColor}">
        <a :href="urls.menu || undefined" @click="onClickMenu" @mouseover="menuMouseOver" @mouseleave="menuMouseLeave">
          <span
            :class="[$style.news, { [$style.hasNews]: !!this.news.menu }]"
          ></span>
          <span :class="$style.icon">
            <img :class="$style.logo" :src="logo" />
          </span>
          <span :class="$style.label">メニュー</span>
        </a>
      </li>
    </ol>
  </nav>
</template>

<script>
import BaseIcon from "@/components/base/BaseIcon/BaseIcon";

export default {
  name: "AppTabbar",
  components: { BaseIcon },
  props: {
    type: {
      type: String,
      validator: (value) => ["white", "yellow"].includes(value),
      default: "white",
    },
    // trueならアイコンに赤い丸がつく。falseならなにもせず省略可。
    news: {
      type: Object,
      default: () => ({
        // home: false,
        // notice: false,
        // search: false,
        // favorite: false,
        // menu: false,
      }),
    },
    // hrefの設定。またはfalsyな値なら何もせず省略可。
    urls: {
      type: Object,
      default: () => ({
        home: '/home/home',
        notice: '/home/notice',
        search: '/home/search',
        favorite: '/home/favorite',
        // menu: '', // 画面遷移ではなくサイドメニューのスライドインのようなので、利用側に@menuで処理してもらう
      })
    },
    bottomFixed: {
      type: Boolean,
      default: false,
    },
    isShowMenu: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      homeIconColor: this.$route.fullPath.includes('home/home') ? 'black' : 'green',
      homeBackgroundColor: this.$route.fullPath.includes('home/home') ? '#FFF07E' : '#FFFFFF',
      noticeIconColor: this.$route.fullPath.includes('home/notice') ? 'black' : 'green',
      noticeBackgroundColor: this.$route.fullPath.includes('home/notice') ? '#FFF07E' : '#FFFFFF',
      searchIconColor: this.$route.fullPath.includes('home/search') ? 'black' : 'green',
      searchBackgroundColor: this.$route.fullPath.includes('home/search') ? '#FFF07E' : '#FFFFFF',
      favoriteIconColor: this.$route.fullPath.includes('home/favorite') ? 'black' : 'green',
      favoriteBackgroundColor: this.$route.fullPath.includes('home/favorite') ? '#FFF07E' : '#FFFFFF',
      menuBackgroundColor: this.isShowMenu ? '#FFF07E' : '#FFFFFF',
      logo: !this.isShowMenu ? require("@/assets/images/nav_logo3.svg") : require("@/assets/images/nav_logo1.svg")
    }
  },
  computed: {},
  methods: {
    onClickMenu() {
      this.$emit('menu')
    },
    homeMouseOver() {
      if (this.isShowMenu) {
        this.homeBackgroundColor = '#FFFFFF'
      } else {
        this.homeBackgroundColor ='#FFF07E'
      }
      this.homeIconColor = 'black'
    },
    homeMouseLeave() {
      if (this.$route.fullPath.includes('home/home')) return
      this.homeBackgroundColor = '#FFFFFF'
      this.homeIconColor = 'green'
    },
    noticeMouseOver() {
      if (this.isShowMenu) {
        this.noticeBackgroundColor = '#FFFFFF'
      } else {
        this.noticeBackgroundColor ='#FFF07E'
      }
      this.noticeIconColor = 'black'
    },
    noticeMouseLeave() {
      if (this.$route.fullPath.includes('home/notice')) return
      this.noticeBackgroundColor = '#FFFFFF'
      this.noticeIconColor = 'green'
    },
    searchMouseOver() {
      if (this.isShowMenu) {
        this.searchBackgroundColor = '#FFFFFF'
      } else {
        this.searchBackgroundColor ='#FFF07E'
      }
      this.searchIconColor = 'black'
    },
    searchMouseLeave() {
      if (this.$route.fullPath.includes('home/search')) return
      this.searchBackgroundColor = '#FFFFFF'
      this.searchIconColor = 'green'
    },
    favoriteMouseOver() {
      if (this.isShowMenu) {
        this.favoriteBackgroundColor = '#FFFFFF'
      } else {
        this.favoriteBackgroundColor ='#FFF07E'
      }
      this.favoriteIconColor = 'black'
    },
    favoriteMouseLeave() {
      if (this.$route.fullPath.includes('home/favorite')) return
      this.favoriteBackgroundColor = '#FFFFFF'
      this.favoriteIconColor = 'green'
    },
    menuMouseOver() {
      this.menuBackgroundColor = '#FFF07E'
      this.logo = require("@/assets/images/nav_logo1.svg")
    },
    menuMouseLeave() {
      this.menuBackgroundColor = '#FFFFFF'
      this.logo = require("@/assets/images/nav_logo3.svg")
    },
  },
};
</script>

<style lang="scss" module>
.nav {
  z-index: 10;
  &.white {
    background-color: $keyWhite;
  }
  &.yellow {
    background-color: #fffbe4;
    border-top: 1px solid $borderLiteGray;
  }
  &.bottom_fixed {
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  .item_list {
    display: flex;
    justify-content: center;
    height: 69px;
    margin: 0;
    padding: 0;

    .item {
      display: inline-block;
      width: 75px;

      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        position: relative;
        color: $keyBlack;
        text-decoration: none;
        cursor: pointer;
      }

      .news {
        display: block;
        position: absolute;
        width: 9px;
        height: 9px;
        background-color: $keyPink;
        border-radius: 50%;
        right: 23px;
        top: 15px;
        opacity: 0%;
      }
      .hasNews {
        opacity: 100%;
      }

      .icon {
        display: flex;
        align-items: center;
        height: 34px;
        font-size: 19px;
        color: $keyGreen;
        user-select: none;
      }

      .label {
        display: block;
        font-size: 10px;
        transform: scale(0.9);
        font-weight: bold;
        user-select: none;
      }

      &.menu {
        .logo {
          display: block;
          width: 21px;
        }
      }
    }

    .item.menu {
      .label {
      }

      .logo {
      }
    }
  }

  &.white {
    .item.menu {
      background-color: $keyYellow;
    }
  }
  &.yellow {
    .item.menu {
      background-color: $keyBlack;
      color: $keyYellow;
    }
  }
}
</style>
