import Vue from 'vue'
import ConstantsPlugin from '../plugins/constants'
Vue.use(ConstantsPlugin)

/**
 * ログアウトAPI 呼出
 * @param {*} payload
 * @returns ステータス エラーリスト
 */
 export async function logoutApi() {
  try {
      const res = await $axios.post('/api/' + Vue.prototype.$constants.API_VERSION + '/logout')
      return { status: res.data?.status, errors: []}
  } catch (e) {
    return { status: 'failed', errors: [] }
  }
}

/**
 * ユーザー情報詳細取得API 呼出
 * @param {*} payload user_id juid(任意)
 * @returns value:ユーザー情報
 */
 export async function fetchUserInfo(payload) {
    const { user_id, juid } = payload
    let params = {};
    if(user_id != null) params = {user_id}
    if(juid != null) params = {...params, juid}

    try {
        const res = await $axios.get('/api/' + Vue.prototype.$constants.API_VERSION + '/user/info', {params})
        return { status: res.data?.status, value: res.data?.value?.user, errors: [] }
    } catch (e) {
      if (e.response && e.response.status === 400) {
        return { status: 'error', errors: e.response.data?.value?.errors };
      }
      return { status: 'failed', errors: [] }
    }
}

/**
 * 組織ユーザー情報取得API 呼出
 * @param {*} payload user_id team_id(必須)
 * @returns value:組織ユーザー情報
 */
 export async function fetchTeamUserInfo(payload) {
    const { user_id, team_id } = payload
    let params = {};
    if(user_id != null) params = {user_id}
    if(team_id != null) params = {...params, team_id}

    try {
        const res = await $axios.get('/api/' + Vue.prototype.$constants.API_VERSION + '/team/user/info', {params})
        return { status: res.data?.status, value: res.data?.value?.user_team, errors: [] }
    } catch (e) {
      if (e.response && e.response.status === 400) {
        return { status: 'error', errors: e.response.data?.value?.errors };
      }
      return { status: 'failed', errors: [] }
    }
}

/**
 * 組織への招待、組織への承認権限確認API
 * @param {*} payload team_id(必須)
 * @returns invite:[0:組織への招待権限がない][1:組織への招待権限がある]
 *          approval:[0:組織への承認権限がない][1:組織への承認権限がある]
 */
 export async function fetchTeamInvite(payload) {
    const { team_id } = payload
    let params = {};
    if(team_id != null) params = {...params, team_id}

    try {
        const res = await $axios.post('/api/' + Vue.prototype.$constants.API_VERSION + '/screen_auth/team/invite', params)
        return { status: res.data?.status, invite: res.data?.value?.invite, approval: res.data?.value?.approval, errors: [] }
    } catch (e) {
      if (e.response && e.response.status === 400) {
        return { status: 'error', errors: e.response.data?.value?.errors };
      }
      return { status: 'failed', errors: [] }
    }
}

/** 組織の投稿一覧の取得API 呼出 */
export async function fetchTeamChatList(payload) {
  const { team_id, display_type, limit, offset } = payload

  let params = {};
  if ( team_id != null ) params = {...params, team_id}
  if ( display_type ) params = {...params, display_type }
  if ( limit ) params = {...params, limit }
  if ( offset ) params = {...params, offset }

  let response = {}

  await $axios.get('/api/' + Vue.prototype.$constants.API_VERSION + '/team/chat/talk/list', {params})
  .then((res) => {
    response = { status: res.data?.status, value: res.data?.value, errors: [] }
  })
  .catch((e) => {
    if (e.response && e.response.status === 400) {
      response = { status: 'error', errors: e.response.data?.value?.errors };
    }
    response = { status: 'failed', errors: [] }
  })

  return response
}

/** 組織の投稿詳細の取得API 呼出 */
export async function fetchTeamChatInfo(payload) {
  const { chat_id } = payload

  let params = {};
  if ( chat_id != null ) params = {...params, chat_id}

  try {
      const res = await $axios.get('/api/' + Vue.prototype.$constants.API_VERSION + '/team/chat/info', {params})
      return { status: res.data?.status, value: res.data?.value, errors: [] }
  } catch (e) {
    if (e.response && e.response.status === 400) {
      return { status: 'error', errors: e.response.data?.value?.errors };
    }
    return { status: 'failed', errors: [] }
  }
}

/** 組織の投稿のコメントの一覧取得をするAPI 呼出 */
export async function fetchTeamChatCommentList(payload) {
  const { chat_id, text, sort, direction, limit, offset } = payload

  let params = {};
  if ( chat_id != null ) params = {...params, chat_id}
  if ( text ) params = {...params, text}
  if ( sort ) params = {...params, sort}
  if ( direction ) params = {...params, direction}
  if ( limit ) params = {...params, limit}
  if ( offset != null ) params = {...params, offset}

  try {
      const res = await $axios.get('/api/' + Vue.prototype.$constants.API_VERSION + '/team/chat/comment/list', {params})
      return { status: res.data?.status, value: res.data?.value, errors: [] }
  } catch (e) {
    if (e.response && e.response.status === 400) {
      return { status: 'error', errors: e.response.data?.value?.errors };
    }
    return { status: 'failed', errors: [] }
  }
}

export async function fetchCommentReplyList(payload) {
  const { comment_id, text, sort, direction, limit, offset } = payload

  let params = {};
  if ( comment_id != null ) params = {...params, comment_id}
  if ( text ) params = {...params, text}
  if ( sort ) params = {...params, sort}
  if ( direction ) params = {...params, direction}
  if ( limit ) params = {...params, limit}
  if ( offset != null ) params = {...params, offset}

  try {
      const res = await $axios.get('/api/' + Vue.prototype.$constants.API_VERSION + '/team/chat/comment/reply/list', {params})
      return { status: res.data?.status, value: res.data?.value, errors: [] }
  } catch (e) {
    if (e.response && e.response.status === 400) {
      return { status: 'error', errors: e.response.data?.value?.errors };
    }
    return { status: 'failed', errors: [] }
  }
}

/**
 * 組織ユーザー登録・更新API 呼出
 * @param {*} payload
 * @returns ステータス エラーリスト
 */
 export async function editTeamUser(payload) {
  const {user_id, team_id, auth_type, event_flg, status, favorite_flg, pin_flg, good_flg} = payload

  let params = {};
  if(user_id != null) params = {...params, user_id}
  if(team_id != null) params = {...params, team_id}
  if(auth_type) params = {...params, auth_type}
  if(event_flg != null) params = {...params, event_flg}
  if(status) params = {...params, status}
  if(favorite_flg != null) params = {...params, favorite_flg}
  if(pin_flg != null) params = {...params, pin_flg}
  if(good_flg != null) params = {...params, good_flg}

  try {
      const res = await $axios.post('/api/' + Vue.prototype.$constants.API_VERSION + '/team/user/registration', { team_user_list: [params] })
      return { status: res.data?.status, errors: []}
  } catch (e) {
    if (e.response && e.response.status === 400) {
      return { status: 'error', errors: e.response.data?.value?.errors };
    }
    return { status: 'failed', errors: [] }
  }
}

/**
 * 投稿内ユーザー登録・更新API 呼出
 * @param {*} payload
 * @returns ステータス エラーリスト
 */
 export async function editTeamChatFavorite(payload) {
    const {user_id, chat_id, read_flg, favorite_flg, auth_flg, vote_result_flg, pin_flg, good_flg, comment_notice_flg, mention_notice_flg} = payload

    let params = {};
    if(user_id != null) params = {...params, user_id}
    if(chat_id != null) params = {...params, chat_id}
    if(read_flg != null) params = {...params, read_flg}
    if(favorite_flg != null) params = {...params, favorite_flg}
    if(auth_flg != null) params = {...params, auth_flg}
    if(vote_result_flg != null) params = {...params, vote_result_flg}
    if(pin_flg != null) params = {...params, pin_flg}
    if(good_flg != null) params = {...params, good_flg}
    if(comment_notice_flg != null) params = {...params, comment_notice_flg}
    if(mention_notice_flg != null) params = {...params, mention_notice_flg}

    try {
        const res = await $axios.post('/api/' + Vue.prototype.$constants.API_VERSION + '/team/chat/favorite', { chat_users: [params]})
        return { status: res.data?.status, errors: []}
    } catch (e) {
      if (e.response && e.response.status === 400) {
        return { status: 'error', errors: e.response.data?.value?.errors };
      }
      return { status: 'failed', errors: [] }
    }
}

/**
 * 組織の投稿更新API 呼出
 * @param {*} payload
 * @returns ステータス エラーリスト
 */
 export async function editTeamChat(payload) {
  const { chat_id, title, text, open_flg, start_datetime, end_datetime,
          auth_type, vote_result_type, background_color, pin_flg, attention_flg,
          attention_sort, vote_start_datetime, vote_end_datetime, vote_type, path,
          path_list} = payload

  let params = {};
  if(chat_id != null) params = {...params, chat_id}
  if(title) params = {...params, title}
  if(text) params = {...params, text}
  if(open_flg != null) params = {...params, open_flg}
  if(start_datetime) params = {...params, start_datetime}
  if(end_datetime) params = {...params, end_datetime}
  if(auth_type) params = {...params, auth_type}
  if(vote_result_type) params = {...params, vote_result_type}
  if(background_color) params = {...params, background_color}
  if(pin_flg != null) params = {...params, pin_flg}
  if(attention_flg != null) params = {...params, attention_flg}
  if(attention_sort) params = {...params, attention_sort}
  if(vote_start_datetime) params = {...params, vote_start_datetime}
  if(vote_end_datetime) params = {...params, vote_end_datetime}
  if(vote_type) params = {...params, vote_type}
  if(path) params = {...params, path}
  if(path_list && path_list.length > 0) {
    params = {...params, path_list}
  }
  try {
    const res = await $axios.put('/api/' + Vue.prototype.$constants.API_VERSION + '/team/chat/update', params)
    return {status: res.data?.status, errors: []}
  } catch (e) {
    if (e.response && e.response.status === 400) {
      return {status: 'error', errors: e.response.data?.value?.errors};
    }
    return {status: 'failed', errors: []}
  }
}

/**
 * 組織TOPでの投稿のピン止めAPI 呼出
 * @param {*} payload
 * @returns ステータス エラーリスト
 */
export async function editTeamChatPin(payload) {

  const { chat_id, pin_flg } = payload
  let params = {};
  if(chat_id != null) params = {...params, chat_id}
  if(pin_flg != null) params = {...params, pin_flg}

  try {
    const res = await $axios.put('/api/' + Vue.prototype.$constants.API_VERSION + '/team/chat/pin/update', params)
    return {status: res.data?.status, errors: []}
  } catch (e) {
    if (e.response && e.response.status === 400) {
      return {status: 'error', errors: e.response.data?.value?.errors};
    }
    return {status: 'failed', errors: []}
  }
}

/**
 * 組織の投稿削除API 呼出
 * @param {*} payload chat_id
 * @returns ステータス エラーリスト
 */
export async function deleteTeamChat(payload) {
  const {chat_id} = payload
  try {
    const res = await $axios.delete('/api/' + Vue.prototype.$constants.API_VERSION + '/team/chat/delete', {data: {chat_id} })
    return { status: res.data?.status, errors: [] }
  } catch (e) {
    if (e.response && e.response.status === 400) {
      return { status: 'error', errors: e.response.data?.value?.errors };
    }
    return { status: 'failed', errors: [] }
  }
}

/**
 * コーナー一覧取得API 呼出
*/
export async function fetchCornerList(payload) {
  const {id, course_id, team_id, sort, direction, limit} = payload

  let params = {}

  if (id) params = {...params, id}
  if (course_id) params = {...params, course_id}
  if (team_id) params = {...params, team_id}
  if (sort) params = {...params, sort}
  if (direction) params = {...params, direction}
  if (limit) params = {...params, limit}

  try {
    const res = await $axios.get('/api/' + Vue.prototype.$constants.API_VERSION + '/corner/list', {params: params})
    return { status: res.data?.status, value: res.data?.value, errors: []}
  } catch (e) {
    if (e.response && e.response.status === 400) {
      return { status: 'error', errors: e.response.data?.value?.errors };
    }
    return { status: 'failed', errors: [] }
  }
}

/**
 * コーナー内ユーザー更新・登録API 呼出
 * @param {*} payload
 * @returns
 */
export async function editCornerUser(payload) {
  const {
    corner_id, favorite_flg, pin_flg, good_flg
   } = payload

  let params = {}

  if (corner_id != null) params = {...params, corner_id}
  if (favorite_flg != null) params = {...params, favorite_flg}
  if (pin_flg != null) params = {...params, pin_flg}
  if (good_flg != null) params = {...params, good_flg}
  console.log(params)

  try {
    const res = await $axios.post('/api/' + Vue.prototype.$constants.API_VERSION + '/corner/user', params)
    return { status: res.data?.status, errors: []}
  } catch (e) {
    if (e.response && e.response.status === 400) {
      return { status: 'error', errors: e.response.data?.value?.errors };
    }
    return { status: 'failed', errors: [] }
  }
}

/**
 * 講座内ユーザー登録・更新API 呼出
 * @param {*} payload
 */
export async function editLectureHistoryChatFavorite(payload) {
  const { lecture_id, favorite_flg, pin_flg, good_flg, playback_terminal,
          add_display_count, add_view_count, add_sum_view_time } = payload

  let params = {}
  if (lecture_id != null) params = {...params, lecture_id}
  if (favorite_flg != null) params = {...params, favorite_flg}
  if (pin_flg != null) params = {...params, pin_flg}
  if (good_flg != null) params = {...params, good_flg}
  if (playback_terminal) params = {...params, playback_terminal}
  if (add_display_count) params = {...params, add_display_count}
  if (add_view_count) params = {...params, add_view_count}
  if (add_sum_view_time) params = {...params, add_sum_view_time}

  try {
    const res = await $axios.post('/api/' + Vue.prototype.$constants.API_VERSION + '/lecture_history/chat/favorite', {chat_users: [params]})
    return { status: res.data?.status, errors: []}
  } catch (e) {
    if (e.response && e.response.status === 400) {
      return { status: 'error', errors: e.response.data?.value?.errors };
    }
    return { status: 'failed', errors: [] }
  }
}

/** メッセージ一覧取得API 呼出 */
export async function fetchGiftList (payload) {
  const { team_id, gift_id } = payload

  let params = {}
  if (team_id) params = {...params, team_id}
  if (gift_id) params = {...params, gift_id}

  try {
    const res = await $axios.get('/api/' + Vue.prototype.$constants.API_VERSION + '/gift/list', {params: params})
    return { status: res.data?.status, value: res.data?.value?.gift_list, errors: []}
  } catch (e) {
    if (e.response && e.response.status === 400) {
      return { status: 'error', errors: e.response.data?.value?.errors };
    }
    return { status: 'failed', errors: [] }
  }
}

/** バリューコード名称更新API 呼出 */
export async function editGift (payload) {
  const { team_id, gift_id, value_code_name, message } = payload

  let params = {}
  if (team_id) params = {...params, team_id}
  if (gift_id) params = {...params, gift_id}
  if (value_code_name) params = {...params, value_code_name}
  if (message) params = {...params, message}

  try {
    const res = await $axios.put('/api/' + Vue.prototype.$constants.API_VERSION + '/gift/update', params)
    return { status: res.data?.status, errors: []}
  } catch (e) {
    if (e.response && e.response.status === 400) {
      return { status: 'error', errors: e.response.data?.value?.errors };
    }
    return { status: 'failed', errors: [] }
  }
}

/** メッセージ配布設定一覧取得API 呼出 */
export async function fetchGiftSettingList (payload) {
  const { team_id, limit, offset } = payload

  let params = {}
  if (team_id) params = {...params, team_id}
  if (limit) params = {...params, limit}
  if (offset) params = {...params, offset}

  try {
    const res = await $axios.get('/api/' + Vue.prototype.$constants.API_VERSION + '/gift/setting/list', { params: params })
    return { status: res.data?.status, value: res.data?.value?.setting_list, errors: []}
  } catch (e) {
    if (e.response && e.response.status === 400) {
      return { status: 'error', errors: e.response.data?.value?.errors };
    }
    return { status: 'failed', errors: [] }
  }
}

/** メッセージ配布対象者設定登録・更新API 呼出 */
export async function editGiftSetting (payload) {
  const {
    team_id, gift_id, title, body_1,
    body_2, bind_flg, gift_target_id, gift_setting_id
  } = payload

  let params = {}
  if (team_id) params = {...params, team_id}
  if (gift_id) params = {...params, gift_id}
  if (title) params = {...params, title}
  if (body_1) params = {...params, body_1}
  if (body_2) params = {...params, body_2}
  if (bind_flg) params = {...params, bind_flg}
  if (gift_target_id) params = {...params, gift_target_id}
  if (gift_setting_id) params = {...params, gift_setting_id}

  try {
    const res = await $axios.post('api/v1/gift/setting/registration', params)
    return { status: res.data?.status, value: res.data?.value, errors: []}
  } catch (e) {
  if (e.response && e.response.status === 400) {
    return { status: 'error', errors: e.response.data?.value?.errors };
    }
  return { status: 'failed', errors: [] }
  }
}

/** メッセージ配布対象ユーザー一覧取得API 呼出 */
export async function fetchGiftSettingSendList(payload) {
  // /api/' + Vue.prototype.$constants.API_VERSION + '/gift/setting/send/user/list:
  const { gift_target_id, sort, direction } = payload

  let params = {};
  if ( gift_target_id ) params = {...params, gift_target_id}
  if ( sort ) params = {...params, sort}
  if ( direction ) params = {...params, direction}

  try {
      const res = await $axios.get('/api/' + Vue.prototype.$constants.API_VERSION + '/gift/setting/send/list', {params})
      return { status: res.data?.status, value: res.data?.value?.user_list, errors: [] }
  } catch (e) {
    if (e.response && e.response.status === 400) {
      return { status: 'error', errors: e.response.data?.value?.errors };
    }
    return { status: 'failed', errors: [] }
  }
}

/** メッセージ配布対象ユーザー一覧取得API 呼出 */
export async function fetchGiftSettingSendUserList(payload) {
  // /api/' + Vue.prototype.$constants.API_VERSION + '/gift/setting/send/user/list:
  const { team_id, gift_target_id, juid, name_nickname, service_code, gift, auth_type_list,
          chat_id, vote_choice_id_list, sort, direction } = payload

  let params = {};
  if ( team_id ) params = {...params, team_id}
  if ( gift_target_id ) params = {...params, gift_target_id}
  if ( juid ) params = {...params, juid}
  if ( name_nickname ) params = {...params, name_nickname}
  if ( service_code ) params = {...params, service_code}
  if ( gift ) params = {...params, gift}
  if ( auth_type_list ) params = {...params, auth_type_list}
  if ( chat_id ) params = {...params, chat_id}
  if ( vote_choice_id_list ) params = {...params, vote_choice_id_list}
  if ( sort ) params = {...params, sort}
  if ( direction ) params = {...params, direction}

  try {
      const res = await $axios.get('/api/' + Vue.prototype.$constants.API_VERSION + '/gift/setting/send/user/list', {params})
      return { status: res.data?.status, value: res.data?.value?.user_list, errors: [] }
  } catch (e) {
    if (e.response && e.response.status === 400) {
      return { status: 'error', errors: e.response.data?.value?.errors };
    }
    return { status: 'failed', errors: [] }
  }
}

/** メッセージ配布実績ダウンロードAPI 呼出 */
export async function downloadGiftResult (payload) {
  const { gift_setting_id } = payload

  let params = {}
  if (gift_setting_id) params = {...params, gift_setting_id}

  try {
    const res = await $axios.get('/api/' + Vue.prototype.$constants.API_VERSION + '/gift/result/download', { params: params })
    return { status: res.data?.status, value: res.data?.value, errors: []}
  } catch (e) {
    if (e.response && e.response.status === 400) {
      return { status: 'error', errors: e.response.data?.value?.errors };
    }
    return { status: 'failed', errors: [] }
  }
}

/** メッセージ配布対象者設定登録・更新API 呼出 */
export async function editGiftSettingSend (payload) {
  const { team_id, gift_target_id, user_id_list } = payload

  let params = {}
  if (team_id) params = {...params, team_id}
  if (gift_target_id) params = {...params, gift_target_id}
  if (user_id_list) params = {...params, user_id_list}

  try {
    const res = await $axios.post('/api/' + Vue.prototype.$constants.API_VERSION + '/gift/setting/send/registration', params)
    return { status: res.data?.status, value: res.data?.value, errors: []}
  } catch (e) {
  if (e.response && e.response.status === 400) {
    return { status: 'error', errors: e.response.data?.value?.errors };
    }
  return { status: 'failed', errors: [] }
  }
}

/** 差し込みデータダウンロードAPI 呼出 */
export async function downloadGiftSettingSendBind (payload) {
  const { team_id, gift_target_id, user_id_list } = payload

  let params = {}
  if (team_id) params = {...params, team_id}
  if (gift_target_id) params = {...params, gift_target_id}
  if (user_id_list) params = {...params, user_id_list}

  try {
    const res = await $axios.post('/api/' + Vue.prototype.$constants.API_VERSION + '/gift/setting/send/bind/download', params)
    return { status: res.data?.status, value: res.data?.value, errors: []}
  } catch (e) {
  if (e.response && e.response.status === 400) {
    return { status: 'error', errors: e.response.data?.value?.errors };
    }
  return { status: 'failed', errors: [] }
  }
}

/** 差し込みデータアップロードAPI 呼出 */
export async function uploadGiftSettingSendBind (payload) {
  const { team_id, gift_target_id, user_id_list, file_name, file_data } = payload

  let params = {}
  if (team_id) params = {...params, team_id}
  if (gift_target_id) params = {...params, gift_target_id}
  if (user_id_list) params = {...params, user_id_list}
  if (file_name) params = {...params, file_name}
  if (file_data) params = {...params, file_data}

  try {
    const res = await $axios.post('/api/' + Vue.prototype.$constants.API_VERSION + '/gift/setting/send/bind/upload', params)
    return { status: res.data?.status, value: res.data?.value, errors: []}
  } catch (e) {
  if (e.response && e.response.status === 400) {
    return { status: 'error', errors: e.response.data?.value?.errors };
    }
  return { status: 'failed', errors: [] }
  }
}

/** メッセージ配布設定複製API 呼出 */
export async function copyGiftSetting (payload) {
  const { gift_setting_id_list } = payload

  let params = {}
  if (gift_setting_id_list != null) params = {...params, gift_setting_id_list}

  try {
    const res = await $axios.post('/api/' + Vue.prototype.$constants.API_VERSION + '/gift/setting/copy', params)
    return { status: res.data?.status, errors: []}
  } catch (e) {
  if (e.response && e.response.status === 400) {
    return { status: 'error', errors: e.response.data?.value?.errors };
    }
  return { status: 'failed', errors: [] }
  }
}

/** メッセージ配布設定削除API 呼出 */
export async function deleteGiftSetting (payload) {
  const { gift_setting_id_list } = payload

  let params = {}
  if (gift_setting_id_list != null) params = {...params, gift_setting_id_list}

  try {
    const res = await $axios.delete('/api/' + Vue.prototype.$constants.API_VERSION + '/gift/setting/delete', { data: params })
    return { status: res.data?.status, value: res.data?.value?.messages, errors: []}
  } catch (e) {
  if (e.response && e.response.status === 400) {
    return { status: 'error', errors: e.response.data?.value?.errors };
    }
  return { status: 'failed', errors: [] }
  }
}
