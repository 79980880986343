<template>
  <div :class="$style.team_home">
    <!-- チームヘッダ（画像） -->
    <div :class="$style.team_header">
      <team-header
        :team="dataTeam"
        :user_id="user_id"
        :belongsTo="true"
        :indexType="true"
        :informationDisplayNone="false"
      />
    </div>

    <!-- チーム詳細 -->
    <div :class="$style.team_info">
      <team-info
        :data-team="this.dataTeam"
        :user_id="user_id"
        :show-dissolve="true"
        :informationDisplayNone="false"
      ></team-info>
    </div>

    <div :class="$style.team_home_list">
      <!-- 動画一覧（PC） -->
      <div :class="$style.team_home__left_wrapper">
        <!-- 一覧 -->
        <div :class="$style.team_lectures" v-if="dataLectures.length > 0">
          <div :class="$style.team_lectures__list" v-if="!lectureSpinner">
            <div v-for="(model, index) in dataLectures" :key="index">
              <team-lecture
                :borderColor="convertColor(model.bar_color)"
                :head="model.lecture_title"
                :foot="createPageTitle(model)"
                :image="model.lecture_path"
                :selected="!!model.good_flg"
                :tag="model.status"
                :customTagText="model.status"
                :customTagColor="convertColor(model.background_color)"
                :customTagTextColor="convertColor(model.text_color)"
                v-on:select="clickGood(index, $event)"
                v-on:click="$router.push('/union/course/' + model.lecture_id)"
                :class="$style.lecture_item"
              />
            </div>
          </div>

          <!-- ローディング（ページネーション） -->
          <div :class="$style.team_lectures_spinner" v-if="lectureSpinner">
            <base-icon name="spinner" size="32px" spin />
          </div>

          <!-- ページネーション -->
          <page-nav-team-home-lecture
            v-if="pageMaxLecture > 0"
            :class="$style.page_nav_lecture_wrap"
            :page="currentPageLecture"
            :pageMax="pageMaxLecture"
            v-on="$listeners"
            @page="pageNoClickLecture"
            :page-list-num="4"
          />
        </div>

        <!-- ローディング（初回） -->
        <div
          :class="$style.team_lectures_spinner"
          v-if="lectureSpinner && dataLectures.length == 0"
        >
          <base-icon name="spinner" size="32px" spin />
        </div>
      </div>
    </div>

    <div :class="$style.team_home_list">
      <div :class="$style.team_home__right_wrapper">
        <!-- 動画一覧（Mobile） -->
        <div :class="$style.team_home__mobile_area">
          <div
            :class="$style.post_item_list"
            :style="{ width: 50 * dataLectures.length + '%' }"
          >
            <div
              v-for="(featured_chat, i) in dataLectures"
              :class="$style.post_item_wrap"
              :style="{ width: 100 / dataLectures.length + '%' }"
              :key="i"
            >
              <app-carousel-post-item
                v-if="featured_chat.lecture_id !== 0"
                :border-color="
                  featured_chat.team_color
                    ? `rgb(${featured_chat.team_color})`
                    : '#7BE293'
                "
                :head="featured_chat.lecture_title"
                :foot="featured_chat.course_name"
                :time="featured_chat.created_at"
                :image="featured_chat.lecture_path"
                :selected="Boolean(featured_chat.good_flg)"
                :icon="featured_chat.block_user_flg ? '' : 'heart'"
                :customTagText="featured_chat.status"
                :customTagColor="convertColor(featured_chat.background_color)"
                :customTagTextColor="convertColor(featured_chat.text_color)"
                @click="
                  $router.push('/union/course/' + featured_chat.lecture_id)
                "
                @select="clickGood(i, $event)"
              />
            </div>
          </div>
        </div>

        <!-- タブ -->
        <base-list
          v-if="unread_fetched && (chats_fetched || sso_fetched || team_fetched) && lecture_fetched"
          type="normal"
          :class="$style.tab_wrapper"
          id="tabContainer"
        >
          <base-tab
            tab-color="underline"
            :tab="current_tab"
            :unread_exists="unread_exists"
            @tab="tabChange"
          >
            <!-- トーク -->
            <template v-slot:[TAB_TALK]>
              <div :class="$style.tab_contents">
                <!-- リンク（すべて表示、未読のみ表示） -->
                <div
                  :class="[
                    $style.team_condition_btn,
                    $style.team_condition_btn_pc,
                  ]"
                >
                  <div
                    :class="[
                      $style.team_condition_btn_pc_off,
                      {
                        [$style.team_condition_btn_pc_on]:
                          current_display_type === 'all',
                      },
                    ]"
                    @click="fetchChats('all')"
                  >
                    すべて表示({{ all_chats_count }})
                  </div>
                  <div
                    :class="[
                      $style.team_condition_btn_pc_off,
                      {
                        [$style.team_condition_btn_pc_on]:
                          current_display_type === 'unread',
                      },
                    ]"
                    @click="fetchChats('unread')"
                  >
                    未読のみ表示
                  </div>
                </div>

                <!-- 一覧 -->
                <div v-show="chats != null" :class="$style.team_article">
                  <app-team-list-item
                    :class="$style.team_home_list_item"
                    v-for="chat in chats"
                    :key="chat.chat_id"
                    :item="chat"
                    :type="chat_type[chat.type]"
                    :read="!!chat.read_flg"
                    :clip="!!chat.team_pin_flg"
                    :collapse="true"
                    :chat-id="chat.chat_id"
                    :title="chat.title"
                    :time="
                      time(
                        chat.vote_start_datetime,
                        chat.vote_end_datetime,
                        chat.start_datetime,
                        chat.end_datetime,
                        chat.created_at
                      )
                    "
                    :text="chat.text"
                    :read-count="
                      chat.read_count ? String(chat.read_count) : '0'
                    "
                    :comment-count="
                      chat.comment_count ? String(chat.comment_count) : '0'
                    "
                    :goods-count="
                      chat.goods_count ? String(chat.goods_count) : '0'
                    "
                    :favorite-flg="!!chat.user_favorite_flg"
                    :good-flg="!!chat.good_flg"
                    :teamId="team_id"
                    :is-hight-fix="true"
                    :profileUrl="chat.profile_url"
                    @ref-data="reflectData"
                    @click="
                      clickChatOrMiniVoteMove(chat.type, team_id, chat.chat_id)
                    "
                    @select="goodSelect"
                    :body-image="
                      chat.block_user_flg
                        ? ''
                        : chat.image_url
                        ? chat.image_url
                        : ''
                    "
                    :avatar-image="
                      chat.block_user_flg
                        ? require('@/assets/images/block_chat_user.png')
                        : chat.profile_path
                    "
                    :body-color="
                      chat.block_user_flg
                        ? '#DDDDDD'
                        : chat.background_color
                        ? `rgb(${chat.background_color})`
                        : '#FFFFFF'
                    "
                    :path="
                      chat.block_user_flg
                        ? ''
                        : chat.path
                        ? chat.path
                        : chat.image_url
                    "
                    :showPost="!chat.block_user_flg"
                    :showFavorite="!chat.block_user_flg"
                    :editChatFlg="
                      chat.block_user_flg ? false : !!chat.edit_chat_flg
                    "
                    :showPinFlg="chat.block_user_flg ? false : showPinFlg"
                    :showShare="!chat.block_user_flg"
                    :showRead="!chat.block_user_flg"
                    :deleteAuthFlg="
                      chat.block_user_flg ? false : !!chat.delete_auth_flg
                    "
                    :blockUser="chat.block_user_flg"
                    :showBrowse="!!chat.block_user_flg"
                    :switchPost="false"
                    :showBlock="
                      $store.state.auth.user_id == chat.created_by
                        ? false
                        : true
                    "
                    @blockUser="blockUser(chat)"
                    @browse="
                      clickChatOrMiniVoteMove(chat.type, team_id, chat.chat_id)
                    "
                    :report_flg="chat.is_user_reported"
                    :showReport="
                      $store.state.auth.user_id == chat.created_by
                        ? false
                        : true
                    "
                    @report="reportUser(chat)"
                    :spam_flg="chat.spam_flg"
                    @spam="spamChat(chat)"
                    :open_flg="chat.open_flg"
                    :start_datetime="chat.start_datetime"
                    :end_datetime="chat.end_datetime"
                    :comment-notice-flg="!!chat.comment_notice_flg"
                    :mention-notice-flg="!!chat.mention_notice_flg"
                    :comment_latest_user_name="chat.comment_latest_user_name"
                    :comment_latest_updated_at="
                      time(chat.comment_latest_updated_at)
                    "
                    :comment_latest_text="chat.comment_latest_text"
                  ></app-team-list-item>
                </div>

                <!-- ページネーション -->
                <page-nav-team-home
                  v-if="pageMaxChats > 0"
                  :class="$style.page_nav_wrap"
                  :page="currentPage"
                  :pageMax="pageMaxChats"
                  v-on="$listeners"
                  @page="pageNoClick"
                />
              </div>
            </template>

            <!-- 連携サービスからのお知らせ -->
            <template v-slot:[TAB_SERVICE_INFO]>
              <div :class="$style.tab_contents">
                <!-- リンク（すべて表示、未読のみ表示） -->
                <div
                  :class="[
                    $style.team_condition_btn,
                    $style.team_condition_btn_pc,
                  ]"
                >
                  <div
                    :class="[
                      $style.team_condition_btn_pc_off,
                      {
                        [$style.team_condition_btn_pc_on]:
                          current_sso_display_type === 'all',
                      },
                    ]"
                    @click="fetchSsoChats('all')"
                  >
                    すべて表示({{ all_sso_count }})
                  </div>
                  <div
                    :class="[
                      $style.team_condition_btn_pc_off,
                      {
                        [$style.team_condition_btn_pc_on]:
                          current_sso_display_type === 'unread',
                      },
                    ]"
                    @click="fetchSsoChats('unread')"
                  >
                    未読のみ表示
                  </div>
                </div>

                <!-- 一覧 -->
                <div v-show="sso_chats != null" :class="$style.team_article">
                  <app-team-list-item
                    :class="$style.team_home_list_item"
                    v-for="chat in sso_chats"
                    :key="chat.chat_id"
                    :item="chat"
                    :type="chat_type[chat.type]"
                    :read="!!chat.read_flg"
                    :clip="!!chat.team_pin_flg"
                    :collapse="true"
                    :chat-id="chat.chat_id"
                    :title="chat.title"
                    :time="
                      time(
                        chat.vote_start_datetime,
                        chat.vote_end_datetime,
                        chat.start_datetime,
                        chat.end_datetime,
                        chat.created_at
                      )
                    "
                    :text="chat.text"
                    :read-count="
                      chat.read_count ? String(chat.read_count) : '0'
                    "
                    :comment-count="
                      chat.comment_count ? String(chat.comment_count) : '0'
                    "
                    :goods-count="
                      chat.goods_count ? String(chat.goods_count) : '0'
                    "
                    :favorite-flg="!!chat.user_favorite_flg"
                    :good-flg="!!chat.good_flg"
                    :teamId="team_id"
                    :is-hight-fix="true"
                    :profileUrl="chat.profile_url"
                    @ref-data="reflectData"
                    @click="
                      clickChatOrMiniVoteMove(chat.type, team_id, chat.chat_id)
                    "
                    @select="goodSelect"
                    :body-image="
                      chat.block_user_flg
                        ? ''
                        : chat.image_url
                        ? chat.image_url
                        : ''
                    "
                    :avatar-image="
                      chat.block_user_flg
                        ? require('@/assets/images/block_chat_user.png')
                        : chat.profile_path
                    "
                    :body-color="
                      chat.block_user_flg
                        ? '#DDDDDD'
                        : chat.background_color
                        ? `rgb(${chat.background_color})`
                        : '#FFFFFF'
                    "
                    :path="
                      chat.block_user_flg
                        ? ''
                        : chat.path
                        ? chat.path
                        : chat.image_url
                    "
                    :showPost="!chat.block_user_flg"
                    :showFavorite="!chat.block_user_flg"
                    :editChatFlg="
                      chat.block_user_flg ? false : !!chat.edit_chat_flg
                    "
                    :showPinFlg="chat.block_user_flg ? false : showPinFlg"
                    :showShare="!chat.block_user_flg"
                    :showRead="!chat.block_user_flg"
                    :deleteAuthFlg="
                      chat.block_user_flg ? false : !!chat.delete_auth_flg
                    "
                    :blockUser="chat.block_user_flg"
                    :showBrowse="!!chat.block_user_flg"
                    :switchPost="false"
                    :showBlock="
                      $store.state.auth.user_id == chat.created_by
                        ? false
                        : true
                    "
                    @blockUser="blockUser(chat)"
                    @browse="
                      clickChatOrMiniVoteMove(chat.type, team_id, chat.chat_id)
                    "
                    :report_flg="chat.is_user_reported"
                    :showReport="
                      $store.state.auth.user_id == chat.created_by
                        ? false
                        : true
                    "
                    @report="reportUser(chat)"
                    :spam_flg="chat.spam_flg"
                    @spam="spamChat(chat)"
                    :open_flg="chat.open_flg"
                    :start_datetime="chat.start_datetime"
                    :end_datetime="chat.end_datetime"
                    :comment-notice-flg="!!chat.comment_notice_flg"
                    :mention-notice-flg="!!chat.mention_notice_flg"
                    :comment_latest_user_name="chat.comment_latest_user_name"
                    :comment_latest_updated_at="
                      time(chat.comment_latest_updated_at)
                    "
                    :comment_latest_text="chat.comment_latest_text"
                  ></app-team-list-item>
                </div>

                <!-- ページネーション -->
                <page-nav-team-home
                  v-if="pageMaxSso > 0"
                  :class="$style.page_nav_wrap"
                  :page="currentPageSso"
                  :pageMax="pageMaxSso"
                  v-on="$listeners"
                  @page="pageNoClickSso"
                />
              </div>
            </template>

            <!-- チームからのお知らせ -->
            <template v-slot:[TAB_TEAM_INFO]>
              <div :class="$style.tab_contents">
                <!-- リンク（すべて表示、未読のみ表示） -->
                <div
                  :class="[
                    $style.team_condition_btn,
                    $style.team_condition_btn_pc,
                  ]"
                >
                  <div
                    :class="[
                      $style.team_condition_btn_pc_off,
                      {
                        [$style.team_condition_btn_pc_on]:
                          current_team_display_type === 'all',
                      },
                    ]"
                    @click="fetchTeamChats('all')"
                  >
                    すべて表示({{ all_team_count }})
                  </div>
                  <div
                    :class="[
                      $style.team_condition_btn_pc_off,
                      {
                        [$style.team_condition_btn_pc_on]:
                          current_team_display_type === 'unread',
                      },
                    ]"
                    @click="fetchTeamChats('unread')"
                  >
                    未読のみ表示
                  </div>
                </div>

                <!-- 一覧 -->
                <div v-show="team_chats != null" :class="$style.team_article">
                  <app-team-list-item
                    :class="$style.team_home_list_item"
                    v-for="chat in team_chats"
                    :key="chat.chat_id"
                    :item="chat"
                    :type="chat_type[chat.type]"
                    :read="!!chat.read_flg"
                    :clip="!!chat.team_pin_flg"
                    :collapse="true"
                    :chat-id="chat.chat_id"
                    :title="chat.title"
                    :time="
                      time(
                        chat.vote_start_datetime,
                        chat.vote_end_datetime,
                        chat.start_datetime,
                        chat.end_datetime,
                        chat.created_at
                      )
                    "
                    :text="chat.text"
                    :read-count="
                      chat.read_count ? String(chat.read_count) : '0'
                    "
                    :comment-count="
                      chat.comment_count ? String(chat.comment_count) : '0'
                    "
                    :goods-count="
                      chat.goods_count ? String(chat.goods_count) : '0'
                    "
                    :favorite-flg="!!chat.user_favorite_flg"
                    :good-flg="!!chat.good_flg"
                    :teamId="team_id"
                    :is-hight-fix="true"
                    :profileUrl="chat.profile_url"
                    @ref-data="reflectData"
                    @click="
                      clickChatOrMiniVoteMove(chat.type, team_id, chat.chat_id)
                    "
                    @select="goodSelect"
                    :body-image="
                      chat.block_user_flg
                        ? ''
                        : chat.image_url
                        ? chat.image_url
                        : ''
                    "
                    :avatar-image="
                      chat.block_user_flg
                        ? require('@/assets/images/block_chat_user.png')
                        : chat.profile_path
                    "
                    :body-color="
                      chat.block_user_flg
                        ? '#DDDDDD'
                        : chat.background_color
                        ? `rgb(${chat.background_color})`
                        : '#FFFFFF'
                    "
                    :path="
                      chat.block_user_flg
                        ? ''
                        : chat.path
                        ? chat.path
                        : chat.image_url
                    "
                    :showPost="!chat.block_user_flg"
                    :showFavorite="!chat.block_user_flg"
                    :editChatFlg="
                      chat.block_user_flg ? false : !!chat.edit_chat_flg
                    "
                    :showPinFlg="chat.block_user_flg ? false : showPinFlg"
                    :showShare="!chat.block_user_flg"
                    :showRead="!chat.block_user_flg"
                    :deleteAuthFlg="
                      chat.block_user_flg ? false : !!chat.delete_auth_flg
                    "
                    :blockUser="chat.block_user_flg"
                    :showBrowse="!!chat.block_user_flg"
                    :switchPost="false"
                    :showBlock="
                      $store.state.auth.user_id == chat.created_by
                        ? false
                        : true
                    "
                    @blockUser="blockUser(chat)"
                    @browse="
                      clickChatOrMiniVoteMove(chat.type, team_id, chat.chat_id)
                    "
                    :report_flg="chat.is_user_reported"
                    :showReport="
                      $store.state.auth.user_id == chat.created_by
                        ? false
                        : true
                    "
                    @report="reportUser(chat)"
                    :spam_flg="chat.spam_flg"
                    @spam="spamChat(chat)"
                    :open_flg="chat.open_flg"
                    :start_datetime="chat.start_datetime"
                    :end_datetime="chat.end_datetime"
                    :comment-notice-flg="!!chat.comment_notice_flg"
                    :mention-notice-flg="!!chat.mention_notice_flg"
                    :comment_latest_user_name="chat.comment_latest_user_name"
                    :comment_latest_updated_at="
                      time(chat.comment_latest_updated_at)
                    "
                    :comment_latest_text="chat.comment_latest_text"
                  ></app-team-list-item>
                </div>

                <!-- ページネーション -->
                <page-nav-team-home
                  v-if="pageMaxTeam > 0"
                  :class="$style.page_nav_wrap"
                  :page="currentPageTeam"
                  :pageMax="pageMaxTeam"
                  v-on="$listeners"
                  @page="pageNoClickTeam"
                />
              </div>
            </template>
          </base-tab>
        </base-list>
        <!-- /タブ -->
      </div>
    </div>

    <div :class="$style.extended_scroll"></div>

    <team-actions
      @post="handleTeamActionsPost"
      @vote="handleTeamActionsVote"
      :show-post="showPostBtn"
      :show-vote="showVoteBtn"
    />
    <app-scroll-button />
  </div>
</template>
<script>
import BaseIcon from '@/components/base/BaseIcon/BaseIcon'
import AppCarouselPostItem from '@/components/app/AppCarousel/PostItem/AppCarouselPostItemPhase2.9'
import AppTeamListItem from '@/components/app/AppTeamListItem/AppTeamListItemPhase2.9'
import AppScrollButton from '@/components/app/AppScrollButton/AppScrollButton'
import PageNavTeamHome from '@/components/app/AppTableList/PageNavTeamHome'
import PageNavTeamHomeLecture from '@/components/app/AppTableList/PageNavTeamHomeLecture'
import TeamHeader from '@/pages/team/components/TeamHeaderPhase2.9'
import TeamActions from '@/pages/team/components/TeamActions'
import TeamLecture from '@/pages/team/components/TeamLecturePhase2.9'
import store from '@/store'
import { mapMutations, mapGetters, mapActions } from 'vuex'
import {
  editTeamChatFavorite,
  fetchTeamChatList,
  editTeamUser,
  editLectureHistoryChatFavorite,
} from '@/helper/common.js'
import {
  convertColor,
  createPageTitle,
  fromBoolToNumber,
  commitGoodFlg,
} from '@/helper/union/lecture.js'
import TeamInfo from '@/pages/team/components/TeamInfoPhase2.9'
import isMobile from '@/utils/mixins/isMobile'
import BaseList from '@/components/base/BaseList/BaseList'
import BaseTab from '@/components/base/BaseTab/BaseTabPhase2.9'

export default {
  name: 'TeamIndex',
  mixins: [isMobile()],
  components: {
    TeamHeader,
    AppTeamListItem,
    BaseIcon,
    TeamActions,
    AppScrollButton,
    PageNavTeamHome,
    PageNavTeamHomeLecture,
    TeamLecture,
    AppCarouselPostItem,
    TeamInfo,
    BaseList,
    BaseTab,
  },
  props: {
    team_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    const TAB_TALK = 'トーク'
    const TAB_SERVICE_INFO = '連携サービスからのお知らせ'
    const TAB_TEAM_INFO = 'チームからのお知らせ'
    return {
      TAB_TALK,
      TAB_SERVICE_INFO,
      TAB_TEAM_INFO,
      current_tab: TAB_TALK,
      user_id: null,
      dataTeam: null,
      current_display_type: 'all',
      current_sso_display_type: 'all',
      current_team_display_type: 'all',
      errorList: [],
      showPostBtn: false,
      showVoteBtn: false,
      showPinFlg: false,
      processing: false,
      chat_type: { 1: 'post', 2: 'vote', 3: 'gift', 4: 'post' },
      currentPage: 1,
      currentPageSso: 1,
      currentPageTeam: 1,
      currentPageLecture: 1,
      all_chats_count: 0,
      all_sso_count: 0,
      all_team_count: 0,
      unread_chats_count: 0,
      unread_sso_count: 0,
      unread_team_count: 0,
      chats: [],
      sso_chats: [],
      team_chats: [],
      dataLecturesTotalNum: 0,
      perPage: 16,
      perPageLecture: 4,
      like: null,
      notice: null,
      dataLectures: [],
      member_list_view_flg: false, // メンバー管理に遷移できるかフラグ
      lectureSpinner: true,
      unread_exists: {
        [TAB_TALK]: 0,
        [TAB_SERVICE_INFO]: 0,
        [TAB_TEAM_INFO]: 0,
      },
      chats_fetched: false,
      sso_fetched: false,
      team_fetched: false,
      lecture_fetched: false,
      unread_fetched: false,
    }
  },
  beforeRouteEnter(to, from, next) {
    // 遷移元によってページを初期化する
    if (from && from.name) {
      const restore_page_url = [
        'TeamPostDetail',
        'TeamPostCreate',
        'TeamMiniVoteDetail',
        'MiniVoteCreate',
      ]
      if (!restore_page_url.includes(from.name)) {
        sessionStorage.removeItem('currentTab')
        store.commit('team/initTeamCurrentPage')
      }
    } else {
      if (from.path != '/') {
        // リロード時以外
        sessionStorage.removeItem('currentTab')
        store.commit('team/initTeamCurrentPage')
      }
    }
    next()
  },
  computed: {
    ...mapGetters('team', [
      'getDisplayType',
      'getTeamCurrentPage',
      'getTeamCurrentPageSso',
      'getTeamCurrentPageTeam',
      'getLectureCurrentPage',
      'getTeamUserEventFlg',
      'getTeamGoodFlg',
    ]),
    isDisplayTypeAll: function () {
      return this.current_display_type == 'all'
    },
    isDisplayTypeUnRead: function () {
      return this.current_display_type == 'unread'
    },
    pageMaxChats: function () {
      if (this.current_display_type == 'all') {
        return Math.ceil(this.all_chats_count / this.perPage)
      } else {
        return Math.ceil(this.unread_chats_count / this.perPage)
      }
    },
    pageMaxSso: function () {
      if (this.current_display_type == 'all') {
        return Math.ceil(this.all_sso_count / this.perPage)
      } else {
        return Math.ceil(this.unread_sso_count / this.perPage)
      }
    },
    pageMaxTeam: function () {
      if (this.current_display_type == 'all') {
        return Math.ceil(this.all_team_count / this.perPage)
      } else {
        return Math.ceil(this.unread_team_count / this.perPage)
      }
    },
    pageMaxLecture: function () {
      return Math.ceil(this.dataLecturesTotalNum / this.perPageLecture)
    },
    offset: function () {
      return (this.currentPage - 1) * this.perPage
    },
    offsetSso: function () {
      return (this.currentPageSso - 1) * this.perPage
    },
    offsetTeam: function () {
      return (this.currentPageTeam - 1) * this.perPage
    },
    offsetLecture: function () {
      return (this.currentPageLecture - 1) * this.perPageLecture
    },
    badgeImage() {
      return this.dataTeam?.badge_image || ''
    },
    isTablet() {
      const ua = navigator.userAgent.toLowerCase()
      // 前提条件：macはタッチスクリーン不採用
      return /ipad|macintosh|android/.test(ua) && 'ontouchend' in document
    },
  },
  methods: {
    convertColor,
    createPageTitle,
    /** お知らせの表示切り替え */
    async handleNotification(notice) {
      this.notice = notice

      let params = {
        user_id: this.user_id,
        team_id: this.team_id,
        event_flg: notice,
      }
      const status = await editTeamUser(params)
      // TODO: お知らせ登録
      console.error('status: ', status)
    },
    /** いいねの表示切り替え */
    async handleLike(like) {
      this.like = like

      let params = {
        user_id: this.user_id,
        team_id: this.team_id,
        good_flg: like,
      }
      const status = await editTeamUser(params)
      // TODO: いいね登録
      console.error('status: ', status)
    },
    // チーム設定の編集
    ruleClick() {
      this.$router.push(`/team/create/${this.team_id}`)
    },
    ...mapMutations('team', ['setDisplayType']),
    ...mapActions('team', [
      'commitTeamId',
      'commitTeamCurrentPage',
      'commitTeamCurrentPageSso',
      'commitTeamCurrentPageTeam',
      'commitLectureCurrentPage',
    ]),
    async fetchUserInfo() {
      await this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/user/info')
        .then((res) => {
          if (res.data.status == 'success') {
            this.user_id = res.data.value.user.user_id
          } else {
            this.user_id = null
          }
        })
    },
    async fetchTeamInfo() {
      await this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/team/info', {
          params: { team_id: this.team_id },
        })
        .then((res) => {
          this.dataTeam = res.data.value?.team
          this.dataTeam.team_color =
            'rgb(' + res.data.value?.team.team_color + ')'
          this.dataTeam.team_id = this.team_id

          // likeとnoticeの初期値を設定
          this.like = this.dataTeam?.good_flg
          this.notice = this.dataTeam?.event_flg
          this.member_list_view_flg =
            res.data?.value?.team.member_list_view_flg == 1 ? true : false
        })
    },
    async fetchChats(display_type = 'all') {
      if (this.current_display_type != display_type) {
        this.currentPage = 1
      }
      let params = {
        team_id: this.team_id,
        display_type,
        limit: this.perPage,
        offset: this.offset,
      }

      let res = await fetchTeamChatList(params)
      if (res.status == 'success') {
        if (display_type == 'all') {
          this.chats = res.value.chats
          this.all_chats_count = res.value.total_count
          this.current_display_type = display_type
        } else {
          this.chats = res.value.chats
          this.unread_chats_count = res.value.total_count
          this.current_display_type = display_type
        }
        this.chats_fetched = true
      } else {
        this.all_chats = []
        this.unread_chats = []
        this.all_chats_count = 0
        this.unread_chats_count = 0
        this.chats_fetched = true
      }
    },

    async fetchSsoChats(display_type = 'all') {
      if (this.current_display_type != display_type) {
        this.currentPageSso = 1
      }
      let params = {
        team_id: this.team_id,
        display_type,
        limit: this.perPage,
        offset: this.offsetSso,
      }
      await $axios
        .get(
          '/api/' + this.$constants.API_VERSION + '/team/chat/sso_post/list',
          { params }
        )
        .then((res) => {
          if (display_type == 'all') {
            this.sso_chats = res.data.value.chats
            this.all_sso_count = res.data.value.total_count
            this.current_sso_display_type = display_type
          } else {
            this.sso_chats = res.data.value.chats
            this.unread_sso_chats_count = res.data.value.total_count
            this.current_sso_display_type = display_type
          }
        })
        .catch((err) => {
          if (err.response && err.response.status != '200') {
            this.sso_chats = []
            this.all_sso_count = 0
            this.unread_sso_count = 0
          }
        })
        this.sso_fetched = true
    },
    async fetchTeamChats(display_type = 'all') {
      if (this.current_display_type != display_type) {
        this.currentPageTeam = 1
      }
      let params = {
        team_id: this.team_id,
        display_type,
        limit: this.perPage,
        offset: this.offsetTeam,
      }
      await $axios
        .get(
          '/api/' + this.$constants.API_VERSION + '/team/chat/team_notice/list',
          { params }
        )
        .then((res) => {
          if (display_type == 'all') {
            this.team_chats = res.data.value.chats
            this.all_team_count = res.data.value.total_count
            this.current_team_display_type = display_type
          } else {
            this.team_chats = res.data.value.chats
            this.unread_team_chats_count = res.data.value.total_count
            this.current_team_display_type = display_type
          }
        })
        .catch((err) => {
          if (err.response && err.response.status != '200') {
            this.team_chats = []
            this.all_team_count = 0
            this.unread_team_count = 0
          }
        })
        this.team_fetched = true
    },
    async fetchUnreadFlg() {
      let params = {
        team_id: this.team_id,
      }
      await $axios
        .get(
          '/api/' + this.$constants.API_VERSION + '/team/chat/not_displayed',
          { params }
        )
        .then((res) => {
          this.unread_exists = {
            [this.TAB_TALK]: res.data.value.talk,
            [this.TAB_SERVICE_INFO]: res.data.value.sso_post,
            [this.TAB_TEAM_INFO]: res.data.value.team_notice,
          }
          this.unread_fetched = true
        })
        .catch((err) => {
          if (err.response && err.response.status != '200') {
            this.unread_exists = {
              [this.TAB_TALK]: 0,
              [this.TAB_SERVICE_INFO]: 0,
              [this.TAB_TEAM_INFO]: 0,
            }
          }
          this.unread_fetched = true
        })
    },
    async fetchScreenAuthChatRegist() {
      this.showPostBtn = false
      await this.$axios
        .post(
          '/api/' +
            this.$constants.API_VERSION +
            '/screen_auth/chat/registration',
          {
            team_id: this.team_id,
            type: 1,
          }
        )
        .then((res) => {
          if (res.data.status == 'success') {
            this.showPostBtn = true
          }
        })
        .catch((err) => {
          if (err.response.status === 461) {
            // 投稿権限なし
            this.showPostBtn = false
          }
        })
    },
    async fetchScreenAuthMiniVoteRegist() {
      this.showVoteBtn = false
      await this.$axios
        .post(
          '/api/' +
            this.$constants.API_VERSION +
            '/screen_auth/mini_vote/registration',
          {
            team_id: this.team_id,
          }
        )
        .then((res) => {
          if (res.data.status == 'success') {
            this.showVoteBtn = true
          }
        })
        .catch((err) => {
          if (err.response.status === 461) {
            // ミニ投票権限なし
            this.showVoteBtn = false
          }
        })
    },
    async fetchScreenAuthTeamPin() {
      await this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/screen_auth/team/pin', {
          team_id: this.team_id,
        })
        .then((res) => {
          if (res.data.status == 'success') {
            this.showPinFlg = !!res?.data?.value?.pin_flg
          }
        })
    },
    async fetchLectureData() {
      this.lectureSpinner = true
      let params = {
        team_id: this.team_id,
        unread_flg: 0,
        sort: 'created_at',
        direction: 'asc',
        shared_state: 1,
        limit: this.perPageLecture,
        offset: this.offsetLecture,
      }

      await this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/lecture/list', {
          params,
        })
        .then((res) => {
          this.dataLectures = res.data.value.lecture_list
          this.dataLecturesTotalNum = res.data.value.total_cnt
          this.lectureSpinner = false
          this.lecture_fetched = true
        })
    },
    async clickGood(index, flg) {
      if (this.processing) return

      const good_flg = fromBoolToNumber(flg)
      const lecture = this.dataLectures[index]
      this.processing = true
      const result = await editLectureHistoryChatFavorite({
        lecture_id: lecture.lecture_id,
        good_flg: good_flg,
      })
      if (result.status == 'success') {
        commitGoodFlg(this.dataLectures, index, good_flg)
      }
      this.processing = false
    },
    async initFetch() {
      await this.fetchUnreadFlg()
      await this.fetchUserInfo()
      await this.fetchTeamInfo()
      switch (this.current_tab) {
        case this.TAB_TALK:
          await this.fetchChats('all')
          break
        case this.TAB_SERVICE_INFO:
          await this.fetchSsoChats('all')
          break
        case this.TAB_TEAM_INFO:
          await this.fetchTeamChats('all')
          break
        default:
          await this.fetchChats('all')
          break
      }
      await this.fetchLectureData()
      await this.fetchScreenAuthChatRegist()
      await this.fetchScreenAuthMiniVoteRegist()
      await this.fetchScreenAuthTeamPin()
    },
    reflectData(resultObj) {
      const { type, chat_id, data, errors } = resultObj
      this.errorList = errors
      let targetChat = null
      switch (type) {
        case 'favorite': // お気に入りに追加/削除する
          targetChat = this.chats.find((chat) => chat.chat_id === chat_id)
          if (!targetChat) {
            return
          }
          targetChat.user_favorite_flg = data.user_favorite_flg
          break
        case 'clip': // クリップする/外す
          targetChat = this.chats.find((chat) => chat.chat_id === chat_id)
          if (!targetChat) {
            return
          }
          targetChat.team_pin_flg = data
          break
        case 'read': // 未読/既読にする
          targetChat = this.chats.find((chat) => chat.chat_id === chat_id)
          if (!targetChat) {
            return
          }
          targetChat.read_flg = data.read_flg
          targetChat.read_count = data.read_count
          break
        case 'delete': // 削除する
          if (chat_id) {
            this.chats = this.chats.filter((chat) => {
              return chat.chat_id !== chat_id
            })
            window.location.reload()
          }
          break
        default:
          break
      }
    },
    handleTeamActionsPost() {
      // 投稿の処理
      this.$router.push(`/team/post-create/${this.team_id}/`)
    },
    handleTeamActionsVote() {
      // ミニ投票の処理
      this.$router.push(`/team/mini-vote-create/${this.team_id}/`)
    },
    time: function (
      vote_start_datetime,
      vote_end_datetime,
      start_datetime,
      end_datetime,
      created_at
    ) {
      let startFormatStr = ''
      let endFormatStr = ''

      if (vote_start_datetime || vote_end_datetime) {
        if (vote_start_datetime) {
          startFormatStr =
            this.$dayjs(vote_start_datetime).format('YYYY/MM/DD HH:mm')
        } else if (created_at) {
          startFormatStr = this.$dayjs(created_at).format('YYYY/MM/DD HH:mm')
        }
        if (vote_end_datetime) {
          endFormatStr =
            this.$dayjs(vote_end_datetime).format('YYYY/MM/DD HH:mm')
        }
      } else {
        if (start_datetime) {
          startFormatStr =
            this.$dayjs(start_datetime).format('YYYY/MM/DD HH:mm')
        } else if (created_at) {
          startFormatStr = this.$dayjs(created_at).format('YYYY/MM/DD HH:mm')
        }
        if (end_datetime) {
          endFormatStr = this.$dayjs(end_datetime).format('YYYY/MM/DD HH:mm')
        }
      }

      // 表示設定
      if (startFormatStr && !endFormatStr) {
        return startFormatStr
      }
      if (startFormatStr && endFormatStr) {
        return startFormatStr + ' - ' + endFormatStr
      }
      return ''
    },
    limitComment: function (comment) {
      let commentStr = ''
      if (comment && comment.length > 26) {
        commentStr = comment.substring(0, 26) + '…'
      } else {
        commentStr = comment
      }
      // 表示設定
      if (commentStr) {
        return commentStr
      }
      return ''
    },
    chatImg: function (path_list) {
      if (path_list || path_list.length == 0) return ''

      return path_list[0].image_url
    },
    clickChatOrMiniVoteMove: function (type, team_id, chat_id) {
      let targetUrl = ''
      switch (type) {
        case 2: // ミニ投票
          targetUrl = `/team/mini-vote-detail/${team_id}/${chat_id}`
          break
        default:
          targetUrl = `/team/post-detail/${team_id}/${chat_id}`
          break
      }
      this.$router.push(targetUrl)
    },
    async goodSelect(chat) {
      if (this.processing) {
        return
      }
      this.processing = true
      const { chat_id, good_flg } = chat
      const targetFlg = !!good_flg == true ? 0 : 1
      // 組織が投稿をお気に入りにするAPI call
      const { status } = await editTeamChatFavorite({
        chat_id,
        good_flg: targetFlg,
      })
      if (status == 'success') {
        chat.good_flg = targetFlg
        const targetGoodCount = chat.goods_count ? chat.goods_count : 0
        chat.goods_count = targetFlg ? targetGoodCount + 1 : targetGoodCount - 1
      }
      this.processing = false
    },
    blockUser: async function (chat) {
      if (this.processing) {
        return
      }
      this.processing = true
      if (chat.block_user_flg) {
        if (!confirm('ユーザのブロックを解除しますか？')) {
          this.request_sending = false
          return
        }
        await this.deleteBlockUser(chat.created_by)
      } else {
        if (!confirm('ユーザをブロックしますか？')) {
          this.request_sending = false
          return
        }
        await this.updateBlockUser(chat.created_by)
      }
      this.processing = false
    },
    async updateBlockUser(block_user_id) {
      await this.$axios
        .post(
          '/api/' + this.$constants.API_VERSION + '/user/block/registration',
          {
            block_user_id: block_user_id,
          }
        )
        .then((res) => {
          if (res.data.status == 'success') {
            //データ再取得
            this.fetchChats(this.current_display_type)
            alert('ユーザをブロックしました')
          }
        })
        .catch((err) => {
          if (err.response.status != '200') {
            alert(err.response.data.value.errors[0].message)
          }
        })
    },
    async deleteBlockUser(block_user_id) {
      await this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/user/block/delete', {
          block_user_id: block_user_id,
        })
        .then((res) => {
          if (res.data.status == 'success') {
            //データ再取得
            this.fetchChats(this.current_display_type)
            alert('ユーザのブロックを解除しました')
          }
        })
        .catch((err) => {
          if (err.response.status != '200') {
            alert(err.response.data.value.errors[0].message)
          }
        })
    },
    reportUser: async function (chat) {
      if (this.processing) {
        return
      }
      if (chat.is_user_reported) {
        //３点リーダーを閉じる
      } else {
        if (!confirm('このユーザを悪質なユーザとして報告しますか？')) {
          return
        }
        this.processing = true
        await this.callReportUser(chat.created_by)
      }
      this.processing = false
      return
    },
    callReportUser: async function (user_id) {
      await this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/user/report', {
          reported_user_id: user_id,
        })
        .then((res) => {
          if (res.data.status == 'success') {
            //データ再取得
            this.initFetch()
            alert('管理者が内容を確認し、24時間以内に対応を致します。')
          }
        })
        .catch((err) => {
          if (err.response.status != '200') {
            alert(err.response.data.value.errors[0].message)
          }
        })
    },
    spamChat: async function (chat) {
      if (this.processing) {
        return
      }
      if (chat.spam_flg) {
        //３点リーダーを閉じる
      } else {
        if (!confirm('この投稿を悪質な投稿として報告しますか？')) {
          return
        }
        this.processing = true
        await this.reportSpamChat(chat.chat_id)
      }
      this.processing = false
    },
    async reportSpamChat(chat_id) {
      await this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/team/chat/spam', {
          chat_id: chat_id,
        })
        .then((res) => {
          if (res.data.status == 'success') {
            //データ再取得
            this.fetchChats(this.current_display_type)
            alert('管理者が内容を確認し、24時間以内に対応を致します。')
          }
        })
        .catch((err) => {
          if (err.response.status != '200') {
            alert(err.response.data.value.errors[0].message)
          }
        })
    },
    pageNoClick(page) {
      this.currentPage = page
      this.commitTeamCurrentPage({ page })
      this.fetchChats(this.current_display_type)
    },
    pageNoClickSso(page) {
      this.currentPageSso = page
      this.commitTeamCurrentPageSso({ page })
      this.fetchSsoChats(this.current_display_type)
    },
    pageNoClickTeam(page) {
      this.currentPageTeam = page
      this.commitTeamCurrentPageTeam({ page })
      this.fetchTeamChats(this.current_display_type)
    },
    pageNoClickLecture: function (page) {
      this.currentPageLecture = page
      this.commitLectureCurrentPage({ page })
      this.fetchLectureData()
    },
    tabChange(name) {
      this.current_tab = name
      sessionStorage.setItem('currentTab', this.current_tab)
      if (name == this.TAB_SERVICE_INFO) {
        this.fetchSsoChats()
      } else if (name == this.TAB_TEAM_INFO) {
        this.fetchTeamChats()
      } else if (name == this.TAB_TALK) {
        this.fetchChats()
      }
    },
    setSavedValue() {
      const storedTab = sessionStorage.getItem('currentTab')
      if (storedTab !== null) {
        this.current_tab = storedTab
      }
    },
  },
  created() {
    this.currentPage = this.getTeamCurrentPage(this.team_id) || 1
    this.currentPageSso = this.getTeamCurrentPageSso(this.team_id) || 1
    this.currentPageTeam = this.getTeamCurrentPageTeam(this.team_id) || 1
    this.currentPageLecture = this.getLectureCurrentPage(this.team_id) || 1
    this.commitTeamId({ team_id: this.team_id })
    this.$router.app.$emit('page-updated', {
      bgColor: 'yellow',
      back: `/home/home`,
      pageTitle: '',
      show_dissolve: true,
    })
  },
  mounted() {
    this.setSavedValue()
    this.initFetch()
  },
}
</script>
<style lang="scss" module>
.tab_contents {
  padding: 20px 0;
  @include mobile() {
    padding: 20px 1rem;
  }
}
.team_condition_btn {
  @include mobile() {
    top: 175px;
    right: 2%;
  }

  &_fc {
    margin-right: 10px;

    @include mobile() {
      margin-right: 5px;
    }
  }
}

.team_info {
  position: relative;
  padding: 70px 90px 0 90px;

  @include mobile() {
    padding: 0;
  }
}

.team_home_list {
  margin: 0 auto;
  padding: 0 90px;

  @include mobile() {
    padding: 0;
  }

  &_item {
    margin: 10px auto;
  }
}

.page_nav_wrap {
  text-align: center;
  margin: 60px 0 150px;

  @include mobile() {
    margin-bottom: 30px;
    display: none;
  }
}

.page_nav_lecture_wrap {
  text-align: center;
  margin: 0px;
}

.team_info__name {
  word-break: break-all;
}

// phase2.1
.team_home_list {
  // justify-content: space-between;
  gap: 0 40px;

  @include mobile() {
    max-width: 905px;
  }

  &_item {
    margin: 10px auto;
  }
}

.team_home__left_wrapper {
  width: auto;
  margin-bottom: 10px;

  .team_lectures_title {
    padding: 0px 10px;
    display: flex;
    align-items: center;

    .text {
      margin: 0;
      padding-left: 6px;
      color: $keyGreen;
    }
  }
  .team_lectures_spinner {
    height: 360px;
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .team_lectures {
    margin: 10px 0;
    height: 330px;

    .team_lectures_spinner {
      height: 300px;
      margin: 0;
    }

    &__list {
      width: auto;
      height: 280px;
      display: flex;

      > div {
        width: 25%;

        .lecture_item {
          margin-inline-start: 1rem;
          margin-inline-end: 1rem;
          margin-bottom: 1rem;
        }
      }
      > div:first-child .lecture_item {
        margin-inline-start: 0;
      }
      > div:last-child .lecture_item {
        margin-inline-end: 0;
      }
    }
  }

  .team_info {
    border: 1pt solid rgb(214, 217, 218);
    border-radius: 10pt;
    padding: 30px;
    width: 300px;

    &__header {
      display: flex;
      justify-content: space-between;
    }

    &__badge {
      width: 150px;
      height: 150px;

      @include mobile() {
        width: 90px;
        height: 90px;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
        border: solid 1.5pt rgb(100, 221, 179);
      }
    }

    &__settings {
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;
      width: 60px;
    }

    &__settings_button {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    &__settings_icon {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }

    &__settings_text {
      line-height: 1;
      margin-top: 5px;
      margin-left: 5px;
      font-size: 9.75pt;
      font-weight: bold;
    }

    &__icon {
      display: flex;
      justify-content: space-between;
      width: 60px;
    }

    &__notification {
      width: 22px;
      cursor: pointer;

      img {
        width: 100%;
        vertical-align: top;
      }
    }

    &__body {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    &__name {
      font-size: 22pt;

      @include mobile() {
        font-size: 18pt;
      }

      font-weight: bold;
      margin: 11.625pt 0 8.25pt 0;
    }

    &__detail {
      font-size: 12pt;

      @include mobile() {
        font-size: 9.75pt;
      }
    }

    &__profile {
      font-size: 15pt;
      margin: 40px 0;

      @include mobile() {
        font-size: 10pt;
      }
    }

    &__app_icon {
      p {
        font-size: 12pt;

        @include mobile() {
          font-size: 9.75pt;
        }

        font-weight: bold;
        margin: 0 0 5px 0;
      }

      ul {
        list-style: none;
        padding: 0;
        display: flex;
        align-items: flex-start;
        gap: 0 10px;

        li {
          width: 30px;

          img {
            width: 100%;
            vertical-align: top;
          }
        }
      }
    }
  }
}

.team_home__right_wrapper {
  .team_home_list_item {
    margin: 0 0 10px;
  }

  .team_condition_btn_pc {
    margin-bottom: 25px;
    display: flex;
    gap: 0 20px;
  }

  .team_condition_btn_pc_off {
    color: rgb(45, 204, 211);
    font-size: 16px;
    text-decoration: underline;
    cursor: pointer;

    &.team_condition_btn_pc_on {
      color: black;
      font-weight: bold;
      display: inline-block;
      border-bottom: solid 1.5pt;
      text-decoration: none;
    }
  }
}

.team_home__mobile_area {
  display: none;
}

.post_item_list {
  padding: 0;

  .post_item_wrap {
    float: left;
    margin-bottom: 1rem;
  }

  .post_item_wrap section {
    margin-inline-start: 0.5rem;
    margin-inline-end: 0.5rem;
  }
}

@include mobile() {
  .team_home_list {
    display: block;
    margin-bottom: 16px;

    &_item {
      margin: 10px auto;
    }
  }

  .team_home__left_wrapper {
    display: none;
  }

  .team_home__mobile_area {
    display: block;
    overflow-x: scroll;
    margin: 0 0.5rem 40px;
  }
}
.extended_scroll {
  height: 50px;
}

.content_list {
  padding: 0;
  margin: 12px 0 20px 0;
  max-width: 1280px;
  @media (max-width: 768px) {
    margin-top: 0;
  }
}
.content_item {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: solid 1px $borderGray;

  & > *:not(:last-child) {
    margin-right: 10px;
  }
}
.tab_wrapper {
  margin-bottom: 0;
}
.unread_marker_talk {
  margin-left: 10px;
  color: red;
}
.unread_marker_sso {
  margin-left: 20px;
  color: blue;
}
.unread_marker_team {
  margin-left: 30px;
  color: greenyellow;
}
.list_button_wrapper {
  display: flex;
  gap: 20px;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
}
</style>
