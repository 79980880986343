<template>
  <div>
      <div :class="$style.team_header" :style="headerBgStyle" />
  </div>
</template>
<script>
import isMobile from "@/utils/mixins/isMobile";
import { mapGetters } from 'vuex'

export default {
  name: 'TeamHeader',
  mixins: [isMobile()],
  props: {
    team: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      processing: false,
      admin_notification_flg: false
    }
  },
  computed: {
    ...mapGetters('team'),
    headerBgStyle() {
      const teamCoverImg = this.team?.cover_image || ''
      return {
        backgroundImage: `url(${teamCoverImg})`,
      }
    },
  },
}
</script>
<style lang="scss" module>
.team_header {
  position:absolute;
  /*height: 255px;*/
  width: 100%;
  max-width: 1280px;
  height: 225px;

  @include mobile() {
    position:relative;
    height: 170px;
  }

  background-position: center;
  background-size: cover;
}

</style>
