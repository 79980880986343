<template>
  <div :class="$style.home_notice_edit_bg">
    <div :class="$style.home_notice_edit_main">
      <div :class="$style.home_notice_edit">
        <app-prev-button
          margin="10px 10px 0px"
          @click="backButtonAction(prevRoute)"
        />
        <div :class="$style.notice_edit_wrap">
          <div :class="$style.notice_border">
            <p :class="$style.title_text">チームからの通知</p>
            <p :class="$style.normal_text">
              チームからの通知とは、あなたが所属する全てのチーム
            </p>
            <p :class="$style.normal_text">
              の新しい投稿や投票、コメントを指します。
            </p>
            <p :class="$style.normal_text">ここではその通知を設定します。</p>
            <p :class="$style.title_text">チーム、投稿通知一括設定</p>
            <p :class="$style.normal_text">チームの設定を一括で変更します。</p>
            <div :class="$style.normal_button_area">
              <base-button
                rounded="rounded"
                width="222px"
                height="40px"
                @click.prevent="toggleTeamNoticeOn"
                >一括で全てをONにする
              </base-button>
            </div>
            <div :class="$style.normal_button_area">
              <base-button
                rounded="rounded"
                width="222px"
                height="40px"
                @click.prevent="toggleTeamNoticeOff"
                >一括で全てをOFFにする
              </base-button>
            </div>
            <div :class="$style.normal_button_area">
              <base-button
                rounded="rounded"
                width="222px"
                height="40px"
                @click.prevent="toggleTeamNoticeInitialize"
                >チーム通知を初期設定に戻す</base-button
              >
            </div>
          </div>
          <div :class="$style.notice_border">
            <p :class="$style.title_text">お知らせ通知</p>
            <div :class="$style.text_button_area">
              <p :class="$style.switch_text_area">サービスからのお知らせ</p>
              <base-button
                :class="$style.switch_button_area"
                rounded="corner"
                width="85px"
                height="30px"
                @click.prevent="toggleServiceNotification"
                >{{
                  this.service_notification_flg ? 'OFFにする' : 'ONにする'
                }}</base-button
              >
            </div>
            <div :class="$style.text_button_area">
              <p :class="$style.switch_text_area">注目の講座、イベント発生</p>
              <base-button
                :class="$style.switch_button_area"
                rounded="corner"
                width="85px"
                height="30px"
                @click.prevent="toggleEventNotification"
                >{{
                  this.event_notification_flg ? 'OFFにする' : 'ONにする'
                }}</base-button
              >
            </div>
            <div :class="$style.text_button_area">
              <p :class="$style.switch_text_area">スコア集計</p>
              <base-button
                :class="$style.switch_button_area"
                rounded="corner"
                width="85px"
                height="30px"
                @click.prevent="toggleScoreNotification"
                >{{
                  this.score_notification_flg ? 'OFFにする' : 'ONにする'
                }}</base-button
              >
            </div>
            <div :class="$style.text_button_area">
              <p :class="$style.switch_text_area">新しい端末からのアクセス</p>
              <base-button
                :class="$style.switch_button_area"
                rounded="corner"
                width="85px"
                height="30px"
                @click.prevent="toggleNewDeviceNotification"
                >{{
                  this.new_device_notification_flg ? 'OFFにする' : 'ONにする'
                }}</base-button
              >
            </div>
          </div>
          <div :class="$style.notice_border">
            <p :class="$style.title_text">通知をメールで受け取る</p>
            <div :class="$style.notice_mail_button_area">
              <base-button
                rounded="rounded"
                width="309px"
                height="40px"
                @click.prevent="toggleEmailReceiveNotification"
                >{{
                  this.email_receive_flg
                    ? 'メール受け取りをOFFにする'
                    : 'メール受け取りをONにする'
                }}</base-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import BaseButton from '@/components/base/BaseButton/BaseButton'
import AppPrevButton from '@/components/app/AppPrevButton/AppPrevButton'
export default {
  name: 'HomeNoticeEdit',
  components: {
    BaseButton,
    AppPrevButton,
  },
  mounted() {
    this.emitPageUpdated()
    this.fetchNoticeSetting()
    console.log('emitPageUpdated')
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from && from.name) {
        if (from.name == 'HomeNoticeEdit') {
          vm.prevRoute = { name: 'HomeHome' }
        } else {
          vm.prevRoute = from
        }
      } else {
        vm.prevRoute = '__history_back'
      }
    })
  },
  data() {
    return {
      prevRoute: '',
      service_notification_flg: false,
      event_notification_flg: false,
      score_notification_flg: false,
      new_device_notification_flg: false,
      email_receive_flg: false,
      bulk_change_value: null,
      notification_flg_switch: false
    }
  },
  methods: {
    toggleTeamNoticeOn() {
      this.bulk_change_value = 'on';
      if (confirm('設定を変更してもよろしいですか？')) this.updateNoticeSetting(true);
    },
    toggleTeamNoticeOff() {
      this.bulk_change_value = 'off';
      if (confirm('設定を変更してもよろしいですか？')) this.updateNoticeSetting(true);
    },
    toggleTeamNoticeInitialize() {
      this.bulk_change_value = 'initialize';
      if (confirm('設定を変更してもよろしいですか？')) this.updateNoticeSetting(true);
    },
    toggleServiceNotification: function () {
      this.service_notification_flg = !this.service_notification_flg
      this.notification_flg_switch = this.service_notification_flg;
      this.updateNoticeSetting();
    },
    toggleEventNotification: function () {
      this.event_notification_flg = !this.event_notification_flg
      this.notification_flg_switch = this.event_notification_flg;
      this.updateNoticeSetting();
    },
    toggleScoreNotification: function () {
      this.score_notification_flg = !this.score_notification_flg
      this.notification_flg_switch = this.score_notification_flg;
      this.updateNoticeSetting();
    },
    toggleNewDeviceNotification: function () {
      this.new_device_notification_flg = !this.new_device_notification_flg
      this.notification_flg_switch = this.new_device_notification_flg;
      this.updateNoticeSetting();
    },
    toggleEmailReceiveNotification: function () {
      this.email_receive_flg = !this.email_receive_flg
      this.notification_flg_switch = this.email_receive_flg;
      this.updateNoticeSetting();
    },
    fetchNoticeSetting() {
      this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/user/notification/setting')
        .then((res) => {
          this.service_notification_flg = res.data.value.service_notification_flg
          this.event_notification_flg = res.data.value.event_notification_flg
          this.score_notification_flg = res.data.value.score_notification_flg
          this.new_device_notification_flg = res.data.value.new_device_notification_flg
          this.email_receive_flg = res.data.value.email_receive_flg
        })
    },
    async updateNoticeSetting(is_bulk_change = false) {
      var is_updated_success = false
      if (is_bulk_change) {
        await this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/user/notification/bulk_change', {
          run_type: this.bulk_change_value
        })
        .then((res) => {
          if (res.data.status == 'success') {
            is_updated_success = true
          }
        })
      } else {
        await this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/user/notification/setting', {
          service_notification_flg: this.service_notification_flg,
          event_notification_flg: this.event_notification_flg,
          score_notification_flg: this.score_notification_flg,
          new_device_notification_flg: this.new_device_notification_flg,
          email_receive_flg: this.email_receive_flg
        })
        .then((res) => {
          if (res.data.status == 'success') {
            is_updated_success = true
          }
        })
      }

      if (is_updated_success) {
        if (is_bulk_change) {
          alert('設定を変更しました')
        }
        else if(this.notification_flg_switch) {
          alert('設定をONにしました')
        }
        else {
          alert('設定をOFFにしました')
        }
      } else {
        alert('設定を変更できませんでした。時間をおいて再度お試しください。')
      }
    },
    emitPageUpdated() {
      this.$router.app.$emit('page-updated', {
        pageTitle: '通知設定',
        showSetting: false,
        back: this.prevRoute,
        bgColor: 'white',
        headerColor: 'yellow',
        showTabbar: false,
        showFooter: true,
      })
    },
    scrollToTop: function () {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    },
    backButtonAction(back) {
      if (back === '__history_back') {
        this.$router.go(-1)
      } else {
        this.$router.push(back, () => {})
      }
    },
  },
}
</script>

<style lang="scss" module>
.home_notice_edit_bg {
  width: 100%;
  background-color: $backGround;
}

.home_notice_edit {
  @include pc() {
    // width: 980px;//TODO:
    width: 400px;
    margin: 0 auto;
    background-color: $keyWhite;
  }

  @include mobile() {
    width: 400px;
    background-color: $keyWhite;
    margin: 0 auto;
    padding-bottom: 84px;
  }

  &_content {
    width: 375px;
    margin: 0 auto;
    margin-top: 12px;
    @include mobile() {
      margin-top: 10px;
    }
  }
}

.home_notice_edit_main {
  display: block;
  width: 100%;
  background-color: $keyWhite;

  .notice_edit_wrap {
    display: flex;
    flex-direction: column;
    align-items: left;

    .notice_border {
      box-sizing: border-box;
      border-bottom: 1px $borderLiteGray solid;
    }

    .normal_button_area {
      margin-bottom: 20px;
      margin-top: 17px;
      margin-left: 40px;
    }

    .notice_mail_button_area {
      margin-bottom: 20px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .text_button_area {
      display: flex;
      justify-content: left;

      .switch_text_area {
        font-size: 12px;
        width: 240px;
        margin-bottom: 0;
        margin-top: 10px;
        margin-left: 30px;
      }
      .switch_button_area {
        margin-bottom: 10px;
        margin-top: 0px;
        margin-left: 30px;
        font-size: 9px;
      }
    }

    .title_text {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 5px;
      margin-top: 20px;
      margin-left: 30px;
    }
    .normal_text {
      font-size: 12px;
      margin-bottom: 0;
      margin-top: 3px;
      margin-left: 30px;
    }
  }
}

.home_notice_edit_footer {
  display: block;
  position: sticky;
  width: 100%;
  height: 85px;
  bottom: 0;
  background-color: $keyYellow;

  @include mobile() {
    background: $keyYellow;
    box-sizing: border-box;
    border-top: 1px solid $borderLiteGray;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 12;
  }

  .confirm_button_wrap {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    & > :first-child {
      margin-right: 20px;
    }
  }
}
</style>
