<template>
  <div :class="$style.stamp_tab">
    <div :class="$style.tab_header">
      <ul :class="$style.tabs">
        <li
          v-for="(tab, tabIndex) in tabData"
          :key="tab.tabName"
          :class="$style.tab"
          @click="isSelect(tabIndex)"
        >
          <div :class="[$style.tab_image, { [$style.active]: currentTab === tabIndex }]">
            <img
              :src="tab.tabImagePath"
              :alt="tab.tabName"
            >
          </div>
        </li>
      </ul>
    </div>
    <div :class="$style.divide_line"></div>
    <div :class="$style.tab_body">
      <div v-for="(tab, tabIndex) in tabData"
           :key="tab.tabName"
           :class="[$style.tab_items_wrap,{ [$style.active]: currentTab === tabIndex}]"
      >
        <ul :class="$style.tab_items">
          <li v-for="(item, itemIndex) in tab.tabItems"
              :key="item.id"
              :class="$style.tab_item"
          >
            <div :class="$style.tab_item_image_wrap">
              <label :class="$style.tab_item_image">
                <input name="stamp" type="radio" :value="itemIndex" v-model="selectedItem">
                <img :src="item.image_path" :alt="item.name">
              </label>
              <img v-if="isSelectedItem(itemIndex)" src="@/assets/images/stamp-checked.svg" :class="$style.tab_item_check_icon">
              <img v-else src="@/assets/images/stamp-unchecked.svg" :class="$style.tab_item_check_icon">
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div :class="$style.stamp_tab_action">
      <base-button
        width="185px"
        rounded="rounded"
        @click="stampSubmit"
        :bgColor="selectedItem == null ? 'gray': 'green'"
        :disabled="selectedItem == null"
      >挿入する</base-button>
    </div>
  </div>
</template>
<script>
import BaseButton from '@/components/base/BaseButton/BaseButton'
export default {
  name: "StampTab",
  components: { BaseButton },
  data() {
    return {
      currentTab: 0,
      selectedItem: null,
    }
  },
  props: {
    tab: {
      type: String,
    },
    tabData: {
      type: Array,
      default: null
    },
  },
  methods: {
    isSelect(tab) {
      this.currentTab = tab
      this.selectedItem = null
    },
    isSelectedItem(itemIndex) {
      return this.selectedItem === itemIndex;
    },
    getSelectedItem() {
      if (this.selectedItem === null) return null
      return this.tabData[this.currentTab].tabItems[this.selectedItem]
    },
    stampSubmit() {
      // スクロールアップする処理
      window.scrollTo(0, 0);

      this.$router.app.$emit('show-spinner', true)
      this.$emit('submit', this.getSelectedItem())
    },
  },
}
</script>
<style lang="scss" module>
.stamp_tab {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.tab_header {
  margin-bottom: 10px;
}

.tabs {
  display: flex;
  width: 100%;
  height: 32px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow-y: scroll;

  .tab {

    .tab_image {
      width: 33px;
      height: 33px;
      box-sizing: border-box;
      margin-right: 2px;
      border: 2px solid $keyWhite;
      &.active {
        border: 2px solid $keyGreen;
      }
      img {
        margin: 2px;
        width: 25px;
        height: 25px;
      }
    }
  }
}

.divide_line {
  width: 100%;
  height: 1px;
  border-top: 1px solid #DDDDDD;
  border-bottom: 1px solid #DDDDDD;
}

.tab_body {
  margin-top: 16px;
  margin-bottom: 14px;
}

.tab_items_wrap {
  display: none;

  &.active {
    display: block;
  }

  .tab_items {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style-type: none;
    .tab_item {
      .tab_item_image_wrap {
        position: relative;
        input {
          display: none;
        }
        .tab_item_check_icon {
          position: absolute;
          top: 2px;
          left: 83px;
          width: 15px;
          height: 15px;
          pointer-events:none;
        }
        .tab_item_image {
          width: 50px;
          height: 50px;
          margin-right: 10px;
          margin-bottom: 10px;
          img {
            width: 100px;
            height: 60px;
          }
        }
      }
    }
  }
}

.stamp_tab_action {
  display: flex;
  justify-content: center;
  margin-top: auto;
}

</style>
