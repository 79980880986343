<template>
  <div :class="$style.union_course_quiz">
    <app-prev-button @click="$router.go(-1)" margin="0 0 24px" />
    <div :class="$style.union_course_quiz_content_wrap">
      <div :class="$style.union_course_quiz_content">
        <div :class="$style.content_header">
          <p :class="$style.content_header_title">視聴確認クイズ</p>
          <p :class="$style.content_header_caption">
            クイズに正解すると受講完了になります。
          </p>
          <h1 :class="$style.content_header_course_title">{{ title }}</h1>
        </div>

        <div :class="$style.content_body">
          <div :class="$style.question">
            <h2 :class="$style.question_title">
              Q. この動画をみてどう思いましたか？
            </h2>
            <p :class="$style.question_caption">
              当てはまるものを全てお選びください。
            </p>
          </div>

          <div :class="$style.answer_radio">
            <base-check-box name="answer" v-model="learn_content_flg" :disabled="is_answered"
              ><span :class="$style.answer_radio_item"
                >学びのある内容だった</span
              ></base-check-box
            >
            <base-check-box name="answer" v-model="useful_content_flg" :disabled="is_answered"
              ><span :class="$style.answer_radio_item"
                >役に立つ内容だった</span
              ></base-check-box
            >
            <base-check-box name="answer" v-model="sympathy_flg" :disabled="is_answered"
              ><span :class="$style.answer_radio_item"
                >自分の経験からも共感できた</span
              ></base-check-box
            >
            <base-check-box name="answer" v-model="talk_flg" :disabled="is_answered"
              ><span :class="$style.answer_radio_item"
                >人に話してみようと思った</span
              ></base-check-box
            >
            <base-check-box name="answer" v-model="practice_flg" :disabled="is_answered"
              ><span :class="$style.answer_radio_item"
                >実践してみようと思った</span
              ></base-check-box
            >
            <base-check-box name="answer" v-model="learn_more_flg" :disabled="is_answered"
              ><span :class="$style.answer_radio_item"
                >もっと深く学びたいと思った</span
              ></base-check-box
            >
            <base-check-box name="answer" v-model="union_activity_important_flg" :disabled="is_answered"
              ><span :class="$style.answer_radio_item"
                >改めて組合活動は大事なものだと思った</span
              ></base-check-box
            >
          </div>
          <div :class="$style.answer_triangle"></div>
          <div :class="$style.next_button_wrap">
            <base-button width="120px" rounded="rounded" @click="save"
              >次へ</base-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppPrevButton from '@/components/app/AppPrevButton/AppPrevButton'
// import BaseRadio from '@/components/base/BaseRadio/BaseRadio'
import BaseButton from '@/components/base/BaseButton/BaseButton'
import BaseCheckBox from '@/components/base/BaseCheckBox/BaseCheckBox'
export default {
  name: 'UnionCourseStaticQuiz1',
  components: { AppPrevButton, BaseButton, BaseCheckBox },
  data() {
    return {
      quiz: '',
      title: '',
      learn_content_flg: false,
      useful_content_flg: false,
      sympathy_flg: false,
      talk_flg: false,
      practice_flg: false,
      learn_more_flg: false,
      union_activity_important_flg: false,
      lecture_id: '',
      is_answered: true,
    }
  },
  mounted() {
    this.$router.app.$emit('page-updated', {
      pageTitle: '視聴確認クイズ',
      headerColor: 'yellow',
      back: '__history_back',
      showSetting: false,
      bgColor: 'yellow',
    })
  },
  created() {
    let url = '/api/' + this.$constants.API_VERSION + '/lecture/info?lecture_id=' + this.$route.params.lecture_id
    this.lecture_id = this.$route.params.lecture_id
    this.$axios.get(url, {}).then((res) => {
      let data = res.data.value.lecture
      this.title = data.title
      this.quiz = data.quiz
    })

    let url2 =
      '/api/' + this.$constants.API_VERSION + '/lecture_history/list?lecture_id=' + this.$route.params.lecture_id
    this.$axios.get(url2, {}).then((res) => {
      let data = res.data.value.user
      this.learn_content_flg = !!data.learn_content_flg
      this.useful_content_flg = !!data.useful_content_flg
      this.sympathy_flg = !!data.sympathy_flg
      this.talk_flg = !!data.talk_flg
      this.practice_flg = !!data.practice_flg
      this.learn_more_flg = !!data.learn_more_flg
      this.union_activity_important_flg = !!data.union_activity_important_flg
      this.is_answered = res.data.value.is_answered_practice_flg
    })
  },
  methods: {
    save: function () {
      if (this.is_answered) {
          if (this.$route.params.corner_id) {
            this.$router.push(
              '/union/course_static_quiz_2/' +
                this.$route.params.lecture_id +
                '/' +
                this.$route.params.corner_id
            )
          } else {
            this.$router.push(
              '/union/course_static_quiz_2/' + this.$route.params.lecture_id
            )
          }
      } else {
        this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/lecture_history/registration', {
          lecture_id: this.$route.params.lecture_id,
          learn_content_flg: String(Number(this.learn_content_flg)),
          useful_content_flg: String(Number(this.useful_content_flg)),
          sympathy_flg: String(Number(this.sympathy_flg)),
          talk_flg: String(Number(this.talk_flg)),
          practice_flg: String(Number(this.practice_flg)),
          learn_more_flg: String(Number(this.learn_more_flg)),
          union_activity_important_flg: String(
            Number(this.union_activity_important_flg)
          ),
        })
        .then(() => {
          if (this.$route.params.corner_id) {
            this.$router.push(
              '/union/course_static_quiz_2/' +
                this.$route.params.lecture_id +
                '/' +
                this.$route.params.corner_id
            )
          } else {
            this.$router.push(
              '/union/course_static_quiz_2/' + this.$route.params.lecture_id
            )
          }
        })
      }
    },
  },
}
</script>

<style lang="scss" module>
.union_course_quiz {
  display: flex;
  width: 960px;
  flex-direction: column;
  align-items: center;
  padding: 12px 0 48px 0;
  margin: 0 auto;
  @include mobile() {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}

.union_course_quiz_content_wrap {
  width: 100%;
  background-color: $keyWhite;
  padding: 24px 150px;
  box-sizing: border-box;

  @include mobile() {
    padding: 22px 32px;
  }
}

.union_course_quiz_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content_header {
  width: 100%;
  text-align: center;
  margin-bottom: 12px;
  border-bottom: 1px solid #dddddd;
  @include mobile() {
    margin-bottom: 16px;
  }

  &_title {
    font-size: 19px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 8px;
    @include mobile() {
      display: none;
    }
  }
  &_caption {
    font-size: 11px;
    font-weight: bold;
    color: $keyPink;
    margin-top: 0;
    margin-bottom: 12px;
  }
  &_course_title {
    font-size: 19px;
    margin-top: 0;
    margin-bottom: 16px;
    @include mobile() {
      font-size: 16px;
      margin-bottom: 24px;
    }
  }
}

.content_body {
  width: 660px;
  @include mobile() {
    width: 100%;
  }
  .question {
    margin-bottom: 12px;
    @include mobile() {
      margin-bottom: 16px;
    }

    &_title {
      font-size: 20px;
      color: $keyPink;
      margin-top: 0;
      margin-bottom: 8px;
      @include mobile() {
        font-size: 16px;
        margin-bottom: 12px;
      }
    }
    &_caption {
      font-size: 12px;
      font-weight: bold;
      margin: 0;
    }
  }

  .answer_radio {
    border: 5px solid $keyPink;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 24px;
    margin-bottom: 16px;
    @include mobile() {
      margin-bottom: 16px;
    }

    &_item {
      font-size: 16px;
    }
  }

  .answer_triangle {
    display: none;
    @include mobile() {
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 15px 20px 0 20px;
      border-color: #dddddd transparent transparent transparent;
      margin: 0 auto 20px auto;
    }
  }

  .result {
    background-color: $openBg;
    border-radius: 10px;
    padding: 16px 20px 20px 20px;
    margin-bottom: 8px;
    @include mobile() {
      margin-bottom: 20px;
    }

    &_header {
      display: flex;
      margin-bottom: 14px;
      span {
        font-size: 16px;
        font-weight: bold;
        margin-left: 8px;
        color: $keyPink;
      }
    }
    &_description {
      font-size: 11px;
      color: $fontBlack;
      margin: 0;
      @include mobile() {
        font-size: 13px;
      }
    }
  }
}

.next_button_wrap {
  display: flex;
  justify-content: center;
}
</style>
