<template>
  <div :class='$style.login'>
    <h1 :class='$style.title'>ログイン</h1>

    <div :class='$style.button_wrap'>
      <h2 :class="$style.sub">登録済の方はこちらから</h2>
      <base-button
        :class="$style.button"
        display='inline-flex'
        width='100%'
        color="white"
        bg-color="green"
        @click="linkClicked"
      >
        ログイン
      </base-button>
    </div>
    <div :class='$style.button_wrap'>
      <h2 :class="$style.sub">はじめての方はこちらから</h2>
      <base-button
        :class="$style.button"
        display='inline-flex'
        width='100%'
        color="white"
        bg-color="green"
        @click="registration"
      >
        新規登録
      </base-button>
    </div>
    <div :class='$style.link_wrap'>
      <a href="/open/password_forget" :class="$style.link">ID・パスワードを忘れた方はこちら</a>
      <a href="/open/faq" :class="$style.link">ヘルプ</a>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/base/BaseButton/BaseButton'
import VueCookies from "vue-cookies"
const cookies = VueCookies

export default {
  name: 'Login',
  components: { BaseButton },
  created: async function() {
    if (this.$route.query.token) {
      this.$store.commit('invite/setToken', this.$route.query.token)
    }
    if (this.$route.query.redirect) {
      const redirect = unescape(this.$route.query.redirect)
      const searchStr = 'token='
      const index = redirect.indexOf(searchStr)
      if (index != -1) {
        this.$store.commit('invite/setToken', redirect.substring(index+searchStr.length, redirect.length))
      }
    }
  },
  methods: {
    linkClicked() {
      const url = '/open/ju_setting'
      // this.$loginfo(this.$route.query.redirect)
      if (this.$route.query.redirect) {
        this.$router.push(`${url}?redirect=${this.$route.query.redirect}`)
      } else {
        this.$router.push(url)
      }
    },
    registration: function(){
      cookies.remove('redirect_url')
      var redirect_url = '/home/home'
      if (this.$route.query.redirect) {
        redirect_url = this.$route.query.redirect
      }
      cookies.set('redirect_url', redirect_url)
      this.$router.push('/open/registration')
    },
  }
}
</script>
<style lang='scss' module>
.login {
  display: flex;
  flex-direction: column;
  width: 375px;
  margin: 0 auto;
  padding: 68px 32px 47px 32px;
  box-sizing: border-box;
  text-align: left;

  @include mobile() {
    width: 375px;
    padding: 42px 32px 26px 32px;
  }
}

.title {
  margin-top: 0;
  font-size: 36px;
  font-weight: bold;
  line-height: 44px;
  margin-bottom: 70px;

  @include mobile() {
    font-size: 24px;
    margin-bottom: 60px;
  }
}

.sub {
  font-size: 13px;
  line-height: 24px;
  font-weight: bold;
  color: $fontBlack;
  margin-top: 0;
  margin-bottom: 12px;
}

.button_wrap {
  &:not(last-child) {
    margin-bottom: 58px;
  }
}

.button {
  height: 50px !important;
  font-size: 16px !important;
  font-weight: bold !important;
}

.link_wrap {
  margin-top: auto;
  text-align: center;
}
.link {
  display: block;
  font-size: 13px;
  line-height: 30px;
  color: $fontBlack;
  margin-top: 0;
  margin-bottom: 0px;
}
</style>
