<template>
  <div :class="customClass">
    <div :class="$style.form_textarea_area">
      <div v-if="$slots.prepend" :class="$style.form_textarea_prepend" :style="styleObject">
        <slot name="prepend" />
      </div>
      <textarea
        :class="[$style.form_textarea, errorClass]"
        :style="styleObject"
        v-bind="$attrs"
        v-model="boundValue"
        :placeholder="placeholder"
        ref="textarea"
        :rows="rows"
      >
      </textarea>

      <div v-if="$slots.append" :class="[$style.form_textarea_append, errorClass]" :style="styleObject">
        <slot name="append" />
      </div>
    </div>

    <p v-if="!!errorText" :class="$style.error_txt">{{ errorText }}</p>
  </div>
</template>

<script>
export default {
  name: 'BaseTextArea',
  // inheritAttrs: false,
  model: {
    prop: 'modelValue',
    event: 'update',
  },
  props: {
    modelValue: {},
    rows: {
      type: Number,
      default: 1
    },
    fixedHeight: {
      type: String,
      default: null
    },
    /**
     * エラー時の文言を指定するとエラー表示になります
     * String: そのままエラー文として表示します。空文字列は無視されます
     * Boolean: trueならエラー文なしで枠のみがエラー表示になります。
     */
    error: {
      type: [String, Boolean],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    }
  },
  created(){
    this.height = 'auto'
  },
  mounted(){
    this.resize();
  },
  data() {
    return {
      value: "",
      height: "42px",
      offsetHeight: 14,
      lineHeight: 24,
      borderHeight: 2,
    }
  },
  computed: {
    customClass() {
      return [
        this.$style.wrapper,
        {
          [this.$style.has_prepend]: !!this.$slots.prepend,
          [this.$style.has_append]: !!this.$slots.append,
          [this.$style.focus]: !!this.focus,
          [this.$style.error]: !!this.error,
        },
      ]
    },
    errorClass(){
      return {[this.$style.error]: !!this.error}
    },
    styleObject() {
      return {
        "height": this.height
      }
    },
    boundValue: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update', value)
      }
    },
    errorText() {
      return typeof this.error === 'string' ? this.error : ''
    },
  },
  methods: {
    resize(){
      if (this.fixedHeight) {
        this.height = this.fixedHeight
        return
      }
      this.height = this.$refs.textarea.clientHeight + (this.borderHeight * 2) + 'px';
    }

  },
}
</script>

<style lang="scss" module>
.wrapper {
  position: relative;
  width: 100%;
  .error {
    border-color: $keyRed;
  }
}

.form_textarea_area {
  display: flex;
  align-items: center;
}

.form_textarea {
  display: block;
  resize: none;
  width: 100%;
  box-sizing: border-box;
  padding-top: 8px;
  padding-right: 10px;
  padding-bottom: 6px;
  padding-left: 10px;
  flex: 1;
  outline: none;
  background: #fff;
  transition: all 0.1s;
}

.form_textarea:disabled,
.form_textarea:read-only {
  background: #e7e7e7;
}

.form_textarea,
.form_textarea_prepend,
.form_textarea_append {
  font-size: 16px;
  line-height: 24px;
  color: #262626;
  border: 2px solid #C4C4C4;
  border-radius: 4px;
}

.form_textarea_prepend,
.form_textarea_append {
  padding-top: 8px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background: #fff;
  transition: all 0.1s;
}

.has_prepend .form_textarea {
  padding-left: 0;
  border-left: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.has_append .form_textarea {
  padding-right: 0;
  border-right: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.form_textarea_prepend {
  border-right: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.form_textarea_append {
  border-left: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.error_txt {
  font-size: 13px;
  margin: 4px 0 0 0;
  color: #f00;
}
</style>
