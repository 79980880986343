<template>
  <app-menu v-on="$listeners">
    <template v-slot:title>チームメンバー検索</template>
    <template v-slot:body>
      <div :class="$style.search_menu_content_body">
        <div :class="$style.input_wrap">
          <base-input
            name="search_search_menu_input"
            look="outline2"
            v-model="inputText"
          >
            <template v-slot:prepend>
              <base-icon name="search" size="18px"/>
            </template>
          </base-input>
        </div>
        <div :class="$style.note">探したいメンバーのju-IDやニックネームを入力してください。</div>
        <div :class="$style.search_button_wrap">
          <base-button
            width="120px"
            height="40px"
            rounded="rounded"
            @click="handleSearch"
          >検索</base-button>
        </div>
      </div>
    </template>
  </app-menu>
</template>

<script>
import BaseInput from '@/components/base/BaseInput/BaseInput'
import BaseButton from '@/components//base/BaseButton/BaseButton'
import BaseIcon from '@/components/base/BaseIcon/BaseIcon'
import AppMenu from "@/components/app/AppMenu/AppMenu/AppMenu";
export default {
  name: 'AppTeamMemberSearchMenu',
  components: {AppMenu, BaseInput, BaseButton, BaseIcon },
  props: {
    member: Array,
    team_id: Number,
    text: {
      type: String,
      default: ''
    }
  },
  data: function() {
    return {
      filtered_member: [],
      inputText: this.text
    }
  },
  methods: {
    handleSearch(){
      this.$emit('search', this.inputText)
    }
  }
}
</script>

<style lang="scss" module>

.search_menu_content_body {
  margin: 72px 32px 0 32px;

  .input_wrap {
    margin-bottom: 24px;
  }
  .note {
    font-size: 11px;
    color: $fontBlack;
    margin-top: 0;
    margin-bottom: 30px;
  }
  .search_button_wrap {
    text-align: center;
  }
}

.member_list {
  padding: 0;
  margin: 0 32px 16px;
  list-style: none;
  font-size: 11px;
  li {
    &:not(:last-child) {
      margin-bottom: 6px;
    }

    a {
      color: $KeyDarkGreen;
    }
  }
}

.member_is_me {
  &::after {
    content: '※本人';
    color: #FF221F;
  }
}

.member_is_admin {
  &::before {
    content: '★';
    color: $keyPink;
  }
}
</style>
