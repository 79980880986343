<template>
  <label
    v-if="!!byLabel"
    :style="customStyle"
    :class="classObject"
    @click="$emit('click', $event)"
  >
    <slot />
  </label>

  <a
    v-else-if="!!href"
    :href="href"
    :style="customStyle"
    :class="classObject"
    @click="$emit('click', $event)"
  >
    <slot />
  </a>

  <button
    v-else-if="!href"
    :type="buttonType"
    :style="customStyle"
    :class="classObject"
    @click="$emit('click', $event)"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: "BaseButton",
  props: {
    /**
     * ボタンの幅を指定します。
     * 幅の指定がない場合は、autoになります
     */
    width: {
      type: String,
      default: "auto",
    },
    /**
     * ボタンの高さを指定します。
     * 高さの指定がない場合は、40pxになります
     */
    height: {
      type: String,
      default: "40px",
    },
    size: {
      type: String,
      default: 'm',
      validator:(value) => ['m','s', 'xs'].includes(value)
    },
    padding: {
      type: String,
      default: undefined,
    },
    display: {
      type: String,
      default: "inline-block",
      validator: (value) =>
        [undefined, "inline-block", "inline-flex", "flex", "block"].includes(
          value
        ),
    },
    /**
     * リンク先を指定できます。`to`が指定されている場合はそちらが優先されます
     */
    href: {
      type: String,
      default: "",
    },
    /**
     * ボタン色を指定できます。
     */
    bgColor: {
      type: String,
      default: "green",
      validator: (value) =>
        [undefined, "green", "dark-green", "white", "yellow", 'red', 'pink', 'light-gray', 'gray',"black"].includes(value),
    },
    /**
     * 文字色を指定できます。
     */
    color: {
      type: String,
      default: "white",
      validator: (value) =>
        [undefined, "white", "gray", "black", "green"].includes(value),
    },
    /**
     * ボタンの形状を指定できます。
     */
    rounded: {
      type: String,
      default: undefined,
      validator: (value) => [undefined, "rounded", "corner", "shallow-corner"].includes(value),
    },
    /**
     * ボーダースタイルを適用するかを指定します
     */
    bordered: {
      type: Boolean,
      default: false,
    },
    /**
     * box-shadowの有無を指定します。
     */
    shadow: {
      type: Boolean,
      default: false,
    },
    buttonType: {
      type: String,
      default: "submit",
      validator: (value) => [undefined, "submit", "button"].includes(value),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    byLabel: {
      type: Boolean,
      default: false
    },
    showCursor: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    classObject() {
      return [
        this.$style.button,
        this.$style["bg-" + this.bgColor],
        this.$style[this.color],
        this.$style[this.rounded],
        this.bordered ? this.$style.bordered : null,
        this.shadow ? this.$style.shadow : null,
        this.disabled ? this.$style.disabled : null,
        this.$style["size_" + this.size],
        (this.showCursor && !this.disabled) ? this.$style.pointer: null,
      ];
    },
    customStyle() {
      const displays = {
        "inline-block": "inline-flex",
        "inline-flex": "inline-flex",
        block: "flex",
        flex: "flex",
      };
      return {
        display: displays[this.display],
        width: this.width,
        height: this.height,
        padding: this.padding || undefined
      };
    },
  },
};
</script>

<style lang="scss" module>
.button {
  border: 0;
  background: none;
  display: inline-flex;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  vertical-align: bottom;
  font-family: sans-serif;

  &:hover {
    opacity: .8;
  }

  &.size_m{}
  &.size_s {
    font-size: 10px;
    line-height: 10px;
    padding: 0 8px;
  }

  &.bg-green {
    background: $keyGreen;
    &.bordered {
      color: $keyGreen;
      background: $keyWhite;
      border: 2px solid $keyGreen;
    }
  }
  &.bg-dark-green {
    background: $KeyDarkGreen;
    &.bordered {
      color: $KeyDarkGreen;
      background: $keyWhite;
      border: 2px solid $KeyDarkGreen;
    }
  }
  &.bg-white {
    background: $keyWhite;
    &.bordered {
      color: $fontGray;
      background: $keyWhite;
      border: 2px solid $fontGray;
    }
  }
  &.bg-yellow {
    background: $keyYellow;
    &.bordered {
      color: $keyYellow;
      background: $keyWhite;
      border: 2px solid $keyYellow;
    }
  }
  &.bg-red {
    background: $keyRed;
    &.bordered {
      color: $keyRed;
      background: $keyWhite;
      border: 2px solid $keyRed;
    }
  }
  &.bg-pink {
    background: $keyPink;
    &.bordered {
      color: $keyPink;
      background: $keyWhite;
      border: 2px solid $keyPink;
    }
  }
  &.bg-gray {
    background: #707070;
    &.bordered {
      color: #707070;
      background: $keyWhite;
      border: 2px solid #707070;
    }
  }
  &.bg-light-gray {
    background: #DDDDDD;
    &.bordered {
      color: #DDDDDD;
      background: $keyWhite;
      border: 2px solid #DDDDDD;
    }
  }
  &.bg-black {
    background: $keyBlack;
    &.bordered {
      color: $keyBlack;
      background: $keyWhite;
      border: 2px solid $keyBlack;
    }
  }
  &.white {
    color: $fontWhite;
  }
  &.black {
    color: $fontBlack;
  }
  &.gray {
    color: $fontGray;
  }
  &.green {
    color: $keyGreen;
  }
  &.rounded {
    border-radius: 30px;
  }
  &.corner {
    border-radius: 10px;
    &.size_s {
      border-radius: 5px;
    }
  }
  &.shallow-corner {
    border-radius: 5px;
  }
  &.shadow {
    box-shadow: 0px 3px 8px -3px rgba(119,119,119,0.6);
  }
  &.disabled {
    pointer-events: none;
  }
  &.pointer {
    cursor: pointer;
  }
}
</style>
