<template>
  <div>
    <v-select
      :class="[$style.select, {
        [$style.multiple]: multiple,
        [$style.error]: !!error,
      }]"
      v-bind="$attrs"
      :multiple="multiple"
      v-model="boundValue"
      :options="options"
      :placeholder="placeholder"
      :selectable="(option) => !option.disabled"
    >
      <span slot="no-options"></span>
      <template v-if="is_exist_choicesLabel" v-slot:option="option">
        {{ option.choicesLabel }}
      </template>
    </v-select>
    <p v-if="!!errorText" :class="$style.error_txt">{{ errorText }}</p>
  </div>
</template>

<script>
import 'vue-select/dist/vue-select.css'

export default {
  model: {
    prop: "modelValue",
    event: "update",
  },
  props: {
    modelValue: {
      default: null
    },
    options: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    error: {
      type: [String, Boolean],
      default: "",
    },
    /**
     * プルダウン展開時の選択肢の文字列と
     * 選択後の文字列を分ける場合にtrueをセット
     */
    is_exist_choicesLabel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    boundValue: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update', value)
      }
    },
    errorText() {
      return typeof this.error === 'string' ? this.error : ''
    },
  },
  methods: {
  }
}
</script>

<style lang="scss" module>
$selectedGray: #F6F8F9;

.select {
  div[class^='vs__dropdown'] {
    border: 2px solid $borderLiteGray;
    border-radius: 4px;
    min-height: 56px;
    padding: 0 0 0 18px;

    div[class^='vs__selected'] {
      margin-top: auto;
      margin-bottom: auto;
      vertical-align: middle;

      span[class='vs__selected'] {
        color: $keyBlack;
        font-size: 16px;
        line-height: 1.4;
        outline: none;
        padding: 0 0.25em;
        background: $keyWhite;
        z-index: 0;
      }

      input {
        width:0;
      }

    }

    div[class='vs__actions'] {
      svg {
        width: 14px;
        height: 12px;
      }
    }

  }
  div[class^='vs__dropdown']:focus-within {
    border-color: $keyGreen;
  }

}

.select.multiple {
  div[class^='vs__dropdown'] {

    div[class^='vs__selected'] {

      span[class='vs__selected'] {
        background: #f0f0f0;
        border: 1px solid rgba(60,60,60,.26);
        border-radius: 4px;
      }

    }
  }
}

.select.error {
  div[class^='vs__dropdown'] {
    border-color: $keyRed;
  }
}

.error_txt {
  font-size: 13px;
  margin: 4px 0 0 0;
  color: #f00;
}
</style>

