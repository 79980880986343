<template>
  <div :class="$style.content_inner">
    <section
      v-if="post.sso_url"
      :class="$style.wrapper"
      :style="customStyle"
      @click="goTo(post, post.type)"
    >
      <div :class="$style.header_wrapper">
        <div v-if="post.service_id" :class="$style.sso_logo_wrapper">
          <img :src="sso_logo_imgpath(post.service_id, post.service_code)" />
        </div>
        <div :class="$style.time">
          <time :datetime="timeAttr" :class="$style.time_text">{{
            post.created_at
          }}</time>
        </div>
        <p v-if="post.team_name" :class="$style.team_name">
          {{ post.team_name }}
        </p>
        <div :class="$style.icons_area">
          <div :class="$style.icons">
            <span :class="[$style.icons_wrapper]">
              <span
                :class="[
                  $style.icons_inner,
                  $style.read,
                  { [$style.on]: post.read_flg },
                ]"
              >
                <base-icon
                  type="fas"
                  name="eye"
                  size="10px"
                  :color="post.read_flg ? 'green' : 'gray'"
                />
                {{ post.read_count }}
              </span>
              <span
                :class="[
                  $style.icons_inner,
                  $style.commented,
                  { [$style.on]: post.comment_count > 0 },
                ]"
              >
                <span :class="$style.icon"
                  ><base-icon size="10px" name="comment-dots"
                /></span>
                {{ post.comment_count }}
              </span>
              <span
                :class="[
                  $style.icons_inner,
                  $style.like,
                  { [$style.on]: post.good_flg },
                ]"
                @click.stop="clickGood(post, post.chat_id)"
              >
                <base-icon
                  name="heart"
                  size="10px"
                  type="fas"
                  :color="post.good_flg ? 'pink' : 'gray'"
                />
                {{ post.goods_count }}
              </span>
            </span>
          </div>
        </div>
      </div>
      <p :class="$style.title">{{ post.title }}</p>
    </section>
    <section
      v-else-if="post.news_id"
      :class="$style.wrapper"
      @click="goToNews(post.news_id)"
      :style="customStyle"
    >
      <div :class="$style.header_wrapper">
        <div v-if="post.service_id" :class="$style.sso_logo_wrapper">
          <img :src="sso_logo_imgpath(post.service_id, post.service_code)" />
        </div>
        <div :class="$style.news_label">お知らせ</div>
        <div :class="$style.time">
          <time :datetime="timeAttr(post)" :class="$style.time_text">{{
            post.created_at
          }}</time>
        </div>
        <p v-if="post.team_name" :class="$style.team_name">
          {{ post.team_name }}
        </p>
      </div>
      <p :class="$style.title">{{ post.title }}</p>
    </section>
  </div>
</template>

<script>
import BaseIcon from '@/components/base/BaseIcon/BaseIcon'
import { editTeamChatFavorite } from '@/helper/common.js'

export default {
  components: {
    BaseIcon,
  },
  name: 'AppNoticeList',
  props: {
    iconHeart: {
      type: String,
      default: 'heart',
      validator: (icon) => !icon || ['heart'].includes(icon),
    },
    iconEye: {
      type: String,
      default: 'eye',
      validator: (icon) => !icon || ['eye'].includes(icon),
    },
    iconAt: {
      type: String,
      default: 'at',
      validator: (icon) => !icon || ['at'].includes(icon),
    },
    favorite: {
      type: Boolean,
      default: false,
    },
    sso_list: {
      type: Array,
    },
    title: {
      type: String,
    },
    auth_team_name: {
      type: String,
    },
    /** 投稿日 */
    time: {
      type: [String, Date, Number],
      default: () => new Date(),
    },
    post: {
      type: Object,
      default: () => {},
    },
    unread: {
      type: Boolean,
      default: false,
    },
    read: {
      type: Boolean,
      default: false,
    },
    isCursorPointer: {
      type: Boolean,
      default: true,
    },
    /** ユーザー画像パス。BaseAvatarに準じる */
    /** アバター色 */
    color: {
      type: Function,
    },
    /** 投稿者名前 */
    name: {
      type: String,
    },
    /** タイプ */
    type: {
      type: Number,
    },
    bodyColor: {
      type: String,
      default: '#FFFFFF',
    },
    service_id: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      processing: false,
      showNoticeModal: false,
      showImage: false,
      // good_count: 0,
      // goodFlg: post.good_flg,
    }
  },
  methods: {
    goTo(chat, type) {
      if (chat.chat_id == '') {
        this.$router.push(`/union/course/${chat.lecture_id}`)
      } else {
        if (type == 2) {
          this.$router.push(
            `/team/mini-vote-detail/${chat.team_id}/${chat.chat_id}`
          )
        } else {
          this.$router.push(`/team/post-detail/${chat.team_id}/${chat.chat_id}`)
        }
      }
    },
    timeAttr(post) {
      const date =
        post.created_at instanceof Date
          ? post.created_at
          : new Date(post.created_at.replace(/-/g, '/'))
      return date.toLocaleString()
    },
    clickComment(chat) {
      if (chat.block_user_flg) {
        alert('ブロックしているのでコメントできません')
        return
      }
      this.$router.push(
        `/team/comment/${chat.team_id}/${chat.chat_id}/${chat.type}`
      )
    },
    clickGood: async function (chat, chat_id) {
      if (this.processing) return

      this.processing = true
      const targetFlg = !!chat.good_flg == true ? 0 : 1
      const { status } = await editTeamChatFavorite({
        chat_id: Number(chat_id),
        good_flg: targetFlg,
      })
      if (status == 'success') {
        chat.good_flg = targetFlg
        if (targetFlg) chat.goods_count++
        else chat.goods_count--
      }
      this.processing = false
    },
    goToNews(news_id) {
      this.$router.push(`/home/noticetext/${news_id}`)
    },
    handleSelect() {
      // 選択イベントが発火するように設定
      this.$emit('select', !this.selected, this.item)
    },
    iconType() {
      return 'fas' // iconやselectedによって変化する予定
    },
    iconAtName() {
      return this.iconAt
    },
    iconHeartName() {
      return this.iconHeart
    },
    iconEyeName() {
      return this.iconEye
    },
    iconAtColor() {
      return 'red'
    },
    sso_logo_imgpath(service_id, service_code) {
      try {
        let img = ''
        if (service_code) {
          img = require(`@/assets/images/sso_logo_service_code_${service_code}.png`)
        } else {
          img = require(`@/assets/images/sso_logo_service_id_${service_id}.png`)
        }
        return img
      } catch (e) {
        return ''
      }
    },
  },
  computed: {
    customStyle() {
      return {
        'background-color': `${this.bodyColor}`,
      }
    },
  },
}
</script>

<style lang="scss" module>
.wrapper {
  width: 100%;
  display: grid;
  gap: 10px;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}
.title {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  color: $keyGreen;
  margin: 0;
  text-decoration: underline;
}
.time {
  color: $fontGray2;
  font-size: 12px;
}
.team_name {
  font-size: 12px;
  line-height: 1.5;
  color: $keyBlack;
  margin: 0;
}
.header_wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  @media (max-width: 768px) {
    gap: 10px;
    flex-wrap: wrap;
  }
  img {
    height: 27px;
    width: auto;
  }
}
.icons_area {
  margin-left: auto;
}
.icons_wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}
.icons_inner {
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: 10px;
  cursor: pointer;
}
.content_inner {
  width: 100%;
}
.read {
  svg {
    color: $iconGray;
  }
  &.on svg {
    color: $keyGreen;
  }
}
.commented {
  svg {
    color: $iconGray;
  }
  &.on svg {
    color: $keyGreen;
  }
}
.news_label {
  background-color: $keyGreen;
  color: $keyWhite;
  font-size: 10px;
  font-weight: bold;
  padding: 2px 5px;
  border-radius: 2px;
}
.sso_logo_wrapper {
  display: flex;
}
</style>
