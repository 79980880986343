<template>
  <div :class="$style.video_poster" :style="styleObject" @click="$emit('click', $event)">
    <div v-if="source" :class="$style.play_icon">
      <base-icon name="play" size="30px" color="white"/>
    </div>
    <img :class="$style.poster" :src="source" alt="クリックで動画再生"/>
  </div>
</template>

<script>
import BaseIcon from '@/components/base/BaseIcon/BaseIcon'
export default {
  name: 'BaseVideoPoster',
  components: { BaseIcon },
  props: {
    source: {
      type: String,
      default: undefined
    },
    width: {
      type: String,
      default: "auto"
    },
    height: {
      type: String,
      default: "auto"
    },
    display: {
      type: String,
      default: "inline-block",
      validator: (value) => [undefined, "inline-block", "inline-flex", "flex", "block"].includes(value),
    },
  },
  computed: {
    styleObject() {
      const displays = {
        "inline-block": "inline-flex",
        "inline-flex": "inline-flex",
        block: "flex",
        flex: "flex",
      };
      return {
        display: displays[this.display],
        "width" : this.width,
        "height" : this.height
      }
    }
  }
}
</script>

<style lang="scss" module>
.video_poster {
  position: relative;
  cursor: pointer;
  .play_icon {
    width: 30px;
    height: 30px;
    position: absolute;
    top: calc(50% - 15px);
    left: calc(50% - 15px);
  }
  .poster {
    width: 100%;
  }
}
</style>
