<template>
  <div :class="classObject" :style="customStyle">
    <slot />
    <div v-if="dismissible" :class="$style.btn_wrapper" @click="toggle">
      <base-icon name="times" size="8px" />
    </div>
  </div>
</template>

<script>
import BaseIcon from "../BaseIcon/BaseIcon";
export default {
  name: "BaseMessage",
  components: { BaseIcon },
  data() {
    return {
      alert: true,
    };
  },
  props: {
    width: {
      type: String,
      default: "328px",
    },
    height: {
      type: String,
      default: "48px",
    },
    display: {
      type: String,
      default: "inline-block",
      validator: (value) =>
        [undefined, "inline-block", "inline-flex", "flex", "block"].includes(
          value
        ),
    },
    dismissible: {
      type: Boolean,
    },
    type: {
      type: String,
      default: "normal",
      validator: (value) => ["normal", "success", "error"].includes(value),
    },
  },
  computed: {
    classObject() {
      return [this.$style.message, this.$style[this.type]];
    },
    customStyle() {
      const displays = {
        "inline-block": "inline-flex",
        "inline-flex": "inline-flex",
        block: "flex",
        flex: "flex",
      };
      return {
        display: displays[this.display],
        width: this.width,
        'min-height': this.height,
      };
    },
  },
  methods: {
    toggle: function () {
      this.alert = !this.alert;
    },
  },
};
</script>

<style lang="scss" module>
.message {
  border: 0;
  background: none;
  display: inline-flex;
  font-size: 13px;
  font-weight: bold;
  font-family: sans-serif;
  align-items: center;
  text-align: left;
  padding: 0 24px;
  box-sizing: border-box;
  border-radius: 6px;

  &.normal {
    background: $keyBlack;
    color: $fontWhite;
  }

  &.success {
    background: $keyGreen;
    color: $fontWhite;
  }

  &.error {
    background: $keyRed;
    color: $fontWhite;
  }
}

.btn_wrapper {
  display: inline-flex;
  margin-left: auto;
}
</style>
