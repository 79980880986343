<template>
  <div :class="$style.status_chart">
    <span
      v-for="chartLabel in chartLabels"
      :key="chartLabel.label"
      :class="$style.status_chart_label"
      :style="chartLabel.styleObject"
      >{{ chartLabel.label }}</span
    >
    <div :class="$style.status_chart_wrapper">
      <radar-chart :chart-data="radarData" :options="radarOptions" />
    </div>
  </div>
</template>

<script>
import RadarChart from '../RadarChart'
import { labelStyle } from "./labelStyle";
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "AppStatusChart",
  components: {
    RadarChart,
  },
  mixins: [ isMobile() ],
  props: {
    data: {
      type: Array,
    },
    labels: {
      type: Array,
    },
    average: {
      type: Array,
    },
  },
  computed: {
    radarData() {
      return {
        labels: this.labels,
        datasets: [
          {
            label: "average",
            data: this.average,
            fill: true,
            backgroundColor: "rgba(0, 241, 213, 0.5)",
            borderColor: "rgb(0, 241, 213)",
            borderWidth: 2,
            pointRadius: this.isMobile ? 1 : 2,
          },
          {
            label: "your-status",
            data: this.data,
            fill: true,
            backgroundColor: "rgba(109, 233, 50, 0.5)",
            borderColor: "rgb(109, 233, 50)",
            borderWidth: 2,
            pointRadius: this.isMobile ? 1 : 2,
          },
        ],
      }
    },
    radarOptions() {
      return {
        scale: {
          ticks: {
            fontSize: this.isMobile ? 4 : 6,
            max: 100,
            min: 0,
            stepSize: 10,
          },
          pointLabels: {
            display: false,
          },
        },
        legend: {
          display: false,
        },
        tooltips: {
          titleFontSize: this.isMobile ? 9 : 12,
          bodyFontSize: this.isMobile ? 9 : 12,
          callbacks: {
            title: function () {
              return "再生完了率";
            },
            label: function (tooltipItem, data) {
              const idx = tooltipItem.index;
              const label = data.labels[idx];
              return label + ':' + tooltipItem.value;
            },
          },
        },
      }
    },
    chartLabels() {
      if (!this.labels) return null;
      return this.labels.map((label, index) => {
        return {
          label: label,
          styleObject: labelStyle[`${this.isMobile ? 'mobile_': 'pc_'}${this.labels.length}`][index],
        };
      });
    }
  }
};
</script>

<style lang="scss" module>
.status_chart {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 300px;
  box-sizing: border-box;
  background-color: $keyWhite;
  border: 5px solid $categoryYellow;
  border-radius: 50%;

  @include mobile(){
    width: 200px;
    height: 200px;
  }

  .status_chart_label {
    position: absolute;
    white-space: nowrap;
    text-align: center;
    font-size: 12px;
    width: 75px;
    color: #707070;

    @include mobile(){
      font-size: 9px;
      width: 50px;
    }

    &:nth-child(2) {
      text-align: right;
    }

    &:nth-child(4) {
      text-align: left;
    }
  }
  .status_chart_wrapper {
    width: 255px;
    height: 255px;
    @include mobile(){
      width: 170px;
      height: 170px;
    }
  }
  canvas {
    position: relative;
    z-index: 2;
  }
}
</style>
