<template>
  <div :class="[$style.wrapper, previousSpace? $style.previous_space : '' ]">
    <label v-for="value in values" :key="getColor(value)" :class="$style.checkbox">
      <input
        :class="$style.check"
        :name="name"
        :value="getValue(value)"
        type="radio"
        v-model="boundChecked"
      />
      <span :class="$style.label">
        <span
          :style="{ 'background-color': `rgb(${getColor(value)}) !important` }"
          :class="$style.box"
        />
      </span>
    </label>
  </div>
</template>
<script>
export default {
  name: "BaseRadioColor",
  inheritAttrs: false,
  model: {
    props: "checked",
    event: "update",
  },
  props: {
    values: {
      type: Array,
      default: () => [
        "255,255,255",
        "249,249,249",
        "245,220,220",
        "241,245,220",
        "222,245,220",
        "220,242,245",
        "226,220,245",
        "245,220,237",
      ],
    },
    checked: {
      type: String,
    },
    name: {
      type: String,
      default: "color",
    },
    previousSpace: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    getValue(value) {
      if (typeof value === "object") {
        return value.value;
      } else {
        return value;
      }
    },
    getColor(value) {
      if (typeof value === "object") {
        return value.color;
      } else {
        return value;
      }
    },
  },
  computed: {
    boundChecked: {
      get() {
        // console.log("color get", { this: this, checked: this.checked });
        return this.checked;
      },
      set(value) {
        // console.log("color set", { this: this, value });
        this.$emit("update", value);
      },
    },
  },
};
</script>
<style lang="scss" module>
$baseSize: 50px;
$mobileBaseSize: 30px;
$baseBorder: 2px;
$baseSpacing: 10px;
$fontSize: 14px;
$checkSize: 10px;
$checkBorder: 3px;
$checkTransX: 1px;
$checkTransY: -1px;
$baseColor: #b7b7b7;
// $activeColor: #e94c89;
$checkColor: #000;

.wrapper {
  display: flex;
  justify-content: center;
}
.checkbox {
  display: block;
  // border-radius: 4px;
  // background: #fff;
  font-size: $fontSize;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: $baseSpacing;
  }

  .box {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: $baseSize;
    height: $baseSize;
    // margin-right: $textSpacing;
    border: $baseBorder solid $baseColor;
    border-radius: 50%;
    vertical-align: middle;
    box-sizing: border-box;
    transition: all 0.1s linear;

    @media (max-width: 768px) {
      width: $mobileBaseSize;
      height: $mobileBaseSize;
    }

    &:before {
      content: "";
      display: block;
      width: $checkSize;
      height: calc($checkSize / 2);
      border-left: $checkBorder solid $checkColor;
      border-bottom: $checkBorder solid $checkColor;
      opacity: 0;
      transition: all 0.1s linear;
      transform: rotate(-90deg) translate($checkTransX, $checkTransY);
    }
  }

  .check {
    display: none;

    &:checked {
      + .label {
        // font-weight: bold;

        .box {
          // border-color: $activeColor;
          &:before {
            opacity: 1;
            transform: rotate(-45deg) translate($checkTransX, $checkTransY);
          }
        }
      }
    }
  }
}
.previous_space {
  margin: 1em 0 0 2em;
  @media screen and (max-width: 767px) {
    margin: 0;
  }
}
</style>
