<template>
  <section :class="[$style.expand, { [$style.expand_open]: isExpanded }]">
    <div :class="$style.expand_header">
      <div
        v-if="type === 'qa'"
        :class="[$style.expand_title, $style.qa]"
        @click="toggleExpand"
      >
        <base-icon name="question" size="22px" />
        <h1 :class="[$style.expand_title_text, $style.qa]">{{ title }}</h1>
        <base-icon
          :class="[$style.expand_arrow, $style.qa]"
          name="chevron-right"
          size="24px"
          color="gray"
        />
      </div>

      <div
        v-else-if="type === 'normal'"
        :class="[$style.expand_title, $style.normal]"
        @click="toggleExpand"
      >
        <base-icon
          v-if="!isExpanded"
          type="far"
          name="plus-square"
          size="16px"
          color="green"
        />
        <base-icon
          v-else-if="!!isExpanded"
          type="far"
          name="minus-square"
          :size="size"
          color="green"
        />
        <h1 :class="[$style.expand_title_text, $style.normal]">{{ title }}</h1>
      </div>

      <div
        v-else-if="type === 'borderless'"
        :class="[$style.expand_title, $style.borderless]"
        @click="toggleExpand"
      >
        <h1 :class="[$style.expand_title_text, $style.borderless]">
          {{ title }}
        </h1>
        <div :class="[$style.triangle, { [$style.open]: isExpanded } ]"></div>
        <!-- <div v-else :class="[$style.triangle]"></div> -->
      </div>
      <div :class="$style.expand_header_sort" v-if="showSortLabel">
        <div
          :class="[
            {[$style.expand_header_sort_on]: direction === 'asc' },
            {[$style.expand_header_sort_off]: direction !== 'asc' },
          ]"
          @click="toggleDirection('asc')"
        >{{labelAsc}}</div>
        <div
          :class="[
            {[$style.expand_header_sort_on]: direction === 'desc' },
            {[$style.expand_header_sort_off]: direction !== 'desc' },
          ]"
          @click="toggleDirection('desc')"
        >{{labelDesc}}</div>
      </div>
    </div>

    <div
      :class="[$style.expand_content, { [$style.normal]: type === 'normal' }]"
    >
      <slot />
    </div>
  </section>
</template>
<script>
import BaseIcon from '@/components/base/BaseIcon/BaseIcon'
export default {
  name: 'TeamChatAccordion',
  components: {
    BaseIcon,
  },
  props: {
    type: {
      type: String,
      default: 'normal',
      validator: (value) => ['normal', 'qa', 'borderless'].includes(value),
    },
    title: {
      type: String,
      required: true,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    labelAsc: {
      type: String,
      default: "古い順",
    },
    labelDesc: {
      type: String,
      default: "新しい順",
    },
    orderBy: {
      type: String,
      default: "desc",
      validator: (value) => ['asc', 'desc'].includes(value),
    },
    showSortLabel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isExpanded: this.expanded,
      windowWidth: 0,
      direction: this.orderBy,
    }
  },
  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded

      var content_element = this.$el.lastElementChild
      var content_height = content_element.scrollHeight
      if (!this.isExpanded) {
        content_element.style.maxHeight = content_height + 'px'
        content_height = 0
        setTimeout(
          function () {
            content_element.style.maxHeight = content_height + 'px'
          }.bind(this),
          50
        )
      } else {
        content_element.style.maxHeight = content_height + 'px'
        setTimeout(
          function () {
            content_element.style.maxHeight = ''
          }.bind(this),
          50
        )
      }

      this.$emit('expand', this.isExpanded)
    },
    toggleDirection(direction = 'asc') {
      if (this.direction == direction) {
        return
      }
      this.direction = direction
      this.$emit('direction-changed', this.direction)
    },
    resizeWindow() {
      this.windowWidth = window.innerWidth
    },
  },
  mounted() {
    window.addEventListener('resize', this.resizeWindow)
  },
  watch: {
    expanded(newValue /*, oldValue*/) {
      this.isExpanded = newValue
    },
  },
  computed: {
    size() {
      const ipadWidth = 728
      return this.windowWidth > ipadWidth ? '24px' : '16px'
    },
  },
}
</script>
<style lang="scss" module>
.expand {
  // background: $keyWhite;
}
.expand_title {
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 1;

  &.qa {
    align-items: start;
    background: $keyWhite;
    border: 1px solid #b9b9b9;
    border-collapse: collapse;
    color: $fontBlack;
    padding: 17px 12px 13px 8px;
  }

  &.normal {
    background: $keyWhite;
    // border-bottom: 1px solid #b9b9b9;
    color: $fontBlack;
    padding: 17px 0 17px 10px;
    box-sizing: border-box;
    .expand:first-of-type & {
      border-top: 1px solid #b9b9b9;
    }
    // .expand_open & {
    //   border-bottom: none;
    // }
  }

  &.borderless {
    display: inline-flex;
    color: $fontBlack;
  }
}

.expand_title_text {
  flex: 1;
  margin: 0;
  &.qa {
    padding-left: 10px;
  }
  &.normal {
    @media screen and (min-width: 768px) {
      font-size: 24px;
    }
    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
    line-height: 20px;
    padding-left: 6px;
  }
  &.borderless {
    font-size: 18px;
    padding-right: 10px;
  }
}

.expand_arrow {
  transition: 0.3s;
}
.expand_open .expand_arrow {
  transform: rotate(180deg);
  &.qa {
    transform: rotate(90deg);
  }
}
.expand_content {
  // max-height: 0;
  overflow: hidden;
  transition: 0.3s;
  &.normal {
    // .expand_open & {
    //   border-bottom: 1px solid #b9b9b9;
    // }
    // border-top: none;
    border-bottom: 1px solid #b9b9b9;
  }
}
.expand_open .expand_content {
  // padding-top: 16px;
  // max-height: 4800px;
  overflow: visible;
}

.expand_header {
  border-bottom: solid 7px $borderLightGray;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.expand_header_sort {
  z-index: 1;
  display: flex;
  justify-content: space-between;
  gap: 0 30px;
  cursor: pointer;
  &_on{
    font-weight: bold;
  }
  &_off{
    text-decoration: underline;
    color: rgb(45, 204, 211);
  }
}
.triangle {
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  border-bottom: 5px solid #000;
  &.open {
    transform: rotate(180deg);
  }
}
</style>
