<template>
  <div :class="$style.team_header">
    <div :class="$style.team_header_icon_utility" v-if="indexType">

      <!-- お気に入り -->
      <div
        v-if="team != null"
      >
        <a @click.prevent="clickTeamUser('favorite_flg')">
          <div :class="$style.team_header_icon_utility__button">
            <img :src="isFavorite" alt="" :width="iconSize" />
            お気に入り
          </div>
        </a>
      </div>
      <!-- /お気に入り -->

      <!-- いいね -->
      <div>
        <a @click.prevent="clickTeamUser('good_flg')">
          <div :class="$style.team_header_icon_utility__button">
            <base-icon
              :class="[
                { [$style.heart]: !isGood },
                { [$style.heart_active]: isGood },
              ]"
              name="heart"
              :size="iconSize"
            />
            いいね
          </div>
        </a>
      </div>
      <!-- /いいね -->

      <!-- 通知 -->
      <div>
        <div
          ref="icon_wrap"
          :class="$style.icon_wrap"
          @click="toggleShowNotification"
        >
          <div>
          <!--
            <div :class="$style.team_header_icon_utility__box_button">
              <img :src="notificationImg" alt="" width="9px" />
              通知
            </div>
            <div v-show="isShowNotification" :class="$style.link_contents_team">
              <div :class="$style.menuItemLink">
                <p @click="clickTeamUser('event_flg', 1)">通知をONにする</p>
                <p @click="clickTeamUser('event_flg', 0)">通知をOFFにする</p>
                <p @click="clickTeamUser('event_flg', 2)">自分宛のみ通知</p>
              </div>
            </div>
            -->

            <base-overlay-menu-list :menuWidth="160" :showNotificationButton="true" :notificationStatus="notificationStatus">

              <template>
                <base-overlay-menu-list-item
                  to=""
                  @click.native="clickTeamUser('event_flg', 1)"
                >
                  通知をONにする
                </base-overlay-menu-list-item>

                <base-overlay-menu-list-item
                  to=""
                  @click.native="clickTeamUser('event_flg', 0)"
                >
                  通知をOFFにする
                </base-overlay-menu-list-item>

                <base-overlay-menu-list-item
                  to=""
                  @click.native="clickTeamUser('event_flg', 2)"
                >
                  自分宛のみ通知
                </base-overlay-menu-list-item>
              </template>
            </base-overlay-menu-list>
          </div>
        </div>
      </div>
      <!-- /通知 -->

      <!-- このチームについて
      <div v-if="!informationDisplayNone">
        <router-link :to="{ name: 'TeamInfo' }">
          <div :class="$style.team_header_icon_utility__button">
            <img :src="informationImg" :width="iconSize" alt="" />
            このチームについて
          </div>
        </router-link>
      </div>-->
      <!-- /このチームについて -->

    </div>
  </div>
</template>
<script>
import BaseIcon from '@/components/base/BaseIcon/BaseIcon'
//import NotificationDisabledImg from '@/assets/images/notification-disabled.svg'
//import NotificationImg from '@/assets/images/notification.svg'
//import NotificationMeImg from '@/assets/images/notification-me.svg'

import NotificationDisabledImg from '@/assets/images/notification-disabled-white.svg'
import NotificationImg from '@/assets/images/notification-white.svg'
import NotificationMeImg from '@/assets/images/notification-me-white.svg'

import InformationImg from '@/assets/images/information.svg'
//import AdminIconImg from '@/assets/images/admin-enabled.png'
import AdminIconImg from '@/assets/images/admin-enabled.svg'
//import AdminIconDisabledImg from '@/assets/images/admin-disabled.png'
import AdminIconDisabledImg from '@/assets/images/admin-disabled.svg'
import isMobile from '@/utils/mixins/isMobile'
import { mapMutations, mapGetters } from 'vuex'
import { editTeamUser } from '@/helper/common.js'
import { autoLink } from '@/utils/helper.js'

import BaseOverlayMenuList from '@/components/base/BaseOverlayMenuList/BaseOverlayMenuListPhase2.9'
//import BaseOverlayMenuListTitle from '@/components/base/BaseOverlayMenuList/BaseOverlayMenuListTitle'
//import BaseOverlayMenuListItem from '@/components/base/BaseOverlayMenuList/BaseOverlayMenuListItem'
import BaseOverlayMenuListItem from '@/components/base/BaseOverlayMenuList/BaseOverlayMenuListItemPhase2.9'

export default {
  name: 'TeamIcon',
  mixins: [isMobile()],
  props: {
    team: {
      type: Object,
      default: () => {},
    },
    user_id: {
      type: Number,
    },
    belongsTo: {
      type: Boolean,
      default: true,
    },
    informationDisplayNone: {
      type: Boolean,
    },
    indexType: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      processing: false,
      favorite_flg: this.team.favorite_flg,
      isShowNotification: false,
    }
  },
  components: {
    BaseIcon,
    BaseOverlayMenuList,
    //BaseOverlayMenuListTitle,
    BaseOverlayMenuListItem,
  },
  computed: {
    ...mapGetters('team', [
      'getDisplayType',
      'getTeamUserEventFlg',
      'getTeamGoodFlg',
    ]),
    iconSize() {
      return '14px' //this.isMobile ? '14px' : '34px'
    },
    notificationImg() {
      if (this.isEventDone == 0) {
        return NotificationDisabledImg
      } else if (this.isEventDone == 1) {
        return NotificationImg
      } else {
        return NotificationMeImg
      }
    },
    notificationStatus() {
      return this.isEventDone
    },
    informationImg() {
      return InformationImg
    },
    isGood() {
      return !!this.getTeamGoodFlg == true
    },
    isFavorite() {
      if (this.favorite_flg) {
        return AdminIconImg
      } else {
        return AdminIconDisabledImg
      }
    },
    isEventDone() {
      return this.getTeamUserEventFlg
    },
  },
  methods: {
    ...mapMutations('team', ['setTeamUserEventFlg', 'setTeamGoodFlg']),
    async clickTeamUser(field_name, event_flg) {
      if (this.processing) return
      this.processing = true
      let params = { user_id: this.user_id, team_id: this.team.team_id }
      switch (field_name) {
        case 'event_flg':
          params = { ...params, event_flg: event_flg } // ベルアイコン
          break
        case 'good_flg':
          params = { ...params, good_flg: this.getTeamGoodFlg ^ 1 } // いいね
          break
        case 'favorite_flg':
          params = { ...params, favorite_flg: !this.favorite_flg } // お気に入り
          break
      }
      // 組織ユーザー登録・更新
      const res = await editTeamUser(params)

      // レスポンスがsuccessじゃないのなら画面に反映しない
      if (!['error', 'failed'].includes(res.status)) {
        switch (field_name) {
          case 'event_flg': // ベルアイコン
            this.setTeamUserEventFlg(event_flg)
            break
          case 'good_flg': // いいね
            this.setTeamGoodFlg(this.getTeamGoodFlg ^ 1)
            break
          case 'favorite_flg': // お気に入り
            this.favorite_flg = !this.favorite_flg
            break
        }
      }
      this.processing = false
    },
    toggleShowNotification() {
      this.isShowNotification = !this.isShowNotification
      if (this.isShowNotification) {
        document.addEventListener('click', this.hideNotificationOnClickOutside)
      } else {
        document.removeEventListener(
          'click',
          this.hideNotificationOnClickOutside
        )
      }
    },
    hideNotificationOnClickOutside(event) {
      // クリックされた要素がアイコン以外の場合は非表示にする
      const iconWrap = this.$refs.icon_wrap
      if (iconWrap && !iconWrap.contains(event.target)) {
        this.isShowNotification = false
      }
    },
    autoLink,
  },
}
</script>
<style lang="scss" module>
.team_header {
  display: flex;
  justify-content: space-between;

  .team_header_icon_utility {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    margin: 0;

    @include mobile() {
      align-items: flex-end;
    }

    &__button {
      font-size: 8pt;

      @include mobile() {
        font-size: 10pt;
      }

      display: flex;
      align-items: center;
      white-space: nowrap;
      margin-right: 20px;
      gap: 3px;
      cursor: pointer;

    }

    &__box_button {
      font-size: 8pt;
      width: 80px;
      padding: 3px;

      @include mobile() {
        font-size: 10pt;
        width: 100px;
        padding: 1px;
      }

      display: flex;
      justify-content: center;
      white-space: nowrap;
      gap: 3px;
      cursor: pointer;

      background-color: #2dccd3;
      border-radius: 3px;
      color: #fff;
    }
  }

  &_detail {
    height: 80px;
    margin: 0 auto;
    background: #fff;
    border-bottom: 1px solid #dddddd;

    @include mobile() {
      display: block;
      width: auto;
      height: 40px;
    }

    &_wrap {
      display: flex;
      align-items: center;
      max-width: 1280px;
      height: 100%;
      margin: 0 auto;

      @include mobile() {
        width: auto;
      }
    }

    &_avatar {
      margin-top: -45px;
      margin-left: 16px;
    }

    ul {
      list-style: none;
      padding: 0;
    }

    &_meta {
      flex: 1;
      font-size: 13px;
      margin: 0 16px;

      @include mobile() {
        font-size: 10px;
        transform: scale(0.9);
      }
    }

    &_utility {
      display: flex;
      align-items: flex-end;

      li {
        cursor: pointer;
        margin: 0 16px;
      }
    }
  }
}

.heart {
  color: $borderLiteGray;
  &_active {
    color: $keyPink;
  }
}

.link_contents {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  transition: 0.5s;
  gap: 30px;
  margin-right: 30px;
  margin-left: 20px;
  @media screen and (max-width: 1190px) {
    display: none;
  }
}

.link_contents_team {
  position: absolute;
  padding: 0 30px 0 30px;
  background-color: $keyWhite;
  border-radius: 20px;
  box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.1);
  z-index: 100; // ヘッダーの持つオーバーレイメニューをページのものよりも上にする
  top: 40px;
  max-height: 60vh;
  @media screen and (max-width: 1080px) {
    top: 30px;
  }
}.link_contents_team p {
  cursor: pointer;
}

.link_contents_team {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 0px;
  max-width: 100px;
  left: -4vw;
  @media screen and (max-width: 1080px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.icon_wrap {

  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  /*
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-right: 0px !important;
  position: relative;
  @media screen and (max-width: 1080px) {
    margin-top: 5px;
  }
  */
}

.menuItemLink {
  font-size: 13px;
  color: #707070;
}


.team_info {
  border-radius: 10pt;
  padding: 30px;
  /*width: 300px;*/
  display: flex;
  background-color: #ff0000;

  &__settings {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    /*width: 60px;*/
    background-color: #0000ff;


    ul {
      list-style: none;
      padding: 0;
      display: flex;
      align-items: flex-start;
    }
  }

  &__settings_button {
    /*display: flex;
    align-items: center;*/
    display: block;
    cursor: pointer;
    margin:12px auto 0;
    background-color: #00ff00;
  }
}
</style>
