<template>
  <app-menu v-on="$listeners">
    <template v-slot:title>検索</template>
    <template v-slot:body>
      <div :class="$style.search_menu_content_body">
        <div :class="$style.input_wrap">
          <base-input
            name="search_search_menu_input"
            look="outline2"
            v-model="keyWord"
          >
            <template v-slot:prepend>
              <base-icon name="search" size="18px"/>
            </template>
          </base-input>
        </div>
        <div :class="$style.note">キーワードを入力してください。<br>通知、チーム、お気に入りなどの情報を検索できます。</div>
        <div :class="$style.search_button_wrap">
          <base-button
            width="120px"
            height="40px"
            rounded="rounded"
            @click.native="clickHandler"
          >検索</base-button>
        </div>
      </div>
    </template>
  </app-menu>
</template>

<script>
import BaseInput from '@/components/base/BaseInput/BaseInput'
import BaseButton from '@/components//base/BaseButton/BaseButton'
import BaseIcon from '@/components/base/BaseIcon/BaseIcon'
import AppMenu from "@/components/app/AppMenu/AppMenu/AppMenu";
export default {
  name: 'AppSearchMenu',
  components: {AppMenu, BaseInput, BaseButton, BaseIcon },
  props: {},
  data() {
    return {
      keyWord: "",
    }
  },
  methods: {
    clickHandler: function () {
      this.$emit('search-keyword', this.keyWord)
    },
  },
  computed: {},
}
</script>

<style lang="scss" module>

.search_menu_content_body {
  margin: 72px 32px 0 32px;

  .input_wrap {
    margin-bottom: 24px;
  }
  .note {
    font-size: 11px;
    color: $fontBlack;
    margin-top: 0;
    margin-bottom: 30px;
  }
  .search_button_wrap {
    text-align: center;
  }
}
</style>
