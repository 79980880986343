<template>
  <section :class="$style.union_gift_detail">
    <app-prev-button @click="$router.push({ name: 'UnionGift', params: {team_id}})"/>

    <div :class="$style.content_header">
      <h1 :class="$style.content_header_title">配布可能なメッセージの詳細設定</h1>
    </div>
    <div :class="$style.content_table_wrap">
      <table :class="$style.content_table">
        <tr :class="$style.value_code">
          <th>バリューコード名称<span>※</span></th>
          <td>
            <base-input
              v-model="gift.value_code_name"
              :error="v$.gift.value_code_name.$invalid && v$.gift.value_code_name.$dirty
                ? v$.gift.value_code_name.$errors[0].$message
                : isFieldErrorMsg('value_code_name', errorMsgList)"/>
          </td>
        </tr>
        <tr :class="$style.message">
          <th>メッセージ</th>
          <td>
            <base-input v-model="gift.message"
            placeholder="カード画面に表示するメッセージを256文字以内でご入力ください"
          ></base-input>
          </td>
        </tr>
      </table>

      <template v-if="hasError(errorMsgList) && hasFieldErrorMsg(errorMsgList, [])">
        <base-message
        type="error" v-for="(error, i) in errorMsgList" :key="i"
        :class="$style.msg_area">{{error.message}}</base-message>
      </template>

    </div>
    <div :class="$style.detail_box">
      <dl :class='$style.detail_list'>
        <div :class='$style.detail_list_item'>
          <dt>初期設定名称</dt>
          <dd>{{ gift.init_value_code_name }}</dd>
        </div>
        <div :class='$style.detail_list_item'>
          <dt>発行額面</dt>
          <dd>{{ getViewModel.amount }}</dd>
        </div>
        <div :class='$style.detail_list_item'>
          <dt>運用開始日</dt>
          <dd>{{ getViewModel.start_datetime }}</dd>
        </div>
        <div :class='$style.detail_list_item'>
          <dt>発行上限額</dt>
          <dd>{{ getViewModel.limit_amount }}</dd>
        </div>
        <div :class='$style.detail_list_item'>
          <dt>累計発行額</dt>
          <dd>{{ getViewModel.used_amount }}</dd>
        </div>
        <div :class='$style.detail_list_item'>
          <dt>サービスコード</dt>
          <dd>{{ gift.service_code }}</dd>
        </div>
      </dl>
    </div>
    <base-button width="185px" rounded="rounded" @click="submit()">保存</base-button>
  </section>
</template>

<script>
import BaseInput from '@/components/base/BaseInput/BaseInput'
import BaseButton from '@/components/base/BaseButton/BaseButton'
import AppPrevButton from '@/components/app/AppPrevButton/AppPrevButton'
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import { fetchGiftList, editGift } from '@/helper/common.js'
import { isFieldErrorMsg, hasError, hasFieldErrorMsg } from '@/helper/validator.js'

export default {
  name: 'UnionGiftDetail',
  components: { BaseInput, BaseButton, AppPrevButton },
  props: {
    /** 組織ID */
    team_id: {
      type: Number,
      required: true,
    },
    /** メッセージID */
    gift_id: {
      type: Number,
      required: true,
    },
  },
  setup: function () {
    return { v$: useVuelidate() }
  },
  validations: function () {
    return {
      gift: {
        value_code_name: {
          required: helpers.withMessage(
            'バリューコード名称は、必ず指定してください。',
            required
          ),
          $autoDirty: true,
        },
      }
    }
  },
  data() {
    return {
      gift: {
        /** バリューコード名称 */
        value_code_name: null,
        /** 初期バリューコード名称 */
        init_value_code_name: null,
        message: null,
        /** 発行額 */
        amount: null,
        /** 利用限度額 */
        limit_amount: null,
        /** 発行累計額 */
        used_amount: null,
        /** サービスコード */
        service_code: null,
        start_datetime: null,
      },
      errorMsgList: [],
      processing: false,
    }
  },
  created: async function () {
    this.$router.app.$emit('show-spinner', true)
    await this.getGiftById()
    this.$router.app.$emit('show-spinner', false)
  },
  mounted() {
    this.$router.app.$emit('page-updated', {
      pageTitle: 'メッセージ配布',
      back: '/union/gift/' +this.team_id,
      headerColor: 'white',
      showSetting: false,
      bgColor: 'gray'
    })
  },
  computed: {
    getViewModel: function () {
      return {
        /** 発行額 */
        amount: `${this.formatNumber(this.gift.amount)}円`,
        /** 利用限度額 */
        limit_amount: `${this.formatNumber(this.gift.limit_amount)}円`,
        /** 発行累計額 */
        used_amount: `${this.formatNumber(this.gift.used_amount)}円`,
        // yyyy/MM/dd にする
        start_datetime: this.formatDateTime(this.gift.start_datetime),
      }
    },
    // getGiftMock: function () {
    //   return [{
    //     gift_id: this.gift_id,
    //     value_code_name: 'バリューコード名称',
    //     init_value_code_name: '初期バリューコード名称',
    //     message: 'メッセージ',
    //     amount: '1000',
    //     limit_amount: '100000',
    //     used_amount: '50000',
    //     service_code: '123456789',
    //     start_datetime: '2021/11/04 17:55:18',
    //   }]
    // },
  },
  methods: {
    isFieldErrorMsg,
    hasError,
    hasFieldErrorMsg,
    /** メッセージ一覧取得 */
    getGiftById: async function () {
      const result = await fetchGiftList({team_id: this.team_id, gift_id: this.gift_id})
      if (result.status == 'success') {
        if (result.value.length > 0) {
          const gift = result.value[0]
          this.gift.value_code_name = gift.value_code_name
          this.gift.init_value_code_name = gift.init_value_code_name
          this.gift.message = gift.message
          this.gift.amount = gift.amount
          this.gift.limit_amount = gift.limit_amount
          this.gift.used_amount = gift.used_amount
          this.gift.service_code = gift.service_code
          this.gift.start_datetime = gift.start_datetime
        }
      }
    },
    /** 数値を「,」区切りにする */
    formatNumber: function (value) {
      if (value == null) return ''
      const num = Number(value)
      return num.toLocaleString()
    },
    /** 日時を「yyyy/MM/dd」に変換する */
    formatDateTime: function (value) {
      if (value == null) return ''
      return value.substring(0, 10)
    },
    /** 保存ボタン押下処理 */
    submit: async function () {
      if (this.processing) return

      const validate = await this.v$.$validate()
      if (!validate) {
        return
      }

      this.errorMsgList = []

      this.processing = true
      const result = await editGift({
        team_id: this.team_id, gift_id: this.gift_id,
        value_code_name: this.gift.value_code_name, message: this.gift.message
      })
      this.processing = false

      if (result.status == 'success') {
        this.$router.push(`/union/gift/${this.team_id}`)
      } else if (result.status == 'error') {
        result.errors.forEach(error => {
          this.errorMsgList.push(error)
        })
      }
    },
    // setMock: function () {
    //   const giftMock = this.getGiftMock[0]
    //   this.gift.value_code_name = giftMock.value_code_name
    //   this.gift.init_value_code_name = giftMock.init_value_code_name
    //   this.gift.message = giftMock.message
    //   this.gift.amount = giftMock.amount
    //   this.gift.limit_amount = giftMock.limit_amount
    //   this.gift.used_amount = giftMock.used_amount
    //   this.gift.service_code = giftMock.service_code
    //   this.gift.start_datetime = giftMock.start_datetime
    // },
  },
}
</script>

<style lang="scss" module>
.union_gift_detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 12px 0 50px 0;
  width: 960px;
}

.content_header {
  width: 100%;
  text-align: left;
  margin-bottom: 60px;
  &_title {
    font-size: 30px;
    margin: 0;
  }
}

.content_table_wrap {
  width: 100%;
  margin-bottom: 36px;
}
.content_table {
  width: 100%;
  border-spacing: 0;

  th {
    width: 156px;
    text-align: left;
    font-size: 13px;
  }
  td {
    padding: 0;
  }

  .value_code {
    th, td {
      padding-bottom: 24px;
    }
    span {
      margin-left: 16px;
      color: $keyPink;
    }
  }
}

.detail_box {
  padding: 18px 28px 0 28px;
  margin-bottom: 22px;
  background-color: $keyWhite;
  border: 2px solid #B7B7B7;
  box-sizing: border-box;

  .detail_list {
    width: 100%;
    padding: 0;
    margin: 0;

    &_item {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 12px;
      font-size: 13px;
      font-weight: bold;

      dt, dd {
        color: $fontGray;
        line-height: 19px;
        margin: 0;
      }

      dt {
        width: 175px;
      }
    }
  }
}
.msg_area {
  width: 100% !important;
  margin: 0 0 10px;
}
</style>
