<template>
  <div :class="$style.stamp_tab">
    <div :class="$style.tab_header">
    </div>
    <div :class="$style.stamp_tab_action">
      <base-button
        width="185px"
        rounded="rounded"
        @click="commentDelete"
      >削除</base-button>
    </div>
  </div>
</template>
<script>
import BaseButton from '@/components/base/BaseButton/BaseButton'
export default {
  name: "CommentTab",
  components: { BaseButton },
  data() {
    return {
      currentTab: 0,
      selectedItem: null,
    }
  },
  props: {
    tab: {
      type: String,
    },
    tabData: {
      type: Array,
      default: null
    },
  },
  methods: {
    commentDelete() {
      this.$emit('commentDelete')
    },
  },
}
</script>
<style lang="scss" module>
.stamp_tab {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.tab_header {
  margin-bottom: 10px;
}

.tabs {
  display: flex;
  width: 100%;
  height: 32px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow-y: scroll;

  .tab {

    .tab_image {
      width: 33px;
      height: 33px;
      box-sizing: border-box;
      margin-right: 2px;
      border: 2px solid $keyWhite;
      &.active {
        border: 2px solid $keyGreen;
      }
      img {
        margin: 2px;
        width: 25px;
        height: 25px;
      }
    }
  }
}

.divide_line {
  width: 100%;
  height: 1px;
  border-top: 1px solid #DDDDDD;
  border-bottom: 1px solid #DDDDDD;
}

.tab_body {
  margin-top: 16px;
  margin-bottom: 14px;
}

.tab_items_wrap {
  display: none;

  &.active {
    display: block;
  }

  .tab_items {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style-type: none;
    .tab_item {
      .tab_item_image_wrap {
        position: relative;
        input {
          display: none;
        }
        .tab_item_check_icon {
          position: absolute;
          top: 2px;
          left: 33px;
          width: 15px;
          height: 15px;
          pointer-events:none;
        }
        .tab_item_image {
          width: 50px;
          height: 50px;
          margin-right: 10px;
          margin-bottom: 10px;
          img {
            width: 50px;
            height: 50px;
          }
        }
      }
    }
  }
}

.stamp_tab_action {
  display: flex;
  justify-content: center;
  margin-top: auto;
}

</style>
