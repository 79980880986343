<template>
  <div :class="classObject" :style="styleObject">
    <img
      :src="path"
      :style="customStyle"
      :class="$style.image"
      v-bind="$attrs"
    />
  </div>
</template>

<script>
export default {
  name: 'BaseImg',
  props: {
    path: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: '36px',
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
    bordered: {
      type: Boolean,
      default: false,
    },
    borderColor: {
      type: String,
      default: undefined,
    },
    outlineColor: {
      type: String,
      default: undefined,
    },
    icon: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    customStyle() {
      if (this.icon) {
        const size = {
          width: this.size,
          height: this.size,
        }
        return size
      } else {
        const size = {
          width: this.size,
          height: 'auto',
        }
        return size
      }
    },
    classObject() {
      return [this.$style.img_wrapper]
    },
    styleObject() {
      const border = this.borderColor
        ? { border: `2px solid ${this.borderColor}` }
        : null
      const outline = this.outlineColor
        ? { outline: `2px solid ${this.outlineColor}`, 'outline-offset': `2px` }
        : null
      if (this.icon) {
        const size = {
          width: this.size,
          height: this.size,
        }
        return {
          ...size,
          ...border,
          ...outline,
        }
      } else if (this.width && this.height) {
        const size = {
          width: this.width,
          height: this.height,
        }
        return {
          ...size,
          ...border,
          ...outline,
        }
      } else {
        const size = {
          width: this.size,
          height: 'auto',
        }
        return {
          ...size,
          ...border,
          ...outline,
        }
      }
    },
  },
}
</script>

<style lang="scss" module>
.yellow {
  color: $keyYellow;
}
.green {
  color: $keyGreen;
}
.pink {
  color: $keyPink;
}
.gray {
  color: $iconGray;
}
.white {
  color: $keyWhite;
}
.black {
  color: $keyBlack;
}
.blue {
  color: $keyBlue;
}
.red {
  color: $keyRed;
}
.green2 {
  color: $keyGreen2;
}

.img_wrapper {
  display: flex;
  // overflow: hidden;
  box-sizing: border-box;
  justify-content: center;
}
</style>
