var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.home_favorite},[_c('base-tab',{attrs:{"tab":_vm.current_tab},on:{"tab":_vm.tabChange},scopedSlots:_vm._u([{key:_vm.TAB_APP,fn:function(){return [_c('div',{class:_vm.$style.app_tab_content},[_c('base-accordion',{attrs:{"title":"MOVIE","type":"borderless","expanded":""}},[_c('div',{class:_vm.$style.app_item_list},_vm._l((_vm.contents_favorite_list),function(content,i){return _c('div',{key:i,class:_vm.$style.app_item_wrap},[_c('app-carousel-post-item',{attrs:{"border-color":content.bar_color ? ("rgb(" + (content.bar_color) + ")") : '#7BE293',"head":content.title,"foot":_vm.judgeFoot(content),"image":content.image_url
                    ? content.image_url
                    : 'https://via.placeholder.com/140x50',"selected":content.type && content.type == 'corner'
                    ? null
                    : Boolean(content.good_flg),"pin":Boolean(content.pin_flg),"time":content.created_at,"customTagText":content.status,"customTagColor":content.background_color
                    ? ("rgb(" + (content.background_color) + ")")
                    : null,"customTagTextColor":content.text_color ? ("rgb(" + (content.text_color) + ")") : null,"favorite":Boolean(content.favorite_flg),"type":"app"},on:{"pin":function($event){return _vm.pin(content, i, 'app')},"favorite":function($event){return _vm.favorite(content, i, 'app')},"share":function($event){return _vm.share(content, 'app')},"click":function($event){_vm.$router.push(_vm.switchContentsPageURL(content))},"select":function($event){return _vm.good(content, i, 'app')}}})],1)}),0)])],1)]},proxy:true},{key:_vm.TAB_TEAM,fn:function(){return [_c('div',{class:_vm.$style.team_tab_content},[_c('div',{class:_vm.$style.team_item_list},_vm._l((_vm.team_favorite_list),function(content,i){return _c('div',{key:i,class:_vm.$style.team_item_wrap},[_c('app-carousel-post-item',{attrs:{"border-color":content.team_color ? ("rgb(" + (content.team_color) + ")") : '#7BE293',"head":content.title,"foot":content.team_name,"image":content.image_url
                  ? content.image_url
                  : 'https://via.placeholder.com/140x50',"selected":Boolean(content.good_flg),"pin":Boolean(content.pin_flg),"time":content.created_at,"favorite":Boolean(content.favorite_flg),"read":Boolean(content.read_flg),"type":"team","showFavorite":content.block_user_flg ? false : true,"showPin":content.block_user_flg ? false : true,"showShare":content.block_user_flg ? false : true,"showRead":content.block_user_flg ? false : content.type != 'team',"showDelete":content.block_user_flg ? false : !!content.delete_auth_flg,"icon":content.block_user_flg ? '' : 'heart',"blockUser":content.block_user_flg,"showBrowse":content.block_user_flg ? true : false,"showReport":_vm.$store.state.auth.user_id == content.chat_user_id
                  ? false
                  : true,"reported":content.is_user_reported,"showBlock":_vm.$store.state.auth.user_id == content.chat_user_id
                  ? false
                  : true},on:{"pin":function($event){return _vm.pin(content, i, 'team')},"favorite":function($event){return _vm.favorite(content, i, 'team')},"read":function($event){return _vm.read(content, i, 'team')},"share":function($event){return _vm.share(content, 'team')},"delete":function($event){return _vm.deleteTeamContent(content, i)},"click":function($event){_vm.$router.push(_vm.switchTeamPageURL(content))},"select":function($event){return _vm.good(content, i, 'team')},"report":function($event){return _vm.reportUser(content)},"blockUser":function($event){return _vm.blockUser(content, i, 'team')},"browse":function($event){_vm.$router.push(_vm.switchTeamPageURL(content))}}})],1)}),0)])]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }