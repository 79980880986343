import { v4 as uuidv4 } from 'uuid'

const UUIDPlugin = {}

UUIDPlugin.install = function (Vue) {
  Vue.prototype.$uuidv4 = function () {
    return uuidv4()
  }
}

export default UUIDPlugin
