<template>
  <section :class="$style.union_setting">
    <div :class="$style.union_setting_content" v-if="!initLoading">
      <app-prev-button @click="$router.go(-1)" />
      <div :class="$style.filter_wrap">
        <div :class="$style.filter_header">
          <h1 :class="$style.filter_header_title">メンバー絞込検索</h1>
          <base-button bg-color="black" width="250px" @click="showCourseAssign = true" v-if="auth_type == 'admin'">コース自動割り当て設定を行う</base-button>
          <base-button
            bg-color="black"
            width="250px"
            @click="showInvite = true"
          >メンバー外から招待する</base-button>
        </div>
        <div :class="$style.filter">
          <div :class="$style.msg_container_main">
            <template v-if="hasError(searchCondErrorMsgList)">
              <base-message type="error" v-for="(error, i) in searchCondErrorMsgList" :key="i" :class="$style.msg_area">{{error.message}}</base-message>
            </template>
          </div>
          <div :class="$style.filter_table_container">
            <table :class="$style.filter_table_left">
              <tr>
                <th>JU-ID</th>
                <td><input class="custom-input"  v-model="juid"/></td>
              </tr>
              <tr>
                <th>名前/ニックネーム</th>
                <td><input class="custom-input"  v-model="nickname"/></td>
              </tr>
                <tr>
                  <td colspan="2" style="border-bottom: 1px solid #000;"></td>
                </tr>
              <tr>
                <td rowspan="2">登録日時</td>
                <td class="date-picker-container">
                  <span>開始日</span>
                  <base-date-picker class="custom-datepicker" style="width: 250px !important;" v-model="from_created_by" min="1901-01-01T00:00" max="9999-12-31T23:59"/>
                </td>
              </tr>
              <tr>
                <td class="date-picker-container">
                  <span>終了日</span>
                  <base-date-picker style="width: 250px" v-model="to_created_by" min="1901-01-01T00:00" max="9999-12-31T23:59"/>
                </td>
              </tr>
              <tr>
                <td colspan="2" style="border-bottom: 1px solid #000;"></td>
              </tr>
              <tr>
                <td rowspan="2">最終ログイン日</td>
                <td class="date-picker-container">
                  <span>開始日</span>
                  <base-date-picker style="width: 250px" v-model="from_last_login_time_stamp" min="1901-01-01T00:00" max="9999-12-31T23:59"/>
                </td>
              </tr>
              <tr>
                <td class="date-picker-container">
                  <span>終了日</span>
                  <base-date-picker style="width: 250px" v-model="to_last_login_time_stamp" min="1901-01-01T00:00" max="9999-12-31T23:59"/>
                </td>
              </tr>
              <tr>
                <td colspan="2" style="border-bottom: 1px solid #000;"></td>
              </tr>
            </table>

            <table :class="$style.filter_table_right">
              <tr>
                <th>受講可能コース</th>
                <td>
                  <div :class="$style.filter_check_colum_group" style="height: 500px; overflow-y: scroll;">
                    <base-check-box
                      v-for="course in courseList"
                      :key="course.id"
                      :name="course.name"
                      :value="course.id"
                      v-model="checkedAble"
                    >{{course.name}}</base-check-box>
                  </div>
                </td>
              </tr>
            </table>

            <table :class="$style.filter_table_bottom">
              <tr>
                <th>権限</th>
                <td colspan="2">
                  <div :class="$style.filter_check_row_group">
                    <base-check-box name="auth" value="admin" v-model="checkedAuth">管理者</base-check-box>
                    <base-check-box name="auth" value="admin_auth" v-model="checkedAuth">共同管理者</base-check-box>
                    <base-check-box name="auth" value="user" v-model="checkedAuth">メンバー</base-check-box>
                  </div>
                </td>
              </tr>
              <tr>
                <th>状態</th>
                <td colspan="2">
                  <div :class="$style.filter_check_row_group">
                    <base-check-box name="status" value="use" v-model="checkedStatus">利用中</base-check-box>
                    <base-check-box name="status" value="invite" v-model="checkedStatus">招待中</base-check-box>
                    <base-check-box name="status" value="uninvited" v-model="checkedStatus">未招待</base-check-box>
                  </div>
                </td>
              </tr>
            </table>

          </div>

          <div :class="$style.filter_action_button">
            <base-button
              rounded="rounded"
              width="120px"
              bordered
              @click="resetClick"
            >条件リセット</base-button>
            <base-button
              rounded="rounded"
              width="120px"
              @click="searchClick"
              v-if="!searchProcessing"
            >検索</base-button>
            <div :class="$style.filter_action_button_loading_area" v-else><base-icon name="spinner" size="24px" spin /></div>
          </div>
        </div>
      </div>
      <div :class="$style.list_table_wrap" v-show="!isInitSearch">
        <div :class="$style.list_table_header">
          <h1 :class="$style.list_table_header_title">検索結果</h1>
          <div :class="$style.list_table_header_total"><span>{{totalRowCount}}</span>&nbsp;{{totalRowCount != null ? '件' : ''}}</div>
          <div :class="$style.list_table_header_selected" v-show="isRows"><span>{{ tableSelectedNum }}</span>&nbsp;件選択中</div>
          <base-button width="200px" rounded="rounded" @click="showReport = true" v-show="isRows">視聴確認レポート</base-button>
        </div>
        <div :class="$style.list_table">
          <p :class="$style.not_found" v-show="!isRows">データが見つかりませんでした。</p>
          <app-table-list
            :col-defs="tableColDefs"
            :row-data="tableRowData"
            :selected="tableSelected"
            :page="tablePageIndex"

            :no-sort="true"
            :fetch-mode="true"
            :sort-key="sortKey"
            :sort-asc="sortAsc"

            :page-data-num="per_page"
            :total-record="total"
            @select="handleSelect"
            @page-no-click="pageNoClick"
            @sort-click="sortClick"
            @cell-click="cellClick"
            :no-margin-b="true"
          >
            <template v-slot:courses_slot="{ course_list }">
              <span class="course" v-for="(course, i) of course_list" :key="i">{{ course.name }}<br></span>
              <span class="no-course" v-if="course_list.length == 0">（なし）</span>
            </template>
            <template v-slot:course_list_slot>
              <div :class="$style.course_list_area">
              <p :class="$style.course_list_header" v-show="isRows">チェックボックスで選択したメンバーに対しての操作</p>
              <div :class="$style.course_list_contents" v-show="isRows">
                <div :class="$style.col_action">
                  <base-select
                    name="course_id"
                    id="course_id"
                    v-model="selected_course_id"
                    :options="courseListByListBox"
                    placeholder="選択してください"
                    :class="$style.course_list"
                  />
                  <div :class="$style.course_btn">
                    <base-button rounded="rounded" @click="allocateMembers" v-if="!processing">まとめてコース割当</base-button>
                    <div :class="$style.loading"><base-icon name="spinner" size="24px" spin v-if="processing" /></div>
                  </div>
                  <div :class="$style.course_btn">
                    <base-button rounded="rounded" @click="inviteMembers" v-if="!processing">まとめてコース招待</base-button>
                    <div :class="$style.loading"><base-icon name="spinner" size="24px" spin v-if="processing" /></div>
                  </div>
                  <div :class="$style.course_release_btn_">
                    <base-button rounded="rounded" style="background-color: red;" @click="uniCancelMembers" v-if="!processing">コース一括削除</base-button>
                    <div :class="$style.loading"><base-icon name="spinner" size="24px" spin v-if="processing" /></div>
                  </div>
                </div>
              </div>
              <div :class="$style.col_msg">
                <template v-if="hasError(memberUpErrorMsgList)">
                  <base-message type="error" v-for="(error, i) in memberUpErrorMsgList" :key="i" :class="$style.msg_area">{{error.message}}</base-message>
                </template>
                <base-message type="success" :class="$style.msg_area" v-if="memberUpSuccessMsg">{{memberUpSuccessMsg}}</base-message>
              </div>
              </div>
            </template>
            <template v-slot:config_slot="">
          <span class="config">
            <a href="javascript:void(0)" style="color:hotpink">コース割当/招待</a>
          </span>
            </template>
          </app-table-list>
        </div>
      </div>
    </div>
    <div :class="[$style.union_setting_content, $style.loading]" v-if="initLoading">
      <base-icon name="spinner" size="32px" spin />
    </div>
    <base-modal
      v-if="showAllocation"
      width="960px"
      height="auto"
      :hidenCloseBtn="true"
    >
      <template v-slot:body>
        <div :class="$style.modal_content">
          <div :class="$style.modal_header">
            <h1 :class="$style.modal_header_title">コース割当/招待</h1>
            <base-button
              width="185px"
              bg-color="black"
              @click="startMailer"
            >在庫管理（プラン変更依頼）</base-button>
          </div>
          <div :class="$style.msg_container">
            <template v-if="hasError(uniSalonErrorMsgList)">
              <base-message type="error" v-for="(error, i) in uniSalonErrorMsgList" :key="i" :class="$style.msg_area">{{error.message}}</base-message>
            </template>
            <base-message type="success" :class="$style.msg_area" v-if="uniSalonSuccessMsg">{{uniSalonSuccessMsg}}</base-message>
          </div>
          <div :class="$style.modal_allocation">
            <div :class="$style.modal_allocation_table_left">
              <table >
                <thead>
                  <tr>
                    <th colspan="2">基本情報</th>
                  </tr>
                </thead>
                <tr>
                  <th>JU-ID</th>
                  <td>{{user.juid}}</td>
                </tr>
                <tr>
                  <th>名前</th>
                  <td>{{user.name}}</td>
                </tr>
                <tr>
                  <th>ニックネーム</th>
                  <td>{{user.nickname}}</td>
                </tr>
                <tr>
                  <th>登録日</th>
                  <td>{{user.created_at}}</td>
                </tr>
                <tr>
                  <th>最終ログイン日</th>
                  <td>{{user.last_login_timestamp}}</td>
                </tr>
                <tr>
                  <th>権限</th>
                  <td>{{user.auth_type_name}}</td>
                </tr>
              </table>
            </div>

            <div :class="$style.modal_allocation_table_right" v-if="!processing && courseListByUser.length > 0">
              <table>
                <thead>
                  <tr>
                    <th>コース設定</th>
                    <td></td>
                    <td>割当 / 招待</td>
                    <td :class="$style.col_head">残り</td>
                    <td :class="$style.col_head">契約数</td>
                  </tr>
                </thead>
                  <tr
                    v-for="course in courseListByUser"
                    :key="course.id"
                  >
                    <th><span :class="[{[$style.non_name]: isDisableRemainStatus(course.remain)}]">{{course.name}}</span></th>
                    <td>
                      <span :class="$style.modal_allocation_table_right_status" v-if="!dispStatusStr(course)">&nbsp;</span>
                      <span :class="$style.modal_allocation_table_right_status" v-if="dispStatusStr(course)">{{dispStatusStr(course)}}</span>
                    </td>
                    <td>
                      <a :class="$style.modal_allocation_table_right_invite"
                          href="javascript:void(0)"
                          @click.stop.prevent="clickUserAllocate(course)"
                          v-if="!isInviteStatusCancel(course)"
                      >割当
                      </a>
                      <span > {{!isInviteStatusCancel(course)? '/':''}}</span>
                      <a :class="$style.modal_allocation_table_right_invite"
                          href="javascript:void(0)"
                          @click.stop.prevent="clickUserInvite(course)"
                          v-if="!isDisableRemainStatus(course.remain) || isInviteStatusCancel(course) || isInviteStatusResend(course)"
                      >{{isInviteStatusCancel(course)? '利用解除' :course.invite_status}}
                      </a>
                      <span :class="$style.modal_allocation_table_right_invite_none"
                          v-if="isDisableRemainStatus(course.remain) && !isInviteStatusCancel(course) && !isInviteStatusResend(course)"
                      >{{course.invite_status == '招待' ? '' : course.invite_status}}
                      </span>
                    </td>
                    <td :class="$style.number">
                      <span :class="[$style.modal_allocation_table_right_remain, {[$style.non_number]: isDisableRemainStatus(course.remain)}]">{{course.remain}}</span>
                    </td>
                    <td :class="$style.number">{{course.count}}</td>
                  </tr>
              </table>
            </div>
            <div :class="$style.modal_allocation_table_right" v-else>
                <div :class="$style.loading"><base-icon name="spinner" size="32px" spin /></div>
            </div>
          </div>
          <div :class="$style.modal_action_button" v-if="!searchProcessing">
            <base-button width="185px" rounded="rounded" @click="setCompleteClick">設定完了</base-button>
          </div>
          <div :class="$style.modal_action_button" v-else>
            <div :class="$style.loading"><base-icon name="spinner" size="32px" spin /></div>
          </div>
        </div>
      </template>
    </base-modal>

    <base-modal
      v-if="showInvite"
      @close="showInvite = false"
      width="960px"
      height="auto"
    >
      <template v-slot:body>
        <div :class="$style.modal_content">
          <div :class="$style.modal_header">
            <h1 :class="$style.modal_header_title">メンバー外から招待する</h1>
            <base-button
              width="185px"
              bg-color="black"
              @click="startMailer"
            >在庫管理（プラン変更依頼）</base-button>
          </div>
          <div :class="$style.modal_invite">
            <p :class="$style.modal_invite_description">以下に入力した対象をチームまたはMOVIEに招待します。下記形式に従ってリストを入力しアップロードしてください。<br/>
              招待メールが自動的に送信されます。
            </p>
            <div :class="$style.modal_invite_legend">
              <div :class="$style.modal_invite_legend_unit">
                <div :class="$style.modal_invite_legend_unit_part">※チームに招待する場合</div>
                <div :class="$style.modal_invite_legend_unit_header">チームに3名を招待する場合の入力例：</div>
                <div :class="$style.modal_invite_legend_unit_format">形式：(未入力）,Eメール</div>
                <p :class="$style.modal_invite_legend_unit_content">
                  ,sample@unionXXX.jp<br/>
                  ,sampletest@unionXXX.jp<br/>
                  ,samplemember@unionXXX.jp
                </p>
              </div>
              <div :class="$style.modal_invite_legend_unit">
                <div :class="$style.modal_invite_legend_unit_part">※MOVIEに招待する場合</div>
                <div :class="$style.modal_invite_legend_unit_header">組織リーダーコースに3名を招待する場合の入力例：</div>
                <div :class="$style.modal_invite_legend_unit_format">形式：コース名,Eメール</div>
                <p :class="$style.modal_invite_legend_unit_content">
                  組織リーダーコース,sample@unionXXX.jp<br/>
                  組織リーダーコース,sampletest@unionXXX.jp<br/>
                  組織リーダーコース,samplemember@unionXXX.jp
                </p>
              </div>
            </div>
            <div :class="$style.modal_invite_legend_triangle"></div>
            <div :class="$style.modal_invite_textarea_wrap">
              <base-text-area rows="4" v-model="inviteTextArea"></base-text-area>
            </div>
            <div :class="$style.msg_container">
              <template v-if="hasError(inviteErrorMsgList)">
                <base-message type="error" v-for="(error, i) in inviteErrorMsgList" :key="i" :class="$style.msg_area">{{error.message}}</base-message>
              </template>
              <base-message type="success" :class="$style.msg_area" v-if="inviteSuccessMsg">{{inviteSuccessMsg}}</base-message>
            </div>
          </div>
          <div :class="$style.modal_action_button" v-if="!processing">
            <base-button width="185px" rounded="rounded" @click="inviteUpload">招待する</base-button>
          </div>
          <div :class="$style.modal_action_button" v-else>
            <div :class="$style.loading"><base-icon name="spinner" size="32px" spin /></div>
          </div>
        </div>
      </template>
    </base-modal>

    <base-modal
      v-if="showReport"
      @close="showReport = false"
      width="960px"
      height="auto"
    >
      <template v-slot:body>
        <div :class="$style.modal_content">
          <div :class="$style.modal_header">
            <h1 :class="$style.modal_header_title">視聴確認レポート</h1>
            <base-button
              width="185px"
              bg-color="black"
              @click="macroDwonload"
            >整形用マクロDL</base-button>
          </div>
          <div :class="$style.modal_report">
            <table :class="$style.modal_report_table">
              <thead>
              <tr>
                <th colspan="4">条件</th>
              </tr>
              <tr>
                <th colspan="4" :class="[$style.error_report_outer, viewSuccessMsg ? $style.msg_success: '', $style.msg_outer, viewDtErrorMsgList.length > 0 ? $style.msg_error : '']">
                  <template v-if="hasError(viewDtErrorMsgList)">
                    <base-message type="error" v-for="(error, i) in viewDtErrorMsgList" :key="i" :class="$style.msg_area">{{error.message}}</base-message>
                  </template>
                  <base-message type="success" :class="$style.msg_area" v-if="viewSuccessMsg">{{viewSuccessMsg}}</base-message>
                </th>
              </tr>
              </thead>
              <tr>
                <th :class="$style.modal_report_table_range_header">期間</th>
                <td :class="$style.modal_report_table_range"><base-date-picker style="width:224px" v-model="view_dt_start" look="outline2"/></td>
                <td :class="$style.modal_report_table_range_divider"> - </td>
                <td :class="$style.modal_report_table_range"><base-date-picker style="width:224px" v-model="view_dt_end" look="outline2"/></td>
                <th :class="$style.modal_report_table_course_header">コース</th>
                <td :class="$style.modal_report_table_course">
                  <div :class="$style.check_column_group">
                    <base-check-box
                      v-for="course in courseList"
                      :key="course.id"
                      :name="course.name"
                      :value="course.id"
                      v-model="checkedCourseByView"
                    >{{course.name}}</base-check-box>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <base-message type="error" :class="$style.msg_area" v-if="isAllPeriod">{{viewErrorMsg}}</base-message>
          <div :class="$style.modal_action_button" v-if="!processing">
            <base-button width="185px" rounded="rounded" @click="viewConfirmDownload">ダウンロードURLを<br>取得する</base-button>
          </div>
          <div :class="$style.modal_action_button" v-else>
            <div :class="$style.loading"><base-icon name="spinner" size="32px" spin /></div>
          </div>
          <div :class="$style.modal_action_button">
            <font color="red"><small>
            大量のデータのダウンロードを行う場合<br>
            ネットワーク状態によりダウンロードにお時間を要する場合がございます。<br>
            ※ダウンロードが正常にできない場合期間やユーザーを絞り込みをお試しください。<br>
            </small></font>
          </div>
        </div>
      </template>
    </base-modal>

    <base-modal
      v-if="showCourseAssign"
      @close="showCourseAssign = false"
      width="960px"
      height="auto"
    >
      <template v-slot:body>
        <div :class="$style.modal_content">
          <div :class="$style.modal_header">
            <h1 :class="$style.modal_header_title">コース自動割り当て設定</h1>
          </div>
          <p :class="$style.modal_course_paragraph">チェックを付けると、対象のチームメンバーに対してコースを割り当てします。<br>※コースの人数制限がある場合は、先着で割当します。</p>
          <table :class="$style.modal_course_table" >
            <thead style="padding-right: 17px;">
              <tr style="border-bottom: 1px solid #707070; padding-right: 17px;">
                <th width=10%></th>
                <th :class="$style.modal_course_table_title" width=50%;>契約コース一覧</th>
                <th  id="popover" class="popover" style="cursor: pointer;" width=15%;>
                  <div
                   width=15%;
                    v-b-popover.hover
                    title="現在チームに所属しているユーザーにコースを割り当てます。"
                    >
                      既存メンバー割当
                  </div>
                </th>
                <th  id="popover" class="popover" style="cursor: pointer; background-color: #fffacd; argin-left: 3%" width=15%; text-align="center">
                  <div
                    width=15%;
                    v-b-popover.hover
                    title="今後、チームに所属したユーザーにコースを割り当てます。"
                    >
                      新規メンバー割当
                  </div>
                </th>
                <th width=10% style="padding-right: 17px;"></th>
                <th width=17px ></th>
              </tr>
              <tr>
                <th :class="[$style.error_report_outer, viewSuccessMsg ? $style.msg_success: '', $style.msg_outer, viewDtErrorMsgList.length > 0 ? $style.msg_error : '']">
                  <template v-if="hasError(viewDtErrorMsgList)">
                    <base-message type="error" v-for="(error, i) in viewDtErrorMsgList" :key="i" :class="$style.msg_area">{{error.message}}</base-message>
                  </template>
                  <base-message type="success" :class="$style.msg_area" v-if="viewSuccessMsg">{{viewSuccessMsg}}</base-message>
                </th>
              </tr>
              </thead>
          </table>
          <hr>
          <div style="overflow-y: scroll; height:450px; margin-bottom: 50px; padding-left:0%;" >
          <div :class="$style.modal_course">
            <table :class="$style.modal_course_table" >
              <tr v-for="course in courseList" :key="course.id" >
                <td width=10%></td>
                <td :class="$style.modal_course_table_range" >{{course.name}}</td>
                <td :class="$style._checkbox" style="margin-left: 7%; text-align: center" >
                  <base-check-box
                      :key="course.id"
                      :name="course.name"
                      :value="course.id"
                      v-model="checkedExistsAssign"
                  ></base-check-box>
                </td>
                <td :class="$style._toggle" style="padding-top: 1vh; text-align: center">
                  <toggle-button
                    :key="course.id"
                    :name="course.name"
                    v-model="toggledStates[course.id]"
                    height="18">
                  </toggle-button>
                </td>
                <td width=10%></td>
              </tr>
            </table>
          </div>
          </div>
          <base-message type="error" :class="$style.msg_area" v-if="isAllPeriod">{{viewErrorMsg}}</base-message>
          <div :class="$style.modal_action_button" v-if="!processing">
            <base-button width="185px" rounded="rounded" @click="saveCourseAssign">実行</base-button>
          </div>
          <div :class="$style.modal_action_button" v-else>
            <div :class="$style.loading"><base-icon name="spinner" size="32px" spin /></div>
          </div>
        </div>
      </template>
    </base-modal>

    <base-modal
      v-if="showCompletionModal"
      @close="showCompletionModal = false"
      width="360px"
      height="120px"
    >
      <template v-slot:body>
        <p style="text-align: center">コース自動割当設定が完了いたしました。</p>
        <div style="text-align: center">
          <base-button rounded="rounded" @click="showCompletionModal = false">閉じる</base-button>
        </div>
      </template>
    </base-modal>
    <app-scroll-button />

  </section>
</template>

<script>
import BaseButton from '@/components/base/BaseButton/BaseButton'
// import BaseInput from '@/components/base/BaseInput/BaseInput'
import BaseDatePicker from '@/components/base/BaseDatePicker/BaseDatePicker'
import BaseCheckBox from '@/components/base/BaseCheckBox/BaseCheckBox'
import BaseTextArea from '@/components/base/BaseTextArea/BaseTextArea'
import AppPrevButton from '@/components/app/AppPrevButton/AppPrevButton'
import AppTableList from '@/components/app/AppTableList/AppTableList'
import BaseModal from '@/components/base/BaseModal/BaseModal'
import BaseIcon from '@/components/base/BaseIcon/BaseIcon'
import BaseSelect from '@/components/base/BaseSelect/BaseSelect'
import BaseMessage from '@/components/base/BaseMessage/BaseMessage'
import {hasError} from '@/helper/validator.js'
import {fetchTeamUserInfo} from '@/helper/common.js'
import AppScrollButton from '@/components/app/AppScrollButton/AppScrollButton'
export default {
  name: 'UnionSetting',
  components: { BaseButton, BaseDatePicker, BaseCheckBox, BaseTextArea, AppPrevButton, AppTableList, BaseModal, BaseIcon, BaseSelect, BaseMessage, AppScrollButton },
  props: {
    team_id: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    this.$router.app.$emit('page-updated', {
      pageTitle: 'チームメンバー招待/割当',
      back: '/',
      headerColor: 'white',
      showSetting: false,
      bgColor: 'gray'
    })
    this.initializeToggledStates();
  },
  data() {
    return {
      showAllocation: false,
      showInvite: false,
      showReport: false,
      showBulk: false,
      showCourseAssign: false,
      tableColDefs: [
        {
          key: "juid",
          label: "JU-ID",
          sortable: true
        },
        {
          key: "name",
          label: "名前",
          sortable: true,
        },
        {
          key: "nickname",
          label: "ニックネーム",
          sortable: true
        },
        {
          key: "auth_type_name",
          label: "権限",
          sortable: true
        },
        {
          key: "course_list",
          label: "受講可能コース",
          slot: "courses_slot"
        },
        {
          key: "last_login_timestamp",
          label: "最終ログイン",
          sortable: true
        },
        {
          label: "設定",
          clickable: true,
          slot: "config_slot"
        },
      ],
      tableRowData: [],
      tablePageIndex: 1,
      tableSelected: [],

      initLoading: false,
      searchProcessing: false,
      processing: false,

      juid: null,
      nickname: null,
      name: null,
      from_created_by: null,
      to_created_by: null,
      from_last_login_time_stamp: null,
      to_last_login_time_stamp: null,
      checkedAble: [],
      checkedAuth: [],
      checkedStatus: [],
      per_page: 1000,
      total: null,
      isInitSearch: true,
      sortKey: "",
      sortAsc: true,
      search_condition: null,
      courseList: [],
      courseListByListBox: [],
      selected_course_id: null,
      checkedCourseByView: [],
      view_dt_start: null,
      view_dt_end: null,
      inviteTextArea: '',
      inviteErrorMsgList: [],
      inviteSuccessMsg: '',
      user : null,
      courseListByUser: [],

      searchCondErrorMsgList: [],
      memberUpErrorMsgList: [],
      memberUpSuccessMsg: '',
      viewDtErrorMsgList: [],
      viewSuccessMsg: '',
      viewErrorMsg: '※開始日にご指定の期間（2023年10月26日以前）を指定した場合、全期間累計の再生数・表示回数が出力されます。',
      uniSalonErrorMsgList: [],
      uniSalonSuccessMsg: '',
      isEditAllocation: false,
      isAllPeriod: false,
      user_name: '', // use mailer

      checkedAutoAssign: [], //TODO: 初期選択状態 コース一覧取得API /api/v1_2/course/listのレスポンスでコース自動割り当てONのものがある場合、初期表示時にこのリストに追加する
      checkedExistsAssign: [],
      showCompletionModal: false, // 完了モーダルの表示状態

      auth_type: null,
    }
  },
  methods: {
    handleSelect(selected) {
      this.tableSelected = selected;
    },
    createCondition: function (){
      let search_params = {}

      if(this.juid){
        search_params = {...search_params, juid: this.juid}
      }
      if(this.nickname){
        search_params = {...search_params, nickname: this.nickname, name: this.nickname}
      }
      if(this.from_created_by){
        search_params = {...search_params, from_created_by: this.$dayjs(this.from_created_by).format('YYYY-MM-DD HH:mm')}
      }
      if(this.to_created_by){
        search_params = {...search_params, to_created_by: this.$dayjs(this.to_created_by).format('YYYY-MM-DD HH:mm')}
      }
      if(this.from_last_login_time_stamp){
        search_params = {...search_params, from_last_login_time_stamp: this.$dayjs(this.from_last_login_time_stamp).format('YYYY-MM-DD HH:mm')}
      }
      if(this.to_last_login_time_stamp){
        search_params = {...search_params, to_last_login_time_stamp: this.$dayjs(this.to_last_login_time_stamp).format('YYYY-MM-DD HH:mm')}
      }
      if(this.checkedAble.length > 0){
        search_params = {...search_params, course_id_list: this.checkedAble.join(',')}
      }
      if(this.checkedAuth.length > 0){
        search_params = {...search_params, auth_type_list: this.checkedAuth.join(',')}
      }
      if(this.checkedStatus.length > 0){
        search_params = {...search_params, status_list: this.checkedStatus.join(',')}
      }
      return search_params;
    },
    searchClick: function () {
      if (this.searchProcessing) return
      this.sortKey = "";
      this.sortAsc = true;
      const col_def_temp = [...this.tableColDefs]
      this.tableColDefs = []

      this.$nextTick(() => {
          this.tableColDefs = [...col_def_temp];
      });

      this.selected_course_id = null
      this.memberUpErrorMsgList = []
      this.memberUpSuccessMsg = ''
      this.search_condition = null

      this.searchCondErrorMsgList =[]
      if(this.checkedAble.length > 0 && this.checkedStatus.length == 0){
        this.searchCondErrorMsgList.push({message: '受講可能コース条件を選択した場合、状態条件の何れかをチェックしてください。'})
        this.scrollToTop();
        return;
      }

      this.search(null, true)
    },
    search: async function (params_arg, isSearchClick = false) {
      if (this.searchProcessing) return
      let params = {}
      if(params_arg){
        params = {...params_arg}
      }
      if(isSearchClick){
        this.search_condition = this.createCondition()
      }
      params = {...params, ...this.search_condition , team_id: this.team_id}

      if(!this.sortKey) { //default sort
        params = { ...params, sort: 'created_at', direction: 'desc'}
      }

      params = { ...params, limit: this.per_page }
      this.memberUpErrorMsgList = []
      this.memberUpSuccessMsg = ''
      this.searchProcessing = true
      try {
        if(this.isInitSearch){
          this.tableRowData = []
        }
        this.tablePageIndex = 1
        this.tableSelected = []
        if (params.sort && params.sort == 'auth_type_name') {
          params.sort = 'auth_type'
        }
        const result = await this.$axios.get('/api/' + this.$constants.API_VERSION + '/user/member', { params })
        if (result.data.status == 'success') {
          if(result.data.value.items && result.data.value.items.length > 0){
            this.tableRowData = result.data.value.items
            this.tablePageIndex = Number(result.data.value.current_page)
            this.total = result.data.value.total
            return
          }
        }
        this.tableRowData = []
        this.tablePageIndex = 1
        this.total = 0
      } finally {
        this.searchProcessing = false
        this.isInitSearch = false
      }
    },
    resetClick: async function () {
      this.juid = null;
      this.nickname = null;
      this.from_created_by = null;
      this.to_created_by = null;
      this.from_last_login_time_stamp = null;
      this.to_last_login_time_stamp = null;
      this.checkedAble = [];
      this.checkedAuth = [];
      this.checkedStatus = [];
      this.sortKey = "";
      this.sortAsc = true;
    },
    pageNoClick: function (listPage) {
      let params = {
        page: Number(listPage)
      }
      if(!this.sortKey){
        params = {
          ...params,
          sort: this.sortKey,
          direction: this.sortAsc ? 'asc' : 'desc'
        }
      }
      this.search(params)
    },
    sortClick: function (sortObj) {
      this.sortKey = sortObj.key
      this.sortAsc = sortObj.asc
      let params = {}
      if(this.sortKey) {
          params = {
            ...params,
            sort: this.sortKey,
            direction: this.sortAsc ? 'asc' : 'desc'
          }
      }
      this.search(params)
    },
    allocateMembers: async function () {
      if (this.processing) return

      this.memberUpErrorMsgList = []
      this.memberUpSuccessMsg = ''

      // 選択したコースリストを抽出
      const targetRowData = []
      this.tableRowData.forEach((row, index) => {
        if (this.tableSelected.includes(index)){
          targetRowData.push({user_id: row.user_id, course_list: row.course_list, index})
        }
      });

      if(targetRowData.length == 0) {
        this.memberUpErrorMsgList.push({message: '一覧から対象をチェックボックスで選択してください。'});
      }
      if(!this.selected_course_id) {
        this.memberUpErrorMsgList.push({message: '対象コースを選択してください。'});
      }

      // 選択したコースリストがチェック対象行のコースリストに存在するかチェック
      targetRowData.forEach((row) => {
        const course_list = row.course_list.map((course)=>{
          return course.course_id
        })
        if(course_list.includes(this.selected_course_id)){
          this.memberUpErrorMsgList.push({message: `${row.index + 1}行目に既に利用中のコースが存在します。`});
        }
      });

      if(this.memberUpErrorMsgList.length > 0){
        return
      }

      if(targetRowData.length > 0) {
        let params = []
        targetRowData.forEach((row) => {
          params.push({
            user_id: row.user_id,
            course_id: this.selected_course_id,
            team_id: this.team_id,
            status: 'use'})
        });

        const result = await this.uniSalonUserAllocate(params)
        if (result) {
          // 再表示
          let params = {
            page: this.tablePageIndex
          }
          if(!this.sortKey){
            params = {
              ...params,
              sort: this.sortKey,
              direction: this.sortAsc ? 'asc' : 'desc'
            }
          }
          // 再検索
          this.search(params)
          this.memberUpSuccessMsg = "まとめてコース割当処理が完了しました。"
        }
      }
    },
    inviteMembers: async function () {
      if (this.processing) return

      this.memberUpErrorMsgList = []
      this.memberUpSuccessMsg = ''

      // 選択したコースリストを抽出
      const targetRowData = []
      this.tableRowData.forEach((row, index) => {
        if (this.tableSelected.includes(index)){
          targetRowData.push({user_id: row.user_id, course_list: row.course_list, index})
        }
      });

      if(targetRowData.length == 0) {
        this.memberUpErrorMsgList.push({message: '一覧から対象をチェックボックスで選択してください。'});
      }
      if(!this.selected_course_id) {
        this.memberUpErrorMsgList.push({message: '対象コースを選択してください。'});
      }

      // 選択したコースリストがチェック対象行のコースリストに存在するかチェック
      targetRowData.forEach((row) => {
        const course_list = row.course_list.map((course)=>{
          return course.course_id
        })
        if(course_list.includes(this.selected_course_id)){
          this.memberUpErrorMsgList.push({message: `${row.index + 1}行目に既に利用中のコースが存在します。`});
        }
      });

      if(this.memberUpErrorMsgList.length > 0){
        return
      }

      if(targetRowData.length > 0) {
        let params = []
        targetRowData.forEach((row) => {
          params.push({
                    user_id: row.user_id,
                    type: 'union_salon',
                    team_id: this.team_id,
                    course_id: this.selected_course_id})
        });

        this.processing = true;
        this.$axios
          .post('/api/' + this.$constants.API_VERSION + '/invite/mail', {
            invite_flow_list: params,
          })
          .then((res) => {
            if (res.data.status == 'success') {
              this.memberUpSuccessMsg = 'まとめてコース招待処理が完了しました。'
            }
          })
          .catch((e) => {
            if (e.response && e.response.status === 400) {
              this.memberUpErrorMsgList = e.response.data.value.errors;
            }
          })
          .finally(() => {
            this.processing = false
          })
      }
    },

    uniCancelMembers: async function () {
      if (this.processing) return

      // 確認ダイアログを表示
      const confirmed = window.confirm("チェックしたユーザーのコース閲覧権限を削除します。");
      if (!confirmed) {
        // ユーザーがキャンセルを選択した場合、処理を中止
        return;
      }
      this.memberUpErrorMsgList = []
      this.memberUpSuccessMsg = ''

      // 選択したコースリストを抽出
      const targetRowData = []
      this.tableRowData.forEach((row, index) => {
        if (this.tableSelected.includes(index)){
          targetRowData.push({user_id: row.user_id, course_list: row.course_list, index})
        }
      });

      if(targetRowData.length == 0) {
        this.memberUpErrorMsgList.push({message: '一覧から対象をチェックボックスで選択してください。'});
      }
      // if(!this.selected_course_id) {
      //   this.memberUpErrorMsgList.push({message: '対象コースを選択してください。'});
      // }

      if(this.memberUpErrorMsgList.length > 0){
        return
      }

      if(targetRowData.length > 0) {
        let params = []
        targetRowData.forEach((row) => {
          row.course_list.forEach((course) => {
            params.push({
                      user_id: row.user_id,
                      course_id: course.course_id,
                      team_id: this.team_id,
                      status: 'cancel'})
          })
        });

        this.processing = true;
        this.$axios
          .post('/api/' + this.$constants.API_VERSION + '/union_salon/user/registration', {
                union_salon_user_list: params,
            })
          .then((res) => {
            if (res.data.status == 'success') {
              this.memberUpSuccessMsg = 'コース一括削除処理が完了しました。'

              // 再表示
              let params = {
                page: this.tablePageIndex
              }
              if(!this.sortKey){
                params = {
                  ...params,
                  sort: this.sortKey,
                  direction: this.sortAsc ? 'asc' : 'desc'
                }
              }
              // 再検索
              this.search(params)
              this.memberUpSuccessMsg = "コース一括削除処理が完了しました。"
            }
          })
          .catch((e) => {
            if (e.response && e.response.status === 400) {
              this.memberUpErrorMsgList = e.response.data.value.errors;
            }
          })
          .finally(() => {
            this.processing = false
          })
      }
    },
    fetchCourseList: function () {
      const params = {team_id: this.team_id, sort: 'created_at', direction: 'desc', limit: 100}
      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/course/list', {params})
        .then((res) => {
          if (res.data.status == 'success') {
            this.courseList = res.data.value.course_list
            this.courseListByListBox = this.courseList.map((course)=>{
              this.initializeToggledStates();
              return {label: course.name, value: course.id}
            })
          } else {
            this.courseList = []
            this.courseListByListBox = []
          }
        })
    },


    fetchUserName: function () {
      this.user_name = ''
      const params = {display_name_flg: 0}
      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/user/info', {params})
        .then((res) => {
          if (res.data.status == 'success') {
            this.user_name = res.data.value.user.name ?? (res.data.value.user.nickname ?? '')
          }
        })
    },

    async getTeamUserInfo() {
      // 組織ユーザー情報取得
      const { value } = await fetchTeamUserInfo({ team_id: this.team_id })
      this.auth_type = value?.auth_type
    },

    async initFetch() {
      this.initLoading = true;
      try {
        await Promise.all([
          this.fetchCourseList(),
          this.fetchUserName(),
          await this.getTeamUserInfo()
        ])
      }
      finally {
        this.initLoading = false
      }
    },
    viewConfirmDownload: function () {
      if (this.processing) return

      this.viewDtErrorMsgList = []
      this.viewSuccessMsg = ''

      let params = {}

      if (!this.view_dt_start) {
        this.viewDtErrorMsgList.push({message: '期間 開始日時を指定してください。'})
      } else {
        // 開始日が2023/10/26以前の場合全期間累計になるのでメッセージを表示する
        const specifiedDate = new Date('2023-10-27')
        const startDate = new Date(this.view_dt_start)
        this.isAllPeriod = startDate < specifiedDate

        params = {...params, from: this.$dayjs(this.view_dt_start).format('YYYY-MM-DD HH:mm')}
      }

      if(this.view_dt_end){
        params = {...params, to: this.$dayjs(this.view_dt_end).format('YYYY-MM-DD HH:mm')}
      }else{
        this.viewDtErrorMsgList.push({message: '期間 終了日時を指定してください。'})
      }
      if(this.checkedCourseByView && this.checkedCourseByView.length > 0){
          params  = {...params, course_id_list: this.checkedCourseByView}
      }else{
        this.viewDtErrorMsgList.push({message: 'コースを指定してください。'})
      }

      if(this.viewDtErrorMsgList.length > 0){ //未入力エラー
        return;
      }

      // 選択したコースリストを抽出
      const targetRowData = []
      this.tableRowData.forEach((row, index) => {
        if (this.tableSelected.includes(index)){
          targetRowData.push(row.user_id)
        }
      })
      if(targetRowData.length == 0) {
        params = {...params, user_id_list: []}
      }else{
        params = {...params, user_id_list: targetRowData}
      }

      params = {team_id: this.team_id, ...params}

      this.processing = true;
      // 視聴確認レポート取得API
      this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/report/download', {...params})
        .then((res) => {
          if (res.data.status == 'success') {
            // TODO: メッセージ変更必要？
            this.viewSuccessMsg = '視聴確認レポートのダウンロード処理を受付いたしました。ダウンロードが完了しましたら、メールと投稿にてURLをお送りいたします。'
          }
        })
        .catch((e) => {
          if (e.response && e.response.status === 400) {
            this.viewDtErrorMsgList = e.response.data.value.errors;
          } else {
            this.viewDtErrorMsgList.push({message: 'データ容量が多くなってしまうため、条件を絞りこみもう一度ダウンロードをお試しください。'})
          }
        })
        .finally(() => {
          this.processing = false
        })
    },
    macroDwonload: async function () {
      if (this.processing) return

      this.viewDtErrorMsgList = []
      this.viewSuccessMsg = ''
      this.processing = true
      try {
        // ファイルダウンロードAPI call
        const result = await this.$axios.post('/api/' + this.$constants.API_VERSION + '/file/download', { path: '/report/macro/union_salon_macro.xlsm' })
        if (result.data.status == 'success') {
            this.viewSuccessMsg = 'ダウンロードが完了しました。(整形用マクロ)'
            this.downloadFile(result.data.value.image, result.data.value.file_name, "application/vnd.ms-excel")
        }
      } catch (e) {
        if (e.response && e.response.status === 400) {
          this.viewDtErrorMsgList = e.response.data.value.errors;
        }
      } finally {
        this.processing = false
      }
    },
    downloadFile: function(data, fileName, type) {
      const decoded_utf8str = atob(data);
      let decoded = new Uint8Array(Array.prototype.map.call(decoded_utf8str, c => c.charCodeAt()));
      if (window.navigator.msSaveOrOpenBlob) {
        // Edge IE
        window.navigator.msSaveOrOpenBlob(decoded, fileName);
      } else {
        // Chrome firefox
        const url = URL.createObjectURL(new Blob([decoded], {type}));
        const elem = document.createElement('a');
        elem.href = url;
        elem.setAttribute('download', fileName);
        document.body.appendChild(elem);
        elem.click();

        URL.revokeObjectURL(url);
        elem.parentNode.removeChild(elem);
      }
    },
    inviteUpload: async function() {
      if (this.processing) return

      this.inviteErrorMsgList = []
      this.inviteSuccessMsg = ''
      if (!this.inviteTextArea || this.inviteTextArea.trim() == ''){
        this.inviteErrorMsgList.push({attribute: 0, message:'入力エリアに値を設定してください。'})
        return;
      }
      const rows = this.inviteTextArea.split('\n');
      if(rows.length == 0) return

      this.processing = true
      try {
        // 招待フロー一括登録API call
        const result = await this.$axios.post('/api/' + this.$constants.API_VERSION + '/invite/invite_team',
          { team_id: this.team_id, text: this.inviteTextArea }
        )
        if (result.data.status == 'success') {
            this.inviteSuccessMsg = "アップロードが完了しました。(メンバー外招待)"
        }
      } catch (e) {
        if (e.response && e.response.status === 400) {
          this.inviteErrorMsgList = e.response.data.value.errors;
        }
      } finally {
        this.processing = false
      }
    },
    startMailer: function() {
      const to_address = `${process.env.VUE_APP_ADMIN_MAIL_TO}`
      // const body1 = `${this.user_name}さんがプランの変更依頼致しました。`
      // let newLine = '%0D%0A'
      // // body.replace(/\n\r?/g, '%0D%0A');
      // const body2 = '承認完了までしばらくお待ちください。'
      // let body = ''
      // const userAgent = navigator.userAgent.toUpperCase();
      // if (userAgent.indexOf("MAC") >= 0) {
      //   body = body1 + newLine + body2
      // } else {
      //   body = convertShiftJIS(body1) + newLine + convertShiftJIS(body2)
      // }
      let subject = 'プラン変更依頼'
      location.href = 'mailto:' + to_address + '?subject=' + subject;
    },
    cellClick: async function(cellObj) {
      const {col, rowDat} = cellObj
      if(col == 6){ // 設定列
        this.user = rowDat
        this.showAllocation = true
        await this.fetchCourseListByUser(rowDat.user_id)
      }
    },
    fetchUserInfo: function (user_id) {
      return this.$axios.get('/api/' + this.$constants.API_VERSION + '/user/info', {params: {user_id}}).then((res) => {
        if (res.data.status == 'success') {
          this.user = res.data?.value?.user
        } else {
          this.user = null
        }
      })
    },
    fetchCourseListByUser: function (user_id) {
      this.courseListByUser = []
      const params = {user_id, team_id: this.team_id, sort: 'created_at', direction: 'desc', limit: 100}
      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/course/list', {params})
        .then((res) => {
          if (res.data.status == 'success') {
            this.courseListByUser = res.data.value.course_list
          } else {
            this.courseListByUser = []
          }
        })
        .finally(() => {})
    },
    dispStatusStr: function(course) {
      switch (course.invite_status) {
        case '解除':
          return '※利用中'
        case '再送':
          return `※招待済${this.getMonthDayStr(course.updated_at)}`
        default:
          break;
      }
      return ''
    },
    getMonthDayStr: function(target){
      if(!target) return ''
      return `(${target})`
    },
    clickUserAllocate: async function(course) {
      const union_salon_user_list = [{
        user_id: this.user.user_id,
        course_id: course.id,
        team_id: this.team_id,
        status: 'use'}]

      const result = await this.uniSalonUserAllocate(union_salon_user_list)
      if (result) {
        // ステータスと残席の更新
        course.invite_status = '解除'
        course.remain = Number(course.remain) - 1
      }
    },
    clickUserInvite: function(course) {
      switch (course.invite_status) {
        case '招待':
          this.uniSalonUserInvite(course)
          break;
        case '解除':
          this.uniSalonUserCancel(course)
          break;
        case '再送':
          this.uniSalonUserReInvite(course)
          break;
        default:
          break;
      }
    },
    uniSalonUserInvite: async function(course){
      if (this.processing) return

      this.uniSalonErrorMsgList = []
      this.uniSalonSuccessMsg = ''
      this.processing = true
      this.isEditAllocation = true //編集フラグ
      try {
        // 招待フロー登録・更新API call
        const result = await this.$axios.post('/api/' + this.$constants.API_VERSION + '/invite/mail',
          { invite_flow_list: [
            { user_id: this.user.user_id,
              type: 'union_salon',
              team_id: this.team_id,
              course_id: course.id}]
          }
        )
        if (result.data.status == 'success') {
            // ステータスと招待日の更新
              course.invite_status = '再送'
              course.updated_at = this.$dayjs().format('MM/DD')
              this.uniSalonSuccessMsg = `処理が完了しました。(招待)`
              return
        }
      } catch (e) {
        if (e.response && e.response.status === 400) {
          this.uniSalonErrorMsgList = e.response.data.value.errors;
        }
      } finally {
        this.processing = false
      }
    },
    uniSalonUserAllocate: async function(union_salon_user_list){
      if (this.processing) return
      this.uniSalonErrorMsgList = []
      this.uniSalonSuccessMsg = ''
      this.processing = true
      this.isEditAllocation = true //編集フラグ
      let res = false
      try {
        // UNION-SALON登録ユーザー登録・更新API call
        const result = await this.$axios.post('/api/' + this.$constants.API_VERSION + '/union_salon/user/registration',
          { union_salon_user_list }
        )
        if (result.data.status == 'success') {
            res = true
            this.uniSalonSuccessMsg = `処理が完了しました。(割当)`
        }
      } catch (e) {
        if (e.response && e.response.status === 400) {
          this.uniSalonErrorMsgList = e.response.data.value.errors;
        }
      } finally {
        this.processing = false
      }
      return res
    },
    uniSalonUserCancel: async function(course){
      if (this.processing) return

      this.uniSalonErrorMsgList = []
      this.uniSalonSuccessMsg = ''
      this.processing = true
      this.isEditAllocation = true //編集フラグ
      try {
        // UNION-SALON登録ユーザー登録・更新API call
        const result = await this.$axios.post('/api/' + this.$constants.API_VERSION + '/union_salon/user/registration',
          { union_salon_user_list: [
            { user_id: this.user.user_id,
              course_id: course.id,
              team_id: this.team_id,
              status: 'cancel' }]
          }
        )
        if (result.data.status == 'success') {
            // ステータスと残席の更新
            course.invite_status = '招待'
            course.remain = Number(course.remain) + 1
            this.uniSalonSuccessMsg = `処理が完了しました。(解除)`
            return
        }
      } catch (e) {
        if (e.response && e.response.status === 400) {
          this.uniSalonErrorMsgList = e.response.data.value.errors;
        }
      } finally {
        this.processing = false
      }
    },
    uniSalonUserReInvite: async function(course){
      if (this.processing) return

      this.uniSalonErrorMsgList = []
      this.uniSalonSuccessMsg = ''
      this.processing = true
      this.isEditAllocation = true //編集フラグ
      try {
        // 招待フロー登録・更新API call
        const result = await this.$axios.post('/api/' + this.$constants.API_VERSION + '/invite/mail',
          { invite_flow_list: [
            { user_id: this.user.user_id,
              type: 'union_salon',
              team_id: this.team_id,
              course_id: course.id,
              resend_flg: 1}]
          }
        )
        if (result.data.status == 'success') {
              // 招待日の更新
              course.updated_at = this.$dayjs().format('MM/DD')
              this.uniSalonSuccessMsg = `処理が完了しました。(再送)`
              return
        }
      } catch (e) {
        if (e.response && e.response.status === 400) {
          this.uniSalonErrorMsgList = e.response.data.value.errors;
        }
      } finally {
        this.processing = false
      }
    },
    isDisableRemainStatus: function(remain) {
      return remain == null || remain <= 0
    },
    isInviteStatusCancel: function(course) {
      return course.invite_status == '解除'
    },
    isInviteStatusResend: function(course) {
      return course.invite_status == '再送'
    },
    setCompleteClick: function() {
      if(this.isEditAllocation){
        // pageNo設定
        let params = {
          page: this.tablePageIndex
        }
        if(!this.sortKey){
          params = {
            ...params,
            sort: this.sortKey,
            direction: this.sortAsc ? 'asc' : 'desc'
          }
        }
        // 再検索
        this.search(params)
      }
      this.showAllocation = false
    },
    saveCourseAssign: async function(){
      console.log('発火')
      if (this.processing) return

      this.uniSalonErrorMsgList = []
      this.uniSalonSuccessMsg = ''
      this.processing = true
      try {
        // toggledStatesの値を使用してautoAssignListを構築
        const autoAssignList = this.courseList.map(course => {
          return {
            course_id: course.id,
            auto_assign_flg: this.toggledStates[course.id] ? 1 : 0
          };
        });
        // デバッグ用：auto_assign_listの内容を確認
        console.log('自動割り当てフラグの更新')
        const result = await this.$axios.post('/api/' + this.$constants.API_VERSION + '/team/course/auto_assign/registration',
          { team_id: this.team_id,
            auto_assign_list: autoAssignList
          });
        if (result.data.status == 'success') {
          console.log('success',result)
          this.showCompletionModal = true; // 完了モーダルを表示
          // this.initializeToggledStates();
        }
      } catch (e) {
        console.log(e)
        if (e.response && e.response.status != 200) {
          // エラーを表示して処理終了
          this.uniSalonErrorMsgList = e.response.data.value.errors;
        }
        return
      } finally {
        this.processing = false
      }

      try {
        //
        console.log('既存ユーザーへの割当')
        // チェックされたコースのIDに基づいてリクエストボディを作成
        const existAssignList = this.courseList
          .filter(course => this.checkedExistsAssign.includes(course.id))
          .map(course => ({
            course_id: course.id,
            exist_assign_flg: 1 // 割当る
          }));
        // existAssignListが空の場合はリクエストを送らずに処理を終了
        if (existAssignList.length === 0) {
          console.log('割当コースが選択されていません。');
          this.uniSalonSuccessMsg = `割当るコースが選択されていません。`;
          return;
        }
        const result2 = await this.$axios.post(
          '/api/' + this.$constants.API_VERSION + '/team/course/exist_assign/registration',
          {
            team_id: this.team_id,
            exist_assign_list: existAssignList
          }
        );

        console.log(result2)
        if (result2.data.status == 'success') {
          this.uniSalonSuccessMsg = `処理が完了しました。`
          this.checkedExistsAssign = [];
          return
        }
      } catch (e) {
        console.log(e)
        if (e.response && e.response.status != 200) {
          // TODO:エラーを表示して処理終了
          this.uniSalonSuccessMsg = `既存ユーザー割当処理が失敗しました。`
        }
      } finally {
        this.processing = false
      }
    },

    initializeToggledStates() {
      this.toggledStates = {};
      this.courseList.forEach(course => {
        this.toggledStates[course.id] = course.auto_assign_flg === 1;
      });
    },


    scrollToTop: function(){
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    },
    scrollToBottom() {
      const element = document.documentElement
      const bottom = element.scrollHeight - element.clientHeight
      window.scroll(0, bottom)
    },
    hasError,
  },
  computed: {
    tableSelectedNum() {
      return this.tableSelected.length;
    },
    totalRowCount(){
      return this.total == null ? '' : this.total;
    },
    isRows() {
      return this.total && this.total > 0
    },
  },
  watch: {
    showBulk(nv, ov) {
      if(!nv && ov){
        this.memberUpErrorMsgList = []
        this.memberUpSuccessMsg = ''
      }
    },
    showInvite(nv, ov) {
      if(!nv && ov){
        this.inviteTextArea = '';
        this.inviteSuccessMsg = ''
        this.inviteErrorMsgList = [];
      }
    },
    showReport(nv, ov) {
      if(!nv && ov){
        this.checkedCourseByView = [];
        this.view_dt_start = null;
        this.view_dt_end = null;
        this.viewSuccessMsg = '';
        this.viewDtErrorMsgList = [];
      }
    },
    showAllocation(nv, ov) {
      if(!nv && ov){
        this.user = null
        this.courseListByUser = []
        this.uniSalonSuccessMsg = ''
        this.uniSalonErrorMsgList = []
        this.isEditAllocation = false
      }
    },

  },
  created() {
    this.initFetch()
  },
}
</script>

<style lang="scss" module>
.union_setting {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 12px 0 0 0;
  width: 960px;
}
.union_setting_content {
  width: 100%;
  &.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}

.filter_wrap {
  width: 100%;
  margin-bottom: 34px;

  .filter_header {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 26px;

    &_title {
      font-size: 30px;
      margin: 0 auto 0 0;
    }

    & > *:last-child {
      margin-left: 32px;
    }
  }

  .filter {
    width: 100%;
    background-color: $keyWhite;
    border: 2px solid $keyBlack;
    box-sizing: border-box;

    &_table {

      &_container {
        display: grid;
        grid-template:
      "left right" auto
      "bottom bottom" auto / 456px 1fr
      ;
        padding: 28px 32px;
      }

      &_left, &_right, &_bottom {
        border-spacing: 0;
        th, td {
          padding: 0;
          text-align: left;
        }
        // &::after {
        //   content: '';
        //   display: block;
        //   width: 90%; /* 境界線の長さを90%に設定 */
        //   height: 1px;
        //   background-color: #707070;
        //   position: absolute;
        //   bottom: 0;
        //   left: 5%; /* 中央に寄せるための調整 */
        // }
      }

      &_left {
        grid-area: left;
        tr:not(:last-child) {
          th, td {
            padding-bottom: 10px;
          }
        }
        th {
          width: 156px;
          font-size: 13px;
        }
        td {
          width: 300px;
          & > *:not(:last-child) {
            padding-bottom: 10px;
          }
        }
      }

      &_right {
        grid-area: right;
        margin-left: 76px;
        th {
          vertical-align: top;
          padding-top: 10px;
          width: 134px;
          font-size: 13px;
        }
        td {
          vertical-align: top;
          padding-top: 6px;
        }
      }

      &_bottom {
        grid-area: bottom;
        margin-top: 20px;
        tr:not(:last-child) {
          th, td {
            padding-bottom: 24px;
          }
        }
        th {
          width: 156px;
          font-size: 13px;
        }
      }

    }

    &_check_colum_group {
      & > * {
        margin-bottom: 4px;
      }
    }
    &_check_row_group {
      display: flex;
      & > * {
        margin-right: 36px;
      }
    }

    &_action_button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 70px;
      border-top: 1px solid #707070;
      box-sizing: border-box;

      & > *:last-child {
        margin-left: 30px;
      }
      &_loading_area {
        width: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.list_table_wrap {
  width: 100%;
  .not_found {
    margin: 0px 20px 30px;
    font-size: 14px;
    font-weight: 600;
  }
  .list_table_header {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 26px;

    &_title {
      font-size: 30px;
      margin: 0 20px 0 0;
    }

    &_total {
      margin-right: auto;
      font-size: 16px;
      span {
        font-weight: bold;
      }
    }
    &_selected {
      margin-right: 16px;
      font-size: 13px;
      span {
        font-weight: bold;
        color: $keyPink;
      }
    }

    & > *:last-child {
      margin-left: 20px;
    }
  }

  & .course_list_area {
    & .course_list_header {
      margin: 6px auto;
      font-size: 14px;
      font-weight: 600;
    }
    & .course_list_contents {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 10px auto;
      & .col_action{
        flex: 3;
        display: flex;
        align-items: center;
        & select {
          width: 300px;
          height: 50px;
          margin-right: 48px;
        }
        & .course_btn {
          width: auto;
          margin-right: 16px;
        }
        & .course_release_btn {
          width: auto;
          margin-right: 16px;
          color: red;
          background-color: red;
        }
      }
      & .col_msg{
        flex: 2;
      }
    }
  }
}

.modal_content {
  padding: 20px 28px 20px 28px;
}
.modal_header {
  display: flex;
  align-items: center;
  width: 100%;

  & [class^="BaseButton_button"] {
    padding: 0;
  }
  &_title {
    font-size: 30px;
    margin: 0 auto 0 0;
  }
}

.modal_action_button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal_allocation {
  margin: 20px 0 52px 0;
  display: grid;
  grid-template: "left right" auto /356px 1fr;
  &_table_left {
    grid-area: left;
    table {
      border-spacing: 0;
      width: 100%;
    }
    thead {
      th {
        font-size: 18px;
        line-height: 26px;
        padding-bottom: 6px;
        font-weight: bold;
        text-align: left;
        border-bottom: 1px solid #707070;
      }
    }
    th, td {
      padding: 0 0 20px 0;
      text-align: left;
      font-size: 13px;
    }

    tr:nth-child(2) {
      th, td {
        padding: 28px 0 20px 0;
      }
    }
    // tr:nth-child(3) {
    //   th, td {
    //     padding: 0;
    //   }
    // }

    th {
      width: 172px;
    }

  }

  &_table_right {
    grid-area: right;
    margin-left: 70px;
    margin-right: 24px;
    table {
      border-spacing: 0;
      width: 100%;
    }
    thead {
      th, td {
        padding-bottom: 6px;
        text-align: left;
        vertical-align: bottom;
        font-weight: bold;
        border-bottom: 1px solid #707070;
      }
      th {
        padding-left: 0;
        font-size: 18px;
        line-height: 26px;
        border-bottom: 1px solid #707070;
      }
      td {
        font-size: 11px;
        &.col_head {
          text-align: center !important;
        }
      }
    }

    tr:nth-child(2) {
      th{
        padding: 28px 0 12px 20px;
      }
      td {
        padding: 28px 0 12px 0;
      }
    }

    th {
      padding: 0 0 12px 20px;
      font-size: 14px;
      font-weight: normal;
      text-align: left;
      .non_name{
        color: #707070;
      }
    }

    td {
      padding: 0 0 12px 0;
      font-size: 13px;
      .non_number{
        color: #FF0000;
      }
      &.number {
        text-align: right;
        padding-right: 10px !important;
      }
    }

    &_disable {
      th {
        color: #B9B9B9;
      }
      .modal_allocation_table_right_remain {
        color: #FF0000;
      }
    }

    &_status {
      font-size: 11px;
      color: #FF0000;
    }

    &_invite {
      color: $keyPink;
    }
    &_invite_none {
      color: inherit;
    }
  }
}

.modal_invite {
  margin: 18px 0 18px 0;

  &_description {
    margin: 0 0 6px 0px;
    font-size: 14px;
    color: $fontBlack;
  }
  &_legend {
    border: 1px solid $KeyDarkGreen;
    display: flex;
    justify-content: center;
    align-items: center;
    & > :first-child{
      border-right: 1px solid $KeyDarkGreen;
    }
    &_unit {
        flex: 1;
        font-size: 14px;
        color: $fontBlack;
        line-height: 22px;
        &_part {
          background-color: $KeyDarkGreen;
          font-weight: bold;
          padding: 4px 0 4px 12px;
        }
        &_header {
          background-color: $backGroundGray;
          padding-left: 26px;
          font-size: 12px;
        }
        &_format {
          color: $keyPink;
          border-bottom: 1px solid $KeyDarkGreen;
          background-color: $backGroundGray;
          padding-left: 26px;
          font-size: 12px;
          font-weight: bold;
        }
        &_content {
          margin: 6px auto;
          font-size: 14px;
          padding-left: 26px;
        }
      }
      &_triangle {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 15px 20px 0 20px;
        border-color: #dddddd transparent transparent transparent;
        margin: 8px auto 8px auto;
      }
    }
}

.modal_report {
  margin: 20px 0 60px 0;
  &_table {
    width: 100%;
    border-spacing: 0;

    thead {
      th {
        font-size: 18px;
        line-height: 26px;
        padding-bottom: 6px;
        font-weight: bold;
        text-align: left;
        border-bottom: 1px solid #707070;
      }
      .error_report_outer {
        border-bottom: none;
      }
    }

    th, td {
      vertical-align: top;
      padding: 16px 0 0 0;
    }

    &_range_header, &_course_header {
      font-size: 13px;
      text-align: left;
      box-sizing: border-box;
    }

    &_range_header {
      width: 80px;
      padding: 28px 0 0 16px !important;

    }
    &_range {
      width: 224px;
      &_divider {
        width: 36px;
        text-align: center;
        padding: 24px 0 !important;
      }
    }
    &_course_header {
      width: 100px;
      padding: 28px 0 0 28px !important;
      text-align: left;
    }
    &_course {
      padding: 28px 0 0 0 !important;
    }

    .check_column_group {
      & > * {
        margin-bottom: 4px;
      }
    }

  }

}

.modal_bulk {
  margin: 20px 0 30px 0;
  &_header {
    font-size: 18px;
    line-height: 26px;
    margin: 0;
    padding-bottom: 6px;
    font-weight: bold;
    text-align: left;
    border-bottom: 1px solid #707070;
  }

  &_action_button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.modal_csv_bulk {
  margin: 20px 0 30px 0;
  &_header {
    font-size: 18px;
    line-height: 26px;
    margin: 0;
    padding-bottom: 6px;
    font-weight: bold;
    text-align: left;
    border-bottom: 1px solid #707070;
  }

  &_content {
    margin: 80px 0 120px 180px;
    p {
      font-size: 18px;
      font-weight: bold;
      margin: 0 0 24px 0;
    }
  }

  &_action_button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.msg_container {
  padding: 4px 0;
  &_main {
    padding: 10px 10px 0;
  }
}
.msg_area {
  width: 100% !important;
  margin: 0 0 10px;
  &:last-of-type{
    margin-bottom: 0;
  }
}
.filter_table_container {
  word-break: break-all;
}

.modal_course {
  margin: 20px 0 60px 0;

  &_paragraph {
      font-size: 16px;
  }

  &_table {
    width: 100%;
    border-spacing: 0;

    thead {
      th {
        font-size: 13px;
        line-height: 26px;
        // padding-bottom: 6px;
        font-weight: bold;
        text-align: left;
        padding-left:17px;
        // border-bottom: 1px solid #707070;
        // &::after {
        //   content: '';
        //   display: block;
        //   width: 80%; /* 境界線の長さを80%に設定 */
        //   height: 1px;
        //   background-color: #707070;
        //   margin: 0 auto; /* 中央揃え */
        // }
      }
      .error_course_outer {
        border-bottom: none;
      }
    }

    td {
      vertical-align: top;
      padding: 16px 0 0 0;
      // border-bottom: 1px solid #707070;
    }

    &_title {
      width: 50%;
      // padding-left:10%; /* 小さめの値に調整 */
      text-align: left;

    }

    &_title2 {
      width: 15%;
      text-align: left;
    }
    &_range {
      width: 224px;
      &_divider {
        width: 15%;
        text-align: center;
        padding: 24px 0 !important;
      }
    }

    .check_column_group {
      & > * {
        margin-bottom: 2px;
      }
    }

  }

}
.modal_course_table {
  /* 既存のスタイル... */

  /* ヘッダー部分の行にはスタイルを適用しない */
  thead tr {
    &::after {
      display: none; /* ヘッダーの行には疑似要素を表示しない */
    }
  }

  /* コースリストの各行に適用するスタイル */
  tr {
    position: relative;
    width: 100%;
    margin-bottom: 2px;

    &::after {
      content: '';
      display: block;
      width: 90%; /* 境界線の長さを90%に設定 */
      height: 1px;
      background-color: #707070;
      position: absolute;
      bottom: 0;
      left: 5%; /* 中央に寄せるための調整 */
    }
  }
  td {
    }
    &_range {
      font-weight: bold;
      font-size: 16px;
      width: 50%;
      // padding-left: 20%; /* 小さめの値に調整 */
      word-break: break-word; /* 長い単語も適切に折り返す */
    }
     td._checkbox {
      width: 15%;
      padding-left:7%; /* 小さめの値に調整 */
    }
     td._toggle{
      width: 15%;
      background-color: #fffacd;
      // margin-left: 20%; /* 小さめの値に調整 */

    }
}

</style>


<style scoped>
.base-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5); /* 背景の半透明の黒 */
}

.base-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.base-modal p {
  font-size: 16px;
  color: #333;
  margin: 10px 0;
}

.base-button {
  background-color: #4CAF50; /* 緑色 */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.base-button:hover {
  background-color: #45a049; /* 濃い緑色 */
}
.custom-input {
  height: 30px;
  width: 290px;
  font-size: 16px;
  border: 2px solid #b7b7b7;
  border-radius: 4px;
  padding: 5px;
}

.date-picker-container {
  display: flex;
  align-items: center; /* 垂直方向の中央に配置 */
  justify-content: flex-end;
  padding-top: 10px; /* 上部のパディングを追加 */
  margin-top: 20px; /* 下部のパディングを追加 */
  width: 100%;

}

.date-picker-container span {
  margin-right: 10px; /* ラベルと日付ピッカーの間に余白を追加 */
  flex-shrink: 0; /* ラベルのサイズが縮小しないように設定 */
  width: auto; /* ラベルの自然なサイズを使用 */
}
.date-picker-container base-date-picker {
  width: auto;
  justify-content: flex-end;

  flex-grow: 1; /* 日付ピッカーが残りのスペースを埋めるように設定 */
}
.date-picker-container > * {
  flex-grow: 1; /* 子要素が親要素の幅を埋めるように設定 */
}
.custom-datepicker {
  width: 250px;
}
</style>
