<template>
  <div :class="$style.team_header">
    <div :class="$style.team_header_icon_utility" v-if="indexType">
      <div
        v-if="
          team != null &&
          belongsTo &&
          (team.auth_type === 'admin' ||
            (team.auth_type === 'admin_auth' &&
              team.joint_admin_invite_flg === 1))
        "
      >
        <a @click.prevent="clickAdminIcon()">
          <img :src="adminIconImg" alt="" :width="iconSize" />
        </a>
      </div>
      <div v-if="belongsTo">
        <div
          ref="icon_wrap"
          :class="$style.icon_wrap"
          @click="toggleShowNotification"
        >
          <img :src="notificationImg" alt="" :width="iconSize" />
          <div v-show="isShowNotification" :class="$style.link_contents_team">
            <div :class="$style.menuItemLink">
              <p @click="clickTeamUser('event_flg', 1)">通知をONにする</p>
              <p @click="clickTeamUser('event_flg', 0)">通知をOFFにする</p>
              <p @click="clickTeamUser('event_flg', 2)">自分宛のみ通知</p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="belongsTo">
        <a @click.prevent="clickTeamUser('good_flg')">
          <base-icon
            :class="[
              { [$style.heart]: !isGood },
              { [$style.heart_active]: isGood },
            ]"
            name="heart"
            :size="iconSize"
          />
        </a>
      </div>
      <div v-if="!informationDisplayNone">
        <router-link :to="{ name: 'TeamInfo' }"
          ><img :src="informationImg" :width="iconSize" alt=""
        /></router-link>
      </div>
    </div>
  </div>
</template>
<script>
import BaseIcon from '@/components/base/BaseIcon/BaseIcon'
import NotificationDisabledImg from '@/assets/images/notification-disabled.svg'
import NotificationImg from '@/assets/images/notification.svg'
import NotificationMeImg from '@/assets/images/notification-me.svg'
import InformationImg from '@/assets/images/information.svg'
import AdminIconImg from '@/assets/images/admin-enabled.png'
import AdminIconDisabledImg from '@/assets/images/admin-disabled.png'
import isMobile from '@/utils/mixins/isMobile'
import { mapMutations, mapGetters } from 'vuex'
import { editTeamUser } from '@/helper/common.js'
import { autoLink } from '@/utils/helper.js'

export default {
  name: 'TeamIcon',
  mixins: [isMobile()],
  props: {
    team: {
      type: Object,
      default: () => {},
    },
    user_id: {
      type: Number,
    },
    belongsTo: {
      type: Boolean,
      default: true,
    },
    informationDisplayNone: {
      type: Boolean,
    },
    indexType: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      processing: false,
      admin_notification_flg: false,
      isShowNotification: false,
    }
  },
  components: { BaseIcon },
  computed: {
    ...mapGetters('team', [
      'getDisplayType',
      'getTeamUserEventFlg',
      'getTeamGoodFlg',
    ]),
    iconSize() {
      return this.isMobile ? '14px' : '34px'
    },
    notificationImg() {
      if (this.isEventDone == 0) {
        return NotificationDisabledImg
      } else if (this.isEventDone == 1) {
        return NotificationImg
      } else {
        return NotificationMeImg
      }
    },
    adminIconImg() {
      if (this.admin_notification_flg) {
        return AdminIconImg
      } else {
        return AdminIconDisabledImg
      }
    },
    informationImg() {
      return InformationImg
    },
    isGood() {
      return !!this.getTeamGoodFlg == true
    },
    isEventDone() {
      return this.getTeamUserEventFlg
    },
  },
  methods: {
    ...mapMutations('team', ['setTeamUserEventFlg', 'setTeamGoodFlg']),
    fetchUserAdminSetting() {
      if (this.team != null) {
        this.$axios
          .get(
            '/api/' +
              this.$constants.API_VERSION +
              '/user/notification/admin_setting',
            { params: { team_id: this.team.team_id } }
          )
          .then((res) => {
            if (res.data.status == 'success') {
              this.admin_notification_flg =
                res.data.value.admin_notification_flg
            }
          })
      }
    },
    async clickTeamUser(field_name, event_flg) {
      if (this.processing) return
      this.processing = true
      let params = { user_id: this.user_id, team_id: this.team.team_id }
      switch (field_name) {
        case 'event_flg':
          params = { ...params, event_flg: event_flg } // ベルアイコン
          console.log('event_flg:', event_flg)
          break
        case 'good_flg':
          params = { ...params, good_flg: this.getTeamGoodFlg ^ 1 } // いいね
          break
      }
      // 組織ユーザー登録・更新
      const res = await editTeamUser(params)

      // レスポンスがsuccessじゃないのなら画面に反映しない
      if (!['error', 'failed'].includes(res.status)) {
        switch (field_name) {
          case 'event_flg': // ベルアイコン
            this.setTeamUserEventFlg(event_flg)
            break
          case 'good_flg': // いいね
            this.setTeamGoodFlg(this.getTeamGoodFlg ^ 1)
            break
        }
      }

      this.processing = false
    },
    clickAdminIcon() {
      this.$axios
        .post(
          '/api/' +
            this.$constants.API_VERSION +
            '/user/notification/admin_setting',
          {
            team_id: this.team.team_id,
            admin_notification_flg: !this.admin_notification_flg,
            approve_notification_flg: !this.admin_notification_flg,
            invite_notification_flg: !this.admin_notification_flg,
            member_add_notification_flg: !this.admin_notification_flg,
            request_notification_flg: !this.admin_notification_flg,
          }
        )
        .then((res) => {
          if (res.data.status == 'success') {
            this.admin_notification_flg = !this.admin_notification_flg
          }
        })
    },
    toggleShowNotification() {
      this.isShowNotification = !this.isShowNotification
      if (this.isShowNotification) {
        document.addEventListener('click', this.hideNotificationOnClickOutside)
      } else {
        document.removeEventListener(
          'click',
          this.hideNotificationOnClickOutside
        )
      }
    },
    hideNotificationOnClickOutside(event) {
      // クリックされた要素がアイコン以外の場合は非表示にする
      const iconWrap = this.$refs.icon_wrap
      if (iconWrap && !iconWrap.contains(event.target)) {
        this.isShowNotification = false
      }
    },
    autoLink,
  },
  watch: {
    team: function () {
      this.fetchUserAdminSetting()
    },
  },
  mounted() {
    this.fetchUserAdminSetting()
  },
}
</script>
<style lang="scss" module>
.team_header {
  display: flex;
  justify-content: space-between;

  .team_header_icon_utility {
    display: flex;
    justify-content: end;
    list-style: none;
    margin: 0;

    div {
      align-items: center;
      margin-right: 30px;
      cursor: pointer;
    }
  }

  &_detail {
    height: 80px;
    margin: 0 auto;
    background: #fff;
    border-bottom: 1px solid #dddddd;

    @include mobile() {
      display: block;
      width: auto;
      height: 40px;
    }

    &_wrap {
      display: flex;
      align-items: center;
      max-width: 1280px;
      height: 100%;
      margin: 0 auto;

      @include mobile() {
        width: auto;
      }
    }

    &_avatar {
      margin-top: -45px;
      margin-left: 16px;
    }

    ul {
      list-style: none;
      padding: 0;
    }

    &_meta {
      flex: 1;
      font-size: 13px;
      margin: 0 16px;

      @include mobile() {
        font-size: 10px;
        transform: scale(0.9);
      }
    }

    &_utility {
      display: flex;
      align-items: flex-end;

      li {
        cursor: pointer;
        margin: 0 16px;
      }
    }
  }
}

.heart {
  color: $borderLiteGray;
  &_active {
    color: $keyPink;
  }
}

.link_contents {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  transition: 0.5s;
  gap: 30px;
  margin-right: 30px;
  margin-left: 20px;
  @media screen and (max-width: 1190px) {
    display: none;
  }
}

.link_contents_team {
  position: absolute;
  padding: 0 30px 0 30px;
  background-color: $keyWhite;
  border-radius: 20px;
  box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.1);
  z-index: 100; // ヘッダーの持つオーバーレイメニューをページのものよりも上にする
  top: 60px;
  max-height: 60vh;
  @media screen and (max-width: 1080px) {
    top: 30px;
  }
}

.link_contents_team {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 0px;
  max-width: 100px;
  left: -4vw;
  @media screen and (max-width: 1080px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.icon_wrap {
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-right: 0px !important;
  cursor: pointer;
  position: relative;
  @media screen and (max-width: 1080px) {
    margin-top: 5px;
  }
}

.menuItemLink {
  font-size: 13px;
  color: #707070;
}
</style>
