<template>
  <div :class="$style.menu">

    <div
      :class="$style.menuWrap"
      @click.stop.prevent="isShow = !isShow"
    >
      <div
        :class="$style.menuButton"
        ref="menuButton"
      >
        <slot name="menu-button">
          <div :class="$style.menuDefaultButton">
            <base-icon name="ellipsis-h" :size="iconSize" color="black" v-if="!showGearIcon"/>
            <div v-if="showGearIcon" :class="$style.settings_button">
              <img src="@/assets/images/Icon-awesome-cog.svg" alt=""   :class="$style.settings_button____settings_icon"/>
              <span :class="$style.settings_button__settings_text">設定</span>
            </div>

          </div>
        </slot>
      </div>
      <div
        :class="[
          $style.menuListTriangle,
          isShow ? $style.menuListTriangleShow : ''
        ]"
        :style="menuTrianglePosition"
      />
      <div
        ref="menuList"
        :class="[
          $style.menuList,
          isShow ? $style.menuListShow : ''
        ]"
        :style="menuListPosition"
      >
        <slot name="menu-contents"></slot>
        <ul>
          <slot />
        </ul>
      </div>
    </div>

    <div
      :class="[
        $style.menuOverlay,
        isShow ? $style.menuOverlayShow : ''
      ]"
      @click.stop.prevent="isShow = false"
    />
  </div>
</template>
<script>
import BaseIcon from "@/components/base/BaseIcon/BaseIcon";
import isMobile from "@/utils/mixins/isMobile";
const TRIANGLE_SIZE = 20
const MENU_WIDTH = 375

export default {
  name: 'BaseOverlayMenuList',
  components: {BaseIcon},
  mixins: [ isMobile() ],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    iconSize: {
      type: String,
      default: '18px'
    },
    showGearIcon: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show(show) {
      this.isShow = show
    },
    isShow(isShow) {
      if (isShow === true) {
        this.domRect = this.$refs.menuButton.getBoundingClientRect()
      }
      // console.log("BaseOverlayMenuList: isMobile: ", { this: this, isShow, domRect: this.domRect })
      this.$emit('show', isShow)
    },
  },
  data() {
    return {
      isShow: this.show,
      domRect: null,
    }
  },
  mounted() {
    this.$on('resized', (/*width*/) => {
      if (this.isShow) {
        this.domRect = this.$refs.menuButton.getBoundingClientRect()
        // console.log('BaseOverlayMenuList on resized', {this: this, width, domRect: this.domRect })
      }
    })
  },
  computed: {
    menuTrianglePosition() {
      return this.domRect ? {
        top: `${this.domRect.height + 3 + (TRIANGLE_SIZE - 10) }px`,
        left: `${(this.domRect.width / 2) - 10}px`
      } : {}
    },
    menuListPosition() {
      const left = (() => {
        let result = 0
        if (this.$refs.menuList) {
          if (this.isMobileMin) {
            return `${-this.domRect.x}px`
          } else {
            return this.calcMenuListLeftPosition()
          }
        }
        return result
      })()
      return this.domRect ? {
        top: `${this.domRect.height + TRIANGLE_SIZE}px`,
        left
      } : {}
    }
  },
  methods: {
    calcMenuListLeftPosition() { // 画面幅がメニュー幅より大きい場合のleftを計算する
      const windowWidth = window.innerWidth
      const menuButtonWidth = this.domRect.width
      const menuLeftPosition = this.domRect.x
      const menuRightPosition = windowWidth - menuLeftPosition
      if (menuLeftPosition < MENU_WIDTH / 2){// 矢印を中央にするには左幅が足りない
        // トライアングル部(20px)よりボタンサイズが大きいor小さい場合は差分だけボタン左からの位置をずらす
        const triangleOffset = menuButtonWidth === TRIANGLE_SIZE ? 0 : (menuButtonWidth - TRIANGLE_SIZE) / 2
        let left = triangleOffset
        if (menuLeftPosition + MENU_WIDTH > windowWidth) {// メニュー右側が画面から出る
          left -= menuLeftPosition + (MENU_WIDTH - windowWidth) / 2 // はみ出る分の半分を左に
        }
        return `${left}px`
      }
      if (menuRightPosition < MENU_WIDTH / 2){// 矢印を中央にするには右幅が足りない
        // トライアングル部(20px)よりボタンサイズが大きいor小さい場合は差分だけボタン右からの位置をずらす
        const triangleOffset = menuButtonWidth === TRIANGLE_SIZE ? 0 : (menuButtonWidth - TRIANGLE_SIZE) / 2
        let left = -MENU_WIDTH + menuButtonWidth - triangleOffset
        if (left < -menuLeftPosition) { // メニュー左側が画面から出る
          left = -menuLeftPosition - (MENU_WIDTH - windowWidth) / 2// はみ出る分の半分を右に
        }
        return `${left}px`
      }
      // 矢印を中央にしてメニューを表示
      return `calc((-${MENU_WIDTH}px / 2) + (${menuButtonWidth}px / 2))`
    }
  }
}
</script>
<style lang="scss" module>

.menu {
  display: inline-block;
  width: auto;
}

.menuWrap {
  position: relative;
  // z-index: 10;
}

.menuButton {
  cursor: pointer;
}

.menuDefaultButton {
  width: auto;
  height: 20px;
  cursor: pointer;
}

.menuList {
  display: none;
  position: absolute;
  @media screen and (max-width: 375px){
    width: 100vw;
  }
  width: 375px;
  background: #fff;
  box-shadow: 0 1px 5px rgba(0,0,0,.16);
  &Show {
    z-index: 10;
    display: block;
  }
  // list
  ul {
    list-style: none;
    margin: 30px 0;
    padding: 0;
  }
}

.menuListTriangle {
  display: none;
  position: absolute;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  border-left: 10px solid transparent;
  z-index: 11;
  filter: drop-shadow(0 -3px 3px rgba(0,0,0,.10));
  &Show {
    display: block;
  }
}

.menuOverlay {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  &Show {
    display: block;
    @media screen and (max-width: 375px){
      background: rgba(0,0,0,.3);
    }
  }
}

.settings_button {
    display: flex;
    align-items: center;
    cursor: pointer;

  &__settings_icon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }

  &__settings_text {
    line-height: 1;
    margin-left: 5px;
    font-size: 9.75pt;
    font-weight: bold;
  }
}
</style>
