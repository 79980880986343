<template>
  <div :class="$style.wrapper">
    <!-- ※利用規約同意が更新された場合にterms_of_service_versionのversionを更新 -->
    <!-- Registration.vueにversionは記載 -->
    <h1>くみあいアプリ利用規約</h1>
<section>
<p>
j.union株式会社（以下「当社」といいます）により運営されるくみあいアプリ（以下「本サービス」といいます）の利用を希望されるお客様は、ご利用の前に以下の利用規約（以下「本規約」といいます）をお読みいただき、全ての条項に同意の上でのみご利用ください。<br>
また、利用者が本サービスをご利用いただく場合には、本規約のすべてに同意したものとみなします。<br>
</p>
<p>
なお、本規約は予告なしに変更されることがありますので、最新の内容をご確認ください。<br>
皆様が容易に本規約の内容を確認できるよう、当社は本規約の内容をサービス内に掲示します。当社は今後も継続してサービスを提供するために最善を尽くして参ります。<br>
</p>
</section>

<section>
<h2>本規約で使用する用語について</h2>
<p>
「サービス」とは、端末の種類に関係なく利用できる「くみあいアプリ」(ku-mi-ai.com)を意味します。<br>
「皆様」、「利用者」とは、サービスにアクセスし、当社が提供するサービスを利用するお客様を指します。<br>
「会員」とは、皆様、利用者の中で当社が会員と認め、特別な権限を付与したお客様を指し、特定範囲の利用者をとりまとめ、チームを適切に運営する役割を担います。<br>
「コンテンツ」とは、皆様がサービスをご利用の際、サービス上に投稿された記号、文字、音声、画像及び動画から構成される写真、映像、ファイル並びにリンクを意味します。<br>
</p>
</section>

<section>
<h2>サービスの利用の制限事項</h2>
<p>
本サービスの利用希望者は、当社所定の方法で当サービスの会員資格を得て本サービスを利用することができます。<br>
当社は、利用希望者が、以下の各号のいずれかの事由に該当する場合は、ご利用をお断りする場合があります。<br>
なお、当社が利用を認めない場合、その理由を開示する義務を負うものではありません。<br>
</p>
<p>
<ol>
<li>提供事項につき虚偽の内容や記載漏れがあった場合</li>
<li>当サービスの提供目的を果たさないと判断した場合</li>
<li>反社会的勢力等、又は資金提供その他を通じて反社会的勢力等の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力等との何らかの交流若しくは関与を行っていると当社が判断した場合</li>
<li>過去に当社との契約に違反した事実が認められる場合</li>
<li>本規約を遵守しない恐れがあると当社が判断した場合</li>
<li>その他、当社が登録を適当でないと判断した場合、</li>
</ol>
</p>
<p>
当社は、利用の可否を判断し、利用を可と判断した場合に、その旨を申込者に通知します。
当サービスの利用者は、本規約に従い、本サービスを利用するものとします。
</p>
</section>

<section>
<h2>個人情報の保護について</h2>
<p>
当社では関連法令に基づき、皆様の個人情報を保護します。当社による個人情報の保護及び使用に関しては、関連法令及び個人情報保護方針が適用されます。<br>
ただし、不適切なコンテンツの配布や、生命に脅威が及ぶと判断されるコンテンツが確認された場合、当社は、個人情報処理方針の記載にかかわらず、個人情報を関連する法執行機関に対して提供します。<br>
また、当社は関係法令又は本規約の遵守状況を確認する必要がある場合に投稿の内容を確認することができ、関係法令に従って投稿の内容を関連する法執行機関に提供することがあります。<br>
会員がサービスに一定期間ログイン又はアクセスした履歴がない場合、当社は、電子メールやサービス内の通知、又はその他適切な電子的な手段を通じて事前に案内した後、会員の個人情報を破棄又は分離保管することがあります。<br>
また、長期間サービスにアクセスしなかった場合、関連サービスの利用契約を解除することができるものとします。<br>
</p>
<p>
登録された個人情報は変更することができます。
皆様は、プロフィール設定画面でいつでも本人の個人情報を閲覧し、修正することができます。<br>
会員登録申込みの際に記載した事項に変更が生じた場合、オンラインでプロフィールを修正することにより、当社に変更事項を通知してください。<br>
特に、携帯電話番号やメールアドレスを破棄・契約解除するなどの場合には情報をアップデートしてください。<br>
アップデートしない場合、皆様の変更前の携帯電話番号を取得した第三者に対して、 皆様のコンテンツが送信される可能性があります。<br>
変更事項を当社に通知しないことによって発生した不利益について、当社はいかなる責任も負いません。<br>
</p>
</section>

<section>
<h2>利用規約の変更</h2>
<p>
当社は、利用者及び会員との個別の合意なしに、本サービス上での告知又は当社が適当と判断する方法で利用者及び会員に通知することにより本規約を変更することができるものとします。
</p>
<p>
前項による変更後の本規約は、当社が別途定める場合を除き、当社が運営するＷｅｂサイト等において表示された時点で、効力を生じるものとします。
</p>
<p>
本規約の変更についての履歴は、本サービス上で参照できるものとします。<br>
当社は、利用者及び会員との個別の合意なしに、本サービスの内容を変更することができるものとし、変更に伴い会員に不利益、損害が発生したとしても、故意又は重過失による場合を除き、当社はその責任を負わないものとします。<br>
</p>
</section>

<section>
<h2>提供事項について</h2>
<p>
本サービスにおいて当社が直接提供するサービス利用に必要な機器類や通信費用、その他参加費・会費等は利用者の負担となります。
</p>
</section>

<section>
<h2>動作環境</h2>
<p>
本サービスは、後記の最新のブラウザ環境でのご利用を前提にサイト制作を行っております。それ以外のブラウザやバージョンでは動作に一部不具合やサービスをご利用いただけない場合がございますが、あらかじめご了承ください。
</p>
</section>

<section>
<h2>利用者等の地位等の譲渡等禁止</h2>
<p>
利用者は、利用者、もしくは会員としての地位又は本規約による生じた権利を第三者に譲渡、承継又は担保の目的に供してはならないものとします。
</p>
<p>
会員は、利用者に対しても、その地位又は本規約による生じた権利を第三者に譲渡、承継又は担保の目的に供させないものとします。利用者の行為は、会員の行為とみなされます。
</p>
</section>

<section>
<h2>ID等の管理</h2>
<p>
当社が利用者に対して発行したユーザIDとパスワードは、当社指定手続きによって当社が把握する利用対象者以外に譲渡または貸与により第三者に利用させることはできないものとし、会員は利用者に対して、かかる行為を行わせないよう協力するものとします。「自分のユーザIDを使用して、非会員に利用させる」などユーザID／パスワードを貸し借りは、貸した側、借りた側の両者が不正利用とみなされます。<br>
会員は、利用者に対して、ユーザIDとパスワードの管理を厳重に行わせるものとし、利用者以外の第三者に知られないようにしてください。<br>
</p>
<p>
<ul>
<li>利用者のユーザIDとパスワードを利用して利用者以外の第三者により行われた行為は、会員の行為とみなして会員に責任を負担して頂きます。</li>
<li>万一、ユーザIDとパスワードが第三者に盗取され、またはその他の方法で漏洩したことが発覚した場合、ただちに当社にご連絡ください。ただし、当該連絡は会員の損害賠償責任を免除するものではございません。</li>
<li>当社はユーザIDとパスワードの漏洩、不正使用などから生じた損害については、当社への連絡の有無を問わず保証をいたしません。</li>
<li>当社は漏洩または不正使用の対象となったユーザIDとパスワードの登録を解除したり、必要に応じて他の会員に対して公表する権利を保有し、これにより利用者又は会員に損害が生じたとしても、当社は一切損害賠償責任を負いません。利用者に損害が生じた場合には、いかなる理由によっても、会員が責任を負うものとします。</li>
<li>ユーザID、パスワードを当社との同意なく共同利用する行為は他の利用者に対する迷惑行為となるため、行ってはならず、会員は共同利用をしないよう利用者に指導を行い、利用者による共同利用があった場合、会員の規約違反とみなします。</li>
<li>ID等の利用について問題が認められる場合、会員を介して解決をはかるものとします。</li>
</ul>
</p>
</section>

<section>
<h2>個人情報管理</h2>
<p>
利用者が本ウェブサイトを利用されたために当社が取得した利用者に関連する個人情報は、本ウェブサイトのプライバシーポリシーに従って取り扱われます。
</p>
</section>

<section>
<h2>著作権</h2>
<p>
本サービスにて表示される商標（トレードマーク、サービスマーク）及び著作物は、当社もしくは当社にその使用を認めた権利者にその権利が帰属し、当社の事前の許諾なく使用することはできません。これらを権利者に無断で使用することを禁止します。悪質な不正行為を発見した場合は損害賠償請求など厳しい措置をとらせていただきます。
</p>
<p>
本サービスにて表示される文章、動画、写真、表、図面、グラフなど、本ウェブサイトを通じて提供されるあらゆる形のコンテンツの一部または全部は、当社の事前の許可なく複製することができません。<br>
コンテンツの一部または全部を著作権者の許諾を得ずに複製のうえ、社内のLANなどで配信したり、印刷して配布するなどの行為はできません。<br>
出典表記を行っての掲載であったとしても、引用にはあたりません。<br>
</p>
</section>

<section>
<h2>禁止事項</h2>
<p>
会員は、利用者及び会員が以下の各号のいずれかに該当しないことを保証するものとし、該当した場合、当社は事前に通知することなく、直ちに当該利用者の資格を取り消すことができ、又は当該会員の利用者の利用を停止できるものとします。
</p>
<p>
<ol>
<li>法令に違反するもの。</li>
<li>他人の権利を侵害するもの。</li>
<li>他人に経済的・精神的損害を与えるもの、脅迫的なもの。</li>
<li>他人の名誉を毀損するもの、プライバシーを侵害するもの。</li>
<li>いやがらせ、他人を誹謗・中傷するもの、事実に反するもの。</li>
<li>猥褻・猥雑なもの、未成年者に悪影響を与えるもの。</li>
<li>嫌悪感を与えるもの、民族的・人種的差別につながるもの。</li>
<li>その他倫理的観点等から問題のあるもの。その他倫理的観点等から問題のあるもの。</li>
<li>第三者の知的財産権（特許権、実用新案権、意匠権、商標権、著作権など）を侵害する第三者の知的財産権（特許権、実用新案権、意匠権、商標権、著作権など）を侵害するようなもの。</li>
<li>他利用者の意に反し、勧誘・営利等を目的とするもの。</li>
<li>コンピューターのソフトウェア、ハードウェア、通信機器の機能を妨害、破壊、制限するようにデザインされたプログラム等</li>
<li>以上の内容を含むおそれがあるもの。以上の内容を含むおそれがあるもの。</li>
<li>その他当社が不適当であると判断するもの。その他当社が不適当であると判断するもの。</li>
</ol>
</p>
</section>

<section>
<h2>損害賠償</h2>
<p>
利用者又は会員が、その者の故意又は過失により本規約に違反し、当社に損害が生じた場合、会員は当社に発生した一切の損害（逸失利益、間接損害及び弁護士費用を含む）を賠償するものとします。
</p>
<p>
当社が利用者及び会員に対して負う損害賠償の累計総額は、債務不履行、法律上の瑕疵担保責任、不当利得、不法行為その他請求原因の如何にかかわらず、当社が利用者より受領した会費を限度とします。
</p>
</section>

<section>
<h2>免責事項</h2>
<p>
当社は、本サービスにより提供されるサービスおよび情報に関していかなる保証をするものではなく、その内容の誤り、正確性、信頼性、完全性、可用性に問題があった場合、または本サービスのご利用に際して生じたトラブル又は損害発生の責任は負いません。
</p>
<p>
本サービスのご利用に起因するソフトウェア、ハードウェア上の事故その他の損害についても、一切責任を負いかねます。
</p>
<p>
通信回線の異常、通信機器、ソフトウェア、電力供給等のトラブル、ウェブサイトの保守等により本サービスが中断される場合がありますが、これに基づき損害が発生した場合も、当社はそれらの損害発生につき故意または重大な過失ある場合を除き何らの責任を負担しません。
</p>
<p>
本ウェブサイトに掲載されている情報、ファイル名等は、予告なく変更されることがあり、これにより会員又は会員が組織するチームの利用者に損害が生じても、当社は一切の責任を負いません。
</p>
<p>
当社は本ウェブサイトより送信されるメール等にウィルスなどの有害なコードが含まれないことを保証するものではありません。
</p>
<p>
本ウェブサイトに関する取引、紛争等ならびに本規約の解釈および適用にあたっては、日本国法に準拠します。また、本ウェブサイトに関わる全ての紛争については、他に別段の定めのない限り、訴額に応じて東京地方裁判所又は東京簡易裁判所を第一審の専属管轄裁判所とします。
</p>
</section>

<section>
<p style="text-align: right; display:block">2022年7月1日 j.union株式会社</p>
</section>
  </div>
</template>
<script>
export default {
}
</script>
<style lang="scss" module>
.wrapper {
  color: $keyBlack;
  background-color: $openBg;
  text-align: left;
  margin: 0 auto;
  box-sizing: border-box;
  width: 1080px;
  padding: 68px 210px;

  @include mobile() {
    width: 375px;
    padding: 42px 32px;
  }

  * {
    margin: 0;
  }

  h1 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 63px;
    counter-reset: section-count;

    @include mobile() {
      font-size: 24px;
      margin-bottom: 55px;
    }
  }
  section {
    counter-increment: section-count;
    counter-reset: p-count;

    &:not(:last-child) {
      margin-bottom: 36px;

      @include mobile() {
        margin-bottom: 30px;
      }
    }

    h2 {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 24px;

      @include mobile() {
        font-size: 16px;
        margin-bottom: 20px;
      }

      &::before {
        //content: "第" counter(section-count) "条";
        margin-right: 4px;
      }
    }

    p {
      display: flex;
      font-size: 13px;
      line-height: 26px;
      counter-increment: p-count;
      @include mobile() {
        font-size: 11px;
        line-height: 16px;
      }

      &::before {
        //content: counter(p-count) ".";
        display: block;
        min-width: 23px;
      }

      &:not(:last-child) {
        margin-bottom: 24px;

        @include mobile() {
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>
