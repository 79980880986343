<template>
  <div :class="$style.union_corner">
    <app-prev-button @click="$router.push(prevRoute)" />

    <div :class="$style.union_header">
      <img :class="$style.union_image" :src="corner.corner_path" alt="image">
      <div :class="$style.union_description">
        <div :class="$style.union_description_content" v-html="autoLink(corner.corner_contents)"></div>
      </div>
    </div>

    <div :class="$style.list_accordion_wrap">
      <base-accordion title="このコーナーの講座" type="borderless" expanded >
        <div :class="$style.post_item_list">
          <div v-for="(model, index) in getLectures" :class="$style.post_item_wrap" :key="index">
            <app-carousel-post-item
              :borderColor="convertColor(model.bar_color)"
              :head="model.lecture_title"
              :foot="createPageTitle(model)"
              :image="model.lecture_path"
              :selected="!!model.good_flg"
              :tag="model.status"
              :customTagText="model.status"
              :customTagColor="convertColor(model.background_color)"
              :customTagTextColor="convertColor(model.text_color)"
              v-on:select="clickGood(index, $event)"
              v-on:click="$router.push('/union/course/' + model.lecture_id)"
            />
          </div>
        </div>
      </base-accordion>
    </div>
    <app-scroll-button/>
  </div>
</template>

<script>
import BaseAccordion from '@/components/base/BaseAccordion/BaseAccordion'
import AppCarouselPostItem from '@/components/app/AppCarousel/PostItem/AppCarouselPostItem'
import AppPrevButton from '@/components/app/AppPrevButton/AppPrevButton'
import AppScrollButton from '@/components/app/AppScrollButton/AppScrollButton'
import { fetchCornerList, editLectureHistoryChatFavorite } from '@/helper/common.js'
import { convertColor, createPageTitle, fromBoolToNumber, commitGoodFlg } from '@/helper/union/lecture.js'
import { autoLink } from '@/utils/helper.js'

export default {
  name: 'UnionCorner',
  components: { BaseAccordion, AppCarouselPostItem, AppPrevButton, AppScrollButton },
  props: {
    corner_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      /** POST処理実行中フラグ */
      processing: false,
      /** お気に入りフラグ（コーナー） */
      favorite_flg: 0,
      /** コーナー */
      corner: {},
      /** 講座一覧 */
      lectureData: { lecture_list: []},
      prevRoute: '/union/home'
    }
  },
  created: async function() {
    this.$router.app.$emit('show-spinner', true)
    await Promise.all([
      this.getCorner(),
      this.setLectureData(),
    ])
    this.setFavoriteFlg()
    this.pageUpdated()
    this.$router.app.$emit('show-spinner', false)
  },
  computed: {
    getLectures: function () {
      return this.lectureData.lecture_list
    },
    // /** 講座一覧モック出力処理 */
    // createLectureListMock: function () {
    //   const result = this.$store.getters['union/createLectureListMock']
    //   return result
    // },
  },
  methods: {
    convertColor,
    createPageTitle,
    autoLink,
    /** お気に入りフラグ取得/設定 */
    setFavoriteFlg: function () {
      this.favorite_flg = this.corner?.favorite_flg
    },
    /** 講座一覧取得/設定 */
    setLectureData: async function () {
      const lectures = (await this.fetchLectureList()).lecture_list
      this.lectureData.lecture_list.splice(0, lectures.length, ...lectures)
    },
    /** コーナー取得処理 */
    getCorner: async function () {
      const result = await fetchCornerList({id: this.corner_id, sort: 'created_at', direction: 'desc', limit: 500})
      if (result.status == 'success') {
        const corners = result.value
        // this.$loginfo(corners)
        if (corners != null && corners.length > 0) {
          this.corner = corners[0]
          // this.$loginfo(this.corner)
        }
      }
    },
    /**
     * 講座一覧取得API実行処理
     */
    fetchLectureList: async function () {
      let lectureData
      await this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/lecture/list', {
          params: {
            "corner_id": this.corner_id,
            "sort": "sort",
            "direction": "asc",
            "unread_flg": 0,
            "limit": 500, // 仮
            }
          })
        .then((res) => {
          if (res.data.status == 'success') {
            lectureData = res.data.value
            if (lectureData.lecture_list.length == 0) {
              this.$router.push('/error/404')
            }
          // } else {
          //   lectureData = null
          }
        })
        .catch((err) => {
          this.$logerror(err)
          throw new Error(err)
        })

      // if (lectureData == null) {
      //   // コーナーの閲覧権限がない場合?
      //   // this.$router.push('*')
      //   // 開発用
      //   lectureData = this.createLectureListMock
      // }

      return lectureData
    },
    clickGood: async function (index, flg) {
      if (this.processing) return

      const good_flg = fromBoolToNumber(flg)
      const lecture = this.getLectures[index]
      this.processing = true
      const result = await editLectureHistoryChatFavorite({lecture_id: lecture.lecture_id, good_flg: good_flg})
      if (result.status == 'success') {
        commitGoodFlg(this.lectureData.lecture_list, index, good_flg)
      }
      this.processing = false
    },
    pageUpdated() {
      // this.$loginfo('page-updated')
      this.$router.app.$emit('page-updated', {
        pageTitle: `${this.corner.course_name}：${this.corner.corner_name}`,
        back: this.prevRoute,
        showSetting: true,
        bgColor: 'yellow',
        cornerId: this.corner_id,
        favoriteFlg: this.favorite_flg,
      })
    },
  },
}
</script>

<style lang="scss" module>

.union_corner {
  display: flex;
  width: 960px;
  flex-direction: column;
  align-items: center;
  padding: 12px 0 50px 0;
  margin: 0 auto;
  overflow: hidden;

  @include mobile(){
    min-width: 300px;
    margin: 0;
    padding: 24px 16px 24px 16px;
  }
}

.union_header {
  display: flex;
  width: 100%;
  min-height: 100px;
  max-height: 200px;
  border: 1px solid #707070;
  box-sizing: border-box;
  background-color: $keyWhite;
  margin-bottom: 32px;

  @include mobile(){
    margin-bottom: 24px;
  }

  .union_image {
    width: 40%;
    object-fit: contain;
  }

  .union_description {
    width:60%;
    height:100%;
    padding: 5px 5px 5px 5px;
    box-sizing: border-box;

    &_title {
      font-size: 20px;
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @include mobile(){
        font-size: 14px;
        margin-bottom: 4px;
      }
    }

    &_content {
      height: 100%;
      font-size: 12px;
      color: $fontBlack;
      margin: 0;
      white-space: pre-wrap;
      word-break: break-all;
      overflow: auto;
      @include mobile(){
        font-size: 10px;
        margin-bottom: 4px;
      }
    }
  }

}

.list_accordion_wrap {
  width: 100%;
  margin-bottom: 10px;

  .post_item_list {
    padding: 0 1px;
    margin: 16px 0 0 0;
    display: flex;
    flex-wrap: wrap;

    @include mobile(){
      margin: 10px 0 0 0;
      padding: 0 16px 0 16px;
    }

    // 右端下端のマージンを打ち消す。ピッタリだと影が消えるので少し小さく取る
    margin-right: -32px;
    margin-bottom: -10px;
    @include mobile(){
      margin-right: -40px;
      margin-bottom: -20px;
    }

    .post_item_wrap {
      margin-right: 20px;
      margin-bottom: 14px;

      @include mobile(){
        margin-right: 24px;
        margin-bottom: 24px;
      }
    }

  }
}
</style>
