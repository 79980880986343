<template>
  <div :class="$style.wrapper">
    <app-prev-button v-if="auth_info" @click="$router.go(-1)" :no-mobile="true"/>
    <h1>個人情報保護方針</h1>

    <section>
      <p>当社はお客さまのプライバシーを尊重することをお約束するとともに、以下の「個人情報保護方針」を定め、役員及び従業員に周知徹底し、個人情報の適切な保護・管理に努め、社会とお客さまの信頼に応えてまいります。</p>
    </section>

    <section>
      <h2>個人情報保護の理念</h2>
      <p>
        ｊ.ｕｎｉｏｎ株式会社（以下、当社）は、組織・経営コンサルティング事業、調査分析リサーチ事業、研修・セミナープログラムの企画運営事業、書籍販売業、メンタルヘルス対策、人材育成支援事業、情報宣伝印刷物の企画制作支援事業、会員制ホームページ運営サービス事業、システムソリューション事業、ソフトウェア販売業、人材派遣業、各種イベント・研究会・式典の企画運営業を核とした事業活動を行っています。<br>
        事業活動を通じてお客さまから取得する個人情報及び当社従業者の個人情報（以下、「個人情報」という。）は、当社にとって重要な情報であり、その個人情報を確実に保護することは、当社の重要な社会的責務と認識しております。<br>
        したがって、当社は、事業活動を通じて取得する個人情報を、以下の方針に従って取り扱い、個人情報保護に関して、お客さま及び当社従業者への「安心」の提供及び社会的責務を果たしていきます。<br>
      </p>
    </section>

    <section>
      <h2>方針</h2>

      <h3>個人情報の取得、利用及び提供に関して</h3>
      <p>
        •	適法、かつ、公正な手段によって個人情報を取得いたします。<br>
        •	利用目的の達成に必要な範囲内で、個人情報を利用いたします。<br>
        •	個人情報を第三者に提供する場合には、事前に本人の同意を取ります。<br>
        •	取得した個人情報の目的外利用はいたしません。そのための措置をPMSに定めます。<br>
        •	目的外利用の必要が生じた場合は新たな利用目的の再同意を得た上で利用いたします。<br>
      </p>

      <h3>法令、国が定める指針その他の規範（以下、「法令等」という。）に関して</h3>
      <p>
        個人情報を取り扱う事業に関連する法令等を常に把握することに努め、当社事業に従事する従業者（以下、「従業者」という）に周知し、遵守いたします。
      </p>

      <h3>個人情報の安全管理に関して</h3>
      <p>
        個人情報への不正アクセス、個人情報の漏えい、滅失、又はき損などの様々なリスクを防止すべく、個人情報の安全管理のための迅速な是正措置を講じる体制を構築し維持いたします。<br>
        •	点検を実施し、発見された違反や事故に対して、速やかにこれを是正するとともに、弱点に対する予防処置を実施いたします。<br>
        •	安全に関する教育を、従業者に徹底いたします。<br>
      </p>

      <h3>苦情・相談に関して</h3>
      <p>
        個人情報の取扱いに関する苦情及び相談については、個人情報問合せ窓口を設け、迅速な対応が可能な体制を構築し、誠意をもって対応いたします。
      </p>

      <h3>継続的改善に関して</h3>
      <p>
        当社の個人情報保護マネジメントシステムは、個人情報保護のため、内部規程遵守状況を監視及び監査し、違反、事件、事故及び弱点の発見に努め、経営者による見直しを実施いたします。<br>
        これを管理策及び内部規程に反映し、個人情報保護マネジメントシステムの継続的改善に努めます。<br>
        制定日 ２００５年３月１日<br>
        改定日 ２０２１年６月１日<br>
        ｊ.ｕｎｉｏｎ 株式会社<br>
        代表取締役社長 服部 恵祐<br>
        ※「個人情報保護方針」に関してご不明な点および苦情・相談などがございましたら下記「個人情報問合せ窓口」までご連絡下さい。<br>
      </p>
    </section>

    <section>
      <h2>プライバシーマークについて</h2>
      <img src="@/assets/images/privacy_mark.gif" width="150px" height="150px">
    </section>

    <section>
      <h2>個人情報の取扱いについて</h2>
      <p>
        当社は、JISQ15001:2006 要求事項 3.4.2.5および3.4.4.3に従い、以下の内容を公表します。
      </p>

      <h3>ご本人から直接書面取得意外で取得する場合の利用目的</h3>
      <table border="1" width="100%">
        <tr>
          <th>個人情報の種別</th>
          <th>利用目的</th>
          <th>開示区分</th>
        </tr>
        <tr>
          <td>調査関連個人情報</td>
          <td>データベース作成のため</td>
          <td rowspan="2">非開示</td>
        </tr>
        <tr>
          <td>情宣関連個人情報</td>
          <td>発行物作成のため</td>
        </tr>
      </table>
      <br>

      <h3>開示対象個人情報の利用目的</h3>
      <table border="1" width="100%">
        <tr>
          <th>個人情報の種別</th><th>利用目的</th>
        </tr>
        <tr>
          <td rowspan="2">資料請求やお問い合わせを頂いた方の情報</td>
          <td>お問い合わせへの対応</td>
        </tr>
        <tr>
          <td>サービスに関するご案内</td>
        </tr>
        <tr>
          <td>お取引先情報</td>
          <td>お取引先との連絡、契約の履行、履行請求等</td>
        </tr>
        <tr>
          <td>採用候補者・応募者の方に関する情報</td>
          <td>採用選考、連絡</td>
        </tr>
        <tr>
          <td>社員情報</td>
          <td>社員情報	人事、総務などの雇用管理</td>
        </tr>
        <tr>
          <td>公開セミナーの参加者情報</td>
          <td>公開セミナーの運営等のため</td>
        </tr>
      </table>
    </section>

    <section>
      <h2>開示等の請求手続き</h2>
      <p>
        当社がご本人様又はその代理人様から、当社が保有する開示対象個人情報に関して(1)開示のご請求、(2)利用目的の通知のご請求、(3)訂正のご請求、(4)追加のご請求、(5)消去のご請求、(6)利用停止又は第三者提供の停止のご請求等（以下(1)から(6)を総称して「開示等のご請求」といいます。）にご対応させていただく場合の手続きは、下記のとおりです。
      </p>
      <h3>開示等の請求の申出先</h3>
      <p>
        開示等のご請求については、<a href="https://www.j-union.com/images/3-4-4-2pv.pdf">個人情報開示等請求書</a>に必要書類を添付の上、郵送によりお願いいたします。
請求書を当社へ郵送する際には、配達記録郵便や簡易書留郵便など、配達の記録が確認できる方法にてお願いいたします。
なお、封筒に朱書きで「個人情報請求書在中」とお書き添えいただければ幸いです。
      </p>

      <h3>開示等の請求における提出書面</h3>
      <p>
        開示等のご請求を行う場合は、<a href="https://www.j-union.com/images/3-4-4-2pv.pdf">個人情報開示等請求書</a>に所定の事項を全てご記入の上、ご郵送ください。
      </p>

      <h3>ご本人様確認</h3>
      <p>
        当社は開示等請求者のご本人様確認のため、お電話による照合を行います。ただし電話による照合が出来ない場合、運転免許証、住民票、健康保険証等のコピーを提示してもらう場合がございます。
      </p>

      <h3>代理人様による開示等のご請求</h3>
      <p>
        開示等のご請求をすることについて代理人様に委任する場合は、<a href="https://www.j-union.com/images/3-4-4-2pv.pdf">個人情報開示等請求書</a>に加えて、下記の書類をご同封ください。
①代理人様本人であることを確認するための書類（コピー）
運転免許証、住民票の写し、健康保険証の被保険者証のいずれか１点
※コピーは本籍地を塗りつぶしたものをご用意下さい。
②委任状（ご本人様により委任状に捺印し、その印鑑の印鑑登録証明書を添付してください。代理人様が親権者などの法定代理人のときは、委任状に代えて、ご本人様との関係がわかる書類をご提出いただくことも可能です。）
      </p>

      <h3>開示、利用目的の通知のご請求に関する手数料</h3>
      <p>
        個人情報の開示及び利用目的の通知をご請求する場合、１回の請求ごとに、500円（税込）の手数料をいただきます。
500円分の郵便定額小為替を提出書類にご同封ください。
郵便定額小為替のご購入のための料金及び当社への郵送料はお客さまにてご負担ください。
なお、手数料が不足していた場合、及び手数料が同封されていなかった場合は、開示、利用目的の通知は致しかねますので、ご注意ください。
      </p>

      <h3>開示等のご請求に対する回答方法</h3>
      <p>
        請求者の請求書記載住所宛に書面によってご回答いたします。
      </p>

      <h3>認定個人情報保護団体の名称及び、苦情の解決の申出先</h3>
      <table border="1" width="100%">
        <tr>
          <th width="25%">認定個人情報保護団体の名称</th>
          <td>
            認定個人情報保護団体の名称	◆一般財団法人日本情報経済社会推進協会（JIPDEC）<br>
            <br>
            ●一般社団法人日本情報システム・ユーザー協会（JUAS）<br>
          </td>
        </tr>
        <tr>
          <th>苦情の解決の申出先</th>
          <td>
            ◆一般財団法人日本情報経済社会推進協会（JIPDEC）<br>
            個人情報保護苦情相談室<br>
            〒105-0011 東京都港区六本木1丁目9番9号 六本木ファーストビル内<br>
            TEL：03-5860-7565、0120-700-779<br>
            <br>
            ●一般社団法人日本情報システム・ユーザー協会（JUAS）<br>
            認定個人情報保護団体事務局 苦情相談室<br>
            〒103-0012 東京都中央区日本橋堀留町2-4-3 日本橋堀留町二丁目ビル8階<br>
            TEL：03-3249-4104<br>
            <br>
            <b>※上記は当社の商品・サービスに関する問合せ先ではございませんのでご注意ください。</b><br>
            <br>
            ※当社の個人情報の管理が適切でないと判断した時は、まず当社に苦情を申し立ていただきたいと存じますが、当社の対応が満足できない場合、お客さまは上記の「認定個人情報保護団体」へ苦情を申し立てることが出来ます。<br>
          </td>
        </tr>
        <tr>
          <th>電話番号</th>
          <td>
            「個人情報の取扱いについて」に関してご不明な点および苦情・相談などがございましたら下記窓口までご連絡下さい。<br>
            <br>
            <b>ｊ.ｕｎｉｏｎ 株式会社</b><br>
            ＜個人情報問合せ窓口＞<br>
            経営企画本部 佐々木 務<br>
            TEL：03-5339-8200（平日10:00～17:00）<br>
            FAX：03-5339-8201（24時間受付）<br>
            <br>
            ＜個人情報に関する責任者＞<br>
            個人情報保護管理者 渡邉 秀一<br>
          </td>
        </tr>
      </table>
    </section>
  </div>
</template>
<script>
import AppPrevButton from '@/components/app/AppPrevButton/AppPrevButton'
export default {
  components: { AppPrevButton },
  data() {
    return {
      auth_info: this.$cookies.get('auth_info'),
    }
  },
}
</script>
<style lang="scss" module>
.wrapper {
  color: $keyBlack;
  background-color: $openBg;
  text-align: left;
  margin: 0 auto;
  box-sizing: border-box;
  width: 1080px;
  padding: 68px 210px;

  @include mobile() {
    width: 375px;
    padding: 42px 32px;
  }

  * {
    margin: 0;
  }

  h1 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 63px;

    @include mobile() {
      font-size: 24px;
      margin-bottom: 55px;
    }
  }
  section {
    counter-reset: h3-counter;

    &:not(:last-child) {
      margin-bottom: 24px;

      @include mobile() {
        margin-bottom: 20px;
      }
    }

    h2 {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 24px;

      @include mobile() {
        font-size: 16px;
        margin-bottom: 20px;
      }
    }

    h3 {
      font-size: 13px;
      font-weight: bold;
      margin-bottom: 8px;
      counter-increment: h3-counter;
      &::before {
        content: counter(h3-counter) ". "
      }

      @include mobile() {
        font-size: 11px;
        margin-bottom: 6px;
      }
    }

    p {
      display: block;
      font-size: 13px;
      line-height: 26px;

      @include mobile() {
        font-size: 11px;
        line-height: 16px;
      }

      &:not(:last-child) {
        margin-bottom: 24px;

        @include mobile() {
          margin-bottom: 20px;
        }
      }
    }
    table {
      font-size: 13px;
      @include mobile() {
        font-size: 11px;
      }
    }
  }
}
</style>
