var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{class:_vm.$style.table,attrs:{"height":"1"}},[_c('thead',[_c('tr',[(_vm.selectable)?_c('th',{class:_vm.$style.select,attrs:{"width":"1"}},[_c('base-check-box',{model:{value:(_vm.boundSelectedAll),callback:function ($$v) {_vm.boundSelectedAll=$$v},expression:"boundSelectedAll"}})],1):_vm._e(),_vm._l((_vm.colDefs),function(colDef,col){
var _obj;
return _c('th',{key:colDef.key || ("__unnamed_col_" + col),class:( _obj = {}, _obj[_vm.$style.sortable] = _vm.isSortableCol(colDef), _obj[_vm.$style.sorted] = _vm.isSortedCol(colDef), _obj ),on:{"click":function () { return _vm.handleClickTh(col); }}},[_vm._t(colDef.label_slot ||("__unnamed_label_slot_" + col),function(){return [_c('span',[_vm._v(_vm._s(colDef.label))])]},null,{ col: col, key: colDef.key, colDef: colDef }),(colDef.sortable)?_c('base-icon',{class:_vm.$style.icon,attrs:{"type":"fas","name":colDef.key == _vm.sortedKey ? _vm.sortedAsc ? 'sort-up' : 'sort-down' : 'sort',"size":"11px"}}):_vm._e()],2)})],2)]),_c('tbody',{class:( _obj = {}, _obj[_vm.$style[("bg_" + (this.bgColor))]] = !!this.bgColor, _obj )},[(_vm.rowDataEmpty)?_c('tr',[_c('td',{attrs:{"colspan":_vm.colDefsNum + 1}},[_vm._v(_vm._s(_vm.message))])]):_vm._e(),_vm._l((_vm.pageSortedRowData),function(rowDat,row){
    var _obj;
return _c('tr',{key:_vm.getRowKeyValue(rowDat),class:( _obj = {}, _obj[_vm.$style.selected] = _vm.isSelectedRow(rowDat), _obj )},[(_vm.selectable)?_c('td',{class:_vm.$style.select},[_c('base-check-box',{attrs:{"value":_vm.getRowKeyValue(rowDat)},model:{value:(_vm.boundSelected),callback:function ($$v) {_vm.boundSelected=$$v},expression:"boundSelected"}})],1):_vm._e(),_vm._l((_vm.colDefs),function(colDef,col){
    var _obj, _obj$1;
return _c('td',{key:colDef.key || ("__unnamed_col_" + col),class:( _obj = {}, _obj[_vm.$style.sorted] = _vm.isSortedCol(colDef), _obj[_vm.$style.align_left] = _vm.getTextAlignCol(colDef) == 'left', _obj[_vm.$style.align_center] = _vm.getTextAlignCol(colDef) == 'center', _obj[_vm.$style.align_right] = _vm.getTextAlignCol(colDef) == 'right', _obj ),on:{"click":function () {_vm.handleClickTd(row, col, colDef, rowDat)}}},[_vm._t(colDef.slot ||("__unnamed_slot_" + col),function(){return [_vm._v(" "+_vm._s(_vm.formatTd(rowDat[colDef.key], colDef))+" ")]},null,( _obj$1 = {
            row: row, col: col,
            key: colDef.key,
            value: rowDat[colDef.key],
            colDef: colDef, rowDat: rowDat
          }, _obj$1[colDef.key] = rowDat[colDef.key], _obj$1 ))],2)})],2)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }