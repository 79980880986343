<template>
  <label
    v-if="!!byLabel"
    :class="classObject"
    :style="customStyle"
    @click="$emit('click', $event)"
  >
    <slot />
  </label>

  <a
    v-else-if="!!href"
    :href="href"
    :class="classObject"
    :style="customStyle"
    @click="$emit('click', $event)"
  >
    <slot />
  </a>

  <button
    v-else-if="!href"
    :type="buttonType"
    :class="classObject"
    :style="customStyle"
    @click="$emit('click', $event)"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: "BaseButton",
  props: {
    /**
     * ボタンの直径を指定します。
     */
    size: {
      type: String,
      default: "70px",
    },
    /**
     * リンク先を指定できます。`to`が指定されている場合はそちらが優先されます
     */
    href: {
      type: String,
      default: "",
    },
    /**
     * ボタン背景色を指定できます。
     */
    bgColor: {
      type: String,
      default: "green",
      validator: (value) =>
        [undefined, "green", "dark-green", "white", "yellow"].includes(value),
    },
    /**
     * 文字色を指定できます。
     */
    color: {
      type: String,
      default: "white",
      validator: (value) =>
        [undefined, "white", "gray", "black", "green"].includes(value),
    },
    /**
     * ボーダースタイルを適用するかを指定します
     */
    bordered: {
      type: Boolean,
      default: false,
    },
    /**
     * box-shadowの有無を指定します。
     */
    shadow: {
      type: Boolean,
      default: false,
    },
    buttonType: {
      type: String,
      default: "submit",
      validator: (value) => [undefined, "submit", "button"].includes(value),
    },
    byLabel: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isCursorPointer: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    classObject() {
      return [
        this.$style.button,
        this.$style["bg-" + this.bgColor],
        this.$style[this.color],
        this.bordered ? this.$style.bordered : null,
        this.shadow ? this.$style.shadow : null,
        this.disabled ? this.$style.disabled : null,
      ];
    },
    customStyle() {
      // const displays = {
      //   "inline-block": "inline-flex",
      //   "inline-flex": "inline-flex",
      //   block: "flex",
      //   flex: "flex",
      // };
      let style = {
        width: this.size,
        height: this.size,
      };
      if(this.isCursorPointer){
        // trueの場合マウスオーバーでカーソルが指マークに変化
        style.cursor = 'pointer'
      }
      return style
    },
  },
};
</script>

<style lang="scss" module>
.button {
  border: 0;
  border-radius: 50%;
  display: inline-flex;
  padding: 0;
  font-size: 11px;
  font-weight: bold;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  font-family: sans-serif;

  &.bg-green {
    background: $keyGreen;
    &.bordered {
      border: 2px solid $keyWhite;
    }
  }
  &.bg-dark-green {
    background: $KeyDarkGreen;
    &.bordered {
      border: 2px solid $keyWhite;
    }
  }
  &.bg-white {
    background: $keyWhite;
    &.bordered {
      border: 2px solid $keyGreen;
    }
  }
  &.bg-yellow {
    background: $keyYellow;
    &.bordered {
      border: 2px solid $keyWhite;
    }
  }
  &.white {
    color: $fontWhite;
  }
  &.black {
    color: $fontBlack;
  }
  &.gray {
    color: $fontGray;
  }
  &.green {
    color: $keyGreen;
  }
  &.shadow {
    box-shadow: 0 1px 3px 0 $fontBlack;
  }
  &.disabled {
    pointer-events: none;
  }
}
</style>
