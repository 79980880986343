export default {
  namespaced: true,
  state: {
    token: "",
  },
  getters: {
    getToken (state) {
      return state?.token
    }
  },
  mutations: {
    setToken(state, token){
      state.token = token
    },
    clearAll(state) {
      Object.keys(state).forEach(key => {
        state[key] = ""
    })
    }
  },
}
