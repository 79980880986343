import Vue from 'vue'
import axios from 'axios'
import VueCookies from 'vue-cookies'
const cookies = VueCookies
import router from '../router'
import dayjs from "dayjs";

import ConstantsPlugin from './constants'
Vue.use(ConstantsPlugin)

async function updateToken(auth_info) {
  let new_auth_info = {
    tel_or_email: auth_info.tel_or_email,
    refresh_token: auth_info.refresh_token
  }

  try{
    const response = await axios.post(process.env.VUE_APP_API_BASE_URL + '/api/' + Vue.prototype.$constants.API_VERSION + '/auth/token/refresh', {
      tel_or_email: auth_info.tel_or_email,
      refresh_token: auth_info.refresh_token,
    })

    const value = response.data.value
    new_auth_info['access_token'] = value.access_token
    new_auth_info['expired_at'] = dayjs().add(value.expires_in, 's').format()
  } catch {
    //エラーだったら認証情報を空にして返す
    new_auth_info = {}
  }

  return new_auth_info
}

export default () => {
  const http = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    responseType: 'json',
    crossDomain: true,
    timeout: 600000,
    withCredentials: true // Cookieの送信を許可
  });

  http.interceptors.request.use(
    async function (config) {
      if (process.env.VUE_APP_CONSOLE_LOGGING == 'true') {
        console.info('[info]', config)
      }
      const auth_info = cookies.get('auth_info')
      let access_token = ''
      if(auth_info){
        access_token = auth_info.access_token

        if (! dayjs().isBefore(auth_info.expired_at)) {
          const new_auth_info = await updateToken(auth_info)
          if (Object.keys(new_auth_info).length > 0) {
            cookies.set('auth_info', new_auth_info)
            access_token = new_auth_info.access_token
          } else {
            // アクセストークンが必要が無いAPI(ログイン必要無いAPI)があり、
            // アクセストークン期限切れ時にトークン更新できなかった場合に、一律ログイン画面しない様にコメントアウト
            // アクセストークンが必要なAPIは、アクセストークン期限切れの場合401エラーを返すため、401エラー時にログイン画面へ戻る処理へ任せる
            //router.push('/open/ju_setting')
          }
        }
      }
      //SSOログイン用ヘッダー更新防止対応
      if(access_token == null || access_token.length == 0) {
        return config
      }
      config['headers'] = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + access_token
      }

      return config
    },
    function (error) {
      if (process.env.VUE_APP_CONSOLE_LOGGING == 'true') {
        console.error('[error]', error)
      }
      return Promise.reject(error)
    }
  );

  http.interceptors.response.use(
    function (response) {
      if (process.env.VUE_APP_CONSOLE_LOGGING == 'true') {
        console.info('[info]', response)
      }
      return response
    },

    function (error) {
      if (process.env.VUE_APP_CONSOLE_LOGGING == 'true') {
        console.error('[error]', error)
        if(error.response && error.response.data.value.errors !== '') {
          //エラーメッセージの配列を各々で出力
          error.response.data.value.errors.forEach(message => {
            console.error('[error]', message)
          })
        }
      }

      const response_code = error.response ? error.response.status : 400

      // axiosのエラー時にリジェクトを返して、axios内でエラー処理
      if([400, 404, 461].includes(response_code)){
        return Promise.reject(error)
      }

      // local以外はエラーハンドリングでページ遷移
      if(response_code === 401) {
        if (['develop', 'staging', 'production'].includes(process.env.VUE_APP_NODE_ENV)) {
          if(! router.currentRoute.path !== '/open/ju_setting') {
            if (!router.currentRoute.query.redirect) {
              if (router.currentRoute.fullPath != '/open/ju_setting') {
                // リダイレクト指定は初回のみ、ログイン画面以外の場合にリダイレクト先設定
                return router.push({
                  path: "/open/ju_setting",
                  query: {
                    redirect: router.currentRoute.fullPath
                  }
                })
              }
            }
            return router.push({
              path: "/open/ju_setting",
            })
          }
        }
      }

      // 460はユーザーには404を見せる
      if(response_code === 460) {
        if (['develop', 'staging', 'production'].includes(process.env.VUE_APP_NODE_ENV)) {
          return router.replace('/error/404')
        }
      }

      if (['develop', 'staging', 'production'].includes(process.env.VUE_APP_NODE_ENV)) {
        return router.replace('/error/500')
      }
    }
  );
  return http;
}
