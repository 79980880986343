<template>
  <div id="app" class="app">
    <component :is="layout"></component>
  </div>
</template>

<script>
import DefaultLayout from './layouts/Default.vue'
import OpenLayout from './layouts/Open.vue'
import OpenInformationLayout from './layouts/OpenInformation.vue'
import OpenTopLayout from './layouts/OpenTop.vue'
import HomeLayout from './layouts/Home.vue'
import TeamLayout from './layouts/Team.vue'
import TeamNewLayout from './layouts/TeamNewLayout.vue'
import TeamWithoutHeaderLayout from './layouts/TeamWithoutHeaderLayout.vue'
import VoteResultLayout from './layouts/VoteResult.vue'
import UnionLayout from './layouts/Union.vue'
import CommentLayout from './layouts/Comment.vue'
import TopHomeLayout from './layouts/TopHome.vue'
import PostAndVoteCreateLayout from './layouts/PostAndVoteCreate.vue'
export default {
  components: {
    DefaultLayout,
    OpenLayout,
    OpenInformationLayout,
    OpenTopLayout,
    HomeLayout,
    TeamLayout,
    TeamNewLayout,
    CommentLayout,
    VoteResultLayout,
    UnionLayout,
    TeamWithoutHeaderLayout,
    TopHomeLayout,
    PostAndVoteCreateLayout,
  },
  computed: {
    layout() {
      let layout = this.$route.meta.layout
        ? this.$route.meta.layout + '-layout'
        : 'default-layout'
      return layout
    },
  },
  head: {
    meta: [
      {
        'http-equiv': 'Content-Security-Policy',
        content: 'upgrade-insecure-requests',
      },
    ],
  },
}
</script>

<style lang="scss"></style>
