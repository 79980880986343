export default {
  namespaced: true,
  state: {
    tel_or_email: "",
    tel: "",
    password: "",
    juid: "",
    country: "",
    birthday: "",
    is_email: "",
  },
  mutations: {
    setTelOrEmail(state, tel_or_email){
      state.tel_or_email = tel_or_email
    },
    setTel(state, tel){
      state.tel = tel
    },
    setPassword(state, password) {
      state.password = password
    },
    setJuid(state, juid) {
      state.juid = juid
    },
    setCountry(state, country) {
      state.country = country
    },
    setBirthday(state, birthday) {
      state.birthday = birthday
    },
    setIsEmail(state, is_email) {
      state.is_email = is_email
    },
    clearAll(state) {
      Object.keys(state).forEach(key => {
        state[key] = ""
    })
    }
  },
}
