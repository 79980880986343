<template>
  <fa-icon
    v-if="!!iconObject"
    :icon="iconObject"
    :class="className"
    :style="customStyle"
    v-bind="$attrs"
  />
</template>

<script>
export default {
  name: 'BaseIcon',
  props: {
    type: {
      type: String,
      default: 'fas',
      validator: (value) => ['fas', 'far', 'fab'].includes(value),
    },
    name: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '16px',
    },
  },
  computed: {
    iconObject() {
      if (!this.type || !this.name) return null
      return [this.type, this.name]
    },
    className() {
      return this.color ? this.$style[this.color] : []
    },
    customStyle() {
      return {
        width: this.size,
        height: this.size,
      }
    },
  },
}
</script>

<style lang="scss" module>
.yellow {
  color: $keyYellow;
}
.green {
  color: $keyGreen;
}
.pink {
  color: $keyPink;
}
.gray {
  color: $iconGray;
}
.white {
  color: $keyWhite;
}
.black {
  color: $keyBlack;
}
.blue {
  color: $keyBlue;
}
.red {
  color: $keyRed;
}
</style>
