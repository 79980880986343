<template>
  <div :class="$style.change_password">
    <app-prev-button @click="backButtonAction(prevRoute)" />
    <h1 :class='$style.title'>本人確認メッセージ送信</h1>
    <p :class='$style.note'>本人確認のためメッセージを送信しました。<br>メッセージを確認の上、次へを押してください。</p>

    <div :class="$style.resend_link_wrap">
      <a :class="$style.resend_link" href="#" @click="clickResend"
        >本人確認メッセージが届かない<br />再送する</a
      >
    </div>
    <p :class="$style.resend" v-show="isResend">
      本人確認メッセージを再送しました。<br />再度メッセージを確認の上、次へを押してください。
    </p>

    <base-message
      type="error"
      width="auto"
      v-if="errorMessage"
    >
      <template>
        {{ errorMessage }}
      </template>
    </base-message>

    <div :class='$style.next_button_wrap'>
      <base-button display='inline-block' width='100%' height='50px' @click="clickNext">
        次へ
      </base-button>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/base/BaseButton/BaseButton'
import BaseMessage from '@/components/base/BaseMessage/BaseMessage'
import AppPrevButton from '@/components/app/AppPrevButton/AppPrevButton'

export default {
  name: 'HomeChangeAuth',
  components: { BaseButton, BaseMessage, AppPrevButton },

  computed: {
    // Vuexストアからデータを取得
    authChangeData() {
      return this.$store.getters.getAuthChangeData;
    }
  },

  mounted() {
    this.$router.app.$emit('page-updated', {
      pageTitle: 'ログイン情報の変更',
      showSetting: false,
      back: this.prevRoute,
      bgColor: 'white',
      headerColor: 'yellow',
      showTabbar: false,
      showFooter: true, // this.isMobile
    });

    // Vuexストアからデータが取得できなければHomeChangeAuth画面に戻る
    if (!this.authChangeData.authChangeId || !this.authChangeData.changeCognitoId) {
      this.$router.replace({ name: 'HomeChangeAuth' });
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from && from.name) {
        if (from.name == 'HomeChangeAuthConfirm') {
          vm.prevRoute = { name: 'HomeChangeAuth' }
        } else {
          vm.prevRoute = from
        }
      } else {
        vm.prevRoute = '__history_back'
      }
    })
  },


  data() {
    return {
      prevRoute: '',
      isResend: false,
      errorMessage: "",
    };
  },

  methods: {
    clickNext() {
      this.$router.replace({ name: 'HomeChangeAuthConfirm' });
    },
    clickResend() {
      this.errorMessage = "";

      this.$axios.post('/api/' + this.$constants.API_VERSION + '/auth/change/resend', {
        "auth_change_id": this.authChangeData.authChangeId,
        "change_cognito_id": this.authChangeData.changeCognitoId,
      }).then(() => {
        this.isResend = true;
      }).catch(() => {
        this.isResend = false;
        this.errorMessage = "確認コードの再送時にエラーが発生しました。";
      });
    },
    backButtonAction(back) {
      if (back === '__history_back') {
        this.$router.go(-1);
      } else {
        this.$router.push(back, () => {});
      }
    },
  },
}
</script>

<style lang="scss" module>
.change_password {
  display: flex;
  flex-direction: column;
  min-width: 375px;
  margin: 0 auto;
  padding: 42px 32px 30px 32px;
  box-sizing: border-box;
  text-align: left;
}

.title {
  margin-top: 0;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 50px;
  width: 100%;
}

.next_button_wrap {
  margin-top: 10px;
}

.note,.resend {
  font-size: 13px;
  text-align: left;
  color: $fontBlack;
  margin-top: 0;
  margin-bottom: 38px;
}

.resend_link_wrap {
  text-align: center;
  margin-bottom: 38px;
}
.resend_link {
  color: $KeyDarkGreen;
  font-size: 13px;
}
</style>
