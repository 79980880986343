<template>
  <div class="default-layout">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'defaultLayout',
  components: {},
  computed: {},
  data() {
    return {}
  },
  methods: {},
}
</script>

<style lang="scss">
.test {
  font-size: 2rem;
}
</style>
