<template>
  <section :class="$style.team_join">
    <div :class="$style.invitees_avatar_wrap">
      <base-avatar
        size="126px"
        :border-color="'rgb(' +invite_info.team_color +')'"
        :path="invite_info.badge_image"
      />
    </div>
    <p :class="$style.description">
      <span :class="$style.invitees_name">{{invite_from}}</span><span v-if="invite_info.type != 'team_admin'">さん</span>が<br/>
      <span :class="$style.invitees_team">{{invite_to}}</span>に<br/>
      招待しました。
    </p>

    <p :class="$style.caption" v-if="mode == 'app'">「くみあいアプリ」がある場合は招待を確認、ない場合はインストールをして招待確認をお願いします。</p>
    <p :class="$style.caption" v-if="mode == 'vue'">招待確認をお願いします。</p>

    <div :class="$style.action_button">
      <base-button width="100%" height="50px"
      v-if="mode == 'app'"
      :href="app_url"
      :disable="!!app_url"
      >Step1.<br>くみあいアプリをインストール</base-button>
      <base-button width="100%" height="50px" bg-color="pink"
      :href="invite_url"
      :disable="!!invite_url"
      >Step2.<br>招待を確認</base-button>
    </div>

    <a :class="$style.confirmation" href="/open/faq">招待されたチームに参加できませんか？</a>

  </section>
</template>

<script>
import BaseAvatar from '@/components/base/BaseAvatar/BaseAvatar'
import BaseButton from '@/components/base/BaseButton/BaseButton'
import NoImage from '@/assets/images/account-circle.svg'

const MobileOS = {
    Android : 'Android',
    iOS : 'iOS',
    Other : 'Other',
};

export default {
  name: 'TeamJoin',
  components: { BaseAvatar, BaseButton },
  props: {
    mode: {
      type: String,
      required: true,
      default: 'vue',
    },
  },
  data() {
    return {
      token: '',
      app_url: '',
      invite_url: '',
      no_mobile: false,
      invite_info: {
        type: '',
        team_id: '',
        user_nickname: '',
        team_name: '',
        course_name: '',
        badge_image: NoImage,
        team_color: ''
      },
      invite_from:'',
      invite_to:'',
    }
  },
  mounted() {
    this.$router.app.$emit('page-updated', {
      hasLogin: false
    })
  },
  created: async function() {
    const mobileOs = this.getMobileOS()
    this.$loginfo(mobileOs)
    if (mobileOs == MobileOS.Android) {
      this.app_url = `javascript:window.open('${process.env.VUE_APP_GOOGLE_PLAY_URL}', '_blank');void(0);`
      this.invite_url = process.env.VUE_APP_MOBILE_INVITE_URL + "?path=/open/login&token=" + this.$route.query.token.replace(/\$/g, '\\$')
    } else if (mobileOs == MobileOS.iOS) {
      this.app_url = `javascript:window.open('${process.env.VUE_APP_APP_STORE_URL}', '_blank');void(0);`
      this.invite_url = process.env.VUE_APP_MOBILE_INVITE_URL + "?path=/open/login&token=" + this.$route.query.token.replace(/\$/g, '\\$')
    }else{
      this.no_mobile = true
      this.invite_url = "/open/login?token=" + encodeURIComponent(this.$route.query.token)
    }

    if (this.$route.query.token) {
      this.$axios.get('/api/' + this.$constants.API_VERSION + '/invite/info',{
          params: {
            token: this.$route.query.token,
          }
      })
      .then((response) => {
        this.invite_info = response.data.value

        if (this.invite_info.type == 'team_admin_url') {
          this.invite_from = this.invite_info.user_nickname
          this.invite_to = this.invite_info.team_name
        }
        if (this.invite_info.type == 'union_salon') {
          this.invite_from = this.invite_info.user_nickname
          this.invite_to = this.invite_info.course_name
        }
        if (this.invite_info.type == 'team_admin') {
          this.invite_from = 'j.union株式会社'
          this.invite_to = this.invite_info.team_name
        }

        if (this.mode == 'vue') {
          // Vue画面の場合
          const auth_info = this.$cookies.get('auth_info')
          if (auth_info?.access_token) {
            // ログイン済みの場合
            if (this.invite_info.type == 'team_admin') {
              // j.union様が組織管理者を招待する場合は、組織編集画面へ遷移
              this.invite_url = '/team/create/' + this.invite_info.team_id;
            } else {
              // 招待されたチームの情報画面(あいことば入力画面)へ遷移
              this.invite_url = '/team/info/' + this.invite_info.team_id;
            }
          }

          // user_idがない場合は未登録ユーザーなので会員登録へ遷移
          if(! this.invite_info.user_id){
            this.invite_url = '/open/registration'
          }

          // ログイン画面へ遷移
          if(! this.invite_url) {
            this.invite_url = '/open/ju_setting';
          }
        }
      })
      .catch((err) => {
        this.$router.push('/error/' +err.response.status)
      })

      this.$store.commit('invite/setToken', this.$route.query.token)
    }
    this.$loginfo(this.invite_url)
  },
  methods: {
    getMobileOS () {
      const userAgent = navigator.userAgent.toLowerCase()
      this.$loginfo(userAgent)
      if (userAgent.match('android.+mobile')) {
        return MobileOS.Android
      }
      else if (userAgent.match('iphone.+mobile')) {
        return MobileOS.iOS
      }

      return MobileOS.Other
    },
  },
}
</script>

<style lang="scss" module>
.team_join {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 375px;
  margin: 0 auto;
  padding: 36px 32px 20px 32px;
  box-sizing: border-box;
}

.invitees_avatar_wrap {
  margin-bottom: 24px;
}

.description {
  padding: 0 32px;
  font-size: 13px;
  margin-bottom: 24px;
  text-align: center;

  .invitees_name {
    font-weight: bold;
    color: $keyPink;
  }

  .invitees_team {
    font-weight: bold;
    color: $keyGreen;
  }
}

.caption {
  padding: 0 32px;
  font-size: 13px;
  margin-bottom: 20px;
  text-align: center;
}

.action_button {
  width: 100%;
  margin-bottom: 26px;
  & > :first-child {
    margin-bottom: 26px;
  }
}
.confirmation {
  font-size: 13px;
  &:link {
    color: $fontBlack;
  }
}


</style>
