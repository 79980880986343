var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.team_header},[(_vm.indexType)?_c('div',{class:_vm.$style.team_header_icon_utility},[(
        _vm.team != null &&
        _vm.belongsTo &&
        (_vm.team.auth_type === 'admin' ||
          (_vm.team.auth_type === 'admin_auth' &&
            _vm.team.joint_admin_invite_flg === 1))
      )?_c('div',[_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.clickAdminIcon()}}},[_c('img',{attrs:{"src":_vm.adminIconImg,"alt":"","width":_vm.iconSize}})])]):_vm._e(),(_vm.belongsTo)?_c('div',[_c('div',{ref:"icon_wrap",class:_vm.$style.icon_wrap,on:{"click":_vm.toggleShowNotification}},[_c('img',{attrs:{"src":_vm.notificationImg,"alt":"","width":_vm.iconSize}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowNotification),expression:"isShowNotification"}],class:_vm.$style.link_contents_team},[_c('div',{class:_vm.$style.menuItemLink},[_c('p',{on:{"click":function($event){return _vm.clickTeamUser('event_flg', 1)}}},[_vm._v("通知をONにする")]),_c('p',{on:{"click":function($event){return _vm.clickTeamUser('event_flg', 0)}}},[_vm._v("通知をOFFにする")]),_c('p',{on:{"click":function($event){return _vm.clickTeamUser('event_flg', 2)}}},[_vm._v("自分宛のみ通知")])])])])]):_vm._e(),(_vm.belongsTo)?_c('div',[_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.clickTeamUser('good_flg')}}},[_c('base-icon',{class:[
            ( _obj = {}, _obj[_vm.$style.heart] = !_vm.isGood, _obj ),
            ( _obj$1 = {}, _obj$1[_vm.$style.heart_active] = _vm.isGood, _obj$1 ) ],attrs:{"name":"heart","size":_vm.iconSize}})],1)]):_vm._e(),(!_vm.informationDisplayNone)?_c('div',[_c('router-link',{attrs:{"to":{ name: 'TeamInfo' }}},[_c('img',{attrs:{"src":_vm.informationImg,"width":_vm.iconSize,"alt":""}})])],1):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }