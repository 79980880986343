<template>
  <div :class="$style.header">
    <div :class="[$style.header_row1, $style.bg_image]" :style="headerBgStyle">
      <div :class="$style.header_row1_wrap">
        <div :class="$style.link" @click="toTeamHome" v-if="!isBeforeApproval">{{teamName.length > 20 ? teamName.slice(0,20) + '...' : teamName}} TOP</div>
      </div>
    </div>

    <div :class="{[$style.scroll_header]: changeHeader,
      [$style.bg_image]: changeHeader }"
      :style="[changeHeader ? headerBgStyle: '']">
      <div :class="changeHeader ? $style.header_compact_container : $style.header_container">
        <div :class="$style.grid_back">
          <app-prev-button v-if="!isBeforeApproval" margin="auto 0" :noMobile=true @click="$router.push(backUrl)" />
          <app-prev-button v-else margin="auto 0" :noMobile=true @click="$router.go(-1)" />
        </div>
        <div :class="[$style.title, $style.grid_title]" ref="title" style="word-break: break-word;">{{ isMobile && changeHeader ? title.slice(0, 14) + (title.length > 14 ? '...' : '') : title  }}</div>
        <a :class="[$style.link_comment, $style.grid_link_comment]" :href="`${$route.path}#linkComments`" v-show="(!isMobile && !noChangeHeader) || (isMobile && !changeHeader)">コメントを見る</a>
        <div :class="changeHeader ? $style.buttons : $style.grid_buttons">
          <page-nav-post
            :class="[$style.page_nav, $style.nav_previous]"
            :isToPrevious="true"
            :teamId="this.teamId"
            :previousPost="this.previousPost"
            :previousType="this.previousType"
          />
          <page-nav-post
            :class="[$style.page_nav, $style.nav_next]"
            :isToPrevious="false"
            :teamId="this.teamId"
            :nextPost="this.nextPost"
            :nextType="this.nextType"
          />
        </div>
        <div v-if="!isCommentPage" :class="$style.grid_menu">
          <team-chat-detail-menu-list
            v-if="!isBeforeApproval"
            :cover-image="coverImage"
            :team-id="Number(teamId)"
            :type="type"
            :chat-id="Number(chatId)"
            :read="read"
            :clip="clip"
            :show-pin-flg="showPinFlg"
            :favorite-flg="favoriteFlg"
            :good-flg="goodFlg"
            :read-count="readCount"
            :show-favorite="showFavorite"
            :show-share="showShare"
            :edit-chat-flg="editChatFlg"
            :show-read="showRead"
            :delete-auth-flg="deleteAuthFlg"
            :block-user="blockUser"
            :show-block="showBlock"
            :report_flg="report_flg"
            :show-report="showReport"
            :spam-flg="spamFlg"
            :commentNoticeFlg="commentNoticeFlg"
            :mentionNoticeFlg="mentionNoticeFlg"
            :is-before-approval="isBeforeApproval"
            @ref-data="reflectData"
            @spam="$emit('spam')"
            @block="$emit('block')"
            @report="$emit('report')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AppPrevButton from '@/components/app/AppPrevButton/AppPrevButton'
import TeamChatDetailMenuList from '@/pages/team/components/TeamChatDetailMenuList'
import PageNavPost from '@/components/app/AppTableList/PageNavPost'

export default {
  name: 'TeamChatDetailHeader',
  components: {
    AppPrevButton,
    TeamChatDetailMenuList,
    PageNavPost,
  },
  props: {
    coverImage: {
      type: String,
      default: '',
    },
    teamId: {
      type: Number
    },
    teamName: {
      type: String,
      default: '',
    },
    backUrl: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      validator: (type) =>
        !type ||
        [
          'post', // 投稿
          'vote', // ミニ投票
          'gift', // メッセージ
        ].includes(type),
      default: 'post',
    },
    title: {
      type: String,
      default: '',
    },
    clip: {
      type: Boolean,
      default: false,
    },
    read: {
      type: [Boolean, Number],
      default: false,
    },
    blockUser: {
      type: Boolean,
      default: false,
    },
    chatId: {
      type: Number,
    },
    readCount: {
      type: [String, Number],
      default: '',
    },
    commentCount: {
      type: String,
      default: '',
    },
    favoriteFlg: {
      type: Boolean,
      default: false,
    },
    goodFlg: {
      type: Boolean,
      default: false,
    },
    editChatFlg: {
      type: Boolean,
      default: false,
    },
    showPinFlg: {
      type: Boolean,
      default: true,
    },
    deleteAuthFlg: {
      type: Boolean,
      default: false,
    },
    imageEnlargementFlg: {
      type: Boolean,
      default: false,
    },
    showEllipsis: {
      type: Boolean,
      default: true,
    },
    isHightFix: {
      type: Boolean,
      default: false,
    },
    profileUrl: {
      type: String,
      default: '/home/profile',
    },
    showFavorite: {
      type: Boolean,
      default: true,
    },
    showShare: {
      type: Boolean,
      default: true,
    },
    showRead: {
      type: Boolean,
      default: true,
    },
    showBlock: {
      type: Boolean,
      default: true,
    },
    showBrowse: {
      type: Boolean,
      default: false,
    },
    showReport: {
      type: Boolean,
      default: true,
    },
    report_flg: {
      type: Boolean,
      default: false,
    },
    showSpam: {
      type: Boolean,
      default: true,
    },
    spamFlg: {
      type: Boolean,
      default: false,
    },
    commentNoticeFlg: {
      type: Boolean,
      default: false,
    },
    mentionNoticeFlg: {
      type: Boolean,
      default: false,
    },
    noChangeHeader: {
      type: Boolean,
      default: false,
    },
    isBeforeApproval: {
      type: Boolean,
      default: false
    },
    previousPost: {
      type: Number,
    },
    nextPost: {
      type: Number,
    },
    previousType: {
      type: Number
    },
    nextType: {
      type: Number
    },
  },
  data() {
    return {
      scrollY: 0,
      height: '180px',
    }
  },
  computed: {
    headerBgStyle () {
      // const coverImg = this.coverImage || ''
      return {
        backgroundImage: `url(${this.coverImage})`,
      }
    },
    changeHeader() {
      return this.noChangeHeader || this.scrollY > 15
    },
    isMobile() {
      return window.innerWidth < 1080;
    },
    isCommentPage() {
      const currentRoutePath = this.$route.path;
      return currentRoutePath.startsWith('/team/comment/');
    }
  },
  methods: {
    toTeamHome() {
      this.$router.push(`/team/home/${this.teamId}`)
    },
    getScroll() {
      this.scrollY = window.scrollY
    },
    getHeight() {
      const elem = document.getElementById('static_header')
      if (elem && elem.clientHeight > 0) {
        this.height = `${elem.clientHeight}px`
      }
    },
    reflectData(resultObj) {
      this.$emit('ref-data', resultObj)
    },
    adjustTitleFontSize() {
      const title = this.$refs.title;
      const titleLength = title.textContent.length;
      const windowWidth = window.innerWidth;
      if (title) {
        title.style.fontSize = '36px';
        if (windowWidth <= 1080 && !this.changeHeader && titleLength <= 20) {
          //スマホサイズかつ文字数が20文字以下の時は、(iphone SE表示で)2行に収まるぎりぎりのフォントサイズ
          title.style.fontSize = '29px';
        } else if (this.isMobile && this.changeHeader) {
          //スマホサイズかつヘッダー表示が変わった際のフォントサイズ
          title.style.fontSize = '14px';
        } else {
          //液晶サイズごとにフォントサイズを変更する
          if (titleLength > 10 && windowWidth >= 1280) {
            title.style.fontSize = '21.5px';
          } else if (windowWidth >= 1024 && windowWidth < 1280) {
            title.style.fontSize = '18.5px';
          } else if (windowWidth >= 900 && windowWidth < 1024) {
            title.style.fontSize = '16px';
          } else if (windowWidth >= 800 && windowWidth < 900) {
            title.style.fontSize = '14px';
          } else if (windowWidth >= 768 && windowWidth < 800) {
            title.style.fontSize = '13px';
          } else if (windowWidth < 768) {
            title.style.fontSize = '11.5px';
          }
        }
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.getScroll)
    this.getHeight()
    this.adjustTitleFontSize();
  },
  updated(){
    this.adjustTitleFontSize();
  },
}

</script>
<style lang="scss" module>
$lightGray: #E0E0E0;

.header {
  position: fixed;
  z-index: 10;
  width: 100%;

  &_row1 {
    height: 70px;
    width: 100%;
    display: flex;
    justify-content:right;
    align-items: center;
    &_wrap {
      display: flex;
    }
  }

  .title {
    font-size: 36px;
    font-weight: bold;
    @include mobile {
      font-size: 24px;
    }
  }

  .link {
    cursor: pointer;
    margin-right: 36px;
    color: inherit;
    text-decoration: underline;
  }

  &_container {
    max-width: 1280px;
    margin: 0 auto;
    display: grid;
    grid-template-rows: 50px 50px 50px;
    grid-template-columns: 1fr 160px;
    align-items: center;
    @media (max-width: 400px) {
        grid-template-columns: 250px 1fr;
      }

    .grid_back {
      margin-left: 24px;
      grid-row: 1 / 2;
      grid-column: 1 / 2;
    }
    .grid_buttons {
      display: flex;
      justify-content: center;
      grid-row: 2 / 3;
      grid-column: 1 / 4;
      margin-left: 12px;
      margin-right: 24px;
      border-bottom: solid 7px $borderLightGray;
    }
    .grid_title {
      margin-top: 20px;
      margin-left: 12px;
      margin-right: 12px;
      overflow: hidden;
      grid-row: 3 / 4;
      grid-column: 1 / 2;
      @include mobile(){
        grid-column: 1 / 3;
      }
    }
    .grid_link_comment {
      margin-right: 24px;
      grid-row: 1 / 2;
      grid-column: 2 / 3;
      @include mobile(){
        grid-column: 2 / 4;
      }
      justify-self: end;
    }
    .grid_menu {
      margin-right: 24px;
      grid-row: 3 / 4;
      grid-column: 2 / 3;
      @include mobile(){
        grid-column: 3 / 4;
      }
      justify-self: end;
    }
  }

  &_compact {
    &_container {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: 100px 1fr 160px 80px;
      grid-template-rows: 70px;
      align-items: center;
      @include mobile() {
        grid-template-columns: 70px 1fr calc(160px - 90px) ;
      }
      .grid_back {
        margin-left: 24px;
        grid-column: 1 / 2;
      }
      .grid_title {
        margin-left: 6px;
        margin-right: 6px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        grid-column: 2 / 3;
      }
      @media (min-width: 1080px) {
        .grid_link_comment {
          grid-column: 3 / 4;
          justify-self: end;
      }
      }
      .grid_menu {
        margin-right: 24px;
        grid-column: 4 / 5;
        @include mobile() {
          grid-column: 3 / 4;
        }
        justify-self: end;
      }
    }
  }
}

.scroll_header {
  height: 70px;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  .title {
    font-size: 24px;
    font-weight: bold;
  }
  .buttons {
    display: none;
  }
  .page_nav {
    visibility:hidden
  }
}

.link_comment {
  font-size: 18px;
  color: $keyGreen;
  cursor: pointer;
  text-decoration: underline;
  @include mobile {
    font-size: 14px;
  }
}

.bg_image {
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #fff; //透過した画像を使用したとき用に不透明な色に変更
  // background-color:rgba(255, 255, 255, 0.6);
	// background-blend-mode: lighten;
  background-position: center;
  // opacity: 0.2;
}

.page_nav {
  text-align: center;
  padding-bottom: 20px;
}

.nav_previous {
  margin-right: 10px;
}

.nav_next {
  margin-left: 10px;
}
</style>
