export default {
  namespaced: true,
  state: {
    count: 0,
  },
  mutations: {
    addCount(state, n) {
      state.count += n
    },
  },
}
