<template>
  <div :class="$style.union_course">
    <app-prev-button @click="backButtonAction(prevRoute)" />

    <div :class="$style.union_course_content">
      <div :class="$style.content_header">
        <h1 :class="$style.content_header_title">
          {{ title }}
        </h1>
        <div :class="$style.content_header_status">
          <div :class="$style.content_header_status_tag">
            <base-tag
              width="100px"
              height="12px"
              bg-color="yellow"
              color="black"
              fontSize="s"
            >
              Step1- {{ achievement_rate }}% Step2 - {{ step2_number }}%
            </base-tag>
          </div>

          <div :class="$style.content_header_status_item">
            <base-overlay-menu-list v-if="true">
            <template v-slot:menu-button>
              <base-icon name="link" size="12px" color="green" />
              <span :class="[$style.content_header_status_item_caption, $style.share_link,]">この講座を共有する</span>
            </template>
            <template v-slot:menu-contents>
              <div style="margin:10px">チームHOMEに表示する</div>
              <div :class="$style.share_teams">
                <div :class="$style.share_teams_container">
                  <div :class="$style.share_teams_item" v-for="(team, i) of teams" :key="i">
                    <app-carousel-team-item
                    :head="team.team_name"
                    :border-color="team.team_color ? `rgb(${team.team_color})` : '#7BE293'"
                    :outline-color="team.shared_state ? '#FF88FF': '#FFFFFF'"
                    :image="team.path"
                    :show-badge="team.shared_state"
                    @click.native="shareTeam(team.team_id, lecture_id, team.shared_state)" />
                  </div>
                </div>
              </div>
            </template>
            <base-overlay-menu-list-item @click.native="shareUrl" to="">
              <base-icon name="link" size="12px" color="green" />この動画のURLを取得
            </base-overlay-menu-list-item>
          </base-overlay-menu-list>
          </div>
          <div :class="$style.content_header_status_item">
            <base-icon name="play-circle" size="12px" color="green" />
            <span :class="$style.content_header_status_item_caption">
              {{ lecture_history_count }}
            </span>
          </div>
          <div :class="$style.content_header_status_item">
            <span :class="[$style.like, $style.cursor_pointer, {[$style.on]: good_flg}]" @click="clickLectureGood">
              <base-icon name="heart" size="12px" />
            </span>
            <span :class="$style.content_header_status_item_caption">
              {{ good_count }}
            </span>
          </div>
        </div>
      </div>

      <div :class="$style.content_body">
        <div :class="$style.video_wrap">
          <base-video-poster
            :source="
              thumbnail_path
                ? thumbnail_path
                : 'http://via.placeholder.com/660x370.jpg'
            "
            display="inline-block"
            width="100%"
            @click="showVideo = true"
          />
        </div>

        <div :class="$style.course_progress">
          <p :class="$style.course_progress_title">
            動画の視聴とクイズ正答で視聴完了です。
          </p>
          <div :class="$style.course_progress_button_wrap">
            <div :class="$style.course_progress_button">
              <base-button
                width="100%"
                bg-color="yellow"
                rounded="rounded"
                :shadow="true"
                @click="showVideo=true"
              >
                <span :class="$style.course_progress_button_title">
                  STEP 1： 講座の動画を再生する
                </span>
              </base-button>
              <div :class="$style.course_progress_button_caption">
                <base-icon
                  size="12px"
                  name="check"
                  :color="achievement_rate < 100 ? 'gray' : 'pink'"
                />
                <span>{{ achievement_rate }}%完了しています。</span>
              </div>
            </div>

            <div :class="$style.course_progress_button_wrap">
              <div :class="$style.course_progress_button">
                <base-button
                  width="100%"
                  bg-color="yellow"
                  rounded="rounded"
                  :shadow="true"
                  @click="goQuiz()"
                >
                  <span :class="$style.course_progress_button_title">
                    STEP 2： 視聴確認クイズに回答する
                  </span>
                </base-button>
                <div :class="$style.course_progress_button_caption">
                  <base-icon
                    size="12px"
                    name="check"
                    :color="step2_number < 100 ? 'gray' : 'pink'"
                  />
                  <span>{{ step2 }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div :class="$style.course_about">
          <div :class="$style.sub_header_title">
            <span>この講座について</span>
          </div>
          <p :class="$style.course_about_description" v-html="autoLink(contents)">
          </p>
        </div>

        <div :class="$style.course_teacher">
          <div :class="$style.sub_header_title"><span>講師</span></div>
          <div
            :class="$style.course_teacher_list"
            v-for="(teacher, i) of teacher_list"
            :key="i"
          >
            <span>{{ teacher.name }}</span>
            <p :class="[$style.course_teacher_description]" v-html="autoLink(teacher.explain)"></p>
          </div>
        </div>

        <div :class="$style.course_next">
          <div :class="$style.sub_header_title"><span>次の講座</span></div>

          <div :class="$style.list_accordion_wrap">
            <div :class="$style.post_item_list">
              <div
                v-for="(lecture_item, i) of lecture_list"
                :class="$style.team_item_wrap"
                :key="i"
              >
                <app-carousel-post-item
                  :borderColor="
                    lecture_item.bar_color
                      ? `rgb(${lecture_item.bar_color})`
                      : '#7BE293'
                  "
                  icon="heart"
                  :selected="Boolean(lecture_item.good_flg)"
                  :head="lecture_item.lecture_title"
                  :foot="`${lecture_item.course_name}：${lecture_item.corner_name}`"
                  :image="
                    lecture_item.lecture_path
                      ? lecture_item.lecture_path
                      : 'https://via.placeholder.com/140x50'
                  "
                  :customTagText="lecture_item.status"
                  :customTagColor="
                    lecture_item.background_color
                      ? `rgb(${lecture_item.background_color})`
                      : null
                  "
                  :customTagTextColor="
                    lecture_item.text_color
                      ? `rgb(${lecture_item.text_color})`
                      : null
                  "
                  @click="goNextLecture(lecture_item.lecture_id)"
                  @select="setGoodFlg(i)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div :class="$style.next_button_wrap">
      <base-button
        width="120px"
        rounded="rounded"
        @click="lecture_list.length > 0 ? goNextLecture(lecture_list[0].lecture_id) : null"
        v-if="lecture_list.length > 0"
      >
        次へ
      </base-button>
    </div>

    <base-video-overlay v-if="showVideo" @close="showVideo = false">
      <template v-slot:body>
        <base-video
          :source="after_path"
          :auto-play="true"
          @close-video-modal="closeVideoModal"
          :current-time="current_time"
        />
      </template>
    </base-video-overlay>
  </div>
</template>

<script>
import BaseIcon from '@/components/base/BaseIcon/BaseIcon'
import BaseTag from '@/components/base/BaseTag/BaseTag'
import BaseButton from '@/components/base/BaseButton/BaseButton'
import AppCarouselPostItem from '@/components/app/AppCarousel/PostItem/AppCarouselPostItem'
import BaseVideoPoster from '@/components/base/BaseVideo/BaseVideoPoster'
import BaseVideo from '@/components/base/BaseVideo/BaseVideo'
import BaseVideoOverlay from '@/components/base/BaseVideo/BaseVideoOverlay'
import AppPrevButton from '@/components/app/AppPrevButton/AppPrevButton'
import { editLectureHistoryChatFavorite } from '@/helper/common.js'
import { autoLink } from '@/utils/helper.js'
import BaseOverlayMenuList from '@/components/base/BaseOverlayMenuList/BaseOverlayMenuList'
import BaseOverlayMenuListItem from '@/components/base/BaseOverlayMenuList/BaseOverlayMenuListItem'
import AppCarouselTeamItem from '@/components/app/AppCarousel/TeamItem/AppCarouselTeamItem'

export default {
  name: 'UnionCourse',
  components: {
    BaseIcon,
    BaseTag,
    BaseButton,
    AppCarouselPostItem,
    AppPrevButton,
    BaseVideoPoster,
    BaseVideo,
    BaseVideoOverlay,
    BaseOverlayMenuList,
    BaseOverlayMenuListItem,
    AppCarouselTeamItem,
  },
  async mounted() {
    this.$router.app.$emit('show-spinner', true)
    await Promise.all([
      this.getLectureInfo(),
      this.getNextList(),
      this.fetchTeamList(),
    ])
    this.updateLectureHistory({
      lecture_id: this.lecture_id,
      playback_terminal: this.isMobile ? 'スマートフォン' : 'PC',
      add_display_count: 1,
    })
    this.$router.app.$emit('show-spinner', false)
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // console.info(from)
      let from_name = null
      let from_params_corner_id = null
      let from_params_lecture_id = null
      let from_page_title = null
      // console.log(history.state)

      if (from.name) {
        from_name = from.name
        if (from.params) {
          if (from.params.corner_id) {
            from_params_corner_id = from.params.corner_id
          }
          if (from.params.lecture_id) {
            from_params_lecture_id = from.params.lecture_id
          }
        }
      } else if (history.state) {
        if (history.state.from_name) {
          from_name = history.state.from_name
        }
        if (history.state.from_params_corner_id) {
          from_params_corner_id = history.state.from_params_corner_id
        }
        if (history.state.from_params_lecture_id && history.state.from_page_title) {
          from_params_lecture_id = history.state.from_params_lecture_id
          from_page_title = history.state.from_page_title
        }
      }

      if (from_name == 'HomeHome') {
        vm.pageTitle = 'HOME'
        vm.prevRoute = {name: 'HomeHome'}
      } else if (from_name == 'UnionHome' || from_name === null) {
        vm.pageTitle = 'MOVIE'
        vm.prevRoute = {name: 'UnionHome'}
      } else if (from_name == 'UnionLeaningStatus') {
        vm.pageTitle = '学習状況'
        vm.prevRoute = {name: 'UnionLeaningStatus'}
      } else if (from_name == 'UnionCorner') {
        vm.getCornerList(from_params_corner_id)
        vm.prevRoute = {name: 'UnionCorner', params: {corner_id: from_params_corner_id}}
      } else if (from_name == 'UnionCourse') {
        if (from_params_lecture_id) {
          vm.pageTitle = from_page_title
          vm.prevRoute = {name: 'UnionCourse', params: {lecture_id: from_params_lecture_id}}
        } else {
          vm.pageTitle = 'MOVIE'
          vm.prevRoute = {name: 'UnionHome'}
        }
      // IRET_JUNION_UNI_VERSE-2117
      //「43000 - 講座」画面へ遷移後、メニューバーの「24110_プロフィール 編集」or「通知設定」or「ログイン情報変更」画面へ遷移し、
      //「43000 - 講座」画面に戻ってきて「戻る」ボタン押下時、上記３つの画面に戻るのではなく、「43000 - 講座」画面へ遷移する前の画面に戻るよう修正
      } else if (from_name == 'HomeProfileEdit' || from_name == 'HomeNoticeEdit' || from_name == 'HomeChangeAuth') {
        vm.prevRoute = {name: '__three_history_back'}
      }
      vm.prevRoute.force_history_back = true

      // history.state 追加
      let history_var = {}
      history_var.from_name = from_name
      if (from_params_corner_id) {
        history_var.from_params_corner_id = from_params_corner_id
      }
      if (from_params_lecture_id) {
        history_var.from_params_lecture_id = from_params_lecture_id
      }
      history_var.from_page_title = vm.pageTitle
      history.replaceState(history_var, '')

      // console.info(history.state)
    })
  },
  beforeRouteUpdate(to, from, next) {
    // console.info(from)

    // ルート移動
    next()

    this.lecture_id = to.params.lecture_id

    // 画面情報更新
    let self = this
    async function sameLayoutUpdate () {
      self.$router.app.$emit('show-spinner', true)
      await Promise.all([
        self.getLectureInfo(),
        self.getNextList()
      ])
      self.updateLectureHistory({
        lecture_id: self.lecture_id,
        playback_terminal: self.isMobile ? 'スマートフォン' : 'PC',
        add_display_count: 1,
      })
      self.$router.app.$emit('show-spinner', false)
    }
    sameLayoutUpdate ()

    this.prevRoute = {name: from.name, params: {lecture_id: from.params.lecture_id}}
    this.prevRoute.force_history_back = true

    // リロード対策
    if (history.state && history.state.from_name) {
      // 記録があったら上書きしない
      // console.info('already history exists')
    } else {
      // console.info(history.state)
      let history_var = {}
      history_var.from_name = from.name
      history_var.from_params_corner_id = from.params.corner_id
      history_var.from_params_lecture_id = from.params.lecture_id
      history_var.from_page_title = this.pageTitle
      history.replaceState(history_var, '')
    }

    // console.info(history.state)
  },
  updated() {
    this.$router.app.$emit('page-updated', {
      pageTitle: this.pageTitle,
      back: this.prevRoute,
      showSetting: true,
      bgColor: 'yellow',
      lectureId: this.lecture_id,
      favoriteFlg: this.favorite_flg,
    })
  },
  data() {
    return {
      showVideo: false,
      title: 'タイトル(未取得)',
      contents: 'コンテンツ内容(未取得)',
      thumbnail_path: '',
      teacher_list: [],
      good_count: 0,
      lecture_history_count: 0,
      achievement_rate: 0,
      after_path: '',
      step2: '未回答(未取得)',
      step2_number: 0,
      lecture_list: [],
      pageTitle: 'HOME',
      backUrl: '/',
      corner_id: this.$route.query.corner_id,
      corner_name: 'コーナー名(未取得)',
      course_id: 1,
      course_name: 'コース名(未取得)',
      start_time: '00:00:00',
      lecture_id: this.$route.params.lecture_id,
      favorite_flg: 0,
      good_flg: 0,
      current_time: 0,
      processing: false,
      prevRoute: { name: 'HomeHome'},
      teams: []
    }
  },
  methods: {
    async getLectureInfo() {
      await this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/lecture/info', {
          params: { lecture_id: this.lecture_id },
        })
        .then((response) => {
          var lecture_data = response.data.value.lecture
          this.title = lecture_data.title
          this.contents = lecture_data.contents
          this.teacher_list = lecture_data.teacher_list
          this.good_count = lecture_data.good_count
            ? lecture_data.good_count
            : 0
          this.lecture_history_count = lecture_data.lecture_history_count
            ? lecture_data.lecture_history_count
            : 0
          this.achievement_rate = lecture_data.achievement_rate
            ? lecture_data.achievement_rate
            : 0
          this.step2 = lecture_data.step2
          this.step2_number = lecture_data.step2_number
            ? lecture_data.step2_number
            : 0
          this.after_path = lecture_data.after_path
          this.thumbnail_path = lecture_data.thumbnail_path
          this.favorite_flg = lecture_data.favorite_flg
          this.good_flg = lecture_data.good_flg
          this.start_time = lecture_data.start_time
          var split_start_time = this.start_time.split(':')
          this.current_time =
            split_start_time[0] * 3600 +
            split_start_time[1] * 60 +
            split_start_time[2] * 1
        })
        .catch((error) => {
          this.$logerror(error)
          //this.$router.push('/error/' + error.code)
        })
    },
    getNextList() {
      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/lecture/next/list', {
          params: {
            lecture_id: this.lecture_id,
          },
        })
        .then((response) => {
          this.lecture_list = response.data.value.lecture_list
        })
        .catch((error) => {
          this.$logerror(error)
        })
    },
    getCornerList(corner_id) {
      this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/corner/list', {
          params: {
            id: corner_id,
            sort: 'id',
            direction: 'asc',
            limit: 1,
          },
        })
        .then((response) => {
          this.corner_id = response.data.value[0].corner_id
          this.corner_name = response.data.value[0].corner_name
          this.course_id = response.data.value[0].course_id
          this.course_name = response.data.value[0].course_name
          this.pageTitle = this.course_name + ':' + this.corner_name
        })
        .catch((error) => {
          this.$logerror(error)
        })
    },
    setGoodFlg(i) {
      this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/lecture_history/chat/favorite', {
          chat_users: [
            {
              lecture_id: this.lecture_list[i]['lecture_id'],
              good_flg: this.lecture_list[i]['good_flg'] ? 0 : 1,
            },
          ],
        })
        .then(() => {
          this.lecture_list[i]['good_flg'] = !this.lecture_list[i]['good_flg']
        })
        .catch((error) => {
          this.$logerror(error)
        })
    },
    goNextLecture(lecture_id) {
      this.$router.push('/union/course/' + lecture_id)
      // location.href = '/union/course/' + lecture_id
    },
    async updateLectureHistory(params) {
      if (this.processing) return
      this.processing = true
      await this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/lecture_history/registration', params)
        .then((response) => {
          this.$loginfo(response)
        })
        .catch((error) => {
          this.$logerror(error)
        })
        this.processing = false
    },
    goQuiz() {
      this.$router.push({
        name: 'UnionCourseQuiz',
        params: {
          corner_id: this.corner_id,
          lecture_id: this.lecture_id,
        },
      })
    },
    async closeVideoModal(currentTime, totalPlayTime) {
      this.current_time = currentTime
      var current_start_time = this.$dayjs('2000-01-01 00:00:00')
        .add(currentTime, 's')
        .format('HH:mm:ss')

      if (current_start_time > this.start_time) {
        await this.updateLectureHistory({
          lecture_id: this.lecture_id,
          playback_terminal: this.isMobile ? 'スマートフォン' : 'PC',
          start_time: current_start_time,
          add_sum_view_time: totalPlayTime,
        })
        await this.getLectureInfo()
      } else {
        this.updateLectureHistory({
          lecture_id: this.lecture_id,
          playback_terminal: this.isMobile ? 'スマートフォン' : 'PC',
          add_sum_view_time: totalPlayTime,
        })
      }
    },
    shareUrl() {
      navigator.clipboard
        .writeText(location.href)
        .then(() => {
          this.$loginfo(location.href)
          alert('URLをクリップボードにコピーしました')
        })
        .catch((error) => this.$logerror(error))
    },
    async shareTeam(team_id, lecture_id, shared_state) {
      await this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/lecture_team/registration', {
          lecture_team_list: [
            {
              team_id: team_id,
              lecture_id: lecture_id,
              shared_state: !shared_state
            }
          ]
        })
        .then(() => {
          this.fetchTeamList()
          if (!shared_state) {
            alert('チームホームに表示を設定しました')
          } else {
            alert('チームホームに表示を解除しました')
          }
        })
    },
    backButtonAction(back) {
      let old_history_state = history.state
      old_history_state.from_name = null
      history.replaceState(old_history_state, '')
      if (back.name === '__three_history_back') {
        // 「43000 - 講座」画面へ遷移する前の画面に戻るので、3つ前の画面に戻る
        this.$router.go(-3)
      } else {
        this.$router.push(back, () => {})
      }
    },
    clickLectureGood: async function() {
      if (this.processing) return

      this.processing = true
      const targetFlg = !!this.good_flg == true ? 0 : 1
      const {status} = await editLectureHistoryChatFavorite({ lecture_id: Number(this.lecture_id),
                                                              good_flg: targetFlg })
      if(status == 'success'){
        this.good_flg = targetFlg
        if(targetFlg) this.good_count++
        else this.good_count--
      }
      this.processing = false;
    },
    async fetchTeamList() {
      await this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/lecture_team/list', {params: {lecture_id: this.lecture_id}})
        .then((res) => {
          console.log(res.data.value)
          this.teams = res.data.value.team_list
          console.log(this.teams)
        })
    },
    autoLink,
  },
  watch: {
    showVideo: function (newShowVideo, oldShowVideo) {
      // 動画再生開始時
      if (newShowVideo && !oldShowVideo) {
        this.updateLectureHistory({
          lecture_id: this.lecture_id,
          playback_terminal: this.isMobile ? 'スマートフォン' : 'PC',
          start_date_time: this.$dayjs().format('YYYY-MM-DD HH:mm:ss'),
          add_view_count: 1,
        })
      }
    },
  },
}
</script>

<style lang="scss" module>
.union_course {
  display: flex;
  width: 960px;
  flex-direction: column;
  align-items: center;
  padding: 12px 0 48px 0;
  margin: 0 auto;
  overflow: hidden;
  @include mobile() {
    width: 100%;
    margin: 0;
    padding: 20px 28px 24px 32px;
  }
}

.union_course_content {
  width: 660px;
  @include mobile() {
    width: 100%;
  }
}

.content_header {
  margin-bottom: 28px;
  &_title {
    width: 100%;
    font-size: 20px;
    line-height: 20px;
    margin-top: 0;
    margin-bottom: 8px;
    padding-bottom: 12px;
    box-sizing: border-box;
    border-bottom: 2px $keyYellow solid;
    @include mobile() {
      font-size: 13px;
    }
  }

  &_status {
    display: flex;
    align-items: center;
    margin-bottom: 28px;

    &_tag {
      display: inline-flex;
      margin-right: auto;
    }

    &_item {
      display: inline-flex;
      margin-left: 12px;

      &_caption {
        font-size: 10px;
        line-height: 12px;
        margin-left: 4px;
        transform: scale(0.9);
        color: $fontGray;

        &.share_link {
          margin-left: 0;
          text-decoration: underline;
        }
      }
      .like {
        color: #acacac;
        line-height: 8px;
        &.on {
          color: $keyPink;
        }
      }
    }
  }
}

.content_body {
  margin-bottom: 30px;
  @include mobile() {
    margin-bottom: 48px;
  }

  .video_wrap {
    margin-bottom: 32px;
    width: 100%;
  }

  .course_progress {
    background-color: $keyWhite;
    border: 1px solid $keyYellow;
    padding: 14px 46px;
    margin-bottom: 48px;
    @include mobile() {
      padding: 14px 20px 18px 20px;
      margin-bottom: 16px;
    }

    &_title {
      margin-top: 0;
      margin-bottom: 12px;
      text-align: center;
      font-size: 11px;
      font-weight: bold;
    }

    &_button_wrap {
      display: flex;
      justify-content: space-between;
      @include mobile() {
        flex-direction: column;
        &:last-child {
          margin-top: 14px;
        }
      }
    }

    &_button {
      text-align: center;

      &_title {
        font-size: 13px;
        color: $fontBlack;
      }

      &_caption {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        span {
          margin-left: 8px;
          font-size: 10px;
        }
      }
    }
  }

  .sub_header_title {
    display: flex;
    width: 100%;
    height: 24px;
    margin: 0;
    box-sizing: border-box;
    border-bottom: 2px $keyYellow solid;
    @include mobile() {
      height: 18px;
    }

    span {
      font-size: 13px;
      font-weight: bold;
      margin-bottom: 4px;
      @include mobile() {
        font-size: 10px;
        transform: scale(0.9);
        transform-origin: 0 0;
        margin-bottom: 0;
      }
    }
  }

  .course_about {
    margin-bottom: 30px;
    @include mobile() {
      margin-bottom: 20px;
    }

    &_description {
      word-wrap: break-word;
      margin-top: 10px;
      margin-bottom: 0;
      padding-left: 4px;
      font-size: 13px;
      white-space: pre-wrap;
      @include mobile() {
        font-size: 10px;
        transform: scale(0.9);
        transform-origin: 0 0;
        margin-bottom: 0;
      }
    }
  }

  .course_teacher {
    margin-bottom: 40px;
    @include mobile() {
      margin-bottom: 20px;
    }

    &_list {
      display: flex;
      flex-direction: column;
      margin-top: 16px;
      margin-bottom: 16px;
      padding-left: 4px;

      span {
        font-size: 13px;
        font-weight: bold;
        @include mobile() {
          font-size: 10px;
        }
      }
    }

    &_description {
      margin: 0;
      padding-left: 4px;
      font-size: 13px;
      white-space: pre-wrap;
      @include mobile() {
        font-size: 10px;
        transform: scale(0.9);
        transform-origin: 0 0;
      }
    }
  }

  .course_next {
    margin-bottom: 30px;
  }
}

.list_accordion_wrap {
  width: 100%;
  margin-top: 0px;

  .post_item_list {
    padding: 0;
    margin-left: 8px;
    display: flex;
    flex-wrap: wrap;
    @include mobile() {
      flex-wrap: nowrap;
      overflow-y: scroll;
    }

    .team_item_wrap {
      margin-right: 20px;
      margin-top: 24px;
    }
  }
}

.next_button_wrap {
  display: flex;
  justify-content: center;
}
.share_teams {
  display: flex;
  overflow-x: scroll;
  margin: 10px;
}
.share_teams_container {
  display: flex;
  margin-top: 5px;
}
.share_teams_item {
  margin: 0 10px;
}
.cursor_pointer {
  cursor: pointer;
}
</style>
