<template>
  <div
    :class="[$style.footer, { [$style[`bg_${this.bgColor}`]]: !!this.bgColor }]"
  >
    <div :class="$style.icon_wrap">
      <img :class="$style.icon_logo" :src="logoPath" />
      <span :class="$style.icon_title"
        >©くみあいアプリ All rights reserved</span
      >
    </div>
    <base-modal
      v-if="showAppModal"
      @close="showAppModal = false"
      height="130px"
      type="round"
    >
      <template v-slot:header-title></template>
      <template v-slot:body>
        <div :class="$style.download_text">
          アプリ版くみあいアプリはこちらからダウンロードできます
        </div>
        <ul :class="$style.store_icon">
          <li :class="$style.apple_icon">
            <a
              href="https://apps.apple.com/jp/app/%E3%81%8F%E3%81%BF%E3%81%82%E3%81%84%E3%82%A2%E3%83%97%E3%83%AA/id1631089204"
            >
              <img
                src="@/assets/images/open_budge_app_store.svg"
                alt="App Soreからダウンロード"
              />
            </a>
          </li>
          <li :class="$style.google_icon">
            <a
              href="https://play.google.com/store/apps/details?id=com.j_union.KumiaiApp"
            >
              <img
                src="@/assets/images/open_budge_google_play.png"
                alt="Google Playで手に入れよう"
              />
            </a>
          </li>
        </ul>
      </template>
    </base-modal>
  </div>
</template>

<script>
import BaseModal from '@/components/base/BaseModal/BaseModal'

export default {
  name: 'AppFooter',
  components: {
    BaseModal,
  },
  data() {
    return {
      showAppModal: false,
    }
  },
  props: {
    bgColor: {
      type: String,
      default: 'white',
      validator: (bg_color) =>
        !bg_color || ['white', 'yellow'].includes(bg_color),
    },
  },
  mounted() {
    this.checkShowAppModal()
  },
  methods: {
    checkShowAppModal() {
      const shouldShowModal =
        !this.isExcludePath() && this.checkReferrer() && this.isSmartPhone()
      this.showAppModal = shouldShowModal
    },
    checkReferrer() {
      const baseUrl = process.env.VUE_APP_BASE_URL
      const referrer = document.referrer

      // セッションストレージに訪問フラグがあるか確認
      const isFirstVisit = !sessionStorage.getItem('visited')

      if (isFirstVisit) {
        // 初回訪問の場合
        sessionStorage.setItem('visited', 'true')
        // console.log('初回訪問 かつ サイト外からの遷移' + !referrer.includes(baseUrl))
        return !referrer.includes(baseUrl)
      } else {
        return false
      }
    },
    isSmartPhone() {
      const userAgent = window.navigator.userAgent
      // console.log('スマホ判定' + /iPhone|iPad|iPod|Android/i.test(userAgent))
      return /iPhone|iPad|iPod|Android/i.test(userAgent)
    },
    isExcludePath() {
      const excludePaths = [
        '/',
        '/open/index',
        '/open/about',
        '/open/faq',
        '/open/contact',
        '/open/terms_of_service',
        '/open/privacy_policy',
      ]
      // console.log('除外パス判定' + excludePaths.includes(this.$route.path))
      return excludePaths.includes(this.$route.path)
    },
  },
  computed: {
    logoPath() {
      return require('@/assets/images/nav_logo1.svg')
    },
  },
}
</script>

<style lang="scss" module>
.footer {
  //position: fixed;
  //bottom: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 40px;
  min-height: 40px;
  border-top: 1px solid $borderLiteGray;
  box-sizing: border-box;
  background: #fff;

  &.bg_white {
    border-top: 1px solid $borderLiteGray;
    background: #fff;
  }
  &.bg_yellow {
    border-top: 1px solid $keyYellow;
    background: $keyYellow;
  }
}
.icon_wrap {
  display: flex;
  align-items: center;

  .icon_logo {
    width: 20px;
    height: 20px;
  }

  .icon_title {
    font-size: 10px;
    transform: scale(0.9);
  }
}
.download_text {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  padding: 0 20px;
}
.store_icon {
  margin: 20px 0 0 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;
  padding-left: 0;
  .apple_icon {
    margin: 7px 0 0 0;
    box-sizing: border-box;
    @include mobile() {
      margin: 6px 0 0 0;
    }
    img {
      width: auto;
      height: 47px;
      @include mobile() {
        width: auto;
        height: 40px;
      }
    }
  }
  .google_icon {
    margin: 0 0 0 40px;
    box-sizing: border-box;
    @include mobile() {
      margin: 0 0 0 10px;
    }
    img {
      width: 151px;
      height: auto;
      @include mobile() {
        width: 135px;
        height: auto;
      }
    }
  }
}
.stores {
  display: flex;

  @include pc() {
    margin-top: 12px;
  }

  & > * {
    flex: 1;

    &:not(:last-child) {
      margin-right: 21px;
      @include mobile() {
        margin-right: 8px;
      }
    }
  }

  .google_play {
  }
  .app_store {
  }

  .phone {
    display: block;
    width: 150px;
    height: 300px;
    margin-bottom: 21px;

    @include mobile() {
      display: none;
    }
  }
  .budge {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  a {
    img {
      width: 135%;
    }
  }
}
</style>
