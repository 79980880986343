<template>
  <div :class="$style.wrapper">
    <ol :class="[$style.tab, $style['tab_' + this.tabColor]]">
      <li
        v-for="(tab, name) in $slots"
        :key="name"
        :class="[$style.item, { [$style.selected]: name == currentTab }]"
        @click="onClickTab(name)"
      >
        {{ name }}
      </li>
    </ol>
    <div :class="[$style.content, $style['tab_' + this.tabColor]]">
      <slot :name="currentTab" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'BaseTab',
  props: {
    tab: {
      type: String,
    },
    tabColor: {
      type: String,
      validator: (value) => ['yellow', 'green', 'underline'].includes(value),
      default: 'yellow',
    },
  },
  computed: {
    tabs() {
      return Object.keys(this.$slots)
    },
    currentTab: {
      get() {
        return this.tab || this.tabs[0] || null
      },
    },
  },
  methods: {
    onClickTab(name) {
      this.$emit('tab', name)
    },
  },
}
</script>
<style lang="scss" module>
.wrapper {
  background: $keyWhite;

  .tab {
    background: $keyWhite;
    display: flex;
    margin: 0;
    padding: 0;
    user-select: none;
    cursor: pointer;

    &_yellow {
      .item {
        background: $keyYellow;
        &.selected {
          background: $keyWhite;
        }
      }
    }

    &_green {
      .item {
        background: $keyWhite;
        &.selected {
          color: $keyWhite;
          background: $KeyDarkGreen;
        }
      }
    }

    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      list-style: none;
      flex: 1;
      font-size: 11px;
      font-weight: bold;
    }

    &_underline {
      .item {
        height: auto;
        padding: 0 30px 11px;
        font-size: 16px;
        line-height: 1.5;
        font-weight: bold;
        background: transparent;
        border-bottom: solid 1px $borderGray;
        white-space: nowrap;
        &.selected {
          background: transparent;
          border-bottom: solid 6px $keyYellow;
          padding-bottom: 6px;
        }
      }
    }
    &.tab_underline {
      @media screen and (max-width: 1200px) {
        overflow-x: scroll;
        max-width: 100%;
      }
    }
  }
  .content {
    background: $keyWhite;

    &.tab_green {
      border: 1px solid $KeyDarkGreen;
      box-sizing: border-box;
    }
    &.tab_underline {
      border: 0;
      background: transparent;
    }
  }
}
</style>
