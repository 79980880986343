<template>
  <div :class="$style.icon">
    <img
      :class="$style.icon_logo"
      @click="scrollToTop"
      src="@/assets/images/scroll_top_icon.png"
      alt="logo"
    />
    <img
      :class="$style.icon_logo"
      @click="scrollToBottom"
      src="@/assets/images/scroll_bottom_icon.png"
      alt="logo"
    />
  </div>
</template>

<script>
export default {
  name: 'AppScrollButton',
  methods: {
    scrollToTop: function () {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    },
    scrollToBottom() {
      const element = document.documentElement
      const bottom = element.scrollHeight - element.clientHeight
      window.scroll({ top: bottom, left: 0, behavior: 'smooth' })
    },
  },
}
</script>

<style lang="scss" module>
.icon {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: fixed;
  right: 0px;
  bottom: 150px;
  @media screen and (max-width: 768px) {
    bottom: 78px; // 小画面はAppTabbarがある
  }
  .icon_logo {
    margin: 6px;
    width: 30px;
    height: 30px;
    margin-right: 30px;
    cursor: pointer;
  }
}
</style>
