<template>
  <section :class="$style.union_gift_setting">
    <div :class="$style.union_gift_setting_content" v-if="!initLoading">
      <app-prev-button @click="$router.push({ name: 'UnionGift', params: {team_id}})">メッセージ送信基本設定へ戻る</app-prev-button>

      <div :class="$style.content_header">
        <h1 :class="$style.content_header_title">メッセージ送信設定</h1>
      </div>
      <div :class="$style.content_table_wrap">
        <base-message type="error" :class="$style.msg_area" v-if="hasError(errorMsgList) && hasFieldErrorMsg(errorMsgList, ['gift_setting_id'])"
        >
        入力内容に誤りがあります。
        </base-message>
        <template v-if="hasError(errorMsgList) && !hasFieldErrorMsg(errorMsgList, ['gift_setting_id'])">
          <base-message type="error" v-for="(error, i) in errorMsgList" :key="i" :class="$style.msg_area">{{error.message}}</base-message>
        </template>
        <template v-if="hasError(notImportedErrorMessages) && notImportedErrorMessages.length > 0">
          <base-message type="error" v-for="(error, i) in notImportedErrorMessages" :key="i" :class="$style.msg_area">{{error.message}}</base-message>
        </template>
        <base-message type="success" :class="$style.msg_area" v-if="successMsg">{{successMsg}}</base-message>
        <table :class="$style.content_table">
          <tr :class="$style.message_title">
            <th>メッセージタイトル</th>
            <td>
              <base-input
                v-model.trim="giftSettingInfo.title"
                :disabled="isReadOnly"
                :error="isFieldErrorMsg('title', errorMsgList)"
              >
              </base-input>
            </td>
          </tr>
          <tr :class="$style.notice_before">
            <th>通知文（前文）</th>
            <td>
              <base-text-area
                min-rows="3"
                max-rows="3"
                fixed-height="90px"
                v-model.trim="giftSettingInfo.body_1"
                :disabled="isReadOnly"
                :error="isFieldErrorMsg('body_1', errorMsgList)"
                />
            </td>
          </tr>
          <tr :class="$style.notice_condition">
            <th></th>
            <td>
              <div :class="$style.notice_condition_check">
                <base-check-box
                  name="notice_condition"
                  v-model="giftSettingInfo.bind_flg"
                  v-on:update="changeBindFlg"
                  :disabled="isReadOnly"
                  :error="isFieldErrorMsg('bind_flg', errorMsgList)"
                >差し込み文章設定を使用します
                </base-check-box>
                <p>※差し込み文章は配布対象者リスト作成時にセットします</p>
              </div>
            </td>
          </tr>
          <tr :class="$style.notice_after">
            <th>通知文（後文）</th>
            <td><base-text-area
                  min-rows="3"
                  max-rows="3"
                  fixed-height="90px"
                  v-model.trim="giftSettingInfo.body_2"
                  :disabled="isReadOnly"
                  :error="isFieldErrorMsg('body_2', errorMsgList)"
                />
            </td>
          </tr>
          <tr :class="$style.charge">
            <th>担当者</th>
            <td>{{giftSettingInfo && giftSettingInfo.created_user ? giftSettingInfo.created_user : ''}}</td>
          </tr>
          <tr :class="$style.attach_gift">
            <th>付属させるメッセージ</th>
            <td>
              <base-select
                name="gift_id"
                id="gift_id"
                v-model="giftSettingInfo.gift_id"
                :options="giftItems"
                placeholder="QUOカードPayでメッセージ発行する場合は選択してください"
                :disabled="isReadOnly"
                :error="isFieldErrorMsg('gift_id', errorMsgList)"
              />
            </td>
          </tr>
          <tr :class="$style.gift_to">
            <th>宛先設定</th>
            <td>
              <base-button width="185px" rounded="rounded" padding="0"
              v-if="showBtnSendList" @click="sendListClick">配布対象者リストを作成</base-button>
              <a href="javascript: void(0)"  v-if="showAnchorSetting" @click="sendListClick">設定を確認</a>
              <p>※QUOカードPayでメッセージ発行する場合は、付属させるメッセージを選択してから作成に進んでください。</p>
              <base-message type="error" :class="$style.msg_area" v-if="isFieldErrorMsg('gift_target_id', errorMsgList)">
              配布対象者リストを作成してください。
              </base-message>
            </td>
          </tr>
        </table>
      </div>
      <div :class="$style.bottom_action_button" v-if="!initLoading && !processing">
        <base-button
          width="185px"
          rounded="rounded"
          bg-color="pink"
          @click="previewClick"
        >プレビュー</base-button>
        <base-button width="185px" rounded="rounded" @click="registClick" v-if="!isReadOnly">保存</base-button>
        <base-button
          width="185px"
          rounded="rounded"
          @click="sendClick"
          v-if="!isNewMode && !processing && !isReadOnly && !giftSettingInfo.is_setting_update"
        >送信</base-button>
      </div>
      <div :class="$style.bottom_action_button" v-if="processing">
          <base-icon name="spinner" size="32px" spin />
      </div>
      <p :class="$style.bottom_action_comment">※通知設定に関わらず、受信ユーザには通知が行われます。</p>
    </div>
    <base-modal
      v-if="showPreview"
      @close="showPreview = false"
      width="auto"
      type="round"
      position="top"
      padding-top="52px"
      :class="$style.preview_modal"
    >
      <template v-slot:body>
        <div :class="$style.preview_modal_content">
          <gift-preview-tab :tab-data="tabData" :processing="processing" />
        </div>
      </template>
    </base-modal>

    <base-modal
      v-if="showSending && giftSettingInfo.gift_id"
      width="287px"
      type="none"
    >
      <template v-slot:body>
        <div :class="$style.sending_modal_content">
          <h1 :class="$style.sending_modal_content_header">送信を開始しました</h1>
          <p :class="$style.sending_modal_content_description">
            メッセージ送信完了後、一覧の下記アイコンより送信結果を確認いただけます。<br/>
            不具合、不明点などございましたらj.unionまでご連絡ください。
          </p>
          <div :class="$style.sending_modal_content_download_icon">
            <img src="@/assets/images/file_download.svg"  alt="sending">
          </div>
          <div>
            <base-button
              width="185px"
              rounded="rounded"
              @click="giftSendListClick"
            >一覧へ</base-button>
          </div>
        </div>
      </template>
    </base-modal>
    <base-modal
      v-if="showSending && !giftSettingInfo.gift_id"
      width="287px"
      type="none"
    >
      <template v-slot:body>
        <div :class="$style.sending_modal_content">
          <h1 :class="$style.sending_modal_content_header">送信を開始しました</h1>
          <p :class="$style.sending_modal_content_description">
            メッセージ送信結果は一覧のステータスで確認いただけます。<br/>
            不具合、不明点などございましたらj.unionまでご連絡ください。
          </p>
          <div>
            <base-button
              width="185px"
              rounded="rounded"
              @click="giftSendListClick"
            >一覧へ</base-button>
          </div>
        </div>
      </template>
    </base-modal>
    <div :class="[$style.union_gift_setting_content, $style.loading]" v-if="initLoading">
      <base-icon name="spinner" size="32px" spin />
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BaseInput from '@/components/base/BaseInput/BaseInput'
import BaseTextArea from '@/components/base/BaseTextArea/BaseTextArea'
import BaseCheckBox from '@/components/base/BaseCheckBox/BaseCheckBox'
import BaseSelect from '@/components/base/BaseSelect/BaseSelect'
import BaseButton from '@/components/base/BaseButton/BaseButton'
import BaseModal from '@/components/base/BaseModal/BaseModal'
import AppPrevButton from '@/components/app/AppPrevButton/AppPrevButton'
import GiftPreviewTab from './components/GiftPreviewTab'
import BaseMessage from '@/components/base/BaseMessage/BaseMessage'
import BaseIcon from '@/components/base/BaseIcon/BaseIcon'
import { isFieldErrorMsg, hasError, hasFieldErrorMsg } from '@/helper/validator.js'

export default {
  name: 'UnionGiftSetting',
  components: { BaseInput, BaseTextArea, BaseCheckBox, BaseSelect, BaseButton, BaseModal, AppPrevButton, GiftPreviewTab, BaseMessage, BaseIcon},
  props: {
    team_id: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    this.$router.app.$emit('page-updated', {
      pageTitle: 'メッセージ送信',
      back: '/union/gift/' +this.team_id,
      headerColor: 'white',
      showSetting: true,
      bgColor: 'gray'
    })
  },
  data() {
    return {
      showPreview: false,
      showSending: false,
      tabData:[],
      initLoading: false,
      processing: false,
      giftSettingInfo: {
        title: null,
        body_1: null,
        body_2: null,
        bind_flg: false,
        gift_id: null,
        created_user: null,
        status: null,
        is_setting_update: false,
      },
      giftList: [],
      errorMsgList: [],
      successMsg: '',
      giftSettingId: null,
      giftTargetId: null,
      isReadOnly: false,
    }
  },
  watch: {
    'giftSettingInfo.title': function(newVal, oldVal){
      this.setSettingUpdateFlag(newVal, oldVal)
    },
    'giftSettingInfo.body_1': function(newVal, oldVal){
      this.setSettingUpdateFlag(newVal, oldVal)
    },
    'giftSettingInfo.body_2': function(newVal, oldVal){
      this.setSettingUpdateFlag(newVal, oldVal)
    },
    'giftSettingInfo.gift_id': function(newVal, oldVal){
      this.setSettingUpdateFlag(newVal, oldVal)
    },
  },
  methods: {
    ...mapActions('union', [
      'commitGiftSettingId', 'commitGiftTargetId',
      'commitGiftSettngInfo', 'commitServiceCode',
      'resetGiftSettngInfo'
      ]),

    fetchGiftSettingInfo: function () {
      if (this.getGiftSettngInfo) {
        this.restoregiftSettingInfo()
        if (this.giftSettingInfo.status !== '設定中') {
          // 設定中以外は読込専用フラグを立てて、画面上の項目を入力不可とする
          this.isReadOnly = true
        }
        return
      }

      const params = {gift_setting_id: this.giftSettingId}
      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/gift/setting/info', {params})
        .then((res) => {
          if (res.data.status == 'success') {
            this.giftSettingInfo = res.data.value
            this.setGiftTargetId(this.giftSettingInfo.gift_target_id)
            if (this.giftSettingInfo.status !== '設定中') {
              // 設定中以外は読込専用フラグを立てて、画面上の項目を入力不可とする
              this.isReadOnly = true
            }
          }
        })
        .finally(() => {})
    },
    fetchGiftList: function () {
      const params = {team_id: this.team_id}
      this.giftList = []
      // メッセージ一覧取得API call
      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/gift/list', {params})
        .then((res) => {
          if (res.data.status == 'success') {
            this.giftList = res.data.value.gift_list
          }
        })
        .finally(() => {})
    },
    fetchUserInfo: function () {
      this.restoregiftSettingInfo()
      return this.$axios.get('/api/' + this.$constants.API_VERSION + '/user/info').then((res) => {
        if (res.data.status == 'success') {
          this.giftSettingInfo.created_user = res.data?.value?.user?.name
          this.giftSettingInfo.gift_target_id = this.getGiftTargetId
        }
      })
    },
    async previewClick() {
      if (this.processing) return

      this.showPreview = true
      try {
        await this.fetchGiftPreviewSetting()
      } catch (e) {
        if (e.response && e.response.status === 400) {
          this.errorMsgList = e.response.data.value.errors
          this.showPreview = false
        } else {
          throw e
        }
      }
    },
    fetchGiftPreviewSetting: function () {
      this.processing = true
      this.tabData = []
      let params = {}
      const gift_target_id = this.getGiftTargetId
      if(gift_target_id){
        params = {...params, gift_target_id: gift_target_id}
      }
      params = {...params,
                title: this.giftSettingInfo.title,
                body_1: this.giftSettingInfo.body_1,
                body_2: this.giftSettingInfo.body_2,
                bind_flg: this.giftSettingInfo.bind_flg}

      return this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/gift/setting/preview', {...params})
        .then((res) => {
          if (res.data.status == 'success') {
            const now_datetime = this.$dayjs().format('YYYY/MM/DD HH:mm')
            if(res.data.value.preview_list && res.data.value.preview_list.length > 0) {
                const formatList = res.data.value.preview_list.map(preview => {
                  return {
                    avatar: res.data.value?.profile_path ?? '',
                    content: (preview?.body ?? ''),
                    user_id: preview?.user_id ?? '',
                    item: {title: preview?.title ?? '', now_datetime}
                  }
                })
                this.tabData = formatList
                return;
            }
          }
          this.tabData = []
        })
        .catch((e) => {
          throw e
        })
        .finally(() => {
          this.processing = false
        })
    },
    /** 入力中データの復元 */
    restoregiftSettingInfo() {
      if (this.getGiftSettngInfo) {
        const setting_info = this.getGiftSettngInfo
        this.giftSettingInfo.title = setting_info.title
        this.giftSettingInfo.body_1 = setting_info.body_1
        this.giftSettingInfo.body_2 = setting_info.body_2
        this.giftSettingInfo.bind_flg = setting_info.bind_flg
        this.giftSettingInfo.gift_id = setting_info.gift_id
        this.giftSettingInfo.created_user = setting_info.created_user
        this.giftSettingInfo.status = setting_info.status
        this.giftSettingInfo.is_setting_update = setting_info.is_setting_update
        return
      }
    },
    async initFetch() {
      this.initLoading = true
      if (this.getGiftSettingId) {
        this.setGiftSettingId(this.getGiftSettingId, false)
      }
      if (this.getGiftTargetId) {
        this.setGiftTargetId(this.getGiftTargetId, false)
      }
      try {
        if(this.giftSettingId){
          await Promise.all([
            this.fetchGiftSettingInfo(),
            this.fetchGiftList(),
          ])
        }else {
          await Promise.all([
            this.fetchUserInfo(),
            this.fetchGiftList(),
          ])
        }
      }
      finally {
        this.initLoading = false
      }
    },
    /** サービスコード保存処理 */
    setServiceCode() {
      const gift = this.giftList.find(gift => {return gift.gift_id == this.giftSettingInfo.gift_id})
      this.commitServiceCode({service_code: gift?.service_code})
    },
    sendListClick(){
      this.errorMsgList = []
      // 配布対象者候補の検索

      this.setServiceCode()
      // 入力データの保存
      this.commitGiftSettngInfo({gift_settng_info: this.giftSettingInfo})
      this.$router.push(`/union/gift_target/${this.team_id}`)
    },
    registClick: async function() {
      if (!this.giftTargetId) {
          this.errorMsgList.push({
            attribute: 'gift_target_id',
            message: '配布対象者リストを作成してください。'
          })
          return
      }

      if (this.processing) return

      this.errorMsgList = []
      this.successMsg = ''
      this.processing = true
      // 登録-更新条件 作成
      let params = { team_id: this.team_id,
                     gift_id: this.giftSettingInfo.gift_id,
                     title: this.giftSettingInfo.title,
                     body_1: this.giftSettingInfo.body_1,
                     body_2: this.giftSettingInfo.body_2,
                     bind_flg: this.giftSettingInfo.bind_flg }
      if(!this.isNewMode) {
        params = {...params, gift_setting_id: this.giftSettingId}
      }
      // メッセージ配信対象(gift_targets)テーブルのID追加
      if(this.getGiftTargetId){
        this.setGiftTargetId(this.getGiftTargetId, false)
        params = {...params, gift_target_id: this.giftTargetId}
      }
      try {
        // メッセージ送信設定登録・更新API call
        const result = await this.$axios.post('/api/' + this.$constants.API_VERSION + '/gift/setting/registration', params)
        if (result.data.status == 'success') {
            this.successMsg = `${this.isNewMode ? '登録' : '更新'}が完了しました。(メッセージ送信設定)`

            this.setGiftSettingId(result.data.value.gift_setting_id)
            this.resetGiftSettngInfo()

            this.giftSettingInfo.is_setting_update = false

            await this.initFetch()
        }
      } catch (e) {
        if (e.response && e.response.status === 400) {
          this.errorMsgList = e.response.data.value.errors;
        }
      } finally {
        this.processing = false
      }
    },
    sendClick: async function() {
      var confirm_result = confirm('メッセージを送信しますか？');
      if(!confirm_result) {
          return
      }

      if (this.processing) return
      if (!this.giftSettingId) return

      this.errorMsgList = []
      this.successMsg = ''
      this.processing = true

      try {
        // メッセージ送信実行API call
        const result = await this.$axios.post('/api/' + this.$constants.API_VERSION + '/gift/issue', {gift_setting_id: this.giftSettingId})
        if (result.data.status == 'success') {
            this.showSending = true //モーダル起動(送信レスポンス画面起動)
        }
      } catch (e) {
        if (e.response && e.response.status === 400) {
          this.errorMsgList = e.response.data.value.errors;
        }
        this.errorMsgList.push({message: '送信処理が失敗しました。'});
      } finally {
        this.processing = false
      }
    },
    giftSendListClick: function() {
      this.showSending = false
      this.$router.push(`/union/gift/${this.team_id}`);
    },
    setGiftSettingId(gift_setting_id, set_cookie = true) {
      this.giftSettingId = gift_setting_id
      if (set_cookie == true) {
        this.commitGiftSettingId({gift_setting_id})
      }
    },
    setGiftTargetId(gift_target_id, set_cookie = true) {
      this.giftTargetId = gift_target_id
      if (set_cookie == true) {
        this.commitGiftTargetId({gift_target_id})
      }
    },
    changeBindFlg() {
      // watchで変更検知だと画面初期表示時に更新フラグが立つためメソッドで設定
      this.giftSettingInfo.is_setting_update = true
    },
    setSettingUpdateFlag(newVal, oldVal) {
      if (oldVal != null) {
        if (newVal !==  oldVal) {
          this.giftSettingInfo.is_setting_update = true
          console.log('setSettingUpdateFlag')
          console.log(oldVal)
          console.log(newVal)
        }
      }
    },
    isFieldErrorMsg,
    hasError,
    hasFieldErrorMsg,
  },
  computed: {
    ...mapGetters('union', [
      'getGiftSettingId', 'getSendListSavedFlg',
      'getGiftSettngInfo', 'getGiftTargetId'
      ]),

    isNewMode() {
      //新規モード判定
      return !this.giftSettingId ? true : false;
    },
    showBtnSendList() {
      // 配布対象者リストを作成
      return this.giftTargetId == null
    },
    showAnchorSetting() {
      // 設定を確認
      return this.giftTargetId != null
    },
    giftItems() {
      if(this.giftList.length > 0){
        let items = [{label: '未選択', value: null}]
        items = items.concat(this.giftList.map((gift)=> {
          return {label: gift.value_code_name, value: gift.gift_id}
        }))
        return items
      } else {
        return []
      }
    },
    /** 差し込みデータのインポートエラーメッセージ */
    notImportedErrorMessages() {
      return this.errorMsgList.filter((err) => {
        return err.message.match(/差し込み/)
      })
    }
  },
  created() {
    this.initFetch()
  },
}
</script>

<style lang="scss" module>
.union_gift_setting {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 12px 0 50px 0;
  width: 960px;
}
.union_gift_setting_content {
  width: 100%;
  &.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}

.content_header {
  margin-bottom: 70px;
  &_title {
    font-size: 30px;
    margin: 0;
  }
}

.content_table_wrap {
  width: 100%;
  margin-bottom: 56px;
}

.content_table {
  width: 100%;
  border-spacing: 0;

  th {
    width: 156px;
    padding: 12px 0 0 0;
    vertical-align: top;
    text-align: left;
    font-size: 13px;
  }

  td {
    padding: 0;
  }

  .message_title {
    th, td {
      padding-bottom: 24px;
    }
  }

  .notice_before {
    th, td {
      padding-bottom: 30px;
    }
  }

  .notice_condition {
    th, td {
      padding-bottom: 30px;
    }
    .notice_condition_check {
      display: flex;
      [class^="BaseCheckBox_label"] {
        font-size: 13px;
        font-weight: bold;
      }
      p {
        margin: 0 0 0 98px;
        font-size: 13px;
      }
    }
  }

  .notice_after {
    th, td {
      padding-bottom: 44px;
    }
  }

  .charge {
    th, td {
      padding-bottom: 40px;
      border-bottom: 1px solid #707070;
    }
    td {
      font-size: 13px;
      font-weight: bold;
    }
  }

  .attach_gift {
    th {
      padding-top: 42px;
      padding-bottom: 50px;
    }
    td {
      padding-top: 30px;
      padding-bottom: 50px;
    }
    span {
      margin-left: 16px;
      color: $keyPink;
    }
    select {
      width: 100%;
    }
  }

  .gift_to {
    a {
      color: $KeyDarkGreen;
      margin-left: 36px;
      line-height: 40px;
      &:visited {
        color: $KeyDarkGreen;
      }
    }
    p {
      margin-top: 18px;
      margin-bottom: 0;
      font-size: 13px;
      font-weight: bold;
    }
  }
}

.bottom_action_button {
  display: flex;
  justify-content: center;
  *:not(:first-child){
    margin-left: 32px;
  }
}

.bottom_action_comment {
  font-size: 13px;
  text-align: center;
  font-weight: bold;
}

.preview_modal {
  [class^="BaseModal_modal_content_body"] {
    background-color: #2DCCD3;
  }
  [class^="BaseModal_modal_content_header"] {
    height: 32px;
  }
}

.preview_modal_content {
  padding: 0 12px 52px 12px;
}

.sending_modal_content {
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &_header {
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 24px;
  }

  &_description {
    margin-top: 0;
    margin-bottom: 64px;
    font-size: 11px;
  }

  &_download_icon {
    margin-bottom: 116px;
    img {
      width: 58px;
      height: 58px;
    }
  }
}
.msg_area {
  width: 100% !important;
  margin: 0 0 10px;
}
</style>
