<template>
  <div>
    <label :class="[ $style.checkbox, { [$style.checkbox_disabled]: isDisabled }]">
      <input
        :class="$style.check"
        v-bind="$attrs"
        :value="value"
        v-model="boundChecked"
        type="checkbox"
      />

      <span :class="$style.box" />
      <span v-if="$slots['default']" :class="$style.label">
        <slot />
      </span>
    </label>
    <p v-if="!!errorText" :class="$style.error_txt">{{ errorText }}</p>
  </div>
</template>

<script>
export default {
  name: "BaseCheckBox",
  inheritAttrs: false,
  model: {
    prop: "checked",
    event: "update",
  },
  props: {
    value: {
      type: [String, Number],
    },
    checked: {
      type: [Boolean, Array, Number],
    },
    /**
     * エラー時の文言を指定するとエラー表示になります
     * String: そのままエラー文として表示します。空文字列は無視されます
     * Boolean: trueならエラー文なしで枠のみがエラー表示になります。
     */
    error: {
      type: [String, Boolean],
      default: '',
    },
  },
  computed: {
    isDisabled() {
      return this.$attrs['disabled'] === 'disabled' ||
        this.$attrs['disabled'] === true ||
        this.$attrs['disabled'] === 'true'
    },
    boundChecked: {
      get() {
        // console.log("checkbox get", { this: this });
        return this.checked;
      },
      set(value) {
        // console.log("checkbox set", { this: this, value });
        this.$emit("update", value);
      },
    },
    errorText() {
      return typeof this.error === 'string' ? this.error : ''
    },
  },
};
</script>

<style lang="scss" module>
$baseSize: 20px;
$baseBorder: 2px;
// $baseSpacing: 4px;
$textSpacing: 9px;
$fontSize: 16px;
$checkSize: 6px;
$checkBorder: 2px;
$checkTransX: 2px;
$checkTransY: -1px;
$baseColor: #b7b7b7;
$activeColor: #e94c89;
$checkColor: #fff;
$disabledColor: #DDDDDD;

.checkbox {
  // display: block;
  // padding: $baseSpacing;
  display: flex;
  align-items: center;
  font-size: $fontSize;
  cursor: pointer;

  .box {
    display: inline-flex;
    background: #fff;
    justify-content: center;
    align-items: center;
    min-width: $baseSize;
    min-height: $baseSize;
    border: $baseBorder solid $baseColor;
    border-radius: 4px;
    vertical-align: middle;
    box-sizing: border-box;
    transition: all 0.1s linear;

    &:before {
      content: "";
      display: block;
      width: $checkSize;
      height: calc($checkSize / 2);
      border-left: $checkBorder solid $checkColor;
      border-bottom: $checkBorder solid $checkColor;
      opacity: 0;
      transition: all 0.1s linear;
      transform: rotate(-90deg) translate($checkTransX, $checkTransY);
    }
  }

  &_disabled {
    .box {
      border-color: $disabledColor;
    }
    .label {
      color: $disabledColor;
    }
    .check:checked {
      + .box {
        background-color: $disabledColor !important;
        border-color: $disabledColor !important;
      }
    }
  }

  .label {
    margin-left: $textSpacing;
  }

  .check {
    display: none;

    &:checked {
      + .box {
        // font-weight: bold;
        background-color: $activeColor;
        border-color: $activeColor;
        &:before {
          opacity: 1;
          transform: rotate(-45deg) translate($checkTransX, $checkTransY);
        }
      }
    }
  }
}

.error_txt {
  font-size: 13px;
  margin: 4px 0 0 0 !important;
  color: #f00;
}
</style>
