<template>
  <section :class="$style.union_gift_target">
    <div :class="$style.union_gift_target_content">
      <app-prev-button @click="$router.push({ name: 'UnionGiftSetting', params: {team_id}})"/>

      <div :class="$style.filter_wrap" v-if="!isReadOnly">
        <div :class="$style.filter_header">
          <h1 :class="$style.filter_header_title">配布対象者候補の検索</h1>
        </div>
        <div :class="$style.filter">
          <div :class="$style.filter_table_container">
            <table :class="$style.filter_table_left">
              <tr>
                <th>JU-ID</th>
                <td><base-input look="outline2" v-model="searchCondition.juId" /></td>
              </tr>
              <tr>
                <th>名前/ニックネーム</th>
                <td><base-input look="outline2" v-model="searchCondition.nameOrNickname" /></td>
              </tr>
              <tr>
                <th>サービスコード</th>
                <td><base-input look="outline2" v-model="searchCondition.serviceCode" /></td>
              </tr>
              <tr>
                <th>メッセージ</th>
                <td>
                  <base-select
                    look="outline2"
                    v-model="searchCondition.gift"
                    :options="giftStatus"
                  />
                </td>
              </tr>
              <tr>
                <th>権限</th>
                <td>
                  <div :class="$style.filter_check_row_group">
                    <template v-for="auth of authorities">
                      <base-check-box name="auth"
                        v-model="searchCondition.authority"
                        :value="auth.value"
                        :key="auth.value">{{ auth.label }}</base-check-box>
                    </template>
                  </div>
                </td>
              </tr>
            </table>
            <table :class="$style.filter_table_right">
              <tr>
                <th>回答アンケート</th>
                <td>
                  <base-select
                    :class="$style.select_enquete"
                    v-model="searchCondition.selectEnquete"
                    :options="enqueteItems"
                    :multiple="false"
                    size="4"
                    @update="enqueteSelected"
                  ></base-select>
                </td>
              </tr>
              <tr>
                <th>回答の選択肢</th>
                <td>
                  <base-select
                    :class="$style.select_choices"
                    v-model="searchCondition.selectChoices"
                    multiple
                    :options="voteChoiceItems"

                  ></base-select>
                </td>
              </tr>
            </table>
          </div>
          <div :class="$style.filter_action_button">
            <base-button
              rounded="rounded"
              width="120px"
              bordered
              @click="clickReset"
            >条件リセット</base-button>
            <base-button
              rounded="rounded"
              width="120px"
              @click="clickSearch"
            >検索</base-button>
          </div>
        </div>
      </div>
      <div :class="$style.list_table_wrap">
        <div :class="$style.list_table_header">
          <h1 :class="$style.list_table_header_title">配布対象者一覧</h1>
          <div :class="$style.list_table_header_total">対象として選択されている対象者<span>{{ tableSelectedNum }}</span>&nbsp;件</div>
          <div :class="$style.list_table_header_button">
            <base-button
              width="266px"
              rounded="rounded"
              @click="scrollToBottom"
              >一番下の検索結果に遷移</base-button>
          </div>
        </div>
        <div :class="$style.list_table">
          <app-table-list
            :col-defs="tableColDefs"
            :row-data="tableRowData"
            :row-key="rowKey"
            :selected="tableSelected"
            :page="null"
            :page-data-num="null"
            :show-page-list="false"
            :message="tableMessage"
            :selectable="!isReadOnly"
            v-on="$listeners"
            @select="handleSelect"
          >
          </app-table-list>
        </div>
        <template v-if="hasError(errorMsgList) && hasFieldErrorMsg(errorMsgList, [])">
          <base-message type="error" v-for="(error, i) in errorMsgList" :key="i" :class="$style.msg_area">{{ error.message }}</base-message>
        </template>
        <base-message type="success" :class="$style.msg_area" v-if="successMsg">{{successMsg}}</base-message>
      </div>
      <div :class="$style.bottom_action_button" v-if="!isReadOnly">
        <base-button
          width="266px"
          rounded="rounded"
          @click="showInsert = true"
          v-if="this.tableSelectedNum > 0"
        >
        差し込み文章を設定・編集する
        </base-button>
        <base-button
          width="185px"
          rounded="rounded"
          bg-color="pink"
          @click="register"
        >保存</base-button>
      </div>
      <p :class="$style.bottom_description" v-if="!isReadOnly">※差し込み文章設定後、一覧を変更することはできません。<br/>一覧のリストに変更があった場合は再度差し込み文章の設定をやりなおしてください。</p>
    </div>

    <base-modal
      v-if="showInsert"
      @close="showInsert = false"
      width="998px"
      type="round"
      position="top"
      padding-top="40px"
    >
    <!-- padding-top="140px" より変更 -->
      <template v-slot:body>
        <div :class="$style.modal_content">
          <div :class="$style.modal_header">
            <h1 :class="$style.modal_header_title">差し込みデータを作成</h1>
            <p :class="$style.modal_header_caption">詳しくは別途お渡ししておりますマニュアルをご参照ください。</p>
          </div>
          <p :class="$style.modal_description">
            手順①<br/>
            下記ボタン「対象者リストのダウンロード」から対象者リスト(CSV)をダウンロードし、<br/>
            4列目以降に対象ユーザ毎に差し込み情報を入力してください。<br/>
            列ごとにリストの改行が行われます。10例以内の情報に対応しています。
          </p>
          <div :class="$style.modal_action_button">
            <base-button width="328px" rounded="rounded" @click="csvDownload">対象者リストのダウンロード</base-button>
          </div>
          <p :class="$style.modal_description">
            手順②<br/>
            編集したリストを下記ボタン「編集したリストをアップロード」からアップロードしてください。<br/>
            列に空欄のデータがある場合、エラーとしてチェックされます。<br/>
            メッセージ送信設定時にプレビューでよく確認してください。<br/>
          </p>
          <div :class="$style.modal_action_button">
            <base-button width="328px" rounded="rounded" @click="csvUpload">編集したリストをアップロード</base-button>
          </div>
          <template v-if="hasError(coErrorMsgList) && hasFieldErrorMsg(coErrorMsgList, [])">
            <base-message type="error" v-for="(error, i) in coErrorMsgList" :key="i" :class="$style.msg_area">{{ error.message }}</base-message>
          </template>
          <base-message type="normal" :class="$style.msg_area" v-if="uploadMsg">{{uploadMsg}}</base-message>
          <div :class="$style.modal_bottom_action_button">
            <base-button width="328px" rounded="rounded" @click="uploadData">保存</base-button>
          </div>
        </div>
      </template>
    </base-modal>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BaseButton from '@/components/base/BaseButton/BaseButton'
import BaseInput from '@/components/base/BaseInput/BaseInput'
import BaseSelect from '@/components/base/BaseSelect/BaseSelect'
import BaseCheckBox from '@/components/base/BaseCheckBox/BaseCheckBox'
import AppPrevButton from '@/components/app/AppPrevButton/AppPrevButton'
import AppTableList from '@/components/app/AppTableList/AppTableList'
import BaseModal from '@/components/base/BaseModal/BaseModal'
import BaseMessage from '@/components/base/BaseMessage/BaseMessage'
import { isFieldErrorMsg, hasError, hasFieldErrorMsg } from '@/helper/validator.js'

import {
  fetchTeamChatList,
  fetchTeamChatInfo,
  fetchGiftSettingSendList,
  fetchGiftSettingSendUserList,
  editGiftSettingSend,
  downloadGiftSettingSendBind,
  uploadGiftSettingSendBind,
  editGiftSetting,
} from '@/helper/common.js'

export default {
  name: 'UnionGiftTarget',
  components: {
    BaseButton,
    BaseInput,
    BaseSelect,
    BaseCheckBox,
    AppPrevButton,
    AppTableList,
    BaseModal,
    BaseMessage,
  },
  created: async function () {
    this.$router.app.$emit('show-spinner', true)
    await this.init()
    this.searchCondition.serviceCode = this.getServiceCode
    this.searchCondition.gift = "not"
    this.$router.app.$emit('show-spinner', false)
  },
  mounted() {
    this.$router.app.$emit('page-updated', {
      pageTitle: 'メッセージ送信',
      back: '/union/gift_setting/' +this.team_id,
      headerColor: 'white',
      showSetting: false,
      bgColor: 'gray'
    })
  },
  props: {
    team_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showInsert: false,
      /** 検索条件 */
      searchCondition: {
        juId: null,
        nameOrNickname: null,
        serviceCode: null,
        gift: null,
        authority: [],
        /** chat_id */
        selectEnquete: null,
        selectChoices: [],
      },
      tableColDefs: [
        {
          key: "ju_id",
          label: "JU-ID",
          sortable: true
        },
        {
          key: "name",
          label: "名前",
        },
        {
          key: "nickname",
          label: "ニックネーム",
        },
        {
          key: "authority",
          label: "権限",
        },
        {
          key: "login",
          label: "最終ログイン",
        },
        {
          key: "setting",
          label: "差し込みデータ設定",
        },
      ],
      rowKey: 'user_id',
      tableSelected: [],
      uploadFileName: '',
      uploadFileData: '', // BASE64エンコード
      teamChatList: [],
      teamChatInfo: {},
      userList: [],
      voteChoiceItems: [],
      errorMsgList: [],
      coErrorMsgList: [],
      successMsg: '',
      uploadMsg: '',
      tableMessage: '検索をやりなおしてください',
      isReadOnly: false,
    }
  },
  computed: {
    ...mapGetters('union', ['getGiftSettingId', 'getServiceCode', 'getGiftTargetId', 'getGiftSettngInfo']),

    tableSelectedNum() {
      return this.tableSelected.length;
    },
    giftStatus() {
      return [
        {
          label: '受取',
          value: 'receive'
        },
        {
          label: '未受取',
          value: 'not'
        },
      ]
    },
    authorities() {
      return [
        { label: "管理者", value: "admin" },
        { label: "共同管理者", value: "admin_auth" },
        { label: "メンバー", value: "user" },
      ]
    },
    enqueteItems() {
      const defaultItem = [{
        label: '指定なし',
        value: null,
      }]
      // this.$loginfo(this.teamChatList)
      if (this.teamChatList.length > 0) {
        return defaultItem.concat(this.teamChatList.map(chat => {
          return {
            label: chat.title,
            value: chat.chat_id,
          }
        }))
      } else {
        return defaultItem
      }
    },
    /** 配布対象者一覧 */
    tableRowData() {
      let convertAuth = function (value) {
        switch (value) {
          case "admin":
            return "管理者"
          case "admin_auth":
            return "共同管理者"
          case "user":
            return "メンバー"
          default:
            return ""
        }
      }

      let convertSetting = function (value) {
        switch (value) {
          case false:
            return "なし"
          case true:
            return "あり"
          default:
            return ""
        }
      }

      return this.userList.map(user => {
        return {
          ju_id: user.juid,
          name: user.name,
          nickname: user.nickname,
          authority: convertAuth(user.auth_type),
          login: user.last_login_timestamp,
          setting: convertSetting(user.bind_flg),
          user_id: user.user_id,
        }
      })
    },
  },
  methods: {
    ...mapActions('union', ['commitGiftTargetId', 'commitSendListSavedFlg', 'commitGiftSettingId']),

    handleSelect(selected) {
      this.tableSelected = selected;
    },
    /** 初期化処理 */
    async init() {
      const gift_target_id = this.getGiftTargetId
      // this.$loginfo(gift_target_id)
      if (gift_target_id) {
        await Promise.all([
          this.getTeamChatList(),
          this.getGiftSettingSendList(),
          this.setIsReadOnly()
        ])
      } else {
        await this.getTeamChatList()
      }
    },
    /** 回答アンケートのデータ取得 */
    async getTeamChatList() {
      const params = {
        team_id: this.team_id,
        type: "2", // ミニ投票
        display_type: "all",
      }
      const result = await fetchTeamChatList(params)
      if (result.status == 'success') {
        // 開発用
        if (result.value.chats.length > 0) {
          this.teamChatList = result.value.chats
        }
      }
    },
    async getTeamChatInfo(chat_id) {

      this.voteChoiceItems.splice(0)
      // アンケート選択後に取得する
      // アンケート初期値が先頭なので、初期表示でも取得する
      const result = await fetchTeamChatInfo({ chat_id: chat_id })
      // this.$loginfo(result)
      if (result.status == 'success') {
        this.teamChatInfo = result.value
      }

      if (this.teamChatInfo && this.teamChatInfo.vote_choices.length > 0) {
        this.voteChoiceItems = this.teamChatInfo.vote_choices.map(vote_choice => {
          return {
            label: vote_choice.choice,
            value: vote_choice.id,
          }
        })
      }
    },
    async setIsReadOnly() {
      const setting = this.getGiftSettngInfo
      console.log(setting)
      if (setting.status && setting.status !== "設定中") {
        // 新規作成 or 設定中 以外時は更新不可
        this.isReadOnly = true
      }
    },
    async getGiftSettingSendList() {
      // 編集時と保存後取得
      const result = await fetchGiftSettingSendList({ gift_target_id: this.getGiftTargetId })
      if (result.status == 'success') {
        this.userList = result.value
      }
      this.initSelected()
    },
    /** 検索ボタン押下後処理 */
    async clickSearch() {
      const params = {
        team_id: this.team_id,
        // 条件により追加
        gift_target_id: this.getGiftTargetId,
        // 以下ユーザー入力による
        juid: this.searchCondition.juId,
        name_nickname: this.searchCondition.nameOrNickname,
        service_code: this.searchCondition.serviceCode,
        gift: this.searchCondition.gift, // receive or not
        auth_type_list: this.searchCondition.authority,
        chat_id: this.searchCondition.selectEnquete,
        vote_choice_id_list: this.searchCondition.selectChoices,
      }

      // this.$loginfo(params)

      const result = await fetchGiftSettingSendUserList(params)
      if (result.status == 'success') {
        this.userList = result.value
      }
      this.initSelected()
    },
    /** リセット押下 */
    clickReset() {
      this.searchCondition.juId = null
      this.searchCondition.nameOrNickname = null
      this.searchCondition.serviceCode = null
      this.searchCondition.gift = null
      this.searchCondition.authority.splice(0)
      // enquete: []
      this.searchCondition.selectEnquete = null
      this.searchCondition.selectChoices.splice(0)
      this.voteChoiceItems = []
    },
    async enqueteSelected() {
      // 回答の選択肢の選択リセット
      this.searchCondition.selectChoices.splice(0)
      this.$loginfo(this.searchCondition.selectEnquete)
      if (this.searchCondition.selectEnquete) {
        await this.getTeamChatInfo(this.searchCondition.selectEnquete)
      } else {
        this.voteChoiceItems = []
      }
    },
    validateUserList(isModal = false) {
      this.errorMsgList.splice(0)
      this.coErrorMsgList.splice(0)
      if (this.tableSelected.length == 0) {
        const target = isModal? this.coErrorMsgList : this.errorMsgList
        target.push({
          attribute: "user_id_list",
          message: "配布対象者を選択してください。",
        })
        return false
      }
      return true
    },
    async register() {
      if (!this.validateUserList()) return

      const params = {
        team_id: this.team_id,
        gift_target_id: this.getGiftTargetId, // nullの場合あり
        user_id_list: this.tableSelected // 一覧のチェックボックスから取得
      }

      const result = await editGiftSettingSend(params)
      // this.$loginfo(result)
      if (result.status == 'success') {
        // this.$loginfo(result.value.gift_target_id)
        // メッセージ配信対象ID設定
        this.commitGiftTargetId({gift_target_id: result.value.gift_target_id}),
        this.commitSendListSavedFlg({send_list_saved_flg: true})
        // this.$loginfo(this.gift.getGiftTargetId)

        await this.updateGiftSetting()

        this.$router.push(`/union/gift_setting/${this.team_id}`)
      } else if(result.errors) {
        this.errorMsgList = result.errors
        // this.$loginfo(this.errorMsgList)
      }
    },
    /** 配布対象者一覧の選択初期化 */
    initSelected () {
      const selected = this.userList.filter(user => user.select_flg).map(user => {return user.user_id})
      // this.$loginfo(selected)
      this.tableSelected.splice(0, this.tableSelectedNum, ...selected)
      // this.$loginfo(this.tableSelected)
    },
    /** gift_targetとgift_settingを紐づける */
    async updateGiftSetting() {
      if (this.getGiftSettingId != null) {
        const setting = this.getGiftSettngInfo
        const result = await editGiftSetting({
          team_id: this.team_id,
          gift_id: setting.gift_id,
          title: setting.title,
          body_1: setting.body_1,
          body_2: setting.body_2,
          bind_flg: setting.bind_flg,
          gift_target_id: this.getGiftTargetId,
          gift_setting_id: this.getGiftSettingId,
        })

        if (result.status == 'success') {
          this.commitGiftSettingId({gift_setting_id: result.value.gift_setting_id})
        }
      }
    },
    // 以下モーダルの処理
    /** CSVファイルダウンロード */
    async csvDownload() {
      const params = {
        team_id: this.team_id,
        user_id_list: this.tableSelected,
        gift_target_id: this.getGiftTargetId, // nullの場合あり
      }

      const result = await downloadGiftSettingSendBind(params)
      if (result.status == 'success') {
        let link = document.createElement('a')
        // this.$loginfo(result.value)
        link.download = result.value.file_name
        link.href = result.value.file_url
        link.click()
      }
    },
    /** CSVファイルアップロード */
    async csvUpload() {
      const _this = this
      const input_file = document.createElement("input")
      input_file.type = "file"

      _this.uploadMsg = ''

      input_file.onchange = function() {
        if (!input_file.value) return
        const files = input_file.files

        if (files.length > 1) return
        const file = files[0]
        if (file) {
          _this.uploadFileName = file.name
          // console.log(this.uploadFileName)

          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = function() {
            // console.log(reader.result)
            const data = reader.result.replace(/data:.*\/.*;base64,/, '')
            _this.uploadFileData = data
            // console.log(this.uploadFileData)

            _this.uploadMsg = 'ファイルを読込ました。下記ボタン「保存」で編集したリストのデータを保存します。'
          }

        }
      }
      input_file.click()
    },
    /** 差し込みデータアップロード */
    async uploadData() {
      if (!this.validateUserList(true)) return

      const params = {
        team_id: this.team_id,
        user_id_list: this.tableSelected,
        gift_target_id: this.getGiftTargetId, // nullの場合あり
        file_name: this.uploadFileName,
        file_data: this.uploadFileData,
      }

      // this.$loginfo(params)

      const result = await uploadGiftSettingSendBind(params)
      if (result.status == 'success') {
        // gift_target_idを設定
        // this.$loginfo(result.value.gift_target_id)
        await this.commitGiftTargetId({gift_target_id: result.value.gift_target_id})

        await this.updateGiftSetting()

        // データ再取得
        await this.init()
        this.successMsg = '差し込みデータ設定が完了しました。'

        // モーダルを閉じる
        this.showInsert = false
      } else if(result.errors) {
        this.coErrorMsgList = result.errors
      }
    },
    isFieldErrorMsg,
    hasError,
    hasFieldErrorMsg,
    scrollToBottom() {
      const element = document.documentElement
      const bottom = element.scrollHeight - element.clientHeight
      window.scroll(0, bottom)
    },
  },
}
</script>

<style lang="scss" module>
.union_gift_target {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 12px 0 68px 0;
  width: 960px;
}

.union_gift_target_content {
  width: 100%;
}

.filter_wrap {
  width: 100%;
  margin-bottom: 70px;
  .filter_header {
    margin-bottom: 26px;

    &_title {
      font-size: 30px;
      margin: 0 auto 0 0;
    }
  }

  .filter {
    width: 100%;
    background-color: $keyWhite;
    border: 2px solid $keyBlack;
    box-sizing: border-box;

    &_table {
      &_container {
        display: grid;
        grid-template: "left right" auto / 456px 1fr;
        padding: 28px 26px 28px 32px;
      }

      &_left, &_right {
        border-spacing: 0;
      }

      &_left {
        grid-area: left;
        text-align: left;
        height: 276px;
        tr:nth-child(-n+3) {
          th, td {
            padding-bottom: 10px;
          }
        }
        tr:nth-child(4) {
          th, td {
            padding-bottom: 26px;
          }
        }
        th {
          width: 156px;
          height: 40px;
          font-size: 13px;
          padding: 0;
        }
        td {
          width: 300px;
          padding: 0;
        }
        select {
          width: 100%;
        }
      }
      &_right {
        grid-area: right;
        text-align: right;
        tr:first-child {
          th, td {
            padding-bottom: 16px;
          }
        }
        th {
          width: 142px;
          padding: 10px 12px 0 0;
          font-size: 13px;
          vertical-align: top;
          box-sizing: border-box;
        }
        td {
          width: 300px;
          padding: 0;
        }
        .select_enquete {
          select {
            width: 100%;
            height: 140px
          }
        }
        .select_choices {
          select {
            width: 100%;
            height: 124px;
          }
        }
      }
    }

    &_check_row_group {
      display: flex;
      flex-wrap: wrap;
      & > * {
        margin-right: 36px;
      }

      & > *:last-child {
        margin-top: 12px;
      }
    }

    &_action_button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 70px;
      border-top: 1px solid #707070;
      box-sizing: border-box;

      & > *:last-child {
        margin-left: 30px;
      }
    }
  }
}

.list_table_wrap {
  width: 100%;
  margin-bottom: 50px;
  .list_table_header {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 26px;

    &_title {
      font-size: 30px;
      margin: 0 36px 0 0;
    }
    &_total {
      font-size: 16px;
      font-weight: bold;
    }
    &_button {
      margin-left: auto;
    }
  }
}

.bottom_action_button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  & > *:last-child {
    margin-left: 36px;
  }
}

.bottom_description {
  margin: 0;
  font-size: 13px;
  font-weight: bold;
  color: $fontBlack;
}

.modal_content {
  margin-top: -2px;
  padding: 0 42px 38px 42px;
  color: $fontBlack;

  .modal_header {
    display: flex;
    align-items: flex-end;
    margin-bottom: 28px;
    &_title {
      font-size: 30px;
      margin: 0;
    }
    &_caption {
      font-size: 16px;
      margin: 0;
      padding-left: 118px;
    }
  }

  .modal_description {
    font-size: 16px;
    margin: 0;
  }

  .modal_action_button {
    margin: 22px 0;
  }

  .modal_bottom_action_button {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 50px;
  }
}
.msg_area {
  width: 100% !important;
  margin: 0 0 10px;
}
</style>
