<template>
  <div :class="$style.union">
    <div :class="$style.chart_content_wrap">
      <h1 :class="$style.chart_content_title">あなたの学習ステータス</h1>

      <div :class="$style.dash_board">
        <div :class="$style.chart_content_chart">
          <app-status-chart
            :labels="['組合理解', '役割理解', '労働基礎', 'スキル']"
            :data="data"
            :average="average"
          />
        </div>
        <a @click="$router.push(`/home/home`)" :class="$style.dash_board_arrow_right">
          <span :class="$style.dash_board_arrow_right_title">HOME</span>
          <base-icon name="chevron-right" color="yellow" size="35px" />
        </a>
      </div>

      <div :class="$style.check_button_wrap">
        <base-button
          width="100%"
          height="30px"
          bg-color="yellow"
          color="black"
          rounded="rounded"
          @click="$router.push(`/union/leaning_status`)"
        >
          <span :class="$style.check_button_caption"
            >学習状況をチェックする</span
          >
        </base-button>
      </div>
    </div>

    <div :class="$style.list_accordion_wrap">
      <base-accordion title="注目の講座・イベント" type="borderless" expanded>
        <div :class="$style.post_item_list">
          <div
            v-for="featured_lecture in lecture_list"
            :class="$style.team_item_wrap"
            :key="featured_lecture.id"
          >
            <app-carousel-post-item
              :border-color="convertColor(featured_lecture.bar_color)"
              :head="featured_lecture.lecture_title"
              :foot="featured_lecture.course_name + ':' + featured_lecture.corner_name"
              :image="featured_lecture.lecture_path"
              :selected="Boolean(featured_lecture.good_flg)"
              @click="goToLecture(featured_lecture.lecture_id)"
              @select="
                goodChange(
                  featured_lecture.lecture_id,
                  featured_lecture.good_flg
                )
              "
            />
          </div>
        </div>
      </base-accordion>
    </div>

    <div :class="$style.list_accordion_wrap">
      <base-accordion title="受講可能なコーナー" type="borderless" expanded>
        <div :class="$style.post_item_list">
          <div
            v-for="corner in corner_list"
            :class="$style.team_item_wrap"
            :key="corner.corner_id"
            @click="goToCorner(corner.corner_id)"
          >
            <app-carousel-post-item
              border-color="#E2E2E2"
              :foot="corner.course_name"
              :head="corner.corner_name"
              :time="corner.corner_created_at"
              :image="corner.corner_path"
              :href="corner.corner_path"
            />
          </div>
        </div>
      </base-accordion>
    </div>

    <div :class="$style.list_accordion_wrap">
      <base-accordion title="サービスからのお知らせ" type="borderless" expanded>
        <div :class="$style.post_item_list">
          <div
            v-for="servicenews in servicenews_list"
            :class="$style.post_item_wrap"
            :key="servicenews.news_id"
          >
            <app-carousel-post-item
              :head="servicenews.title"
              :time="servicenews.created_at"
              :image="servicenews.path"
              border-color="#75B5E2"
              :foot="servicenews.team_name"
              @click="goToNews(servicenews.news_id)"
            />
          </div>
        </div>
      </base-accordion>
    </div>
  </div>
</template>

<script>
import AppStatusChart from '@/components/app/AppChart/AppStatusChart/AppStatusChart'
import BaseButton from '@/components/base/BaseButton/BaseButton'
import BaseIcon from '@/components/base/BaseIcon/BaseIcon'
import BaseAccordion from '@/components/base/BaseAccordion/BaseAccordion'
import AppCarouselPostItem from '@/components/app/AppCarousel/PostItem/AppCarouselPostItem'
export default {
  name: 'UnionIndex',
  components: {
    AppStatusChart,
    BaseButton,
    BaseIcon,
    BaseAccordion,
    AppCarouselPostItem,
  },
  mounted() {
    this.$router.app.$emit('page-updated', {
      pageTitle: 'MOVIE',
      showSetting: false,
      headerColor: 'white',
    })
  },
  data() {
    return {
      data: [0, 0, 0, 0],
      average: [0, 0, 0, 0],
      lecture_list: [],
      corner_list: [],
      servicenews_list: [],
    }
  },
  created: async function () {
    this.$router.app.$emit('show-spinner', true)
    await this.$axios
      .get('/api/' + this.$constants.API_VERSION + '/attendance_rate/list', {})
      .then((res) => {
        let data = [
          res.data.value.union,
          res.data.value.role,
          res.data.value.scene,
          res.data.value.skill,
        ]
        let average = [
          res.data.value.union_average,
          res.data.value.role_average,
          res.data.value.scene_average,
          res.data.value.skill_average,
        ]
        this.data = data
        this.average = average
      })
      .catch((err) => {
        if (err.response) {
          this.$logerror(err.response.data)
          this.$logerror(err.response.status) // 例：400
          this.$logerror(err.response.statusText) // Bad Request
          this.$logerror(err.response.headers)
        }

        this.$logerror(err)
      })

    await this.$axios
      .get(
        '/api/' + this.$constants.API_VERSION + '/lecture/list',
        {params:{
          attention_lecture_flg:1,
          unread_flg:0,
          sort:'updated_at',
          direction:'desc',
          limit:18
        }}
      )
      .then((res) => {
        this.lecture_list = res.data.value.lecture_list
      })
      .catch((err) => {
        if (err.response) {
          this.$logerror(err.response.data)
          this.$logerror(err.response.status) // 例：400
          this.$logerror(err.response.statusText) // Bad Request
          this.$logerror(err.response.headers)
        }

        this.$logerror(err)
      })

    await this.$axios
      .get('/api/' + this.$constants.API_VERSION + '/corner/list',
      {params:{
        sort:'created_at',
        direction:'desc',
        limit:500
      }})
      .then((res) => {
        this.corner_list = res.data.value
      })
      .catch((err) => {
        if (err.response) {
          this.$logerror(err.response.data)
          this.$logerror(err.response.status) // 例：400
          this.$logerror(err.response.statusText) // Bad Request
          this.$logerror(err.response.headers)
        }

        this.$logerror(err)
      })

    await this.$axios
      .get(
        '/api/' + this.$constants.API_VERSION + '/servicenews/list',
        {
          params:{
            sort:'created_at',
            direction:'desc',
            limit:500
          }
        }
      )
      .then((res) => {
        this.servicenews_list = res.data.value.servicenews_list
      })
      .catch((err) => {
        if (err.response) {
          this.$logerror(err.response.data)
          this.$logerror(err.response.status) // 例：400
          this.$logerror(err.response.statusText) // Bad Request
          this.$logerror(err.response.headers)
        }

        this.$logerror(err)
      })
      this.$router.app.$emit('show-spinner', false)
  },
  methods: {
    goToCorner(id) {
      this.$router.push(`/union/corner/${id}`)
    },
    goToLecture(lecture_id) {
      this.$router.push(`/union/course/${lecture_id}`)
    },
    goToNews(news_id) {
      this.$router.push(`/home/noticetext/${news_id}`)
    },
    goodChange(lecture_id, good_flg) {
      let url = ''
      let postdata = {}
      if (good_flg) {
        good_flg = 0
      } else {
        good_flg = 1
      }
      let filter = []
      url = '/api/' + this.$constants.API_VERSION + '/lecture_history/chat/favorite'
      let chat_users = []
      chat_users.push({
        lecture_id: lecture_id,
        good_flg: good_flg,
      })
      postdata = {
        chat_users: chat_users,
      }

      filter = this.lecture_list.filter(function (element) {
        return element['lecture_id'] == lecture_id
      })

      this.$axios
        .post(url, postdata)
        .then(() => {
          filter.forEach((element) => {
            if (good_flg == 1) {
              element['good_flg'] = true
            } else {
              element['good_flg'] = false
            }
          })
        })
        .catch((err) => {
          this.$logerror(err)
          alert(err.config)
        })
    },
    convertColor(color) {
      return `rgb(${color})`
    }
  },
}
</script>

<style lang="scss" module>
.union {
  display: flex;
  width: 960px;
  flex-direction: column;
  align-items: center;
  padding: 30px 0 50px 0;
  margin: 0 auto;
  overflow: hidden;

  @include mobile() {
    width: 100%;
    margin: 0;
    padding: 16px 16px 24px 16px;
  }
}

.chart_content_wrap {
  width: 100%;
  margin-bottom: 20px;

  @include mobile() {
    margin-bottom: 32px;
  }
}

.chart_content_title {
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  margin: 0 0 20px 0;

  @include mobile() {
    font-size: 11px;
    margin: 0 0 12px 0;
  }
}

.dash_board {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  margin-bottom: 16px;

  &_arrow_left,
  &_arrow_right {
    position: absolute;
    display: flex;
    align-items: center;
    height: 35px;
    top: 134px;
    color: $keyBlack;
    text-decoration: none;
    cursor: pointer;

    @include mobile() {
      top: 84px;
    }

    &_title {
      font-size: 13px;
      line-height: 35px;
      font-weight: bold;
      @include mobile() {
        display: none;
      }
    }
  }

  &_arrow_left {
    left: 0;
  }
  &_arrow_right {
    right: 0;
  }

  .chart_content_chart {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

.check_button_wrap {
  display: flex;
  margin-left: 62px;
  margin-right: 62px;

  .check_button_caption {
    font-size: 10px;
    color: $fontBlack;
  }
}

.list_accordion_wrap {
  width: 100%;
  margin-bottom: 10px;

  .post_item_list {
    padding: 0;
    margin: 10px 0 0 0;
    display: flex;
    flex-wrap: wrap;

    @include mobile() {
      flex-wrap: nowrap;
      overflow-y: scroll;
    }

    .post_item_wrap {
      margin-right: 14px;
      margin-bottom: 14px;
    }

    .team_item_wrap {
      margin-right: 20px;
      margin-bottom: 14px;
    }
  }
}
</style>
