<template>
  <div
    :class="[$style.header, { [$style[`bg_${this.bgColor}`]]: !!this.bgColor }]"
    :style="{backgroundImage: `url(${coverImage})`}"
  >
    <div :class="$style.sp_header_item">
      <div :class="$style.back_button_wrap">
        <div
            :class="$style.open_menu_content_header_close"
            @click.prevent.stop="backButtonAction(back)"
          >
            <img
              :class="$style.open_menu_content_header_close_icon"
              src="@/assets/images/times_black.svg"
              alt="閉じる"
            />
            <span :class="$style.app_menu_content_header_close_icon_text">閉じる</span>
          </div>
      </div>
      <div :class="$style.title_wrap">
        <span>{{ pageTitle }}</span>
      </div>
      <div :class="$style.menu_icon_wrap">
        <base-overlay-menu-list
          v-if="$slots['setting-menu'] && hasSetting"
          :show="showSetting"
          @show="(show) => $emit('show-setting', show)"
        >
          <slot name="setting-menu" />
        </base-overlay-menu-list>
      </div>
    </div>
    <div
      :class="$style.icon_wrap"
      @click="$router.push('/home/home', () => {})"
    >
      <img :class="$style.icon_logo" :src="logoPath" />
      <span :class="$style.icon_title">くみあいアプリ {{ branch_info }}</span>
    </div>
    <div :class="$style.home_button_wrap">
      <base-button
        width="auto"
        height="auto"
        :bgColor="bgColor"
        color="black"
        >{{ pageTitle }}</base-button
      >
    </div>
    <div
      v-if="$slots['setting-menu'] && hasSetting"
      :class="$style.menu_button_wrap"
    >
      <base-overlay-menu-list
        :show="showSetting"
        @show="(show) => $emit('show-setting', show)"
      >
        <template v-slot:menu-button>
          <base-button
            width="75px"
            height="32px"
            size="s"
            bgColor="white"
            color="black"
            rounded="shallow-corner"
            bordered
          >
            <base-icon name="ellipsis-h" :style="{ 'padding-right': '10px' }" />
            設定
          </base-button>
        </template>
        <slot name="setting-menu" />
      </base-overlay-menu-list>
    </div>
    <div v-if="showTabbar" :class="$style.tabbar_wrap">
      <app-home-menu v-if="isShowMenu" @close="isShowMenu = false" />
      <app-tabbar @menu="toggleShowMenu()" :news="showDots" :is-show-menu="isShowMenu" />
    </div>
  </div>
</template>
<script>
import AppTabbar from '../AppTabbar/AppTabbar'
import AppHomeMenu from '@/components/app/AppMenu/AppHomeMenu/AppHomeMenu'
import BaseButton from '@/components/base/BaseButton/BaseButton'
import BaseOverlayMenuList from '@/components/base/BaseOverlayMenuList/BaseOverlayMenuList'
export default {
  name: 'AppHeader',
  components: {
    BaseOverlayMenuList,
    AppTabbar,
    AppHomeMenu,
    BaseButton,
  },
  mounted() {
    this.getUserNotification()
  },
  props: {
    back: {
      type: [String, Object],
      default: '',
    },
    bgColor: {
      type: String,
      default: 'white',
      validator: (bg_color) =>
        !bg_color || ['white', 'yellow'].includes(bg_color),
    },
    pageTitle: {
      type: String,
      default: undefined,
    },
    coverImage: {
      type: String,
      default: '',
    },
    hasSetting: {
      type: Boolean,
      default: true,
    },
    showSetting: {
      type: Boolean,
      default: false,
    },
    showTabbar: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      navItem: {
        about: {
          label: '運営会社について',
          path: '/about',
          check: false,
        },
        faq: {
          label: 'よくある質問',
          path: '/faq',
          check: false,
        },
        contact: {
          label: 'お問い合わせ',
          path: '/contact',
          check: false,
        },
        teamsOfService: {
          label: '利用規約',
          path: '/teams-of-service',
          check: false,
        },
        privacyPolicy: {
          label: '個人情報保護方針',
          path: '/privacy-policy',
          check: false,
        },
      },
      isShowMenu: false,
      showDots: {
        home: false,
        notice: false,
        search: false,
        favorite: false,
        menu: false,
      },
      branch_info: process.env.VUE_APP_BRANCH_INFO,
    }
  },
  computed: {
    logoPath() {
      return require('@/assets/images/nav_logo1.svg')
    },
  },
  methods: {
    toggleShowMenu() {
      this.isShowMenu = !this.isShowMenu
    },
    getUserNotification() {
      this.$axios
        .get(
          '/api/' + this.$constants.API_VERSION + '/user/notification/unread',
          {
            params: {
              limit: 20,
            },
          }
        )
        .then((response) => {
          this.showDots.notice = Boolean(response.data.value.unread_flg)
        })
    },
    backButtonAction(back) {
      if (back === '__history_back' || back.force_history_back) {
        if (back.force_history_back) {
          let old_history_state = history.state
          old_history_state.from_name = null
          history.replaceState(old_history_state, '')
        }
        this.$router.go(-1)
      } else {
        this.$router.push(back, () => {})
      }
    },
  },
}
</script>
<style lang="scss" module>
.header {
  max-height: 70px;
  height: 70px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid $borderLiteGray;
  @media screen and (min-width: 768px) {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    // JSで動的にbg-colorを変更しているのを上書き
    background-color: white !important;
  }
  @media screen and (max-width: 768px) {
    height: 50px;
    background-color: $keyWhite;
    border-top: 2px solid $keyYellow;
  }
  &.bg_white {
    background-color: $keyWhite;
  }
  &.bg_yellow {
    background-color: $keyYellow;
  }
}
.icon_wrap {
  display: flex;
  align-items: center;
  margin-right: auto;
  margin-left: 32px;
  white-space: nowrap;
  cursor: pointer;
  display: none;
  .icon_logo {
    width: 26px;
    height: 26px;
    padding-right: 10px;
  }
  .icon_title {
    font-size: 13px;
    font-weight: bold;
  }
}
.bar_icon_wrap {
  display: none;
  @media screen and (max-width: 1080px) {
    display: block;
    margin-right: 20px;
    height: 18px;
  }
  .bar_icon {
    width: 27px;
    height: 18px;
  }
}
.nav_wrap {
  @media screen and (max-width: 1080px) {
    display: none;
  }
  height: 90px;
  display: flex;
  justify-content: flex-end;
  padding: 0;
  margin-right: 50px;
  box-sizing: border-box;
  .nav_item {
    display: block;
    font-size: 13px;
    line-height: 18px;
    font-weight: bold;
    text-align: center;
    margin: 36px 0 35px 30px;
    box-sizing: border-box;
    text-decoration: none;
    color: black;
    white-space: nowrap;
    &:first-child {
      margin-left: 0;
    }
    &:hover {
      text-decoration: underline $keyYellow 2px;
      text-underline-offset: 8px;
    }
  }
}
.home_button_wrap {
  margin-right: auto;
  flex: auto;
  margin-left: 65px;
  display: none;
}
.menu_button_wrap {
  margin-right: 15px;
  @media screen and (max-width: 1080px) {
    display: none;
  }
  z-index: 100; // ヘッダーの持つオーバーレイメニューをページのものよりも上にする
}
.login_button_wrap {
  font-size: 13px;
  font-weight: bold;
  margin-right: 30px;
  @media screen and (max-width: 1080px) {
    margin-right: 20px;
  }
}
.sp_header_item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 3em;
  @media screen and (max-width: 768px) {
    margin: 0;
  }
  .back_button_wrap {
    display: flex;
    width: 100px;
    height: 50px;
    justify-content: center;
    a {
      color: #525252;
    }
    align-items: center;
    background-color: white;
    filter: invert(100%);
    mix-blend-mode: difference;
  }
  .open_menu_content_header_close {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    cursor: pointer;
  }

  .open_menu_content_header_close_icon {
    width: 16px;
    height: 16px;
    margin-right: 8.5px;
  }

  .app_menu_content_header_close_icon_text {
    font-size: 11px;
    transform: scale(0.9);
    text-decoration:underline;
  }

  .title_wrap {
    font-size: 14px;
    font-weight: bold;
    background-color: white;
    filter: invert(100%);
    mix-blend-mode: difference;
    @media screen and (min-width: 768px) {
      margin-right: 30px;
      border-bottom: black solid 1px;
      font-weight: bold;
    }
  }
  .menu_icon_wrap {
    display: flex;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    z-index: 100; // ヘッダーの持つオーバーレイメニューをページのものよりも上にする
    @media screen and (min-width: 768px) {
      display: none;
    }
  }
}
.tabbar_wrap {
  background: #fff;
  box-sizing: border-box;
  border-top: 1px solid $borderLiteGray;
  width: 100%;
  position: fixed;
  bottom: 0em;
  z-index: 11;
  border-bottom: 1px solid $borderLiteGray;
  @media screen and (max-width: 768px) {
    border-bottom: 0px;
    bottom: 0px;
    left: 0px;
  }
}
</style>
