<template>
  <li :class="$style.menuItem">
    <router-link :class="$style.menuItemLink" v-bind="$attrs"><slot /></router-link>
  </li>
</template>
<script>
export default {
  inheritAttrs: false,
  name: 'BaseOverlayMenuListItem',
}
</script>
<style lang="scss" module>
.menuItem {
  margin: 17px 40px;
}
.menuItemLink {
  font-size: 13px;
  color: #707070;
  text-decoration: underline;
}
</style>
