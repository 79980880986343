<template>
  <div>
    <div :class="$style.team_header" :style="headerBgStyle">
      <div :class="$style.team_name">
        {{ teamName.length > 20 ? teamName.slice(0,20) + '...' : teamName }}
      </div>
      <div v-if="indexType">
        <team-icon  :team="team" :user_id="user_id" :belongsTo="isMobile" :indexType="isMobile"
          :informationDisplayNone="false"></team-icon>
      </div>
    </div>
    <div :class="[$style.team_header_detail, {[$style.show_sp_only] : hidePcHeaderDetail}]">
      <div :class="$style.team_header_detail_wrap">
        <router-link :to="`/team/info/${teamId}`">
          <base-avatar :class="$style.team_header_detail_avatar" :size="avatarSize" :color="teamColor"
            :border-color="teamColor" :path="badgeImage" />
        </router-link>

        <ul :class="$style.team_header_detail_meta">
          <li>メンバー数: {{ memberCount }}名</li>
          <li>Team-ID: {{ teamId }}</li>
          <li v-if="urlType.includes('1')">
            <a :class="$style.link" @click.stop="$router.push(`/home/team/${teamId}`)">関連URLを見る</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import BaseAvatar from "@/components/base/BaseAvatar/BaseAvatar";
import TeamIcon from '@/pages/team/components/TeamIcon'
import isMobile from "@/utils/mixins/isMobile";
import { mapMutations, mapGetters } from 'vuex'
import { autoLink } from '@/utils/helper.js'

export default {
  name: 'TeamHeader',
  mixins: [isMobile()],
  props: {
    team: {
      type: Object,
      default: () => {}
    },
    user_id: {
      type: Number
    },
    belongsTo: {
      type: Boolean,
      default: true
    },
    informationDisplayNone: {
      type: Boolean
    },
    indexType: {
      type: Boolean,
      default: false
    },
    hidePcHeaderDetail: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      processing: false,
      admin_notification_flg: false
    }
  },
  components: {BaseAvatar,TeamIcon },
  computed: {
    ...mapGetters('team'),
    avatarSize() {
      return this.isMobile ? '60px' : '120px'
    },
    headerBgStyle() {
      const teamCoverImg = this.team?.cover_image || ''
      return {
        backgroundImage: `url(${teamCoverImg})`,
      }
    },
    teamColor() {
      return this.team?.team_color || undefined
    },
    badgeImage() {
      return this.team?.badge_image || ''
    },
    memberCount() {
      return this.team?.member_count || 0
    },
    urlType() {
      return this.team?.url_type || ''
    },
    teamCode() {
      return this.team?.team_code || ''
    },
    teamId() {
      return this.team?.team_id || ''
    },
    teamProfile() {
      return this.team?.profile || ''
    },
    teamName() {
      return this.team?.name || ''
    }
  },
  methods: {
    ...mapMutations('team'),
    autoLink
  },
}
</script>
<style lang="scss" module>
.team_header {
  display: flex;
  justify-content: space-between;
  height: 255px;

  @include mobile() {
    height: 170px;
  }

  background-position: center;
  background-size: cover;

  &_detail {
    height: 80px;
    max-width: 980px;
    margin: 0 auto;
    background: #fff;
    border-bottom: 1px solid #DDDDDD;

    @include mobile() {
      display: block;
      width: auto;
      height: 40px;
    }

    &_wrap {
      display: flex;
      align-items: center;
      max-width: 720px;
      height: 100%;
      margin: 0 auto;

      @include mobile () {
        width: auto;
      }
    }

    &_avatar {
      margin-top: -45px;
      margin-left: 16px;
    }

    ul {
      list-style: none;
      padding: 0;
    }

    &_meta {
      flex: 1;
      font-size: 13px;
      margin: 0 16px;

      @include mobile() {
        font-size: 10px;
        transform: scale(0.9);
      }
    }

    &_utility {
      display: flex;
      align-items: flex-end;

      li {
        cursor: pointer;
        margin: 0 16px;
      }
    }

  }
}

.team_name{
  height: 100%;
  margin-left: 35px;
  display: flex;
  align-items: center;
  font-size: 30pt;
  font-weight: bold;
  color: #FFF;
  -webkit-text-stroke: 1px #ddd;
  @include mobile() {
    display: none;
  }
}

.heart {
  color: $borderLiteGray;
  ;

  &_active {
    color: $keyPink;
  }
}
.show_sp_only {
  display: none;
  @include mobile () {
    display: block;
  }
}
.link {
  color: $keyGreen;
  line-height: 1.5;
  font-size: 10px;
  text-decoration: underline;
  cursor: pointer;
}
</style>
