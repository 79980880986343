var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.wrapper},[_c('section',{class:_vm.$style.lead},[_c('div',{class:_vm.$style.cond_block},[_c('h1',{class:_vm.$style.head},[_c('img',{class:_vm.$style.logo,attrs:{"src":require("@/assets/images/open_logo.svg")}}),_vm._v(" くみあいアプリ ")]),_c('p',{class:_vm.$style.sub},[_vm._v(" みんなにとって労働組合が"),_c('br'),_vm._v(" 大事な「場」であるために ")]),_c('section',{class:_vm.$style.contents,attrs:{"id":"contents","name":"contents"}},[_c('p',[_vm._v(" 「私人」とも「会社人」とも違う、「組合員」として活動できる場所（サードプレイス）。今ある組合のつながりに、時代にあった活動の場を提供しましょう。 ")])]),_c('ul',{class:_vm.$style.store_icon},[_c('li',{class:_vm.$style.apple_icon},[_vm._m(0)]),_c('li',{class:_vm.$style.google_icon},[_vm._m(1)])])]),_c('div',{class:_vm.$style.stores},[_c('div',{class:_vm.$style.google_play},[_c('a',{class:_vm.$style.phone},[_c('img',{attrs:{"src":require("@/assets/images/HOME.png")}})])])])]),_c('div',_vm._l(([
        {
          main: '❝仲間❞をつなぐ',
          sub: '組合活動をスマホアプリに！',
          detail:
            'ホームページやSNSでは、組合活動が推進できないと感じていませんか？\r\nくみあいアプリには、組合活動と組合員をつなぐ「チーム機能」があります。\r\n仲間とつながる場所を用意して、情報発信しましょう。',
          gif: 'GIF_01_nakama',
        },
        {
          main: '❝声❞をつなぐ',
          sub: 'みんなの声を集める！',
          detail:
            '選択式の投票機能を使えば、日時の都合伺いや企画アイデアへの投票、クイズイベントなどが行えます。組合として大事にしたい、組合員さんの声を集めましょう。',
          gif: 'GIF_02_koe',
        },
        {
          main: '❝場所❞をつなぐ',
          sub: 'プッシュ通知でお知らせ！',
          detail:
            'プッシュ通知で最新の投稿をお知らせします。\r\n\組合からの大事なお知らせや、仲間の声がアプリに届くことで、仲間とのつながりをより身近に感じることができます。',
          gif: 'GIF_03_basho',
        },
        {
          main: '❝活動❞をつなぐ',
          sub: 'オンライン学習でつなぐ！',
          detail:
            'オンラインでの教宣活動には動画の活用が欠かせません。\r\n\くみあいアプリに組み込まれた学習動画の配信機能を活用することで、より親しみやすく、効果的な組合活動を展開できます。\r\n \r\n1 ---- 仲間をつなぐ\r\n2 ---- 声をつなぐ\r\n3 ---- 場所をつなぐ\r\n4 ---- 活動をつなぐ',
          gif: 'GIF_04_katsudo',
        } ]),function(i){return _c('section',{key:i,class:_vm.$style.feature},[_c('picture',{class:[_vm.$style.picture, _vm.$style.pc]},[_c('img',{attrs:{"src":require(("@/assets/images/" + (i.gif) + ".gif")),"alt":i.gif}})]),_c('div',{class:_vm.$style.cond_block},[_c('div',{class:_vm.$style.head},[_c('div',{class:_vm.$style.block},[_c('h2',{class:_vm.$style.main},[_vm._v(_vm._s(i.main))]),_c('p',{class:_vm.$style.sub},[_vm._v(_vm._s(i.sub))])])]),_c('picture',{class:[_vm.$style.picture, _vm.$style.sp]},[_c('img',{attrs:{"src":require(("@/assets/images/" + (i.gif) + ".gif")),"alt":i.gif}})]),_c('p',{class:_vm.$style.detail},[_vm._v(" "+_vm._s(i.detail)+" ")])])])}),0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"href":"https://apps.apple.com/jp/app/%E3%81%8F%E3%81%BF%E3%81%82%E3%81%84%E3%82%A2%E3%83%97%E3%83%AA/id1631089204"}},[_c('img',{attrs:{"src":require("@/assets/images/open_budge_app_store.svg"),"alt":"App Soreからダウンロード"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"href":"https://play.google.com/store/apps/details?id=com.j_union.KumiaiApp"}},[_c('img',{attrs:{"src":require("@/assets/images/open_budge_google_play.png"),"alt":"Google Playで手に入れよう"}})])}]

export { render, staticRenderFns }