
export default {
  namespaced: true,
  state: {
    display_type: 'all',
    team_user_event_flg: 0, // 組織ユーザー通知フラグ
    /** チームID (ページとセットで保持) */
    team_id: null,
    /** チームHOMEの選択ページ */
    team_current_page: 1,
    team_current_page_sso: 1,
    team_current_page_team: 1,
    /** 投稿ID (ページとセットで保持) */
    chat_id: null,
    /** 投稿詳細の選択ページ */
    team_post_current_page: 1,
    /** 投票詳細の選択ページ */
    team_vote_current_page: 1,
    /** 組織ユーザーのいいねフラグ */
    team_good_flg:0,
  },
  mutations: {
    /**
     * 投稿表示タイプ設定
     * @param {*} state
     * @param {*} payload all:全部/unread:未読のみ
     */
    setDisplayType(state, payload) {
      state.display_type = payload
    },
    /**
     * 組織ユーザー通知フラグ設定
     * @param {*} state
     * @param {*} payload 0:未通知/1:通知済
     */
    setTeamUserEventFlg(state, payload) {
      state.team_user_event_flg = payload
    },
    setTeamId(state, payload) {
      state.team_id = payload.team_id
    },
    initTeamCurrentPage(state) {
      state.team_current_page = 1
      state.team_current_page_sso = 1
      state.team_current_page_team = 1
    },
    initLectureCurrentPage(state) {
      state.lecture_current_page = 1
    },
    setTeamCurrentPage(state, payload) {
      state.team_current_page = payload.page
    },
    setLectureCurrentPage(state, payload) {
      state.lecture_current_page = payload.page
    },
    setChatId(state, payload) {
      state.chat_id = payload.chat_id
    },
    initTeamPostCurrentPage(state) {
      state.team_post_current_page = 1
    },
    setTeamPostCurrentPage(state, payload) {
      state.team_post_current_page = payload.page
    },
    initTeamVoteCurrentPage(state) {
      state.team_vote_current_page = 1
    },
    setTeamVoteCurrentPage(state, payload) {
      state.team_vote_current_page = payload.page
    },
    setTeamGoodFlg(state, payload) {
      state.team_good_flg = payload
    },
  },
  getters: {
    /**
     * 投稿表示タイプ取得
     * @param {*} state
     * @returns all:全部/unread:未読のみ
     */
    getDisplayType (state) {
      return state?.display_type
    },
    /**
     * 組織ユーザー通知フラグ取得
     * @param {*} state
     * @returns 0:未通知/1:通知済
     */
    getTeamUserEventFlg(state) {
      return state?.team_user_event_flg
    },
    /**
     * 組織ユーザーいいね取得
     * @param {*} state
     * @returns 0:OFF 1:ON
     */
    getTeamGoodFlg(state) {
      return state?.team_good_flg
    },
    /**
     * チームHOMEで選択中のページを取得する
     * @param {*} state
     * @param {*} id 遷移先ページのチームID
     * @returns ページ番号
     */
    getTeamCurrentPage: (state) => (id) => {
      if (state) {
        if (state.team_id === id) {
          return state.team_current_page
        } else {
          return 1
        }
      } else {
        return 1
      }
    },
    getTeamCurrentPageSso: (state) => (id) => {
      if (state) {
        if (state.team_id === id) {
          return state.team_current_page_sso
        } else {
          return 1
        }
      } else {
        return 1
      }
    },
    getTeamCurrentPageTeam: (state) => (id) => {
      if (state) {
        if (state.team_id === id) {
          return state.team_current_page_team
        } else {
          return 1
        }
      } else {
        return 1
      }
    },
    /**
     * チームHOMEで選択中の講座ページを取得する
     * @param {*} state
     * @param {*} id 遷移先ページのチームID
     * @returns ページ番号
     */
    getLectureCurrentPage: (state) => (id) => {
      if (state) {
        if (state.team_id === id) {
          return state.lecture_current_page
        } else {
          return 1
        }
      } else {
        return 1
      }
    },
    /**
     * 投稿詳細で選択中のページを取得する
     * @param {*} state
     * @param {*} id 遷移先ページの投稿ID
     * @returns ページ番号
     */
    getTeamPostCurrentPage: (state) => (id) => {
      if (state) {
        if (state.chat_id === id) {
          return state.team_post_current_page
        } else {
          return 1
        }
      } else {
        return 1
      }
    },
    /**
     * 投票詳細で選択中のページを取得する
     * @param {*} state
     * @param {*} id 遷移先ページの投稿ID
     * @returns ページ番号
     */
    getTeamVoteCurrentPage: (state) => (id) => {
      if (state) {
        if (state.chat_id === id) {
          return state.team_vote_current_page
        } else {
          return 1
        }
      } else {
        return 1
      }
    },
  },
  actions: {
    commitTeamId({ commit }, payload) {
      return commit('setTeamId', payload)
    },
    commitTeamCurrentPage({ commit }, payload) {
      return commit('setTeamCurrentPage', payload)
    },
    commitChatId({ commit }, payload) {
      return commit('setChatId', payload)
    },
    commitTeamPostCurrentPage({ commit }, payload) {
      return commit('setTeamPostCurrentPage', payload)
    },
    commitTeamVoteCurrentPage({ commit }, payload) {
      return commit('setTeamVoteCurrentPage', payload)
    },
    commitLectureCurrentPage({ commit }, payload) {
      return commit('setLectureCurrentPage', payload)
    }
  }
}
