<template>
  <div :class="$style.wrapper">
    <base-circle-button
      size="70px"
      bgColor="green"
      color="white"
      :bordered="true"
      :shadow="true"
      @click="() => $emit('post')"
      v-if="showPost"
    >
      <div
        :style="{
          'display':'flex',
          'flex-direction':'column',
          'align-items': 'center'
        }"
      >
        <span :style="{'padding-bottom': '4px'}">
          <base-icon size="17px" name="pencil-alt" color="yellow"/>
        </span>
        <span :style="{'line-height':'16px'}">投稿</span>
      </div>
    </base-circle-button>
    <base-circle-button
      size="70px"
      bgColor="green"
      color="white"
      :bordered="true"
      :shadow="true"
      @click="() => $emit('vote')"
      v-if="showVote"
    >
      <div
        :style="{
          'display':'flex',
          'flex-direction':'column',
          'align-items': 'center'
        }"
      >
        <span :style="{'padding-bottom': '4px'}">
          <base-icon :style="{'padding-right':'4px'}" size="12px" name="check-square" color="yellow"/>
          <base-icon size="12px" type="far" name="square" color="yellow"/>
        </span>
        <span :style="{'line-height':'16px'}">ミニ投票</span>
      </div>
    </base-circle-button>
  </div>
</template>
<script>
import BaseIcon from "@/components/base/BaseIcon/BaseIcon"
import BaseCircleButton from "@/components/base/BaseCircleButton/BaseCircleButton"

export default {
  components: { BaseCircleButton, BaseIcon },
  props: {
    showPost: {
      type: Boolean,
      default: true
    },
    showVote: {
      type: Boolean,
      default: true
    },
  },
}
</script>
<style lang="scss" module>
.wrapper {
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 126px;
  z-index: 1;
  width: 170px;
  left: 50%;
  transform: translateX(-50%);
  @media (max-width: 768px) {
    bottom: 48px; // 大画面はAppFooterがある
  }

  @include mobile() {
    bottom: 78px; // 小画面はAppTabbarがある
  }

  & > *:not(:last-child) {
    margin-right: 30px;
  }
}
</style>
