<template>
  <transition
    :name="$style.overlay"
    :enter-class="$style.overlay_enter"
    :enter-active-class="$style.overlay_enter_active"
    :enter-to-class="$style.overlay_enter_to"
    :leave-class="$style.overlay_leave"
    :leave-active-class="$style.overlay_leave_active"
    :leave-to-class="$style.overlay_leave_to"
    @leave="closing"
    appear="appear"
  >

    <div :class="$style.overlay">

      <section
        :class="$style.overlay_content"
      >

        <div :class="$style.overlay_content_header">
          <div
            :class="isActive ? $style.overlay_content_header_close : $style.overlay_content_header_close_disabled"
            @click.prevent.stop="$emit('close')"
          >
            <img src="@/assets/images/times.svg" alt="閉じる">
          </div>
        </div>

        <div :class="$style.overlay_content_body">
          <div :class="$style.overlay_content_body_inner">
            <slot name="body"></slot>
          </div>
        </div>

      </section>

      <div :class="$style.overlay_bg"></div>

    </div>
  </transition>
</template>
<script>
export default {
  name: 'BaseVideoOverlay',
  inheritAttrs: false,
  components: {},
  props: {},
  computed: {},
  methods: {
    closing(el, done) {
      setTimeout(() => {
        done()
        // 講座再生のモーダルを閉じた後1秒間再生ボタンをクリックできなくする
      }, 1000)
    }
  },
  data() {
    return {
      isActive: false,
    }
  },
  mounted() {
    setTimeout(() => {
      this.isActive = true
    }, 1000);
  }
}
</script>
<style lang="scss" module>
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 0 150px;
  box-sizing: border-box;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @include mobile() {
    padding: 0;
  }
}

.overlay_position_top {
  margin-top: 32px;
}
.overlay_position_center {
  align-items: center;
}

.overlay_content {
  position: relative;
  top: 0;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  transition: 0.4s;
  transition-delay: 0.2s;
}

.overlay_content_header {
  grid-area: overlay-header;
  height: 52px;
  line-height: 52px;
  position: relative;
  @include mobile() {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
}
.overlay_content_header_close {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 4px;
  border-radius: 100px;
  right: 16px;
  cursor: pointer;
  background: #262626;
  width: 32px;
  height: 32px;
  border: 1px solid #707070;
  line-height: 0;
  vertical-align: middle;
  text-align: center;
}

// 上記の.overlay_content_header_closeを非活性にするスタイルを適用
.overlay_content_header_close_disabled {
  pointer-events: none;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 4px;
  border-radius: 100px;
  right: 16px;
  cursor: pointer;
  background: #262626;
  width: 32px;
  height: 32px;
  border: 1px solid #707070;
  line-height: 0;
  vertical-align: middle;
  text-align: center;
}


.overlay_content_body {
  grid-area: overlay-body;
  overflow-y: auto;
  background: #fff;
  border: 1px solid #707070;
}

.overlay_content_body_inner {
  width: 100%;
}

.overlay_bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  top: 0;
  left: 0;
  transition: 0.4s;
}

.overlay_enter .overlay_content,
.overlay_leave_to .overlay_content {
  opacity: 0;
  top: -10px;
}

.overlay_enter .overlay_bg,
.overlay_leave_to .overlay_bg {
  opacity: 0;
}

.overlay_enter_to .overlay_content {
  opacity: 1;
  top: 0;
  .overlay_bg {
    opacity: 1;
  }
}

.overlay_enter_to .overlay_bg {
  opacity: 1;
}

.overlay_leave .overlay_content {
  opacity: 1;
  top: 0;
}

.overlay_leave .overlay_bg {
  opacity: 1;
}

.overlay_leave_active .overlay_content {
  transition: 0.4s;
}

.overlay_content_wrapper {
}

.overlay_content_round {
  .overlay_content_body {
    border: 0;
    border-radius: 10px;
  }
  .overlay_content_header_title {
    color: #000;
  }
  .overlay_content_header_close {
    right: -10px;
    top: -10px;
    z-index: 10;
  }
}

</style>
