<template>
  <div
    :class="[
      $style.home_search,
      { [$style.home_search_loading_area]: searchProcessing },
    ]"
  >
    <template v-if="!searchProcessing">
      <div :class="$style.search_wrap">
        <app-prev-button
          margin="0 0 46px"
          @click="backButtonAction(prevRoute)"
        />

        <div :class="$style.search_input_wrap">
          <base-input
            type="text"
            name="search_input"
            look="outline2"
            v-model="keyWord"
            @keydown.enter.native="searchWordHandler"
          >
            <template v-slot:prepend>
              <base-icon name="search" size="18px" />
            </template>
          </base-input>
        </div>

        <p :class="$style.search_note"></p>
        <p :class="$style.search_result_caption">検索結果</p>
        <div :class="$style.search_keyword_wrap">
          <span :class="$style.search_keyword_caption">キーワード:&nbsp;</span>
          <span
            :class="$style.search_keyword_word"
            @click="showSearch = true"
            >{{ searchResultWord }}</span
          >
        </div>
      </div>

      <div :class="$style.list_accordion_wrap">
        <base-accordion title="投稿・ミニ投票" type="borderless" expanded>
          <div
            :class="$style.post_item_list"
            v-if="featuredPostedVideoList.length > 0"
          >
            <!-- <div v-for="i of 6" :class="$style.post_item_wrap" :key='i'>
              <app-carousel-post-item
                border-color="#7BE293"
                head="ヘッダー"
                foot="フッター"
                time="2021/06/01 11:45"
                image="https://via.placeholder.com/140x50"
              /> -->
            <div
              v-for="(item, index) in featuredPostedVideoList"
              :class="$style.post_item_wrap"
              :key="index"
            >
              <app-carousel-post-item
                :border-color="
                  item.team_color ? `rgb(${item.team_color})` : '#7BE293'
                "
                :head="item.title"
                :foot="item.type_name == 'ミニ投票' ? '投票' : item.type_name"
                :time="
                  formatDate(
                    item.start_datetime ? item.start_datetime : item.created_at
                  )
                "
                :image="item.image_url || ''"
                :icon="item.block_user_flg ? '' : 'heart'"
                :selected="!!item.good_flg"
                :item="item"
                @select="goodSelect"
                @click="
                  clickChatOrMiniVoteMove(item.type, item.team_id, item.chat_id)
                "
              />
            </div>
          </div>
          <span v-if="featuredPostedVideoList.length <= 0"
            >検索結果はありません</span
          >
        </base-accordion>
      </div>

      <div :class="$style.list_accordion_wrap">
        <base-accordion title="MOVIE" type="borderless" expanded>
          <div :class="$style.post_item_list" v-if="lectures.length > 0">
            <div
              v-for="(item, index) in lectures"
              :class="$style.post_item_wrap"
              :key="index"
            >
              <app-carousel-post-item
                :border-color="
                  item.bar_color ? `rgb(${item.bar_color})` : '#7BE293'
                "
                :head="item.lecture_title"
                :foot="item.corner_name + '-' + item.course_name"
                :time="
                  formatDate(
                    item.start_datetime ? item.start_datetime : item.created_at
                  )
                "
                :image="item.lecture_path || ''"
                icon="heart"
                :selected="!!item.good_flg"
                :item="item"
                @select="goodLecture"
                @click="$router.push(`/union/course/${item.lecture_id}`)"
              />
            </div>
          </div>
          <span v-if="lectures.length <= 0">検索結果はありません</span>
        </base-accordion>
      </div>

      <div :class="$style.list_accordion_wrap">
        <base-accordion title="チーム" type="borderless" expanded>
          <div :class="$style.post_item_list" v-if="teamList.length > 0">
            <!-- <div v-for="i of 13" :class="$style.team_item_wrap" :key='i'>
              <app-carousel-team-item
                border-color="#7BE293"
                head="ヘッダー"
              />
            </div> -->
            <div
              v-for="(value, key, i) in teamList"
              :class="$style.team_item_wrap"
              :key="i"
            >
              <router-link :to="'/team/info/' + value.team_id">
                <app-carousel-team-item
                  :border-color="
                    value.team_color ? `rgb(${value.team_color})` : '#7BE293'
                  "
                  :head="value.name"
                  :image="value.path"
                />
              </router-link>
            </div>
          </div>
          <span v-if="teamList.length <= 0">検索結果はありません</span>
        </base-accordion>
      </div>

      <div :class="$style.list_accordion_wrap">
        <base-accordion
          title="サービスからのお知らせ"
          type="borderless"
          expanded
        >
          <div :class="$style.post_item_list" v-if="serviceNewsList.length > 0">
            <!-- <div v-for="i of 2" :class="$style.post_item_wrap" :key='i'>
              <app-carousel-post-item
                border-color="#E2E2E2"
                head="ヘッダー"
                foot="フッター"
                time="2021/06/01 11:45"
                image="https://via.placeholder.com/140x50"
              />
            </div> -->
            <div
              v-for="item of serviceNewsList"
              :class="$style.post_item_wrap"
              :key="item.news_id"
            >
              <router-link :to="'/home/noticetext/' + item.news_id">
                <app-carousel-post-item
                  border-color="#7BE293"
                  :head="item.title"
                  foot="service news"
                  :time="formatDate(item.created_at)"
                  :image="item.path"
                />
              </router-link>
            </div>
          </div>
          <span v-if="serviceNewsList.length <= 0">検索結果はありません</span>
        </base-accordion>
      </div>

      <app-search-menu
        v-if="showSearch"
        @close="showSearch = false"
        @search-keyword="mobileSearchWordHandler"
        :class="$style.mobile_only"
      />
    </template>
    <div :class="$style.home_search_loading" v-if="searchProcessing">
      <base-icon name="spinner" size="32px" spin />
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/components/base/BaseIcon/BaseIcon'
import BaseInput from '@/components/base/BaseInput/BaseInput'
import BaseAccordion from '@/components/base/BaseAccordion/BaseAccordion'
import AppCarouselPostItem from '@/components/app/AppCarousel/PostItem/AppCarouselPostItem'
import AppCarouselTeamItem from '@/components/app/AppCarousel/TeamItem/AppCarouselTeamItem'
import AppSearchMenu from '@/components/app/AppMenu/AppSearchMenu/AppSearchMenu'
import AppPrevButton from '@/components/app/AppPrevButton/AppPrevButton'
import { editTeamChatFavorite } from '@/helper/common.js'

export default {
  name: 'HomeSearch',
  components: {
    BaseIcon,
    BaseInput,
    BaseAccordion,
    AppCarouselPostItem,
    AppCarouselTeamItem,
    AppSearchMenu,
    AppPrevButton,
  },
  mounted() {
    this.$router.app.$emit('page-updated', {
      pageTitle: '検索結果',
      showSetting: false,
      headerColor: 'white',
      back: this.prevRoute,
    })
  },
  data() {
    return {
      showSearch: true,
      searchProcessing: false,
      processing: false,
      keyWord: '',
      featuredPostedVideoList: [],
      lectures: [],
      teamList: [],
      serviceNewsList: [],
      prevRoute: '',
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from && from.name) {
        vm.prevRoute = from
      } else {
        vm.prevRoute = '__history_back'
      }
    })
  },
  methods: {
    fetchTeamChat: function () {
      let search_params = {}
      if (this.keyWord != '') {
        search_params = {
          title: this.keyWord,
          text: this.keyWord,
          comment: this.keyWord,
        }
      }
      search_params = {
        ...search_params,
        sort: 'created_at',
        direction: 'desc',
      }
      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/team/chat/search', {
          params: search_params,
        })
        .then((res) => {
          if (res.data.status == 'success') {
            this.featuredPostedVideoList = res.data.value?.chat_list
          } else {
            this.featuredPostedVideoList = []
          }
        })
    },
    fetchTeamList: function () {
      let search_params = {}
      if (this.keyWord != '') {
        search_params = {
          name: this.keyWord,
          profile: this.keyWord,
          team_code: this.keyWord,
          team_id: this.keyWord,
        }
      }
      search_params = {
        search_type: 'all',
        ...search_params,
        open_flg: 1,
        sort: 'created_at',
        direction: 'desc',
        limit: 500,
      }
      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/team/list', {
          params: search_params,
        })
        .then((res) => {
          if (res.data.status == 'success') {
            this.teamList = res.data.value.team_list
          } else {
            this.teamList = []
          }
        })
    },
    fetchServiceNews: function () {
      let search_params = {}
      if (this.keyWord != '') {
        search_params = {
          title: this.keyWord,
        }
      }
      search_params = {
        ...search_params,
        sort: 'created_at',
        direction: 'desc',
        limit: 500,
      }
      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/servicenews/list', {
          params: search_params,
        })
        .then((res) => {
          if (res.data.status == 'success') {
            this.serviceNewsList = res.data.value.servicenews_list
          } else {
            this.serviceNewsList = []
          }
        })
    },
    fetchLectures() {
      let search_params = {
        search_word: this.keyWord,
        unread_flg: 0,
        sort: 'created_at',
        direction: 'desc',
        limit: 20,
      }

      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/lecture/list', {
          params: search_params,
        })
        .then((res) => {
          if (res.data.status == 'success') {
            this.lectures = res.data.value.lecture_list
          } else {
            this.lectures = []
          }
        })
    },
    async fetchData() {
      this.searchProcessing = true
      try {
        if (this.keyWord) {
          this.keyWord = this.keyWord.trim()
          this.$gtag.event('search', {
            search_word: this.keyWord,
          })
        }
        await Promise.all([
          this.fetchTeamChat(),
          this.fetchTeamList(),
          this.fetchServiceNews(),
          this.fetchLectures(),
        ])
      } finally {
        this.showSearch = false
        this.searchProcessing = false
      }
    },
    formatDate(targetDateTime) {
      return targetDateTime != null
        ? this.$dayjs(targetDateTime).format('YYYY/MM/DD HH:mm')
        : ''
    },
    searchWordHandler() {
      this.fetchData()
    },
    mobileSearchWordHandler(keyword_args) {
      this.keyWord = keyword_args
      this.showSearch = false
      this.fetchData()
    },
    goodSelect: async function (selected, item) {
      if (this.processing) {
        return
      }
      this.processing = true
      // 投稿内ユーザー登録・更新
      const { status } = await editTeamChatFavorite({
        chat_id: item.chat_id,
        good_flg: selected ? 1 : 0,
      })
      if (status == 'success') {
        item.good_flg = selected ? 1 : 0
      }
      this.processing = false
    },
    async goodLecture(good_flg, item) {
      if (this.processing) {
        return
      }
      this.processing = true

      let url =
        '/api/' + this.$constants.API_VERSION + '/lecture_history/chat/favorite'
      let chat_users = []
      chat_users.push({
        lecture_id: item.lecture_id,
        good_flg: good_flg,
      })
      let postdata = {
        chat_users: chat_users,
      }

      let filter = this.lectures.filter(function (element) {
        return element['lecture_id'] == item.lecture_id
      })

      this.$axios.post(url, postdata).then(() => {
        filter.forEach((element) => {
          if (good_flg == 1) {
            element['good_flg'] = true
          } else {
            element['good_flg'] = false
          }
        })
      })

      this.processing = false
    },
    clickChatOrMiniVoteMove: function (type, team_id, chat_id) {
      let targetUrl = ''
      switch (type) {
        case 1: // 投稿
        case 3: // メッセージ
        case 4: // SSO
          targetUrl = `/team/post-detail/${team_id}/${chat_id}`
          break
        case 2: // ミニ投票
          targetUrl = `/team/mini-vote-detail/${team_id}/${chat_id}`
          break
      }
      this.$router.push(targetUrl)
    },
    backButtonAction(back) {
      let baseUrl = process.env.VUE_APP_BASE_URL
      // 遷移元URLがVUE_APP_BASE_URLを含んでいた場合
      if (document.referrer.includes(baseUrl)) {
        if (back === '__history_back') {
          this.$router.go(-1)
        } else {
          this.$router.push(back, () => {})
        }
        // 遷移元URLがVUE_APP_BASE_URLを含んでいない場合はHOME画面へ遷移
      } else {
        this.$router.push('/home/home', () => {})
      }
    },
  },
  computed: {
    searchResultWord: function () {
      return this.keyWord && this.keyWord != ''
        ? this.keyWord
        : 'キーワードなし'
    },
  },
  watch: {},
  async created() {
    this.$router.app.$emit('show-spinner', true)
    await this.fetchData()
    this.$router.app.$emit('show-spinner', false)
    this.showSearch = true

    //パラメータを取得する（Home画面からの検索があるか確認）
    const keyword = this.$route.query.search_word
    if (keyword) {
      // パラメータが存在する場合は検索
      this.keyWord = keyword
      this.searchWordHandler()
    }
  },
}
</script>

<style lang="scss" module>
.home_search {
  display: flex;
  width: 960px;
  flex-direction: column;
  align-items: center;
  padding: 12px 0 50px 0;
  margin: 0 auto;
  overflow: hidden;

  @include mobile() {
    width: auto;
    margin: 0;
    padding: 24px 16px 24px 16px;
  }

  &_loading_area {
    width: 100% !important;
    justify-content: center;
  }
}
.search_wrap {
  width: 100%;

  .search_input_wrap {
    margin-bottom: 12px;

    @include mobile() {
      display: none;
    }
  }

  .search_note {
    padding: 0;
    margin-top: 0;
    margin-bottom: 48px;
    font-size: 11px;
    color: $fontBlack;

    @include mobile() {
      display: none;
    }
  }
  .search_result_caption {
    padding: 0;
    margin-top: 0;
    margin-bottom: 24px;
    font-size: 30px;
    font-weight: bold;
    color: #000000;

    @include mobile() {
      font-size: 20px;
      margin-bottom: 8px;
    }
  }

  .search_keyword_wrap {
    display: none;

    @include mobile() {
      display: inline-flex;
      height: 16px;
      margin-bottom: 24px;
    }

    .search_keyword_caption {
      font-size: 11px;
    }
    .search_keyword_word {
      font-size: 11px;
      font-weight: bold;
      color: $keyGreen;
    }
  }
}

.list_accordion_wrap {
  width: 100%;
  margin-bottom: 10px;
  .post_item_list {
    padding: 0 2px;
    margin: 10px 0 0 0;
    display: flex;
    flex-wrap: wrap;

    @include mobile() {
      flex-wrap: nowrap;
      overflow-y: scroll;
    }

    .post_item_wrap {
      margin-right: 14px;
      margin-bottom: 14px;
    }

    .team_item_wrap {
      margin-right: 20px;
      margin-bottom: 14px;
    }
  }
}

.mobile_only {
  @include pc() {
    display: none;
  }
}
</style>
