<template>
  <transition
    :name="$style.app_menu"
    :enter-class="$style.app_menu_enter"
    :enter-active-class="$style.app_menu_enter_active"
    :enter-to-class="$style.app_menu_enter_to"
    :leave-class="$style.app_menu_leave"
    :leave-active-class="$style.app_menu_leave_active"
    :leave-to-class="$style.app_menu_leave_to"
    @leave="closing"
    appear="appear"
  >
    <div :class="$style.app_menu">
      <section :class="$style.app_menu_content">

        <div :class="$style.app_menu_content_header">
          <div
            v-if="$listeners['close']"
            :class="$style.app_menu_content_header_close"
            @click.prevent.stop="$emit('close')"
          >
            <img
              :class="$style.app_menu_content_header_close_icon"
              src="@/assets/images/times_green.svg" alt="閉じる">
            <span :class="$style.app_menu_content_header_close_icon_text">閉じる</span>
          </div>
          <div :class="$style.app_menu_content_header_title">
            <slot name="title" />
          </div>
        </div>

        <div :class="$style.app_menu_content_body">
          <slot name="body" />
        </div>

      </section>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'AppMenu',
  props: {},
  computed: {},
  methods: {
    closing(el, done) {
      setTimeout(() => {
        done()
      }, 400)
    }
  }
}
</script>

<style lang="scss" module>

.app_menu {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  background: rgba(0, 0, 0, 0.5);
}
.app_menu_content {
  background: $keyYellow;
  max-width: 375px;
  height: 100%;
  z-index: 1001;
  transition: 0.4s;
  transition-delay: 0.2s;
  overflow-y: auto;

  @include mobile(){
    max-width: 500px;
  }
}

.app_menu_content_header {
  margin: 20px 16px 20px 20px;
  position: relative;

  .app_menu_content_header_title {
    margin: 0 auto;
    text-align: center;
    font-size: 14px;
    line-height: 19px;
    font-weight: bold;
  }

  .app_menu_content_header_title_logo {
    width: 26px;
    height: 26px;
    padding-right: 10px;
  }

  .app_menu_content_header_close {
    position: absolute;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .app_menu_content_header_close_icon{
    width: 21px;
    height: 21px;
    margin-bottom: 4px;
  }

  .app_menu_content_header_close_icon_text {
    font-size: 9px;
    transform: scale(0.9);
  }
}

.app_menu_enter_active, .app_menu_leave_active {
  transform: translate(0px, 0px);
  transition: transform 250ms;
}
.app_menu_enter, .app_menu_leave_to {
  transform: translateX(100vw) translateX(0px);
}

</style>
