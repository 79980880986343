<template>
  <div :class="$style.input_comment_wrap">
    <div :class="$style.input_comment">
      <div v-if="showFileIcon" :class="$style.icon_button">
        <base-file-input
          :name="name"
          @change="handleFileSubmit"
          @error="handleFileError"
          :extensions="extensions"
          :maxFileSize="maxFileSize"
        >
          <img
            :src="require('@/assets/images/' + fileIconName)"
            :class="$style.icon_plus"
            alt="puls"
          />
        </base-file-input>
      </div>
      <div v-if="showTextArea">
        <base-rich-text-editor
          :modelValue="textareaValue"
          @update="handleTextAreaUpdate"
          :rows="rows"
          :maxlength="maxlength"
          :error="error"
          :class="$style.icon_button_text_only"
        >
          <template v-slot:append>
            <span
              v-if="showStampIcon"
              :class="$style.icon_button_smile"
              @click="showStampModal = true"
            >
              <img src="@/assets/images/stamp_03.svg" alt="stamp" />
            </span>
          </template>
        </base-rich-text-editor>
      </div>
      <div v-if="!showTextArea">
        <p :class="$style.icon_button_text_only">{{ textareaValue }}</p>
      </div>
    </div>
    <div
      :class="$style.error_comment"
      v-for="(error, index) in commentErrorList"
      :key="'cm-err-' + index"
    >
      <p>{{ error.message }}</p>
    </div>
    <div :class="$style.error_comment" v-if="commentError">
      <p>{{ commentError }}</p>
    </div>
    <div :class="$style.files">
      <div
        v-for="(file, index) in files"
        :class="$style.file"
        v-bind:key="index"
      >
        <span :class="$style.download" @click="onFileDownload(file, index)">
          <img src="@/assets/images/file_download.svg" alt="download" />
        </span>
        <template v-if="isImage(file.type)">
          <img :class="$style.thumbnail" :src="file.url" />
        </template>
        <template v-else>
          <span>{{ file.name }}</span>
        </template>
        <span
          :class="$style.trash"
          @click="onFileDelete(file, index)"
          v-if="showTrash"
        >
          <base-icon size="18px" type="far" name="trash-alt" color="green" />
        </span>
      </div>
    </div>
    <base-modal
      v-if="showStampModal"
      @close="showStampModal = false"
      height="460px"
      type="round"
    >
      <template v-slot:header-title>スタンプ挿入</template>
      <template v-slot:body>
        <div :class="$style.stamp_tab_wrap">
          <stamp-tab :tab-data="tab_data" @submit="handleStampSubmit" />
        </div>
      </template>
    </base-modal>
  </div>
</template>

<script>
import BaseRichTextEditor from '@/components/base/BaseRichTextEditor/BaseRichTextEditor'
import BaseFileInput from '@/components/base/BaseFileInput/BaseFileInput'
import BaseModal from '@/components/base/BaseModal/BaseModal'
import StampTab from '@/components/base/BaseInputComment/StampTab'
import BaseIcon from '@/components/base/BaseIcon/BaseIcon.vue'

export default {
  name: 'BaseInputComment',
  components: { BaseRichTextEditor, BaseFileInput, BaseModal, StampTab, BaseIcon },
  props: {
    modelValue: {},
    maxlength: {
      type: Number,
      default: 10000,
    },
    rows: {
      type: Number,
      default: 1,
    },
    name: {
      type: String,
      default: 'file',
    },
    showFileIcon: {
      type: Boolean,
      default: true,
    },
    fileIconName: {
      type: String,
      default: 'feather-plus.svg',
    },
    showTextArea: {
      type: Boolean,
      default: true,
    },
    showStampIcon: {
      type: Boolean,
      default: true,
    },
    showRecordIcon: {
      type: Boolean,
      default: true,
    },
    extensions: {
      type: Array,
      default: () => null, // 全てのファイルのアップロードを許可
    },
    imageTypes: {
      type: Array,
      default: () => null, // 添付ファイルは全て通常のファイル扱い
    },
    textareaValue: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    commentErrorList: {
      type: Array,
      default: () => [],
    },
    commentError: {
      type: String,
      default: '',
    },
    files: {
      type: Array,
      default: () => null,
    },
    /**
     * メガバイトで指定
     */
    maxFileSize: {
      type: Number,
      default: null,
    },
    showTrash: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showStampModal: false,
      showRecordModal: false,
      tab_data: [],
    }
  },
  methods: {
    isImage(fileType) {
      if (fileType) {
        return this.imageTypes.includes(fileType)
      }
      return false
    },
    onClickTab(name) {
      this.$emit('tab', name)
    },
    onFileDownload(file, index) {
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.download = file.name
      a.href = file.url
      a.target = '_blank'
      a.click()
      a.remove()
      this.$emit('file_download', file, index)
    },
    onFileDelete(file, index) {
      this.$emit('file_delete', file, index)
    },
    handleFileSubmit([base64, file]) {
      this.$emit('file_updated', [base64, file])
    },
    handleFileError(errors) {
      this.$emit('error', errors)
    },
    handleStampSubmit(args) {
      this.showStampModal = false
      this.$emit('stamp', args)
    },
    handleTextAreaUpdate(value) {
      this.$emit('update', value)
    },
    fetchStampList: function () {
      return this.$axios
        .get('api/v1/stamp/list', {
          params: {
            sort: 'category_id',
            direction: 'asc',
            limit: 100,
          },
        })
        .then((res) => {
          if (res.data.status == 'success') {
            this.stamp_list = res.data.value.stamp_list
            this.stamp_list.forEach((element) => {
              this.tab_data.push({
                tabName: element.category,
                tabImagePath: element.category_image_url,
                tabItems: element.stamp_category_list,
              })
            })
          } else {
            this.tab_data = null
          }
        })
        .catch((err) => {
          this.tab_data = null
          throw new Error(err)
        })
    },
    async initFetch() {
      this.initLoading = true
      try {
        await Promise.all([this.fetchStampList()])
      } catch (e) {
        // this.$router.push('/error')
        this.$loginfo(e)
      } finally {
        this.initLoading = false
      }
    },
  },
  async created() {
    this.initFetch()
  },
}
</script>

<style lang="scss" module>
$textSize_Error: 13px;
$textSpecing_Error: 4px;
$textColor_Error: #ff0000;

.input_comment_wrap {
}
.input_comment {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.icon_button {
  min-height: 42px;

  &_text_only {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  &.camera {
    display: none;
    @include mobile() {
      display: block;
    }
  }

  &_smile {
    height: 100%;
    padding: 2px 10px 0 10px;
  }
}

.icon_plus {
  margin: 10px 10px 0 0;
}
.icon_camera {
  margin: 13px 8px 0 8px;
}
.icon_mic {
  margin: 11px 8px 0 8px;
}

.icon_button_text_only {
  width: 1200px;
  @media (max-width: 767px) {
    width: 313px;
  }
}

.stamp_tab_wrap {
  display: flex;
  flex-direction: column;
  min-height: 408px;
  padding: 16px 30px 24px 30px;
  box-sizing: border-box;
}

.record_button_wrap {
  display: flex;
  flex-direction: column;
  padding: 16px 20px 16px 20px;
  box-sizing: border-box;
  .description {
    margin-top: 0;
    margin-bottom: 66px;
    font-size: 11px;
    color: $fontBlack;
  }
}
.error_comment {
  font-size: $textSize_Error;
  margin: $textSpecing_Error 0 0 0;
  color: $textColor_Error;
  white-space: pre-wrap;
  padding: 0 96px 0 38px;
  line-height: 1.2;
  p {
    margin: 6px 0 0;
  }
}

.files {
  margin-top: 10px;

  .file {
    border: 2px solid #c4c4c4;
    border-radius: 4px;
    margin-left: 30px;
    margin-bottom: 10px;
    padding: 5px;

    .download {
      margin-left: 10px;
      margin-right: 10px;
      float: left;
    }
    .download:hover {
      cursor: pointer;
    }

    .thumbnail {
      //height: 120px;
      width: 195px;
    }

    .trash {
      margin-right: 10px;
      float: right;
    }
    .trash:hover {
      cursor: pointer;
    }
  }
}
</style>
