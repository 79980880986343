<template>
  <div :class="$style.mini_vote_result">
    <div :class="$style.result_header_lg">
      <div :class="$style.result_title_btn_lg">
        <h2 :class="$style.theme_lg">{{ chat_title }}</h2>
        <span :class="$style.result_header_title_lg">投票</span>
        <span v-if="chat != null" :class="$style.result_header_time">
            {{time(
              chat.vote_start_datetime,
              chat.vote_end_datetime,
              chat.start_datetime,
              chat.end_datetime,
              chat.created_at
            )}}
        </span>
      </div>
      <span :class="$style.result_header_vote_num_lg"
        >(総投票数: {{ sum_count }}票)</span
      >
    </div>
    <div :class="$style.result">
      <div v-if="result_download_flg" :class="$style.result_download">
        <div :class="$style.download_button" @click="csvDownload">
          <img src="@/assets/images/file_download.svg" alt="DL">
          <span :class="$style.download_text">結果をダウンロードする</span>
        </div>
      </div>
      <p :class="$style.theme">{{ chat_title }}</p>
      <div :class="$style.result_header">
        <span :class="$style.result_header_title">投票結果</span>
        <span :class="$style.result_header_vote_num"
          >総投票数: {{ sum_count }}票</span
        >
      </div>
      <div :class="$style.result_chart">
        <app-bar-chart :vote_result="vote_result"></app-bar-chart>
      </div>
    </div>
    <div :class="$style.voter_list_accordion">
      <div>
        <base-accordion type="normal" title="投票者（新しい順）" expanded>
          <ul :class="$style.voter_list">
            <li v-for="vote_answer in vote_answer_list" :key="vote_answer.id">
              <div :class="$style.voter_list_item">
                <span :class="$style.voter_list_item_answer">{{
                  vote_answer.vote_choice_name
                }}</span>
                <router-link
                  v-if="vote_answer.status == '通常'"
                  :class="$style.voter_list_item_voter"
                  :to="'/home/profile/' + vote_answer.user_id"
                  >{{ vote_answer.nickname }}</router-link
                >
                <span v-if="vote_answer.status != '通常'">{{
                  vote_answer.nickname
                }}</span>
                <span :class="$style.voter_list_item_time">{{
                  voteTime(vote_answer.updated_at)
                }}</span>
              </div>
            </li>
          </ul>
        </base-accordion>
      </div>
      <div :class="$style.scroll_button_lg">
        <app-scroll-button />
      </div>
      <div :class="$style.scroll_button">
        <app-scroll-button />
      </div>
    </div>
  </div>
</template>
<script>
import AppBarChart from '@/components/app/AppChart/AppBarChart/AppBarChart'
import AppScrollButton from '@/components/app/AppScrollButton/AppScrollButton'
import BaseAccordion from '@/components/base/BaseAccordion/BaseAccordion'
export default {
  name: 'TeamMiniVoteResult',
  components: { AppBarChart, BaseAccordion, AppScrollButton},
  mounted() {
    window.addEventListener('resize', this.resizeWindow)

    this.$router.app.$emit('page-updated', {
      pageTitle: '',
      back: `/team/mini-vote-detail/${this.$route.params.team_id}/${this.$route.params.chat_id}`,
      showSetting: false,
      headerColor: 'yellow',
      bgColor: 'white',
      showPcBackBtn: true,
    })
  },
  data() {
    return {
      chat: null,
      chat_title: {},
      sum_count: '',
      vote_result: [],
      vote_answer_list: [],
      windowWidth: 0,
      result_download_flg: false,
    }
  },
  methods: {
    fetchChatInfo: function () {
      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/team/chat/info', {
          params: { chat_id: this.$route.params.chat_id },
        })
        .then((res) => {
          if (res.data.status == 'success') {
            this.chat = res.data.value
          }
        })
        .catch((err) => {
          this.chat = null
          throw new Error(err)
        })
    },
    fetchVoteResult: function () {
      return this.$axios
        .get(
          '/api/' +
            this.$constants.API_VERSION +
            '/team/chat/vote_answer/result',
          { params: { chat_id: this.$route.params.chat_id } }
        )
        .then((res) => {
          if (res.data.status == 'success') {
            this.chat_title = res.data.value.chat_title
            this.sum_count = res.data.value.sum_count
            this.vote_result = res.data.value.vote_result
            this.result_download_flg = res.data.value.result_download_flg
          } else {
            this.chat_title = null
            this.sum_count = null
            this.vote_result = null
            this.result_download_flg = false
          }
        })
        .catch((err) => {
          this.chat_title = null
          this.sum_count = null
          this.vote_result = null
          throw new Error(err)
        })
    },
    fetchVoteAnswerList: function () {
      return this.$axios
        .get(
          '/api/' + this.$constants.API_VERSION + '/team/chat/vote_answer/list',
          {
            params: {
              chat_id: this.$route.params.chat_id,
              sort: 'updated_at',
              direction: 'desc',
            },
          }
        )
        .then((res) => {
          if (res.data.status == 'success') {
            this.vote_answer_list = res.data.value.vote_answer_list
          } else {
            this.vote_answer_list = null
          }
        })
        .catch((err) => {
          this.vote_answer_list = null
          throw new Error(err)
        })
    },
    time: function (
      vote_start_datetime,
      vote_end_datetime,
      start_datetime,
      end_datetime,
      created_at
    ) {
      let startFormatStr = ''
      let endFormatStr = ''

      if (vote_start_datetime || vote_end_datetime) {
        if (vote_start_datetime) {
          startFormatStr =
            this.$dayjs(vote_start_datetime).format('YYYY/MM/DD HH:mm')
        } else if (created_at) {
          startFormatStr = this.$dayjs(created_at).format('YYYY/MM/DD HH:mm')
        }
        if (vote_end_datetime) {
          endFormatStr =
            this.$dayjs(vote_end_datetime).format('YYYY/MM/DD HH:mm')
        }
      } else {
        if (start_datetime) {
          startFormatStr =
            this.$dayjs(start_datetime).format('YYYY/MM/DD HH:mm')
        } else if (created_at) {
          startFormatStr = this.$dayjs(created_at).format('YYYY/MM/DD HH:mm')
        }
        if (end_datetime) {
          endFormatStr = this.$dayjs(end_datetime).format('YYYY/MM/DD HH:mm')
        }
      }
      // 表示設定
      if (startFormatStr && !endFormatStr) {
        return startFormatStr
      }
      if (startFormatStr && endFormatStr) {
        return startFormatStr + ' - ' + endFormatStr
      }
      return ''
    },
    voteTime: function (voteTime) {
      voteTime = this.$dayjs(voteTime).format('YYYY/MM/DD HH:mm')

      return voteTime
    },
    async initFetch() {
      try {
        await Promise.all([this.fetchVoteResult(), this.fetchVoteAnswerList(), this.fetchChatInfo()])
      } catch (e) {
        // this.$router.push('/error')
        this.$loginfo(e)
      }
    },
    resizeWindow() {
      this.windowWidth = window.innerWidth
    },
    getFileName: function (contentDisposition) {
      return decodeURI(contentDisposition).substring(
        contentDisposition.indexOf("''") + 2,
        contentDisposition.length,
      )
    },
    async csvDownload() {
      this.$axios
        .get(
          '/api/' + this.$constants.API_VERSION + '/team/chat/vote_answer/download',
          {
            params: {
              chat_id: this.$route.params.chat_id,
            },
            responseType: "blob",
          }
        )
        .then((res) => {
          const fileName = this.getFileName(res.headers["content-disposition"])
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          window.URL.revokeObjectURL(url)
        })
        .catch((err) => {
          throw new Error(err)
        })
    },
  },
  async created() {
    this.$router.app.$emit('show-spinner', true)
    await this.initFetch()
    this.$router.app.$emit('show-spinner', false)
  },
}
</script>
<style lang="scss" module>
.mini_vote_result {
  flex: 1;
  justify-content: start;

  .result {
    box-sizing: border-box;
    padding: 20px 32px 24px 32px;
    margin: 0 auto;
    @media screen and (min-width: 768px) {
      background-color: white;
      max-width: 80%;
    }
    @media screen and (max-width: 768px) {
      background-color: $openBg;
      max-width: 960px;
    }
  }

  .theme {
    @media screen and (min-width: 768px) {
      display: none;
    }
    @media screen and (max-width: 768px) {
      font-size: 10px;
      margin-top: 0;
      margin-bottom: 14px;
    }
  }
  .theme_lg {
    font-weight: bold;
    font-size: 2em;
    margin-right: 0.5em;
  }

  .result_header_title {
    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  .result_header_title_lg {
    background-color: black;
    color: white;
    padding: 5px 10px;
    font-weight: bold;
    border-radius: 10%;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .result_header_time{
    font-weight: bold;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .result_title_btn_lg {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .result_header_vote_num {
    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  .result_header_vote_num_lg {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .result_header {
    @media screen and (min-width: 768px) {
      display: none;
    }
    @media screen and (max-width: 768px) {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      height: 20px;
      margin-bottom: 14px;
      &_title {
        font-size: 14px;
        font-weight: bold;
      }
      &_vote_num {
        font-size: 11px;
      }
    }
  }
  .result_header_lg {
    display: none;
    @media screen and (min-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 10%;
    }
  }
  .result_download {
    display: flex;
    justify-content: flex-end;
    font-size: 14px;

    .download_button {
      cursor: pointer;
    }

    .download_text {
      margin-left: 0.5em;
    }
  }

  .voter_list_accordion {
    margin: 0 auto;

    @media screen and (min-width: 768px) {
      width: 75%;
      position: relative;
    }

    & [class^='BaseAccordion_expand_title'] {
      border-top: none !important;
    }

    & [class^='BaseAccordion_expand_content'] {
      border-bottom: none !important;
    }

    .scroll_button {
      position: relative;
      top: 0;
      right: 0;
      @media screen and (min-width: 768px) {
        display: none;
      }
    }

    .scroll_button_lg {
      position: absolute;
      top: 0;
      right: 0;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    .voter_list {
      list-style: none;
      padding: 0 32px;
      &_item {
        display: flex;
        width: 100%;
        margin-bottom: 10px;
        font-size: 20px;
        @media screen and (max-width: 768px) {
          font-size: 11px;
        }
        &_answer {
          margin-right: 20px;
        }
        &_time {
          margin-left: auto;
        }
      }
      .voter_list_item {
        width: 100%;
        .voter_list_item_time {
          width: 30%;
        }
      }
    }
  }
}
</style>
