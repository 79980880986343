import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate' // use local storage
import authChange from './modules/authChange'; // 追加


import sample from './modules/sample'
import auth from './modules/auth'
import invite from './modules/invite'
import profile_edit from "./modules/profile_edit";
import registration from "./modules/registration";
import team from './modules/team'
import union from './modules/union'

Vue.use(Vuex)

const getDefaultState = () => {
  return {
    sample: {},
    auth: {},
    invite: {},
    profile_edit: {},
    registration: {},
    team: {},
    union: {},
  }
}

const store = new Vuex.Store({
  mutations: {
    logout(state) {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {},
  getters: {},
  modules: {
    authChange,
    sample,
    auth,
    invite,
    profile_edit,
    registration,
    team,
    union,
  },
  plugins: [createPersistedState({storage: window.localStorage})],
})

export default store
