<template>
  <section
    :class="[
      $style.wrapper,
      $style.cursor_pointer,
      {
        [$style.unread]: this.unread,
      },
      $style.wrapper_movie,
    ]"
    :style="customStyle"
    @click="handleClick"
  >
    <img v-if="clip" :class="$style.clip" src="@/assets/images/clip.svg" />
    <img
      v-if="pin"
      :class="$style.pin"
      src="@/assets/images/favorite_pin.svg"
    />

    <!-- 画像 -->
    <picture
      :class="[$style.picture, $style.picture_obj_movie]"
      :border-color="borderColor"
      :outline-color="outlineColor"
    >
      <img :src="image" />
    </picture>

    <!-- タグ -->
    <div :class="$style.header_wrapper">
      <div v-if="displayTag" :class="$style.tag_wrapper">
        <base-tag
          width="100%"
          height="20px"
          :bgColor="tagColor"
          :color="tagTextColor"
          fontSize="m"
          >{{ tagText }}</base-tag
        >
      </div>
      <p v-else :class="$style.time">
        <time :datetime="timeAttr" :class="$style.time_text">{{
          timeText
        }}</time>
      </p>

      <p v-if="ableToSelect" :class="$style.icons">
        <span
          :class="[$style.icons_wrapper, { [$style.selected]: !!selected }]"
          @click.stop="handleSelect"
        >
          <base-icon
            :type="iconType"
            :name="iconName"
            :color="iconColor"
            size="20px"
          />
        </span>
      </p>

      <span v-if="type === 'app'" :class="$style.overlay_menu_wrapper">
        <base-overlay-menu-list>
          <template v-slot:menu-button>
            <div :class="$style.menu_button_icon" />
          </template>
          <base-overlay-menu-list-item to="" @click.native="handleFavorite">
            {{ favorite ? 'お気に入りから削除する' : 'お気に入りに追加する' }}
          </base-overlay-menu-list-item>
          <base-overlay-menu-list-item to="" @click.native="handlePin">
            {{ pin ? 'クリップを解除する' : 'クリップする' }}
          </base-overlay-menu-list-item>
          <base-overlay-menu-list-item to="" @click.native="handleShare">
            シェアする
          </base-overlay-menu-list-item>
        </base-overlay-menu-list>
      </span>
      <span v-else-if="type === 'team'" :class="$style.overlay_menu_wrapper">
        <base-overlay-menu-list>
          <template v-slot:menu-button>
            <div :class="$style.menu_button_icon" />
          </template>
          <base-overlay-menu-list-item
            to=""
            @click.native="handleFavorite"
            v-if="showFavorite"
          >
            {{ favorite ? 'お気に入りから削除する' : 'お気に入りに追加する' }}
          </base-overlay-menu-list-item>
          <base-overlay-menu-list-item
            to=""
            @click.native="handlePin"
            v-if="showPin"
          >
            {{ pin ? 'クリップを解除する' : 'クリップする' }}
          </base-overlay-menu-list-item>
          <base-overlay-menu-list-item
            to=""
            @click.native="handleShare"
            v-if="showShare"
          >
            シェアする
          </base-overlay-menu-list-item>
          <base-overlay-menu-list-item
            to=""
            @click.native="handleRead"
            v-if="showRead"
          >
            {{ read ? '未読にする' : '既読にする' }}
          </base-overlay-menu-list-item>
          <base-overlay-menu-list-item
            to=""
            @click.native="handleDelete"
            v-if="showDelete"
          >
            削除する
          </base-overlay-menu-list-item>
          <base-overlay-menu-list-item
            to=""
            @click.native="handleBrowse"
            v-if="showBrowse"
          >
            この投稿を閲覧する
          </base-overlay-menu-list-item>
          <base-overlay-menu-list-item
            to=""
            @click.native="handleReport"
            v-if="showReport"
          >
            {{
              reported ? '※このユーザは通報済みです' : 'このユーザを通報する'
            }}
          </base-overlay-menu-list-item>
          <base-overlay-menu-list-item
            to=""
            @click.native="handleBlock"
            v-if="showBlock"
          >
            {{
              blockUser
                ? 'このユーザのブロックを解除する'
                : 'ユーザをブロックする'
            }}
          </base-overlay-menu-list-item>
        </base-overlay-menu-list>
      </span>
    </div>

    <!-- タイトル -->
    <h3 :class="$style.head">{{ head }}</h3>

    <!-- テキスト -->
    <p :class="$style.foot">{{ foot }}</p>
  </section>
</template>

<script>
import BaseIcon from '@/components/base/BaseIcon/BaseIcon'
import BaseTag from '@/components/base/BaseTag/BaseTagPhase2.9'
import BaseOverlayMenuList from '@/components/base/BaseOverlayMenuList/BaseOverlayMenuList'
import BaseOverlayMenuListItem from '@/components/base/BaseOverlayMenuList/BaseOverlayMenuListItem'

export default {
  name: 'AppCarouselPostItem',
  components: {
    BaseIcon,
    BaseTag,
    BaseOverlayMenuList,
    BaseOverlayMenuListItem,
  },
  props: {
    head: {
      type: String,
      default: '8月の活動日決めます！投票してください', // あとで消す
    },
    foot: {
      type: String,
      default: 'オタメシ労働組合湘南サーフィン支部', // あとで消す
    },
    icon: {
      type: String,
      default: 'heart',
      validator: (icon) => !icon || ['heart'].includes(icon),
    },
    image: {
      type: String,
      default: require('@/assets/images/no-image.png'),
    },
    tag: {
      type: String,
      default: null,
    },
    customTagText: {
      type: String,
      default: null,
    },
    customTagColor: {
      type: String,
      default: null,
    },
    customTagTextColor: {
      type: String,
      default: null,
    },
    time: {
      type: [String, Date, Number],
      default: () => new Date(),
    },
    selected: {
      type: Boolean,
      default: null, // true:選択, false:非選択, null:選択機能無し
    },
    unread: {
      type: Boolean,
      default: false,
    },
    clip: {
      type: Boolean,
      default: false,
    },
    pin: {
      type: Boolean,
      default: false,
    },
    favorite: {
      type: Boolean,
      default: false,
    },
    read: {
      type: Boolean,
      default: false,
    },
    borderColor: {
      type: String,
      default: '#FFFFFF',
    },
    steps: {
      type: Array,
      default: () => [],
      validator: (steps) => steps.every((step) => step >= 0 && step <= 100),
    },
    item: {
      type: Object,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: false,
      default: null,
    },
    showShare: {
      type: Boolean,
      default: true,
    },
    showRead: {
      type: Boolean,
      default: true,
    },
    showPin: {
      type: Boolean,
      default: true,
    },
    showFavorite: {
      type: Boolean,
      default: true,
    },
    showDelete: {
      type: Boolean,
      default: true,
    },
    reported: {
      type: Boolean,
      default: false,
    },
    showReport: {
      type: Boolean,
      default: true,
    },
    blockUser: {
      type: Boolean,
      default: false,
    },
    showBlock: {
      type: Boolean,
      default: true,
    },
    showBrowse: {
      type: Boolean,
      default: false,
    },
    isCursorPointer: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleSelect() {
      // 選択イベントが発火するように設定
      // this.$emit('select', !this.selected)
      this.$emit('select', !this.selected, this.item)
    },
    handleClick() {
      // クリックイベントが発火するように設定
      this.$emit('click', this.item)
    },
    handleFavorite() {
      // お気に入りイベント
      this.$emit('favorite', this.item)
    },
    handlePin() {
      // ピンイベント
      this.$emit('pin', this.item) //user側の変更は文言でクリップと言いつつピンなので注意
    },
    handleRead() {
      // 未読/既読イベント
      this.$emit('read', this.item)
    },
    handleDelete() {
      // 削除イベント
      this.$emit('delete', this.item)
    },
    handleShare() {
      // シェアイベント
      this.$emit('share', this.item)
    },
    handleReport() {
      // ユーザ通報イベント
      this.$emit('report', this.item)
    },
    handleBlock() {
      // ブロックイベント
      this.$emit('blockUser', this.item)
    },
    handleBrowse() {
      // 閲覧イベント
      this.$emit('browse', this.item)
    },
  },
  computed: {
    ableToSelect() {
      return this.selected === false || this.selected === true
    },
    customStyle() {
      let style = {
        //'border-top': `2px solid ${this.borderColor}`,
      }

      if (this.isCursorPointer) {
        // trueの場合マウスオーバーでカーソルが指マークに変化
        style.cursor = 'pointer'
      }
      return style
    },
    iconType() {
      return 'fas' // iconやselectedによって変化する予定
    },
    iconName() {
      return this.icon // 今のところheartのみ
    },
    iconColor() {
      return this.selected ? 'pink' : 'gray'
    },
    timeAttr() {
      const date =
        this.time instanceof Date
          ? this.time
          : new Date(this.time.replace(/-/g, '/'))
      return date.toLocaleString()
    },
    timeText() {
      if (!this.time) {
        return ''
      }
      const date =
        this.time instanceof Date
          ? this.time
          : new Date(this.time.replace(/-/g, '/'))
      return `${date.getFullYear()}/${('0' + (date.getMonth() + 1)).slice(
        -2
      )}/${('0' + date.getDate()).slice(-2)} ${('0' + date.getHours()).slice(
        -2
      )}:${('0' + date.getMinutes()).slice(-2)}`
    },
    displayTag() {
      if (
        this.customTagText ||
        this.customTagTextColor ||
        this.customTagColor
      ) {
        return true
      } else if (this.tag) {
        return true
      }

      return false
    },
    tagColor() {
      return ''
      /*
      if (this.customTagColor) {
        return this.customTagColor
      }
      const tagColorMap = {
        need: 'pink',
        step: 'yellow',
        done: 'yellow',
      }

      return tagColorMap[this.tag] || 'pink'
      */
    },
    tagStepText() {
      const tagStepText = this.steps
        .map((step, i) => `Step${i + 1} - ${step}%`)
        .slice(0, 2)
        .join('  ')
      return tagStepText
    },
    tagText() {
      if (this.customTagText) {
        return this.customTagText
      }

      const tagTextMap = {
        need: '受講してください',
        step: this.tagStepText,
        done: '受講完了',
      }

      return tagTextMap[this.tag] || ''
    },
    tagTextColor() {
      return 'black'
      /*
      if (this.customTagTextColor) {
        return this.customTagTextColor
      }

      const tagTextColorMap = {
        need: 'white',
        step: 'black',
        done: 'black',
      }

      return tagTextColorMap[this.tag] || 'white'
      */
    },
  },
}
</script>

<style lang="scss" module>
.wrapper {
  height: 273px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 10px;
  picture img {
    object-fit: cover;
    -o-object-fit: cover;
    width: 100%;
    transition: transform 0.4s ease;
  }
  &:hover {
    picture img {
      transform: scale(1.1);
    }
  }
}
.wrapper.unread {
  opacity: 50%;
}

.clip {
  position: absolute;
  top: -16px;
  left: 4px;
}

.pin {
  z-index: 2;
  position: absolute;
  top: -14px;
  left: -3px;
  transform: rotate(-21deg);
}
.header_wrapper {
  height: 22px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 11px;
}

.tag_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: left;
}

.time {
  display: inline-block;
  color: $fontGray;
  margin: 0;
}
.time_text {
  display: inline-block;
  font-size: 10px;
  transform: scale(0.9);
}
.icons {
  display: inline-block;
  color: $borderLiteGray;
  margin: 0;

  &_wrapper {
    display: flex;
  }

  .selected {
    color: $keyPink;
  }
}

.overlay_menu_wrapper {
  display: flex;
  .menu_button_icon {
    width: 10px;
    height: 10px;
    cursor: pointer;
    background-image: url('@/assets/images/グループ 1656.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}
.picture {
  display: block;
  height: auto;
  overflow: hidden;
  flex-shrink: 1;
  &.picture_obj_movie {
    border-radius: 10px;
    position: relative;
    width: 100%;
    height: 88px;
    &::after {
      display: inline-block;
      width: 50px;
      height: 50px;
      background-image: url('@/assets/images/list/icon-play.svg');
      background-size: contain;
      vertical-align: middle;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      @media screen and (max-width: 768px) {
        width: 30px;
        height: 30px;
      }
    }
    img {
      height: 88px;
    }
  }
}
.wrapper_movie {
  background-color: $keyWhite;
  border: solid 1px $borderLiteGray;
  padding: 16px;
  border-radius: 16px;
  .title {
    color: $keyBlack;
    font-size: 14px;
  }
}
.head {
  display: block;
  color: $keyBlack;
  max-height: 74px;
  line-height: 26px;
  margin: 0 0 8px;
  font-size: 14px;
  transform: scale(1);
  word-break: break-all;
  overflow: hidden;
  font-family: HiraginoSans-W6;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: left;
  color: #262626;
}

.foot {
  display: block;
  color: $fontGray;
  max-height: 30px;
  margin: 0;
  transform: scale(1);
  word-break: break-all;
  overflow: hidden;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #262626;
}
</style>
