<template>
  <div :class="$style.wrapper">
    <label
      v-for="value in values"
      :key="value.sort"
      :class="[$style.checkbox, { [$style.vertically_long]: verticallyLong }]"
    >
      <input
        :class="$style.check"
        :name="name"
        :value="value.id"
        type="checkbox"
        v-model="value.choice_flg"
        @change="onChange(value.id, value.choice_flg)"
      />
      <span :class="$style.choice"> {{ value.sort }}.{{ value.choice }} </span>
    </label>
  </div>
</template>
<script>
export default {
  name: "BaseRadioItem",
  inheritAttrs: false,
  model: {
    event: "change",
  },
  props: {
    values: {
      type: Array,
    },
    name: {
      type: String,
    },
    verticallyLong: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    onChange(value, checked) {
      this.$emit('change', value, checked)
    }
  },
  computed: {},
};
</script>
<style lang="scss" module>
// $baseSize: 40px;
$baseBorder: 2px;
$baseSpacing: 8px;
$textSpacing: 8px 5%;
$fontSize: 12px;
$checkBorder: 4px;
$baseColor: #dddddd;
$activeColor: #4ac0c0;

.wrapper {
}

.checkbox {
  display: block;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: $baseSpacing;
  }

  .choice {
    display: block;
    padding: 15px 30px;
    font-size: 15px;
    font-weight: bolder;
    background-color: rgb(244, 244, 244);
    border: 2px solid $baseColor;
    border-radius: 100vh;
    transition: all 0.1s;
    max-width: 590px;
    margin: 0 auto;
  }

  .check {
    display: none;

    &:checked {
      + .choice {
        // font-weight: bold;
        border: $checkBorder solid $activeColor;
        background-color: #FFFFFF;
      }
    }
  }
}

.vertically_long {
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}
</style>
