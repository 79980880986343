<template>
  <label :class="[$style.radio_box, previousSpace? $style.previous_space : '' ]">
    <input
      :class="$style.radio"
      v-bind="$attrs"
      :value="value"
      type="radio"
      v-model="boundChecked"
    />
    <span :class="$style.label">
      <span :class="$style.circle" />
      <slot />
    </span>
  </label>
</template>
<script>
export default {
  name: "BaseRadio",
  inheritAttrs: false,
  model: {
    prop: 'checked',
    event: 'update',
  },
  props: {
    value: {
      type: [String, Number, Boolean],
      required: true,
    },
    checked: {
      type: [String, Number, Boolean],
    },
    previousSpace: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    boundChecked: {
      get() {
        // console.log("radio get", { this: this, checked: this.checked });
        return this.checked;
      },
      set(value) {
        // console.log("radio set", { this: this, value });
        this.$emit("update", value);
      },
    },
  },
};
</script>
<style lang="scss" module>
$baseSize: 20px;
$innerSize: 10px;
$borderSize: 2px;
$baseSpacing: 4px;
$textSpacing: 8px;
$fontSize: 14px;
$baseColor: #b7b7b7;
$activeColor: #e94c89;

.radio_box {
  display: block;
  padding: $baseSpacing;
  // border-radius: 4px;
  // background: #fff;
  font-size: $fontSize;
  cursor: pointer;
  .circle {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: $baseSize;
    height: $baseSize;
    margin-right: $textSpacing;
    border: $borderSize solid $baseColor;
    border-radius: 50%;
    vertical-align: middle;
    box-sizing: border-box;
    &:before {
      content: "";
      display: block;
      width: $innerSize;
      height: $innerSize;
      border-radius: 50%;
      background: $baseColor;
      opacity: 0;
      transition: all 0.1s linear;
    }
  }
  .radio {
    display: none;
    &:checked {
      + .label {
        // font-weight: bold;
        .circle {
          border: $borderSize solid $activeColor;
          &:before {
            opacity: 1;
            background: $activeColor;
          }
        }
      }
    }
  }
}

.previous_space {
  margin: 1em 0 0 2em;
  @media screen and (max-width: 767px) {
    margin: 0;
  }
}
</style>
