<template>
  <transition
    :name="$style.open_menu"
    :enter-class="$style.open_menu_enter"
    :enter-active-class="$style.open_menu_enter_active"
    :enter-to-class="$style.open_menu_enter_to"
    :leave-class="$style.open_menu_leave"
    :leave-active-class="$style.open_menu_leave_active"
    :leave-to-class="$style.open_menu_leave_to"
    @leave="closing"
    appear="appear"
  >
    <div :class="$style.open_menu">
      <section :class="$style.open_menu_content">
        <div :class="$style.open_menu_content_header">
          <div :class="$style.open_menu_content_header_title">
            <img
              :class="$style.open_menu_content_header_title_logo"
              :src="logoPath"
            />
            <span>くみあいアプリ</span>
          </div>
          <div
            :class="$style.open_menu_content_header_close"
            @click.prevent.stop="$emit('close')"
          >
            <img
              :class="$style.open_menu_content_header_close_icon"
              src="@/assets/images/times_black.svg"
              alt="閉じる"
            />
          </div>
        </div>

        <div :class="$style.open_menu_content_body">
          <div :class="$style.open_menu_content_body_inner">
            <div :class="$style.nav_wrap">
              <a
                v-for="item in navItem"
                :class="$style.nav_item"
                :href="item.path"
                :key="item.label"
              >
                {{ item.label }}
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'AppOpenMenu',
  props: {},
  data() {
    return {
      navItem: {
        about: { label: '運営会社について', path: '/open/about' },
        faq: { label: 'よくある質問', path: '/open/faq' },
        contact: { label: 'お問い合わせ', path: '/open/contact' },
        teamsOfService: { label: '利用規約', path: '/open/teams_of_service' },
        privacyPolicy: {
          label: '個人情報保護方針',
          path: '/open/privacy_policy',
        },
      },
    }
  },
  computed: {
    logoPath() {
      return require('@/assets/images/nav_logo1.svg')
    },
  },
  methods: {
    closing(el, done) {
      setTimeout(() => {
        done()
      }, 400)
    },
  },
}
</script>

<style lang="scss" module>
.open_menu {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.open_menu_content {
  background: $keyWhite;
  width: 100%;
  height: 100%;
  z-index: 1001;
  transition: 0.4s;
  transition-delay: 0.2s;
}

.open_menu_content_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 32px;
  height: 90px;
  background: $keyWhite;

  .open_menu_content_header_title {
    display: flex;
    text-align: center;
    font-size: 13px;
    line-height: 26px;
    font-weight: bold;
  }

  .open_menu_content_header_title_logo {
    width: 26px;
    height: 26px;
    padding-right: 10px;
  }

  .open_menu_content_header_close {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .open_menu_content_header_close_icon {
    width: 21px;
    height: 21px;
    margin-bottom: 4px;
  }

  .open_menu_content_header_close_icon_text {
    font-size: 9px;
    transform: scale(0.9);
  }
}

.open_menu_content_body {
  height: 100%;
  background-color: $keyWhite;
  padding-top: 24px;
  padding-left: 102px;

  .open_menu_content_body_inner {
    height: 100%;
  }
}

.nav_wrap {
  display: flex;
  flex-direction: column;
  padding: 0;
  box-sizing: border-box;

  .nav_item {
    text-align: left;
    margin-bottom: 24px;
    display: block;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    box-sizing: border-box;
    text-decoration: none;
    color: black;
    white-space: nowrap;
    border-bottom: 2px solid $keyWhite;

    &:hover {
      border-bottom: 2px solid $keyYellow;
    }
  }
}

.open_menu_enter_active,
.open_menu_leave_active {
  transform: translate(0px, 0px);
  transition: transform 250ms;
}
.open_menu_enter,
.open_menu_leave_to {
  transform: translateX(100vw) translateX(0px);
}
</style>
