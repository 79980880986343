<template>
  <div
    :class="[$style.header, { [$style[`bg_${this.bgColor}`]]: !!this.bgColor }]"
  >
    <div :class="$style.icon_wrap">
      <img :class="$style.icon_logo" :src="logoPath" />
      <span :class="$style.icon_title" @click="redirectToTop"
        >くみあいアプリ</span
      >
    </div>

    <div :class="$style.nav_wrap" v-if="showMenu">
      <a
        v-for="item in navItem"
        :class="$style.nav_item"
        :href="item.path"
        :key="item.label"
        >{{ item.label }}
      </a>
    </div>

    <div v-if="hasLogin" :class="$style.login_button_wrap">
      <base-button
        display="inline-block"
        width="80px"
        height="24px"
        size="s"
        @click="clickLogin"
        >ログイン
      </base-button>
    </div>

    <div
      :class="$style.bar_icon_wrap"
      @click="showSetting = !showSetting"
      v-if="showMenu"
    >
      <img src="@/assets/images/bars.svg" :class="$style.bar_icon" alt="bar" />
    </div>
    <app-open-menu v-if="showSetting" @close="showSetting = false" />
  </div>
</template>

<script>
import AppOpenMenu from '@/components/app/AppMenu/AppOpenMenu/AppOpenMenu'
import BaseButton from '@/components/base/BaseButton/BaseButton'
export default {
  name: 'AppOpenHeader',
  components: { AppOpenMenu, BaseButton },
  props: {
    back: {
      type: [String, Object],
      default: '',
    },
    bgColor: {
      type: String,
      default: 'white',
      validator: (bg_color) =>
        !bg_color || ['white', 'yellow'].includes(bg_color),
    },
    pageTitle: {
      type: String,
      default: undefined,
    },
    hasLogin: {
      type: Boolean,
      default: true,
    },
    showMenu: {
      type: Boolean,
      default: true,
    },
    enableRedirectToTop: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showSetting: false,
      navItem: {
        about: {
          label: '運営会社について',
          path: '/open/about',
          check: false,
        },
        faq: {
          label: 'よくある質問',
          path: '/open/faq',
          check: false,
        },
        contact: {
          label: 'お問い合わせ',
          path: '/open/contact',
          check: false,
        },
        teamsOfService: {
          label: '利用規約',
          path: '/open/teams_of_service',
          check: false,
        },
        privacyPolicy: {
          label: '個人情報保護方針',
          path: '/open/privacy_policy',
          check: false,
        },
      },
    }
  },
  computed: {
    logoPath() {
      return require('@/assets/images/nav_logo1.svg')
    },
  },
  methods: {
    clickLogin() {
      this.$store.commit('invite/setToken', this.$route.query.token)
      this.$router.push('/open/login')
    },
    redirectToTop() {
      if (!this.enableRedirectToTop) {
        return
      }
      const topPath = '/open/index'
      if (this.$route.path != topPath) {
        this.$router.push(topPath)
      }
    },
  },
}
</script>

<style lang="scss" module>
.header {
  max-height: 90px;
  height: 90px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid $borderLiteGray;

  &.bg_white {
    background-color: $keyWhite;
  }
  &.bg_yellow {
    background-color: $keyYellow;
  }
}
.icon_wrap {
  display: flex;
  align-items: center;
  margin-right: auto;
  margin-left: 32px;
  white-space: nowrap;
  cursor: pointer;
  @include mobile() {
    display: flex;
    margin-left: 42px;
  }

  .icon_logo {
    width: 26px;
    height: 26px;
    padding-right: 10px;
  }

  .icon_title {
    font-size: 13px;
    font-weight: bold;
  }
}

.bar_icon_wrap {
  display: none;
  @include mobile() {
    display: block;
    margin-right: 20px;
    height: 18px;
  }

  .bar_icon {
    width: 27px;
    height: 18px;
  }
}
.nav_wrap {
  height: 90px;
  display: flex;
  justify-content: flex-end;
  padding: 0;
  margin-right: 50px;
  box-sizing: border-box;
  @include mobile() {
    display: none;
  }

  .nav_item {
    display: block;
    font-size: 13px;
    line-height: 18px;
    font-weight: bold;
    text-align: center;
    margin: 36px 0 35px 30px;
    box-sizing: border-box;
    text-decoration: none;
    color: black;
    white-space: nowrap;
    &:first-child {
      margin-left: 0;
    }
    &:hover {
      text-decoration: underline $keyYellow 2px;
      text-underline-offset: 8px;
    }
  }
}
.login_button_wrap {
  font-size: 13px;
  font-weight: bold;
  margin-right: 30px;

  @include mobile() {
    margin-right: 20px;
  }
}
</style>
