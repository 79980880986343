<template>
  <div :class="$style.union_course_quiz">
    <app-prev-button @click="$router.go(-1)" margin="0 0 24px" />

    <div :class="$style.union_course_quiz_content_wrap">
      <div :class="$style.union_course_quiz_content">
        <div :class="$style.content_header">
          <p :class="$style.content_header_title">視聴確認クイズ</p>
          <p :class="$style.content_header_caption">
            クイズに正解すると受講完了になります。
          </p>
          <h1 :class="$style.content_header_course_title">{{ title }}</h1>
        </div>

        <div :class="$style.content_body">
          <div :class="$style.question">
            <h2 :class="$style.question_title">Q. {{ quiz }}</h2>
            <p :class="$style.question_caption">
              次の中から最適解を選んでください。
            </p>
          </div>

          <div :class="$style.answer_radio">
            <div v-for="quiz_choice in quiz_choices" :key="quiz_choice.id">
              <base-radio
                name="answer"
                v-model="choice_answer"
                :value="quiz_choice.id"
                ><span :class="$style.answer_radio_item">{{
                  quiz_choice.choice
                }}</span></base-radio
              >
            </div>
          </div>
          <div :class="$style.answer_triangle"></div>
          <!-- {{ choice_answer }} -->
          <div v-for="quiz_choice in quiz_choices" :key="quiz_choice.id">
            <div v-show="choice_answer == quiz_choice.id">
              <div v-if="true == quiz_choice.answer_flg" :class="[$style.result, $style.result_correct]">
                <div :class="[$style.result_header, $style.result_header_correct]">
                  <base-icon type="far" name="smile" size="24px" color="pink" />
                  <span>最適解です!</span>
                </div>
                <p :class="$style.result_description">
                  {{ quiz_choice.explain }}
                </p>
              </div>
              <div v-if="false == quiz_choice.answer_flg" :class="[$style.result, $style.result_incorrect]">
                <div :class="[$style.result_header, $style.result_header_incorrect]">
                  <base-icon type="far" name="frown" size="24px" color="blue" />
                  <span>再確認</span>
                </div>
                <p :class="$style.result_description">
                  {{ quiz_choice.explain }}
                </p>
              </div>
            </div>
          </div>
          <div :class="$style.next_button_wrap" v-show="choice_answer != ''">
            <base-button width="120px" rounded="rounded" @click="save"
              >次へ</base-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/components/base/BaseIcon/BaseIcon'
import BaseRadio from '@/components/base/BaseRadio/BaseRadio'
import BaseButton from '@/components/base/BaseButton/BaseButton'
import AppPrevButton from '@/components/app/AppPrevButton/AppPrevButton'
export default {
  name: 'UnionCourseQuiz',
  components: { BaseIcon, BaseRadio, BaseButton, AppPrevButton },
  data() {
    return {
      quiz: '',
      title: '',
      quiz_choices: {},
      choice_answer: '',
      lecture_id: '',
    }
  },
  mounted() {
    this.$router.app.$emit('page-updated', {
      pageTitle: '視聴確認クイズ',
      headerColor: 'yellow',
      back: '__history_back',
      showSetting: false,
      bgColor: 'yellow',
    })
  },

  created() {
    this.lecture_id = this.$route.params.lecture_id
    let url = '/api/' + this.$constants.API_VERSION + '/lecture/info?lecture_id=' + this.$route.params.lecture_id
    this.$axios
      .get(url, {})
      .then((res) => {
        let data = res.data.value.lecture
        this.title = data.title
        this.quiz = data.quiz
        this.quiz_choices = data.quiz_choices
        // this.choice_answer = this.quiz_choices[0].choice
        // for (let index = 0; index < this.quiz_choices.length; index++) {
        //   this.$set(this.choice_answer,this.quiz_choices[index].id,this.quiz_choices[index].choice)
        // }
      })
      .catch((err) => {
        if (err.response) {
          this.$logerror(err.response.data)
          this.$logerror(err.response.status) // 例：400
          this.$logerror(err.response.statusText) // Bad Request
          this.$logerror(err.response.headers)
        }

        this.$logerror(err)
      })
  },
  methods: {
    save: function () {
      var date = new Date()
      const formatDate = (current_datetime) => {
        let formatted_date =
          current_datetime.getFullYear() +
          '-' +
          (current_datetime.getMonth() + 1).toString().padStart(2, '0') +
          '-' +
          current_datetime.getDate().toString().padStart(2, '0') +
          ' ' +
          current_datetime.getHours().toString().padStart(2, '0') +
          ':' +
          current_datetime.getMinutes().toString().padStart(2, '0') +
          ':' +
          current_datetime.getSeconds().toString().padStart(2, '0')
        return formatted_date
      }
      this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/lecture_history/registration', {
          lecture_id: this.$route.params.lecture_id,
          quiz_choice_id: String(Number(this.choice_answer)),
          answer_at: formatDate(date),
          // answer_at: '2020-02-02 10:10:10',
        })
        .then(() => {
          if (this.$route.params.corner_id) {
            this.$router.push(
              '/union/course_static_quiz_1/' +
                this.$route.params.lecture_id +
                '/' +
                this.$route.params.corner_id
            )
          } else {
            this.$router.push(
              '/union/course_static_quiz_1/' + this.$route.params.lecture_id
            )
          }
        })
        .catch((err) => {
          if (err.response) {
            this.$logerror(err.response.data)
            this.$logerror(err.response.status) // 例：400
            this.$logerror(err.response.statusText) // Bad Request
            this.$logerror(err.response.headers)
          }

          this.$logerror(err)
          alert('エラーが発生しました')
        })
    },
  },
}
</script>

<style lang="scss" module>
.union_course_quiz {
  display: flex;
  width: 960px;
  flex-direction: column;
  align-items: center;
  padding: 12px 0 48px 0;
  margin: 0 auto;
  @include mobile() {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}

.union_course_quiz_content_wrap {
  width: 100%;
  background-color: $keyWhite;
  padding: 24px 150px;
  box-sizing: border-box;

  @include mobile() {
    padding: 22px 32px;
  }
}

.union_course_quiz_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content_header {
  width: 100%;
  text-align: center;
  margin-bottom: 12px;
  border-bottom: 1px solid #dddddd;
  @include mobile() {
    margin-bottom: 16px;
  }

  &_title {
    font-size: 19px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 8px;
    @include mobile() {
      display: none;
    }
  }
  &_caption {
    font-size: 11px;
    font-weight: bold;
    color: $keyPink;
    margin-top: 0;
    margin-bottom: 12px;
  }
  &_course_title {
    font-size: 19px;
    margin-top: 0;
    margin-bottom: 16px;
    @include mobile() {
      font-size: 16px;
      margin-bottom: 24px;
    }
  }
}

.content_body {
  width: 660px;
  @include mobile() {
    width: 100%;
  }
  .question {
    margin-bottom: 12px;
    @include mobile() {
      margin-bottom: 16px;
    }

    &_title {
      font-size: 20px;
      color: $keyPink;
      margin-top: 0;
      margin-bottom: 8px;
      @include mobile() {
        font-size: 16px;
        margin-bottom: 12px;
      }
    }
    &_caption {
      font-size: 12px;
      font-weight: bold;
      margin: 0;
    }
  }

  .answer_radio {
    border: 5px solid $keyPink;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 24px;
    margin-bottom: 16px;
    @include mobile() {
      margin-bottom: 16px;
    }

    &_item {
      font-size: 16px;
    }
  }

  .answer_triangle {
    display: none;
    @include mobile() {
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 15px 20px 0 20px;
      border-color: #dddddd transparent transparent transparent;
      margin: 0 auto 20px auto;
    }
  }

  .result {
    border-radius: 10px;
    padding: 16px 20px 20px 20px;
    margin-bottom: 8px;
    @include mobile() {
      margin-bottom: 20px;
    }

    &_correct {
      background-color: $openBg;
    }

    &_incorrect {
      background-color: $openLiteGray;
    }

    &_header {
      display: flex;
      margin-bottom: 14px;

      &_correct {
        span {
          font-size: 16px;
          font-weight: bold;
          margin-left: 8px;
          color: $keyPink;
        }
      }

      &_incorrect {
        span {
          font-size: 16px;
          font-weight: bold;
          margin-left: 8px;
          color: $keyBlue;
        }
      }
    }
    &_description {
      font-size: 11px;
      color: $fontBlack;
      margin: 0;
      white-space: pre-line;
      word-wrap: break-word;
      @include mobile() {
        font-size: 13px;
      }
    }
  }
}

.next_button_wrap {
  display: flex;
  justify-content: center;
}
</style>
