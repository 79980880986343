<template>
  <div :class="customClass" :style="{'display': display}">
    <label v-if="type === 'avatar'" :for="name">
      <div :class='$style.avatar_wrap'>
        <img :class='$style.avatar' v-show="base64" :src="base64" />
        <img :class='$style.avatar' v-show="!base64" src="@/assets/images/account-circle.svg"/>
      </div>
      <img :class='$style.add_a_photo_icon' src='@/assets/images/add-a-photo.svg' alt=''>
    </label>

    <label v-if="type === 'plus'" :for='name'>
      <base-icon name='plus'/>
    </label>

    <base-button v-if="type === 'button'" :for="name" by-label="by-label">
      <slot v-if="!fileName">ファイルを選択する</slot>
      {{ fileName }}
    </base-button>

    <base-button
      v-if="type === 'qr'"
      :for="name"
      by-label="by-label"
      width="100px"
      height="100px"
      rounded="corner"
    >
      <div :class="$style.qr_reader_wrap">
        <base-icon name="arrow-down" size="14px" :class='$style.qr_reader_arrow'/>
        <img :class='$style.qr_reader_icon' src="@/assets/images/qr-icon.svg" alt="">
        <span :class="$style.qr_reader_caption">QRを読込</span>
      </div>
    </base-button>

    <label v-else :for="name">
      <slot/>
    </label>

    <input
      v-if="ready"
      :id="name"
      :class="$style.file_input"
      v-bind="$attrs"
      :disabled="!!disabled"
      type="file"
      @change="onSelectImage"
    />
    <p v-if="!!error" :class="$style.error_text">{{ error }}</p>
  </div>
</template>
<script>
import BaseIcon from '@/components/base/BaseIcon/BaseIcon'
import validate from 'simple-valid'
import BaseButton from '@/components/base/BaseButton/BaseButton'

// バリデーションにルール設定
validate.addRule(
  'contains',
  [
    (value, params) => {
      return !params.includes(value)
    }
  ],
  'アップロードできないファイルです'
)

export default {
  name: 'BaseFileInput',
  components: {
    BaseButton,
    BaseIcon,
  },
  inheritAttrs: false,
  props: {
    display: {
      type: String,
      default: undefined
    },
    /**
     * ラベルを指定できます
     */
    label: {
      type: String,
      default: ''
    },
    /**
     * 要素の名前を指定します
     */
    name: {
      type: String,
      required: true
    },
    /**
     * エラー時の文言を指定するとエラー表示になります
     */
    error: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: undefined,
      validator: (value) =>
        [undefined,'button', 'avatar', 'plus', 'qr'].includes(value)
    },
    disabled: {
      type: Boolean,
      default: false
    },
    reset: {
      type: Boolean,
      default: false
    },
    extensions: {
      type: Array,
      default: () => ['png', 'jpeg', 'jpg', 'gif']
    },
    /**
     * メガバイトで指定
     */
    maxFileSize: {
      type: Number,
      default: null
    },
    maxRect: {
      type: Object,
      default: null
    },
    base64data: {
      type: String,
      default: null
    }
  },
  data: function () {
    return {
      file: null,
      base64: this.base64data,
      file_name: '',
      ready: true
    }
  },
  computed: {
    fileName() {
      return this.file_name
    },
    customClass() {
      return [
        this.$style.file,
        {
          [this.$style.error]: !!this.error
        }
      ]
    }
  },
  methods: {
    onSelectImage(evt) {
      const files = evt.target.files || evt.dataTransfer.files
      this.file = files[0]
      this.createFile(files[0])
      this.file_name = files[0].name
      // 同じファイルを連続して登録できる様に(changeイベント発生する様に)クリアする
      evt.target.value = ''
    },
    isImage(ext = '') {
      return ['jpg', 'jpeg', 'png', 'giff', 'tiff', 'bmp'].includes(
        ext.toLowerCase()
      )
    },
    getExtension(name) {
      return name
        .split('.')
        .slice(-1)[0]
        .toLowerCase()
    },
    /**
     * @private
     */
    check(file, extension, image) {
      const test = {}
      const values = {}
      // ファイルサイズチェック
      if (this.maxFileSize) {
        const fileSize = this.maxFileSize * 1024 * 1024
        test.filesize = `max:${fileSize}`
        values.filesize = file.size
      }
      if (this.extensions) {
        const extensions = this.extensions.join(',')
        test.extensions = `contains:${extensions}`
        //
        values.extensions = extension
      }
      if (image && this.maxRect) {
        const height = this.maxRect.height
        const width = this.maxRect.width
        test.height = `max:${height}`
        test.width = `max:${width}`

        values.width = image.width
        values.height = image.height
      }
      return validate.execute(values, test)
    },
    resetData() {
      this.file_name = ''
      this.base64 = ''
      this.file = ''
      this.ready = false
      this.$nextTick(() => (this.ready = true))
    },
    createFileEvent(file, base64, extension, image = null) {
      const errors = this.check(file, extension, image)
      if (errors.has()) {
        this.$emit('error', errors.all())
        this.resetData()
      } else {
        this.$emit('change', [this.base64, this.file])
        /**
         * @property {array} base64, fileObject
         */
        if (this.reset) {
          this.resetData()
        }
      }
    },
    createFile(file) {
      const reader = new FileReader()

      reader.onload = (e) => {
        this.base64 = e.target.result

        const extension = this.getExtension(file.name)
        /**
         * load Image if it's image
         */
        if (this.isImage(extension)) {
          const _this = this

          const image = new Image()

          image.src = this.base64

          image.onload = function() {
            _this.createFileEvent(file, e.target.result, extension, this)
          }
        } else {
          this.createFileEvent(file, e.target.result, extension)
        }
      }
      reader.readAsDataURL(file)
    },
  }
}
</script>
<style lang="scss" module>
$borderColor: #e5e5e5;
$errorBorder: #b4251d;
$errorBg: #fff9f8;
$borderRadius: 4px;
.file {
  .file_input {
    display: none;
  }
}
.error_text {
  font-size: 12px;
  color: red;
}

.avatar_wrap {
  position: relative;
  width: 116px;
  height: 116px;
}
.avatar {
  width: 116px;
  height: 116px;
  object-fit: cover;
  border-radius: 50%;
  background: center center / cover no-repeat;
  overflow: hidden;
  box-sizing: border-box;
}

.add_a_photo_icon {
  position: relative;
  top: -21px;
  left: 106px;
}

.qr_reader {
  &_wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100px;
    padding-top: 10px;
    box-sizing: border-box;
  }
  &_arrow {
    margin-bottom: 8px;
  }
  &_icon {
    width: 28px;
    height: 28px;
    margin-bottom: 10px;
  }
  &_caption {
    font-size: 13px;
    font-weight: bold;
    color: $fontWhite;
  }
}

</style>
