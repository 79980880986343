<template>
  <section :class="$style.home_menu">

    <app-prev-button />

    <app-home-menu :class="$style.no_transition"/>
  </section>
</template>
<script>
import AppHomeMenu from '@/components/app/AppMenu/AppHomeMenu/AppHomeMenu'
import AppPrevButton from '@/components/app/AppPrevButton/AppPrevButton'
export default {
  name: 'HomeMenu',
  components: { AppHomeMenu, AppPrevButton },
  mounted() {
    this.$router.app.$emit('page-updated', {
      pageTitle: 'メニュー',
      showSetting: false,
      back: 'path/to',
      bgColor: 'white',
      showTabbar: false, // AppHeaderのAppTabbar、OpenMenuを消す
    })
  },
  data() {
    return {
    }
  }
}
</script>
<style lang="scss" module>
.home_menu {
  display: flex;
  width: 960px;
  flex-direction: column;
  align-items: center;
  padding: 12px 0 50px 0;
  margin: 0 auto;
  overflow: hidden;

  @include mobile(){
    width: auto;
    margin: 0;
    padding: 24px 40px 24px 40px;
  }

  .no_transition {
    transition: none !important;
  }
}
</style>
