<template>
  <section
    :class="[
      $style.article,
      { [$style.clip_on]: this.clip, [$style.pin_on]: this.pin },
      { [$style.read]: this.read }
    ]"
  >

    <header
      :class="$style.header_wrapper"
    >
      <img
        v-if="clip"
        :class="[
          $style.pin,
          { [$style.pin_on]: this.clip, [$style.pin_on_read]: this.read },
        ]"
        src="@/assets/images/icon-clip.svg"
        alt="ピン"
      />

      <div :class="$style.header_row1">
        <div :class="[$style.header_row2, { [$style.read]: this.read }]">
          <base-tag
            :class="$style.typeIcon"
            v-if="displayType() == 'normal' ? type == 'post' : false"
            width="50px"
            height="25px"
            padding="0"
            bgColor="white"
            color="black"
            margin="20px"
            :bordered="true"
            fontSize="m"
          >
            投稿
          </base-tag>
          <base-tag
            :class="$style.typeIcon"
            v-if="displayType() == 'normal' ? type == 'vote' : false"
            width="50px"
            height="25px"
            padding="0"
            bgColor="#2dccd3"
            margin="20px"
            color="white"
            fontSize="m"
          >
            投票
          </base-tag>
          <base-tag
            :class="$style.typeIcon"
            v-if="displayType() == 'normal' ? type == 'gift' : false"
            width="70px"
            height="25px"
            padding="0"
            bgColor="green"
            color="white"
            fontSize="m"
          >
            メッセージ
          </base-tag>
          <base-tag
            :class="$style.typeIcon"
            v-if="displayType() == 'expired'"
            width="50px"
            height="25px"
            padding="0"
            bgColor="#FFF490"
            color="black"
            fontSize="m"
          >
            期間外
          </base-tag>
          <base-tag
            :class="$style.typeIcon"
            v-if="displayType() == 'draft'"
            width="50px"
            height="25px"
            padding="0"
            bgColor="#D75888"
            color="white"
            fontSize="m"
          >
            下書
          </base-tag>
          <h3
            :class="[$style.title, { [$style.read]: this.read }]"
            v-if="showPost"
            @click="handleClick"
          >
            {{ this.title }}
          </h3>
          <div :class="$style.tag_block_time" v-show="!showPost">
            <time :class="$style.block_time_text">{{ time }}</time>
          </div>
        </div>
      </div>
      <div v-show="!showPost" :style="centerText_styles">
        ブロックしたユーザの投稿です
      </div>

      <div :class="$style.header_bottom">
        <div :class="[$style.header_row1point5, {[$style.read]: this.read }]">
          <div :class="{ [$style.read]: this.read }">
            <router-link :to="profileUrl">
              <base-avatar
                size="36px"
                :border-color="avatarColor"
                :color="avatarColor"
                :class="[$style.avatar, { [$style.read]: this.read }]"
                :path="avatarImage"
              />
            </router-link>
          </div>
          <div :class="$style.header_row1point5__text">
            <div :class="$style.user_nickname">{{ item.chat_user_nickname }}</div>
            <div :class="$style.tag_time" v-if="showPost">
              <time :class="$style.time">{{ time }}</time>
            </div>
          </div>
        </div>
        <div :class="[$style.header_row2, { [$style.read]: this.read }]">

          <!-- ボタン(デザインのみです) -->
          <div :class="$style.notification" v-if="showPost && type !== 'gift'">
            <div @click="commentNoticeClick" :class="comment_notice_flg ? $style.notification_button : $style.notification_button_disabled">
              <img src="@/assets/images/notification-white.svg" alt="" :class="$style.notification_button__icon"/>
              コメント通知
            </div>
            <div @click="mentionNoticeClick" :class="mention_notice_flg ? $style.notification_button : $style.notification_button_disabled">
              <img src="@/assets/images/notification-white.svg" alt="" :class="$style.notification_button__icon"/>
              メンション通知
            </div>
          </div>
          <!-- /ボタン(デザインのみです) -->

          <!-- アイコン -->
          <div :class="$style.counters" v-if="showPost">
            <div :class="[$style.browsed, { [$style.on]: read }]">
              <span :class="[$style.icon, $style.cursor_pointer]" @click.stop="readClick"
                ><base-icon size="15" name="eye"
              /></span>
              <span :class="$style.count">{{ readCount }}</span>
            </div>

            <div :class="[$style.commented, { [$style.on]: commentCount > 0 }]">
              <span :class="$style.icon" @click.stop="commentIconClick"
                ><base-icon size="15" name="comment-dots"
              /></span>
              <span :class="$style.count">{{ commentCount }}</span>
            </div>

            <div :class="[$style.like, { [$style.on]: goodFlg }]">
              <span :class="[$style.icon, $style.cursor_pointer]" @click.stop="handleSelect"
                ><base-icon size="15" name="heart" />
              </span>
              <span :class="$style.count">{{ goodsCount }}</span>
            </div>
          </div>

          <!-- menu -->
          <div :class="$style.menu">
            <base-overlay-menu-list :menuWidth="220" v-if="showEllipsis">
              <base-overlay-menu-list-item
                @click.native="favoriteClick"
                to=""
                v-if="showFavorite"
                >{{
                  favoriteFlg ? 'お気に入りから削除する' : 'お気に入りに追加する'
                }}</base-overlay-menu-list-item
              >
              <base-overlay-menu-list-item
                @click.native="editChatClick"
                to=""
                v-if="editChatFlg"
                >{{
                  type == 'post' ? '投稿' : 'ミニ投票'
                }}を編集する</base-overlay-menu-list-item
              >
              <base-overlay-menu-list-item
                @click.native="clipClick"
                to=""
                v-if="showPinFlg"
                >{{
                  clip ? 'クリップを外す' : 'クリップする'
                }}</base-overlay-menu-list-item
              >
              <base-overlay-menu-list-item
                @click.native="shareClick"
                to=""
                v-if="showShare"
                >シェアする</base-overlay-menu-list-item
              >
              <base-overlay-menu-list-item
                @click.native="readClick"
                to=""
                v-if="showRead"
                >{{
                  read ? '未読にする' : '既読にする'
                }}</base-overlay-menu-list-item
              >
              <base-overlay-menu-list-item
                @click.native="deleteChatClick"
                to=""
                v-if="deleteAuthFlg"
                >削除する</base-overlay-menu-list-item
              >
              <base-overlay-menu-list-item
                @click.native="commentNoticeClick"
                to=""
                v-if="['post', 'vote'].includes(type)"
                >コメント通知を{{
                  comment_notice_flg ? 'OFF' : 'ON'
                }}にする</base-overlay-menu-list-item
              >
              <base-overlay-menu-list-item
                @click.native="mentionNoticeClick"
                to=""
                v-if="['post', 'vote'].includes(type)"
                >メンション通知を{{
                  mention_notice_flg ? 'OFF' : 'ON'
                }}にする</base-overlay-menu-list-item
              >
              <base-overlay-menu-list-item
                @click.native="handleBrowse"
                to=""
                v-if="showBrowse"
                >この投稿を閲覧する</base-overlay-menu-list-item
              >
              <base-overlay-menu-list-item
                to=""
                @click.native="handleReport"
                v-if="showReport"
              >
                {{
                  report_flg
                    ? '※このユーザは通報済みです'
                    : 'このユーザを通報する'
                }}
              </base-overlay-menu-list-item>
              <base-overlay-menu-list-item
                @click.native="handleBlock"
                to=""
                v-if="showBlock"
                >{{
                  blockUser
                    ? 'このユーザのブロックを解除する'
                    : 'ユーザをブロックする'
                }}</base-overlay-menu-list-item
              >
              <base-overlay-menu-list-item
                to=""
                @click.native="handleSpam"
                v-if="showSpam"
              >
                {{ spam_flg ? '※この投稿は通報済みです' : 'この投稿を通報する' }}
              </base-overlay-menu-list-item>
            </base-overlay-menu-list>
          </div>
          <!-- /menu -->

        </div>
      </div>
    </header>
    <section
      :class="[$style.body_wrapper, { [$style.collapse]: this.collapse }, { [$style.read]: this.read }]"
      :style="customStyle"
      @click="handleClick"
    >
      <div v-if="path" :class="$style.picture">
        <img :src="bodyImage" @click="imageClick" alt="" />
        <a
          v-if="
            path != 'junion/post.jpg' &&
            path != 'junion/vote.jpg' &&
            type != 'gift'
          "
          href="#"
          @click.prevent.stop="fileDownload(path)"
        >
          ダウンロードする
        </a>
      </div>
      <div v-if="showVideo" :class="$style.video">
        <iframe
          width="289"
          height="162"
          :src="`https://www.youtube.com/embed/${showVideo}`"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        >
        </iframe>
      </div>
      <div :class="[$style.body_content, { [$style.pin_on_read]: this.read }]">
        <p
          v-if="showPost"
          :class="[
            $style.body_content_text,
            $style.body_content_pre_wrap,
            { [$style.body_content_text_fix]: isHightFix },
          ]"
          v-html="text.length > 70 ? text.slice(0,70) + '...' : text"
        ></p>
      </div>
      <div v-show="switchPost" :style="link_styles">
        投稿を表示しますか？<br />
        <a href="#" @click.prevent.stop="switchPostView()">Yes</a>
      </div>
      <div
        v-if="bodyFileList && bodyFileList.length > 0"
        :class="$style.picture"
      >
        <div v-for="file of pathList(bodyFileList)" :key="file.id">
          <div :class="$style.file">
            <button
              :class="$style.file_download"
              @click="fileDownload(file.path)"
            >
              <img
                :class="$style.file_icon"
                src="@/assets/images/file_download.svg"
                alt="icon"
              />
              {{ file.show_path }}
            </button>
          </div>
        </div>
        <a href="#" @click.prevent.stop="fileListDownload">ダウンロードする</a>
      </div>

      <div :class="$style.body">
        <slot name="body" />
      </div>

      <base-modal
        v-if="showImage"
        type="round"
        imageEnlargementFlg
        @close="showImage = false"
      >
        <template v-slot:body>
          <div :class="$style.modal_content">
            <img :src="bodyImage" :class="$style.modal_image" alt="" />
          </div>
        </template>
      </base-modal>
    </section>
    <div v-if="item.comment_view_type == 1">
      <section
        :class="[$style.body_wrapper, $style.body_wrapper_comment, { [$style.collapse]: this.collapse }]"
        v-if="showPost && comment_latest_user_name.length > 0"
        @click=clickChatOrMiniVoteMove(type,teamId,chatId)
      >
        <div :class="[$style.body_comment, { [$style.pin_on_read]: this.read }]">
          <p :class="$style.body_comment_content">
            <span :class="$style.comment_icon">
              <base-icon size="15" name="comment-dots" />
            </span>
            <span :class="$style.comment_label">
              最新コメント
            </span>
          </p>
          <p :class="$style.body_comment_content">
            <span :class="$style.comment_user">{{
              comment_latest_user_name
            }}</span>
            <span :class="$style.comment_time">{{
              comment_latest_updated_at
            }}</span>
          </p>
          <p :class="$style.comment_text" v-if="item.comment_latest_type == 3">
            <img :class="$style.comment_stamp" :src="item.comment_latest_stamp_url" />
          </p>
          <p :class="$style.comment_type_file" v-else-if="[2, 4].includes(item.comment_latest_type)">
            <img
              :class="$style.file_icon"
              src="@/assets/images/file_download.svg"
              alt="icon"
            />
            {{ item.comment_latest_file_name }}
          </p>
          <p :class="$style.comment_text" v-else>
            <template>
              <div v-html="$sanitize(comment_latest_text)"></div>
            </template>
          </p>
        </div>
        <div :class="$style.body">
          <slot name="body" />
        </div>
      </section>
    </div>
    <div v-else>
      <p :class="$style.comment_private_notes">コメントは管理者のみ表示されます。</p>
    </div>
  </section>
</template>

<script>
import BaseAvatar from '../../base/BaseAvatar/BaseAvatar.vue'
import BaseIcon from '@/components/base/BaseIcon/BaseIcon'
import BaseModal from '@/components/base/BaseModal/BaseModal'
import BaseOverlayMenuList from '@/components/base/BaseOverlayMenuList/BaseOverlayMenuListPhase2.9'
import BaseOverlayMenuListItem from '@/components/base/BaseOverlayMenuList/BaseOverlayMenuListItemPhase2.9'
import BaseTag from '@/components/base/BaseTag/BaseTag'
import {
  editTeamChatFavorite,
  deleteTeamChat,
  editTeamChatPin,
} from '@/helper/common.js'
import { mapGetters } from 'vuex'
import { autoLink } from '@/utils/helper.js'

export default {
  components: {
    BaseOverlayMenuListItem,
    BaseOverlayMenuList,
    BaseAvatar,
    BaseIcon,
    BaseModal,
    BaseTag,
  },
  name: 'AppListItem',
  props: {
    type: {
      type: String,
      validator: (type) =>
        !type ||
        [
          'post', // 投稿
          'vote', // ミニ投票
          'gift', // メッセージ
        ].includes(type),
      default: 'post',
    },
    avatarImage: {
      type: String,
      default: undefined,
    },
    avatarColor: {
      type: String,
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    time: {
      type: String,
      default: undefined,
    },
    clip: {
      type: Boolean,
      default: false,
    },
    pin: {
      type: Boolean,
      default: false,
    },
    read: {
      type: Boolean,
      default: false,
    },
    blockUser: {
      type: Boolean,
      default: false,
    },
    bodyColor: {
      type: String,
      default: '#FFFFFF',
    },
    path: {
      type: String,
      default: '',
    },
    bodyImage: {
      type: String,
      default: null,
    },
    bodyFileList: {
      type: Array,
      default: null,
    },
    collapse: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: null,
    },
    chatId: {
      type: Number,
    },
    readCount: {
      type: String,
      default: '',
    },
    commentCount: {
      type: String,
      default: '',
    },
    goodsCount: {
      type: String,
      default: '',
    },
    favoriteFlg: {
      type: Boolean,
      default: false,
    },
    goodFlg: {
      type: Boolean,
      default: false,
    },
    teamId: {
      type: Number,
    },
    editChatFlg: {
      type: Boolean,
      default: false,
    },
    showPinFlg: {
      type: Boolean,
      default: true,
    },
    deleteAuthFlg: {
      type: Boolean,
      default: false,
    },
    imageEnlargementFlg: {
      type: Boolean,
      default: false,
    },
    showEllipsis: {
      type: Boolean,
      default: true,
    },
    isHightFix: {
      type: Boolean,
      default: false,
    },
    profileUrl: {
      type: String,
      default: '/home/profile',
    },
    showVideo: {
      type: String,
      default: '',
    },
    showFavorite: {
      type: Boolean,
      default: true,
    },
    showShare: {
      type: Boolean,
      default: true,
    },
    showRead: {
      type: Boolean,
      default: true,
    },
    showBlock: {
      type: Boolean,
      default: true,
    },
    showBrowse: {
      type: Boolean,
      default: false,
    },
    showPost: {
      type: Boolean,
      default: true,
    },
    switchPost: {
      type: Boolean,
      default: false,
    },
    showReport: {
      type: Boolean,
      default: true,
    },
    report_flg: {
      type: Boolean,
      default: false,
    },
    showSpam: {
      type: Boolean,
      default: true,
    },
    spam_flg: {
      type: Boolean,
      default: false,
    },
    open_flg: {
      type: Number,
      default: 1,
    },
    start_datetime: {
      type: String,
      default: '',
    },
    end_datetime: {
      type: String,
      default: '',
    },
    commentNoticeFlg: {
      type: Boolean,
      default: false,
    },
    mentionNoticeFlg: {
      type: Boolean,
      default: false,
    },
    comment_latest_user_name: {
      type: String,
      default: '',
    },
    comment_latest_text: {
      type: String,
      default: '',
    },
    comment_latest_updated_at: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      processing: false,
      showImage: false,
      link_styles: {
        color: 'gray',
        fontSize: '9pt',
        textAlign: 'center',
      },
      centerText_styles: {
        color: 'gray',
        fontSize: '9pt',
        textAlign: 'center',
      },
      comment_notice_flg: this.commentNoticeFlg,
      mention_notice_flg: this.mentionNoticeFlg,
      pushType: 1,
    }
  },
  computed: {
    ...mapGetters('auth', ['getUserId']),
    customStyle() {
      return {
        'background-color': `${this.bodyColor}`,
      }
    },
  },
  methods: {
    handleClick() {
      // クリックイベントが発火するように設定
      this.$emit('click')
    },
    handleSelect() {
      this.$emit('select', this.item)
      // this.$emit('read', this.chatId, this.read)
    },
    readIconClick() {
      // this.$emit('read', this.chatId, this.read_flg)
      this.$emit('read', this.item)
    },
    commentIconClick() {
      this.$emit('comment', this.chatId)
    },
    imageClick() {
      this.showImage = true
    },
    handleBlock() {
      this.$emit('blockUser', this.item)
    },
    noticeEditClick() {
      // 通知設定の編集
      this.$router.push(`/team/post-notice-edit/${this.teamId}`)
    },
    handleBrowse() {
      this.$emit('browse', this.item)
    },
    switchPostView() {
      this.$emit('switchPostView', this.item)
    },
    handleReport() {
      // ユーザ通報イベント
      this.$emit('report', this.item)
    },
    handleSpam() {
      //スパム通報イベント
      this.$emit('spam', this.item)
    },
    favoriteClick: async function () {
      if (this.processing) return

      this.processing = true
      let targetFlg = this.favoriteFlg ? 0 : 1
      // 投稿内ユーザー登録・更新 [お気に入りから削除/お気に入りに追加]
      const { status, errors } = await editTeamChatFavorite({
        chat_id: this.chatId,
        favorite_flg: targetFlg,
      })
      this.processing = false
      if (status == 'success') {
        this.$emit('ref-data', {
          type: 'favorite',
          chat_id: this.chatId,
          data: { user_favorite_flg: targetFlg },
          errors,
        })
      }
    },
    editChatClick: function () {
      if (this.processing) return
      if (!this.editChatFlg) return

      if (this.type == 'post') {
        this.$router.push(`/team/post-create/${this.teamId}/${this.chatId}`)
      } else if (this.type == 'vote') {
        this.$router.push(
          `/team/mini-vote-create/${this.teamId}/${this.chatId}`
        )
      }
    },
    clipClick: async function () {
      if (this.processing) return

      this.processing = true
      let targetFlg = this.clip ? 0 : 1
      // 組織TOPでの投稿のピン止めAPI [クリップを外す/クリップする]
      const { status, errors } = await editTeamChatPin({
        chat_id: this.chatId,
        pin_flg: targetFlg,
      })
      this.processing = false
      this.$emit('ref-data', {
        type: 'clip',
        chat_id: this.chatId,
        data: status == 'success' ? targetFlg : targetFlg ^ 1,
        errors,
      })
    },
    shareClick: function () {
      if (this.processing) return

      this.processing = true

      const team_id = this.teamId ?? ''
      const elem = document.createElement('input')
      if (this.type == 'post') {
        // 投稿
        elem.value = `${process.env.VUE_APP_BASE_URL}/team/post-detail/${team_id}/${this.chatId}`
      } else {
        // ミニ投票
        elem.value = `${process.env.VUE_APP_BASE_URL}/team/mini-vote-detail/${team_id}/${this.chatId}`
      }
      document.body.appendChild(elem)
      elem.select()
      document.execCommand('copy')
      document.body.removeChild(elem)
      this.processing = false
      alert('URLをクリップボードにコピーしました')
    },
    readClick: async function () {
      if (this.processing) return

      this.processing = true
      // 投稿内ユーザー登録・更新 [既読可否]
      let targetFlg = this.read ? 0 : 1
      const { status, errors } = await editTeamChatFavorite({
        chat_id: this.chatId,
        read_flg: targetFlg,
      })
      let read_flg = null
      let read_count = null
      if (status == 'success') {
        read_flg = targetFlg
        read_count = this.read
          ? String(Number(this.readCount) - 1)
          : String(Number(this.readCount) + 1)
      } else {
        read_flg = targetFlg ^ 1
        read_count = this.readCount
      }

      this.processing = false
      this.$emit('ref-data', {
        type: 'read',
        chat_id: this.chatId,
        data: { read_flg, read_count },
        errors,
      })
    },
    deleteChatClick: async function () {
      if (!confirm('投稿を削除しますか？')) return
      if (this.processing) return

      this.processing = true
      // 組織の投稿削除
      const { status, errors } = await deleteTeamChat({ chat_id: this.chatId })
      this.processing = false
      this.$emit('ref-data', {
        type: 'delete',
        chat_id: this.chatId,
        data: status == 'success' ? this.chatId : null,
        errors,
      })
    },
    async commentNoticeClick() {
      if (this.processing) return
      this.processing = true

      const { status, errors } = await editTeamChatFavorite({
        chat_id: this.chatId,
        comment_notice_flg: !this.comment_notice_flg,
      })

      if (status == 'success') {
        this.comment_notice_flg = !this.comment_notice_flg
      } else {
        errors
      }

      this.processing = false
    },
    async mentionNoticeClick() {
      if (this.processing) return
      this.processing = true

      const { status, errors } = await editTeamChatFavorite({
        chat_id: this.chatId,
        mention_notice_flg: !this.mention_notice_flg,
      })

      if (status == 'success') {
        this.mention_notice_flg = !this.mention_notice_flg
      } else {
        errors
      }

      this.processing = false
    },
    fileDownload: async function (path) {
      this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/file/download', {
          path: path,
        })
        .then((res) => {
          if (res.data.status == 'success') {
            let base64 = res.data.value.image
            let bin = atob(base64.replace(/^.*,/, ''))
            let buffer = new Uint8Array(bin.length)
            for (var i = 0; i < bin.length; i++) {
              buffer[i] = bin.charCodeAt(i)
            }

            let blob = new Blob([buffer.buffer])
            let a = document.createElement('a')
            a.href = window.URL.createObjectURL(blob)
            a.download = res.data.value.file_name
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
          }
        })
        .finally(() => {
          this.processing = false
        })
    },
    fileListDownload: async function () {
      let file_list = []
      this.bodyFileList.forEach((element) => {
        file_list.push({ path: element.path })
      })

      this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/file/download/list', {
          file_list: file_list,
        })
        .then((res) => {
          if (res.data.status == 'success') {
            res.data.value.file_list.forEach((element) => {
              let base64 = element.image
              let bin = atob(base64.replace(/^.*,/, ''))
              let buffer = new Uint8Array(bin.length)
              for (var i = 0; i < bin.length; i++) {
                buffer[i] = bin.charCodeAt(i)
              }

              let blob = new Blob([buffer.buffer])
              let a = document.createElement('a')
              a.href = window.URL.createObjectURL(blob)
              a.download = element.file_name
              document.body.appendChild(a)
              a.click()
              document.body.removeChild(a)
            })
          }
        })
        .finally(() => {
          this.processing = false
        })
    },
    pathList: function (path_list) {
      if (path_list) {
        path_list.forEach((element) => {
          element['show_path'] = element.path.substring(
            element.path.lastIndexOf('/') + 1
          )
        })
      } else {
        path_list = null
      }

      return path_list
    },
    clickChatOrMiniVoteMove: function (type, team_id, chat_id) {
      let targetUrl = ''
      if(type == 'post' || type == 'gift') {
        targetUrl = `/team/post-detail/${team_id}/${chat_id}/comment`
      }
      else {
        targetUrl = `/team/mini-vote-detail/${team_id}/${chat_id}/comment`
      }
      this.$router.push(targetUrl)
    },
    displayType: function () {
      if (this.open_flg == 0) {
        return 'draft'
      } else {
        if (this.start_datetime.length > 0 && this.end_datetime.length > 0) {
          let startTime = this.$dayjs(this.start_datetime)
          let endTime = this.$dayjs(this.end_datetime)
          if (!this.$dayjs().isBetween(startTime, endTime)) {
            return 'expired'
          }
        }
        return 'normal'
      }
    },
    autoLink,
  },
  watch: {
    goodsCount: function () {
      return this.goodsCount ? Number(this.goodsCount) : 0
    },
    commentCount: function () {
      return this.commentCount ? Number(this.commentCount) : 0
    },
    readCount: function () {
      return this.readCount ? Number(this.readCount) : 0
    },
  },
}
</script>

<style lang="scss" module>
$white: #f9f9f9;
$grey: #c4c4c4;
$red: #f5dcdc;
$yellow: #f1f5dc;
$green: #def5dc;
$blue: #dcf2f5;
$purple: #e2dcf5;
$pink: #f5dced;

.article {
  position: relative;
  border-top: 1px solid $borderLiteGray;
  box-sizing: border-box;
  background: #fff;
  @media screen and (max-width: 768px) {
  }

  &.grey {
    border-color: $white;
  }
  &.grey {
    border-color: $grey;
  }
  &.red {
    border-color: $red;
  }
  &.yellow {
    border-color: $yellow;
  }
  &.green {
    border-color: $green;
  }
  &.blue {
    border-color: $blue;
  }
  &.purple {
    border-color: $purple;
  }
  &.pink {
    border-color: $pink;
  }

  &.clip_on {
    border-top: 2px solid $keyPink;
  }

  &.pin_on {
    border-top: 1px solid #f5dcdc;
  }

  &.read {
    /*opacity: 50%;*/
  }
}
.clip {
  position: absolute;
  top: -16px;
  left: 4px;
  opacity: 0;
}
.clip_on {
  opacity: 1;
  &_read {
    /*opacity: 50%;*/
  }
}

.pin {
  position: absolute;
  top: 0;
  right: 0;
  transform: scale(1.0);
}
.pin_on {
  opacity: 1;
}

.header_wrapper {
  padding: 30px 0 0;

  .header_row1 {
    display: block;
    align-items: center;
    position: relative;

    .title {
      width: 100%;
      margin: 0;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: normal;
      text-align: left;
      color: $keyGreen;
      text-decoration: underline;

      @media screen and (max-width: 768px) {
        width: 16px;
      }
      word-wrap: break-word;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 8;
      cursor: pointer;
    }

    .menu {
      position: absolute;
      width: 28px;
      height: 18px;
      right: 0;
      top: 0;
    }
    .read {
      /*opacity: 50%;*/
    }

    .typeIcon {
      display: inline;
      margin: 0 10px 0 0;
    }
  }

  .header_row2 {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-grow: 1;

    @include mobile() {
      justify-content: flex-end;
      width: 100%;
    }

    .tag_time {
      display: block;
      align-items: center;

      & > *:not(:last-child) {
        margin-right: 10px;
      }

      .time {
        display: inline-block;
        font-size: 10px;
        transform-origin: 0;
        width: 100px;
        margin-right: 0px;
      }
    }
    .tag_block_time {
      display: block;
      align-items: center;
      width: 280px;

      & > *:not(:last-child) {
        margin-left: 10px;
        margin-right: 10px;
      }
      .block_time_text {
        display: inline-block;
        font-size: 10px;
        transform-origin: 0;
        width: 200px;
        margin-right: 10px;
      }
    }

    .counters {
      margin: 0;
      display: inline-block;
      font-size: 10px;
      & > *:not(:last-child) {
        margin-right: 10px;
      }

      .icon {
        margin-right: 10px;
        color: #acacac;
      }
      .count {
        display: inline-block;
        font-size: 15px;
        color: $fontBlack;
        &_active {
          color: $keyPink;
        }
      }

      .browsed {
        margin: 0 10px 0 0;
        display: inline-block;
        align-items: center;
        &.on {
          .icon {
            color: $keyGreen;
          }
        }
      }
      .commented {
        margin: 0 10px 0 0;
        display: inline-block;
        align-items: center;
        &.on {
          .icon {
            color: $keyGreen;
          }
        }
      }
      .like {
        margin: 0;
        display: inline-block;
        align-items: center;
        &.on {
          .icon {
            color: $keyPink;
          }
        }
      }
    }
    &.read {
      opacity: 100%;
    }
    .menu {
      width: 18px;
      height: 18px;
      padding-left: 10px;

      a {
        color:#2dccd3;
      }
    }
  }
}
.body_wrapper_comment {
  background-color: #fff !important;
}
.body_wrapper {
  background-color: $white;
  padding: 15px;
  cursor: pointer;

  &_comment{
    padding: 10px 2.95% 15px;
  }

  .picture {
    width: 200px;
    height: 120px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      display: inline-block;
      object-fit: cover;
    }

    a {
      color: $KeyDarkGreen;
      font-size: 11px;
    }
  }
  .video {
    text-align: center;
    padding: 0 16px 22px 16px;
  }
  .body {
    padding: 0 6px;
    font-size: 10px;
    line-height: 15px;
    color: $fontBlack;
  }

  &.white {
    background-color: $white;
  }
  &.grey {
    background-color: $grey;
  }
  &.red {
    background-color: $red;
  }
  &.yellow {
    background-color: $yellow;
  }
  &.green {
    background-color: $green;
  }
  &.blue {
    background-color: $blue;
  }
  &.purple {
    background-color: $purple;
  }
  &.pink {
    background-color: $pink;
  }

  &.collapse {
    display: flex;
    align-items: center;

    @include mobile() {
      display: block;
    }

    .picture {
      width: 200px;
      max-height: 120px;
      overflow: hidden;
      padding: 0;
      margin: 0 10px 0 0;
      display: flex;
      align-items: center;

      img {
        width: 100%;
        display: inline-block;
        object-fit: cover;
      }
      a {
        display: none;
      }
    }
    .body {
      padding: 0;
      overflow: hidden;
      max-height: 45px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .collpasing {
      width: 20px;
      font-size: 20px;
      display: block;
      align-items: center;
      justify-content: center;
    }
  }
}

.file {
  display: block;
  justify-content: center;
  width: 100%;
  margin-top: 4px;

  .file_download {
    width: 306px;
    border: 1px solid #707070;
    background-color: $keyWhite;
    padding: 11px 10px;
    border-radius: 3px;
    color: #000000;
    text-decoration: none;
    text-align: left;
    cursor: pointer;

    .file_icon {
      display: inline-block;
      padding-right: 6px;
      width: 20px;
      height: 25px;
      vertical-align: middle;
    }
  }

  input {
    display: none;
  }
}
.file_all {
  text-align: right;
  margin: 5px;
}

.file_all_download {
  cursor: pointer;
  color: $keyGreen;
  font-size: 12px;
}

.body_content {
  flex: 1;

  &_text {
    margin: 0;
    font-size: 16px;
    @media (max-width: 768px) {
      font-size: 13px;
    }
    word-wrap: break-word;
    overflow: hidden;

    &_fix {
      min-height: unset;
      word-break: break-word;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 6;
    }
  }
  &_pre_wrap {
    white-space: pre-wrap;
  }
  &_link {
    color: $KeyDarkGreen;
    &:last-child {
      display: block;
      text-align: right;
    }
  }
  .choices_image_container {
    margin: 16px 0;
  }
  &_action {
    margin: 18px 0;
    text-align: center;
  }
}

.modal_content {
  margin: 16px auto;
  width: 80%;
  @include mobile() {
    width: auto;
    margin: 16px;
  }
  &_description {
    font-size: 11px;
  }
  &_name {
    font-size: 16px;
    margin-left: 16px;
  }
  h2 {
    font-size: 14px;
  }
  section:first-of-type {
    margin-bottom: 56px;
  }
}
.modal_image {
  width: 100%;
}
.body_comment {
  .comment_text {
    font-size: 14px;

    @media (max-width: 768px) {
      font-size: 12px;
    }

    margin: 0;
    padding-top: 5px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    overflow: hidden;
    text-align: left;
    word-break: break-all;

    .comment_stamp {
      height: auto;
      width: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
  .comment_type_file {
    border: 1px solid #707070;
    background-color: $keyWhite;
    padding: 4px 4px;
    color: #000000;
    text-decoration: none;
    text-align: left;
    .file_icon {
      display: inline-block;
      padding-right: 6px;
      width: 16px;
      height: 20px;
      vertical-align: middle;
    }
  }
  .body_comment_content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    .comment_icon {
      color: $keyGreen;
      margin: 0px 5px 0px 0px;
    }
    .comment_label {
      font-size: 12px;
      font-weight: bold;
      color: #2dccd3;
    }
    .comment_user {
      font-size: 12px;
      font-weight: bold !important;

      @media (max-width: 768px) {
        font-size: 12px;
        font-weight: normal;
      }
      margin: 0;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      padding-right: 15px;
      text-align: left;
    }

    .comment_time {
      font-size: 12px;
      color: #898585;

      @media (max-width: 768px) {
        font-weight: normal;
        font-size: 12px;
      }
      margin: 0px 0px 0px 2px;
    }
  }
}


// phase2.1
.article {
  .read {
    opacity: 60%;
  }
}
.header_bottom {
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 10px;

}
.header_row1point5{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;

  gap: 0 10px;
  margin: 10px 0 0;
  padding: 0;
  font-size: 13px;
  &.read {
    /*opacity: 50%;*/
  }
  &__text {
    display: flex;
  }
  .user_nickname{
    font-weight: bold;
    padding-right: 15px;
  }
}

.notification {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 5px;

  @include mobile() {
    flex: 1;
    justify-content: flex-start;
  }
}
.notification_button {
  font-size: 10px;
  width: 96px;
  padding: 3px;
  margin-right: 5px;

  @include mobile() {
    font-size: 10px;
    width: 95px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  gap: 3px;
  cursor: pointer;

  background-color: #2dccd3;
  border-radius: 3px;
  color: #fff;

  &__icon {
    width: 10px;
    height: 10px;
    margin-right: 4px;
  }
}
.notification_button_disabled {
  font-size: 10px;
  width: 96px;
  padding: 3px;
  margin-right: 5px;

  @include mobile() {
    font-size: 10px;
    width: 95px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  gap: 3px;
  cursor: pointer;

  background-color: #bcbcbc;
  border-radius: 3px;
  color: #fff;

  &__icon {
    width: 10px;
    height: 10px;
    margin-right: 4px;
  }
}

@include mobile_only() {
  .article {
  }
  .body_content {
    &_text {
      padding-top: 10px;
      font-size: 14px;
      line-height: 1.75;

      &_fix {
        min-height: unset;
        word-break: break-word;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }
    &_pre_wrap {
      white-space: pre-wrap;
    }
    &_link {
      color: $KeyDarkGreen;
      &:last-child {
        display: block;
        text-align: right;
      }
    }
    .choices_image_container {
      margin: 16px 0;
    }
    &_action {
      margin: 18px 0;
      text-align: center;
    }
  }

  .header_wrapper {
    .header_row1 {
      .title {
        width: 100%;
        -webkit-line-clamp: 2;
      }
      .header_row2 {
        display: block;
      }
    }
  }

  .body_comment {
    .comment_text {
      -webkit-line-clamp: 2;
    }
  }

  .header_bottom{
    display: block;
  }
  .header_row1point5{
    padding: 0 0 10px 0;
  }
}

.comment_private_notes {
  color: red;
}

.cursor_pointer {
  cursor: pointer;
}
</style>
