<template>
  <div>
    <app-menu v-on="$listeners">
      <template v-slot:title>くみあいアプリ<br />MENU</template>
      <template v-slot:body>
        <div :class="$style.home_menu_content_body">
          <div :class="$style.square_button_group">
            <base-file-input
              name="qr-reader"
              type="qr"
              @click.native.stop.prevent="showQrReader = true"
            />
            <base-button
              width="100px"
              height="100px"
              rounded="corner"
              @click.native="showQrGrenerator = true"
            >
              <div :class="$style.qr_viewer_wrap">
                <base-icon
                  name="arrow-up"
                  size="14px"
                  :class="$style.qr_viewer_arrow"
                />
                <img
                  :class="$style.qr_viewer_icon"
                  src="@/assets/images/qr-icon.svg"
                  alt=""
                />
                <span :class="$style.qr_viewer_caption">MyQR表示</span>
              </div>
            </base-button>

            <base-button
              width="100px"
              height="100px"
              rounded="corner"
              @click.native="showInputJuId = true"
            >
              <div :class="$style.t_id_wrap">
                <img
                  :class="$style.t_id_icon"
                  src="@/assets/images/t-id-icon.svg"
                  alt=""
                />
                <span :class="$style.t_id_caption">ID入力</span>
              </div>
            </base-button>
          </div>
          <div :class="$style.bar_button_group">
            <base-button
              v-show="showCreateTeam"
              width="100%"
              height="40px"
              display="inline-flex"
              rounded="corner"
              @click="$router.push(`/team/create`)"
            >
              <img
                :class="$style.user_plus_icon"
                src="@/assets/images/user-plus-icon.svg"
                alt=""
              />
              <span :class="$style.user_plus_caption">新しいチームを作成</span>
            </base-button>

            <base-button
              width="100%"
              height="40px"
              display="inline-flex"
              rounded="corner"
              bg-color="white"
              @click="$router.push(`/home/profile_edit`)"
            >
              <div :class="$style.bar_button_content_wrap">
                <base-icon
                  :class="$style.bar_button_icon"
                  name="address-card"
                  color="green"
                />
                <span :class="$style.bar_button_caption">プロフィール</span>
              </div>
            </base-button>

            <!-- <base-button
              width="100%"
              height="40px"
              display="inline-flex"
              rounded="corner"
              bg-color="white"
            >
              <div :class="$style.bar_button_content_wrap">
                <base-icon :class="$style.bar_button_icon" name="bell" type="far" color="green"/>
                <span :class="$style.bar_button_caption">プッシュ通知設定</span>
              </div>
            </base-button> -->

            <!-- <base-button
              width="100%"
              height="40px"
              display="inline-flex"
              rounded="corner"
              bg-color="white"
            >
              <div :class="$style.bar_button_content_wrap">
                <base-icon :class="$style.bar_button_icon" name="lock" color="green"/>
                <span :class="$style.bar_button_caption">認証設定の変更</span>
              </div>
            </base-button> -->

            <!-- <base-button
              width="100%"
              height="40px"
              display="inline-flex"
              rounded="corner"
              bg-color="white"
            >
              <div :class="$style.bar_button_content_wrap">
                <base-icon :class="$style.bar_button_icon" name="exchange-alt" color="green"/>
                <span :class="$style.bar_button_caption">連携</span>
              </div>
            </base-button> -->

            <base-button
              width="100%"
              height="40px"
              display="inline-flex"
              rounded="corner"
              bg-color="white"
              @click="clickLogout"
            >
              <div :class="$style.bar_button_content_wrap">
                <img
                  :class="$style.bar_button_icon"
                  src="@/assets/images/logout-icon.svg"
                />
                <span :class="$style.bar_button_caption">ログアウト</span>
              </div>
            </base-button>
            <base-button
              width="100%"
              height="40px"
              display="inline-flex"
              rounded="corner"
              bg-color="white"
              @click="$router.push(`/home/notice_edit`)"
            >
              <div :class="$style.bar_button_content_wrap">
                <base-icon
                  :class="$style.bar_button_icon"
                  name="bell"
                  type="far"
                  color="green"
                />
                <span :class="$style.bar_button_caption">通知設定</span>
              </div>
            </base-button>

            <base-button
              width="100%"
              height="40px"
              display="inline-flex"
              rounded="corner"
              bg-color="white"
              @click="$router.push(`/home/change_auth`)"
            >
              <div :class="$style.bar_button_content_wrap">
                <base-icon
                  :class="$style.bar_button_icon"
                  name="lock"
                  color="green"
                />
                <span :class="$style.bar_button_caption">ログイン情報の変更</span>
              </div>
            </base-button>

            <hr
              style="
                background-color: rgb(252, 252, 252);
                border: none;
                height: 2px;
              "
            />

            <base-button
              width="100%"
              height="40px"
              display="inline-flex"
              rounded="corner"
              bg-color="white"
              @click="clickFaq"
            >
              <div :class="$style.bar_button_content_wrap">
                <img
                  :class="$style.bar_button_icon"
                  src="@/assets/images/contact_support_black_24dp.png"
                />
                <span :class="$style.bar_button_caption">よくあるご質問</span>
              </div>
            </base-button>

            <base-button
              width="100%"
              height="40px"
              display="inline-flex"
              rounded="corner"
              bg-color="white"
              @click="clickContact"
            >
              <div :class="$style.bar_button_content_wrap">
                <img
                  :class="$style.bar_button_icon"
                  src="@/assets/images/email_black_24dp.png"
                />
                <span :class="$style.bar_button_caption">お問い合わせ</span>
              </div>
            </base-button>

            <base-button
              width="100%"
              height="40px"
              display="inline-flex"
              rounded="corner"
              bg-color="white"
              @click="clickAbout"
            >
              <div :class="$style.bar_button_content_wrap">
                <img
                  :class="$style.bar_button_icon"
                  src="@/assets/images/business_black_24dp.png"
                />
                <span :class="$style.bar_button_caption">運営会社について</span>
              </div>
            </base-button>

            <base-button
              width="100%"
              height="40px"
              display="inline-flex"
              rounded="corner"
              bg-color="white"
              @click="clickPrivacyPolicy"
            >
              <div :class="$style.bar_button_content_wrap">
                <img
                  :class="$style.bar_button_icon"
                  src="@/assets/images/awesome-id-badge.png"
                />
                <span :class="$style.bar_button_caption">個人情報保護方針</span>
              </div>
            </base-button>
          </div>
        </div>
      </template>
    </app-menu>

    <base-modal
      v-if="showQrReader"
      type="round"
      :hideContentHeader="true"
      @close="showQrReader = false"
    >
      <template v-slot:body>
        <div :class="$style.modal_content">
          <div>
            <p :class="$style.qr_read_error" v-if="qr_read_error">
              {{ qr_read_error }}
            </p>
            <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit">
              <div :class="$style.qr_read_loading" v-if="qr_read_loading">
                <base-icon name="spinner" size="32px" spin />
              </div>
            </qrcode-stream>
          </div>
        </div>
      </template>
    </base-modal>

    <base-modal
      v-if="showQrGrenerator"
      type="round"
      :hideContentHeader="true"
      @close="showQrGrenerator = false"
    >
      <template v-slot:body>
        <div :class="$style.modal_content">
          <vue-qrcode
            v-if="displayQrGeneratorUrl"
            :value="displayQrGeneratorUrl"
            :options="qr_generator_options"
            tag="img"
          ></vue-qrcode>
        </div>
      </template>
    </base-modal>

    <base-modal
      v-if="showInputJuId"
      type="round"
      :hideContentHeader="true"
      @close="showInputJuId = false"
    >
      <template v-slot:body>
        <div :class="$style.modal_content">
          <base-form-field
            :noBorder="true"
            name="input-ju-id"
            align="center"
            label="ju-IDを入力して下さい。"
          >
            <base-input
              name="input-user-id"
              type="text"
              v-model.trim="inputJuIdText"
              :error="errorInputJuIdMsg ? errorInputJuIdMsg : ''"
            />
          </base-form-field>
        </div>
      </template>
      <template v-slot:footer>
        <base-button
          rounded="rounded"
          width="120px"
          @click="clickJuId"
          v-if="!juidInputLoading"
          >確認</base-button
        >
        <div :class="$style.juid_input_loading" v-if="juidInputLoading">
          <base-icon name="spinner" size="32px" spin />
        </div>
      </template>
    </base-modal>
  </div>
</template>

<script>
import BaseButton from '@/components/base/BaseButton/BaseButton'
import BaseFileInput from '@/components/base/BaseFileInput/BaseFileInput'
import BaseIcon from '@/components/base/BaseIcon/BaseIcon'
import AppMenu from '@/components/app/AppMenu/AppMenu/AppMenu'
import { QrcodeStream } from 'vue-qrcode-reader'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import BaseModal from '@/components/base/BaseModal/BaseModal'
import BaseInput from '@/components/base/BaseInput/BaseInput'
import BaseFormField from '@/components/base/BaseFormField/BaseFormField'
import { mapMutations, mapGetters } from 'vuex'
import { logoutApi, fetchUserInfo } from '@/helper/common.js'

export default {
  name: 'AppOpenMenu',
  components: {
    BaseButton,
    BaseIcon,
    BaseFileInput,
    AppMenu,
    BaseModal,
    QrcodeStream,
    VueQrcode,
    BaseInput,
    BaseFormField,
  },
  props: {},
  computed: {
    ...mapGetters('auth', ['getUserId']),
  },
  data() {
    return {
      showQrReader: false,
      camera: 'auto',
      qr_read_error: '',
      qr_read_loading: false,
      qr_reading: false,

      showQrGrenerator: false,
      displayQrGeneratorUrl: '',
      qr_generator_options: {
        errorCorrectionLevel: 'M',
        maskPattern: 0,
        margin: 2,
        scale: 2,
        width: 300,
        color: {
          dark: '#000000FF',
          light: '#FFFFFFFF',
        },
      },
      showInputJuId: false,
      inputJuIdText: '',
      errorInputJuIdMsg: '',
      juidInputLoading: false,
      showCreateTeam: false,
    }
  },
  mounted() {
    this.screenAuthTeamPublic()
  },
  methods: {
    ...mapMutations(['logout']),
    async onDecode(content) {
      if (this.qr_reading) return true

      this.qr_reading = true
      this.qr_read_error = ''
      this.turnCameraOff()
      await this.timeout(1000)
      if (content.startsWith('http')) {
        this.showQrReader = false
        this.qr_reading = false
        window.location.href = content
        return
      }

      this.qr_read_error = 'http/https形式以外のコードを読み取りました。'
      await this.timeout(2000)
      this.turnCameraOn()
      this.qr_reading = false
    },
    isValidJuId() {
      this.errorInputJuIdMsg = ''
      const keymsg = 'ju-ID'
      if (!this.inputJuIdText) {
        this.errorInputJuIdMsg = `${keymsg}を入力して下さい。`
        return false
      }
      if (this.inputJuIdText.length > 20) {
        this.errorInputJuIdMsg = `${keymsg}は20文字以内で入力してください。`
        return false
      }
      return true
    },
    async onInit(promise) {
      this.qr_read_loading = true
      this.qr_read_error = ''
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.qr_read_error =
            'ERROR: カメラへのアクセス許可を与える必要があります'
        } else if (error.name === 'NotFoundError') {
          this.qr_read_error = 'ERROR: このデバイスにはカメラが存在しません。'
        } else if (error.name === 'NotSupportedError') {
          this.qr_read_error =
            'ERROR: 安全なコンテキストが必要です。（HTTPS、ローカルホスト）'
        } else if (error.name === 'NotReadableError') {
          this.qr_read_error =
            'ERROR: カメラはすでに使用されている可能性があります。'
        } else if (error.name === 'OverconstrainedError') {
          this.qr_read_error =
            'ERROR: 取り付けられたカメラは適切ではありません。'
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.qr_read_error =
            'ERROR: Stream APIはこのブラウザではサポートされていません。'
        } else if (error.name === 'InsecureContextError') {
          this.qr_read_error =
            'ERROR: カメラへのアクセスは、安全な状況でのみ許可されます。 HTTPではなくHTTPSまたはlocalhostを使用します。'
        } else {
          this.qr_read_error = `ERROR: カメラエラー (${error.name})`
        }
      } finally {
        this.qr_read_loading = false
      }
    },
    turnCameraOn() {
      this.camera = 'auto'
    },
    turnCameraOff() {
      this.camera = 'off'
    },
    timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms)
      })
    },
    clickJuId: async function () {
      if (this.isValidJuId()) {
        this.juidInputLoading = true
        const { status, value } = await fetchUserInfo({
          juid: this.inputJuIdText,
        })
        this.juidInputLoading = false
        if (status == 'success') {
          if (value?.user_id) {
            this.showInputJuId = false
            window.location.href = `${process.env.VUE_APP_BASE_URL}/home/profile/${value?.user_id}`
            return
          }
        }
        this.errorInputJuIdMsg = '該当するユーザーは存在しません。'
      }
    },
    clickLogout: async function () {
      await logoutApi()
      this.logout()
      this.$cookies.remove('auth_info')
      window.location.href = `${process.env.VUE_APP_BASE_URL}/open/login`
    },
    clickFaq: function () {
      this.$router.push({ name: 'Faq' })
    },
    clickContact: function () {
      this.$router.push({ name: 'Contact' })
    },
    clickAbout: function () {
      this.$router.push({ name: 'About' })
    },
    clickPrivacyPolicy: function () {
      this.$router.push({ name: 'PrivacyPolicy' })
    },
    screenAuthTeamPublic: function () {
      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/screen_auth/team/public')
        .then((res) => {
        if (res.data.status == 'success') {
          this.showCreateTeam = res.data.value.belong
        }
      })
    },
  },
  watch: {
    showQrReader(nv, ov) {
      if (!nv && ov) {
        this.qr_read_error = ''
        this.turnCameraOff()
      }
      if (nv && !ov) {
        this.turnCameraOn()
      }
    },
    showQrGrenerator(nv, ov) {
      if (!nv && ov) {
        this.displayQrGeneratorUrl = ''
      }
      if (nv && !ov) {
        this.displayQrGeneratorUrl = `${process.env.VUE_APP_BASE_URL}/home/profile/${this.getUserId}`
      }
    },
    showInputJuId(nv, ov) {
      if (!nv && ov) {
        this.inputJuIdText = ''
        this.errorInputJuIdMsg = ''
      }
    },
  },
}
</script>

<style lang="scss" module>
.home_menu_content_body {
  margin: 0 24px 0 24px;
}

.square_button_group {
  display: flex;
  justify-content: space-between;
  @include mobile() {
    justify-content: center;
    & > *:not(:last-child) {
      margin-right: 10px;
    }
  }

  .qr_viewer {
    &_wrap {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      height: 100px;
      padding-top: 10px;
      box-sizing: border-box;
    }
    &_arrow {
      margin-bottom: 8px;
    }
    &_icon {
      width: 28px;
      height: 28px;
      margin-bottom: 10px;
    }
    &_caption {
      font-size: 13px;
      font-weight: bold;
      color: $fontWhite;
    }
  }

  .t_id {
    &_wrap {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      height: 100px;
      padding-top: 30px;
      box-sizing: border-box;
    }
    &_icon {
      width: 34px;
      height: 28px;
      margin-bottom: 10px;
    }
    &_caption {
      font-size: 13px;
      font-weight: bold;
      color: $fontWhite;
    }
  }
}

.bar_button_group {
  margin-top: 10px;
  & > * {
    &:first-child {
      margin-bottom: 24px;
    }
    margin-bottom: 10px;
  }

  .user_plus_icon {
    width: 24px;
    height: 14px;
    margin-right: 10px;
  }

  .user_plus_caption {
    font-size: 13px;
    font-weight: bold;
    color: $fontWhite;
  }

  .bar_button_content_wrap {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .bar_button_icon {
    margin-right: 16px;
  }

  .bar_button_caption {
    font-size: 13px;
    font-weight: normal;
    color: $fontBlack;
  }
}

.modal_content {
  margin: 20px;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  @include mobile() {
    width: auto;
    margin: 20px;
  }

  .qr_read_loading,
  .juid_input_loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .qr_read_error {
    display: flex;
    width: auto;
    justify-content: center;
    align-items: center;
    color: $keyRed;
    border-color: $keyRed;
  }

  // .invite_check_note {
  //   margin-top: 0;
  //   margin-bottom: 24px;
  //   font-size: 11px;
  // }
  // .invite_check_wrap {
  //   margin-bottom: 12px;
  // }
}
</style>
