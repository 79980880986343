<template>
  <div :class="$style.union_course_quiz">
    <app-prev-button @click="$router.go(-1)" margin="0 0 24px" />
    <div :class="$style.union_course_quiz_content_wrap">
      <div :class="$style.union_course_quiz_content">
        <div :class="$style.content_header">
          <p :class="$style.content_header_title">視聴確認クイズ</p>
          <p :class="$style.content_header_caption">
            クイズに正解すると受講完了になります。
          </p>
          <h1 :class="$style.content_header_course_title">{{ title }}</h1>
        </div>

        <div :class="$style.content_body">
          <div :class="$style.question">
            <h2 :class="$style.question_title">
              Q. どんな場面で活用できそうですか？
            </h2>
            <p :class="$style.question_caption">
              当てはまるものを全てお選びください。
            </p>
          </div>

          <div :class="$style.answer_radio">
            <base-check-box name="answer" v-model="union_activity_flg" :disabled="is_answered"
              ><span :class="$style.answer_radio_item"
                >組合活動</span
              ></base-check-box
            >
            <base-check-box name="answer" v-model="work_flg" :disabled="is_answered"
              ><span :class="$style.answer_radio_item"
                >仕事</span
              ></base-check-box
            >
            <base-check-box name="answer" v-model="everyday_life_flg" :disabled="is_answered"
              ><span :class="$style.answer_radio_item"
                >日常生活</span
              ></base-check-box
            >
            <base-check-box name="answer" v-model="hobby_leisure_flg" :disabled="is_answered"
              ><span :class="$style.answer_radio_item"
                >趣味・余暇活動</span
              ></base-check-box
            >
            <base-check-box name="answer" v-model="relationship_flg" :disabled="is_answered"
              ><span :class="$style.answer_radio_item"
                >人間関係</span
              ></base-check-box
            >
            <base-check-box name="answer" v-model="network_formation_flg" :disabled="is_answered"
              ><span :class="$style.answer_radio_item"
                >人脈形成</span
              ></base-check-box
            >
            <base-check-box name="answer" v-model="self_career_flg" :disabled="is_answered"
              ><span :class="$style.answer_radio_item"
                >自己キャリア</span
              ></base-check-box
            >
            <base-check-box name="answer" v-model="team_create_flg" :disabled="is_answered"
              ><span :class="$style.answer_radio_item"
                >組織づくり</span
              ></base-check-box
            >
            <base-check-box name="answer" v-model="leadership_flg" :disabled="is_answered"
              ><span :class="$style.answer_radio_item"
                >リーダーシップを発揮する</span
              ></base-check-box
            >
            <base-check-box name="answer" v-model="eliminate_worry_flg" :disabled="is_answered"
              ><span :class="$style.answer_radio_item"
                >悩みの解消</span
              ></base-check-box
            >
            <base-check-box name="answer" v-model="junior_training_flg" :disabled="is_answered"
              ><span :class="$style.answer_radio_item"
                >後輩の育成</span
              ></base-check-box
            >
            <base-check-box name="answer" v-model="boss_flg" :disabled="is_answered"
              ><span :class="$style.answer_radio_item"
                >上司との付き合い</span
              ></base-check-box
            >
            <base-check-box name="answer" v-model="political_activity_flg" :disabled="is_answered"
              ><span :class="$style.answer_radio_item"
                >政治活動</span
              ></base-check-box
            >
            <base-check-box name="answer" v-model="negotiation_company__flg" :disabled="is_answered"
              ><span :class="$style.answer_radio_item"
                >会社との交渉</span
              ></base-check-box
            >
            <base-check-box name="answer" v-model="management_flg" :disabled="is_answered"
              ><span :class="$style.answer_radio_item"
                >マネジメント</span
              ></base-check-box
            >
          </div>
          <div :class="$style.answer_triangle"></div>
          <div :class="$style.next_button_wrap">
            <base-button width="120px" rounded="rounded" @click="save"
              >次へ</base-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppPrevButton from '@/components/app/AppPrevButton/AppPrevButton'
// import BaseRadio from '@/components/base/BaseRadio/BaseRadio'
import BaseButton from '@/components/base/BaseButton/BaseButton'
import BaseCheckBox from '@/components/base/BaseCheckBox/BaseCheckBox'
export default {
  name: 'UnionCourseStaticQuiz2',
  components: { AppPrevButton, BaseButton, BaseCheckBox },
  data() {
    return {
      quiz: '',
      title: '',
      learn_content_flg: false,
      union_activity_flg: false,
      work_flg: false,
      everyday_life_flg: false,
      hobby_leisure_flg: false,
      relationship_flg: false,
      network_formation_flg: false,
      self_career_flg: false,
      team_create_flg: false,
      leadership_flg: false,
      eliminate_worry_flg: false,
      junior_training_flg: false,
      boss_flg: false,
      political_activity_flg: false,
      negotiation_company__flg: false,
      management_flg: false,
      lecture_id: '',
      is_answered: true,
    }
  },
  mounted() {
    this.$router.app.$emit('page-updated', {
      pageTitle: '視聴確認クイズ',
      headerColor: 'yellow',
      back: '__history_back',
      showSetting: false,
      bgColor: 'yellow',
    })
  },
  created() {
    let url = '/api/' + this.$constants.API_VERSION + '/lecture/info?lecture_id=' + this.$route.params.lecture_id
    this.lecture_id = this.$route.params.lecture_id
    this.$axios.get(url, {}).then((res) => {
      let data = res.data.value.lecture
      this.title = data.title
      this.quiz = data.quiz
    })

    let url2 =
      '/api/' + this.$constants.API_VERSION + '/lecture_history/list?lecture_id=' + this.$route.params.lecture_id
    this.$axios.get(url2, {}).then((res) => {
      let data = res.data.value.user
      this.learn_content_flg = !!data.union_activity_flg
      this.union_activity_flg = !!data.union_activity_flg
      this.work_flg = !!data.work_flg
      this.everyday_life_flg = !!data.everyday_life_flg
      this.hobby_leisure_flg = !!data.hobby_leisure_flg
      this.relationship_flg = !!data.relationship_flg
      this.network_formation_flg = !!data.network_formation_flg
      this.self_career_flg = !!data.self_career_flg
      this.team_create_flg = !!data.team_create_flg
      this.leadership_flg = !!data.leadership_flg
      this.eliminate_worry_flg = !!data.eliminate_worry_flg
      this.junior_training_flg = !!data.junior_training_flg
      this.boss_flg = !!data.boss_flg
      this.political_activity_flg = !!data.political_activity_flg
      this.negotiation_company__flg = !!data.negotiation_company__flg
      this.management_flg = !!data.management_flg
      this.is_answered = res.data.value.is_answered_leadership_flg
    })
  },
  methods: {
    save: function () {
      if (this.is_answered) {
        if (this.$route.params.corner_id) {
            this.$router.push(
              '/union/course_quiz_result/' + this.$route.params.corner_id
            )
          } else {
            this.$router.push('/union/course_quiz_result')
          }
      } else {
        this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/lecture_history/registration', {
          lecture_id: this.$route.params.lecture_id,
          union_activity_flg: String(Number(this.union_activity_flg)),
          work_flg: String(Number(this.work_flg)),
          everyday_life_flg: String(Number(this.everyday_life_flg)),
          hobby_leisure_flg: String(Number(this.hobby_leisure_flg)),
          relationship_flg: String(Number(this.relationship_flg)),
          network_formation_flg: String(Number(this.network_formation_flg)),
          self_career_flg: String(Number(this.self_career_flg)),
          team_create_flg: String(Number(this.team_create_flg)),
          leadership_flg: String(Number(this.leadership_flg)),
          eliminate_worry_flg: String(Number(this.eliminate_worry_flg)),
          junior_training_flg: String(Number(this.junior_training_flg)),
          boss_flg: String(Number(this.boss_flg)),
          political_activity_flg: String(Number(this.political_activity_flg)),
          negotiation_company__flg: String(
            Number(this.negotiation_company__flg)
          ),
          management_flg: String(Number(this.management_flg)),
        })
        .then(() => {
          if (this.$route.params.corner_id) {
            this.$router.push(
              '/union/course_quiz_result/' + this.$route.params.corner_id
            )
          } else {
            this.$router.push('/union/course_quiz_result')
          }
        })
      }
    },
  },
}
</script>

<style lang="scss" module>
.union_course_quiz {
  display: flex;
  width: 960px;
  flex-direction: column;
  align-items: center;
  padding: 12px 0 48px 0;
  margin: 0 auto;
  @include mobile() {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}

.union_course_quiz_content_wrap {
  width: 100%;
  background-color: $keyWhite;
  padding: 24px 150px;
  box-sizing: border-box;

  @include mobile() {
    padding: 22px 32px;
  }
}

.union_course_quiz_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content_header {
  width: 100%;
  text-align: center;
  margin-bottom: 12px;
  border-bottom: 1px solid #dddddd;
  @include mobile() {
    margin-bottom: 16px;
  }

  &_title {
    font-size: 19px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 8px;
    @include mobile() {
      display: none;
    }
  }
  &_caption {
    font-size: 11px;
    font-weight: bold;
    color: $keyPink;
    margin-top: 0;
    margin-bottom: 12px;
  }
  &_course_title {
    font-size: 19px;
    margin-top: 0;
    margin-bottom: 16px;
    @include mobile() {
      font-size: 16px;
      margin-bottom: 24px;
    }
  }
}

.content_body {
  width: 660px;
  @include mobile() {
    width: 100%;
  }
  .question {
    margin-bottom: 12px;
    @include mobile() {
      margin-bottom: 16px;
    }

    &_title {
      font-size: 20px;
      color: $keyPink;
      margin-top: 0;
      margin-bottom: 8px;
      @include mobile() {
        font-size: 16px;
        margin-bottom: 12px;
      }
    }
    &_caption {
      font-size: 12px;
      font-weight: bold;
      margin: 0;
    }
  }

  .answer_radio {
    border: 5px solid $keyPink;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 24px;
    margin-bottom: 16px;
    @include mobile() {
      margin-bottom: 16px;
    }

    &_item {
      font-size: 16px;
    }
  }

  .answer_triangle {
    display: none;
    @include mobile() {
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 15px 20px 0 20px;
      border-color: #dddddd transparent transparent transparent;
      margin: 0 auto 20px auto;
    }
  }

  .result {
    background-color: $openBg;
    border-radius: 10px;
    padding: 16px 20px 20px 20px;
    margin-bottom: 8px;
    @include mobile() {
      margin-bottom: 20px;
    }

    &_header {
      display: flex;
      margin-bottom: 14px;
      span {
        font-size: 16px;
        font-weight: bold;
        margin-left: 8px;
        color: $keyPink;
      }
    }
    &_description {
      font-size: 11px;
      color: $fontBlack;
      margin: 0;
      @include mobile() {
        font-size: 13px;
      }
    }
  }
}

.next_button_wrap {
  display: flex;
  justify-content: center;
}
</style>
