<template>
  <div :class='$style.profile_confirm'>
    <h1 :class='$style.title'>プロフィール登録（確認）</h1>

    <div :class='$style.profile_wrap'>
      <base-avatar :path="inputProfileImage"/>
    </div>
    <span :class='$style.ju_id'>JU-ID: {{$store.state.auth.juid}}</span>

    <dl :class='$style.profile_list'>
      <div :class='$style.profile_list_item'>
        <dt>名前</dt>
        <dd>{{inputName}}</dd>
      </div>
      <div :class='$style.profile_list_item'>
        <dt>ニックネーム</dt>
        <dd>{{inputNickname}}</dd>
      </div>
      <div :class='$style.profile_list_item'>
        <dt>プロフィール</dt>
        <dd>{{inputProfile}}</dd>
      </div>
      <div :class='$style.profile_list_item'>
        <dt>メールアドレス</dt>
        <dd>{{inputEmail}}</dd>
      </div>
      <div :class='$style.profile_list_item'>
        <dt>生年月日</dt>
        <dd>{{displayBirthday}}</dd>
      </div>
    </dl>

    <p v-if="error_text" :class="$style.error_txt">{{ error_text }}</p>

    <div :class='$style.next_button_wrap'>
      <base-button display='inline-block' width='40%' height='50px' style="margin: 10px" @click="$router.go(-1)">
        修正する
      </base-button>
      <base-button display='inline-block' width='40%' height='50px' style="margin: 10px" @click="registration">
        設定完了
      </base-button>
    </div>
  </div>

</template>

<script>
import BaseAvatar from '@/components/base/BaseAvatar/BaseAvatar'
import BaseButton from '@/components/base/BaseButton/BaseButton'
import VueCookies from "vue-cookies"
const cookies = VueCookies

export default {
  name: 'ProfileConfirm',
  components: { BaseButton, BaseAvatar },
  data: function() {
    return {
      inputProfileImage: this.$store.state.profile_edit.profile_image,
      inputName: this.$store.state.profile_edit.name,
      inputNickname: this.$store.state.profile_edit.nickname,
      inputProfile: this.$store.state.profile_edit.profile,
      inputEmail: this.$store.state.profile_edit.email,
      inputBirthday: this.$dayjs(this.$store.state.profile_edit.birthday).format('YYYYMMDD'),
      displayBirthday: this.$dayjs(this.$store.state.profile_edit.birthday).format("YYYY年MM月DD日"),

      error_text: "",

      registration_sending: true,

      invite_flow: {
        type: '',
        team_id: null
      }
    }
  },
  created: async function(){
    if(this.$store.state.invite.token) {
      const invite_flow = await this.fetchInviteFlow(this.$store.state.invite.token)

      this.invite_flow.type = invite_flow.type
      this.invite_flow.team_id = invite_flow.team_id
    }

    // 以下のフラグを立てていると上記終わる前にポストされる可能性があるのでここでフラグを折る
    this.registration_sending = false
  },
  methods: {
    registration: async function(){
      if(this.registration_sending){
        return
      }
      this.registration_sending = true

      const birthday = this.$dayjs(this.inputBirthday).format("YYYYMMDD")
      let profileImage = null
      if(this.inputProfileImage){
        profileImage = this.inputProfileImage.split(',')[1]
      }

      const post_data = {
        "name": this.inputName,
        "nickname": this.inputNickname,
        "profile": this.inputProfile,
        "birthday": birthday,
        "profile_image": profileImage,
        "registration_flow": this.invite_flow.type,
        "pc_flg": 1 // VueがPC扱いなのでフラグを立てる
      }
      if (this.inputEmail != this.$store.state.auth.tel_or_email) {
        // 認証のメールアドレスと違うメールアドレスの場合にメールアドレスのパラメータ設定
        post_data['email'] = this.inputEmail
      }

      await this.$axios.post('/api/' + this.$constants.API_VERSION + '/user/registration', post_data).then((res) => {
        this.registration_sending = false

        const user = res.data.value.user
        this.$store.commit('auth/setUserId', user.user_id)

        // 入力データを削除
        this.$store.commit('profile_edit/setProfileImage', null)
        this.$store.commit('profile_edit/setName', null)
        this.$store.commit('profile_edit/setNickname', null)
        this.$store.commit('profile_edit/setProfile', null)
        this.$store.commit('profile_edit/setEmail', null)
        this.$store.commit('profile_edit/setBirthday', null)

        this.switchNextRouting()
      }).catch((err) => {
        if(err.response.status === 400){
          err.response.data.value.errors.forEach(message => {
            this.error_text = message.message
          })
        }
      })
    },
    switchNextRouting: async function(){
      if(this.$store.state.invite.token){
        if(['team_admin_url', 'union_salon'].includes(this.invite_flow.type)){
          await this.$router.push('/team/info/' + this.invite_flow.team_id)
          return //後ろの関数が走るのでreturnで終了
        }else if(this.invite_flow.type === 'team_admin'){
          await this.$router.push('/team/create/')
          return //後ろの関数が走るのでreturnで終了
        }
      }else if(cookies.isKey('redirect_url')){
        //cookieにURLが保存されているとき
        let team_info = cookies.get('redirect_url')
        cookies.remove('redirect_url')
        await this.$router.push(team_info)
        return //後ろの関数が走るのでreturnで終了
      }

      await this.$router.push('/home/home')
    },
    fetchInviteFlow: async function(token){
      let invite_flow = {}

      await this.$axios('/api/' + this.$constants.API_VERSION + '/invite/info', {
        params: {
          token: token
        }
      }).then((res) => {
        invite_flow = res.data.value
      })

      return invite_flow
    }
  }
}
</script>
<style lang='scss' module>
.profile_confirm {
  display: flex;
  flex-direction: column;
  width: 375px;
  margin: 0 auto;
  padding: 42px 32px 30px 32px;
  box-sizing: border-box;
  text-align: left;
}

.title {
  margin-top: 0;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 50px;
}

.profile_wrap {
  display: flex;
  justify-content: center;
  margin-bottom: 6px;
}

.ju_id {
  font-size: 13px;
  color: $fontGray;
  text-align: center;
  margin-bottom: 30px;
}

.profile_list {
  width: 100%;
  padding: 0;
  margin: 0;

  &_item {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;

    dt, dd {
      color: $fontGray;
      line-height: 24px;
      margin: 0;
    }

    dt {
      width: 35%;
      font-size: 11px;
    }

    dd {
      width: 65%;
      font-size: 16px;
    }
  }
}

.next_button_wrap {
  margin-top: auto;
}

.error_txt {
  font-size: 13px;
  margin: 4px 0 0 0;
  color: #f00;
}
</style>
