<template>
  <!-- <section :class="classObject" :style="customStyle"> -->
  <section :class="[classObject, $style.base_list]" :style="customStyle">
    <div
      :class="[$style.list_title_wrap, $style.normal]"
      v-if="type === 'normal'"
    >
      <div :class="$style.list_title">
        <base-img :path="path" :class="$style.list_title_img" :size="imgSize" />
        <h1 :class="[$style.list_title_text]">
          {{ title }}
        </h1>
      </div>
    </div>
    <div
      v-else-if="type === 'more'"
      :class="[$style.list_title_wrap, $style.more]"
    >
      <div :class="$style.list_title">
        <base-img :path="path" :class="$style.list_title_img" :size="imgSize" />
        <h1 :class="[$style.list_title_text]">
          {{ title }}
        </h1>
      </div>
      <div :class="[$style.list_action, $style.pointer]" @click="$router.push(moreAction)">
        <base-img
          :path="moreIconSrc"
          :class="$style.list_action_img"
          size="24"
        />
        <p :class="[$style.list_action_text, $style.more]">{{ actionText }}</p>
      </div>
    </div>
    <div
      v-else-if="type === 'add'"
      :class="[$style.list_title_wrap, $style.add]"
    >
      <div :class="$style.list_title">
        <base-img :path="path" :class="$style.list_title_img" :size="imgSize" />
        <h1 :class="[$style.list_title_text]">
          {{ title }}
        </h1>
      </div>
      <div
        v-show="showCreateTeam"
        :class="[$style.list_action, $style.pointer]"
        @click="addAction(addPage)"
        :addPage="addPage"
      >
        <base-img
          :path="addIconSrc"
          :class="$style.list_action_img"
          size="24"
        />
        <p :class="[$style.list_action_text, $style.add]">{{ actionText }}</p>
      </div>
    </div>
    <div :class="$style.list_content">
      <slot></slot>
    </div>
  </section>
</template>
<script>
import BaseImg from '@/components/base/BaseImg/BaseImg'
export default {
  name: 'BaseList',
  components: {
    BaseImg,
  },
  props: {
    type: {
      type: String,
      default: 'normal',
      validator: (value) => ['normal', 'more', 'add'].includes(value),
    },
    title: {
      type: String,
      // required: true,
    },
    path: {
      type: String,
      // required: true,
    },
    imgSize: {
      type: String,
      // required: true,
    },
    pathMore: {
      type: String,
      default: '',
    },
    pathAdd: {
      type: String,
      default: '',
    },
    actionText: {
      type: String,
      default: '',
    },
    morePage: {
      type: String,
      default: '',
    },
    addPage: {
      type: String,
      default: '',
    },
    bg: {
      type: String,
      default: 'yellow',
    },
    bgColor: {
      type: String,
      default: '',
    },
    showCreateTeam: {
      type: Boolean,
      default: false,
    },
    moreAction: {
      type: String,
      default: null,
    },
  },
  computed: {
    moreIconSrc() {
      return require('@/assets/images/list/icon-arrow-right.svg')
    },
    addIconSrc() {
      return require('@/assets/images/list/icon-plus.svg')
    },
    customStyle() {
      const bgColor = this.bgColor
        ? {
            'border-image-source': `linear-gradient( ${this.bgColor}, ${this.bgColor} )`,
          }
        : null
      const bgPadding = this.bgColor
        ? {
            padding: '40px 0',
          }
        : null
      const margin = { 'margin-bottom': '60px' }
      return {
        ...bgColor,
        ...bgPadding,
        ...margin,
      }
    },
    classObject() {
      const classObj = this.bgColor ? [this.$style.bg_wide] : ''
      return [classObj]
    },
  },
  methods: {
    addAction() {
      this.$router.push('/team/create/')
    },
  },
}
</script>
<style lang="scss" module>
.list_title {
  display: flex;
}
.list_title_wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 10px;
  }
}
.list_title_text {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}
.list_title_img {
  padding-right: 10px;
}
.list_action {
  display: flex;
  align-items: center;
}
.list_action_text {
  font-size: 14px;
  font-weight: bold;
  color: $keyGreen;
  margin: 0 0 0 5px;
}
.bg_wide {
  border-image-slice: fill 0;
  border-image-outset: 0 100vw 0 100vw;
}
.pointer {
  cursor: pointer;
}
</style>
