<template>
  <div :class="$style.change_password">
    <app-prev-button @click="backButtonAction(prevRoute)" />
    <h1 :class='$style.title'>ログイン情報の変更</h1>
    <p :class='$style.note'>確認のために携帯電話、またはEメールアドレスにメッセージを送信します。</p>
    <div :class="$style.input_group">
      <base-form-field name="current_cognito_id" label="現在のメールアドレス・電話番号">
        <base-input
          type="text"
          name="current_cognito_id"
          maxLength="255"
          v-model="inputCurrentCognitoId"
          :error="currentCognitoIdError"
        />
      </base-form-field>

      <base-form-field name="change_cognito_id" label="新しいメールアドレス・電話番号">
        <base-input
          type="text"
          name="change_cognito_id"
          maxLength="255"
          v-model="inputChangeCognitoId"
          :error="changeCognitoIdError"
        />
      </base-form-field>

      <base-form-field name="change_cognito_id_confirmation" label="新しいメールアドレス・電話番号（確認用）">
        <base-input
          type="text"
          name="change_cognito_id_confirmation"
          maxLength="255"
          v-model="inputChangeCognitoIdConfirmation"
          :error="changeCognitoIdError"
        />
      </base-form-field>

      <base-form-field name="password" label="新しいパスワード">
        <base-input
          type="password"
          maxLength="255"
          v-model="inputPassword"
          :error="passwordError"
        />
      </base-form-field>
    </div>

    <base-message
      type="error"
      width="auto"
      v-if="errorMessage"
    >
      <template>
        {{ errorMessage }}
      </template>
    </base-message>

    <div :class='$style.next_button_wrap'>
      <base-button display='inline-block' width='100%' height='50px' @click="clickNext">
        次へ
      </base-button>
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/base/BaseInput/BaseInput'
import BaseButton from '@/components/base/BaseButton/BaseButton'
import BaseFormField from '@/components/base/BaseFormField/BaseFormField'
import BaseMessage from '@/components/base/BaseMessage/BaseMessage'
import AppPrevButton from '@/components/app/AppPrevButton/AppPrevButton'

export default {
  name: 'HomeChangeAuth',
  components: { BaseInput, BaseButton, BaseFormField, BaseMessage, AppPrevButton },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from && from.name) {
        if (from.name == 'HomeChangeAuthRequested' || from.name == 'HomeChangeAuthConfirm') {
          vm.prevRoute = { name: 'HomeHome' }
        } else {
          vm.prevRoute = from
        }
      } else {
        vm.prevRoute = '__history_back'
      }
    })
  },

  data() {
    return {
      prevRoute: '',
      inputCurrentCognitoId: "",
      inputChangeCognitoId: "",
      inputChangeCognitoIdConfirmation: "",
      inputPassword: "",
      currentCognitoIdError: "",
      changeCognitoIdError: "",
      passwordError: "",
      authChangeId: -1,
      errorMessage: "",
    }
  },

  methods: {
    clickNext() {
      this.errorMessage = "";
      this.passwordError =  "";
      this.currentCognitoIdError = "";
      this.changeCognitoIdError = "";

      this.$axios.post('/api/' + this.$constants.API_VERSION + '/auth/change/request', {
        current_cognito_id: this.inputCurrentCognitoId,
        change_cognito_id: this.inputChangeCognitoId,
        change_cognito_id_confirmation: this.inputChangeCognitoIdConfirmation,
        password: this.inputPassword,
      }).then((res) => {
        this.authChangeId = res.data.value;

        // 入力されたデータをVuexストアに保存
        this.$store.dispatch('saveAuthChangeData', {
          currentCognitoId: this.inputCurrentCognitoId,
          changeCognitoId: this.inputChangeCognitoId,
          changeCognitoIdConfirmation: this.inputChangeCognitoIdConfirmation,
          password: this.inputPassword,
          authChangeId: this.authChangeId,
        });

        // URLにパラメータを含めずに画面遷移
        this.$router.push({ name: 'HomeChangeAuthRequested' });
      }).catch((err) => {
        this.errorMessage = "入力項目を確認してください。";
        err.response.data.value.errors.forEach(function(v) {
          if (v.attribute === "password") {
            this.passwordError = v.message;
          }
          if (v.attribute === "current_cognito_id") {
            this.currentCognitoIdError = v.message;
          }
          if (v.attribute === "change_cognito_id") {
            this.changeCognitoIdError = v.message;
          }

          if (!v.attribute) {
            this.errorMessage = v.message;
          }
        }.bind(this));
      });
    },
    backButtonAction(back) {
      if (back === '__history_back') {
        this.$router.go(-1);
      } else {
        this.$router.push(back, () => {});
      }
    },
  },
}
</script>

<style lang="scss" module>
.change_password {
  display: flex;
  flex-direction: column;
  min-width: 375px;
  margin: 0 auto;
  padding: 42px 32px 30px 32px;
  box-sizing: border-box;
  text-align: left;
}

.title {
  margin-top: 0;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 50px;
}

.note {
  font-size: 13px;
  color: $fontBlack;
  margin-top: 0;
  margin-bottom: 40px;
}

.input_group {
  & > * {
    margin-bottom: 24px;
  }
}

.next_button_wrap {
  margin-top: 10px;
}
</style>
