<template>
  <div :class="$style.home_notice">
    <div :class="$style.body" v-if="news_profile">
      <div :class="$style.notice">
        <div :class="[$style.notice_top, $style.inner]">
          <dt>
            <base-avatar
              size="24px"
              :class="$style.avatar"
              :path="news_profile.path"
            />
            <h3 :class="$style.title">{{news_profile.title}}</h3>
          </dt>
          <dd>
            <base-tag
              width="54px"
              height="20px"
              padding="0"
              bgColor="white"
              color="black"
              :bordered="true"
              fontSize="m"
            >お知らせ
            </base-tag>
            <time :class="$style.time">{{time(news_profile.created_at)}}</time>
          </dd>
        </div>
        <p :class="[$style.notice_body, $style.inner]" v-html="autoLink(notice_body)">
        </p>
      </div>
      <form action="">
        <div v-for="(item, index) in attachment_list" :class="$style.file" :key='index'>
          <button :class="$style.file_download" @click.prevent="multi_download([item])">
            <img :class="$style.file_icon" src="@/assets/images/description_black_24dp.svg" alt="icon">
            {{getFileName(item.path)}}
          </button>
        </div>
        <p :class="$style.file_all" id="all_dl">
          <a :class="$style.file_all_download" @click="multi_download(attachment_list)" v-if="!processing && (attachment_list && attachment_list.length > 0)">ダウンロードする</a>
          <base-icon name="spinner" size="18px" :class="$style.procecinng_download" spin v-if="processing" />
        </p>
        <div :class="$style.msg_container">
          <template v-if="hasError(downloadErrorMsgList)">
            <base-message type="error" v-for="(error, i) in downloadErrorMsgList" :key="i" :class="$style.msg_area">{{error.message ? error.message : error}}</base-message>
          </template>
          <base-message type="success" :class="$style.msg_area" v-if="downloadSuccessMsg">{{downloadSuccessMsg}}</base-message>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import BaseAvatar from '@/components/base/BaseAvatar/BaseAvatar.vue'
import BaseTag from "@/components/base/BaseTag/BaseTag"
import BaseIcon from '@/components/base/BaseIcon/BaseIcon'
import BaseMessage from '@/components/base/BaseMessage/BaseMessage'
import {hasError} from '@/helper/validator.js'
import {autoLink} from '@/utils/helper.js'

export default {
  name: 'HomeNotice',
  components: {
    BaseAvatar,
    BaseIcon,
    BaseTag,
    BaseMessage,

  },
  async mounted() {
    this.$router.app.$emit('page-updated', {
      pageTitle: '通知',
      headerColor: 'white',
      showSetting: false,
    })
    this.$router.app.$emit('show-spinner', true);
    await this.newsInfo();
    this.$router.app.$emit('show-spinner', false);
  },
  data() {
    return {
      current_tab: 'チーム',
      notice_body: '',
      img:'@/assets/images/cat-640.jpg',
      news_profile: null,
      attachment_list: [],
      downloadErrorMsgList: [],
      downloadSuccessMsg: null,
      processing: false,
    }
  },
  props: {
    news_id: {
      type: Number,
    },
  },
  methods: {
    hasError,
    autoLink,
    newsInfo() {
      const params ={news_id: this.news_id};
      return this.$axios
                .get('/api/' + this.$constants.API_VERSION + '/servicenews/detail', {params})
                .then((response) => {
                  this.news_profile = response?.data?.value?.service_news
                  this.notice_body = response?.data?.value?.service_news?.contents
                  if(response.data?.value?.attachment_list){
                    if(response.data.value.attachment_list.length > 5){
                      this.attachment_list = [...response.data.value.attachment_list.slice(0, 5)]
                    }else{
                      this.attachment_list = response.data.value.attachment_list
                    }
                  }
                })
    },
    multi_download: async function (items) {
      if (this.processing) return

      this.downloadErrorMsgList = []
      this.downloadSuccessMsg = ''
      this.processing = true

      try {
        let files = items.map(attachment => {
          return {
            path: attachment.path
          }
        })
        // ファイルを複数ダウンロード出来るAPI call
        const result = await this.$axios.post('/api/' + this.$constants.API_VERSION + '/file/download/list', { file_list: files })
        if (result.data.status == 'success') {
            const file_list = result.data.value.file_list
            if(file_list && file_list.length > 0){
              file_list.forEach((file, index) => {
                if(file.error){
                  this.downloadErrorMsgList.push({message: `${index + 1}つめののダウンロードに失敗しました。`});
                }else{
                  this.downloadFile(file.image, file.file_name, "application/vnd.ms-excel")
                }
              })
              if(this.downloadErrorMsgList.length == 0) {
                this.downloadSuccessMsg = 'ダウンロードが完了しました。'
              }
            }else {
              this.downloadErrorMsgList.push({message: 'ダウンロード対象がありません。'});
            }
        }
        else{
          this.errorMsgList.push({message: 'ダウンロードに失敗しました。'});
        }
      } finally {
        this.processing = false
      }
    },
    downloadFile: function(data, fileName, type) {
      const decoded_utf8str = atob(data);
      let decoded = new Uint8Array(Array.prototype.map.call(decoded_utf8str, c => c.charCodeAt()));
      if(type == 'text/csv'){
        decoded = new TextDecoder().decode(decoded);
      }
      if (window.navigator.msSaveOrOpenBlob) {
        // Edge IE
        window.navigator.msSaveOrOpenBlob(decoded, fileName);
      } else {
        // Chrome firefox
        const url = URL.createObjectURL(new Blob([decoded], {type}));
        const elem = document.createElement('a');
        elem.href = url;
        elem.setAttribute('download', fileName);
        document.body.appendChild(elem);
        elem.click();

        URL.revokeObjectURL(url);
        elem.parentNode.removeChild(elem);
      }
    },
    getFileName(path) {
      if(!path) return ''
      return path.split('/').pop();
    },
    time: function (created_at) {
      let createdFormatStr = ''
      if(created_at){
        createdFormatStr = this.$dayjs(created_at).format('YYYY/MM/DD HH:mm')
      }
      return createdFormatStr
    },
  },
};
</script>

<style lang="scss" module>
.body {
  max-width: 343px;
  padding: 0 16px 64px 16px;
  margin: 25px auto 0px auto;
}

.notice {
  border: 1px solid $borderLiteGray;
  background-color: $keyWhite;
  min-width: 304px;
  .notice_top {
    border-bottom: 1px solid $borderLiteGray;

    dt,dd {
      display: flex;
      align-items: center;
      margin: 0;
    }

    dt {
      .title {
        font-size: 12px;
        margin: 0 0 0 9px;
      }
    }
    dd {
      padding-top: 6px;
      .time {
        display: inline-block;
        font-size: 10px;
        transform: scale(0.9);
        transform-origin: 0;
        width: 200px;
        margin-right: -50px;
        margin-left: 6px;
      }
    }
  }
  .notice_body {
    white-space: pre-line;
    word-wrap: break-word;
    margin: 0;
    font-size: 10px;
    line-height: 16px;
  }
}
.file {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 4px;

  .file_download {
    width: 306px;
    border: 1px solid #707070;
    background-color: $keyWhite;
    padding: 11px 10px;
    border-radius: 3px;
    color: #000000;
    text-decoration: none;
    text-align: left;
    cursor: pointer;

    .file_icon {
      display: inline-block;
      padding-right: 6px;
      width: 20px;
      height: 25px;
      vertical-align: middle;
    }
  }

  input {
    display: none;
  }
}
.file_all {
  text-align: right;
  margin: 5px;
}

.file_all_download {
  cursor: pointer;
  color: $keyGreen;
  font-size: 12px;
}
.procecinng_download {
  margin: 4px;
}

.inner {
  padding: 12px 14px;
}

.home_notice {
  width: 100%;
  background-color: $backGround;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  &.loading {
    align-items: center;
  }
}

.team_tab_content, .notice_tab_content {
  display: flex;
  width: 960px;
  flex-direction: column;
  align-items: center;
  padding: 32px 0 0 0;
  margin: 0 auto;
  overflow: hidden;

  @include mobile(){
    width: auto;
    margin: 0;
    padding: 24px 32px 24px 32px;
  }
}

.team_item_list, .notice_item_list {
  padding: 0;
  margin: 0 0 0 0;
  display: flex;
  flex-wrap: wrap;

  // 右端下端のマージンを打ち消す。ピッタリだと影が消えるので少し小さく取る
  margin-right: -32px;
  margin-bottom: -10px;
  @include mobile(){
    margin-right: -40px;
    margin-bottom: -20px;
  }

  .team_item_wrap, .notice_item_wrap {
    margin-right: 20px;
    margin-bottom: 20px;

    @include mobile(){
      margin-right: 24px;
      margin-bottom: 24px;
    }
  }
}

.msg_container {
  margin: 4px auto;
  .msg_area {
    width: 100% !important;
    margin: 0 0 10px;
    &:last-of-type{
      margin-bottom: 0;
    }
  }
}

</style>
