var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.menu},[_c('div',{class:_vm.$style.menuWrap,on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.isShow = !_vm.isShow}}},[_c('div',{ref:"menuButton",class:_vm.$style.menuButton},[_vm._t("menu-button",function(){return [_c('div',{class:_vm.$style.menuDefaultButton},[(!_vm.showGearIcon)?_c('base-icon',{attrs:{"name":"ellipsis-h","size":_vm.iconSize,"color":"black"}}):_vm._e(),(_vm.showGearIcon)?_c('div',{class:_vm.$style.settings_button},[_c('img',{class:_vm.$style.settings_button____settings_icon,attrs:{"src":require("@/assets/images/Icon-awesome-cog.svg"),"alt":""}}),_c('span',{class:_vm.$style.settings_button__settings_text},[_vm._v("設定")])]):_vm._e()],1)]})],2),_c('div',{class:[
        _vm.$style.menuListTriangle,
        _vm.isShow ? _vm.$style.menuListTriangleShow : ''
      ],style:(_vm.menuTrianglePosition)}),_c('div',{ref:"menuList",class:[
        _vm.$style.menuList,
        _vm.isShow ? _vm.$style.menuListShow : ''
      ],style:(_vm.menuListPosition)},[_vm._t("menu-contents"),_c('ul',[_vm._t("default")],2)],2)]),_c('div',{class:[
      _vm.$style.menuOverlay,
      _vm.isShow ? _vm.$style.menuOverlayShow : ''
    ],on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.isShow = false}}})])}
var staticRenderFns = []

export { render, staticRenderFns }