<template>
  <div :class="$style.input_comment_wrap">
    <div :class="$style.input_comment">
      <div v-if="showFileIcon" :class="$style.icon_button">
        <base-file-input
          :name="name"
          @change="handleFileSubmit"
          @error="handleFileError"
          :extensions="extensions"
          :maxFileSize="maxFileSize"
        >
          <div
            :style="customStyle"
            :class="classObject"
          >
            {{title}}
          </div>
        </base-file-input>
      </div>
      <div
        v-if="showTextArea"
        :class="$style.textarea_wrap"
      >
        <base-text-area
          :modelValue="textareaValue"
          @update="handleTextAreaUpdate"
          :rows="rows"
          :maxlength="maxlength"
          :error="error"
          :class="[$style.icon_button_text_only, previousSpace? $style.previous_space : '' ]"
        >
          <template v-slot:append>
          <span v-if="showStampIcon" :class="$style.icon_button_smile" @click="showStampModal = true">
            <img :src="require('@/assets/images/' + stampIconName)" alt="stamp">
          </span>
          </template>
        </base-text-area>
      </div>
      <div v-if="!showTextArea">
        <p :class="[$style.icon_button_text_only, $style.icon_button_label]">{{textareaValue}}</p>
      </div>
    </div>
    <div :class="$style.error_comment" v-for="(error, index) in commentErrorList" :key="'cm-err-'+ index">
      <p>{{error.message}}</p>
    </div>
    <div :class="$style.error_comment" v-if="commentError">
      <p>{{commentError}}</p>
    </div>
    <div :class="$style.files" v-if="files">
      <div
        v-for="(file, index) in files"
        :class="$style.file"
        v-bind:key="index">

        <span :class="$style.download" @click="onFileDownload(file, index)">
          <img
            src="@/assets/images/file_download.svg"
            alt="download"
            >
        </span>
        <template v-if="isImage(file.type)">
          <img :class="$style.thumbnail" :src="file.url">
        </template>
        <template v-else>
          <span>{{file.name}}</span>
        </template>
        <span :class="$style.trash" @click="onFileDelete(file, index)" v-if="showTrash">
          <base-icon
            size="18px"
            type="far"
            name="trash-alt"
            color="green"
          />
        </span>
      </div>
    </div>
  </div>


</template>

<script>
import BaseFileInput from '@/components/base/BaseFileInput/BaseFileInput'
import BaseIcon from '@/components/base/BaseIcon/BaseIcon.vue'

export default {
  name: 'TeamBaseInputButton',
  components: { BaseFileInput, BaseIcon },
  props: {
    modelValue: {},
    title: {
      type: String,
      default: null
    },
    maxlength: {
      type: Number,
      default: 10000
    },
    rows: {
      type: Number,
      default: 1
    },
    name: {
      type: String,
      default: 'file'
    },
    showFileIcon: {
      type: Boolean,
      default: true
    },
    fileIconName: {
      type: String,
      default: 'feather-plus.svg'
    },
    showTextArea: {
      type: Boolean,
      default: true
    },
    showStampIcon: {
      type: Boolean,
      default: true
    },
    stampIconName: {
      type: String,
      default: 'feather-smile.svg'
    },
    showRecordIcon: {
      type: Boolean,
      default: true
    },
    extensions: {
      type: Array,
      default: () => null   // 全てのファイルのアップロードを許可
    },
    imageTypes: {
      type: Array,
      default: () => null   // 添付ファイルは全て通常のファイル扱い
    },
    textareaValue: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    commentErrorList: {
      type: Array,
      default: () => [],
    },
    commentError: {
      type: String,
      default: ''
    },
    files: {
      type: Array,
      default: () => null
    },
    /**
     * メガバイトで指定
     */
    maxFileSize: {
      type: Number,
      default: null
    },
    showTrash: {
      type: Boolean,
      default: true
    },
    previousSpace: {
      type: Boolean,
      default: false,
    },
    /**
     * ボタンの幅を指定します。
     * 幅の指定がない場合は、autoになります
     */
    width: {
      type: String,
      default: "auto",
    },
    /**
     * ボタンの高さを指定します。
     * 高さの指定がない場合は、40pxになります
     */
    height: {
      type: String,
      default: "40px",
    },
    size: {
      type: String,
      default: 'm',
      validator:(value) => ['m','s', 'xs'].includes(value)
    },
    padding: {
      type: String,
      default: undefined,
    },
    display: {
      type: String,
      default: "inline-block",
      validator: (value) =>
        [undefined, "inline-block", "inline-flex", "flex", "block"].includes(
          value
        ),
    },
    /**
     * リンク先を指定できます。`to`が指定されている場合はそちらが優先されます
     */
    href: {
      type: String,
      default: "",
    },
    /**
     * ボタン色を指定できます。
     */
    bgColor: {
      type: String,
      default: "green",
      validator: (value) =>
        [undefined, "green", "dark-green", "white", "yellow", 'red', 'pink', 'light-gray', 'gray',"black"].includes(value),
    },
    /**
     * 文字色を指定できます。
     */
    color: {
      type: String,
      default: "white",
      validator: (value) =>
        [undefined, "white", "gray", "black", "green"].includes(value),
    },
    /**
     * ボタンの形状を指定できます。
     */
    rounded: {
      type: String,
      default: undefined,
      validator: (value) => [undefined, "rounded", "corner", "shallow-corner"].includes(value),
    },
    /**
     * ボーダースタイルを適用するかを指定します
     */
    bordered: {
      type: Boolean,
      default: false,
    },
    /**
     * box-shadowの有無を指定します。
     */
    shadow: {
      type: Boolean,
      default: false,
    },
    buttonType: {
      type: String,
      default: "submit",
      validator: (value) => [undefined, "submit", "button"].includes(value),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    byLabel: {
      type: Boolean,
      default: false
    },
    showCursor: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    classObject() {
      return [
        this.$style.button,
        this.$style["bg-" + this.bgColor],
        this.$style[this.color],
        this.$style[this.rounded],
        this.bordered ? this.$style.bordered : null,
        this.shadow ? this.$style.shadow : null,
        this.disabled ? this.$style.disabled : null,
        this.$style["size_" + this.size],
        (this.showCursor && !this.disabled) ? this.$style.pointer: null,
      ];
    },
    customStyle() {
      const displays = {
        "inline-block": "inline-flex",
        "inline-flex": "inline-flex",
        block: "flex",
        flex: "flex",
      };
      return {
        display: displays[this.display],
        width: this.width,
        height: this.height,
        padding: this.padding || undefined
      };
    },
  },
  data() {
    return {
      showStampModal : false,
      showRecordModal: false,
      tab_data: [],
    }
  },
  methods: {
    isImage(fileType) {
      if (fileType) {
        return this.imageTypes.includes(fileType)
      }
      return false
    },
    onClickTab(name) {
      this.$emit("tab", name)
    },
    onFileDownload(file, index) {
      const a = document.createElement("a")
      document.body.appendChild(a)
      a.download = file.name
      a.href = file.url;
      a.target = '_blank';
      a.click()
      a.remove()
      this.$emit('file_download', file, index)
    },
    onFileDelete(file, index) {
      this.$emit('file_delete', file, index)
    },
    handleFileSubmit([base64, file]) {
      this.$emit('file_updated', [base64, file])
    },
    handleFileError(errors) {
      this.$emit('error', errors)
    },
    handleStampSubmit(args) {
      this.showStampModal = false
      this.$emit('stamp', args)
    },
    handleTextAreaUpdate(value) {
      this.$emit('update', value)
    },
    fetchStampList: function () {
      return this.$axios
        .get('api/v1/stamp/list', {
          params: {
            sort: "category_id",
            direction: "asc",
            limit: 100,
          }
        })
        .then((res) => {
          if (res.data.status == 'success') {
            this.stamp_list = res.data.value.stamp_list
            this.stamp_list.forEach(element => {
              this.tab_data.push({'tabName':element.category, 'tabImagePath':element.category_image_url, 'tabItems':element.stamp_category_list})
            })
          } else {
            this.tab_data = null
          }
        })
        .catch((err) => {
          this.tab_data = null
          throw new Error(err)
        })
    },
    async initFetch() {
      this.initLoading = true
      try {
        await Promise.all([
          this.fetchStampList(),
        ])
      }
      catch(e){
        // this.$router.push('/error')
        this.$loginfo(e)
      }
      finally {
        this.initLoading = false
      }
    },
  },
  async created() {
    this.initFetch()
  }
}
</script>

<style lang="scss" module>

$textSize_Error: 13px;
$textSpecing_Error: 4px;
$textColor_Error: #ff0000;

.input_comment_wrap {}
.input_comment {
  display: flex;
  align-items: flex-start;
  width: 100%;
  .textarea_wrap{
    width: 100%;
  }
}

.icon_button {
  min-height: 42px;

  &_text_only {
    margin-top: 8px;
    margin-bottom: 8px;
  }
    &_label {
    border-bottom: solid 1px;
  }

  &.camera {
    display: none;
    @include mobile() {
      display: block;
    }
  }

  &_smile {
    height: 100%;
    padding: 2px 10px 0 10px;
  }
}

.previous_space {
  padding: 1em 0 0 2em;
  box-sizing: border-box;
  @media screen and (max-width: 767px) {
    padding: 0;
  }
}

.icon_plus {
  margin: 10px 10px 0 0;
}
.icon_camera {
  margin: 13px 8px 0 8px;
}
.icon_mic {
  margin: 11px 8px 0 8px;
}

.stamp_tab_wrap {
  display: flex;
  flex-direction: column;
  min-height: 408px;
  padding: 16px 30px 24px 30px;
  box-sizing: border-box;
}

.record_button_wrap {
  display: flex;
  flex-direction: column;
  padding: 16px 20px 16px 20px;
  box-sizing: border-box;
  .description {
    margin-top: 0;
    margin-bottom: 66px;
    font-size: 11px;
    color: $fontBlack;
  }
}
.error_comment {
  font-size: $textSize_Error;
  margin: $textSpecing_Error 0 0 0;
  color: $textColor_Error;
  white-space: pre-wrap;
  padding: 0 96px 0 38px;
  line-height: 1.2;
  p {
    margin: 6px 0 0;
  }
}

.files {
  margin-top: 10px;

  .file {
    border: 2px solid #C4C4C4;
    border-radius: 4px;
    margin: 0 auto;
    margin-bottom: 10px;
    padding: 5px;

    .download {
      margin-left: 10px;
      margin-right: 10px;
      float: left;
    }
    .download:hover {
      cursor: pointer;
    }

    .thumbnail {
      width: 195px;
    }

    .trash {
      margin-right: 10px;
      float: right;
    }
    .trash:hover {
      cursor: pointer;
    }
  }
}
.button {
  border: 0;
  background: none;
  display: inline-flex;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  vertical-align: bottom;
  font-family: sans-serif;
  margin-left: 2rem;

  &:hover {
    opacity: .8;
  }

  &.size_m{}
  &.size_s {
    font-size: 10px;
    line-height: 10px;
    padding: 0 8px;
  }

  &.bg-green {
    background: $keyGreen;
    &.bordered {
      color: $keyGreen;
      background: $keyWhite;
      border: 2px solid $keyGreen;
    }
  }
  &.bg-dark-green {
    background: $KeyDarkGreen;
    &.bordered {
      color: $KeyDarkGreen;
      background: $keyWhite;
      border: 2px solid $KeyDarkGreen;
    }
  }
  &.bg-white {
    background: $keyWhite;
    &.bordered {
      color: $fontGray;
      background: $keyWhite;
      border: 2px solid $fontGray;
    }
  }
  &.bg-yellow {
    background: $keyYellow;
    &.bordered {
      color: $keyYellow;
      background: $keyWhite;
      border: 2px solid $keyYellow;
    }
  }
  &.bg-red {
    background: $keyRed;
    &.bordered {
      color: $keyRed;
      background: $keyWhite;
      border: 2px solid $keyRed;
    }
  }
  &.bg-pink {
    background: $keyPink;
    &.bordered {
      color: $keyPink;
      background: $keyWhite;
      border: 2px solid $keyPink;
    }
  }
  &.bg-gray {
    background: #707070;
    &.bordered {
      color: #707070;
      background: $keyWhite;
      border: 2px solid #707070;
    }
  }
  &.bg-light-gray {
    background: #DDDDDD;
    &.bordered {
      color: #DDDDDD;
      background: $keyWhite;
      border: 2px solid #DDDDDD;
    }
  }
  &.bg-black {
    background: $keyBlack;
    &.bordered {
      color: $keyBlack;
      background: $keyWhite;
      border: 2px solid $keyBlack;
    }
  }
  &.white {
    color: $fontWhite;
  }
  &.black {
    color: $fontBlack;
  }
  &.gray {
    color: $fontGray;
  }
  &.green {
    color: $keyGreen;
  }
  &.rounded {
    border-radius: 30px;
  }
  &.corner {
    border-radius: 10px;
    &.size_s {
      border-radius: 5px;
    }
  }
  &.shallow-corner {
    border-radius: 5px;
  }
  &.shadow {
    box-shadow: 0px 3px 8px -3px rgba(119,119,119,0.6);
  }
  &.disabled {
    pointer-events: none;
  }
  &.pointer {
    cursor: pointer;
  }
}
</style>