<template>
  <div>
    TeamRuleSetting
    31230 - チーム設定の編集
  </div>
</template>
<script>
export default {
  name: 'TeamRuleSetting',
  mounted() {
    this.$router.app.$emit('page-updated', {
      pageTitle: ''
    })
  }
}
</script>
<style lang="scss" module>

</style>
