export default {
  namespaced: true,
  state: {
    profile_image: "",
    name: "",
    nickname: "",
    profile: "",
    email: "",
    birthday: "",
    password: ""
  },
  mutations: {
    setProfileImage(state, profile_image){
      state.profile_image = profile_image
    },
    setName(state, name){
      state.name = name
    },
    setNickname(state, nickname) {
      state.nickname = nickname
    },
    setProfile(state, profile) {
      state.profile = profile
    },
    setEmail(state, email) {
      state.email = email
    },
    setBirthday(state, birthday) {
      state.birthday = birthday
    },
    setPassword(state, password) {
      state.password = password
    }
  },
}
