
/**
 * 対象フィールドのエラーメッセージ取得
 * @param {*} field_name フィールド名
 * @param {*} errorMsgList エラーリスト
 * @returns 対象フィールドのエラーが存在する場合:エラーメッセージ 対象フィールド存在しない場合:''
 */
export function isFieldErrorMsg(field_name, errorMsgList){
  let msg = ''
  const targetList = errorMsgList.filter((err)=> err.attribute == field_name)
  if(targetList && targetList.length > 0){
    msg = targetList[0]?.message
  }
  return msg;
}

/**
 * バリデーションエラーがあるか
 * @param {*} errorMsgList エラーリスト
 * @returns ある場合:true ない場合:false
 */
export function hasError(errorMsgList){
  return errorMsgList.length > 0
}

/**
 * 画面上に存在する項目のバリデーションエラーが存在するかチェック
 * @param {*} errorMsgList エラーリスト
 * @param {*} excludeList 除外リスト[除外したいフィールド名を配列で指定]
 * @returns
 */
export function hasFieldErrorMsg(errorMsgList , excludeList = []){
  const target = errorMsgList.find((err)=> err.attribute && !excludeList.includes(err.attribute))
  if(target){
    return true
  }
  return false
}
