<template>
  <section
    :class="[
      $style.wrapper,
      {
        [$style.unread]: this.unread,
      },
      this.movie ? $style.wrapper_movie : $style.wrapper_talk,
    ]"
    :style="customStyle"
    @click="handleClick"
  >
    <span
      v-if="!this.movie"
      :class="$style.talk_label"
      :style="`border-left: 24px solid ${color};`"
    ></span>
    <picture
      :class="[
        $style.picture,
        this.movie ? $style.picture_obj_movie : $style.picture_obj_talk,
      ]"
      :border-color="borderColor"
      :outline-color="outlineColor"
    >
      <img :src="image" />
    </picture>
    <div :class="$style.text_wrap">
      <div :class="$style.info_wrap">
        <div v-if="type" :class="$style.label_wrap">
          <span :class="$style.label_text"> {{ typeText }} </span>
        </div>
        <p :class="$style.time">
          <time :datetime="timeAttr" :class="this.movie && this.attentionFlg ? $style.time_text_attention : $style.time_text">{{
            timeText
          }}</time>
        </p>
      </div>
      <!-- タイトル名 -->
      <div :class="[$style.title_wrap, { [$style.movie]: !this.movie }]">
        <p :class="$style.title">
          {{ title }}
        </p>
      </div>
      <!-- コーナー名が入ります -->
      <p :class="$style.name">
        {{ name }}
      </p>
      <div :class="$style.foot_icons">
        <base-overlay-menu-list
          v-if="!!this.movie"
          :class="$style.link_wrap_icon"
        >
          <template v-slot:menu-button>
            <span :class="[$style.icons_wrapper, $style.icons_share]">
              <base-img
                :path="shareIconSrc"
                :class="$style.icon_img"
                size="10px"
              />
              共有する
            </span>
          </template>
          <template v-slot:menu-contents>
            <div :class="$style.share_title" style="margin: 10px">
              チームHOMEに表示する
            </div>
            <div :class="$style.share_teams">
              <div :class="$style.share_teams_container">
                <div
                  :class="$style.share_teams_item"
                  v-for="(team, i) of teams"
                  :key="i"
                >
                  <app-carousel-team-item
                    :head="team.team_name"
                    :border-color="
                      team.team_color ? `rgb(${team.team_color})` : '#7BE293'
                    "
                    :outline-color="team.shared_state ? '#FF88FF' : '#FFFFFF'"
                    :image="team.path"
                    @click.native="shareTeam(team.team_id, team.shared_state)"
                  />
                </div>
              </div>
            </div>
          </template>
          <base-overlay-menu-list-item @click.native="shareUrl" to="">
            <base-icon
              name="link"
              size="12px"
              color="green"
            />この動画のURLを取得
          </base-overlay-menu-list-item>
        </base-overlay-menu-list>
        <base-overlay-menu-list-item
          v-else
          :class="[$style.link_wrap_icon, { [$style.textDecoration]: !movie }]"
          to=""
        >
          <router-link
            :to="profileUrl"
            :class="[$style.icons_avatar, $style.icons_wrapper]"
          >
            <base-avatar
              size="30px"
              :bordered="false"
              :class="$style.avatar"
              :path="profilePath"
            />
            <div :class="$style.text_overflow">
              {{ chat_user_nickname }}
            </div>
          </router-link>
          <!-- </span> -->
        </base-overlay-menu-list-item>
        <div v-if="!this.movie" :class="[$style.counters, $style.icons_list]">
          <div :class="[$style.read, $style.icon_wrap]">
            <span
              :class="[$style.icon, $style.cursor_pointer]"
              @click.stop="handleRead"
              ><base-icon
                size="10"
                name="eye"
                :color="this.readFlg ? 'green' : 'gray'"
            /></span>
            <span :class="$style.count">{{ readCount }}</span>
          </div>

          <div :class="[$style.commented, $style.icon_wrap]">
            <span :class="$style.icon" @click.stop="commentIconClick"
              ><base-icon
                size="10"
                name="comment-dots"
                :color="this.commentCount > 0 ? 'green' : 'gray'"
            /></span>
            <span :class="$style.count">{{ commentCount }}</span>
          </div>

          <div :class="[$style.good, $style.icon_wrap]">
            <span
              :class="[$style.icon, $style.cursor_pointer]"
              @click.stop="handleGood"
              ><base-icon
                :color="this.goodFlg ? 'pink' : 'gray'"
                size="10"
                name="heart"
              />
            </span>
            <span :class="$style.count">{{ goodsCount }}</span>
          </div>
        </div>

        <div v-else :class="[$style.counters, $style.icons_list]">
          <div :class="[$style.commented, $style.icon_wrap]">
            <span :class="$style.icon">
              <img src="@/assets/images/list/icon-count-play.svg" width="10"
            /></span>
            <span :class="$style.count">{{ viewCount }}</span>
          </div>

          <div :class="[$style.good, $style.icon_wrap]">
            <span
              :class="[$style.icon, $style.cursor_pointer]"
              @click.stop="handleGood"
              ><base-icon
                :color="this.goodFlg ? 'pink' : 'gray'"
                size="10"
                name="heart"
              />
            </span>
            <span :class="$style.count">{{ goodsCount }}</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BaseIcon from '../../../base/BaseIcon/BaseIcon'
import BaseOverlayMenuListItem from '@/components/base/BaseOverlayMenuList/BaseOverlayMenuListItem'
import BaseImg from '@/components/base/BaseImg/BaseImg'
import BaseAvatar from '@/components/base/BaseAvatar/BaseAvatar'
import BaseOverlayMenuList from '@/components/base/BaseOverlayMenuList/BaseOverlayMenuList'
import AppCarouselTeamItem from '@/components/app/AppCarousel/TeamItem/AppCarouselTeamItem'

export default {
  name: 'AppCarouselPostItemMovie',
  components: {
    BaseIcon,
    BaseOverlayMenuList,
    BaseOverlayMenuListItem,
    BaseImg,
    BaseAvatar,
    AppCarouselTeamItem,
  },
  data() {
    return {
      show: false,
      teams: [],
    }
  },
  props: {
    goodsCount: {
      type: Number,
      default: 0,
    },
    goodFlg: {
      type: Boolean,
      default: null,
    },
    readFlg: {
      type: Boolean,
      default: null,
    },
    post: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: '8月の活動日決めます！投票してください', // あとで消す
    },
    name: {
      type: String,
      default: 'オタメシ労働組合湘南サーフィン支部', // あとで消す
    },
    icon: {
      type: String,
      default: 'heart',
      validator: (icon) => !icon || ['heart'].includes(icon),
    },
    image: {
      type: String,
      default: require('@/assets/images/no-image.png'),
    },
    tag: {
      type: String,
      default: null,
    },
    customTagText: {
      type: String,
      default: null,
    },
    customTagColor: {
      type: String,
      default: null,
    },
    customTagTextColor: {
      type: String,
      default: null,
    },
    time: {
      type: [String, Date, Number],
      default: () => new Date(),
    },
    selected: {
      type: Boolean,
      default: null, // true:選択, false:非選択, null:選択機能無し
    },
    unread: {
      type: Boolean,
      default: false,
    },
    clip: {
      type: Boolean,
      default: false,
    },
    pin: {
      type: Boolean,
      default: false,
    },
    favorite: {
      type: Boolean,
      default: false,
    },
    read: {
      type: Boolean,
      default: false,
    },
    steps: {
      type: Array,
      default: () => [],
      validator: (steps) => steps.every((step) => step >= 0 && step <= 100),
    },
    item: {
      type: Object,
      required: false,
      default: null,
    },
    type: {
      type: Number,
      required: false,
      default: null,
    },
    showShare: {
      type: Boolean,
      default: true,
    },
    isCursorPointer: {
      type: Boolean,
      default: true,
    },
    // 202402追加↓
    watchNum: {
      type: String,
      required: false,
      default: null,
    },
    favNum: {
      type: String,
      required: false,
      default: null,
    },
    movie: {
      type: Boolean,
      required: false,
      default: null,
    },
    color: {
      type: String,
      default: '#FFFFFF',
    },
    chat_user_nickname: {
      type: String,
      default: 'ニックネーム',
    },
    borderColor: {
      type: String,
      default: null,
    },
    outlineColor: {
      type: String,
      default: null,
    },
    profilePath: {
      type: String,
      default: null,
    },
    readCount: {
      type: Number,
      default: 0,
    },
    commentCount: {
      type: Number,
      default: 0,
    },
    viewCount: {
      type: Number,
      default: 0,
    },
    profileUrl: {
      type: String,
      default: null,
    },
    lectureId: {
      type: Number,
      default: null,
    },
    attentionFlg: {
      type: Boolean,
      default: false
    }
  },
  async created() {
    await this.initFetch()
  },
  methods: {
    async initFetch() {
      await Promise.all([this.fetchTeamList()])
    },
    async fetchTeamList() {
      if (this.lectureId) {
        await this.$axios
          .get('/api/' + this.$constants.API_VERSION + '/lecture_team/list', {
            params: { lecture_id: this.lectureId },
          })
          .then((res) => {
            this.teams = res.data.value.team_list
          })
      }
    },
    handleRead() {
      // 未読/既読イベント
      this.$emit('read', this.item)
    },
    commentIconClick() {
      this.$emit('comment', this.chatId)
    },
    handleGood() {
      this.$emit('like', this.item)
    },
    handleClick() {
      // クリックイベントが発火するように設定
      this.$emit('click', this.item)
    },
    handleFavorite() {
      // お気に入りイベント
      this.$emit('favorite', this.item)
    },
    // handlePin() {
    //   // ピンイベント
    //   this.$emit('pin', this.item) //user側の変更は文言でクリップと言いつつピンなので注意
    // },
    handleDelete() {
      // 削除イベント
      this.$emit('delete', this.item)
    },
    handleShare() {
      // シェアイベント
      this.show = !this.show
    },
    handleReport() {
      // ユーザ通報イベント
      this.$emit('report', this.item)
    },
    handleBlock() {
      // ブロックイベント
      this.$emit('blockUser', this.item)
    },
    handleBrowse() {
      // 閲覧イベント
      this.$emit('browse', this.item)
    },
    shareUrl() {
      const targetUrl = `${process.env.VUE_APP_BASE_URL}/union/course/${this.lectureId}`;
      navigator.clipboard
        .writeText(targetUrl)
        .then(() => {
          this.$loginfo(targetUrl)
          alert('URLをクリップボードにコピーしました')
        })
        .catch((error) => this.$logerror(error))
    },
    async shareTeam(team_id, shared_state) {
      await this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/lecture_team/registration', {
          lecture_team_list: [
            {
              team_id: team_id,
              lecture_id: this.lectureId,
              shared_state: !shared_state
            }
          ]
        })
        .then(() => {
          this.fetchTeamList()
          if (!shared_state) {
            alert('チームホームに表示を設定しました')
          } else {
            alert('チームホームに表示を解除しました')
          }
        })
    },
  },
  computed: {
    ableToSelect() {
      return this.selected === false || this.selected === true
    },
    customStyle() {
      let style = {}

      if (this.isCursorPointer) {
        // trueの場合マウスオーバーでカーソルが指マークに変化
        style.cursor = 'pointer'
      }
      return style
    },
    iconType() {
      return 'fas' // iconやselectedによって変化する予定
    },
    iconName() {
      return this.icon // 今のところheartのみ
    },
    typeText() {
      let labelText = ''
      switch (this.type) {
        case 0:
        case 1:
          labelText = '投稿'
          break
        case 2:
          labelText = '投票'
          break
        case 3:
          labelText = 'メッセージ'
          break
        case 4:
          labelText = 'SSO'
          break
        default:
          labelText = null
      }
      return labelText
    },
    timeAttr() {
      const date =
        this.time instanceof Date
          ? this.time
          : new Date(this.time.replace(/-/g, '/'))
      return date.toLocaleString()
    },
    timeText() {
      if (!this.time) {
        return ''
      }
      const date =
        this.time instanceof Date
          ? this.time
          : new Date(this.time.replace(/-/g, '/'))
      return `${date.getFullYear()}/${('0' + (date.getMonth() + 1)).slice(
        -2
      )}/${('0' + date.getDate()).slice(-2)} ${('0' + date.getHours()).slice(
        -2
      )}:${('0' + date.getMinutes()).slice(-2)}`
    },
    displayTag() {
      if (
        this.customTagText ||
        this.customTagTextColor ||
        this.customTagColor
      ) {
        return true
      } else if (this.tag) {
        return true
      }

      return false
    },
    tagColor() {
      if (this.customTagColor) {
        return this.customTagColor
      }

      const tagColorMap = {
        need: 'pink',
        step: 'yellow',
        done: 'yellow',
      }

      return tagColorMap[this.tag] || 'pink'
    },
    tagTextColor() {
      if (this.customTagTextColor) {
        return this.customTagTextColor
      }

      const tagTextColorMap = {
        need: 'white',
        step: 'black',
        done: 'black',
      }

      return tagTextColorMap[this.tag] || 'white'
    },
    shareIconSrc() {
      return require('@/assets/images/list/icon-share.svg')
    },
  },
  watch: {
    goodsCount: function () {
      return this.goodsCount ? Number(this.goodsCount) : 0
    },
    commentCount: function () {
      return this.commentCount ? Number(this.commentCount) : 0
    },
    readCount: function () {
      return this.readCount ? Number(this.readCount) : 0
    },
  },
}
</script>

<style lang="scss" module>
.wrapper {
  // position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 10px;
  picture img {
    object-fit: cover;
    -o-object-fit: cover;
    width: 100%;
    transition: transform 0.4s ease;
  }
  &:hover {
    .picture,
    .info_wrap,
    .title_wrap,
    .icons_avatar,
    .icons_list .icons_share .name {
      opacity: 0.7;
    }
    picture img {
      transform: scale(1.1);
    }
  }
}
.wrapper_movie {
  background-color: $keyWhite;
  border: solid 1px $borderLiteGray;
  padding: 16px;
  border-radius: 16px;
  .title {
    color: $keyBlack;
    font-size: 14px;
  }
}
.wrapper_talk {
  position: relative;
  .talk_label {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-bottom: 24px solid transparent;
    border-left: 24px solid transparent;
    z-index: 3;
  }
  &.green {
    &::before {
      border-left: 24px solid $keyGreen;
    }
  }
}
.wrapper.unread {
  opacity: 50%;
}

.clip {
  position: absolute;
  top: -16px;
  left: 4px;
}

.pin {
  z-index: 2;
  position: absolute;
  top: -14px;
  left: -3px;
  transform: rotate(-21deg);
}
.header_wrapper {
  height: 22px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 6px;
  line-height: 1.5;
}

.tag_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  max-width: 100px;
}

.time {
  display: flex;
  color: $fontGray;
  margin: 0;
  &.attention::before {
    font-size: 10px;
    line-height: 1;
    content: '注目';
    color: $keyWhite;
    background-color: $keyRed;
    padding: 2px 5px;
    margin-right: 10px;
    border-radius: 2px;
  }
}
.time_text {
  display: flex;
  font-size: 10px;
}
.time_text_attention {
  display: flex;
  font-size: 10px;
  &::before {
    content: '注目';
    color: $keyWhite;
    background-color: $keyRed;
    padding: 2px 5px;
    margin-right: 10px;
    border-radius: 2px;
  }
}
.icons {
  display: inline-block;
  color: $borderLiteGray;
  margin: 0;

  .selected {
    color: $keyPink;
  }
}
.icons_wrapper {
  display: flex;
  align-items: center;
  gap: 2px;
}
.icons_share {
  text-decoration: underline;
}
.icons_list {
  margin: 0;
  display: flex;
  gap: 10px;
}
.icons_num {
  font-size: 10px;
  color: $keyBlack;
}
.picture {
  display: block;
  height: auto;
  overflow: hidden;
  flex-shrink: 1;
  &.picture_obj_movie {
    border-radius: 10px;
    position: relative;
    width: 100%;
    height: 88px;
    &::after {
      display: inline-block;
      width: 50px;
      height: 50px;
      background-image: url('../../../../assets/images/list/icon-play.svg');
      background-size: contain;
      vertical-align: middle;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      @media screen and (max-width: 768px) {
        width: 30px;
        height: 30px;
      }
    }
    img {
      height: 88px;
    }
  }
  &.picture_obj_talk {
    height: 166px;
    img {
      height: 166px;
    }
  }
}

.title {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: $keyGreen;
  line-height: 1.75;
  font-size: 16px;
  font-weight: bold;
  word-break: break-all;
  overflow: hidden;
  flex-grow: 2;
  margin: -5px 0;
}

.title_wrap {
  margin-bottom: auto;
  &.movie .title {
    text-decoration: underline;
  }
}

.name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: $keyBlack;
  line-height: 1.5;
  margin: -2px 0;
  font-size: 10px;
  word-break: break-all;
  overflow: hidden;
}

.foot_icons {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
}
.link_wrap_icon {
  width: 60%;
  font-size: 10px;
  color: $keyGreen;
  list-style: none;
  margin: 0;
  a {
    color: $keyGreen;
  }
  &.textDecoration {
    a {
      text-decoration: none;
    }
  }
}
.info_wrap {
  display: flex;
  gap: 10px;
}
.text_wrap {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 10px;
}

.label_wrap {
  display: flex;
}
.label_text {
  font-size: 10px;
  line-height: 1;
  background-color: $keyGreen;
  color: $keyWhite;
  padding: 2px 5px;
  white-space: nowrap;
  border-radius: 2px;
  list-style: none;
}
.icons_avatar {
  width: 100%;
  color: $keyBlack;
  display: flex;
  gap: 6px;
  font-size: 10px;
  font-weight: bold;
}
.icon_wrap {
  display: flex;
  align-items: center;
  gap: 2px;
}
.icon {
  display: flex;
}
.count {
  font-size: 10px;
  line-height: 1.5;
}
.share_teams_container {
  display: flex;
  overflow: scroll;
}
.share_teams_item {
  display: flex;
}
.share_teams {
  padding: 0 10px;
}
.text_overflow {
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.avatar {
  width: 20%;
}
</style>
