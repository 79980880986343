<template>
  <div class="$style.team_layout">
    <app-header
      :page-title="getPageTitle"
      :back="back"
      :has-setting="showSetting"
      :bg-color="headerColor"
      :show-tabbar="showTabbar"
      :top-fixed="true"
      :team-id="this.team_id"
    >
      <template v-slot:setting-menu v-if="!initProcessing && isValidUser">
        <template v-if="isValidUser">
          <base-overlay-menu-list-title>ユーザー向けメニュー</base-overlay-menu-list-title>
          <base-overlay-menu-list-item to="" @click.native="favoriteClick">{{isFavorite == true ? 'お気に入りから削除する' : 'お気に入りに追加する'}}</base-overlay-menu-list-item>
          <base-overlay-menu-list-item to="" @click.native="inviteClick" v-if="invite == true">このチームに招待する</base-overlay-menu-list-item>
          <base-overlay-menu-list-item to="" @click.native="withDrawClick">このチームを脱退する</base-overlay-menu-list-item>
          <base-overlay-menu-list-item to="" @click.native="eventClick">{{isEventDone == true ? '通知をOFFにする' : '通知をONにする'}}</base-overlay-menu-list-item>
        </template>
        <template v-if="auth_type == 'admin' || auth_type == 'admin_auth'">
          <base-overlay-menu-list-title>管理者向けメニュー</base-overlay-menu-list-title>
          <base-overlay-menu-list-item to="" @click.native="ruleClick" v-if="canTeamRuleEdit">チーム設定の編集</base-overlay-menu-list-item>
        </template>
        <template v-if="member_list_view_flg">
          <base-overlay-menu-list-item to="" @click.native="memberManageClick">チームメンバー管理</base-overlay-menu-list-item>
        </template>
        <template v-if="auth_type == 'admin' || auth_type == 'admin_auth'">
          <base-overlay-menu-list-item to="" @click.native="unionSalonSettingClick" v-if="!isMobile && !isTablet">チームメンバー招待/割当</base-overlay-menu-list-item>
          <base-overlay-menu-list-item to="" @click.native="giftSendClick" v-if="!isMobile && !isTablet">メッセージ機能</base-overlay-menu-list-item>
          <base-overlay-menu-list-item to="" v-if="show_dissolve && can_dissolve" @click.native="dissolveClick">このチームを解散する</base-overlay-menu-list-item>

        </template>
      </template>
    </app-header>

    <div :class="[$style.team_content, { [$style.hide_tabbar]: !showTabbar }]">
      <div :class="[$style.team_content_body, {[$style.team_content_body_loading]: showSpinner}]">
        <template v-if="teamErrorMsgList && teamErrorMsgList.length > 0">
          <transition :name="$style.team"
                      :enter-class="$style.team_enter"
                      :enter-active-class="$style.team_enter_active"
                      :leave-active-class="$style.team_leave_active"
                      :leave-to-class="$style.team_leave_to">
              <div :class="$style.msg_container">
                <base-message type="error" v-for="(error, i) in teamErrorMsgList" :key="i"
                :class="$style.msg_area">{{error.message}}</base-message>
              </div>
          </transition>
        </template>
        <div :class="$style.back_area">
          <app-prev-button @click="$router.push(back)" />
        </div>

        <router-view v-show="true" />
        <div v-if="showSpinner">
          <base-icon name="spinner" size="32px" spin />
        </div>
      </div>
    </div>

    <base-modal
      v-if="showQrGrenerator"
      type="round"
      @close="showQrGrenerator = false"
    >
      <template v-slot:header-title>このチームのQRコード</template>
      <template v-slot:body>
        <div :class="$style.modal_content">
          <vue-qrcode v-if="displayQrGeneratorUrl" :value="displayQrGeneratorUrl" :options="qr_generator_options" tag="img"></vue-qrcode>
        </div>
      </template>
      <template v-slot:footer>
        <base-button
          rounded="rounded"
          width="150px"
          height="50px"
          @click="clickQrCopy"
        >URLをコピーする</base-button>
      </template>
    </base-modal>

  </div>
</template>

<script>
import AppHeader from '@/components/app/AppHeader/AppHeader'
import BaseOverlayMenuListTitle from "@/components/base/BaseOverlayMenuList/BaseOverlayMenuListTitle";
import BaseOverlayMenuListItem from "@/components/base/BaseOverlayMenuList/BaseOverlayMenuListItem";
import isMobile from "@/utils/mixins/isMobile";
import {mapMutations, mapGetters} from 'vuex'
import {fetchTeamInvite, fetchTeamUserInfo, editTeamUser} from '@/helper/common.js'
import BaseButton from '@/components/base/BaseButton/BaseButton'
import BaseModal from '@/components/base/BaseModal/BaseModal'
import VueQrcode from "@chenfengyuan/vue-qrcode";
import BaseIcon from '@/components/base/BaseIcon/BaseIcon'
import BaseMessage from '@/components/base/BaseMessage/BaseMessage'
import AppPrevButton from '@/components/app/AppPrevButton/AppPrevButton'

export default {
  name: 'TeamLayout',
  mixins: [isMobile()],
  components: {BaseOverlayMenuListItem, BaseOverlayMenuListTitle, AppHeader, BaseButton,
  BaseModal, VueQrcode, BaseIcon, BaseMessage, AppPrevButton},

  beforeMount() {

    this.$router.app.$on('page-updated', ({
        pageTitle,
        back,
        showSetting = true,
        showPcBackBtn = false,
        headerColor = 'white',
        bgColor = 'yellow',
        showTabbar = true,
        showFooter = true,
        show_dissolve = false
      }) => {
      this.childPageTitle = pageTitle
      this.back = back
      this.showSetting = showSetting
      this.headerColor = headerColor
      this.bgColor = bgColor
      this.showTabbar = showTabbar
      this.showFooter = showFooter
      this.showPcBackBtn = showPcBackBtn
      this.show_dissolve = show_dissolve
    })
    this.$router.app.$on('show-spinner', (showFlg) => {
      this.showSpinner = showFlg
    })
    this.$router.app.$on('page-refresh', async (team_id ,callback) => {
      this.team_id = team_id
      await this.initFetch()
      callback()
    })
  },
  data() {
    return {
      childPageTitle: '',
      basePageTitle: '',
      showSetting: true,
      back: '',
      headerColor: 'white',
      bgColor: 'yellow',
      showTabbar: true,
      showFooter: true,
      showSpinner: false,
      showPcBackBtn: false,
      team_id: null,
      invite: false,
      approval: false,
      favorite_flg: 0,
      auth_type: null,
      can_dissolve: false,
      show_dissolve: false,
      canTeamRuleEdit: false,
      initProcessing:false,
      processing: false,
      user_belongs: false, // 所属しているかフラグ
      showQrGrenerator: false,
      member_list_view_flg: false, // メンバー管理に遷移できるかフラグ
      displayQrGeneratorUrl: "",
      qr_generator_options: {
        errorCorrectionLevel: "M",
        maskPattern: 0,
        margin: 2,
        scale: 2,
        width: 300,
        color: {
          dark: "#000000FF",
          light: "#FFFFFFFF"
        }
      },
      teamErrorMsgList: []
    }
  },
  computed: {
    ...mapGetters('team', ['getTeamUserEventFlg']),
    onTheEdge() {
      // post-createページの場合はtrueを返す
      return this.$route.name === 'TeamPostCreate' || this.$route.name === 'MiniVoteCreate'
    },
    className() {
      return {
        [this.$style.yellow]: this.bgColor === 'yellow',
        [this.$style.white]: this.bgColor === 'white'
      }
    },
    isFavorite() {
      return !!this.favorite_flg == true
    },
    isEventDone() {
      return !!this.getTeamUserEventFlg == true
    },
    isValidUser() {
      if(this.auth_type && (this.auth_type == 'admin' || this.auth_type == 'admin_auth' || this.auth_type == 'user')){
        return true
      }
      return false
    },
    creatQrCodeUrl() {
        return `${process.env.VUE_APP_BASE_URL}/team/info/${this.team_id}`
    },
    getPageTitle() {
      if(this.childPageTitle) return this.childPageTitle
      if(this.basePageTitle) return this.basePageTitle
      return ''
    },
    isTablet() {
      const ua = navigator.userAgent.toLowerCase()
      // 前提条件：macはタッチスクリーン不採用
      return /ipad|macintosh|android/.test(ua) && 'ontouchend' in document
    }
  },
  methods: {
    ...mapMutations('team', ['setTeamUserEventFlg']),
    async getTeamUserInfo () {
      // 組織ユーザー情報取得
      const {value} = await fetchTeamUserInfo({team_id: this.team_id})
      this.favorite_flg = value?.favorite_flg ?? 0
      this.setTeamUserEventFlg(value?.event_flg)
      this.auth_type = value?.auth_type

      if (
        this.auth_type == 'admin'
        && value?.black_list_flg == 0
        && (value?.status == 'approved' || value?.status == 'no_approval_required')
        ) {
          this.can_dissolve = true
        }

      // valueが返って来たら所属フラグを立てる
      if(value !== undefined){
        this.user_belongs = true
      }
    },
    fetchTeamInfo () {
      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/team/info', { params: { team_id: this.team_id }})
        .then((res) => {
            this.basePageTitle = res.data?.value?.team.name
            this.member_list_view_flg = res.data?.value?.team.member_list_view_flg == 1 ? true : false
        })
    },
    async getTeamInvite () {
      // 未所属の場合はAPIを叩かせない
      if(! this.user_belongs){
        return
      }
      //組織への招待、組織への承認権限確認取得
      const {invite, approval} = await fetchTeamInvite({team_id: this.team_id})

      if(invite) this.invite = true // 0:組織への招待権限がない 1:組織への招待権限がある
      if(approval) this.approval = true // 0:組織への承認権限がない 1:組織への承認権限がある
    },
    fetchScreenAuthTeamUpdate: function () {
      this.canTeamRuleEdit = false

      // 未所属の場合はAPIを叩かせない
      if(! this.user_belongs){
        return
      }

      // 組織の編集権限を確認するAPI
      return this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/screen_auth/team/update', {team_id: this.team_id})
        .then((res) => {

          if (res.data.status == 'success') {
            this.canTeamRuleEdit = true
          }
        })
        .catch((err) => {
          if (err.response.status === 461) { // 組織の編集権限なし
            this.canTeamRuleEdit = false
          }
        })
    },
    async initFetch() {
      this.initProcessing = true
      try {
        if(this.team_id){
          await this.getTeamUserInfo()

          await Promise.all([
            this.fetchTeamInfo(),
            this.getTeamInvite(),
            this.fetchScreenAuthTeamUpdate()
          ])
        }
      }
      finally {
        this.initProcessing = false
      }
    },
    async favoriteClick() {
      if (this.processing) return

      this.processing = true
      // 組織ユーザー登録・更新 [お気に入りに追加する/削除する]
      const {status} = await editTeamUser({team_id: this.team_id, favorite_flg: this.favorite_flg ^ 1})
      if(status == 'success') {
        this.favorite_flg = this.favorite_flg ^ 1
      }
      this.processing = false

    },
    async inviteClick() {
      // 組織ユーザー更新 このチームに招待する
      this.showQrGrenerator = true
    },
    async withDrawClick() {
      if (this.processing) return
      var confirm_result = confirm('本当に脱退しますか。');
      if(!confirm_result) {
          return
      }

      this.teamErrorMsgList = []
      this.processing = true
      //  組織ユーザー登録・更新 [このチームを脱退する]
      const {status, errors} = await editTeamUser({team_id: this.team_id, status: 'withdrawal',auth_type:'not_user'})
      if(status == 'success') {
        // ホーム
        this.$router.push(`/home/home`)
      }else if(status == 'error'){
        this.teamErrorMsgList = errors
        setTimeout(() => this.teamErrorMsgList = [], 5000)
      }
      this.processing = false
    },
    async dissolveClick() {
      if (this.processing) return
      var confirm_result = confirm('本当にチームを解散しますか。');
      if(!confirm_result) {
          return
      }

      this.teamErrorMsgList = []
      this.processing = true
      //  チーム解散処理
      this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/team/dissolve', {team_id: this.team_id})
        .then((res) => {
          if (res.data.status == 'success') {
            this.$router.push(`/home/home`)
          }
        })
        .catch((err) => {
          this.teamErrorMsgList = err.response.data.value.errors
          setTimeout(() => this.teamErrorMsgList = [], 5000)
        })
      this.processing = false
    },
    ruleClick() {
      // チーム設定の編集
      this.$router.push(`/team/create/${this.team_id}`);
    },
    memberManageClick() {
      // チームメンバー管理
      this.$router.push(`/team/${this.team_id}/manage-member`);
    },
    unionSalonSettingClick() {
      // UNION SALON 設定
      this.$router.push(`/union/setting/${this.team_id}`);
    },
    giftSendClick() {
      // メッセージ機能
      this.$router.push(`/union/gift/${this.team_id}`);
    },
    async eventClick() {
      if (this.processing) return

      this.processing = true
      //  組織ユーザー登録・更新 [通知をONにする/OFFにする]
      const {status} = await editTeamUser({ team_id: this.team_id, event_flg: this.isEventDone ^ 1})
      if(status == 'success') {
        this.setTeamUserEventFlg(this.isEventDone ^ 1)
      }
      this.processing = false
    },
    clickQrCopy() {
      if (this.processing) return

      this.processing = true
      const elem = document.createElement('input')
      elem.value = this.creatQrCodeUrl
      document.body.appendChild(elem)
      elem.select()
      document.execCommand('copy')
      document.body.removeChild(elem)
      this.processing = false
    },
  },
  watch: {
    showQrGrenerator(nv, ov) {
      if(!nv && ov){
        this.displayQrGeneratorUrl = ''
      }
      if(nv && !ov){
        this.displayQrGeneratorUrl = this.creatQrCodeUrl
      }
    }
  },
  created() {
    this.team_id = this.$route.params?.team_id
    this.initFetch()
  }
}
</script>

<style lang="scss" module>
.team_content {
  padding-top: 70px;
  padding-bottom: 70px;
  margin: 0 auto;
  max-width: 1280px;
  flex-direction: column;
  min-height: calc(100vh - 70px);
  &.yellow {
    background: #FFFBE4;
  }
  &.white {
    background: #fff;
  }
}

.team_content_body {
  flex: 1;
  text-align: left;
  inline
  &_loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.modal_content {
  margin: 0 20px !important;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  @include mobile() {
    width: auto;
    margin: 20px;
  }
}

.msg_container{
  width: 980px !important;
  margin: 4px auto;
  background: #fff;
  @include mobile() {
    width: auto !important;
    margin: 4px;
    background: transparent;
  }
}
.msg_area {
  width: 100% !important;
  margin: 0 0 4px;
  &:last-of-type{
    margin-bottom: 0;
  }
}

.team {
  &_enter_active, &_leave_active {
    transition: opacity .5s;
  }
  &_enter, &_leave_to {
    opacity: 0;
  }
}

.back_area {
  margin: 30px;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
</style>
