<template>
  <div :class="$style.tab">
    <div :class="$style.tab_header">
      <div @click="handlePrevClick" v-if="!processing">
        <base-icon name='arrow-left' color="white" size="14px"/>
      </div>
      <span :class="$style.tab_index" v-if="!processing">{{ currentTab + 1 }}/{{ tabDataNum }}</span>
      <div @click="handleNextClick" v-if="!processing">
        <base-icon name='arrow-right' color="white" size="14px"/>
      </div>
    </div>
    <div :class="[$style.tab_content, $style.tab_content_loading]" v-if="processing"><base-icon name="spinner" size="32px" spin /></div>
    <div :class="$style.tab_content" v-else>
      <div v-for="(tab, tabIndex) in tabData"
           :key="tabIndex"
           :class="[$style.list_item_wrap, {[$style.active]: currentTab === tabIndex}]"
      >
        <gift-preview-list-item :avatar-image="tab.avatar" :title="tab.item.title" :time="tab.item.now_datetime">
          <template v-slot:body>
            <span :class="$style.pre_wrap">{{ tab.content }}</span>
          </template>
        </gift-preview-list-item>

      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/components/base/BaseIcon/BaseIcon'
import GiftPreviewListItem from './GiftPreviewListItem'

export default {
  name: 'GiftPreviewTab',
  components: { BaseIcon, GiftPreviewListItem},
  props: {
    tabData: {
      type: Array,
      default: () => []
    },
    processing: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      currentTab: 0,
    }
  },
  computed: {
    tabDataNum() {
      return this.tabData.length;
    },
    enablePrev() {
      return this.currentTab > 0
    },
    enableNext() {
      return this.currentTab + 1 < this.tabDataNum
    },
  },
  methods: {
    handlePrevClick() {
      if (!this.enablePrev) return false
      this.currentTab = this.currentTab - 1;
    },
    handleNextClick() {
      if (!this.enableNext) return false
      this.currentTab = this.currentTab + 1;
    }
  }
}
</script>

<style lang="scss" module>
.tab_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;

  .tab_index {
    font-size: 16px;
    color: $keyWhite;
  }
}

.tab_content {
  display: flex;
  justify-content: center;

  .list_item_wrap {
    display: none;
    &.active {
      display: block;
      overflow: hidden;
    }
    .pre_wrap {
      white-space: pre-wrap;
    }
  }
  &_loading {
    padding: 40px 0px 0px
  }
}
</style>
