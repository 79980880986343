export default {
  namespaced: true,
  state: {
    /** メッセージのサービスコード */
    giftServiceCode: null,
    /**
     * メッセージ送信設定
     * ※ユーザの入力値をJsonで保持
    */
    giftSetting: null,
    /** メッセージ送信設定の編集中フラグ */
    giftSettingEditingFlg: false,
    /** メッセージ配信対象ID */
    giftTargetId: null,
  },
  getters: {
    getGiftSetting (state) {
      const setting = JSON.parse(state.giftSetting)
      return setting ? setting : {}
    },
    getGiftSettingOrDefault (state, getters) {
      if (state.giftSetting) { return getters.getGiftSetting }
      else { return {} }
    },
    /** gift_setting_id(メッセージ配信設定ID) */
    getGiftSettingId (state, getters) {
      return getters.getGiftSetting?.id
    },
    /** gift_setting */
    getGiftSettngInfo(state, getters) {
      if (state.giftSettingEditingFlg) {
        return getters.getGiftSetting
      } else {
        return null
      }
    },
    /** send_list_saved_flg */
    getSendListSavedFlg(state, getters) {
      return getters.getGiftSetting?.send_list_saved_flg
    },
    /** service_code */
    getServiceCode (state) {
      return state.giftServiceCode
    },
    /** gift_target_id(メッセージ配信対象ID) */
    getGiftTargetId (state) {
      return state.giftTargetId
    },
  },
  mutations: {
    /** setter */
    setGiftSettingId(state, { payload, getters }) {
      let giftSetting = getters.getGiftSettingOrDefault
      giftSetting.id = payload.gift_setting_id
      state.giftSetting = JSON.stringify(giftSetting)
    },
    /** setter */
    setGiftSettngInfo(state, { payload, getters }) {
      state.giftSettingEditingFlg = true
      let giftSetting = getters.getGiftSettingOrDefault

      // ユーザ入力中のデータ
      giftSetting.title = payload.gift_settng_info.title
      giftSetting.body_1 = payload.gift_settng_info.body_1
      giftSetting.body_2 = payload.gift_settng_info.body_2
      giftSetting.bind_flg = payload.gift_settng_info.bind_flg
      // 選択したメッセージ
      giftSetting.gift_id = payload.gift_settng_info.gift_id
      giftSetting.created_user = payload.gift_settng_info.created_user
      giftSetting.status = payload.gift_settng_info.status
      giftSetting.is_setting_update = payload.gift_settng_info.is_setting_update

      state.giftSetting = JSON.stringify(giftSetting)
    },
    /** setter */
    setSendListSavedFlg(state, { payload, getters }) {
      let giftSetting = getters.getGiftSetting
      if (giftSetting != null) {
        giftSetting.send_list_saved_flg = payload.send_list_saved_flg
        state.giftSetting = JSON.stringify(giftSetting)
      }
    },
    // initGiftSettngInfo(state, payload) {
    initGiftSettngInfo(state, { getters }) {
      state.giftSettingEditingFlg = false
      let giftSetting = getters.getGiftSettingOrDefault

      // ユーザ入力中のデータ
      giftSetting.title = null
      giftSetting.body_1 = null
      giftSetting.body_2 = null
      giftSetting.bind_flg = null
      giftSetting.send_list_saved_flg = false
      // 選択したメッセージ
      giftSetting.gift_id = null
      giftSetting.status = null
      giftSetting.is_setting_update = false

      state.giftSetting = JSON.stringify(giftSetting)
    },
    /** setter */
    setServiceCode(state, payload) {
      state.giftServiceCode = payload.service_code
    },
    /** setter */
    setGiftTargetId(state, payload) {
      state.giftTargetId = payload.gift_target_id
    },
  },
  actions: {
    /** setter */
    commitGiftSettingId({ commit, getters }, payload) {
      return commit('setGiftSettingId', { payload, getters })
    },
    /** setter */
    commitGiftSettngInfo({ commit, getters }, payload) {
      return commit('setGiftSettngInfo', { payload, getters })
    },
    /** setter */
    commitSendListSavedFlg({ commit, getters }, payload) {
      return commit('setSendListSavedFlg', { payload, getters })
    },
    /** setter */
    resetGiftSettngInfo({ commit, getters }) {
      return commit('initGiftSettngInfo', { getters })
    },
    /** setter */
    commitServiceCode({ commit }, payload) {
      return commit('setServiceCode', payload)
    },
    /** setter */
    commitGiftTargetId({ commit }, payload) {
      return commit('setGiftTargetId', payload)
    },
  },
}
