<template>
  <section
    :class="[
      $style.wrapper,
      {
        [$style.read]: this.isRead,
      },
    ]"
    :style="customStyle"
    @click="handleClick"
  >
    <img v-if="clip" :class="$style.clip" src="@/assets/images/clip.svg" />
    <img
      v-if="pin"
      :class="$style.pin"
      src="@/assets/images/favorite_pin.svg"
    />
    <div
      :class="[
        $style.content_wrapper,
        {
          [$style.comment]: commentText,
        },
      ]"
    >
      <div
        :class="[
          $style.picture_wrapper,
          {
            [$style.hasComment]: commentText,
          },
        ]"
      >
        <div
          v-if="
            commentText &&
            head !== 'ブロックしたユーザのコメント' &&
            commentType === 1
          "
        >
          <div
            :class="$style.text"
            :style="`background-color: rgba(${backgroundColor}, .2);`"
          >
            <div :class="$style.comment_text">{{ commentText }}</div>
          </div>
        </div>
        <div
          v-else-if="
            head !== 'ブロックしたユーザのコメント' &&
            (commentType === 2 || commentType === 4)
          "
        >
          <picture :class="$style.file">
            <img :src="image" />
          </picture>
        </div>
        <div
          v-else-if="
            head !== 'ブロックしたユーザのコメント' && commentType === 3
          "
        >
          <picture :class="$style.stamp">
            <img :src="image" />
          </picture>
        </div>
        <div v-else>
          <picture :class="$style.picture">
            <img :src="image" />
          </picture>
        </div>
      </div>
      <div :class="$style.info_wrapper">
        <div :class="$style.header_wrapper">
          <div :class="$style.nick_name">
            {{ nickName }}
          </div>
          <div v-if="displayTag" :class="$style.tag_wrapper">
            <base-tag
              width="100%"
              height="10px"
              :bgColor="tagColor"
              :color="tagTextColor"
              fontSize="xs"
              >{{ tagText }}</base-tag
            >
          </div>

          <p v-else :class="$style.time">
            <time :datetime="timeAttr" :class="$style.time_text">{{
              timeText
            }}</time>
          </p>
          <p :class="$style.foot">{{ foot }}</p>

          <div :class="$style.icons_area">
            <p v-if="ableToSelect" :class="$style.icons">
              <span
                :class="[
                  $style.icons_wrapper,
                  { [$style.selected]: !!selected },
                ]"
              >
                <span>
                  <base-icon
                    :type="iconType"
                    :name="iconEyeName"
                    :color="iconEyeColor"
                    :style="{
                      'padding-right': '2px',
                      visibility: !isRead ? 'visible' : 'hidden',
                    }"
                    size="10px"
                  />
                </span>
                <span>
                  <base-icon
                    v-if="isMentioned"
                    :type="iconType"
                    :name="iconAtName"
                    :color="iconAtColor"
                    :style="{ padding: '0 2px' }"
                    size="10px"
                  />
                </span>
                <span @click.stop="handleSelect">
                  <base-icon
                    :type="iconType"
                    :name="iconHeartName"
                    :color="iconHeartColor"
                    size="10px"
                    @click.stop="handleSelect"
                  />
                </span>
              </span>
            </p>
          </div>

          <span v-if="type === 'app'" :class="$style.overlay_menu_wrapper">
            <base-overlay-menu-list>
              <template v-slot:menu-button>
                <div :class="$style.menu_button_icon" />
              </template>
              <base-overlay-menu-list-item to="" @click.native="handleFavorite">
                {{
                  favorite ? 'お気に入りから削除する' : 'お気に入りに追加する'
                }}
              </base-overlay-menu-list-item>
              <base-overlay-menu-list-item to="" @click.native="handlePin">
                {{ pin ? 'クリップを解除する' : 'クリップする' }}
              </base-overlay-menu-list-item>
              <base-overlay-menu-list-item to="" @click.native="handleShare">
                シェアする
              </base-overlay-menu-list-item>
            </base-overlay-menu-list>
          </span>
          <span
            v-else-if="type === 'team'"
            :class="$style.overlay_menu_wrapper"
          >
            <base-overlay-menu-list>
              <template v-slot:menu-button>
                <div :class="$style.menu_button_icon" />
              </template>
              <base-overlay-menu-list-item
                to=""
                @click.native="handleFavorite"
                v-if="showFavorite"
              >
                {{
                  favorite ? 'お気に入りから削除する' : 'お気に入りに追加する'
                }}
              </base-overlay-menu-list-item>
              <base-overlay-menu-list-item
                to=""
                @click.native="handlePin"
                v-if="showPin"
              >
                {{ pin ? 'クリップを解除する' : 'クリップする' }}
              </base-overlay-menu-list-item>
              <base-overlay-menu-list-item
                to=""
                @click.native="handleShare"
                v-if="showShare"
              >
                シェアする
              </base-overlay-menu-list-item>
              <base-overlay-menu-list-item
                to=""
                @click.native="handleRead"
                v-if="showRead"
              >
                {{ read ? '未読にする' : '既読にする' }}
              </base-overlay-menu-list-item>
              <base-overlay-menu-list-item
                to=""
                @click.native="handleDelete"
                v-if="showDelete"
              >
                削除する
              </base-overlay-menu-list-item>
              <base-overlay-menu-list-item
                to=""
                @click.native="handleBrowse"
                v-if="showBrowse"
              >
                この投稿を閲覧する
              </base-overlay-menu-list-item>
              <base-overlay-menu-list-item
                to=""
                @click.native="handleReport"
                v-if="showReport"
              >
                {{
                  reported
                    ? '※このユーザは通報済みです'
                    : 'このユーザを通報する'
                }}
              </base-overlay-menu-list-item>
              <base-overlay-menu-list-item
                to=""
                @click.native="handleBlock"
                v-if="showBlock"
              >
                {{
                  blockUser
                    ? 'このユーザのブロックを解除する'
                    : 'ユーザをブロックする'
                }}
              </base-overlay-menu-list-item>
            </base-overlay-menu-list>
          </span>
        </div>
        <h3 :class="$style.head">{{ head }}</h3>
      </div>
    </div>
  </section>
</template>

<script>
import BaseIcon from '../../../base/BaseIcon/BaseIcon'
import BaseTag from '../../../base/BaseTag/BaseTag'
import BaseOverlayMenuList from '@/components/base/BaseOverlayMenuList/BaseOverlayMenuList'
import BaseOverlayMenuListItem from '@/components/base/BaseOverlayMenuList/BaseOverlayMenuListItem'

export default {
  name: 'AppCarouselCommentNotificationItem',
  components: {
    BaseIcon,
    BaseTag,
    BaseOverlayMenuList,
    BaseOverlayMenuListItem,
  },
  props: {
    head: {
      type: String,
      default: '',
    },
    foot: {
      type: String,
      default: '',
    },
    iconHeart: {
      type: String,
      default: 'heart',
      validator: (icon) => !icon || ['heart'].includes(icon),
    },
    iconEye: {
      type: String,
      default: 'eye',
      validator: (icon) => !icon || ['eye'].includes(icon),
    },
    iconAt: {
      type: String,
      default: 'at',
      validator: (icon) => !icon || ['at'].includes(icon),
    },
    image: {
      type: String,
      default: require('@/assets/images/no-image.png'),
    },
    tag: {
      type: String,
      default: null,
    },
    customTagText: {
      type: String,
      default: null,
    },
    customTagColor: {
      type: String,
      default: null,
    },
    customTagTextColor: {
      type: String,
      default: null,
    },
    time: {
      type: [String, Date, Number],
      default: () => new Date(),
    },
    selected: {
      type: Boolean,
      default: null, // true:選択, false:非選択, null:選択機能無し
    },
    clip: {
      type: Boolean,
      default: false,
    },
    pin: {
      type: Boolean,
      default: false,
    },
    favorite: {
      type: Boolean,
      default: false,
    },
    read: {
      type: Boolean,
      default: false,
    },
    borderColor: {
      type: String,
      default: '#FFFFFF',
    },
    steps: {
      type: Array,
      default: () => [],
      validator: (steps) => steps.every((step) => step >= 0 && step <= 100),
    },
    item: {
      type: Object,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: false,
      default: null,
    },
    showShare: {
      type: Boolean,
      default: true,
    },
    showRead: {
      type: Boolean,
      default: true,
    },
    showPin: {
      type: Boolean,
      default: true,
    },
    showFavorite: {
      type: Boolean,
      default: true,
    },
    showDelete: {
      type: Boolean,
      default: true,
    },
    reported: {
      type: Boolean,
      default: false,
    },
    showReport: {
      type: Boolean,
      default: true,
    },
    blockUser: {
      type: Boolean,
      default: false,
    },
    showBlock: {
      type: Boolean,
      default: true,
    },
    showBrowse: {
      type: Boolean,
      default: false,
    },
    isCursorPointer: {
      type: Boolean,
      default: true,
    },
    backgroundColor: {
      type: String,
      required: false,
      default: '',
    },
    commentText: {
      type: String,
      required: false,
      default: '',
    },
    nickName: {
      type: String,
      required: false,
      default: '',
    },
    commentType: {
      type: Number,
      required: false,
      default: 0,
    },
    isMentioned: {
      type: Boolean,
      default: false,
    },
    isRead: {
      type: Boolean,
      default: false,
    },
    userTeamStatus: {
      type: String,
      default: '',
    }
  },
  methods: {
    displayType: function () {
      if (!this.open_flg) {
        return 'draft'
      } else {
        if (this.start_datetime.length > 0 && this.end_datetime.length > 0) {
          let startTime = this.$dayjs(this.start_datetime)
          let endTime = this.$dayjs(this.end_datetime)
          if (!this.$dayjs().isBetween(startTime, endTime)) {
            return 'expired'
          }
        }
        return 'normal'
      }
    },
    handleSelect() {
      // 選択イベントが発火するように設定
      this.$emit('select', !this.selected, this.item)
    },
    handleClick() {
      // クリックイベントが発火するように設定
      this.$emit('click', this.item)
    },
    handleFavorite() {
      // お気に入りイベント
      this.$emit('favorite', this.item)
    },
    handlePin() {
      // ピンイベント
      this.$emit('pin', this.item) //user側の変更は文言でクリップと言いつつピンなので注意
    },
    handleRead() {
      // 未読/既読イベント
      this.$emit('read', this.item)
    },
    handleDelete() {
      // 削除イベント
      this.$emit('delete', this.item)
    },
    handleShare() {
      // シェアイベント
      this.$emit('share', this.item)
    },
    handleReport() {
      // ユーザ通報イベント
      this.$emit('report', this.item)
    },
    handleBlock() {
      // ブロックイベント
      this.$emit('blockUser', this.item)
    },
    handleBrowse() {
      // 閲覧イベント
      this.$emit('browse', this.item)
    },
  },
  computed: {
    ableToSelect() {
      return this.selected === false || this.selected === true
    },
    isDisplayHeart() {
      return this.userTeamStatus !== 'before_approval'
    },
    customStyle() {
      let style = {}

      if (this.isCursorPointer) {
        // trueの場合マウスオーバーでカーソルが指マークに変化
        style.cursor = 'pointer'
      }
      return style
    },
    iconType() {
      return 'fas' // iconやselectedによって変化する予定
    },
    iconAtName() {
      return this.iconAt
    },
    iconHeartName() {
      return this.iconHeart
    },
    iconEyeName() {
      return this.iconEye
    },
    iconAtColor() {
      return 'red'
    },
    iconHeartColor() {
      return this.selected ? 'pink' : 'gray'
    },
    iconEyeColor() {
      return 'green'
    },
    timeAttr() {
      const date =
        this.time instanceof Date
          ? this.time
          : new Date(this.time.replace(/-/g, '/'))
      return date.toLocaleString()
    },
    timeText() {
      if (!this.time) {
        return ''
      }
      const date =
        this.time instanceof Date
          ? this.time
          : new Date(this.time.replace(/-/g, '/'))
      return `${date.getFullYear()}/${('0' + (date.getMonth() + 1)).slice(
        -2
      )}/${('0' + date.getDate()).slice(-2)} ${('0' + date.getHours()).slice(
        -2
      )}:${('0' + date.getMinutes()).slice(-2)}`
    },
    displayTag() {
      if (
        this.customTagText ||
        this.customTagTextColor ||
        this.customTagColor
      ) {
        return true
      } else if (this.tag) {
        return true
      }

      return false
    },
    tagColor() {
      if (this.customTagColor) {
        return this.customTagColor
      }

      const tagColorMap = {
        need: 'pink',
        step: 'yellow',
        done: 'yellow',
      }

      return tagColorMap[this.tag] || 'pink'
    },
    tagStepText() {
      const tagStepText = this.steps
        .map((step, i) => `Step${i + 1} - ${step}%`)
        .slice(0, 2)
        .join('  ')
      return tagStepText
    },
    tagText() {
      if (this.customTagText) {
        return this.customTagText
      }

      const tagTextMap = {
        need: '受講してください',
        step: this.tagStepText,
        done: '受講完了',
      }

      return tagTextMap[this.tag] || ''
    },
    tagTextColor() {
      if (this.customTagTextColor) {
        return this.customTagTextColor
      }

      const tagTextColorMap = {
        need: 'white',
        step: 'black',
        done: 'black',
      }

      return tagTextColorMap[this.tag] || 'white'
    },
  },
}
</script>

<style lang="scss" module>
.wrapper {
  position: relative;
  width: 100%;
  border-bottom: 1px solid $borderGray;
  background-color: $keyWhite;
  display: flex;
  padding: 16px 0;
  gap: 20px;
  @include mobile_only() {
    width: 150px;
    height: 160px;
  }
}
.comment {
  flex-direction: column-reverse;
  gap: 16px;
  @include mobile_only() {
    gap: 10px;
  }
  .head {
    font-size: 14px;
    line-height: 1.75;
    font-weight: normal;
    @include mobile_only() {
      font-size: 12px;
    }
  }
}
.wrapper.read {
  opacity: 50%;
}

.clip {
  position: absolute;
  top: -16px;
  left: 4px;
}

.pin {
  z-index: 2;
  position: absolute;
  top: -14px;
  left: -3px;
  transform: rotate(-21deg);
}
.content_wrapper {
  display: flex;
  width: 100%;
  // flex-direction: row;
  .comment {
    flex-direction: column;
  }
}
.info_wrapper {
  width: 100%;
}
.hasComment {
  width: 100%;
}
.header_wrapper {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  @include mobile_only() {
    margin: 2px -1px;
  }
}

.tag_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.time {
  display: flex;
  color: $fontGray;
  margin: 0px;
}
.time_text {
  display: inline-block;
  font-size: 12px;
  white-space: nowrap;
  text-align: left;
}
.icons_area {
  margin-left: auto;
  @include mobile_only() {
    display: none;
  }
}
.icons {
  display: inline-block;
  color: $borderLiteGray;
  margin: 0;

  &_wrapper {
    display: flex;
  }

  .selected {
    color: $keyPink;
  }
}

.icons span {
  float: right;
}

.overlay_menu_wrapper {
  display: flex;
  .menu_button_icon {
    width: 10px;
    height: 10px;
    cursor: pointer;
    background-image: url('../../../../assets/images/グループ 1656.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}
.picture {
  display: flex;
  overflow: hidden;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 200px;
  @include mobile_only() {
    height: 64px;
  }
}
.picture > img {
  margin-top: 10px;
  height: auto;
  width: auto;
  max-width: 80%;
  max-height: 80%;
  @include mobile_only() {
    margin: 4px;
  }
}

.stamp {
  display: block;
  width: 200px;
  height: 130px;
  overflow: hidden;
  text-align: center;
  justify-content: center;
  align-items: center;
  @include mobile_only() {
    height: 64px;
  }
}

.stamp > img {
  margin-top: 10px;
  height: auto;
  width: auto;
  max-width: 80%;
  max-height: 80%;
  @include mobile_only() {
    margin: 4px;
  }
}

.file {
  display: block;
  width: 200px;
  height: 130px;
  overflow: hidden;
  text-align: center;
  justify-content: center;
  align-items: center;
  @include mobile_only() {
    height: 64px;
  }
}

.file > img {
  margin-top: 10px;
  height: auto;
  width: auto;
  max-width: 80%;
  max-height: 80%;
  @include mobile_only() {
    margin: 4px;
  }
}

.comment_text {
  margin: 10px;
  @include mobile_only() {
    margin: 4px;
  }
}

.text {
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  color: $fontGray;
  height: 128px;
  max-height: 128px;
  line-height: 20px;
  margin: 0;
  font-size: 19px;
  word-break: break-all;
  overflow: hidden;
  @include mobile_only() {
    font-size: 10px;
    line-height: 12px;
    max-height: 64px;
  }
}
.head {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: $keyGreen;
  line-height: 1.5;
  font-size: 16px;
  word-break: break-all;
  overflow: hidden;
  margin: 0;
  padding-left: 0;
  font-weight: bold;
  @include mobile_only() {
    line-height: 1.75;
    font-size: 12px;
  }
}

.foot {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: $fontGray;
  max-height: 32px;
  line-height: 16px;
  margin-bottom: -4px;
  font-size: 15px;
  transform: scale(1);
  word-break: break-all;
  overflow: hidden;
  position: absolute;
  bottom: 8px;
  padding-left: 3px;
  @include mobile_only() {
    font-size: 10px;
    line-height: 10px;
    max-height: 20px;
  }
}

.nick_name {
  display: block;
  font-weight: bold;
  line-height: 1.25;
  font-size: 12px;
  word-break: break-all;
  overflow: hidden;
  @include mobile_only() {
    font-size: 12px;
  }
}
.icons_wrapper {
  display: flex;
  gap: 10px;
}
</style>
