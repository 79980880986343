<template>
  <div class="$style.form_field">
    <!-- アコーディオンアイコンなし / サイズ大きめ -->
    <section :class="[$style.form_field_section, {[$style.no_border]: noBorder}]" v-if="size === 'large'">
      <label :class="$style.title">{{label}}</label>
      <slot />
    </section>
    <base-accordion v-else-if="expand" :title="label" :expanded="expanded" :class="$style.no_border_top">
      <div :class="$style.form_field_input">
        <slot />
      </div>
    </base-accordion>
    <section :class="[$style.form_field_section, {[$style.no_border]: noBorder}]" v-else>
      <label :class="$style.form_field_section_label" v-if="label" :for="name">{{label}}</label>
      <slot />
    </section>
  </div>
</template>
<script>
import BaseAccordion from "@/components/base/BaseAccordion/BaseAccordion";
export default {
  name: 'BaseFormField',
  components: {BaseAccordion},
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
    },
    expand: {
      type: Boolean,
      default: false
    },
    expanded: {
      type: Boolean,
      default: false
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ''
    }
  }
}
</script>
<style lang="scss" module>
.form_field_section {
  padding: 17px 34px;
  border-bottom: 1px solid $borderLiteGray;

  &.no_border {
    padding-bottom: 0;
    border-bottom: none;
  }

  &_label {
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 22px;
  }
}
.form_field_input {
  padding:0 34px 20px;
}
.title {
  font-weight: bold;
  font-size: 24px;
}
.no_border_top {
  & > * {
    border-top: none !important;
  }
}
</style>
