<template>
  <div :class="$style.page_nav_wrap">
    <div :class="$style.page_nav_border">
      <div :class="[$style.page_nav, {[$style.disabled]: (isToPrevious && !previousPost) || (!isToPrevious && !nextPost)}]" @click="handleClick">
        <span v-if="isToPrevious" :class="[$style.span_flex]">
          <a
            :class="[$style.prev, {[$style.disabled]: !previousPost}]"
          ><base-icon type="fas" name="chevron-left" size="18px" /></a>
          <a
            :class="[$style.page, {[$style.disabled]: !previousPost}]"
          >
            前へ
          </a>
        </span>
        <span v-else :class="[$style.span_flex]">
          <a
            :class="[$style.page, {[$style.disabled]: !nextPost}]"
          >
            次へ
          </a>
          <a
            :class="[$style.next, {[$style.disabled]: !nextPost}]"
          ><base-icon type="fas" name="chevron-right" size="18px" /></a>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import BaseIcon from "@/components/base/BaseIcon/BaseIcon"

export default {
  components: { BaseIcon },
  props: {
    isToPrevious: {
      type: Boolean,
      default: true
    },
    teamId: {
      type: Number
    },
    previousPost: {
      type: Number,
      default: null
    },
    nextPost: {
      type: Number,
      default: null
    },
    previousType: {
      type: Number
    },
    nextType: {
      type: Number
    },
  },
  methods: {
    handleClick() {
      if (this.isToPrevious) {
        this.clickChatOrMiniVoteMove(this.previousType, this.previousPost)
      } else {
        this.clickChatOrMiniVoteMove(this.nextType, this.nextPost)
      }
    },
    clickChatOrMiniVoteMove: function (type, chatId) {
      if (!chatId || !type) {
        return
      }
      let targetUrl = ''
      switch (type) {
        case 2: // ミニ投票
          targetUrl = `/team/mini-vote-detail/${this.teamId}/${chatId}`
          break
        default:
          targetUrl = `/team/post-detail/${this.teamId}/${chatId}`
          break
      }
      this.$router.push(targetUrl).then(() => {
        console.log('Navigation success')
        window.location.reload()
      }).catch(err => {
        console.error('Navigation error:', err)
      })
    }
  },
}
</script>
<style lang="scss" module>
$fontGray: #B7B7B7;

.page_nav {
  text-align: center;
  padding: 2px 20px 0px;

  @media (max-width: 767px) {
    padding: 2px 5px 0px;
  }

  display: flex;
  align-items: center;
  cursor: pointer;
  &.disabled {
    cursor: default;
  }

  .prev,
  .next {
    color: rgb(45, 204, 211);
    font-weight: bold;
    cursor: pointer;

    &.disabled {
      color: $fontGray;
      cursor: default;
    }
  }

  .page {
    color: $keyBlack;
    cursor: pointer;
    margin: 0 13px;
    font-size: 16px;

    &.disabled {
      color: $fontGray;
      cursor: default;
    }


    @media (max-width: 767px) {
      margin: 0 6px;
      font-size: 13px;
    }
  }
}
.page_nav_border{
  border: solid 1px rgb(45, 204, 211);
  border-radius: 6px;
  display: inline-block;
  padding: 2px 0;
}
.span_flex {
  display: flex;
}
</style>
