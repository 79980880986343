<template>
  <section
    :class="$style.article"
    v-bind="$attrs"
    @click="handleSelect"
    :style="customStyle"
  >
    <div :class="$style.img_wrapper" style="position: relative">
      <base-img
        size="180px"
        :width="String('180px')"
        :height="String('120px')"
        :path="image"
        :border-color="borderColor"
        :outline-color="outlineColor"
      />
    </div>
    <h3 :class="$style.head">
      <slot name="head">
        {{ head.length > 20 ? head.slice(0, 20) + '...' : head }}
      </slot>
    </h3>
  </section>
</template>

<script>
import BaseImg from '../../../base/BaseImg/BaseImg'

export default {
  name: 'AppHeaderTeamItem',
  components: { BaseImg },
  props: {
    image: {
      type: String,
    },
    head: {
      type: String,
    },
    borderColor: {
      type: String,
      default: '#FFFFFF',
    },
    outlineColor: {
      type: String,
      default: undefined,
    },
    link: {
      type: String,
      default: undefined,
    },
  },
  methods: {
    handleSelect() {
      // 選択イベントが発火するように設定
      // this.$emit('select', !this.selected)
      this.$emit('click', this.item)
    },
  },
  computed: {
    customStyle() {
      let style = {}

      if (this.isCursorPointer) {
        // trueの場合マウスオーバーでカーソルが指マークに変化
        style.cursor = 'pointer'
      }
      return style
    },
  },
}
</script>

<style lang="scss" module>
.article {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.head {
  display: block;
  color: $keyBlack;
  line-height: 1.5;
  font-size: 14px;
  font-weight: bold;
  word-break: break-all;
  overflow: hidden;
  margin: 0;
}

.link {
  color: $keyGreen;
  line-height: 1.5;
  font-size: 14px;
  text-decoration: underline;
}
.img_wrapper img {
  object-fit: contain;
}
</style>
