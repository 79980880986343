/** カラーコード変換 */
export function convertColor(color) {
  return color ? `rgb(${color})` : null
}

/**
 * いいね！フラグ更新処理
 * @param {*} lectures
 * @param {*} index
 * @param {*} good_flg
 */
export function commitGoodFlg(lectures, index, good_flg) {
  if (lectures.length > 0) {
    const lecture = lectures[index]
    lecture.good_flg = good_flg
  }
}

/** ページタイトル生成 */
export function createPageTitle(lecture) {
  return `${lecture.course_name}：${lecture.corner_name}`
}

/**
 * booleanをNumberに変換
 * @param {*} value
 * @returns
 */
export function fromBoolToNumber(value) {
  switch (value) {
    case true:
      return 1
    case false:
      return 0
    default:
      return null
  }
}
