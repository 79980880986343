<template>
  <div class="$style.open_layout">
    <app-open-header :has-login="hasLogin" :show-menu="showMenu"/>
    <div :class="$style.open_content">
      <div :class="$style.open_content_body">
        <router-view />
      </div>
      <app-footer/>
    </div>
  </div>
</template>

<script>
import AppOpenHeader from '@/components/app/AppOpenHeader/AppOpenHeader'
import AppFooter from '@/components/app/AppFooter/AppFooter'
export default {
  name: 'OpenLayout',
  components: {AppOpenHeader, AppFooter},
  beforeMount() {
    this.$router.app.$on('page-updated',
      () => {})
  },
  data: function () {
    return {
      exceptLoginRoute: [
        '/open/profile_edit',
        '/open/profile_confirm',
        '/open/verification_confirm',
        //'/open/ju_setting',
        '/open/password_reset'
      ],
      hideMenu: [
        '/open/profile_edit',
        '/open/profile_confirm',
      ]
    }
  },
  computed: {
    hasLogin: function(){
      return ! this.exceptLoginRoute.includes(this.$route.path)
    },
    showMenu: function(){
      return ! this.hideMenu.includes(this.$route.path)
    }
  },
  methods: {},
}
</script>

<style lang="scss" module>
.open_content {

  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 90px);
  background: #FFFBE4;
}
.open_content_body {
  display: flex;
  flex: 1;
}
</style>
