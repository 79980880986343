<template>
  <div class="$style.union_layout">
    <app-header
      :page-title="pageTitle"
      :back="back"
      :has-setting="showSetting"
      :bg-color="headerColor"
      :show-tabbar="showTabbar"
    >
      <template v-slot:setting-menu>
        <base-overlay-menu-list-item to="" v-on:click.native="favoriteClick()">
          {{
            !!favoriteFlg ? 'お気に入りから削除する' : 'お気に入りに追加する'
          }}
        </base-overlay-menu-list-item>
        <base-overlay-menu-list-item to="" v-on:click.native="shareUrl()">
          シェアする
        </base-overlay-menu-list-item>
      </template>
    </app-header>
    <div :class="[$style.union_content, className]">
      <div :class="$style.union_content_body">
        <router-view v-show="!showSpinner" />
        <div :class="$style.loading" v-if="showSpinner">
          <base-icon name="spinner" size="32px" spin />
        </div>
      </div>
      <div :class="$style.footer">
        <app-footer v-if="showFooter" bgColor="yellow" />
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from '@/components/app/AppHeader/AppHeader'
import AppFooter from '@/components/app/AppFooter/AppFooter'
import BaseOverlayMenuListItem from '@/components/base/BaseOverlayMenuList/BaseOverlayMenuListItem'
import BaseIcon from '@/components/base/BaseIcon/BaseIcon'
import {
  editCornerUser,
  editLectureHistoryChatFavorite,
} from '@/helper/common.js'

export default {
  name: 'UnionLayout',
  components: { AppHeader, AppFooter, BaseOverlayMenuListItem, BaseIcon },
  beforeMount() {
    this.$router.app.$on(
      'page-updated',
      ({
        pageTitle,
        back,
        showSetting = true,
        headerColor = 'white',
        bgColor = 'yellow',
        showTabbar = true,
        showFooter = true,
        cornerId = null,
        lectureId = null,
        favoriteFlg = 0,
      }) => {
        this.pageTitle = pageTitle
        this.back = back
        this.showSetting = showSetting
        this.headerColor = headerColor
        this.bgColor = bgColor
        this.showTabbar = showTabbar
        this.showFooter = showFooter
        this.cornerId = cornerId
        this.lectureId = lectureId
        this.favoriteFlg = favoriteFlg
      }
    )
    this.$router.app.$on('show-spinner', (showFlg) => {
      this.showSpinner = showFlg
    })
  },
  data() {
    return {
      pageTitle: '',
      showSetting: true,
      back: '',
      headerColor: 'white',
      bgColor: 'yellow',
      showTabbar: true,
      showFooter: true,
      showSpinner: false,
      cornerId: null,
      lectureId: null,
      favoriteFlg: 0,
      processing: false,
      target: {
        corner: 0,
        lecture: 1,
      },
    }
  },
  computed: {
    className() {
      return {
        [this.$style.yellow]: this.bgColor === 'yellow',
        [this.$style.white]: this.bgColor === 'white',
        [this.$style.gray]: this.bgColor === 'gray',
      }
    },
    /** お気に入りに追加する/削除する 処理対象取得 */
    getTarget() {
      return this.cornerId != null ? this.target.corner : this.target.lecture
    },
  },
  methods: {
    shareUrl() {
      navigator.clipboard
        .writeText(location.href)
        .then(() => {
          this.$loginfo(location.href)
          alert('URLをクリップボードにコピーしました')
        })
        .catch((error) => this.$logerror(error))
    },
    async favoriteClick() {
      if (this.processing) return

      this.processing = true
      const newFlg = this.favoriteFlg ^ 1
      // コーナー内ユーザー登録・更新 / 講座内ユーザー登録・更新 [お気に入りに追加する/削除する]
      const result =
        this.getTarget == this.target.corner
          ? await editCornerUser({
              corner_id: this.cornerId,
              favorite_flg: newFlg,
            })
          : await editLectureHistoryChatFavorite({
              lecture_id: this.lectureId,
              favorite_flg: newFlg,
            })

      if (result.status == 'success') {
        this.favoriteFlg = newFlg
      }
      this.processing = false
    },
  },
}
</script>

<style lang="scss" module>
.union_content {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 70px);
  background: $openBg;
  @include mobile() {
    min-height: calc(100vh - 70px - 50px);
    padding-bottom: 70px;
  }

  &.gray {
    background: $backGroundGray;
  }
}

.loading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.union_content_body {
  display: flex;
  flex: 1;
}
.footer {
  @include mobile() {
    display: none;
  }
}
</style>
