<template>
  <div :class="customClass">
    <span :class="captionClass">{{ captionText }}</span>
    <div :class="$style.form_input_area">
      <div
        v-show="$slots.prepend"
        ref="prepend_slot"
        :class="[$style.form_input_prepend, searchBar ? $style.search_bar : '']"
      >
        <slot name="prepend" />
      </div>
      <input
        ref="input"
        :class="[
          $style.form_input,
          previousSpace ? $style.previous_space : '',
          searchBar ? $style.search_bar : '',
        ]"
        v-bind="$attrs"
        v-model="boundValue"
        @focus="onFocus"
        @blur="onBlur"
        @change="onChange"
      />

      <div
        v-show="$slots.append"
        ref="append_slot"
        :class="$style.form_input_append"
      >
        <slot name="append" />
      </div>
    </div>

    <p v-if="!!errorText" :class="$style.error_txt">{{ errorText }}</p>
  </div>
</template>

<script>
export default {
  name: 'BaseInput',
  inheritAttrs: false,
  model: {
    prop: 'modelValue',
    event: 'update',
  },
  props: {
    modelValue: {},
    /**
     * prependおよびappendの見た目を指定できます
     */
    look: {
      type: String,
      default: 'outline1',
      validator: (value) =>
        ['', 'outline1', 'outline2', 'border', 'border2'].includes(value),
    },
    /**
     * エラー時の文言を指定するとエラー表示になります
     * String: そのままエラー文として表示します。空文字列は無視されます
     * Boolean: trueならエラー文なしで枠のみがエラー表示になります。
     */
    error: {
      type: [String, Boolean],
      default: '',
    },
    caption: {
      type: String,
      default: null,
    },
    previousSpace: {
      type: Boolean,
      default: false,
    },
    searchBar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      focus: false,
    }
  },
  computed: {
    customClass() {
      return [
        this.$style.wrapper,
        {
          [this.$style.has_prepend]: !!this.$slots.prepend,
          [this.$style.has_append]: !!this.$slots.append,
          [this.$style.focus]: !!this.focus,
          [this.$style.error]: !!this.error,
        },
        this.$style[this.look],
      ]
    },
    captionClass() {
      return [
        this.$style.caption,
        { [this.$style.caption_hidden]: !this.captionText },
      ]
    },
    boundValue: {
      get() {
        // console.log('input get', { this: this, value: this.modelValue })
        return this.modelValue
      },
      set(value) {
        // console.log('input set', { this: this, value })
        this.$emit('update', value)
      },
    },
    errorText() {
      return typeof this.error === 'string' ? this.error : ''
    },
    captionText() {
      // 見出し文字が省略され、borderスタイルでなければエラー時に枠の左上に'Error'と表示する（junionのデザイン指定）
      if (this.caption == null && this.error && this.look != 'border' && this.look != 'border2') {
        return 'Error'
      }
      return this.caption
    },
  },
  methods: {
    onFocus(event) {
      this.focus = true
      this.$emit('focus', event)
    },
    onBlur(event) {
      this.focus = false
      this.$emit('blur', event)
    },
    onChange(event) {
      this.$emit('change', event)
    },
  },
}
</script>
<style lang="scss" module>
$blackTrans10: #e7e7e7;

$baseSize_Border: 35px;
$baseSize_Outline1: 56px;
$baseSize_Outline2: 40px;

$baseColor: #262626;
// $baseColor_Border: #262626;
// $baseColor_Outline1: #262626;
// $baseColor_Outline2: #262626;

$fontSize_Border: 18px;
$fontSize_Outline1: 16px;
$fontSize_Outline2: 16px;

$borderSize_Border: 1px;
$borderSize_Outline1: 2px;
$borderSize_Outline2: 2px;

$borderColor_Border: #707070;
$borderColor_Outline1: #b7b7b7;
$borderColor_Outline2: #b7b7b7;

$borderColor_Border_Focus: #707070;
$borderColor_Outline1_Focus: #2dccd3;
$borderColor_Outline2_Focus: #4ac0c0;

$borderColor_Border_Error: #ff221f;
$borderColor_Outline1_Error: #ff4d4d;
$borderColor_Outline2_Error: #ff4d4d;

$borderRadius_Border: 0px;
$borderRadius_Outline1: 4px;
$borderRadius_Outline2: 30px;

$textSize_Error: 13px;
$textSpecing_Error: 4px;
$textColor_Error: #ff0000;

.wrapper {
  position: relative;
}

.form_input_area {
  display: flex;
  align-items: center;
  // background: #fff;
}

.form_input_prepend,
.form_input_append {
  display: flex;
  align-items: center;
  padding: 0 8px;
  box-sizing: border-box;
  background: #fff;
  transition: all 0.1s;
}

.form_input {
  display: block;
  width: 100%;
  min-width: 160px;
  padding: 0 16px;
  box-sizing: border-box;
  flex: 1;
  outline: none;
  background: #fff;
  transition: all 0.1s;
}
.form_input:disabled,
.form_input:read-only {
  background: $blackTrans10;
}
.previous_space {
  margin: 1em 0 0 2em;
  @media screen and (max-width: 767px) {
    margin: 0;
  }
}
.form_input {
  color: $baseColor !important;
}

.form_input,
.form_input_prepend,
.form_input_append,
.outline1 .form_input,
.outline1 .form_input_prepend,
.outline1 .form_input_append {
  height: $baseSize_Outline1;
  font-size: $fontSize_Outline1;
  color: $borderColor_Outline1;
  border: $borderSize_Outline1 solid $borderColor_Outline1;
  border-radius: $borderRadius_Outline1;
}
.focus .form_input,
.focus .form_input_prepend,
.focus .form_input_append,
.outline1.focus .form_input,
.outline1.focus .form_input_prepend,
.outline1.focus .form_input_append {
  color: $borderColor_Outline1_Focus;
  border-color: $borderColor_Outline1_Focus;
}
.error .form_input,
.error .form_input_prepend,
.error .form_input_append,
.outline1.error .form_input,
.outline1.error .form_input_prepend,
.outline1.error .form_input_append {
  color: $borderColor_Outline1_Error;
  border-color: $borderColor_Outline1_Error;
}

.outline2 .form_input,
.outline2 .form_input_prepend,
.outline2 .form_input_append {
  height: $baseSize_Outline2;
  font-size: $fontSize_Outline2;
  color: $borderColor_Outline2;
  border: $borderSize_Outline2 solid $borderColor_Outline2;
  border-radius: $borderRadius_Outline2;
}
.outline2.focus .form_input,
.outline2.focus .form_input_prepend,
.outline2.focus .form_input_append {
  color: $borderColor_Outline2_Focus;
  border-color: $borderColor_Outline2_Focus;
}
.outline2.error .form_input,
.outline2.error .form_input_prepend,
.outline2.error .form_input_append {
  color: $borderColor_Outline2_Error;
  border-color: $borderColor_Outline2_Error;
}

.border .form_input,
.border .form_input_prepend,
.border .form_input_append {
  height: $baseSize_Border;
  font-size: $fontSize_Border;
  color: $borderColor_Border;
  border: $borderSize_Border solid $borderColor_Border;
  border-radius: $borderRadius_Border;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  background: none;
}
.border.focus .form_input,
.border.focus .form_input_prepend,
.border.focus .form_input_append {
  color: $borderColor_Border_Focus;
  border-color: $borderColor_Border_Focus;
}
.border.error .form_input,
.border.error .form_input_prepend,
.border.error .form_input_append {
  color: $borderColor_Border_Error;
  border-color: $borderColor_Border_Error;
}

.border2 .form_input{
  height: $baseSize_Border;
  font-size: $fontSize_Border;
  color: $borderColor_Border;
  border: $borderSize_Border solid $borderColor_Border;
  border-radius: $borderRadius_Border;
  border-top: 0;
  border-left: 0;
  border-right: 0;

  background: none;
}
.border2 .form_input_prepend,
.border2 .form_input_append {
  height: $baseSize_Border;
  font-size: $fontSize_Border;
  color: $borderColor_Border;
  border: $borderSize_Border solid $borderColor_Border;
  border-radius: $borderRadius_Border;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  background: none;
}
.border2.focus .form_input,
.border2.focus .form_input_prepend,
.border2.focus .form_input_append {
  color: $borderColor_Border_Focus;
  border-color: $borderColor_Border_Focus;
}
.border2.error .form_input,
.border2.error .form_input_prepend,
.border2.error .form_input_append {
  color: $borderColor_Border_Error;
  border-color: $borderColor_Border_Error;
}

.has_prepend .form_input {
  padding: 0;
  border-left: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.has_append .form_input {
  border-right: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.form_input_prepend {
  border-right: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.form_input_append {
  border-left: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.error_txt {
  font-size: $textSize_Error;
  margin: $textSpecing_Error 0 0 0;
  color: $textColor_Error;
  white-space: pre-wrap;
}

.caption {
  display: block;
  position: absolute;
  top: -6px;
  left: 6px;
  font-size: 10px;
  background-color: #fff;
  border-radius: 100vh;
  padding: 0px 2px;
  transition: all 0.1s;
}
.caption_hidden {
  opacity: 0;
}

.border .caption {
  color: $borderColor_Border;
}
.border.focus .caption {
  color: $borderColor_Border_Focus;
}
.border.error .caption {
  color: $borderColor_Border_Error;
}

.outline1 .caption {
  color: $borderColor_Outline1;
  font-weight: bolder;
}
.outline1.focus .caption {
  color: $borderColor_Outline1_Focus;
}
.outline1.error .caption {
  color: $borderColor_Outline1_Error;
}

.outline2 .caption {
  color: $borderColor_Outline2;
  font-weight: bolder;
}
.outline2.focus .caption {
  color: $borderColor_Outline2_Focus;
}
.outline2.error .caption {
  color: $borderColor_Outline2_Error;
}

.search_bar {
  height: 40px;
  border: 0;
  color: $keyGreen;
}
</style>
