<template>
  <div :class="$style.home_wrapper">
    <div :class="$style.home">
      <div :class="$style.dash_board">
        <div :class="$style.iframe_container">
          <iframe
            :src="iframe_url"
            width="100%"
            height="100%"
            frameborder="0"
          ></iframe>
        </div>
        <!-- <a
          href="#"
          :class="$style.dash_board_arrow_right"
          @click.prevent="switchTeam"
        >
          <span :class="$style.dash_board_arrow_right_title">
            {{ tab_name }}
          </span>
          <base-icon name="chevron-right" color="yellow" size="35px" />
        </a> -->
      </div>
    </div>
    <!-- <app-footer bgColor="yellow"/> -->
  </div>
</template>

<script>
// import BaseIcon from '@/components/base/BaseIcon/BaseIcon'
// import AppFooter from '@/components/app/AppFooter/AppFooter'
export default {
  name: 'Index',
  components: {
    // BaseIcon,
    // AppFooter
  },
  async mounted() {
    this.$router.app.$emit('show-spinner', true)
    await this.getTeamList()
    this.$router.app.$emit('show-spinner', false)
  },
  data() {
    return {
      iframe_url: '',
      // tab_name: '',
      pageTitle: '',
      // team_list_index: parseInt(this.$route.params.index_num ?? 0),
      team_id: this.$route.params.team_id, // URLからteam_idを取得
      team_list: [],
    }
  },
  methods: {
  updateTeamData() {
    // URLから取得したteam_idを整数型に変換
    const numericTeamId = parseInt(this.team_id, 10);

    // team_idを使用して現在のチームを見つける
    const currentTeam = this.team_list.find(team => team.team_id === numericTeamId);
    if (currentTeam) {
      this.iframe_url = currentTeam.url;
      this.pageTitle = currentTeam.team_name;

      // 次のチームを探すロジック
      // const currentIndex = this.team_list.findIndex(team => team.team_id === numericTeamId);
      // const nextIndex = (currentIndex + 1) % this.team_list.length; // リストの末尾に達したら最初に戻る
      // const nextTeam = this.team_list[nextIndex];
      // this.tab_name = nextTeam ? nextTeam.team_name : 'UNION SALON';

      // this.$router.app.$emit('page-updated', {
      //   pageTitle: this.pageTitle,
      //   showSetting: false,
      //   headerColor: 'yellow',
      // });

    } else {
        this.$router.push({ path: '/union/home' });
      }
    },
    getTeamList() {
      this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/team/belong/list')
        .then((response) => {
          this.team_list = response.data.value.team_list
          this.updateTeamData()
        })
        .catch((error) => {
          this.$logerror(error)
        })
    },
    // switchTeam() {
    //   this.team_id++
    //   if (this.team_id >= this.team_list.length) {
    //     this.$router.push({ path: '/union/home' })
    //   } else {
    //     this.updateTeamData()
    //   }
    // },
  },
}
</script>

<style lang="scss" module>
.home_wrapper {
  width: 100%;
}
.home {
  display: flex;
  width: 960px;
  height: 100%; // calc(100% - 40px); // 40pxはAppFooterの高さ
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0 auto;
  overflow: hidden;

  @include mobile() {
    width: 100%;
  }
}

.dash_board {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  position: relative;

  &_arrow_left,
  &_arrow_right {
    position: absolute;
    display: flex;
    align-items: center;
    height: 35px;
    margin-bottom: 6px;
    top: 200px;
    color: $keyBlack;
    text-decoration: none;

    @include mobile() {
      top: 84px;
    }

    &_title {
      font-size: 13px;
      line-height: 35px;
      font-weight: bold;
      @include mobile() {
        display: none;
      }
    }
  }
  &_arrow_left {
    left: 15px;
  }
  &_arrow_right {
    right: 15px;
  }

  .iframe_container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}
</style>
