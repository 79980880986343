const ConstantsPlugin = {}

ConstantsPlugin.install = function (Vue) {
  Vue.prototype.$constants = {
    USER_ID: 2,
    API_VERSION: 'v1.2',
    KUMIAI_SERVICE_CODE: 'junion',
  }
}

export default ConstantsPlugin
