<template>
  <div :class="$style.datepicker_wrapper">
    <base-input
      ref='input'
      v-model="boundValue"
      :type="type"
      caption=""
      v-bind="$attrs"
      :class='$style.datetime_input'
      :max="attrMax"
      :min="attrMin"
      required
      @focus="handleFocus"
      @blur="handleBlur"
      @change="handleChange"
    >
      <template v-slot:prepend>
        <slot name="prepend">
          <span
            v-if="prepend"
            :class="[$style.prepend_label, { [$style[$attrs.look]]: !!$attrs.look }]"
          >
            {{ prepend }}
          </span>
        </slot>
      </template>
      <template v-slot:append>
        <slot name="append">
          <span
            v-if="append"
            :class="[$style.append_label, { [$style[$attrs.look]]: !!$attrs.look }]"
          >
            {{ append }}
          </span>
        </slot>
      </template>
    </base-input>
    <div v-if="is_firefox"
      :class='$style.calendar_opaque_icon'
      :style='opaqueIconPosition'
    />
    <div v-else
      :class='$style.calendar_icon'
      :style='iconPosition'
      @click.prevent='handleIconClick'
    />
  </div>
</template>

<script>
import BaseInput from "../BaseInput/BaseInput";
import dayjs from 'dayjs'

const DEFAULT_MAX_TIME = '23:59'
const DEFAULT_MIN_TIME = '00:00'
const ICON_SIZE = 20

export default {
  components: { BaseInput },
  model: {
    prop: 'modelValue',
    event: 'update',
  },
  props: {
    modelValue: {
      type: [String, Date],
    },
    type: {
      type: String,
      default: 'datetime-local'
    },
    prepend: {
      type: String,
    },
    append: {
      type: String,
    },
    /**
     * 入力の最大値を指定します。
     * type=dateの際は時間の指定は無視されます。
     *
     */
    max: {
      type: String,
      default: '2030-01-01T23:59',
    },
    /**
     * 入力の最小値を指定します。
     * type=dateの際は時間の指定は無視されます。
     */
    min: {
      type: String,
      default: '1901-01-01T00:00',
    }
  },
  data() {
    return {
      tempDate: '',
      input_height: 0,
      append_width: 0,
      is_firefox: false,
    }
  },
  mounted() {
    if (this.$refs.input) {
      this.input_height = this.$refs.input.$el.getBoundingClientRect().height
      if (this.$refs.input.$refs.append_slot) {
        this.append_width = this.$refs.input.$refs.append_slot.getBoundingClientRect().width
      }
    }
    this.checkBrowser();
  },
  computed: {
    attrMax() {
      if (this.type === 'date') {
        return this.max.replace(/T.+/, '')
      } else if (this.type === 'datetime-local' && !this.max.includes('T')) {
        return this.max + 'T' + DEFAULT_MAX_TIME
      }
      return this.max
    },
    attrMin() {
      console.log('this min', this.min)
      if (this.type === 'date') {
        return this.min.replace(/T.+/, '')
      } else if (this.type === 'datetime-local' && !this.min.includes('T')) {
        console.log('hello')
        return this.min + 'T' + DEFAULT_MIN_TIME
      }
      return this.min
    },
    iconPosition() {
      return {
        top: `${(this.input_height - ICON_SIZE) / 2}px`,
        right: `${this.append_width + (this.append ? -2 : 0) + 8}px`
      }
    },
    opaqueIconPosition() {
      // Firefoxの場合、デフォルトのカレンダーアイコンを隠すために透過していないカレンダー画像を用いる
      // datetime-local(時分あり)、date（時分なし）でカレンダー画像位置を調整
      return {
        top: `${(this.input_height - ICON_SIZE) / 2}px`,
        right: this.type === 'datetime-local' ? `${this.append_width + (this.append ? -2 : 0) + 21}px` : `${this.append_width + (this.append ? -2 : 0) + 3}px`
      }
    },
    boundValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update', value)
      },
    },
  },
  methods: {
    handleIconClick() {
      this.$refs.input.$refs.input.click()
      this.$refs.input.$refs.input.focus()
    },
    handleFocus(event) {
      if (this.is_firefox && this.type === 'datetime-local' && !this.isSatisfied()) {
        // IRET_JUNION_UNI_VERSE-1702 ブラウザがFirefoxかつtypeがdatetime-local(時分あり)かつ有効値でない場合、現在日時をセット
        const date = dayjs().format('YYYY-MM-DDTHH:mm');
        this.tempDate = date;
        this.$emit('update', this.tempDate)
        return
      }
      this.tempDate = event.target.value
    },
    handleBlur(event) {
      this.tempDate = event.target.value
      // フォーカスアウト時入力値が有効値ならフォームを更新
      if (this.isSatisfied()) {
        this.$emit('update', this.tempDate)
      }
    },
    handleChange(event) {
      console.log('change')
      this.tempDate = event.target.value
    },
    isSatisfied() {
      console.log('is satis', this.tempDate)
      const targets = this.tempDate.split('-')
      if ( targets.length === 1 ) return false
      const target = Number(targets[0])
      return String(target).length === 4 && !this.isBefore() && !this.isAfter()
    },
    isBefore() {
      const _min = dayjs(this.attrMin)
      const _val = dayjs(this.tempDate)
      return _val.isBefore(_min)
    },
    isAfter() {
      const _max = dayjs(this.attrMax)
      const _val = dayjs(this.tempDate)
      return _val.isAfter(_max)
    },
    checkBrowser() {
      const agent = window.navigator.userAgent.toLowerCase();
      this.is_firefox = agent.indexOf("firefox") !== -1;
    }
  }
}
</script>

<style lang="scss" module>

.datepicker_wrapper {
  position: relative;

  .calendar_icon {
    position: absolute;
    width: 20px;
    height: 20px;
    background-image: url("../../../assets/images/datepicker-icon.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .calendar_opaque_icon {
    position: absolute;
    width: 20px;
    height: 20px;
    background-image: url("../../../assets/images/datepicker-opaque-icon.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    pointer-events: none;
  }

  .datetime_input {

    input {
      position: relative;
      -webkit-appearance: none;
      appearance: none;
      display: inline-flex;
      font-size: 16px !important;
    }
    input::-webkit-inner-spin-button{
      -webkit-appearance: none;
    }
    input::-webkit-clear-button{
      -webkit-appearance: none;
    }
    input::-webkit-calendar-picker-indicator {
      position: absolute;
      width: 20px;
      height: 20px;
      right: 4px;
      z-index: 2;
      opacity: 0;
      cursor: pointer;
    }
  }

}

.prepend_label {
  color: #666;
  font-size: 11px;
  margin-right: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &.outline1 {
  }
  &.outline2 {
  }
  &.border {
  }
}
.append_label {
  color: #666;
  font-size: 11px;
  margin-left: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &.outline1 {
  }
  &.outline2 {
  }
  &.border {
  }
}
</style>
