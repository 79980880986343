<template>
  <div :class="[$style.member_manage_bg1, {[$style.manage_member]:initLoading}]">
    <div :class="$style.member_manage_bg2" v-if="!initLoading">
      <app-prev-button margin="25px 10px 0px" @click="$router.push(team_id ? `/team/home/${team_id}` : `/home/home`)" />
      <section :class="$style.member_manage">
        <div :class="$style.member_manage_header">
          <h1 :class="$style.member_manage_title">{{ team.name }}</h1>
          <p :class="$style.member_manage_summary">
            <span :class="$style.member_manage_summary_members">
              <span>アクティブメンバー: {{ team.member_count }}名</span>
              <span>管理者: {{ team.admin_count }}</span>
              <template v-if="team.auth_type != 'user'">
                <span :class="$style.member_manage_invited">承認待ち: {{ team.approval_pending_count }}</span>
              </template>
            </span>
            <br>
            <span :class="$style.member_manage_summary_team_id">Team-ID: {{ team_id }}</span>
          </p>
          <div :class="$style.search_text">
            <a v-if="searchValue.length > 0">{{ `『${searchValue}』の検索結果を表示中` }}</a>
          </div>
          <div :class="$style.member_manage_action">
            <base-button
              width="185px"
              rounded="rounded"
              height="40px"
              @click="showSearch = true"
            >メンバーを検索する</base-button>
          </div>
        </div>

        <section :class="$style.member_manage_status">
          <template v-if="team.auth_type != 'user'">
            <div :class="$style.bulk_approval_button">
              <base-button
                v-if="before_approval_member && before_approval_member.length > 0"
                width="150px"
                rounded="rounded"
                height="40px"
                @click="bulkApproval"
                style=""
              >一括承認</base-button>
            </div>

            <base-accordion
              type="normal"
              title="承認待ち"
              :expanded="expandBeforeApprovalMember"
            >
              <ul :class="$style.member_list">
                <li
                  :class="[isOwn(user), isAdmin(user), isBlackUser(user)]"
                  v-for="user in before_approval_member"
                  :key="user.user_id"
                >
                  <router-link :to="'/team/' + team_id + '/member-profile/' + user.user_id" v-if="!!user.display_name_flg">
                    {{ user.name }}({{ user.furigana }})
                  </router-link>
                  <router-link :to="'/team/' + team_id + '/member-profile/' + user.user_id" v-else>
                    {{ user.nickname }}
                  </router-link>
                </li>
              </ul>
            </base-accordion>
          </template>

          <base-accordion
            type="normal"
            title="管理者"
            :expanded="expandAuthMember"
          >
            <ul :class="$style.member_list">
              <li
                :class="[isOwn(user), isAdmin(user), isBlackUser(user)]"
                v-for="user in auth_member"
                :key="user.user_id"
              >
                <router-link :to="'/team/' + team_id + '/member-profile/' + user.user_id" v-if="!!user.display_name_flg">
                  {{ user.name }}({{ user.furigana }})
                </router-link>
                <router-link :to="'/team/' + team_id + '/member-profile/' + user.user_id" v-else>
                  {{ user.nickname }}
                </router-link>
              </li>
            </ul>
          </base-accordion>

          <base-accordion
            type="normal"
            title="メンバー"
            :expanded="expandMember"
          >
            <ul :class="$style.member_list" v-if="team.auth_type != 'user'">
              <li
                :class="[isOwn(user), isAdmin(user), isBlackUser(user)]"
                v-for="user in member"
                :key="user.user_id"
              >
                <router-link :to="'/team/' + team_id + '/member-profile/' + user.user_id" v-if="!!user.display_name_flg">
                  {{ user.name }}({{ user.furigana }})
                </router-link>
                <router-link :to="'/team/' + team_id + '/member-profile/' + user.user_id" v-else>
                  {{ user.nickname }}
                </router-link>
              </li>
            </ul>
            <ul :class="$style.status_user_member_list" v-else>
              <li
                :class="[isOwn(user), isAdmin(user), isBlackUser(user)]"
                v-for="user in member"
                :key="user.user_id"
              >
                <router-link :to="'/team/' + team_id + '/member-profile/' + user.user_id" v-if="!!user.display_name_flg">
                  {{ user.name }}({{ user.furigana }})
                </router-link>
                <router-link :to="'/team/' + team_id + '/member-profile/' + user.user_id" v-else>
                  {{ user.nickname }}
                </router-link>
              </li>
            </ul>
          </base-accordion>
          <template v-if="team.auth_type != 'user'">
            <base-accordion
            type="normal"
            title="過去メンバー"
            :expanded="expandOldMember"
          >
            <ul :class="$style.member_list">
              <li
                :class="[isOwn(user), isAdmin(user), isBlackUser(user)]"
                v-for="user in old_member"
                :key="user.user_id"
              >
                <router-link :to="'/team/' + team_id + '/member-profile/' + user.user_id" v-if="!!user.display_name_flg">
                  {{ user.name }}({{ user.furigana }})
                </router-link>
                <router-link :to="'/team/' + team_id + '/member-profile/' + user.user_id" v-else>
                  {{ user.nickname }}
                </router-link>
              </li>
            </ul>
          </base-accordion>
          </template>

          <template v-if="team.auth_type != 'user'">
            <base-accordion
            type="normal"
            title="非承認メンバー"
            :expanded="expandNotApprovalMember"
          >
            <ul :class="$style.member_list">
              <li
                :class="[isOwn(user), isAdmin(user), isBlackUser(user)]"
                v-for="user in not_approval_member"
                :key="user.user_id"
              >
                <router-link :to="'/team/' + team_id + '/member-profile/' + user.user_id" v-if="!!user.display_name_flg">
                  {{ user.name }}({{ user.furigana }})
                </router-link>
                <router-link :to="'/team/' + team_id + '/member-profile/' + user.user_id" v-else>
                  {{ user.nickname }}
                </router-link>
              </li>
            </ul>
          </base-accordion>
        </template>
        </section>

        <app-scroll-button/>

        <app-team-member-search-menu
          v-if="showSearch"
          @close="showSearch = false"
          :member="member"
          :team_id="team_id"
          :text="searchValue"
          @search="searchMember"
        />
      </section>
    </div>
    <div v-if="initLoading" :class="$style.manage_member_loading_area">
      <base-icon name="spinner" size="32px" spin />
    </div>
    <base-modal
      v-if="showBulkApproval"
      type="round"
      @close="showBulkApproval = false"
    >
      <template v-slot:header-title>承認待ちユーザー一覧</template>
      <template v-slot:body>
        <div :class="$style.modal_content">
          <div :class="$style.select_all">
            <base-button
              rounded="rounded"
              width="120px"
              bordered
              @click="selecrtAll"
            >全て選択</base-button>
          </div>
          <div :class="$style.before_approval_member">
            <base-check-box
              v-for="user in before_approval_member"
              :key="user.id"
              :name="user.name"
              :value="user.user_id"
              v-model="bulk_approval_member"
              style="flex: 0 0 50%; margin-bottom: 10px;"
            >
              <span :class="$style.nickname">{{ user.nickname + `&nbsp;&nbsp;&nbsp;` + user.name }}</span>
            </base-check-box>
          </div>
          <p :class="$style.error_txt" v-if="err_message">{{ err_message }}</p>
        </div>
      </template>
      <template v-slot:footer>
        <base-button
          rounded="rounded"
          width="160px"
          height="50px"
          @click="approve"
          >選択したユーザーを<br>承認する</base-button
        >
      </template>
    </base-modal>
  </div>
</template>
<script>
import BaseButton from "@/components/base/BaseButton/BaseButton"
import BaseCheckBox from '@/components/base/BaseCheckBox/BaseCheckBox'
import BaseAccordion from "@/components/base/BaseAccordion/BaseAccordion"
import AppTeamMemberSearchMenu from "@/components/app/AppMenu/AppTeamMemberSearchMenu/AppTeamMemberSearchMenu"
import BaseIcon from '@/components/base/BaseIcon/BaseIcon'
import BaseModal from '@/components/base/BaseModal/BaseModal'
import AppScrollButton from '@/components/app/AppScrollButton/AppScrollButton'
import AppPrevButton from '@/components/app/AppPrevButton/AppPrevButton'
// import isMobile from "@/utils/mixins/isMobile"

export default {
  name: 'TeamManageMember',
  // mixins: [isMobile()],
  components: {AppTeamMemberSearchMenu, BaseAccordion, BaseButton, BaseCheckBox, BaseIcon, BaseModal, AppPrevButton, AppScrollButton},
  props: {
    team_id: {
      type: Number,
      required: true
    }
  },
  mounted() {
    this.$router.app.$emit('page-updated', {
        pageTitle: 'チームメンバー（管理）',
        back: this.team_id ? `/team/home/${this.team_id}` : `/home/home`,
        showSetting: false,
        headerColor: 'yellow',
        bgColor: 'white',
        showFooter: true// !this.isMobile
      })
  },
  data() {
    return {
      showSearch: false,
      team: {
        name: "",
        admin_count: 0,
        approval_pending_count: 0,
        team_id: 0,
        member_count: 0,
        auth_type: ""
      },
      member: [],
      auth_member: [],
      old_member: [],
      before_approval_member: [],
      not_approval_member: [],
      bulk_approval_member: [],

      expandBeforeApprovalMember: true,
      expandAuthMember: true,
      expandMember: false,
      expandOldMember: false,
      expandNotApprovalMember: false,

      searchValue: '',
      err_message: '',

      initLoading: true,
      showBulkApproval: false,
      posting: false,
    }
  },
  created: async function(){
    const team = await this.fetchTeam()
    this.team.name = team.name
    this.team.admin_count = team.admin_count
    this.team.approval_pending_count = team.approval_pending_count
    this.team.team_id = team.team_id
    this.team.member_count = team.member_count
    this.team.auth_type = team.auth_type

    const member = await this.fetchMember()

    this.sortMembers(member)

    this.initLoading = false
  },
  methods: {
    isOwn(user) {
      return user.juid === this.$store.state.auth.juid ? [ this.$style.member_is_me ] : ''
    },
    isAdmin(user) {
      return user.in_team_auth_type == 'admin' ? [ this.$style.member_is_admin ] : ''
    },
    isBlackUser(user) {
      return user.in_team_black_list_flg ? [ this.$style.member_is_black ] : ''
    },
    fetchTeam: async function(){
      let team = {}

      await this.$axios.get('/api/' + this.$constants.API_VERSION + '/team/info', {
        params: {
          "team_id": this.team_id
        }
      }).then((res) => {
        team = res.data.value.team
      }).catch((err) => {
        this.$router.push('/error/' +err.response.status)
      })

      return team
    },
    fetchMember: async function(){
      let member = []

      await this.$axios.get('/api/' + this.$constants.API_VERSION + '/user/list', {
        params: {
          team_id: this.team_id,
          with_trashed_flg: "1"
        }
      }).then((res) => {
        member = res.data.value
      })

      return member
    },
    searchMember: function (text) {
      this.initLoading = true

      this.showSearch = false

      this.searchValue = text

      this.$axios.get('/api/' + this.$constants.API_VERSION + '/user/list', {
        params: {
          team_id: this.team_id,
          sort: "id",
          direction: "asc",
          with_trashed_flg: "1",

          //以下が検索パラメータ
          nickname: text,
          juid: text,
          name: text
        }
      }).then((res) => {
        const member = res.data.value

        this.sortMembers(member)

        // カウントしなおし
        this.team.member_count = this.auth_member.length + this.member.length
        this.team.approval_pending_count = this.before_approval_member.length
        this.team.admin_count = this.auth_member.length

        //全アコーディオンを開く
        this.expandBeforeApprovalMember = true
        this.expandAuthMember = true
        this.expandMember = true
        this.expandOldMember = true
        this.expandNotApprovalMember = true

        this.initLoading = false
      })
    },
    sortMembers(members){
      // メンバー振り分け情報のリフレッシュ
      this.old_member = []
      this.before_approval_member = []
      this.not_approval_member = []
      this.auth_member = []
      this.member = []

      // メンバー振り分け team_status team_auth_typeから
      members.forEach(
        function (v) {
          if (v.in_team_status == 'before_approval') {
            this.before_approval_member.push(v)
          } else if (
            ['admin', 'admin_auth'].includes(v.in_team_auth_type) &&
            ['approved', 'no_approval_required'].includes(v.in_team_status)
          ) {
            this.auth_member.push(v)
          } else if (
            v.in_team_auth_type === 'user' &&
            ['approved', 'no_approval_required'].includes(v.in_team_status)
          ) {
            this.member.push(v)
          } else if (['withdrawal', 'expulsion'].includes(v.in_team_status)) {
            this.old_member.push(v)
          } else if (v.in_team_status === 'not_approval') {
            this.not_approval_member.push(v)
          }
        }.bind(this)
      )
    },
    // 一括承認モーダルを表示
    bulkApproval() {
      this.showBulkApproval = true
    },
    // 一括選択する
    selecrtAll() {
      this.bulk_approval_member = this.before_approval_member.map(user => user.user_id)
    },
    // 一括承認する
    approve: async function() {
      if (this.posting) return
      this.posting = true
      await this.$axios.post('/api/' + this.$constants.API_VERSION + '/team/user/approve', {
        user_id_list: this.bulk_approval_member,
        team_id: this.team_id
      })
      .then(() => {
        this.posting = false
        this.reload()
      })
      .catch((err) => {
        this.posting = false
        this.err_message = err.response.data.value.errors[0].message
      })
    },
    reload(){
      window.location.reload()
    },
  }
}
</script>
<style lang="scss" module>

.manage_member {
  background-color: white;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  overflow: hidden;
  justify-content: center;
  text-align: center;

  &_loading_area {
    width: 100% !important;
    position: absolute;
    top: 50%
  }
}

.member_manage_bg1 {
  width: 100%;
  background-color: $backGround;

  @include pc() {
    padding-bottom: 50px;// いる？要確認
  }
}
.member_manage_bg2 {
  background-color: $keyWhite;
  @include pc() {
    width: 980px;
    margin: 0 auto;
  }

  @include mobile() {
    width: 100%;
    padding-bottom: 50px; // 最後のアコーディオンが開いた後にさらにスクロールしないと見れない
  }
}
.member_manage {
  background-color: $keyWhite;
  display: flex;
  width: 375px;
  flex-direction: column;
  align-items: center;
  padding: 30px 0 0 0;
  margin: 0 auto;

  @include mobile() {
    padding: 25px 0 0 0;
  }
}
.member_manage_header {
  width: 100%;
  padding: 0 32px;
  box-sizing: border-box;
}
.member_manage_title {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 3px;
}
.member_manage_summary {
  margin: 0;
  padding: 0;
  margin-bottom: 17px;
  transform: scale(0.9);
  transform-origin: 0;
  font-size: 10px;
  line-height: calc(13px / 0.9);
}
.member_manage_summary_members {
  & > *:not(:last-child) {
    margin-right: 8px;
  }
}
.member_manage_summary_team_id {
}

.member_manage_status {
  width: 100%;
}

.search_text {
  text-align: -webkit-center;
  transform: scale(0.9);
  font-size: 15px;
}

.member_manage_action {
  text-align: center;
  margin-bottom: 21px;

}
.member_manage_invited {
  color: #FF221F;
}
.member_list {
  padding: 0;
  margin: 0 32px 16px;
  list-style: none;
  font-size: 11px;
  li {
    &:not(:last-child) {
      margin-bottom: 6px;
    }

    a {
      color: $KeyDarkGreen;
    }
  }
}
.status_user_member_list {
  padding: 0;
  margin: 0 32px 16px;
  list-style: none;
  font-size: 11px;
  column-count: 2;
  li {
    &:not(:last-child) {
      margin-bottom: 6px;
    }

    a {
      color: $KeyDarkGreen;
    }
  }
}

.member_is_me {
  &::after {
    content: '※本人';
    color: #FF221F;
  }
}

.member_is_admin {
  &::before {
    content: '★';
    color: $keyPink;
  }
}

.member_is_black {
  &::before {
    content: url("@/assets/images/person_add_disabled_black_24dp.svg");
  }
}

.edit_footer {
  display: block;
  position: sticky;
  width: 100%;
  height: 85px;

  @include mobile(){
    background: $keyYellow;
    box-sizing: border-box;
    border-top: 1px solid $borderLiteGray;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 12;
  }

  .confirm_button_wrap {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    & > :first-child {
      margin-right: 20px;
    }
  }
}
.modal_content {
  margin: 0 20px !important;
  width: auto;
  @include mobile() {
    width: auto;
    margin: 20px;
  }
}
.select_all {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  margin-right: 10px;
}
.before_approval_member {
  max-height: 100px;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
}
.nickname {
  font-size: larger;
}

.bulk_approval_button {
  display: flex;
  justify-content: flex-end;
  float: right;
  border-top: 1px solid #b9b9b9;
  padding-top: 7px;
}

$textSize_Error: 13px;
$textSpecing_Error: 4px;
$textColor_Error: #ff0000;

.error_txt {
  font-size: $textSize_Error;
  margin: $textSpecing_Error 0 0 0;
  color: $textColor_Error;
  white-space: pre-wrap;
}

</style>
