<template>
  <div
    :class="[$style.back_button_wrap, { [$style.no_mobile]: noMobile }]"
    :style="{ 'margin': margin }"
  >
    <a :href="href" @click="$emit('click')"
    >
      <base-icon name='arrow-left' size='14px'/>
      <span :class="$style.back_button_label"><slot>戻る</slot></span>
    </a>
  </div>
</template>
<script>
import BaseIcon from '@/components/base/BaseIcon/BaseIcon'

export default {
  components: { BaseIcon },
  props: {
    /** アンカーリンクのhref。代わりにv-on:clickでもよい */
    href: {
      type: String,
      default: undefined
    },
    /** PC画面しかないならtrue */
    noMobile: {
      type: Boolean,
      default: false
    },
    /** マージン。styleに渡される */
    margin: {
      type: String,
      default: "0 0 32px"
    },
  }
}
</script>
<style lang="scss" module>
.back_button_wrap {
  width: 100%;
  line-height: 1.0;

  @include mobile_only(){
    &:not(.no_mobile) {
      display: none;
    }
  }

  a {
    display: inline-flex;
    align-items: center;
    color: $keyBlack;
    cursor: pointer;
  }

  .back_button_label {
    font-size: 11px;
    line-height: 14px;
    font-weight: bold;
    text-decoration: underline;
    margin-left: 8px;
  }
}
</style>
