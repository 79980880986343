<!-- AppListItemのコピー -->
<template>
  <section
    :class="[
      $style.article,
      {[$style.clip_on]: this.clip, [$style.pin_on]: this.pin},
    ]"
  >
    <header :class="$style.header_wrapper">
      <div :class="$style.header_row1">
        <div :class="{[$style.read]: this.read}">
          <router-link :to="''">
            <base-avatar
              size="24px"
              :class="[$style.avatar, {[$style.read]: this.read}]"
              :path="avatarImage"
            />
          </router-link>
        </div>

        <h3 :class="[$style.title, {[$style.read]: this.read}]">{{ title }}</h3>

        <div :class="$style.menu">
          <div :class="$style.menuDefaultButton">
            <base-icon name="ellipsis-h" size="18px" color="black" />
          </div>
        </div>

      </div>

      <div :class="[$style.header_row2, {[$style.read]: this.read}]">
        <div :class="$style.tag_time">
          <base-tag
            width="30px"
            height="16px"
            padding="0"
            bgColor="white"
            color="black"
            :bordered="true"
            fontSize="m"
          >投稿</base-tag>
          <!-- <base-tag
            v-if="type =='vote'"
            width="50px"
            height="16px"
            padding="0"
            bgColor="black"
            color="white"
            fontSize="m"
          >ミニ投票</base-tag> -->
          <time :class="$style.time">{{ time }}</time>
        </div>

        <div :class="$style.counters">
          <div :class="[$style.browsed, {[$style.on]: read}]">
            <span :class="$style.icon"><base-icon size="10" name="eye" /></span>
            <span :class="$style.count">{{ readCount }}</span>
          </div>

          <div :class="[$style.commented, {[$style.on]: commentCount > 0}]">
            <span :class="$style.icon"
              ><base-icon size="10" name="comment-dots"
            /></span>
            <span :class="$style.count">{{ commentCount }}</span>
          </div>

          <div :class="[$style.like, {[$style.on]: favoriteFlg}]">
            <span :class="$style.icon"
              ><base-icon size="10" name="heart"/>
            </span>
            <span :class="$style.count">{{ goodsCount }}</span>
          </div>
        </div>
      </div>
    </header>

    <section
      :class="[
        $style.body_wrapper,
        { [$style.collapse]: this.collapse },
      ]"
      :style="customStyle"
    >

      <div :class="$style.body_content">
        <p :class="$style.body_content_text">{{ text }}</p>
      </div>

      <div :class="$style.body">
        <slot name="body"/>
      </div>

    </section>
  </section>
</template>

<script>
import BaseAvatar from '@/components/base/BaseAvatar/BaseAvatar'
import BaseIcon from '@/components/base/BaseIcon/BaseIcon'
import BaseTag from "@/components/base/BaseTag/BaseTag"

export default {
  components: {
    BaseAvatar,
    BaseIcon,
    BaseTag,
  },
  name: 'GiftPreviewListItem',
  props: {
    avatarImage: {
      type: String,
      default: undefined
    },
    // avatarColor: {
    //   type: String,
    // },
    title: {
      type: String,
      default: ""
    },
    text: {
      type: String,
      default: ""
    },
    time: {
      type: String,
      default: undefined
    },
    clip: {
      type: Boolean,
      default: false,
    },
    pin: {
      type: Boolean,
      default: false,
    },
    read: {
      type: Boolean,
      default: false,
    },
    collapse: {
      type: Boolean,
      default: false,
    },
    readCount: {
      type: String,
      default: '0'
    },
    commentCount: {
      type: String,
      default: '0'
    },
    goodsCount: {
      type: String,
      default: '0',
    },
    favoriteFlg: {
      type: Boolean,
      default: false,
    },
    goodFlg: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      processing: false,
    }
  },
  computed: {
    customStyle() {
      return {
        'background-color': `${this.bodyColor}`,
      }
    },
  },
  methods: {
    hoge: function (e) {
      e.preventDefault()
    },
  },
  watch: {
    goodsCount: function () {
      return this.goodsCount ? Number(this.goodsCount) : 0;
    },
    commentCount: function () {
      return this.commentCount ? Number(this.commentCount) : 0;
    },
    readCount: function () {
      return this.readCount ? Number(this.readCount) : 0;
    },
  }
}

</script>


<style lang="scss" module>
$white: #f9f9f9;
$grey: #c4c4c4;
$red: #f5dcdc;
$yellow: #f1f5dc;
$green: #def5dc;
$blue: #dcf2f5;
$purple: #e2dcf5;
$pink: #f5dced;

.article {
  position: relative;
  border: 1px solid $borderLiteGray;
  width: 343px;
  box-sizing: border-box;
  background: #fff;

  &.grey {
    border-color: $white;
  }
  &.grey {
    border-color: $grey;
  }
  &.red {
    border-color: $red;
  }
  &.yellow {
    border-color: $yellow;
  }
  &.green {
    border-color: $green;
  }
  &.blue {
    border-color: $blue;
  }
  &.purple {
    border-color: $purple;
  }
  &.pink {
    border-color: $pink;
  }

  &.clip_on {
    border: 2px solid $keyPink;
  }

  &.pin_on {
    border: 1px solid #F5DCDC;
  }

  &.read {
    opacity: 50%;
  }
}
.clip {
  position: absolute;
  top: -16px;
  left: 4px;
  opacity: 0;
}
.clip_on {
  opacity: 1;
  &_read{
    opacity: 50%;
  }
}

.pin {
  position: absolute;
  top: -12px;
  left: 2px;
  transform: rotate(-21deg);
  opacity: 0;
}
.pin_on {
  opacity: 1;
  &_read{
    opacity: 50%;
  }
}

.header_wrapper {
  padding: 12px 10px;

  .header_row1 {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    .title {
      flex: 1;
      margin: 0 10px 0 10px;
      font-size: 12px;
      font-weight: bold;
      line-height: 16px;
    }

    .menu {
      width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .read {
      opacity: 50%;
    }
  }

  .header_row2 {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .tag_time {
      display: flex;
      align-items: center;

      & > *:not(:last-child) {
        margin-right: 10px;
      }

      .time {
        display: inline-block;
        font-size: 10px;
        transform: scale(0.9);
        transform-origin: 0;
        width: 200px;
        margin-right: -50px;
      }
    }

    .counters {
      display: flex;
      font-size: 10px;
      & > *:not(:last-child) {
        margin-right: 5px;
      }

      .icon {
        margin-right: 1px;
        color: #acacac;
      }
      .count {
        display: inline-block;
        font-size: 10px;
        transform: scale(0.9);
        color: $fontBlack;
        &_active {
          color: $keyPink;;
        }
      }

      .browsed {
        &.on {
          .icon {
            color: $keyGreen;
          }
        }
      }
      .commented {
        &.on {
          .icon {
            color: $keyGreen;
          }
        }
      }
      .like {
        &.on {
          .icon {
            color: $keyPink;
          }
        }
      }
    }
    &.read {
      opacity: 50%;
    }
  }
}

.menuDefaultButton {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.body_wrapper {
  background-color: $white;
  padding: 20px 10px;

  .picture {
    text-align: end;
    padding: 0 16px 22px 16px;

    // & > *:not(:last-child) {
    //   margin-bottom: 10px;
    // }

    img {
      display: block;
      width: 100%;
    }

    a {
      color: $KeyDarkGreen;
      font-size: 11px;
    }
  }
  .body {
    padding: 0 6px;
    font-size: 10px;
    line-height: 15px;
    color: $fontBlack;
  }

  &.white {
    background-color: $white;
  }
  &.grey {
    background-color: $grey;
  }
  &.red {
    background-color: $red;
  }
  &.yellow {
    background-color: $yellow;
  }
  &.green {
    background-color: $green;
  }
  &.blue {
    background-color: $blue;
  }
  &.purple {
    background-color: $purple;
  }
  &.pink {
    background-color: $pink;
  }

  &.collapse {
    display: flex;
    box-sizing: border-box;
    padding: 10px;

    & > *:not(:last-child) {
      margin-right: 15px;
    }

    .picture {
      width: 50px;
      height: 50px;
      padding: 0;

      img {
        width: 50px;
        height: 50px;
      }
      a {
        display: none;
      }
    }
    .body {
      flex: 1;
      padding: 0;
      overflow: hidden;
      max-height: 45px;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .collpasing {
      width: 20px;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.file {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 4px;

  .file_download {
    width: 306px;
    border: 1px solid #707070;
    background-color: $keyWhite;
    padding: 11px 10px;
    border-radius: 3px;
    color: #000000;
    text-decoration: none;
    text-align: left;
    cursor: pointer;

    .file_icon {
      display: inline-block;
      padding-right: 6px;
      width: 20px;
      height: 25px;
      vertical-align: middle;
    }
  }

  input {
    display: none;
  }
}
.file_all {
  text-align: right;
  margin: 5px;
}

.file_all_download {
  cursor: pointer;
  color: $keyGreen;
  font-size: 12px;
}

.body_content {
  &_text {
    margin: 0;
  }
  &_link {
    color: $KeyDarkGreen;
    &:last-child {
      display: block;
      text-align: right;
    }
  }
  .choices_image_container {
    margin: 16px 0;
  }
  &_action {
    margin: 18px 0;
    text-align: center;
  }
}
</style>
