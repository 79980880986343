<template>
  <div :class="[$style.menu, { [$style[`margin_${this.margin}`]]: this.margin }]">
    <base-overlay-menu-list v-if="showEllipsis" icon-size="36px">
      <base-overlay-menu-list-item
        @click.native="favoriteClick"
        to=""
        v-if="showFavorite"
        >{{
          favoriteFlg ? 'お気に入りから削除する' : 'お気に入りに追加する'
        }}</base-overlay-menu-list-item
      >
      <base-overlay-menu-list-item
        :edit-chat-flg="editChatFlg"
        :type="type"
        @click.native="editChatClick"
        to=""
        v-if="editChatFlg"
        >{{
          type == 'post' ? '投稿' : 'ミニ投票'
        }}を編集する</base-overlay-menu-list-item
      >
      <base-overlay-menu-list-item
        @click.native="clipClick"
        to=""
        v-if="showPinFlg"
        >{{
          clip ? 'クリップを外す' : 'クリップする'
        }}</base-overlay-menu-list-item
      >
      <base-overlay-menu-list-item
        @click.native="shareClick"
        to=""
        v-if="showShare"
        >シェアする</base-overlay-menu-list-item
      >
      <base-overlay-menu-list-item
        @click.native="readClick"
        to=""
        v-if="showRead"
        >{{
          read ? '未読にする' : '既読にする'
        }}</base-overlay-menu-list-item
      >
      <base-overlay-menu-list-item
        @click.native="deleteChatClick"
        to=""
        v-if="deleteAuthFlg"
        >削除する</base-overlay-menu-list-item
      >
      <base-overlay-menu-list-item
        @click.native="commentNoticeClick"
        to=""
        v-if="['post', 'vote'].includes(type)"
        >コメント通知を{{ comment_notice_flg ? 'OFF': 'ON' }}にする</base-overlay-menu-list-item
      >
      <base-overlay-menu-list-item
        @click.native="mentionNoticeClick"
        to=""
        v-if="['post', 'vote'].includes(type)"
        >メンション通知を{{ mention_notice_flg ? 'OFF': 'ON' }}にする</base-overlay-menu-list-item
      >
      <base-overlay-menu-list-item
        @click.native="handleBrowse"
        to=""
        v-if="showBrowse"
        >この投稿を閲覧する</base-overlay-menu-list-item
      >
      <base-overlay-menu-list-item
        to=""
        @click.native="$emit('report')"
        v-if="showReport"
      >
        {{
          report_flg
            ? '※このユーザは通報済みです'
            : 'このユーザを通報する'
        }}
      </base-overlay-menu-list-item>
      <base-overlay-menu-list-item
        @click.native="$emit('block')"
        to=""
        v-if="showBlock"
        >{{
          blockUser
            ? 'このユーザのブロックを解除する'
            : 'ユーザをブロックする'
        }}</base-overlay-menu-list-item
      >
      <base-overlay-menu-list-item
        to=""
        @click.native="$emit('spam')"
        v-if="showSpam"
      >
        {{
          spamFlg
            ? '※この投稿は通報済みです'
            : 'この投稿を通報する'
        }}
      </base-overlay-menu-list-item>
      <!-- <base-overlay-menu-list-item
          @click.native="noticeEditClick"
          to=""
          v-if="false"
          >通知設定
      </base-overlay-menu-list-item> -->
    </base-overlay-menu-list>
  </div>
</template>
<script>
import BaseOverlayMenuList from '@/components/base/BaseOverlayMenuList/BaseOverlayMenuList'
import BaseOverlayMenuListItem from '@/components/base/BaseOverlayMenuList/BaseOverlayMenuListItem'
import {
  editTeamChatFavorite,
  deleteTeamChat,
  editTeamChatPin,
} from '@/helper/common.js'

export default {
  components: {
    BaseOverlayMenuListItem,
    BaseOverlayMenuList,
  },
  name: 'TeamChatDetailMenuList',
  data() {
    return {
      comment_notice_flg: this.commentNoticeFlg,
      mention_notice_flg: this.mentionNoticeFlg,
      spam_flg: this.spamFlg,
    }
  },
  props: {
    type: {
      type: String,
      validator: (type) =>
        !type ||
        [
          'post', // 投稿
          'vote', // ミニ投票
          'gift', // メッセージ
        ].includes(type),
      default: 'post',
    },
    showEllipsis: {
      type: Boolean,
      default: true,
    },
    showFavorite: {
      type: Boolean,
      default: true,
    },
    favoriteFlg: {
      type: Boolean,
      default: false,
    },
    editChatFlg: {
      type: Boolean,
      default: false,
    },
    showPinFlg: {
      type: Boolean,
      default: true,
    },
    deleteAuthFlg: {
      type: Boolean,
      default: false,
    },
    showSpam: {
      type: Boolean,
      default: true,
    },
    spamFlg: {
      type: Boolean,
      default: false,
    },
    showShare: {
      type: Boolean,
      default: true,
    },
    showRead: {
      type: Boolean,
      default: true,
    },
    showBlock: {
      type: Boolean,
      default: true,
    },
    showBrowse: {
      type: Boolean,
      default: false,
    },
    // showPost: {
    //   type: Boolean,
    //   default: true,
    // },
    showReport: {
      type: Boolean,
      default: true,
    },
    report_flg: {
      type: Boolean,
      default: false,
    },
    teamId: {
      type: Number,
    },
    chatId: {
      type: Number,
    },
    clip: {
      type: Boolean,
      default: false,
    },
    read: {
      type: Boolean,
      default: false,
    },
    blockUser: {
      type: Boolean,
      default: false,
    },
    readCount: {
      type: String,
      default: '',
    },
    margin: {
      type: Number,
      default: 0,
      validator: (margin) => [0, 36].includes(margin)
    },
    commentNoticeFlg: {
      type: Boolean,
      default: false,
    },
    mentionNoticeFlg: {
      type: Boolean,
      default: false,
    },
    isBeforeApproval: {
      type: Boolean,
      default: false,
    },
  },
  methods: {

    noticeEditClick() {
      // 通知設定の編集
      this.$router.push(`/team/post-notice-edit/${this.teamId}`);
    },
    handleBrowse() {
      this.$emit('browse')
      // this.$emit('browse', this.item)
    },
    favoriteClick: async function () {
      if (this.processing) return

      this.processing = true
      let targetFlg = this.favoriteFlg ? 0 : 1
      // 投稿内ユーザー登録・更新 [お気に入りから削除/お気に入りに追加]
      const { status, errors } = await editTeamChatFavorite({
        chat_id: this.chatId,
        favorite_flg: targetFlg,
      })
      this.processing = false
      if (status == 'success') {
        this.$emit('ref-data', {
          type: 'favorite',
          chat_id: this.chatId,
          data: { user_favorite_flg: targetFlg },
          errors,
        })
      }
    },
    editChatClick: function () {
      if (this.processing) return
      if (!this.editChatFlg) return

      if (this.type == 'post') {
        this.$router.push(`/team/post-create/${this.teamId}/${this.chatId}`)
      } else if (this.type == 'vote') {
        this.$router.push(
          `/team/mini-vote-create/${this.teamId}/${this.chatId}`
        )
      }
    },
    clipClick: async function () {
      if (this.processing) return

      this.processing = true
      let targetFlg = this.clip ? 0 : 1
      // 組織TOPでの投稿のピン止めAPI [クリップを外す/クリップする]
      const { status, errors } = await editTeamChatPin({
        chat_id: this.chatId,
        pin_flg: targetFlg,
      })
      this.processing = false
      this.$emit('ref-data', {
        type: 'clip',
        chat_id: this.chatId,
        data: status == 'success' ? targetFlg : targetFlg ^ 1,
        errors,
      })
    },
    shareClick: function () {
      if (this.processing) return

      this.processing = true

      const team_id = this.teamId ?? ''
      const elem = document.createElement('input')
      if (this.type == 'post') {
        // 投稿
        elem.value = `${process.env.VUE_APP_BASE_URL}/team/post-detail/${team_id}/${this.chatId}`
      } else {
        // ミニ投票
        elem.value = `${process.env.VUE_APP_BASE_URL}/team/mini-vote-detail/${team_id}/${this.chatId}`
      }
      document.body.appendChild(elem)
      elem.select()
      document.execCommand('copy')
      document.body.removeChild(elem)
      this.processing = false
      alert('URLをクリップボードにコピーしました')
    },
    readClick: async function () {
      if (this.processing) return

      this.processing = true
      // 投稿内ユーザー登録・更新 [既読可否]
      let targetFlg = this.read ? 0 : 1
      const { status, errors } = await editTeamChatFavorite({
        chat_id: this.chatId,
        read_flg: targetFlg,
      })
      let read_flg = null
      let read_count = null
      if (status == 'success') {
        read_flg = targetFlg
      } else {
        read_flg = targetFlg ^ 1
      }

      this.processing = false
      this.$emit('ref-data', {
        type: 'read',
        chat_id: this.chatId,
        data: { read_flg, read_count },
        errors,
      })
    },
    deleteChatClick: async function () {
      if (!confirm('投稿を削除しますか？')) return
      if (this.processing) return

      this.processing = true
      // 組織の投稿削除
      const { status, errors } = await deleteTeamChat({ chat_id: this.chatId })
      this.processing = false
      this.$emit('ref-data', {
        type: 'delete',
        chat_id: this.chatId,
        data: status == 'success' ? this.chatId : null,
        errors,
      })
    },
    async commentNoticeClick() {
      if (this.processing) return
      this.processing = true

      const { status, errors } = await editTeamChatFavorite({
        chat_id: this.chatId,
        comment_notice_flg: !this.comment_notice_flg,
      })

      if (status == 'success') {
        this.comment_notice_flg = !this.comment_notice_flg
      } else {
        console.error('エラーが発生しました:', errors);
        errors;
      }

      this.processing = false
    },
    async mentionNoticeClick() {
      if (this.processing) return
      this.processing = true

      const { status, errors } = await editTeamChatFavorite({
        chat_id: this.chatId,
        mention_notice_flg: !this.mention_notice_flg,
      })

      if (status == 'success') {
        this.mention_notice_flg = !this.mention_notice_flg
      } else {
        errors
      }

      this.processing = false
    },
  },
  watch: {
    commentNoticeFlg(newVal) {
      this.comment_notice_flg = newVal
    },
    mentionNoticeFlg(newVal) {
      this.mention_notice_flg = newVal
    },
    spamFlg(newVal) {
      this.spam_flg = newVal
    },
  },
}
</script>
<style lang="scss" module>
.menu {
  width: 36px;
  height: 36px;
  display: inline-block;
  align-items: right;
  justify-content: right;

}

.margin_36 {
  margin-top: 36px;
  margin-right: 36px;
}

</style>
