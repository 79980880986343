<template>
  <div :class="classObject" :style="styleObject">
    <img :class="$style.image" :src="showImage" />
  </div>
</template>
<script>
import noImage from '@/assets/images/account-circle.svg'
export default {
  name: 'BaseAvatar',
  props: {
    size: {
      type: String,
      default: '120px',
    },
    path: {
      type: String,
      default: '',
    },
    bordered: {
      type: Boolean,
      default: false,
    },
    borderColor: {
      type: String,
      default: undefined,
    },
    outlineColor: {
      type: String,
      default: undefined,
    },
  },
  methods: {},
  computed: {
    classObject() {
      return [this.$style.base_avatar]
    },
    styleObject() {
      const size = {
        width: this.size,
        height: this.size,
      }
      const border = this.borderColor
        ? { border: `2px solid ${this.borderColor}` }
        : null
      const outline = this.outlineColor
        ? { outline: `2px solid ${this.outlineColor}`, 'outline-offset': `2px` }
        : null
      return {
        backgroundImage: `url(${this.showImage})`,
        ...size,
        ...border,
        ...outline,
      }
    },
    showImage() {
      return this.path || noImage
    },
  },
}
</script>
<style lang="scss" module>
.base_avatar {
  border-radius: 50%;
  // box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  background: center center / cover no-repeat;
  overflow: hidden;
  box-sizing: border-box;
}

.image {
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0;
}
</style>
