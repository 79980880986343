<template>
  <div class="$style.open_layout">
    <app-information-header :has-login="hasLogin"/>
    <div :class="$style.open_content">
      <div :class="$style.open_content_body">
        <router-view />
      </div>
      <app-footer/>
    </div>
  </div>
</template>

<script>
import AppInformationHeader from '@/components/app/AppInformationHeader/AppInformationHeader'
import AppFooter from '@/components/app/AppFooter/AppFooter'
export default {
  name: 'OpenLayout',
  components: {AppInformationHeader, AppFooter},
  beforeMount() {
    this.$router.app.$on('page-updated',
      () => {})
  },
  data: function () {
    return {}
  },
  computed: {
    hasLogin: function(){
      return ! this.$cookies.get('auth_info')
    },
  },
  methods: {},
}
</script>

<style lang="scss" module>
.open_content {

  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 90px);
  background: #FFFBE4;
}
.open_content_body {
  display: flex;
  flex: 1;
}
</style>
