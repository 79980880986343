<template>
  <div class="$style.home_layout">
    <app-header
      :page-title="pageTitle"
      :back="back"
      :has-setting="hasSetting"
      :show-setting="showSetting"
      :bg-color="headerColor"
      :show-tabbar="showTabbar"
      @show-setting="(show) => showSetting = show"
    >
      <template v-slot:setting-menu>
        <base-overlay-menu-list-item
          v-for="settingItem of settingItems"
          :key="settingItem.label"
          v-show="!settingItem.hidden"
          :to="settingItem.to || ''"
        >
          <span
            :class="[$style.setting_item, {[$style.disabled]: settingItem.disabled}]"
            @click="() => { if (!settingItem.disabled) { settingItem.on && settingItem.on(); showSetting = !showSetting } }"
          >
            {{ settingItem.label }}
          </span>
        </base-overlay-menu-list-item>
      </template>
    </app-header>
    <div :class="[$style.home_content, { [$style.hide_tabbar]: !showTabbar }]">
      <div :class="$style.home_content_body">
          <router-view v-show="!showSpinner" />
          <div :class="$style.loading" v-if="showSpinner">
            <base-icon name="spinner" size="32px" spin />
          </div>
      </div>
      <div :class="$style.footer">
        <app-footer v-if="showFooter" bgColor="yellow"/>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from '@/components/app/AppHeader/AppHeader'
import AppFooter from '@/components/app/AppFooter/AppFooter'
import BaseOverlayMenuListItem from "@/components/base/BaseOverlayMenuList/BaseOverlayMenuListItem";
import BaseIcon from '@/components/base/BaseIcon/BaseIcon'

export default {
  name: 'HomeLayout',
  components: { AppHeader, AppFooter, BaseOverlayMenuListItem, BaseIcon },
  beforeMount() {

    this.$router.app.$on('page-updated', ({
                                            pageTitle,
                                            back,
                                            showSetting = true,
                                            headerColor = 'white',
                                            bgColor = 'yellow',
                                            showTabbar = true,
                                            showFooter = true,
                                            settingItems = []
                                          }) => {
      this.pageTitle = pageTitle
      this.back = back
      this.hasSetting = showSetting
      this.headerColor = headerColor
      this.bgColor = bgColor
      this.showTabbar = showTabbar
      this.showFooter = showFooter
      this.settingItems = settingItems
    })
    this.$router.app.$on('show-spinner', (showFlg) => {
      this.showSpinner = showFlg
    })
  },
  created() {},
  data() {
    return {
      pageTitle: '',
      hasSetting: true,
      showSetting: false,
      back: '',
      headerColor: 'white',
      bgColor: 'yellow',
      showTabbar: true,
      showFooter: true,
      settingItems: [],
      showSpinner: false,
    }
  },
}
</script>

<style lang="scss" module>

.loading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home_content {

  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 70px);
  background: $keyWhite;
  @include mobile() {
    min-height: calc(100vh - 70px - 50px);
    padding-bottom: 70px;
    &.hide_tabbar {
      min-height: calc(100vh - 50px);
      padding-bottom: 0px;
    }
  }
}
.home_content_body {
  display: flex;
  justify-content: center;
  flex: 1;
}
.setting_item {
  &.disabled {
    color: #ddd;
  }
}
.footer {
  @include mobile() {
    display: none;
  }
}
</style>
