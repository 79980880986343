<template>
  <section :class="[$style.expand, { [$style.expand_open]: isExpanded }]">
    <div v-show="!isExpanded"><slot name="negative-show"></slot></div>
    <div
      v-if="type === 'qa'"
      :class="[$style.expand_title, $style.qa]"
      @click="toggleExpand"
    >
      <base-icon name="question" size="22px" />
      <h1 :class="[$style.expand_title_text, $style.qa]">{{ title }}</h1>
      <base-icon
        :class="[$style.expand_arrow, $style.qa]"
        name="chevron-right"
        size="24px"
        color="gray"
      />
    </div>

    <div
      v-else-if="type === 'normal'"
      :class="[$style.expand_title, $style.normal]"
      @click="toggleExpand"
    >
      <base-icon
        v-if="!isExpanded"
        type="far"
        name="plus-square"
        size="16px"
        color="green"
      />
      <base-icon
        v-else-if="!!isExpanded"
        type="far"
        name="minus-square"
        :size="size"
        color="green"
      />
      <h1 :class="[$style.expand_title_text, $style.normal]">{{ title }}</h1>
    </div>

    <div
      v-else-if="type === 'borderless'"
      :class="[$style.expand_title, $style.borderless]"
      @click="toggleExpand"
    >
      <h1 :class="[$style.expand_title_text, $style.borderless]">
        {{ title }}
      </h1>
      <p :class="$style.expand_text" v-if="showExpandText">もっとみる</p>
      <base-icon
        :class="$style.expand_arrow"
        name="chevron-up"
        size="12px"
        color="green"
      />
    </div>

    <div><slot name="always-show"></slot></div>
    <div
      :class="[$style.expand_content, { [$style.normal]: type === 'normal' }]"
    >
      <slot></slot>
    </div>
  </section>
</template>
<script>
import BaseIcon from '../BaseIcon/BaseIcon'
export default {
  name: 'BaseAccordion',
  components: {
    BaseIcon,
  },
  props: {
    type: {
      type: String,
      default: 'normal',
      validator: (value) => ['normal', 'qa', 'borderless'].includes(value),
    },
    title: {
      type: String,
      required: true,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    showExpandText: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      isExpanded: this.$props.expanded,
      windowWidth: 0,
    }
  },
  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded

      var content_element = this.$el.lastElementChild
      var content_height = content_element.scrollHeight
      if (!this.isExpanded) {
        content_element.style.maxHeight = content_height + 'px'
        content_height = 0
        setTimeout(
          function () {
            content_element.style.maxHeight = content_height + 'px'
          }.bind(this),
          50
        )
      } else {
        content_element.style.maxHeight = content_height + 'px'
        setTimeout(
          function () {
            content_element.style.maxHeight = ''
          }.bind(this),
          50
        )
      }

      this.$emit('expand', this.isExpanded)
    },
    resizeWindow() {
      this.windowWidth = window.innerWidth
    },
  },
  mounted() {
    window.addEventListener('resize', this.resizeWindow)
    this.isExpanded = !this.isExpanded
    this.toggleExpand()
  },
  watch: {
    expanded(newValue /*, oldValue*/) {
      this.isExpanded = newValue
    },
  },
  computed: {
    size() {
      const ipadWidth = 728
      return this.windowWidth > ipadWidth ? '24px' : '16px'
    },
  },
}
</script>
<style lang="scss" module>
.expand {
  // background: $keyWhite;
}
.expand_title {
  display: flex;
  align-items: center;
  cursor: pointer;

  &.qa {
    align-items: start;
    background: $keyWhite;
    border: 1px solid #b9b9b9;
    border-collapse: collapse;
    color: $fontBlack;
    padding: 17px 12px 13px 8px;
  }

  &.normal {
    background: $keyWhite;
    // border-bottom: 1px solid #b9b9b9;
    color: $fontBlack;
    padding: 17px 0 17px 10px;
    box-sizing: border-box;
    .expand:first-of-type & {
      border-top: 1px solid #b9b9b9;
    }
    // .expand_open & {
    //   border-bottom: none;
    // }
  }

  &.borderless {
    display: inline-flex;
    color: $fontBlack;
  }
}

.expand_title_text {
  flex: 1;
  margin: 0;
  &.qa {
    padding-left: 10px;
  }
  &.normal {
    @media screen and (min-width: 768px) {
      font-size: 24px;
    }
    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
    line-height: 20px;
    padding-left: 6px;
  }
  &.borderless {
    font-size: 22px;
    font-weight: lighter;
    padding-right: 25px;
  }
}

.expand_arrow {
  transition: 0.3s;
}
.expand_open .expand_arrow {
  transform: rotate(180deg);
  &.qa {
    transform: rotate(90deg);
  }
}
.expand_content {
  // max-height: 0;
  overflow: hidden;
  transition: 0.3s;
  &.normal {
    // .expand_open & {
    //   border-bottom: 1px solid #b9b9b9;
    // }
    // border-top: none;
    border-bottom: 1px solid #b9b9b9;
  }
}
.expand_open .expand_content {
  // padding-top: 16px;
  // max-height: 4800px;
  overflow: visible;
}
.expand_text {
  font-size: 10px;
  color: $keyGreen;
  margin-right: 5px;
}
</style>
