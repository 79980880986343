<template>
  <section :class="$style.union_gift">
    <app-prev-button @click="$router.push({ name: 'TeamHome', params: {team_id}})" />

    <div :class="$style.list_table_wrap">
      <div :class="$style.list_table_header">
        <h1 :class="$style.list_table_header_title">メッセージ送信設定</h1>
        <base-button
          bg-color="black"
          width="250px"
          @click="redirectToGiftSetting(null)"
        >新規作成</base-button>
      </div>
      <div :class="$style.list_table">
        <table-list
          :col-defs="colDefs"
          :row-data="rowData"
          :row-key="rowKey"
          :selected="tableSelected"
          :page="page"
          :page-data-num="pageDataNum"
          @select="handleSelect"
          :bg-color="rowDataEmpty ? 'gray' : 'white'"
          v-on="$listeners"
          @cell-click="cellClicked"
        >
          <!-- style要検討 -->
          <template v-slot:status="{ status }">
            <span v-if="status=='設定中'" :class="$style.status_setting">{{ status }}</span>
            <span v-else>{{ status }}</span>
          </template>
          <template v-slot:result_download_flg="{ result_download_flg }">
            <!-- <img src="@/assets/images/file_download.svg" alt="DL" @click="fileDownload(download)"> -->
            <img v-show="result_download_flg" src="@/assets/images/file_download.svg" alt="DL">
          </template>
        </table-list>
        <template v-if="showMessage">
          <base-message type="success"
            v-for="(item, i) in msgList" :key="i" :class="$style.msg_area">{{item.message}}</base-message>
        </template>
      </div>
      <div :class="$style.list_table_footer">
        <base-button width="90px" rounded="rounded" @click="copyGift">複製</base-button>
        <base-button width="90px" rounded="rounded" @click="deleteGift">削除</base-button>
      </div>
      <div v-if="!rowDataEmpty" :class="$style.page_nav_wrapper">
        <page-nav
          :class="$style.page_nav"
          :page="page"
          :pageMax="pageMax"
          @page="handlePage"
          v-on="$listeners" />
      </div>
    </div>

    <div :class="$style.able_gift_table_wrap">
      <h1 :class="$style.able_gift_table_header_title">配布可能なQUOカードPayの確認</h1>
      <div :class="$style.able_gift_table">
        <table>
          <tr>
            <th :class="$style.value_code">バリューコード名称</th>
            <th>発行額</th>
            <th>メッセージ上限額</th>
            <th>発行累計額</th>
            <th></th>
          </tr>

          <tr v-for="gift in giftListViewModel" v-bind:key="gift.gift_id">
            <td :class="$style.value_code">{{ gift.value_code_name }}</td>
            <td>{{ gift.amount }}</td>
            <td>{{ gift.limit_amount }}</td>
            <td>{{ gift.used_amount }}</td>
            <td>
              <base-button
                width="114px"
                height="24px"
                rounded="rounded"
                @click="$router.push(`/union/gift_detail/${team_id}/${gift.gift_id}`)"
              >詳細設定</base-button>
            </td>
          </tr>
        </table>
      </div>
    </div>

  </section>
</template>

<script>
import { mapActions } from 'vuex'
import BaseButton from '@/components/base/BaseButton/BaseButton'
import BaseMessage from '@/components/base/BaseMessage/BaseMessage'
import TableList from '@/components/app/AppTableList/TableList'
import PageNav from '@/components/app/AppTableList/PageNav'
import AppPrevButton from '@/components/app/AppPrevButton/AppPrevButton'
import { fetchGiftList, fetchGiftSettingList, downloadGiftResult, copyGiftSetting, deleteGiftSetting } from '@/helper/common.js'

export default {
  name: 'UnionGift',
  components: { BaseButton, BaseMessage, TableList, PageNav, AppPrevButton },
  props: {
    /** 組織ID */
    team_id: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    this.commitGiftTargetId({gift_target_id: null})
    this.$router.app.$emit('page-updated', {
      pageTitle: 'メッセージ送信',
      back: `/team/home/${this.team_id}`,
      headerColor: 'white',
      showSetting: false,
      bgColor: 'gray'
    })
  },
  created: async function() {
    this.$router.app.$emit('show-spinner', true)
    await Promise.all([
      this.getGiftSettingList(),
      this.getGiftList(),
    ])
    this.$router.app.$emit('show-spinner', false)
  },
  data() {
    return {
      /** メッセージ送信設定一覧の項目 */
      colDefs: [
        {
          key: "title",
          label: "配布設定名",
          clickable: true,
        },
        {
          key: "status",
          label: "ステータス",
          slot: "status",
          clickable: true,
        },
        {
          key: "service_code",
          label: "サービスコード",
          clickable: true,
        },
        {
          key: "send_count",
          label: "対象者数",
          clickable: true,
          textAlign: "center",
        },
        {
          key: "created_user",
          label: "担当者",
          clickable: true,
        },
        {
          key: "result_download_flg",
          label: "QUOカードPay発行実績",
          slot: "result_download_flg",
          clickable: true,
          textAlign: "center",
        }
      ],
      page: 1,
      /** 一覧の選択中アイテムの配列 */
      tableSelected: [],
      pageDataNum: 10,
      /** 一覧の選択中アイテムが返却する項目名（メッセージ送信設定ID） */
      rowKey: "gift_setting_id",
      /** メッセージ送信設定リスト */
      giftSettingList: [],
      /** メッセージリスト */
      giftList: [],
      /** 処理実行中フラグ */
      processing: false,
      /** 削除APIのメッセージ */
      msgList: [],
    }
  },
  computed: {
    pageMax() {
      return this.pageDataNum > 0 ? Math.ceil(this.rowData.length / this.pageDataNum) : 1
    },
    rowDataEmpty() {
      return this.rowData.length === 0
    },
    /** メッセージ送信設定一覧の表示データ */
    rowData() {
      if (this.giftSettingList.length > 0) {
        return this.giftSettingList.map(setting => {
          return {
            title: setting.title,
            status: setting.status,
            service_code: setting.service_code,
            send_count: setting.send_count,
            created_user: setting.created_user,
            result_download_flg: setting.result_download_flg,
            gift_setting_id: setting.gift_setting_id // 非表示項目
          }
        })
      } else {
        return []
      }
    },
    giftListViewModel() {
      return this.giftList.map(gift => {
        return {
          gift_id: gift.gift_id,
          value_code_name: gift.value_code_name,
          amount: this.formatNumber(gift.amount),
          limit_amount: this.formatNumber(gift.limit_amount),
          used_amount: this.formatNumber(gift.used_amount),
        }
      })
    },
    showMessage() {
      return (this.msgList && this.msgList.length > 0)
    },
  },
  methods: {
    ...mapActions('union', ['commitGiftSettingId', 'resetGiftSettngInfo', 'commitGiftTargetId']),

    /** チェックボックスの選択中項目を設定 */
    handleSelect(selected) {
      this.tableSelected = selected;
    },
    /** 表示中のページを設定 */
    handlePage(page) {
      this.page = page
    },
    /** セル（行）クリック時処理 */
    cellClicked: async function(e) {
      this.$loginfo(e)
      // セルによって分岐
      if (e.colDef.key == "result_download_flg" && e.rowDat.result_download_flg == true) {
        await this.fileDownload(e)
      } else {
        this.redirectToGiftSetting(e)
      }
    },
    /** 遷移処理 */
    redirectToGiftSetting(e) {
      if (this.processing) return

      this.$loginfo(e)
      if (e) {
        // 編集時
        this.commitGiftSettingId({gift_setting_id: e.rowDat.gift_setting_id})
      } else {
        // 新規作成時
        this.commitGiftSettingId({gift_setting_id: null})
      }

      this.resetGiftSettngInfo()
      this.$router.push(`/union/gift_setting/${this.team_id}`)
    },
    /** ファイルダウンロード */
    fileDownload: async function(e) {
      if (this.processing) return

      this.processing = true
      try {
        const result = await downloadGiftResult({ gift_setting_id: e.rowDat.gift_setting_id })
        if (result.status == 'success') {
          this.$loginfo(result)
          // ダウンロード
          let link = document.createElement('a')
          link.download = result.value.file_name
          link.href = result.value.file_url
          link.click()
        }
      } finally {
        this.processing = false
      }
    },
    getGiftSettingList: async function () {
      const result = await fetchGiftSettingList({ team_id: this.team_id })
      if (result.status == 'success') {
        if (result.value.length > 0) {
          this.giftSettingList = result.value
        // } else {
        //   this.giftSettingList = this.createSettingListMock
        }
      // } else {
      //   this.giftSettingList = this.createSettingListMock
      }
    },
    getGiftList: async function() {
      const result = await fetchGiftList({ team_id: this.team_id })
      if (result.status == 'success') {
        if (result.value.length > 0) {
          this.giftList = result.value
        // } else {
        //   this.giftList = this.createGiftListMock
        }
      // } else {
      //   this.giftList = this.createGiftListMock
      }
    },
    copyGift: async function() {
      const gift_setting_ids = this.tableSelected
      if (gift_setting_ids.length == 0 || this.processing) return

      this.processing = true
      try {
        const result = await copyGiftSetting({ gift_setting_id_list: gift_setting_ids })
        if (result.status == 'success') {
          // 選択の初期化
          this.tableSelected.splice(0)
          await this.getGiftSettingList()
          return
        } else {
          return
        }
      } finally {
        this.processing = false
      }
    },
    deleteGift: async function() {
      const gift_setting_ids = this.tableSelected
      if (gift_setting_ids.length == 0 || this.processing) return

      this.processing = true
      this.msgList.splice(0)
      try {
        const result = await deleteGiftSetting({ gift_setting_id_list: gift_setting_ids })
        if (result.status == 'success') {
          if (result.value && result.value.length > 0) {
            this.msgList.splice(0, result.value.length, ...result.value)
          }
          await this.getGiftSettingList()
          this.page = 1
          return
        } else {
          return
        }
      } finally {
        this.processing = false
      }
    },
    /** 数値を「,」区切りにする */
    formatNumber: function (value) {
      if (value == null) return ''
      const num = Number(value)
      return num.toLocaleString()
    },
  }
}
</script>

<style lang="scss" module>
.union_gift {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 12px 0 50px 0;
  width: 960px;
}

.list_table_wrap {
  width: 100%;
  margin-bottom: 72px;
  .list_table_header {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 26px;

    &_title {
      font-size: 30px;
      margin: 0 auto 0 0;
    }
  }

  .list_table {
    margin-bottom: 16px;
    table {
      width: 100%;
    }
    .status_setting {
      color: #E94C89;
    }
  }
  .list_table_footer {
    display: flex;
    margin-bottom: 10px;
    & > *:first-child {
      margin-right: 12px;
    }
  }

  .page_nav_wrapper {
    text-align: center;
  }
}

.able_gift_table_wrap {
  width: 960px;
  padding: 24px 34px 30px 34px;
  border: 2px solid $keyBlack;
  box-sizing: border-box;
  background-color: #FFFBE4;

  .able_gift_table_header_title {
    font-size: 30px;
    margin: 0 0 32px 0;
  }

  .able_gift_table {
    padding: 0 14px;
    table {
      width: 100%;
      border-spacing: 0 10px;
    }
    th {
      padding-bottom: 12px;
      font-size: 11px;
      font-weight: bold;
      color: $fontBlack;
      border-bottom: 2px solid #707070;
      &.value_code {
        width: 50%;
        padding-left: 32px;
        text-align: left;
      }
    }
    td {
      height: 44px;
      font-size: 13px;
      background-color: $keyWhite;
      text-align: center;
      &.value_code {
        width: 50%;
        padding-left: 32px;
        text-align: left;
        font-weight: bold;
      }
    }
  }
}
.msg_area {
  width: 100% !important;
  margin: 0 0 10px;
}
</style>
