<template>
  <div :class='$style.profile_edit'>

    <h1 :class='$style.title'>プロフィール登録</h1>

    <div :class='$style.profile_file_wrap'>
      <base-file-input
      :name="'profile-file'"
      type='avatar'
      :display="'inline-flex'"
      :base64data="inputProfileImage"
      @change="inputProfileImage = $event[0]"/>
    </div>
    <span :class='$style.ju_id'>JU-ID: {{$store.state.auth.juid}}</span>

    <div :class='$style.input_group'>

      <base-input
        name="name"
        look="border"
        v-model="inputName"
        maxLength="255"
        :error="v$.inputName.$invalid && v$.inputName.$dirty ? v$.inputName.$errors[0].$message : ''"
      >
        <template v-slot:prepend>
          <span :class='$style.input_prepend_name'>お名前</span>
        </template>
      </base-input>

      <base-input
        name="nickname"
        look="border"
        v-model="inputNickname"
        maxLength="255"
        :error="v$.inputNickname.$invalid && v$.inputNickname.$dirty ? v$.inputNickname.$errors[0].$message : ''"
      >
        <template v-slot:prepend>
          <span :class='$style.input_prepend_nickname'>ニックネーム</span>
        </template>
      </base-input>

      <base-input
        name="profile"
        look="border"
        v-model="inputProfile"
        maxLength="1000"
        :error="v$.inputProfile.$invalid && v$.inputProfile.$dirty ? v$.inputProfile.$errors[0].$message : ''"
      >
        <template v-slot:prepend>
          <span :class='$style.input_prepend_profile'>プロフィール</span>
        </template>
      </base-input>


      <base-input
        name="contact"
        look="border"
        v-model="inputEmail"
        maxLength="255"
        :error="v$.inputEmail.$invalid && v$.inputEmail.$dirty ? v$.inputEmail.$errors[0].$message : ''"
        :disabled="! editableEmail"
      >
        <template v-slot:prepend>
          <span :class='$style.input_prepend_contact'>メールアドレス</span>
        </template>
      </base-input>

      <base-date-picker
        name="birthday"
        look='border'
        prepend='生年月日'
        v-model="inputBirthday"
        type="date"
        min="1901-01-01T00:00"
        max="9999-12-31T23:59"
        :error="v$.inputBirthday.$invalid && v$.inputBirthday.$dirty ? v$.inputBirthday.$errors[0].$message : ''"
      >
        <template v-slot:prepend>
          <span :class='$style.input_prepend_birthday'>生年月日</span>
        </template>
      </base-date-picker>

    </div>

    <div :class='$style.next_button_wrap'>
      <base-button display='inline-block' width='100%' height='50px' v-on:click="next">
        次へ
      </base-button>
    </div>
  </div>

</template>

<script>
import BaseInput from '@/components/base/BaseInput/BaseInput'
import BaseDatePicker from '@/components/base/BaseDatePicker/BaseDatePicker'
import BaseButton from '@/components/base/BaseButton/BaseButton'
import BaseFileInput from '@/components/base/BaseFileInput/BaseFileInput'
import useVuelidate from '@vuelidate/core'
import { required, email, helpers } from '@vuelidate/validators'

export default {
  name: 'ProfileEdit',
  components: {
    BaseInput,
    BaseDatePicker,
    BaseButton,
    BaseFileInput
  },
  data: function() {
    return {
      inputProfileImage: this.$store.state.profile_edit.profile_image,
      inputName: this.$store.state.profile_edit.name,
      inputNickname: this.$store.state.profile_edit.nickname,
      inputProfile: this.$store.state.profile_edit.profile,
      inputEmail: this.$store.state.profile_edit.email,
      inputBirthday: this.$store.state.profile_edit.birthday || '1980-01-01',
      editableEmail: true,
    }
  },
  setup: function() {
    return { v$: useVuelidate() }
  },
  validations: function() {
    return {
      inputName: {
        required: helpers.withMessage('名前の入力は必須です。', required),
        $autoDirty: true
      },
      inputNickname: {
        required: helpers.withMessage('ニックネームの入力は必須です。', required),
        $autoDirty: true
      },
      inputProfile: {
        // required: helpers.withMessage('プロフィールの入力は必須です。', required),
        $autoDirty: true
      },
      inputEmail: {
        // required: helpers.withMessage('メールアドレスの入力は必須です。', required),
        email: helpers.withMessage('メールアドレスの形式が違います。', email),
        $autoDirty: true
      },
      inputBirthday: {
        required: helpers.withMessage('生年月日の入力は必須です。', required),
        $autoDirty: true
      }
    }
  },
  created: function(){
    // 生年月日の初期値を登録
    this.inputBirthday = this.$dayjs(this.inputBirthday).format('YYYY-MM-DD')

    // 認証にEmailを使っている場合は、そのEmailをセットしたうえ編集できなくする
    if (! useVuelidate(email, this.$store.state.auth.tel_or_email).value.$invalid) {
      this.inputEmail = this.$store.state.auth.tel_or_email
      this.editableEmail = false
    }
  },
  methods: {
    next: async function(){
      const result = await this.v$.$validate()

      if(! result){
        return
      }

      //データの保存
      this.$store.commit('profile_edit/setProfileImage', this.inputProfileImage)
      this.$store.commit('profile_edit/setName', this.inputName)
      this.$store.commit('profile_edit/setNickname', this.inputNickname)
      this.$store.commit('profile_edit/setProfile', this.inputProfile)
      this.$store.commit('profile_edit/setEmail', this.inputEmail)
      this.$store.commit('profile_edit/setBirthday', this.inputBirthday)

      //ページ遷移
      this.$router.push('/open/profile_confirm')
    }
  }
}
</script>
<style lang='scss' module>
.profile_edit {
  display: flex;
  flex-direction: column;
  width: 375px;
  margin: 0 auto;
  padding: 42px 32px 30px 32px;
  box-sizing: border-box;
  text-align: left;
}

.title {
  margin-top: 0;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 50px;
}

.profile_file_wrap {
  display: flex;
  justify-content: center;
}

.ju_id {
  font-size: 13px;
  color: $fontGray;
  text-align: center;
  margin-bottom: 30px;
}

.input_group {
  & > * {
    &:nth-child(n) {
      margin-bottom: 30px;
    }
  }
  margin-bottom: 30px;
}

.input_prepend {
  &_name,
  &_nickname,
  &_profile,
  &_contact,
  &_birthday,
  &_password {
    font-size: 11px;
  }

  &_name {
    margin-right: 52px;
  }

  &_nickname, &_profile{
    margin-right: 20px;
  }

  &_contact {
    margin-right: 9px;
  }

  &_birthday {
    margin-right: 42px;
  }

  &_password {
    margin-right: 30px;
  }

}

.next_button_wrap {
  margin-top: auto;
}
</style>
