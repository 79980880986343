<template>
  <div :class='$style.verification_confirm'>
    <div v-if="!processing">
      <h1 :class='$style.title'>確認コード入力</h1>
      <p :class='$style.note'>くみあいアプリより通知された確認コードを<br>24時間以内に入力してください</p>

      <div :class="$style.input_group">
        <base-input
          name="code"
          type="password"
          look="border"
          v-model="confirm_code"
          :error="v$.confirm_code.$invalid && v$.confirm_code.$dirty ? v$.confirm_code.$errors[0].$message : error_text.length ? error_text : ''"
          maxlength="6"
        >
          <template v-slot:prepend>
            <span :class='$style.input_prepend_code'>確認コード</span>
          </template>
        </base-input>

      </div>

      <div :class="$style.next_button_wrap">
        <base-button
          display="inline-block"
          width="100%"
          height="50px"
          @click="confirmCode"
          :disabled="processing || nextDisable"
        >
          次へ
        </base-button>
      </div>
    </div>

    <div :class="$style.spinner" v-if="processing">
      <base-icon name="spinner" size="32px" spin />
    </div>

  </div>
</template>

<script>
import BaseInput from '@/components/base/BaseInput/BaseInput'
import BaseButton from '@/components/base/BaseButton/BaseButton'
import BaseIcon from '@/components/base/BaseIcon/BaseIcon'
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'

export default {
  name: 'VerificationConfirm',
  components: { BaseInput, BaseButton, BaseIcon },
  data() {
    return {
      auth_data: '',
      tel_or_email: this.$store.state.registration.tel_or_email,
      password: this.$store.state.registration.password,
      confirm_code: '',
      error_text: '',
      processing: false,
      nextDisable: false,
    }
  },
  setup: function() {
    return { v$: useVuelidate() }
  },
  created: async function() {

    if (this.$route.query.token) {
      this.processing = true
      this.$axios.get('/api/' + this.$constants.API_VERSION + '/auth/temporary',{
          params: {
            token: this.$route.query.token,
          }
      })
      .then((response) => {
        this.auth_data = decodeURIComponent(atob(response.data.value.auth_data))
        if (0 < this.auth_data.length) {
          this.auth_data = JSON.parse(this.auth_data)
          this.tel_or_email = this.auth_data['tel_or_email']
          this.password = this.auth_data['password']
          if (this.auth_data['invite_token']) {
            this.$store.commit('invite/setToken', this.auth_data['invite_token'])
          }
        } else {
          // 確認コード発行24時間後に認証情報取得できなくなるため、エラーメッセージを表示して、確認コード送信できなくする
          this.error_text = '確認コードの有効期間が過ぎました。お手数ですが再度くみあいアプリへの会員登録を行って下さい。'
          this.nextDisable = true
        }
      }).finally(() => {
        this.processing = false
      });
    }
    return
  },
  validations: function() {
    return {
      confirm_code: {
        required: helpers.withMessage('確認コードは、必ず指定してください。', required),
        $autoDirty: true
      },
    }
  },
  methods: {
    async confirmCode() {
      await this.v$.$validate()
      if(this.v$.$invalid){
        return
      }

      this.error_text = ''

      if(this.processing){
        return
      }

      let self = this;
      this.processing = true
      this.error_text = ''

      //認証基盤 確認コード登録APIコール
      this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/auth/confirm/code', {
          tel_or_email: this.tel_or_email,
          confirm_code: this.confirm_code,
        })
        .then(() => {
          // uni-verseログインAPIコール
          this.$axios
            .post('/api/' + this.$constants.API_VERSION + '/login', {
              tel_or_email: this.tel_or_email,
              password: this.password,
              device_info: navigator.userAgent,
            })
            .then((response) => {
              // ログインレスポンス保存
              this.$store.commit('auth/setTelOrEmail',this.tel_or_email)
              this.$store.commit('auth/setJuid', response.data.value.juid)

              this.$cookies.set('auth_info', {
                'tel_or_email': this.tel_or_email,
                'access_token': response.data.value.access_token,
                'expired_at': this.$dayjs().add(response.data.value.expires_in, 's').format(),
                'refresh_token': response.data.value.refresh_token
              })

              // パスワードが設定されているユーザー情報クリア
              this.$store.commit('registration/clearAll')
              // プロフィール編集画面へ遷移
              this.$router.push('/open/profile_edit')
            }).finally(() => {
              this.processing = false
            });
        }).catch(err => {
          if(err.response.status === 400 || err.response.status === 401){
            err.response.data.value.errors.forEach(message => {
              if (message.message == '確認コードが一致しません。') {
                self.error_text = '確認コードが間違っています\n通知された確認コードをご入力ください'
              } else {
                self.error_text = message.message
              }
            })
          }
          this.processing = false
        })
    },
  },
}
</script>
<style lang='scss' module>
.verification_confirm {
  display: flex;
  flex-direction: column;
  width: 375px;
  margin: 0 auto;
  padding: 42px 32px 30px 32px;
  box-sizing: border-box;
  text-align: left;
}

.title {
  margin-top: 0;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 50px;
}

.note {
  font-size: 13px;
  color: $fontBlack;
  margin-top: 0;
  margin-bottom: 60px;
}

.input_group {
  margin-bottom: 30px;
}

.input_prepend_code {
  font-size: 11px;
  color: $fontBlack !important;
  margin-right: 0px;
  cursor: default;
}

.next_button_wrap {
  margin-top: auto;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}
</style>
