// Singleton
// Simple Mobile Width Calculation

// TODO:: もしかしたらenvで管理した方が便利かも
const MOBILE_WIDTH = 1080;
const MOBILE_MIN_WIDTH = 375;

export default () => {
  // let timer = null
  let width = 0

  const calcWidth = () => {
    width = window.innerWidth
  }


  return {
    destroyed() {
      window.removeEventListener('resize', this.__handleResizeEvent)
    },
    mounted() {
      window.addEventListener('resize', this.__handleResizeEvent)
      calcWidth()
      this.isMobile = MOBILE_WIDTH >= width
      this.isMobileMin = MOBILE_MIN_WIDTH >= width
    },
    data() {
      return {
        isMobile: false,
        isMobileMin: false,
      }
    },
    methods: {
      __handleResizeEvent() {
        calcWidth()
        // if (timer) clearTimeout(timer)
        // timer = setTimeout((evt) => {
          // リサイズイベント発生
          this.isMobile = MOBILE_WIDTH >= width
          this.isMobileMin = MOBILE_MIN_WIDTH >= width
          this.$emit('resized', width)
        // }, 200)
      }
    }
  }
}


