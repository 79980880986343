<template>
  <div :class="$style.page_nav">
    <a
      :class="[$style.prev, {[$style.disabled]: !enablePrev}]"
      @click.prevent.stop="() => { enablePrev && $emit('page', page - 1) }"
    ><base-icon type="fas" name="chevron-left" size="18px" /></a>
    <a
      v-for="listPage in listPages"
      :key="listPage"
      :class="[$style.page, {
        [$style.current]: listPage == page,
      }]"
      @click.prevent.stop="() => { enablePage(listPage) && $emit('page', listPage) }"
    >
      {{ listPage }}
    </a>
    <a
      :class="[$style.next, {[$style.disabled]: !enableNext}]"
      @click.prevent.stop="() => { enableNext && $emit('page', page + 1) }"
    ><base-icon type="fas" name="chevron-right" size="18px" /></a>
  </div>
</template>
<script>
import BaseIcon from "@/components/base/BaseIcon/BaseIcon"

export default {
  components: { BaseIcon },
  props: {
    page: {
      type: Number,
    },
    pageMax: {
      type: Number,
    },
    pageMin: {
      type: Number,
      default: 1
    },
    pageListNum: {
      type: Number,
      default: 6
    },
  },
  methods: {
    enablePage(page) {
      return this.page != page
    }
  },
  computed: {
    pageIndex() {
      return this.page - this.pageMin
    },
    pageNum() {
      return this.pageMax - this.pageMin + 1
    },
    enablePrev() {
      return this.pageIndex > 0
    },
    enableNext() {
      return this.pageIndex + 1 < this.pageNum
    },
    listPages() {
      const pages = []
      if (this.pageListNum > 0) {
        let page = this.page - Math.ceil(this.pageListNum / 2) + 1
        if (page < this.pageMin) {
          page = this.pageMin
        }
        let pageLast = page + this.pageListNum - 1
        if (pageLast > this.pageMax) {
          page -= pageLast - this.pageMax
          if (page < this.pageMin) {
            page = this.pageMin
          }
          pageLast = this.pageMax
        }
        for (; page <= pageLast; ++page) {
          pages.push(page)
        }
      }
      return pages
    }
  }
}
</script>
<style lang="scss" module>
$fontGray: #B7B7B7;

.page_nav {
  .prev,
  .next {
    display: inline-block;
    color: $keyBlack;
    font-weight: bold;
    cursor: pointer;

    &.disabled {
      color: $fontGray;
      cursor: default;
    }
  }
  .prev { margin-right: 24px; }
  .next { margin-left: 24px; }

  .page {
    display: inline-block;
    color: $fontGray;
    cursor: pointer;
    margin: 0 13px;
    font-size: 16px;

    @include mobile() {
      margin: 0 10px;
    }

    &.current {
      color: $keyBlack;
      font-weight: bold;
      cursor: default;
      position: relative;

      &::before {
        display: inline-block;
        content: "";
        position: absolute;
        bottom: -7px;
        left: 0;
        right: 0;
        border-top: 2px solid $keyPink;
      }
    }
  }
}
</style>
