<template>
  <div :class='$style.verification_send'>
    <h1 :class='$style.title'>本人確認メッセージ送信</h1>
    <p :class='$style.note'>本人確認のためメッセージを送信しました。<br>メッセージを確認の上、次へを押してください。</p>

    <div :class="$style.resend_link_wrap">
      <a :class="$style.resend_link" href="#" v-on:click="resendCode"
        >本人確認メッセージが届かない<br />再送する</a
      >
    </div>
    <p :class="$style.resend" v-show="isResend">
      本人確認メッセージを再送しました。<br />再度メッセージを確認の上、次へを押してください。
    </p>

    <base-message
      type="error"
      v-if="error_text"
    >
      <template>
        {{ error_text }}
      </template>
    </base-message>

    <div :class="$style.next_button_wrap">
      <base-button
        display="inline-block"
        width="100%"
        height="50px"
        @click="mode == 'new' ? $router.push('/open/verification_confirm') : $router.push('/open/password_reset')"
      >
        次へ
      </base-button>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/base/BaseButton/BaseButton'
import BaseMessage from '@/components/base/BaseMessage/BaseMessage'

export default {
  name: 'VerificationSend',
  components: { BaseButton, BaseMessage },
  props: {
    mode: {
      type: String,
      required: true,
      default: 'new',
    },
  },
  data() {
    return {
      isResend: false,
      error_text: '',
    }
  },
  methods: {
    resendCode() {
      let self = this;
      this.error_text = ''

      if (this.mode == 'new') {
        // 認証基盤 確認コード再送APIコール
        this.$axios
          .post('/api/' + this.$constants.API_VERSION + '/auth/resend/code', {
            tel_or_email: this.$store.state.registration.tel_or_email,
            password: this.$store.state.registration.password,
            invite_token: this.$store.state.invite.token,
          })
          .then(() => {
            this.isResend = true
          }).catch(err => {
            if(err.response.status === 400 || err.response.status === 401){
              err.response.data.value.errors.forEach(error => {
                self.error_text = error.message
              })
            }
          });
      } else if (this.mode == 'reset') {
        // 認証基盤 パスワードリセット要求API
        this.$axios
          .post('/api/' + this.$constants.API_VERSION + '/auth/password/reset/request', {
            tel_or_email: this.$store.state.registration.tel_or_email,
            birthday: this.$store.state.registration.birthday,
          })
          .then(() => {
            this.isResend = true
          }).catch(err => {
            if(err.response.status === 400){
              this.errorMsgList = err.response.data.value.errors;
            }
          });
      }

    },
  },
}
</script>
<style lang='scss' module>
.verification_send {
  display: flex;
  flex-direction: column;
  width: 375px;
  margin: 0 auto;
  padding: 42px 32px 30px 32px;
  box-sizing: border-box;
  text-align: left;
}

.title {
  margin-top: 0;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 50px;
}

.note,.resend {
  font-size: 13px;
  text-align: left;
  color: $fontBlack;
  margin-top: 0;
  margin-bottom: 38px;
}

.resend_link_wrap {
  text-align: center;
  margin-bottom: 38px;
}
.resend_link {
  color: $KeyDarkGreen;
  font-size: 13px;
}

.next_button_wrap {
  margin-top: auto;
}
</style>
