<template>
  <div :class="$style.union_course_quiz_result">
    <div :class="$style.union_course_quiz_result_content">
      <h1 :class="$style.content_title">視聴確認クイズ（回答完了）</h1>
      <p :class="$style.caption">クイズの回答が完了しました。</p>
      <div :class="$style.button_group">
        <h1 v-if="this.$route.params.corner_id">
          <base-button
            @click="goToCourse()"
            width="180px"
            rounded="rounded"
            display="inline-flex"
          >
            講座一覧へ
          </base-button>
        </h1>
        <base-button
          width="180px"
          rounded="rounded"
          @click="$router.push(`/union/leaning_status`)"
        >
          学習状況一覧へ
        </base-button>
        <base-button
          width="180px"
          rounded="rounded"
          @click="$router.push(`/union/home`)"
        >
          MOVIETOPへ
        </base-button>
        <!--  <base-button
          width="180px"
          rounded="rounded"
          @click="share()"
        >
          この講座を共有する
        </base-button> -->
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/base/BaseButton/BaseButton'
export default {
  name: 'UnionCourseQuizResult',
  components: { BaseButton },
  mounted() {
    this.$router.app.$emit('page-updated', {
      pageTitle: '視聴確認クイズ（回答完了）',
      headerColor: 'yellow',
      showSetting: false,
      bgColor: 'yellow',
    })
  },
  created() {},
  methods: {
    goToCourse() {
      this.$router.push(`/union/corner/${this.$route.params.corner_id}`)
    },
  },
}
</script>

<style lang="scss" module>
.union_course_quiz_result {
  display: flex;
  width: 960px;
  flex-direction: column;
  align-items: center;
  padding: 52px 0 62px 0;
  margin: 0 auto;
  @include mobile() {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}

.union_course_quiz_result_content {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  background-color: $keyWhite;
  padding: 24px 150px;
  box-sizing: border-box;

  @include mobile() {
    padding: 100px 0 0 0;
  }
}

.content_title {
  font-size: 19px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 92px;
  @include mobile() {
    display: none;
  }
}

.caption {
  font-size: 13px;
  color: $fontGray;
  margin-top: 0;
  margin-bottom: 50px;
}

.button_group {
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 30px;
  }
}
</style>
