<template>
  <div :class="$style.wrapper">
    <div :class="$style.item_wrapper">
      <div v-for="(value, i) in modelValue" :key="i" :class="$style.item">
        <div :class="$style.item_input_wrapper">
          <base-input
            :class="$style.item_input"
            v-bind="$attrs"
            :model-value="value"
            :error="eachError(value)"
            @update="(e) => change(i, e)"
          >
            <template v-for="(index, name) in $slots" v-slot:[name]>
              <slot :name="name" />
            </template>
          </base-input>
        </div>
        <div v-if="modelValue.length > 1" :class="$style.item_remove" @click="remove(i)">
          <base-icon type="fas" name="minus" color="green" size="18px" />
        </div>
        <div v-if="modelValue.length <= 1" style="width: 24px; margin: 14px"></div>
      </div>
    </div>
    <div :class="$style.item_append_wrapper" @click="append()">
      <div :class="$style.item_append">
        <base-icon type="fas" name="plus" color="green" size="18px" />
      </div>
    </div>
    <p v-if="!!errorText" :class="$style.error_txt">{{ errorText }}</p>
  </div>
</template>

<script>
import BaseInput from '../BaseInput/BaseInput'
import BaseIcon from '../BaseIcon/BaseIcon'

export default {
  name: 'BaseMultiInput',
  components: { BaseInput, BaseIcon },
  inheritAttrs: false,
  model: {
    prop: 'modelValue',
    event: 'update',
  },
  props: {
    modelValue: {
      type: Array,
    },
    /**
     * エラー時の文言を指定するとエラー表示になります。
     * String: そのままエラー文として表示します。空文字列は無視されます
     * Boolean: trueならエラー文なしで枠のみがエラー表示になります。
     */
    error: {
      type: [String, Boolean],
      default: '',
    },
    /**
     * 個々の入力ボックスについてエラー値か判定して文言を返すとエラー表示になります。
     * Function: (value) => String|Booleanを評価して返された値で上記の挙動をとります。
     */
    eachError: {
      type: Function,
      default: (/*value*/) => false,
    },
    defaultValue: {
      default: undefined,
    },
  },
  computed: {
    errorValue() {
      const error = this.error
      return (typeof error === 'function' && error()) || error
    },
    errorText() {
      const errorValue = this.errorValue
      return (typeof errorValue == 'string' && errorValue) || ''
    },
  },
  methods: {
    append() {
      const value =
        typeof this.defaultValue === 'function'
          ? this.defaultValue()
          : this.defaultValue
      const a = this.modelValue.concat()
      // console.log('multi-input append: ', { a, value })
      a.push(value)
      this.$emit('appended', a)
    },
    remove(i) {
      const a = this.modelValue.concat()
      // console.log('multi-input remove: ', { a, i })
      a.splice(i, 1)
      this.$emit('removed', a, i)
    },
    change(i, value) {
      const a = this.modelValue.concat()
      // console.log('multi-input change: ', { a, i, value })
      a[i] = value
      this.$emit('update', a, i)
    },
  },
}
</script>
<style lang="scss" module>
.wrappper {
}
.item_wrapper {
}
.item {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 14px;
  }
}
.item_input_wrapper {
  flex: 1;
}
.item_input {
}
.item_remove {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 3px solid $keyGreen;
  border-radius: 50%;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  margin: 14px;
  cursor: pointer;
}

.item_append_wrapper {
  display: flex;
  justify-content: center;
  margin-right: 52px;
}
.item_append {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 3px solid $keyGreen;
  border-radius: 50%;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  margin: 14px;
  cursor: pointer;
}

.error_txt {
  font-size: 13px;
  margin: 4px 0 0 0;
  color: #f00;
}
</style>
