<template>
  <div :class="$style.wrapper">
    <section :class="$style.container">
      <h1 :class="$style.head_section">
        <img :class="$style.logo" src="@/assets/images/open_logo.svg" />
      </h1>
      <p :class="$style.title_section">
        {{ getTitle }}
      </p>
      <p :class="$style.message_section">
        <span v-html="getMessage"></span>
      </p>
      <div :class="$style.links">
        <base-button
          bg-color="green"
          color="white"
          :class="$style.button"
          @click="clickOpenTop"
        >
          くみあいアプリのトップへ
        </base-button>
      </div>
      <p :class="[$style.title_section, $style.status]">{{ status_code }}</p>
    </section>
  </div>
</template>
<script>
import BaseButton from '@/components/base/BaseButton/BaseButton'

export default {
  components: {
    BaseButton,
  },
  props: {
    status_code: {
      type: Number,
    },
  },
  data: function () {
    return {}
  },
  methods: {
    clickOpenTop: function () {
      this.$router.replace(`/`)
    },
  },
  computed: {
    getTitle() {
      if (this.status_code == null) return ''
      let title = ''
      switch (this.status_code) {
        case 404:
          title = 'ご指定のページがみつかりませんでした。'
          break
        case 460:
          title = '権限がありません。'
          break
        default:
          title = 'システム管理者からのご連絡'
          break
      }
      return title
    },
    getMessage() {
      if (this.status_code == null) return ''
      let message = ''
      switch (this.status_code) {
        case 404:
          message =
            'アクセスしようとしたページは、現在利用出来ない可能性があります。<br>お手数ですが、くみあいアプリのトップよりお探しください。'
          break
        case 460:
          message = '正規の手順でご利用ください。'
          break
        default:
          message =
            "ご不便をお掛け申し訳ありません。<br>システム管理者までご連絡ください。 <a href='/open/contact'> お問い合わせ </a>"
          break
      }
      return message
    },
  },
}
</script>
<style lang="scss" module>
.wrapper {
  color: $keyBlack;
  text-align: left;
  margin: 0 auto;
  box-sizing: border-box;
  width: 1080px;
  padding: 34px 82px;

  @include mobile() {
    width: 375px;
    padding: 42px 32px;
  }

  * {
    margin: 0;
    padding: 0;
  }

  .pc {
    @include mobile() {
      display: none !important;
    }
  }
  .sp {
    @include pc() {
      display: none !important;
    }
  }

  .container {
    display: flex;
    align-items: center;
    flex-direction: column;

    .head_section {
      display: flex;
      align-items: center;
      font-size: 29px;
      font-weight: bold;
      margin-bottom: 24px;
      flex-direction: column;

      @include pc() {
        font-size: 45px;
        margin-bottom: 35px;
      }

      .logo {
        width: 95px;
        @include mobile() {
          width: 64px;
          margin-bottom: 4px;
        }
      }
    }
    .title_section {
      font-size: 20px;
      line-height: 30px;
      font-weight: bold;
      margin-bottom: 0px;
      display: flex;
      justify-content: center;

      @include pc() {
        font-size: 30px;
        line-height: 48px;
        text-align: center;
        margin-bottom: 0px;
      }
      &.status {
        margin-top: 20px;
        margin-bottom: 10px;
      }
    }
    .message_section {
      font-size: 16px;
      line-height: 30px;
      font-weight: bold;
      color: #707070;
      display: flex;
      justify-content: center;
      width: 100%;
      margin: 20px auto 40px;

      @include mobile() {
        font-size: 12px;
        line-height: 24px;
        margin-bottom: 40px;
        width: 280px;
      }
      a {
        color: $keyGreen;
        font-size: 16px;
        font-weight: 400;
        cursor: pointer;
        text-decoration: underline;
        @include mobile() {
          font-size: 11px;
        }
      }
    }

    .links {
      display: flex;
      justify-content: center;
      .button {
        width: 300px !important;
        height: 48px !important;
        font-size: 17px !important;

        @include mobile() {
          width: 300px !important;
          height: 48px !important;
          font-size: 16px !important;
        }
      }
    }
  }
}
</style>
