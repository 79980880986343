<template>
  <div :class="$style.video_player">
    <video ref="videoPlayer" class="video-js vjs-big-play-centered" />
  </div>
</template>

<script>
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import 'videojs-contrib-quality-levels'
import 'videojs-http-source-selector'

export default {
  name: 'BaseVideo',
  props: {
    autoPlay: {
      type: Boolean,
      default: false,
    },
    source: {
      type: String,
      default: null,
    },
    poster: {
      type: String,
      default: null,
    },
    currentTime: { type: Number, default: 0 },
  },
  data() {
    return {
      player: null,
      totalPlayTime: 0,
      startTime: 0,
    }
  },
  mounted() {
    this.player = videojs(this.$refs.videoPlayer, this.videoOptions)
    this.player.httpSourceSelector()

    this.player
      .getChild('controlBar')
      .addChild(this.createRewindButton(), {}, 1)
    this.player
      .getChild('controlBar')
      .addChild(this.createForwardButton(), {}, 2)
    document
      .getElementById('skipButtonRewind')
      .addEventListener('click', () => {
        const newTime = this.player.currentTime() - 30
        this.player.currentTime(newTime)
      })
    document
      .getElementById('skipButtonForward')
      .addEventListener('click', () => {
        const newTime = this.player.currentTime() + 30
        this.player.currentTime(newTime)
      })
    this.player.on('pause', () => {
      this.totalPlayTime =
        Math.floor((Date.now() - this.startTime) / 1000) + this.totalPlayTime
    })
    this.player.on('play', () => {
      this.startTime = Date.now()
    })
    this.player.currentTime(this.currentTime)
  },
  beforeDestroy() {
    if (this.player) {
      this.$emit(
        'close-video-modal',
        this.player.currentTime(),
        this.totalPlayTime
      )
      this.player.dispose()
    }
  },
  computed: {
    videoOptions() {
      return {
        autoplay: this.autoPlay,
        muted: this.autoPlay,
        controls: true,
        aspectRatio: '16:9',
        playbackRates: [0.5, 1, 1.5, 2],
        controlBar: {
          volumePanel: {
            inline: false,
          },
          pictureInPictureToggle: false,
        },
        sources: [
          {
            src: this.source,
          },
        ],
        poster: this.poster,
      }
    },
  },
  methods: {
    createForwardButton() {
      const Component = videojs.getComponent('Component')
      const result = new Component(this.player)
      result.el().innerHTML = `
          <div class="skip_button_wrap" id="skipButtonForward">
            <svg class="skip_button_forward" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
              <defs>
                <style>.cls-1{fill:none;}</style>
              </defs>
              <title>rotate</title>
              <path d="M17.91,26.82l.35,2a12.9,12.9,0,0,0,4.24-1.54l-1-1.73A10.88,10.88,0,0,1,17.91,26.82Z"/>
              <path d="M24.42,23.07,26,24.35a13,13,0,0,0,2.24-3.91l-1.87-.68A11,11,0,0,1,24.42,23.07Z"/>
              <path d="M9.5,27.25a12.9,12.9,0,0,0,4.24,1.54l.35-2a10.88,10.88,0,0,1-3.59-1.3Z"/>
              <path d="M5.67,19.76l-1.87.68A13,13,0,0,0,6,24.35l.32-.26,1.22-1h0a11,11,0,0,1-1.91-3.31Z"/>
              <path d="M29,16a12.85,12.85,0,0,0-.8-4.44l-1.87.68A11.18,11.18,0,0,1,27,16Z"/>
              <path d="M26,7.65a13,13,0,0,0-20,0V4H4v8h8V10H6.81A11,11,0,0,1,24.42,8.93Z"/>
              <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32"/>
            </svg>
          </div>`
      return result
    },

    createRewindButton() {
      const Component = videojs.getComponent('Component')
      const result = new Component(this.player)
      result.el().innerHTML = `
          <div class="skip_button_wrap" id="skipButtonRewind">
            <svg class="skip_button_rewind" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                <defs>
                  <style>.cls-1{fill:none;}</style>
                </defs>
                <title>rotate</title>
                <path d="M17.91,26.82l.35,2a12.9,12.9,0,0,0,4.24-1.54l-1-1.73A10.88,10.88,0,0,1,17.91,26.82Z"/>
                <path d="M24.42,23.07,26,24.35a13,13,0,0,0,2.24-3.91l-1.87-.68A11,11,0,0,1,24.42,23.07Z"/>
                <path d="M9.5,27.25a12.9,12.9,0,0,0,4.24,1.54l.35-2a10.88,10.88,0,0,1-3.59-1.3Z"/>
                <path d="M5.67,19.76l-1.87.68A13,13,0,0,0,6,24.35l.32-.26,1.22-1h0a11,11,0,0,1-1.91-3.31Z"/>
                <path d="M29,16a12.85,12.85,0,0,0-.8-4.44l-1.87.68A11.18,11.18,0,0,1,27,16Z"/>
                <path d="M26,7.65a13,13,0,0,0-20,0V4H4v8h8V10H6.81A11,11,0,0,1,24.42,8.93Z"/>
                <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32"/>
              </svg>
          </div>
      `
      return result
    },
  },
}
</script>

<style lang="scss" module>
.video_player {
  [class^='vjs-big-play-button'] {
    border-radius: 50%;
    width: 3em;
    height: 3em;
    margin-top: -1.5em;
    margin-left: -1.5em;
    line-height: 3em;
  }
  [class^='skip_button_wrap'] {
    height: 30px;
    fill: $keyWhite;
    position: relative;
    &:first-child {
      margin-right: 12px;
    }
    &:after {
      display: block;
      position: absolute;
      top: 12px;
      left: 9px;
      content: '30';
      color: $keyWhite;
      font-size: 10px;
    }
  }
  [class^='skip_button_rewind'] {
    width: 30px;
    height: 30px;
  }
  [class^='skip_button_forward'] {
    width: 30px;
    height: 30px;
    transform: scale(-1, 1);
  }
}
</style>
