export const labelStyle = {
  mobile_4: [
    { top: "0px", left: "70px"},
    { top: "90px", left: "147px", textAlign: "right" },
    { top: "174px", left: "70px" },
    { top: "90px", left: "-5px", textAlign: "left"},
  ],
  pc_4: [
    { top: "0px", left: "107px"},
    { top: "138px", left: "220px", textAlign: "right"},
    { top: "272px", left: "107px" },
    { top: "138px", left: "-5px", textAlign: "left" },
  ],
};
