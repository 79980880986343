<template>
  <div :class="$style.wrapper">
    <app-prev-button v-if="auth_info" @click="$router.go(-1)" :no-mobile="true"/>
    <h1>運営会社</h1>
    <section>
      <h2>商号</h2>
      <p>
        ｊ.ｕｎｉｏｎ 株式会社 （ジェイユニオン &nbsp;&nbsp;カブシキガイシャ）
      </p>
    </section>
    <section>
      <h2>本社</h2>
      <p>
        〒163-1322<br />
        東京都新宿区西新宿6-5-1新宿アイランドタワー 22F &nbsp;&nbsp;<a
          href="https://j-union.com/-/pands/html/page.php?cd=18584"
          :class="$style.map"
          >地図</a
        ><br />
        TEL: 03-5339-8200（代表） &nbsp;&nbsp;FAX: 03-5339-8201（代表）
      </p>
    </section>
    <section>
      <h2>設立</h2>
      <p>1989年（平成元年）3月6日</p>
    </section>
    <section>
      <h2>代表者</h2>
      <p>代表取締役社長 &nbsp;&nbsp; 服部 恵祐</p>
    </section>
    <section>
      <h2>資本金</h2>
      <p>4500万円（2021年3月現在）</p>
    </section>
    <section>
      <h2>主な事業内容</h2>
      <p>労働組合の活動を活性化するため、多様なサービスで支援。<br>
         どのサービスも、労働組合の事情に精通した当社ならではの視点で提供し、<br>
         組合活動における実践・活用についても具体的にアドバイスしていきます。<br>
          •	組織・経営コンサルティング事業<br>
          •	調査分析リサーチ事業<br>
          •	研修・セミナープログラムの企画運営事業<br>
          •	書籍販売業<br>
          •	メンタルヘルス対策、人材育成支援事業<br>
          •	情報宣伝ツールの企画制作支援事業<br>
          •	会員制ホームページ運営サービス事業<br>
          •	システムソリューション事業<br>
          •	ソフトウェア販売業<br>
          •	各種イベント・研究会・周年行事の企画運営事業<br>
      </p>
    </section>
    <section>
      <h2>事業所</h2>
      <p>【 高崎オフィス 】<br>
          〒370-0841<br>
          群馬県高崎市栄町3-11 高崎バナーズビル3階 &nbsp;&nbsp;<a
          href="https://j-union.com/-/pands/html/page.php?cd=77377"
          :class="$style.map"
          >地図</a
        ><br />
      </p>
      <br />
      <p>【 名古屋オフィス 】<br>
          〒460-0003<br>
          愛知県名古屋市中区錦1-18-11 ＣＫ２１広小路伏見ビル8F &nbsp;&nbsp;<a
          href="https://j-union.com/-/pands/html/page.php?cd=18582"
          :class="$style.map"
          >地図</a
        ><br />
        TEL：052-231-7440<br />
        FAX：052-231-7441
      </p>
      <br />
      <p>【 大阪オフィス 】<br>
          〒541-0046<br>
          大阪府大阪市中央区平野町3-2-8 淀屋橋ＭＩビル3F &nbsp;&nbsp;<a
          href="https://j-union.com/-/pands/html/page.php?cd=18583"
          :class="$style.map"
          >地図</a
        ><br />
        TEL：06-6201-0405<br />
        FAX：06-6201-0406
      </p>
      <br />
      <p>【 広島オフィス 】<br>
          〒730-0016<br>
          広島県広島市中区幟町13－15 新広島ビルディング2階 &nbsp;&nbsp;<a
          href="https://j-union.com/-/pands/html/page.php?cd=43860"
          :class="$style.map"
          >地図</a
        ><br />
      </p>
      <br />
      <p>【 福岡オフィス 】<br>
          〒812-0011<br>
          福岡県福岡市博多区博多駅前3-4-25 アクロスキューブ博多駅前6F &nbsp;&nbsp;<a
          href="https://j-union.com/-/pands/html/page.php?cd=18581"
          :class="$style.map"
          >地図</a
        ><br />
      </p>
    </section>
    <section>
      <h2>組織</h2>
      <p>活動促進本部<br />
        カスタマーサクセス本部<br />
        経営企画本部
      </p>
    </section>
    <section>
      <h2>社員数</h2>
      <p>113名（2022年4月現在、役員含む）</p>
    </section>
    <section>
      <h2>役員</h2>
      <p>代表取締役会長 &nbsp;小野 晋<br />
        代表取締役社長 &nbsp;服部 恵祐<br />
        取締役副社長 &nbsp;吉川 政信<br />
        専務取締役 &nbsp;浅野 淳<br />
        取締役 &nbsp;大川 守<br />
        監査役 &nbsp;泉田 和洋
      </p>
    </section>
    <section>
      <h2>名誉会長</h2>
      <p>西尾 力（元ｊ.ｕｎｉｏｎ 株式会社代表取締役会長）</p>
    </section>
    <section>
      <h2>顧問</h2>
      <p>鈴木 勝利<br />
        (元連合副会長・元JCM議長(現顧問)・元電機連合委員長(現顧問)、2012年春の叙勲において旭日重光章を受章)<br />
        <br />
        中世古 幸治<br />
        (元連合大阪副会長・元電機連合大阪地協議長（現名誉顧問）・元JCM関西地連議長・元パナソニック労働組合支部執行委員長・元大阪府労働委員会委員、2019年春の叙勲において旭日双光章を受章)<br />
        <br />
        泉田 和洋<br />
        (元電機連合 書記長・元東芝労働組合 中央執行委員長・元東芝グループ連合 初代会長・前株式会社コンポーズユニ 代表取締役社長)<br />
      </p>
    </section>
    <section>
      <h2>取引銀行</h2>
      <p>三井住友銀行新宿西口支店、中央労働金庫西新宿支店</p>
    </section>



  </div>
</template>
<script>
import AppPrevButton from '@/components/app/AppPrevButton/AppPrevButton'
export default {
  components: { AppPrevButton },
  data() {
    return {
      auth_info: this.$cookies.get('auth_info'),
    }
  }
}
</script>
<style lang="scss" module>
.wrapper {
  color: $keyBlack;
  background-color: $openBg;
  text-align: left;
  margin: 0 auto;
  box-sizing: border-box;
  width: 1080px;
  padding: 68px 210px;

  @include mobile() {
    width: 375px;
    padding: 42px 32px;
  }

  * {
    margin: 0;
  }

  h1 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 63px;

    @include mobile() {
      font-size: 24px;
      margin-bottom: 55px;
    }
  }
  section {
    &:not(:last-child) {
      margin-bottom: 20px;

      @include mobile() {
        margin-bottom: 16px;
      }
    }
    h2 {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 12px;

      @include mobile() {
        font-size: 16px;
        margin-bottom: 8px;
      }
    }
    p {
      font-size: 13px;
      line-height: 26px;

      @include mobile() {
        font-size: 10px;
        line-height: 16px;
      }
    }
  }
  a.map {
    color: $keyBlack;
    // text-decoration: none;
  }
}
</style>
