<template>
  <div :class="$style.password_reset">
    <div v-if="!processing">
      <h1 :class="$style.title">パスワード再設定</h1>
      <p :class="$style.note">
        パスワードを変更します。<br />パスワードは厳重に保管いただきますよう<br />お願いいたします。
      </p>
      <div :class="$style.input_group">
        <base-input
          name="password"
          type="password"
          look="border"
          v-model="user.password"
          :error="
            v$.user.password.$invalid && v$.user.password.$dirty
              ? v$.user.password.$errors[0].$message
              : isFieldErrorMsg('password', errorMsgList)
          "
          maxlength="256"
        >
          <template v-slot:prepend>
            <span :class="$style.input_password">新パスワード</span>
          </template>
        </base-input>
        <base-input
          name="confirm_password"
          type="password"
          look="border"
          v-model="confirm_password"
          :error="
            v$.confirm_password.$invalid && v$.confirm_password.$dirty
              ? v$.confirm_password.$errors[0].$message
              : ''
          "
          maxlength="256"
        >
          <template v-slot:prepend>
            <span :class="$style.input_confirm_password"
              >新パスワード(再入力)</span
            >
          </template>
        </base-input>

        <base-input
          name="code"
          type="password"
          look="border"
          v-model="confirm_code"
          :error="
            v$.confirm_code.$invalid && v$.confirm_code.$dirty
              ? v$.confirm_code.$errors[0].$message
              : isFieldErrorMsg('confirm_code', errorMsgList)
          "
          maxlength="6"
        >
          <template v-slot:prepend>
            <span :class="$style.input_code">確認コード</span>
          </template>
        </base-input>
      </div>

      <template v-if="hasError(errorMsgList) && !hasFieldErrorMsg(errorMsgList, [])">
        <base-message
        type="error" v-for="(error, i) in errorMsgList" :key="i"
        :class="$style.msg_area">{{error.message}}</base-message>
      </template>

      <div :class="$style.next_button_wrap">
        <base-button
          display="inline-flex"
          width="100%"
          height="50px"
          @click="submit"
          :disabled="processing || nextDisable"
        >
          次へ
        </base-button>
      </div>
    </div>
    <base-modal type="round" v-if="showModal" :hidenCloseBtn="true">
      <template v-slot:header-title>設定完了</template>
      <template v-slot:body>
        <div :class="$style.modal_content">
          <p :class="$style.modal_content_description">
            パスワード設定を変更しました。
          </p>
          <section>
            <img src="@/assets/images/open_logo.svg" alt="logo" />
          </section>
          <section>
            <h1>くみあいアプリ</h1>
          </section>
          <section>
            <p :class="$style.modal_content_powerdby">powerd by ju-ID</p>
            <p :class="$style.modal_content_company_name">j.union Inc.</p>
          </section>
        </div>
      </template>
      <template v-slot:footer>
        <base-button
          width="185px"
          rounded="rounded"
          @click.prevent="$router.push(`/open/ju_setting`)"
          >ログイン画面へ</base-button
        >
      </template>
    </base-modal>

    <div :class="$style.spinner" v-if="processing">
      <base-icon name="spinner" size="32px" spin />
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/base/BaseInput/BaseInput'
import BaseButton from '@/components/base/BaseButton/BaseButton'
import BaseMessage from '@/components/base/BaseMessage/BaseMessage'
import BaseModal from '@/components/base/BaseModal/BaseModal'
import BaseIcon from '@/components/base/BaseIcon/BaseIcon'
import useVuelidate from '@vuelidate/core'
import { required, helpers, sameAs } from '@vuelidate/validators'
import { validatePassword } from '@/utils/helper'
import {isFieldErrorMsg, hasError, hasFieldErrorMsg} from '@/helper/validator.js'

export default {
  name: 'Registration',
  components: { BaseInput, BaseButton, BaseMessage, BaseModal, BaseIcon },
  data() {
    return {
      user: {
        tel_or_email: this.$store.state.registration.tel_or_email,
        password: '',
      },
      confirm_password: '',
      confirm_code: '',
      errorMsgList: [],
      showModal: false,
      processing: false,
      nextDisable: false,
    }
  },
  setup: function () {
    return { v$: useVuelidate() }
  },
  created: async function() {
    if (this.$route.query.token) {
      this.$axios.get('/api/' + this.$constants.API_VERSION + '/auth/temporary',{
          params: {
            token: this.$route.query.token,
          }
      })
      .then((response) => {
        this.auth_data = decodeURIComponent(atob(response.data.value.auth_data))
        if (0 < this.auth_data.length) {
          this.auth_data = JSON.parse(this.auth_data)
          this.user.tel_or_email = this.auth_data['tel_or_email']
        } else {
          // 確認コード発行24時間後に認証情報取得できなくなるため、エラーメッセージを表示して、確認コード送信できなくする
          this.errorMsgList.push({
            attribute: 'confirm_code',
            message: '確認コードの有効期間が過ぎました。お手数ですが再度くみあいアプリからのパスワード再設定の手続きを行ってください。'
          })
          this.nextDisable = true;
        }
      })
    }
    return
  },
  validations: function () {
    return {
      user: {
        password: {
          required: helpers.withMessage(
            'パスワードは、必ず指定してください。',
            required
          ),
          password: helpers.withMessage(
            // eslint-disable-next-line
            'パスワードには、10文字以上の英大文字・英小文字・数字・記号(^$*.[]{}()?"!@#%&/\,><\':;|_~`)それぞれを最低1文字ずつ含む文字列を指定してください。',
            validatePassword
          ),
          $autoDirty: true,
        },
      },
      confirm_password: {
        sameAs: helpers.withMessage(
          '新パスワードと再入力のパスワードが一致しません',
          sameAs(this.user.password)
        ),
        $autoDirty: true,
      },

      confirm_code: {
        required: helpers.withMessage(
          '確認コードは、必ず指定してください。',
          required
        ),
        $autoDirty: true,
      },
    }
  },
  methods: {
    isFieldErrorMsg,
    hasError,
    hasFieldErrorMsg,

    async submit() {
      const validate = await this.v$.$validate()
      if (!validate) {
        return
      }

      if(this.processing){
        return
      }
      this.processing = true
      this.errorMsgList = []

      // 認証基盤 パスワードリセットAPI
      this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/auth/password/reset', {
          tel_or_email: this.user.tel_or_email,
          password: this.user.password,
          confirm_code: this.confirm_code,
        })
        .then(() => {
          // ユーザー名が設定されている情報クリア
          this.$store.commit('registration/clearAll')
          this.showModal = true
        }).catch(err => {
          if(err.response.status === 400 || err.response.status === 401){
            this.errorMsgList = err.response.data.value.errors;
            err.response.data.value.errors.forEach(error => {
              if (error.attribute) {
                this.errorMsgList.push(error)
              } else {
                if (error.message == '確認コードが一致しません。') {
                  this.errorMsgList.push({
                    attribute: 'confirm_code',
                    message: '確認コードが間違っています\n通知された確認コードをご入力ください'
                  })
                } else {
                  this.errorMsgList.push({
                    attribute: 'confirm_code',
                    message: error.message
                  })
                }
              }
            })
          }
        }).finally(() => {
          this.processing = false
        });
    },
  },
}
</script>
<style lang="scss" module>
.password_reset {
  display: flex;
  flex-direction: column;
  width: 375px;
  margin: 0 auto;
  padding: 42px 32px 30px 32px;
  box-sizing: border-box;
  text-align: left;
}

.title {
  margin-top: 0;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 50px;
}

.note {
  font-size: 13px;
  color: $fontBlack;
  margin-top: 0;
  margin-bottom: 30px;
}

.note_2 {
  font-size: 11px;
  color: $fontBlack;
  margin-top: 0;
  margin-bottom: 30px;
}

.input_group {
  & > * {
    &:nth-child(n) {
      margin-bottom: 30px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  margin-bottom: 30px;
}

.input {
  &_password,
  &_confirm_password,
  &_code {
    font-size: 11px;
  }
  &_password {
    margin-right: 50px;
  }
  &_confirm_password {
    margin-right: 7px;
  }
  &_code {
    margin-right: 60px;
  }
}

.next_button_wrap {
  margin-top: auto;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}

.modal_width {
  width: 800px;
  @include mobile() {
    width: 80%;
  }
}
.modal_content {
  margin: 16px auto;
  width: 400px;
  text-align: center;

  @include mobile() {
    width: auto;
    margin: 16px;
  }
  &_description {
    font-size: 13px;
    text-align: left;
  }
  &_powerdby {
    font-size: 8px;
    font-weight: bold;
  }
  &_company_name {
    font-size: 16px;
    font-weight: bold;
  }

  h1 {
    font-size: 24px;
  }
  img {
    width: 50%;
  }
}
</style>
