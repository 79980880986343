<template>
  <div :class="$style.bar_chart" :style="{ width: width }">
    <ul :class="$style.bar_chart_bar_wrap">
      <li
        v-for="vote in vote_result"
        :key="vote.sort"
        :class="$style.bar_chart_bar"
        :style="{ width: vote.rate + '%' }"
      >
        <span :class="$style.bar_chart_bar_ratio">{{ vote.rate }}%</span>
        <!-- 20文字以上で...と表示されるように -->
         <span :class="$style.bar_chart_bar_label">{{ vote.choice_sort.length > 22 ? vote.choice_sort.slice(0,22) + '...' : vote.choice_sort }}</span>
      </li>
    </ul>
    <div v-if="isShowTotalCount" :class="$style.total_data">
      {{ totalLabel }}{{ totalData }}{{ totalLabelUnit }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppBarChart',
  props: {
    width: {
      type: String,
      default: 'auto',
    },
    data: {
      type: Array,
      default: undefined,
    },
    vote_result: {
      type: Array,
      default: undefined,
    },
    labels: {
      type: Array,
    },
    totalLabel: {
      type: String,
      default: undefined,
    },
    totalLabelUnit: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    isShowTotalCount() {
      return this.totalLabel || this.totalLabelUnit
    },
    totalData() {
      if (!this.data) return 0
      return this.data.reduce((prev, current) => {
        return prev + current
      }, 0)
    },
  },
}
</script>

<style lang="scss" module>
.bar_chart {
  background-color: #f1f5dc;
  flex-direction: column;
  padding: 12px 22px;
  border-radius: 10px;
  @media screen and (min-width: 768px) {
    padding: 50px 80px;
  }

  .bar_chart_bar_wrap {
    width: 100%;
    list-style-type: none;
    margin: 0;
    position: relative;
    padding: 0;

    .bar_chart_bar {
      background: $keyYellow;
      width: 0;
      border-radius: 6px;
      margin-bottom: 17px;
      height: 29px;
      line-height: 29px;
      font-weight: bold;
      font-size: 13px;
      color: $keyGreen;

      &:last-child {
        margin-bottom: 0;
      }
      @media screen and (min-width: 768px) {
        font-size: 18px;
        color: black;
        margin-bottom: 20px;
        padding: 10px 0;
      }

      .bar_chart_bar_label {
        white-space: nowrap;
        // text-decoration: underline;
        position: relative;
        left: 22px;
      }
      .bar_chart_bar_ratio {
        position: absolute;
        right: 0;
      }
    }
  }
  .total_data {
    font-size: 12px;
    padding-top: 13px;
  }
}
</style>
