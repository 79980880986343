import { Radar, mixins } from 'vue-chartjs'

export default {
  extends: Radar,
  props: ["chartData", "options"],
  mixins: [mixins.reactiveProp],
  methods: {
    update() {
      this.$data._chart.update()
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.options)
  }
}
