<template>
  <div :class="$style.wrapper">
    <app-prev-button v-if="auth_info" @click="$router.go(-1)" :no-mobile="true"/>
    <h1 :class="$style.h1">お問い合わせ</h1>
    <section :class="$style.section">
      <h2 :class="$style.h2">サービスへのお問い合わせ</h2>
      <a
        :href="`mailto:${ mail_to }?subject=くみあいアプリのお問い合わせ&body=${ mail_body }`"
        :class="$style.a"
      >
        くみあいアプリのお問い合わせ
      </a>
    </section>
    <section :class="$style.section">
      <h2 :class="$style.h2">サービスへのお申し込み</h2>
      <a
        :href="`mailto:${ mail_to }?subject=サービスへのお申し込み&body=${ mail_body }`"
        :class="$style.a"
      >
        くみあいアプリのお申し込み
      </a>
    </section>
    <section :class="$style.section">
      <h2 :class="$style.h2">運営会社へのお問い合わせ</h2>
      <a href="https://www.j-union.com/contact/" :class="$style.a">
        ｊ.ｕｎｉｏｎ 株式会社 へのお問い合わせ
      </a>
      <a
        href="https://j-union.com/-/pands/enq/main/enquete.php?cd_enq=4060"
        :class="$style.a"
      >
        個人情報お客さま窓口へのお問い合わせ
      </a>
    </section>
  </div>
</template>
<script>
import AppPrevButton from '@/components/app/AppPrevButton/AppPrevButton'
export default {
  components: { AppPrevButton },
  data() {
    const newline = '%0D%0A'
    const _mail_body =
`
※可能な限り、下記フォームに沿って記入をよろしくお願いいたします。${newline}${newline}
■所属の労働組合の名称（例：j.union労働組合）${newline}${newline}
■ご相談者様のお名前（例：組合 太郎）${newline}${newline}
■メールアドレス${newline}${newline}
■お電話番号${newline}${newline}
■ご相談内容${newline}${newline}${newline}
【既にくみあいアプリをご利用で、下記内容もご存知でしたら、記入ください】${newline}${newline}
■くみあいアプリに登録しているメールアドレス${newline}${newline}
■くみあいアプリでのユーザー名${newline}${newline}
■くみあいアプリで所属しているチーム名${newline}${newline}
`
    return {
      mail_to: process.env.VUE_APP_CONTACT_MAIL_TO,
      mail_body: _mail_body,
      auth_info: this.$cookies.get('auth_info'),
    }
  },
}
</script>
<style lang="scss" module>
.wrapper {
  color: $keyBlack;
  background-color: $openBg;
  text-align: left;
  margin: 0 auto;
  box-sizing: border-box;
  width: 1080px;
  padding: 68px 210px;

  @include mobile() {
    width: 375px;
    padding: 42px 32px;
  }

  * {
    margin: 0;
  }

  .h1 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 63px;

    @include mobile() {
      font-size: 24px;
      margin-bottom: 55px;
    }
  }
  .section {
    &:not(:last-child) {
      margin-bottom: 36px;
      @include mobile() {
        margin-bottom: 30px;
      }
    }

    .h2 {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 16px;

      @include mobile() {
        font-size: 16px;
        margin-bottom: 12px;
      }
    }

    .a {
      display: block;
      color: $keyGreen;
      font-size: 13px;

      @include mobile() {
        font-size: 11px;
      }

      &:not(:last-child) {
        margin-bottom: 16px;

        @include mobile() {
          margin-bottom: 13px;
        }
      }
    }
  }
}
</style>
