<template>
  <div class="$style.open_layout">
    <app-header v-if="!shouldHideSection"/>
    <div :class="$style.open_content">
      <div :class="$style.open_content_body">
        <router-view />
      </div>
      <app-footer />
    </div>
  </div>
</template>

<script>
import AppHeader from '@/components/app/AppOpenHeader/AppOpenHeader'
import AppFooter from '@/components/app/AppFooter/AppFooter'
export default {
  name: 'OpenTopLayout',
  components: { AppHeader, AppFooter },
  data() {
    return {
      shouldHideSection: false,
    }
  },
  methods: {},
  mounted() {
    // URLからパラメータを取得
    const urlParams = new URLSearchParams(window.location.search);
    console.log(urlParams)
    if (urlParams.has('hideHeader') && urlParams.get('hideHeader') === 'true') {
      this.shouldHideSection = true;
    }
  }
}
</script>

<style lang="scss" module>
.open_content {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 90px);
  background: $keyYellow;
}
.open_content_body {
  display: flex;
  flex: 1;
  background: url('~@/assets/images/open_top_bg.svg') repeat;
  background-position: -30px -150px;
  background-attachment: fixed;
}
</style>
